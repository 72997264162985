import React from 'react';
import Icon from 'react-icon-base';

export const Query = (props) => (
    <Icon viewBox="0 0 15 15" {...props}>
        <svg
            width="15px"
            height="15px"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <path
                    d="M10.2941,4.14745 C12.8931,4.14745 15,6.25435 15,8.85335 C15,11.452325 12.8931,13.559225 10.2941,13.559225 C7.695125,13.559225 5.588225,11.452325 5.588225,8.85335 C5.588225,6.25435 7.695125,4.14745 10.2941,4.14745 Z M10.75,10.875 L9.75,10.875 L9.75,11.875 L10.75,11.875 L10.75,10.875 Z M10.25,5.75 C9.145,5.75 8.25,6.645 8.25,7.75 L8.25,7.75 L9.25,7.75 C9.25,7.2 9.7,6.75 10.25,6.75 C10.8,6.75 11.25,7.2 11.25,7.75 C11.25,8.75 9.75,8.625 9.75,10.25 L9.75,10.25 L10.75,10.25 C10.75,9.125 12.25,9 12.25,7.75 C12.25,6.645 11.355,5.75 10.25,5.75 Z M4.484925,7.3811875 C4.436825,7.6680625 4.411775,7.9627625 4.411775,8.2633125 C4.411775,8.4622125 4.42275,8.6585625 4.4441,8.8517875 L4.4441,8.8517875 L5.77315973e-14,8.8517875 L5.77315973e-14,7.3811875 Z M6.044,4.4405875 C5.603725,4.8623875 5.23615,5.3594875 4.962,5.9111875 L4.962,5.9111875 L-1.09245946e-13,5.9111875 L-1.09245946e-13,4.4405875 Z M8.823525,1.5 L8.823525,2.9705875 L-1.48325796e-13,2.9705875 L-1.48325796e-13,1.5 L8.823525,1.5 Z"
                    id="path-1"
                ></path>
            </defs>
            <g
                id="icon/query"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1"></use>
                </mask>
                <use
                    id="Mask"
                    fill="currentColor"
                    fillRule="nonzero"
                    xlinkHref="#path-1"
                ></use>
                <g id="Group" mask="url(#mask-2)" fill="currentColor">
                    <g id="colours/black-1000">
                        <rect
                            id="Rectangle"
                            x="0"
                            y="0"
                            width="15"
                            height="15"
                        ></rect>
                    </g>
                </g>
            </g>
        </svg>
    </Icon>
);
