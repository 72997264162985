import React from 'react';
import {get} from 'lodash';
import {BlockControls} from '../block-controls';
import {Button} from '@app/common';
import {MdFlashOn} from 'react-icons/md';

export const renderInactiveExtension = (
    extension,
    index,
    fields,
    removeBlock,
    activateBlock,
) => {
    const text = get(extension, 'text', '');
    const CTA = get(extension, 'call_to_action');
    return (
        <div className="kare-block-wrapper kare-extension-block">
            <section className="kare-block kare-block--inactive kare-block-choice">
                <h1>{text}</h1>
                <Button
                    outlinedPrimary={true}
                    icon={MdFlashOn}
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                >
                    {CTA}
                </Button>
            </section>
            {index >= 0 && fields && removeBlock && activateBlock && (
                <BlockControls
                    index={index}
                    fields={fields}
                    removeBlock={removeBlock}
                    activateBlock={activateBlock}
                />
            )}
        </div>
    );
};
export const ExtensionBlockField = (props) => {
    const {removeBlock, index, fields} = props;

    const extension = get(props, ['input', 'value', 'webext_block'], '');

    return renderInactiveExtension(
        extension,
        index,
        fields,
        removeBlock,
        props.activateBlock,
    );
};
