import React, {useContext, useEffect, useState} from 'react';
import {CheckCircle} from '@material-ui/icons';
import ChevronRight from '@material-ui/icons/ChevronRight';

import './BrokenCard.scss';
import {transformBrokenDataToBrokenCard} from '@app/utils/BrokenDataTransform';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {StoreContext} from '@app/state';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';

interface IBrokenCardProps {
    history: any;
}
const defaultInitialState = {
    data: null,
    loading: true,
    error: null,
};
export const BrokenCard: React.FunctionComponent<IBrokenCardProps> = ({
    history,
}) => {
    const [data, setData] = useState(defaultInitialState);
    const {nodes} = useContext(StoreContext);
    const getBroken = async () => {
        setData(defaultInitialState);
        const res = await nodes.getBroken();
        setData({data: res.data, error: res.error, loading: false});
    };
    useEffect(() => {
        getBroken();
    }, []);

    const showEmptyState = () => {
        if (!data.data || !data.data.nodes || data.data.nodes.length === 0) {
            return 'No data';
        }
        return '';
    };

    return (
        <ErrorLoadingWrapper
            height={'152px'}
            error={data.error}
            isLoading={data.loading}
            emptyState={showEmptyState()}
            CustomEmptyState={
                <div className="BrokenCard__customEmpty">
                    <div className="BrokenCard__top">
                        <CheckCircle />
                        <span>You're all good!</span>
                    </div>
                    <div className="BrokenCard__bottom">
                        Check this page regularly to see if any content is
                        broken.
                    </div>
                </div>
            }
            render={() => {
                const transformedData = transformBrokenDataToBrokenCard(
                    data.data,
                );

                return (
                    <div className="BrokenCard__container">
                        <div
                            className="BrokenCard__title"
                            onClick={() => {
                                history.push('/consolidate/broken');
                            }}
                        >
                            <CardTitle title={'Broken nodes'}></CardTitle>
                            <ChevronRight />
                        </div>

                        <div className="BrokenCard__content">
                            <div className="BrokenCard__block">
                                <h2>Dialog</h2>
                                <h2>{transformedData.brokenChoices}</h2>
                            </div>
                            <div className="BrokenCard__block">
                                <h2>Quotes</h2>
                                <h2>{transformedData.brokenQuotes}</h2>
                            </div>
                        </div>
                    </div>
                );
            }}
        />
    );
};
