// https://support.zendesk.com/hc/en-us/articles/203663836-Using-OAuth-authentication-with-your-application#topic_pvr_ncl_1l
export default {
    zendesk: {
        clientId: 'zdg-kare-mind',
        scope: 'hc:read',
        redirectPath: '/mind/return/zendesk',
        responseType: 'code',
        // state - An arbitrary string included in the response from Zendesk after the user decides whether or not
        // to grant access.
        state: 'fix-this-state',
    },
};
