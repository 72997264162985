import React from 'react';

import {PropTypes} from './CustomLinkArrowWidget.types';

export const CustomLinkArrowWidget: React.FC<PropTypes> = (props) => {
    const {point, previousPoint, color} = props;

    const angle =
        90 +
        (Math.atan2(
            point.getPosition().y - previousPoint.getPosition().y,
            point.getPosition().x - previousPoint.getPosition().x,
        ) *
            180) /
            Math.PI;

    return (
        <g
            className="arrow"
            transform={
                'translate(' +
                point.getPosition().x +
                ', ' +
                point.getPosition().y +
                ')'
            }
        >
            <g style={{transform: 'rotate(' + angle + 'deg)'}}>
                <g transform={'translate(0, -15)'}>
                    <polygon
                        points="0,10 6,30 -6,30"
                        fill={color}
                        data-id={point.getID()}
                        data-linkid={point.getLink().getID()}
                    />
                </g>
            </g>
        </g>
    );
};
