import React from 'react';
import cx from 'classnames';

interface PanelFooterProps {
    children?: any;
    style?: any;
}

/**
 * Reusable component that acts as a footer for a portal panel.
 */
export class PanelFooter extends React.Component<PanelFooterProps> {
    render() {
        const {children, style} = this.props;
        const className = cx('knowledge-base__panel-footer', {
            'knowledge-base__panel-footer--has-one-child':
                React.Children.count(children) === 1,
        });
        return (
            <footer className={className} style={style}>
                {children}
            </footer>
        );
    }
}
