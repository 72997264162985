import {API} from './Api';
import {Moment} from 'moment';

type Counters = {
    answers: number;
    missings: number;
    disambiguations: number;
    conversations: number;
    'answers.responses': number;
    'answers.chatterings': number;
    'answers.contents': number;
};

export type ExitPageMetric = {
    counters: Counters | null;
};

export type ExitPageByNode = {
    [nodeId: string]: {
        counters: Counters | null;
        node_id: string;
        node_type: string;
        status: string;
        title: string;
    };
};

export class ExitPageAPI extends API {
    constructor() {
        super();
    }

    async getExitPageMetrics(
        minDate: Moment,
        maxDate: Moment,
    ): Promise<ExitPageMetric> {
        const URL = `/v3.1/analytics/aggregated/exit_page?from=${minDate.unix()}&to=${maxDate.unix()}`;
        const response = await this.callApi(URL);
        if (response.data) {
            return response.data!;
        } else {
            throw response.error;
        }
    }

    async getExitPageByNode(
        minDate: Moment,
        maxDate: Moment,
    ): Promise<ExitPageByNode> {
        const URL = `/v3.1/analytics/aggregated/exit_page/node_id?from=${minDate.unix()}&to=${maxDate.unix()}`;
        const response = await this.callApi(URL);
        if (response.data) {
            return response.data.entries;
        } else {
            throw response.error;
        }
    }
}
