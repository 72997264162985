import {METHODS} from '../constants';

import {GET_WIDGET, UPDATE_WIDGET} from './constants';

const {GET, PATCH} = METHODS;

export const rest = {
    [GET_WIDGET]: {
        url: '/v3.1/iam/apps/:id/config',
        options: {
            method: GET,
        },
        transformer: (response) => {
            return response || {};
        },
    },
    [UPDATE_WIDGET]: {
        url: '/v3.1/iam/apps/:id/config',
        options: {
            method: PATCH,
        },
    },
};
