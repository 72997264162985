import {ACTIONS, ROWS, SELECTED_ROWS} from '../constants';
import {PAYLOAD} from '../../constants';

const initialState = {
    [SELECTED_ROWS]: [],
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SET_SELECTED_ROWS:
            return {...state, [SELECTED_ROWS]: action[PAYLOAD][ROWS]};
        case ACTIONS.ADD_SELECTED_ROWS:
            return {
                ...state,
                [SELECTED_ROWS]: state[SELECTED_ROWS].concat(
                    action[PAYLOAD][ROWS],
                ),
            };
        case ACTIONS.REMOVE_SELECTED_ROWS:
            return {
                ...state,
                [SELECTED_ROWS]: state[SELECTED_ROWS].filter((row) => {
                    if (action[PAYLOAD][ROWS].includes(row)) {
                        return false;
                    }
                    return true;
                }),
            };
        case ACTIONS.RESET_SELECTED_ROWS:
            return {...state, [SELECTED_ROWS]: []};
        default:
            return state;
    }
};
