import React from 'react';

export const DialpadAIGradient = () => (
    <svg
        width="14"
        height="11.27"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.2502 3.41094C17.0785 3.41094 17.75 2.73944 17.75 1.9111C17.75 1.08276 17.0785 0.411255 16.2502 0.411255C15.4218 0.411255 14.7503 1.08276 14.7503 1.9111C14.7503 2.73944 15.4218 3.41094 16.2502 3.41094Z"
            fill="url(#paint0_linear_2333_7487)"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.65398 3.06299C6.31416 3.06299 5.72913 3.3867 5.42482 4.38022L5.42421 4.3822L2.54199 13.6749C2.35022 14.2932 1.69353 14.639 1.07523 14.4472C0.456923 14.2554 0.111153 13.5987 0.302925 12.9804L3.18332 3.69365L3.18396 3.69154C3.65254 2.16408 4.88051 0.718693 6.65398 0.718693C8.42693 0.718693 9.65306 2.16102 10.1225 3.68614L10.1229 3.68755L12.3416 10.8406L12.3421 10.842C12.6467 11.8328 13.2306 12.1555 13.5702 12.1555C14.403 12.1555 15.0779 11.4806 15.0779 10.6478V5.75044C15.0779 5.10308 15.6027 4.57829 16.2501 4.57829C16.8974 4.57829 17.4222 5.10308 17.4222 5.75044V10.6478C17.4222 12.7753 15.6977 14.4998 13.5702 14.4998C11.7991 14.4998 10.5714 13.0581 10.1018 11.5326L10.1013 11.531L7.88253 4.37788L7.88211 4.37649C7.57736 3.38528 6.99461 3.06299 6.65398 3.06299Z"
            fill="url(#paint1_linear_2333_7487)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_2333_7487"
                x1="8.83627"
                y1="1.89083"
                x2="8.83627"
                y2="13.3277"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F40FB9" />
                <stop offset="1" stopColor="#7C52FF" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_2333_7487"
                x1="8.83627"
                y1="1.89083"
                x2="8.83627"
                y2="13.3277"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F40FB9" />
                <stop offset="1" stopColor="#7C52FF" />
            </linearGradient>
        </defs>
    </svg>
);
