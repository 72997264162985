import {calcRatio} from './Common';
import {FeedbackAggregatedResponse} from '@app/types/Feedback';

export const transformAggregatedFeedbackDataToRadialChart = (
    data: FeedbackAggregatedResponse,
) => {
    const counters = data.counters
        ? data.counters
        : {
              helpful: 0,
              unhelpful: 0,
              total: 0,
          };
    const helpful = counters.helpful;
    const unhelpful = counters.unhelpful;
    const total = counters.total;
    const helpfulRatio = calcRatio(helpful, total);
    const unHelpfulRatio = calcRatio(unhelpful, total);
    return {
        plotData:
            total > 0
                ? {
                      helpful: helpfulRatio,
                      unhelpful: unHelpfulRatio,
                  }
                : {nodata: 100},
        legendData: {
            helpful: helpfulRatio,
            unhelpful: unHelpfulRatio,
        },
    };
};
