import React, {Component} from 'react';
import cx from 'classnames';
import {get} from 'lodash';
import ScrollBar from 'react-perfect-scrollbar';

import {KareResponseRenderer} from '@app/common';
import {querystring} from '@app/utils';
import {redux} from '@app/redux';
import {AnotherResponse, Clone, Edit} from '@app/common/icons';
import {
    InitialResponseFormDataPropType,
    ResponseFormDataPropType,
} from '../../proptypes';
import {isNewResponse} from '@app/redux/improve/responses/selectors';
import {connect} from 'react-redux';
import {getCluster} from '@app/redux/improve/cards/selectors';
import {AUTO_ANSWER_CLUSTER} from '@app/redux/improve/constants';
import {ClusterPropType} from '@app/prop-types/cluster';
import {withRouter} from 'react-router';
import {IMPROVE_RESET_SELECTED_RESPONSE} from '@app/redux/improve/responses/constants';

interface ResponseViewerProps {
    responseFormData?: ResponseFormDataPropType;
    initialValues?: InitialResponseFormDataPropType;
    cluster?: ClusterPropType;
    chooseAnotherResponse?: any;
    isNewResponse?: boolean;
    selectedResponse?: any;
    history?: any;
    location?: any;
    outboundNodes?: any;
    responses?: any;
    documents?: any;
}

export class ResponseViewerComponent extends Component<ResponseViewerProps> {
    handleEditResponse = () => {
        const {
            history: {push},
            selectedResponse,
            cluster,
        } = this.props;
        const clusterId = get(cluster, ['id']);
        if (!selectedResponse.id) {
            return push(`?compose=new&cluster=${clusterId}&auto-response=true`);
        }
        push(`?compose=${selectedResponse.id}`);
    };

    handleChooseAnotherResponse = () => {
        this.props.chooseAnotherResponse();
    };

    handleClickClone = () => {
        const {
            history: {push},
            location,
            selectedResponse,
        } = this.props;
        push(querystring.getCloneResponsePath(location, selectedResponse.id));
    };

    showCloneButton = () =>
        !this.props.isNewResponse &&
        this.props.cluster.cluster_type !== AUTO_ANSWER_CLUSTER;

    render() {
        const {selectedResponse, outboundNodes, responses, documents} =
            this.props;
        return (
            <div className={cx('selected-response', 'response-viewer')}>
                <div className="query-response__viewer-header-title">
                    Your response
                </div>
                <ScrollBar>
                    <KareResponseRenderer
                        response={selectedResponse}
                        nodes={[...outboundNodes, ...responses, ...documents]}
                    />
                </ScrollBar>
                <div className="response-viewer__footer">
                    <div
                        className="response-viewer__footer-choose-another-response"
                        onClick={this.handleChooseAnotherResponse}
                    >
                        <AnotherResponse /> Choose another response
                    </div>
                    {this.showCloneButton() && (
                        <div
                            className={'response-viewer__footer-clone-response'}
                            onClick={this.handleClickClone}
                        >
                            <Clone />
                            Clone
                        </div>
                    )}
                    <div
                        className="response-viewer__footer-edit"
                        onClick={this.handleEditResponse}
                    >
                        <Edit size={12} /> Edit
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        cluster: getCluster(state),
        isNewResponse: isNewResponse(state),
        selectedResponse:
            redux.responses.selectors.improvePageResponseSelector(state),
        outboundNodes:
            redux.responses.selectors.improvePageOutboundNodesSelector(state),
        responses:
            redux.knowledgeBase.selectors.publishedResponsesSelector(state),
        documents:
            redux.knowledgeBase.selectors.publishedDocumentsSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    chooseAnotherResponse: () =>
        dispatch({type: IMPROVE_RESET_SELECTED_RESPONSE}),
});

export const ResponseViewer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ResponseViewerComponent),
);
