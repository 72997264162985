import Axios from 'axios';
import {LibraryResponse} from '@app/types/Library';

export class Library {
    cache = {};

    constructor() {}

    storeLibraryData = (URL: string, data: LibraryResponse) => {
        this.cache[URL] = data;
    };

    async getLibrary(forceRefresh = false) {
        const URL = `/v3.0/kbm/nodes/stats`;
        if (this.cache[URL] && !forceRefresh) {
            return {data: this.cache[URL], error: null};
        }
        const res = {
            data: null,
            error: null,
        };
        try {
            const req = await Axios.get(URL);
            res.data = req.data;
            this.storeLibraryData(URL, req.data);
        } catch (e) {
            res.error = e;
        }

        return res;
    }
    async getTags(forceRefresh = false) {
        const URL = `/v3.0/kbm/tags`;
        if (this.cache[URL] && !forceRefresh) {
            return {data: this.cache[URL], error: null};
        }
        const res = {
            data: null,
            error: null,
        };
        try {
            const req = await Axios.get(URL);
            res.data = req.data;
            this.storeLibraryData(URL, req.data);
        } catch (e) {
            res.error = e;
        }

        return res;
    }
}
