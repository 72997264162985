import React from 'react';
import cx from 'classnames';

import './ChartLegend.scss';
import {formatThousands} from '@app/utils/Common';

export type LegendItem = {
    color: string;
    title: string;
    value?: number;
    percentage?: number;
};

export interface ChartLegendProps {
    items: LegendItem[];
    oneLineLegend?: boolean;
}

export const ChartLegend: React.FC<ChartLegendProps> = (props) => {
    return (
        <div className="ChartLegend__legendContainer">
            <ul>
                {props.items.map((item) => {
                    const classnames = cx({
                        ['ChartLegend__itemOneLine']: props.oneLineLegend,
                    });
                    return (
                        <li className={classnames} key={item.title}>
                            <div
                                className="ChartLegend__icon"
                                style={{backgroundColor: item.color}}
                            />
                            <span>{item.title}</span>
                            {item.value !== undefined &&
                                item.percentage !== undefined && (
                                    <LegendItemValueAndPercentage
                                        value={item.value}
                                        percentage={item.percentage}
                                    />
                                )}
                            {item.value == undefined &&
                                item.percentage !== undefined && (
                                    <LegendItemPercentage
                                        percentage={item.percentage}
                                    />
                                )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

interface LegendItemValueAndPercentageProps {
    value: number;
    percentage: number;
}
const LegendItemValueAndPercentage: React.FC<
    LegendItemValueAndPercentageProps
> = (props) => {
    return (
        <React.Fragment>
            <strong>{formatThousands(props.value)}</strong>
            <small>({`${props.percentage} %`})</small>
        </React.Fragment>
    );
};

interface LegendItemPercentageProps {
    percentage: number;
}
const LegendItemPercentage: React.FC<LegendItemPercentageProps> = (props) => {
    return (
        <React.Fragment>
            <strong>{`${props.percentage} %`}</strong>
        </React.Fragment>
    );
};
