import React from 'react';

interface SpinnerProps {
    size?: number;
    border?: number;
    color?: string;
    withCenteredContainer?: boolean;
}

export class Spinner extends React.Component<SpinnerProps> {
    renderSpinner() {
        const size = this.props.size || 100;
        const color = this.props.color || '#3A1C95';
        const border = this.props.border || 6;

        const spinnerStyle = {
            width: `${size}px`,
            height: `${size}px`,
        };
        const innerSpinnerStyle = {
            width: `${size - border}px`,
            height: `${size - border}px`,
            border: `${border}px solid ${color}`,
            borderColor: `${color} transparent transparent transparent`,
        };
        return (
            <div className="c-spinner" style={spinnerStyle}>
                <div style={innerSpinnerStyle} />
                <div style={innerSpinnerStyle} />
                <div style={innerSpinnerStyle} />
                <div style={innerSpinnerStyle} />
            </div>
        );
    }

    render() {
        if (this.props.withCenteredContainer) {
            return (
                <div className="c-spinner-centered-container">
                    {this.renderSpinner()}
                </div>
            );
        }
        return this.renderSpinner();
    }
}
