import React from 'react';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import cx from 'classnames';
import {reset} from 'redux-form';

import {redux} from '@app/redux';
import {NODE_STATUSES, STATUS} from '@app/constants';
import {Button, ErrorBoundary, KareError, KareStatusTabs} from '@app/common';
import {NoResponses} from './no-responses';
import {ResponsesSearch} from './responses-search';
import {querystring} from '@app/utils';
import {ResponsesTable} from './responses-table';
import {getTableItems} from './utils';
import {Spinner} from '@app/common';
import {get} from 'lodash';
import {withRouter} from 'react-router-dom';
import {deserializeQs} from '@app/utils/url';
import {GET_ATTACHED_DOCUMENT_FIELD_DOCUMENT} from '@app/redux/documents/constants';
import {RESPONSE_FORM} from '@app/redux/improve/responses/constants';

interface ResponsesProps {
    searchKnowledgeBase?: any;
    getResponses?: any;
    deleteResponse?: any;
    resetSelectedRows: any;
    isLoading?: boolean;
    history?: any;
    query?: string;
    contentLocale?: string;
    responses?: any[];
    responsesLoading?: boolean;
    location?: any;
    getAllPublishedResponses?: any;
    getAllArchivedResponses?: any;
    getAllDraftResponses?: any;
    publishedResponses?: any[];
    archivedResponses?: any[];
    draftResponses?: any;
    status?: string;
    allContentError?: string;
    resetResponseForm?: any;
}

/**
 * Responses listing component.
 */
export class ResponsesComponent extends React.Component<ResponsesProps> {
    state = {
        labelFilter: null,
        labelsToFilter: [],
        preFilledLabel: null,
    };

    setLabelsToFilter = (labelsToFilter) => {
        this.setState({
            labelsToFilter,
        });
    };

    removeAllLabels = () => {
        this.setState({
            labelsToFilter: [],
        });
    };

    componentDidMount() {
        this.getSelectedresponses(
            querystring.getStatus(this.props.location.search),
        );
    }

    componentDidUpdate(prevProps) {
        const newStatus = querystring.getStatus(this.props.location.search);
        const oldStatus = querystring.getStatus(prevProps.location.search);
        const {contentLocale} = this.props;
        const {contentLocale: prevContentLocale} = prevProps;
        if (contentLocale !== prevContentLocale || newStatus !== oldStatus) {
            this.getSelectedresponses(newStatus);
        }
        const previousPreFilledLabel = get(
            deserializeQs(prevProps.location.search),
            ['labelFilter'],
        );
        const preFilledLabel = get(deserializeQs(this.props.location.search), [
            'labelFilter',
        ]);
        const prefilledStatus = get(
            deserializeQs(this.props.location.search),
            ['status'],
            'published',
        );

        if (
            previousPreFilledLabel !== preFilledLabel &&
            preFilledLabel &&
            preFilledLabel.length > 0
        ) {
            this.setState(
                {
                    labelsToFilter: [{name: preFilledLabel}],
                },
                () => {
                    this.props.history.push(
                        `/responses?status=${prefilledStatus}`,
                    );
                },
            );
        }
    }

    getSelectedresponses(filter) {
        const {
            getAllPublishedResponses,
            getAllArchivedResponses,
            getAllDraftResponses,
        } = this.props;

        switch (filter) {
            case NODE_STATUSES.ARCHIVED:
                return getAllArchivedResponses();
            case NODE_STATUSES.DRAFT:
                return getAllDraftResponses();
            case NODE_STATUSES.PUBLISHED:
                return getAllPublishedResponses();
            default:
                return getAllPublishedResponses();
        }
    }

    handleClickShowActive = () => {
        this.removeAllLabels();
        this.props.resetSelectedRows();
        this.props.history.push(`?${STATUS}=${NODE_STATUSES.PUBLISHED}`);
    };

    handleClickShowArchived = () => {
        this.removeAllLabels();
        this.props.resetSelectedRows();
        this.props.history.push(`?${STATUS}=${NODE_STATUSES.ARCHIVED}`);
    };

    handleClickShowDraft = () => {
        this.removeAllLabels();
        this.props.resetSelectedRows();
        this.props.history.push(`?${STATUS}=${NODE_STATUSES.DRAFT}`);
    };

    handleClickNewResponse = () => {
        const {
            history: {push},
            location,
            resetResponseForm,
        } = this.props;

        resetResponseForm();
        push(querystring.getNewResponsePath(location));
    };

    render() {
        const {labelsToFilter} = this.state;
        const {
            responsesLoading,
            publishedResponses,
            archivedResponses,
            draftResponses,
            deleteResponse,
            getResponses,
            history,
            query,
            location,
            status,
            allContentError,
            isLoading,
        } = this.props;
        const className = cx('knowledge-base__container', {
            'knowledge-base__container--loading': responsesLoading,
        });
        let responses = publishedResponses;
        if (status === NODE_STATUSES.DRAFT) {
            responses = draftResponses;
        }
        if (status === NODE_STATUSES.ARCHIVED) {
            responses = archivedResponses;
        }
        const items = getTableItems(query, responses, labelsToFilter);

        const statusTabs = [
            {
                title: 'Published',
                onClick: this.handleClickShowActive,
                isActive: status === NODE_STATUSES.PUBLISHED,
                position: 'LEFT',
            },
            {
                title: 'Draft',
                onClick: this.handleClickShowDraft,
                isActive: status === NODE_STATUSES.DRAFT,
                position: 'LEFT',
            },
            {
                title: 'Archived',
                onClick: this.handleClickShowArchived,
                isActive: status === NODE_STATUSES.ARCHIVED,
                position: 'RIGHT',
            },
        ];
        if (allContentError) {
            return <KareError error={allContentError} />;
        }
        if (isLoading) {
            return <Spinner withCenteredContainer />;
        }
        return (
            <ErrorBoundary>
                <div className={className}>
                    <Helmet title="Responses" />
                    <header className="knowledge-base__toolbar">
                        <ResponsesSearch
                            labelsToFilter={labelsToFilter}
                            query={query}
                            setLabelsToFilter={this.setLabelsToFilter}
                            removeAllLabels={this.removeAllLabels}
                            getResponses={getResponses}
                            showingArchived={status === NODE_STATUSES.ARCHIVED}
                            showingDraft={status === NODE_STATUSES.DRAFT}
                            showingActive={status === NODE_STATUSES.PUBLISHED}
                            responsesCount={responses.length}
                            handleClickShowActive={this.handleClickShowActive}
                            matchingItemsCount={items.length}
                        />
                        <Button
                            className="dialpad-sidebar-new-response"
                            onClick={this.handleClickNewResponse}
                        >
                            New Response
                        </Button>
                    </header>
                    <KareStatusTabs tabs={statusTabs} />

                    {responses.length === 0 && !responsesLoading ? (
                        <NoResponses history={history} location={location} />
                    ) : (
                        <ResponsesTable
                            history={history}
                            items={items}
                            deleteResponse={deleteResponse}
                            isLoading={responsesLoading}
                        />
                    )}
                </div>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state, props) => ({
    contentLocale: redux.locale.selectors.contentLocaleSelector(state),
    query: redux.knowledgeBase.selectors.searchQuerySelector(state),
    publishedResponses:
        redux.knowledgeBase.selectors.publishedResponsesSelector(state),
    archivedResponses:
        redux.knowledgeBase.selectors.archivedResponsesSelector(state),
    draftResponses: redux.knowledgeBase.selectors.draftResponsesSelector(state),
    allContentError:
        redux.knowledgeBase.selectors.allContentErrorSelector(state),
    responsesLoading:
        redux.knowledgeBase.selectors.publishedResponsesLoadingSelector(
            state,
        ) ||
        redux.knowledgeBase.selectors.archivedResponsesLoadingSelector(state) ||
        redux.knowledgeBase.selectors.draftResponsesLoadingSelector(state),
    status: querystring.getStatus(props.location.search),
    isLoading:
        redux.org.selectors.isOrgLoadingSelector(state) ||
        redux.application.selectors.isApplicationLoadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    searchKnowledgeBase: (query) =>
        dispatch(redux.knowledgeBase.actions.searchKnowledgeBase(query)),
    getAllPublishedResponses:
        redux.knowledgeBase.dispatchers.getAllPublishedResponsesDispatcher(
            dispatch,
        ),
    getAllArchivedResponses:
        redux.knowledgeBase.dispatchers.getAllArchivedResponsesDispatcher(
            dispatch,
        ),
    getAllDraftResponses:
        redux.knowledgeBase.dispatchers.getAllDraftResponsesDispatcher(
            dispatch,
        ),
    deleteResponse: (id) => dispatch(redux.rest.actions.deleteResponse({id})),
    resetResponseForm: () => {
        dispatch(
            redux.rest.actions[GET_ATTACHED_DOCUMENT_FIELD_DOCUMENT].reset(),
        );
        dispatch(reset(RESPONSE_FORM));
    },
    resetSelectedRows: () =>
        dispatch(redux.responsesTable.actionCreators.resetSelectedRows()),
});

export const Responses = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ResponsesComponent),
);
