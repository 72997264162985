import React from 'react';
import get from 'lodash/get';

interface MessageCTAProps {
    message: any;
}

export const MessageCTA = (props: MessageCTAProps) => {
    const {message} = props;
    const description = get(message, ['event_body', 'out_text', 'text'], '');
    const buttonText = get(
        message,
        ['event_body', 'out_text', 'actions', '0', 'text'],
        '',
    );
    const button = get(message, ['event_body', 'out_text', 'actions', '0'], '');
    const href = get(button, ['on_click', '0', 'params', 'href'], '');
    return (
        <div
            className={
                'message-cta__container console-activity--out console-activity-message-container'
            }
        >
            <div className={'message-cta__body'}>
                <p>{description}</p>
                <a href={href} target="_blank" rel="noreferrer">
                    {buttonText}
                </a>
            </div>
        </div>
    );
};
