import {get} from 'lodash';
import {
    GET_ACTIVITY,
    GET_RESPONSE_COUNTERS,
    GET_RESPONSE_QUERIES,
    RESPONSE_COUNTERS,
    RESPONSE_QUERIES,
    ACTIVITY,
    ACTIVE_RESPONSE_ID,
} from './constants';
import {DATA, LOADING, SYNC} from '../constants';

import {DASHBOARD_FORM} from '../../dashboard/constants';
import {getInitialDateValues} from '../../dashboard/utils';

export const activitySelector = (state) => {
    return get(state, [GET_ACTIVITY, DATA]);
};

export const isActivityLoadingSelector = (state) => {
    return get(state, [GET_ACTIVITY, LOADING]);
};

export const isActivitySyncedSelector = (state) => {
    return get(state, [GET_ACTIVITY, SYNC]);
};
export const responseCountersSelector = (state) => {
    return get(state, [GET_RESPONSE_COUNTERS, DATA, RESPONSE_COUNTERS]);
};
export const responseQueriesSelector = (state) => {
    return get(state, [GET_RESPONSE_QUERIES, DATA, RESPONSE_QUERIES]);
};
export const analyticsDateRangeSelector = (state) => {
    const from = get(
        state,
        ['form', DASHBOARD_FORM, 'values', 'from'],
        getInitialDateValues().from,
    );
    const to = get(
        state,
        ['form', DASHBOARD_FORM, 'values', 'to'],
        getInitialDateValues().to,
    );
    const analyticsDateRange = {
        from: from.unix(),
        to: to.unix(),
    };
    return analyticsDateRange;
};

export const isResponseCountersLoadingSelector = (state) => {
    return get(state, [GET_RESPONSE_COUNTERS, LOADING]);
};

export const isResponseQueriesLoadingSelector = (state) => {
    return get(state, [GET_RESPONSE_QUERIES, LOADING]);
};

export const activeResponseIdSelector = (state) => {
    return get(state, [ACTIVITY, ACTIVE_RESPONSE_ID]);
};
