import React, {Component} from 'react';
import {get} from 'lodash';
import cx from 'classnames';
import {withRouter} from 'react-router';

import {Document} from '@app/common/icons';
import {querystring} from '@app/utils';
import {getTimestamp} from '../utils/timestamp';
import {DocumentPortal} from '@app/documents/document-portal';
import {MdEdit} from 'react-icons/md';
import {isAllowed} from '@app/with-permissions';
import {PERMISSION_KEYS} from '@app/constants';

interface MessageAnswerProps {
    message: any;
    history: any;
    location: any;
    isHovering: boolean;
    isResponse: boolean;
    onMouseEnter: any;
    onMouseLeave: any;
    requiredPermissions: any[];
    scopes: any[];
    typeMessage: string;
}

export class MessageAnswerComponent extends Component<MessageAnswerProps> {
    state = {
        openDocument: false,
        documentId: '',
    };

    getResponseId = () => {
        const {message} = this.props;
        return get(message, 'headers.resource_ids');
    };

    handleClickResponse = (event, message, isResponse, typeMessage) => {
        event.preventDefault();
        const {
            history: {push},
            location,
        } = this.props;
        const responseId = this.getResponseId();
        if (responseId) {
            if (typeMessage === 'response' || typeMessage === 'chattering') {
                push(querystring.getEditResponsePath(location, responseId));
            } else {
                this.setState({
                    openDocument: true,
                    documentId: responseId,
                });
            }
        }
    };

    getAttachment = (message) => {
        const hasAttachment = get(message, 'body.actions[0].icon');
        if (hasAttachment && hasAttachment === 'attachment') {
            const attachmentAction = get(message, 'body.actions[0]');
            const attachmentLink = get(
                attachmentAction,
                'on_click[2].params.href',
            );
            const attachmentText = get(attachmentAction, 'text');
            return (
                <div className="activity-message-answer-attachment">
                    <a href={attachmentLink} target="_blank" rel="noreferrer">
                        <Document size={16} /> {attachmentText}
                    </a>
                </div>
            );
        }
    };

    render() {
        const {message, isHovering, onMouseEnter, onMouseLeave, isResponse} =
            this.props;

        const timestamp = getTimestamp(message);
        const text = message.body?.text;
        const className = cx(
            'console-activity-message',
            'message-user-choice',
            'message-user-choice__response',
        );

        // TODO Backend does not return the text of the response anymore
        // so for now we just show the "Edit original response message
        if (text) {
            return null;
        }
        return (
            <div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className={className}
            >
                <div className="message-edit-response" style={{width: '100%'}}>
                    {/*<div className="activity-message-answer-container">
            <span>
              <Markdown>ss</Markdown>
            </span>
            {this.getAttachment(message)}
          </div>*/}
                    {this.getResponseId() &&
                        // Only show the edit button to users with WRITE permission.
                        isAllowed(
                            [PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE],
                            this.props.scopes,
                        ) && (
                            <small className="message-answer__edit">
                                <a
                                    onClick={(e) => {
                                        this.handleClickResponse(
                                            e,
                                            message,
                                            isResponse,
                                            this.props.typeMessage,
                                        );
                                    }}
                                >
                                    <MdEdit />
                                    {(this.props.typeMessage === 'response' ||
                                        this.props.typeMessage ===
                                            'chattering') &&
                                        'Edit original response'}
                                    {this.props.typeMessage === 'content' &&
                                        'See content'}
                                </a>
                            </small>
                        )}
                </div>
                {isHovering && (
                    <time className="console-activity-message-timestamp">
                        {timestamp}
                    </time>
                )}
                {this.state.openDocument && this.state.documentId !== '' && (
                    <DocumentPortal
                        documentId={this.state.documentId}
                        onClose={() => {
                            this.setState({
                                openDocument: false,
                                documentId: '',
                            });
                        }}
                    />
                )}
            </div>
        );
    }
}

export const MessageAnswer = withRouter(MessageAnswerComponent);
