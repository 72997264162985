import React from 'react';
import {Field} from 'redux-form';
import {reduxForm} from 'redux-form';
import {Button, KareTextInput} from '@app/common';
import {validators} from '@app/utils';
import {Prompt} from 'react-router-dom';
import {MdClose} from 'react-icons/md';

interface ChangeEmailFormProps {
    handleSubmit?: any;
    pristine?: boolean;
    dirty?: boolean;
    submitting?: boolean;
    error?: string;
    onCloseModal: any;
}

export class ChangeEmailFormComponent extends React.Component<ChangeEmailFormProps> {
    render() {
        const {handleSubmit, pristine, error, dirty, submitting, onCloseModal} =
            this.props;
        return (
            <form
                onSubmit={handleSubmit}
                className="settings__password-reset-form"
            >
                <h1>
                    Change email{' '}
                    <MdClose
                        onClick={() => {
                            onCloseModal();
                        }}
                    />
                </h1>
                <Prompt
                    message="Discard unsaved changes?"
                    when={dirty && !submitting}
                />
                {error && <p className="kare-text-error">{error}</p>}
                <p>
                    Your email address is also your login username. To authorise
                    this change you need to verify your email address by
                    clicking the link you’ll receive to your new email.
                </p>
                <Field
                    name="email"
                    component={KareTextInput}
                    placeholder="Email"
                    validate={validators.email}
                    type="text"
                    props={{tight: true}}
                />
                <Field
                    name="passwordEmail"
                    component={KareTextInput}
                    placeholder="Repeat Email"
                    validate={validators.emailMatchValidation}
                    type="text"
                    props={{tight: true}}
                />
                <section className="submit-button-container">
                    <Button disabled={pristine} onClick={handleSubmit}>
                        Update
                    </Button>
                </section>
            </form>
        );
    }
}

export const ChangeEmailForm = reduxForm({
    form: 'CHANGE_EMAIL',
})(ChangeEmailFormComponent);
