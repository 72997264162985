import React from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css'; // for ES6 modules

interface KareSwitchProps {
    label?: string;
    input: any;
    disabled?: boolean;
}

export class KareSwitch extends React.Component<KareSwitchProps> {
    render() {
        const {label, input, disabled} = this.props;

        return (
            <label className="kare-switch">
                <Toggle
                    checked={!!input.value}
                    onChange={input.onChange}
                    icons={false}
                    disabled={disabled}
                />
                <span>{label}</span>
            </label>
        );
    }
}
