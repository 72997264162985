import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getSubdomainFromURL} from '@app/utils/client-url';
import {getZone} from '@app/zone';
import {Dialpad} from '@app/assets/icons';
import {StyledLink, LoginTitle} from './authorize.styles';
import {getStartUrl} from './utils';

const DialpadOAuthLoginComponent = (props) => {
    const startUrl = getStartUrl(props.provider);
    return (
        <>
            <h2 className="title">Login with Single Sign-on</h2>
            <StyledLink href={`${startUrl}`}>
                <Dialpad size={16} color="#6C3DFF" />
                <LoginTitle>Login with Dialpad</LoginTitle>
            </StyledLink>
        </>
    );
};

const mapStateToProps = () => {
    return {
        zone: getZone(),
        subdomain: getSubdomainFromURL(),
    };
};

export const DialpadLogin = withRouter(
    connect(mapStateToProps)(DialpadOAuthLoginComponent),
);
