import React from 'react';
import get from 'lodash/get';
import {DialpadAI, DialpadAIGradient} from '@app/assets/icons';
import {FEEDBACK} from '@app/dashboard/responses/queries/constants';
import {FeedbackChoice} from '@app/activity/messages/message-factory/feedback-choice';

interface MessageRTAProps {
    message: any;
    messages: any;
}

export const MessageRTA = (props: MessageRTAProps) => {
    const {message, messages} = props;

    const body = get(message, ['event_body', 'in_message'], {});
    const triggerTitle = body.trigger_id;

    const feedbackChoice = messages.find(
        (msg) =>
            msg.aspect === (FEEDBACK.POSITIVE || FEEDBACK.NEGATIVE) &&
            msg.headers.trigger_event_id === message.parent_id,
    );

    return (
        <div className="message-rta__container">
            {triggerTitle && (
                <div className="query-rta-wrapper">
                    <p className="subtitle">RTA Trigger Word</p>
                    <p className="query-rta-message-wrapper">
                        <DialpadAI />{' '}
                        <span className="query-rta-message">
                            “{triggerTitle}”
                        </span>
                    </p>
                </div>
            )}

            <div className="message-rta-wrapper">
                <div className="message-rta">
                    <p className="message-rta__title-wrapper">
                        <DialpadAIGradient />
                        <span className="message-rta__title">
                            {body.sender}
                        </span>
                    </p>
                    <pre className="message-rta-text">{body.text}</pre>
                </div>
                {feedbackChoice && (
                    <FeedbackChoice {...props} message={feedbackChoice} />
                )}
            </div>
        </div>
    );
};
