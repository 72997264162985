import {
    GET_CONVERSATION_METRICS,
    GET_FEEDBACK_METRICS,
    GET_QUERY_METRICS,
    GET_TRAFFIC_SOURCES_METRICS,
} from './constants';
import {METHODS} from '../constants';
const {GET} = METHODS;

export const rest = {
    [GET_CONVERSATION_METRICS]: {
        url: 'v3.0/analytics/timeseries/conversations/:resolution',
        options: {
            method: GET,
        },
    },
    [GET_QUERY_METRICS]: {
        url: 'v3.0/analytics/timeseries/queries/:resolution',
        options: {
            method: GET,
        },
    },
    [GET_FEEDBACK_METRICS]: {
        url: 'v3.0/analytics/timeseries/feedbacks/:resolution',
        options: {
            method: GET,
        },
    },
    [GET_TRAFFIC_SOURCES_METRICS]: {
        url: '/v3.0/analytics/aggregated/devices?from=:minDate&to=:maxDate',
        options: {
            method: GET,
        },
    },
};
