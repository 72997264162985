/**
 * List of actions for the knowledgeBase module.
 */
export const ACTIONS = {
    GET_KNOWLEDGEBASE: 'knowledgeBase/GET_KNOWLEDGEBASE',
    UPDATE_KNOWLEDGEBASE: 'knowledgeBase/UPDATE_KNOWLEDGEBASE',

    GET_APP: 'app/GET_APP',
    UPDATE_APP: 'app/UPDATE_APP',

    SEARCH_TEXT: 'knowledgeBase/SEARCH_TEXT',
    GET_LABELS: 'knowledgeBase/GET_LABELS',

    SET_ALL_CONTENT_LOADING: 'knowledgeBase/SET_ALL_CONTENT_LOADING',
    UNSET_ALL_CONTENT_LOADING: 'knowledgeBase/UNSET_ALL_CONTENT_LOADING',
    SET_ALL_CONTENT_ERROR: 'knowledgeBase/SET_ALL_CONTENT_ERROR',
    UNSET_ALL_CONTENT_ERROR: 'knowledgeBase/UNSET_ALL_CONTENT_ERROR',

    SET_PUBLISHED_CHATTER_RESPONSES:
        'knowledgeBase/SET_PUBLISHED_CHATTER_RESPONSES',
    SET_PUBLISHED_CHATTER_RESPONSES_LOADING:
        'knowledgeBase/SET_PUBLISHED_CHATTER_RESPONSES_LOADING',
    UNSET_PUBLISHED_CHATTER_RESPONSES_LOADING:
        'knowledgeBase/UNSET_PUBLISHED_CHATTER_RESPONSES_LOADING',
    SET_PUBLISHED_CHATTER_RESPONSES_ERROR:
        'knowledgeBase/SET_PUBLISHED_CHATTER_RESPONSES_ERROR',
    UNSET_PUBLISHED_CHATTER_RESPONSES_ERROR:
        'knowledgeBase/UNSET_PUBLISHED_CHATTER_RESPONSES_ERROR',

    SET_PUBLISHED_RESPONSES: 'knowledgeBase/SET_PUBLISHED_RESPONSES',
    SET_PUBLISHED_RESPONSES_LOADING:
        'knowledgeBase/SET_PUBLISHED_RESPONSES_LOADING',
    UNSET_PUBLISHED_RESPONSES_LOADING:
        'knowledgeBase/UNSET_PUBLISHED_RESPONSES_LOADING',
    SET_PUBLISHED_RESPONSES_ERROR:
        'knowledgeBase/SET_PUBLISHED_RESPONSES_ERROR',
    UNSET_PUBLISHED_RESPONSES_ERROR:
        'knowledgeBase/UNSET_PUBLISHED_RESPONSES_ERROR',

    SET_ARCHIVED_RESPONSES: 'knowledgeBase/SET_ARCHIVED_RESPONSES',
    SET_ARCHIVED_RESPONSES_LOADING:
        'knowledgeBase/SET_ARCHIVED_RESPONSES_LOADING',
    UNSET_ARCHIVED_RESPONSES_LOADING:
        'knowledgeBase/UNSET_ARCHIVED_RESPONSES_LOADING',
    SET_ARCHIVED_RESPONSES_ERROR: 'knowledgeBase/SET_ARCHIVED_RESPONSES_ERROR',
    UNSET_ARCHIVED_RESPONSES_ERROR:
        'knowledgeBase/UNSET_ARCHIVED_RESPONSES_ERROR',

    SET_DRAFT_RESPONSES: 'knowledgeBase/SET_DRAFT_RESPONSES',
    SET_DRAFT_RESPONSES_LOADING: 'knowledgeBase/SET_DRAFT_RESPONSES_LOADING',
    UNSET_DRAFT_RESPONSES_LOADING:
        'knowledgeBase/UNSET_DRAFT_RESPONSES_LOADING',
    SET_DRAFT_RESPONSES_ERROR: 'knowledgeBase/SET_DRAFT_RESPONSES_ERROR',
    UNSET_DRAFT_RESPONSES_ERROR: 'knowledgeBase/UNSET_DRAFT_RESPONSES_ERROR',

    SET_PUBLISHED_DOCUMENTS: 'knowledgeBase/SET_PUBLISHED_DOCUMENTS',
    SET_PUBLISHED_DOCUMENTS_LOADING:
        'knowledgeBase/SET_PUBLISHED_DOCUMENTS_LOADING',
    UNSET_PUBLISHED_DOCUMENTS_LOADING:
        'knowledgeBase/UNSET_PUBLISHED_DOCUMENTS_LOADING',
    SET_PUBLISHED_DOCUMENTS_ERROR:
        'knowledgeBase/SET_PUBLISHED_DOCUMENTS_ERROR',
    UNSET_PUBLISHED_DOCUMENTS_ERROR:
        'knowledgeBase/UNSET_PUBLISHED_DOCUMENTS_ERROR',

    SET_ARCHIVED_DOCUMENTS: 'knowledgeBase/SET_ARCHIVED_DOCUMENTS',
    SET_ARCHIVED_DOCUMENTS_LOADING:
        'knowledgeBase/SET_ARCHIVED_DOCUMENTS_LOADING',
    UNSET_ARCHIVED_DOCUMENTS_LOADING:
        'knowledgeBase/UNSET_ARCHIVED_DOCUMENTS_LOADING',
    SET_ARCHIVED_DOCUMENTS_ERROR: 'knowledgeBase/SET_ARCHIVED_DOCUMENTS_ERROR',
    UNSET_ARCHIVED_DOCUMENTS_ERROR:
        'knowledgeBase/UNSET_ARCHIVED_DOCUMENTS_ERROR',

    // Related documents
    SET_RELATED_DOCUMENTS: 'knowledgeBase/SET_RELATED_DOCUMENTS',
    SET_RELATED_DOCUMENTS_LOADING:
        'knowledgeBase/SET_RELATED_DOCUMENTS_LOADING',
    UNSET_RELATED_DOCUMENTS_LOADING:
        'knowledgeBase/UNSET_RELATED_DOCUMENTS_LOADING',
    SET_RELATED_DOCUMENTS_ERROR: 'knowledgeBase/SET_RELATED_DOCUMENTS_ERROR',
    UNSET_RELATED_DOCUMENTS_ERROR:
        'knowledgeBase/UNSET_RELATED_DOCUMENTS_ERROR',

    // Inbound nodes
    SET_INBOUND_NODES: 'knowledgeBase/SET_INBOUND_NODES',
    SET_INBOUND_NODES_LOADING: 'knowledgeBase/SET_INBOUND_NODES_LOADING',
    UNSET_INBOUND_NODES_LOADING: 'knowledgeBase/UNSET_INBOUND_NODES_LOADING',
    SET_INBOUND_NODES_ERROR: 'knowledgeBase/SET_INBOUND_NODES_ERROR',
    UNSET_INBOUND_NODES_ERROR: 'knowledgeBase/UNSET_INBOUND_NODES_ERROR',
};

export const GET_LABELS = 'getLabels';

export const UPLOAD_ASSET = 'uploadAsset';

export const RESPONSES = 'RESPONSES';

export const DYNAMIC_KEYS = {
    PUBLISHED_CHATTER_RESPONSES: 'PUBLISHED_CHATTER_RESPONSES',
    PUBLISHED_RESPONSES: 'PUBLISHED_RESPONSES',
    ARCHIVED_RESPONSES: 'ARCHIVED_RESPONSES',
    DRAFT_RESPONSES: 'DRAFT_RESPONSES',
    PUBLISHED_DOCUMENTS: 'PUBLISHED_DOCUMENTS',
    ARCHIVED_DOCUMENTS: 'ARCHIVED_DOCUMENTS',
    RELATED_DOCUMENTS: 'RELATED_DOCUMENTS',
    GET_OUTBOUND_NODES: 'GET_OUTBOUND_NODES',
    RELATED_RESPONSES: 'RELATED_RESPONSES',
    INBOUND_NODES: 'INBOUND_NODES',
    ALL_CONTENT: 'ALL_CONTENT',
};
