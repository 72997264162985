import React from 'react';
import cx from 'classnames';
import {Message} from '@material-ui/icons';

import './ListResponses.scss';
import {listAllResponses} from './api';
import {Response, Tags} from './models';
import {LabelFilter} from '@app/components/LabelFilter/LabelFilter';
import {Button, KareTextInput} from '@app/common';

export interface ListResponsesProps {
    activeResponse: string;
    onClearResponse: () => void;
    onResponseSelected?: (response: Response) => void;
    onFullResponseSelected?: (response: Response) => void;
}

export const ListResponses: React.FC<ListResponsesProps> = (props) => {
    const [activeResponse, setActiveResponse] = React.useState<Response>();
    const [filterText, setFilterText] = React.useState('');
    const [isFilteringByLabel, setIsFilteringByLabel] = React.useState(false);
    const [tags, setTags] = React.useState<[Tags?]>([]);
    const [responses, setResponses] = React.useState([]);

    React.useEffect(() => {
        listAllResponses((responses) => {
            setResponses(responses);
        });
    }, []);
    React.useEffect(() => {
        const response = responses.find(
            (r: Response) => r.id === props.activeResponse,
        );
        if (response) {
            setActiveResponse(response);
        }
    }, [responses]);
    return (
        <div className="ListResponses__root">
            <div className="ListResponses__inputContainer">
                <KareTextInput
                    input={{
                        onChange: (e: any) => {
                            setFilterText(e.target.value);
                        },
                        value: filterText,
                    }}
                    placeholder="Search by response title"
                    meta={{}}
                />
                <Button
                    type="cancel"
                    onClick={() => {
                        setIsFilteringByLabel(!isFilteringByLabel);
                    }}
                >
                    Filter by label
                </Button>
            </div>
            {isFilteringByLabel && (
                <div className="ListResponses__tagContainer">
                    <LabelFilter
                        placeholder={'+ Add new tag'}
                        availableTags={[]}
                        selectedTags={tags}
                        setSelectedTags={setTags}
                    />
                </div>
            )}
            <div>
                <div className="ListResponses__listContainer">
                    {responses
                        .filter(
                            (r: Response) =>
                                filterText === '' ||
                                filterText === undefined ||
                                r.title.includes(filterText),
                        )
                        .filter((r: Response) => {
                            return (
                                tags.length === 0 ||
                                (r.labels &&
                                    true &&
                                    r.labels.filter(
                                        (l) =>
                                            tags.find(
                                                (t: Tags) => t.name === l,
                                            ) !== undefined,
                                    ).length === tags.length)
                            );
                        })
                        .map((response: Response) => {
                            const classname = cx('ListResponses__item', {
                                ['itemIsActive']:
                                    activeResponse?.id === response.id,
                            });
                            return (
                                <div
                                    className={classname}
                                    key={response.id}
                                    onClick={() => {
                                        setActiveResponse(response);
                                    }}
                                >
                                    <Message />
                                    {response.title}
                                </div>
                            );
                        })}
                </div>
            </div>
            <div className="ListResponses__buttons">
                <Button
                    type="cancel"
                    onClick={() => {
                        props.onClearResponse();
                    }}
                >
                    Clear
                </Button>
                <Button
                    type="confirm"
                    onClick={() => {
                        if (props.onResponseSelected && activeResponse) {
                            props.onResponseSelected(activeResponse);
                        }
                        if (props.onFullResponseSelected && activeResponse) {
                            props.onFullResponseSelected(activeResponse);
                        }
                    }}
                >
                    Assign
                </Button>
            </div>
        </div>
    );
};
