import React, {useContext, useEffect, useState} from 'react';
import {ResponsivePie} from '@nivo/pie';

import './SourcesChart.scss';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {transformLibraryDataToRadialChart} from '@app/utils/Library';
import {StoreContext} from '@app/state';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {
    getChartColor,
    getLabelFromDataKeys,
    getLegendIcon,
} from '@app/utils/DataRenderingUtils';
import {Card} from '@app/components/Card/Card';
import {RadialContentLoader} from '@app/components/ContentLoaders/RadialContentLoader';

interface ISourcesChartProps {}
const defaultInitialState = {
    data: null,
    loading: true,
    error: null,
};
export const SourcesChart: React.FunctionComponent<
    ISourcesChartProps
> = ({}) => {
    const [libraryData, setLibraryData] = useState(defaultInitialState);
    const {library} = useContext(StoreContext);

    const getLibrary = async () => {
        setLibraryData(defaultInitialState);
        const res = await library.getLibrary();
        setLibraryData({
            data: res.data,
            error: res.error,
            loading: false,
        });
    };
    useEffect(() => {
        getLibrary();
    }, []);

    const showEmptyState = () => {
        if (!libraryData.data || Object.keys(libraryData.data).length === 0) {
            return 'No data';
        }
        return '';
    };

    return (
        <ErrorLoadingWrapper
            height={'241px'}
            error={libraryData.error}
            isLoading={libraryData.loading}
            emptyState={showEmptyState()}
            CustomLoading={RadialContentLoader}
            render={() => {
                const transformedData = transformLibraryDataToRadialChart(
                    libraryData.data,
                );
                const chartData = Object.keys(transformedData.plotData).map(
                    (k) => ({
                        id: k,
                        label: k,
                        value: transformedData.plotData[k],
                        color: getChartColor(k),
                    }),
                );
                const transformedLegendData = Object.keys(
                    transformedData.legendData,
                ).map((k) => {
                    return {
                        id: k,
                        label: k,
                        value: transformedData.legendData[k],
                        color: getChartColor(k),
                    };
                });

                return (
                    <Card>
                        <CardTitle title={'Node types'} />
                        <div className="SourcesChart__content">
                            <div className="SourcesChart__chartContainer">
                                <ResponsivePie
                                    data={chartData}
                                    margin={{
                                        top: 20,
                                        right: 20,
                                        bottom: 20,
                                        left: 20,
                                    }}
                                    sortByValue={false}
                                    innerRadius={0.7}
                                    padAngle={1}
                                    colors={(c: any) => c.data.color as string}
                                    borderWidth={0}
                                    enableArcLabels={false}
                                    enableArcLinkLabels={false}
                                    isInteractive={false}
                                />
                            </div>
                            <div className="SourcesChart__legend">
                                {transformedLegendData.map((d) => {
                                    return (
                                        <div
                                            key={d.id}
                                            className="SourcesChart__labelContainer"
                                        >
                                            <div className="SourcesChart__label">
                                                {getLegendIcon(
                                                    d.id,
                                                    getChartColor(d.id),
                                                )}
                                                <span>
                                                    {getLabelFromDataKeys(
                                                        d.label,
                                                    )}
                                                </span>
                                            </div>
                                            <strong>{d.value}</strong>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Card>
                );
            }}
        />
    );
};
