export interface ApiDomain {
    host: string;
    starting_pages: string[];
    flags: Flag[] | null;
    labels: string[] | null;
    additional_robots: string | null;
    custom_agent: string | null;
    forced_locales: string[] | null;
}

export type Flag = string;

export const additionalRobots: Flag = 'use_additional_robots';
export const customAgent: Flag = 'use_custom_agent';
export const ignoreMeta: Flag = 'ignore_meta_tags';
export const ignoreLocale: Flag = 'ignore_locale';
export const forceLocale: Flag = 'force_locale';
export const forceCrawl: Flag = 'force_crawl';
export const onlyGivenPages: Flag = 'only_given_pages';
