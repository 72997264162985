import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icon-base';

export const BrandedFABIcon = (props) => {
    return (
        <Icon
            style={props.style}
            width="72px"
            height="72px"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_618_12741)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 36C6 19.4315 19.4315 6 36 6C52.5685 6 66 19.4315 66 36C66 52.5685 52.5685 66 36 66C19.4315 66 6 52.5685 6 36Z"
                    fill="evenodd"
                />
            </g>
            <path
                d="M45.977 25.5564C43.5556 25.5564 41.2261 26.4616 39.387 28.1161C37.5479 29.7705 36.3831 32.0492 36.046 34.5465V20.1561C36.046 20.1249 36.046 20.0936 36.0153 20.0624C35.9847 20.0312 35.9847 20.0312 35.954 20C35.9234 20 35.8927 20 35.8621 20C35.8314 20 35.8008 20.0312 35.7701 20.0312L29.9157 26.3368C28.6897 25.8061 27.341 25.5252 26.023 25.5252C20.4751 25.5252 16 30.2075 16 35.9824C16 41.7573 20.5057 46.4396 26.023 46.4396C28.4444 46.4396 30.7739 45.5344 32.613 43.8799C34.4521 42.2255 35.6169 39.9468 35.954 37.4495V51.8399C35.954 51.996 36.1073 52.0584 36.1992 51.9336L42.0843 45.628C43.3103 46.1587 44.659 46.4396 45.977 46.4396C51.5249 46.4396 56 41.7573 56 35.9824C56 30.2387 51.4943 25.5564 45.977 25.5564Z"
                fill="currentColor"
            />
            <defs>
                <filter
                    id="filter0_d_618_12741"
                    x="0"
                    y="0"
                    width="72"
                    height="72"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="3" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_618_12741"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_618_12741"
                        result="shape"
                    />
                </filter>
            </defs>
        </Icon>
    );
};

BrandedFABIcon.propTypes = {
    className: PropTypes.object,
    style: PropTypes.object,
};
