import React, {useState, useContext, useEffect} from 'react';
import {Grid} from '@material-ui/core';

import {Call, StoreContext} from '@app/state';
import {ExitPageByNode, ExitPageMetric} from '@app/state/ExitPage';
import {ExitByKind} from './ExitByKind';
import {ExitsAfterAnswer} from './ExitsAfterAnswer';
import {ExitsResponseTable} from './ExitsResponseTable';

export interface ExitPageProps {}

export const ExitPage: React.FC<ExitPageProps> = (props) => {
    const {exitPageApi} = useContext(StoreContext);
    const {minDate, maxDate} = useContext(StoreContext).date;
    const [exitPageMetrics, setExitPageMetrics] = useState<
        Call<ExitPageMetric | null>
    >({
        data: null,
        loading: true,
    });
    const [exitPageByNodeMetrics, setExitPageByNodeMetrics] = useState<
        Call<ExitPageByNode | null>
    >({
        data: null,
        loading: true,
    });
    const getExitPageMetrics = async () => {
        try {
            const response = await exitPageApi.getExitPageMetrics(
                minDate,
                maxDate,
            );
            setExitPageMetrics({
                error: '',
                loading: false,
                data: response,
            });
        } catch (error) {
            setExitPageMetrics({
                error: error,
                loading: false,
                data: null,
            });
        }
    };
    const getExitPageByNodeMetrics = async () => {
        try {
            const response = await exitPageApi.getExitPageByNode(
                minDate,
                maxDate,
            );
            setExitPageByNodeMetrics({
                error: '',
                loading: false,
                data: response,
            });
        } catch (error) {
            setExitPageByNodeMetrics({
                error: error,
                loading: false,
                data: null,
            });
        }
    };
    useEffect(() => {
        getExitPageMetrics();
        getExitPageByNodeMetrics();
    }, [minDate, maxDate]);
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <ExitByKind metrics={exitPageMetrics} />
            </Grid>
            <Grid item xs={6}>
                <ExitsAfterAnswer metrics={exitPageMetrics} />
            </Grid>
            <Grid item xs={12}>
                <ExitsResponseTable metrics={exitPageByNodeMetrics} />
            </Grid>
        </Grid>
    );
};
