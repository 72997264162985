import {
    DECREASE_SELECTED_CLUSTER_INDEX,
    INCREASE_SELECTED_CLUSTER_INDEX,
    REMOVE_INDEX_FROM_CLUSTERS,
    RESET_SELECTED_CLUSTER_INDEX,
} from '../constants';

export const increaseSelectedCardIndexAction = () => ({
    type: INCREASE_SELECTED_CLUSTER_INDEX,
});
export const decreaseSelectedCardIndexAction = () => ({
    type: DECREASE_SELECTED_CLUSTER_INDEX,
});

export const resetSelectedClusterIndexAction = () => ({
    type: RESET_SELECTED_CLUSTER_INDEX,
});
export const removeIndexFromClustersAction = () => ({
    type: REMOVE_INDEX_FROM_CLUSTERS,
});
