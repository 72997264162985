import React from 'react';
import Icon from 'react-icon-base';

export const ReadReviews = (props) => {
    return (
        <Icon viewBox="0 0 15 15" {...props}>
            <svg
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.6 0H14.4C15.28 0 16 0.72 16 1.6V11.2C16 12.08 15.28 12.8 14.4 12.8H3.2L0 16L0.008 1.6C0.008 0.72 0.72 0 1.6 0ZM2.536 11.2H14.4V1.6H1.6V12.136L2.064 11.672L2.536 11.2ZM6.8 9.6H12.8V8H8.4L6.8 9.6ZM9.888 4.336C10.048 4.496 10.048 4.744 9.888 4.904L5.176 9.6H3.2V7.624L7.904 2.92C8.064 2.76 8.312 2.76 8.472 2.92L9.888 4.336Z"
                    fill="black"
                />
                <mask
                    id="mask0_264_82641"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="16"
                    height="16"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.6 0H14.4C15.28 0 16 0.72 16 1.6V11.2C16 12.08 15.28 12.8 14.4 12.8H3.2L0 16L0.008 1.6C0.008 0.72 0.72 0 1.6 0ZM2.536 11.2H14.4V1.6H1.6V12.136L2.064 11.672L2.536 11.2ZM6.8 9.6H12.8V8H8.4L6.8 9.6ZM9.888 4.336C10.048 4.496 10.048 4.744 9.888 4.904L5.176 9.6H3.2V7.624L7.904 2.92C8.064 2.76 8.312 2.76 8.472 2.92L9.888 4.336Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask0_264_82641)">
                    <rect width="16" height="16" fill="#0D0C0F" />
                </g>
            </svg>
        </Icon>
    );
};
