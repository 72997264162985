import React from 'react';
import {QueriesResponse} from './queries-response';
import {QueryList} from './query-list';
import {redux} from '@app/redux';
import {connect} from 'react-redux';

interface QueriesCardProps {
    selectedResponse?: any;
    resetSelectResponseState?: any;
}

export class QueriesCardComponent extends React.Component<QueriesCardProps> {
    componentDidMount() {
        // this.props.resetSelectResponseState();
    }

    handleSubmit = () => {};

    render() {
        return (
            <div className="queries-card-content">
                <QueryList
                    /*@ts-ignore*/
                    onSubmit={this.handleSubmit}
                />
                <QueriesResponse />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    resetSelectResponseState:
        redux.improve.responses.dispatchers.resetSelectResponseState(dispatch),
});

export const QueriesCard = connect(
    null,
    mapDispatchToProps,
)(QueriesCardComponent);
