import React, {useState, useEffect, Fragment} from 'react';
import axios from 'axios';
import _ from 'lodash';
import {Treemap as Tmap} from 'react-vis';
import './index.scss';
import {getClusterColor} from '@app/improve/cluster-header/utils';
import {get} from 'lodash';
import {
    MISSING_KNOWLEDGE_CLUSTER,
    IMPROVE_ANSWER_CLUSTER,
    DISAMBIGUATION_CLUSTER,
} from '@app/redux/improve/constants';
import {withRouter} from 'react-router-dom';

interface TreeMapComponentProps {
    history?: any;
}

export const TreeMapComponent = (props: TreeMapComponentProps) => {
    const [treeMapData, setTreeMapData] = useState({
        title: 'treemap',
        color: 'white',
        children: [],
    });

    const createTreeMapNode = (data, color) => ({
        id: data.id,
        queries: data.queries,
        color: color,
        title: `${data.queries[0].query}`,
        size: data.queries.length,
    });

    const geatLeafColor = (colorNumber) => {
        switch (colorNumber) {
            case 0:
                return getClusterColor('missing-knowledge');
            case 1:
                return getClusterColor('improve-answer');
            case 2:
                return getClusterColor('disambiguation');
            default:
                return getClusterColor('missing-knowledge');
        }
    };

    useEffect(() => {
        axios
            .get('v3.0/improve/clusters?limit=100&next_cursor=&approved=false')
            .then((d) => {
                const entries = _.get(d, ['data', 'clusters']);
                axios
                    .all(
                        entries.map((e) =>
                            axios.get(`v3.0/improve/clusters/${e.id}`),
                        ),
                    )
                    .then((result: any) => {
                        let missing = result.filter(
                            (f) =>
                                f.data.cluster_type ===
                                MISSING_KNOWLEDGE_CLUSTER,
                        );
                        let improve = result.filter(
                            (f) =>
                                f.data.cluster_type === IMPROVE_ANSWER_CLUSTER,
                        );
                        let disambiguation = result.filter(
                            (f) =>
                                f.data.cluster_type === DISAMBIGUATION_CLUSTER,
                        );
                        missing = missing.map((x) =>
                            createTreeMapNode(x.data, 0),
                        );
                        improve = improve.map((x) =>
                            createTreeMapNode(x.data, 1),
                        );
                        disambiguation = disambiguation.map((x) =>
                            createTreeMapNode(x.data, 2),
                        );
                        const allThree = [
                            {
                                title: MISSING_KNOWLEDGE_CLUSTER,
                                color: 'white',
                                children: missing,
                            },
                            {
                                title: IMPROVE_ANSWER_CLUSTER,
                                color: 'white',
                                children: improve,
                            },
                            {
                                title: DISAMBIGUATION_CLUSTER,
                                color: 'white',
                                children: disambiguation,
                            },
                        ];

                        setTreeMapData({...treeMapData, children: allThree});
                    });
            });
    }, []);

    return (
        <div className="o-panel">
            <div className="treemap">
                <h2>Knowledge treemap</h2>
                <div className="legend">
                    <div className="legend-blocks">
                        <div className="legend-block">
                            <div
                                className="legend-dot"
                                style={{
                                    backgroundColor:
                                        getClusterColor('missing-knowledge'),
                                }}
                            />
                            <span> Missing knowledge</span>
                        </div>
                        <div className="legend-block">
                            <div
                                className="legend-dot"
                                style={{
                                    backgroundColor:
                                        getClusterColor('improve-answer'),
                                }}
                            />
                            <span>Improve Answer</span>
                        </div>
                        <div className="legend-block">
                            <div
                                className="legend-dot"
                                style={{
                                    backgroundColor:
                                        getClusterColor('disambiguation'),
                                }}
                            />
                            <span>Disambiguation</span>
                        </div>
                    </div>
                </div>

                <Tmap
                    title={'My New Treemap'}
                    width={800}
                    height={600}
                    data={treeMapData}
                    mode={'squarify'}
                    colorType="category"
                    animation={{damping: 9, stiffness: 300}}
                    getLabel={(x) => {
                        const queries = get(x, ['queries'], []);

                        return (
                            <div>
                                {queries.slice(0, 3).map((q) => {
                                    return (
                                        <Fragment key={q.query}>
                                            <span>{q.query}</span>
                                            <br />
                                        </Fragment>
                                    );
                                })}
                                {queries.length > 3 && (
                                    <span>
                                        +{' '}
                                        {queries.slice(2, queries.length)
                                            .length + 1}
                                    </span>
                                )}

                                <small style={{color: geatLeafColor(x.color)}}>
                                    {x.size}
                                </small>
                            </div>
                        );
                    }}
                    colorDomain={[0, 1, 2]}
                    colorRange={[
                        getClusterColor('missing-knowledge'),
                        getClusterColor('improve-answer'),
                        getClusterColor('disambiguation'),
                        '#fff',
                    ]}
                    onLeafClick={(e) => {
                        props.history.push(`/improve/${e.data.id}`);
                    }}
                />
            </div>
        </div>
    );
};

export const Treemap = withRouter(TreeMapComponent);
