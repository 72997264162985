import React from 'react';
import Icon from 'react-icon-base';

export const Bounced = (props) => (
    <Icon viewBox="0 0 48 59" {...props}>
        <g
            id="icon/global/churn/k"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <path
                d="M43.6615846,30.1579407 C40.8079715,22.9009961 33.7066896,17.7391304 25.4347826,17.7391304 L17.909699,17.7391304 L17.909699,11.7391304 L8.87959866,20.7391304 L17.909699,29.7391304 L17.909699,23.7391304 L25.4347826,23.7391304 C32.8996656,23.7391304 38.9799331,29.7991304 38.9799331,37.2391304 C38.9799331,37.4362557 38.9756647,37.6324122 38.9672154,37.8275129 C34.8585422,42.2393986 28.9996981,45 22.5,45 C10.06875,45 0,34.92 0,22.5 C0,10.06875 10.06875,0 22.5,0 C34.92,0 45,10.06875 45,22.5 C45,25.188457 44.5276939,27.7672715 43.6615846,30.1579407 Z"
                id="Combined-Shape"
                fill="#78909C"
            />
        </g>
    </Icon>
);
