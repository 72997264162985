import React, {useState} from 'react';
import cx from 'classnames';
import {MdEdit, MdSettings, MdClose} from 'react-icons/md';
import {StaticMessagePortal} from './StaticMessagePortal';
import {deleteStaticMessage} from './api';
import {Response} from './models';
import {useHistory} from 'react-router-dom';
// @ts-ignore
import './StaticMessageItem.scss';

const isChatterMessagePublished = (node: Response) =>
    !node || node.status === 'published';
const getChatterMessageStatus = (node: Response) => (node ? node.status : '');

interface StaticMessageItemProps {
    message: any;
    fetchListStaticMessages?: (...args) => any;
    selectedCampaign?: (...args) => any;
    node?: Response;
}
export const StaticMessageItem: React.FC<StaticMessageItemProps> = ({
    message: staticMessage,
    selectedCampaign,
    fetchListStaticMessages: fetchMessages,
    node,
}: StaticMessageItemProps) => {
    const history = useHistory();
    const [showStaticMessagePortal, setShowStaticMessagePortal] =
        useState(false);

    const classname = cx({
        ['StaticMessageItem__description']: !staticMessage.node_id,
        ['StaticMessageItem__assignedResponse']: staticMessage.node_id,
    });
    const containerClassName = cx('StaticMessageItem__container', {
        ['StaticMessageItem__isDeleted']:
            (staticMessage.node_id && node === null) ||
            !isChatterMessagePublished(node!),
    });
    const iconSize = 20;

    return (
        <>
            <div className={containerClassName}>
                <div className="StaticMessageItem__itemTitleContainer">
                    <MdSettings size={iconSize} />
                    {staticMessage.title}
                </div>
                <div>
                    <div
                        className={classname}
                        onClick={() => {
                            if (staticMessage.node_id) {
                                history.push(
                                    `?compose=${staticMessage.node_id}`,
                                );
                            }
                        }}
                    >
                        {!staticMessage.node_id && staticMessage.description}
                        {staticMessage.node_id &&
                            !isChatterMessagePublished(node!) && (
                                <span className="StaticMessageItem__capitalize">
                                    ( {getChatterMessageStatus(node!)} )
                                </span>
                            )}
                        {staticMessage.node_id &&
                            `Response: ${staticMessage.node_title}`}
                    </div>
                    <MdEdit
                        className="StaticMessageItem__svgActionable"
                        size={iconSize}
                        onClick={() => {
                            setShowStaticMessagePortal(true);
                        }}
                    />
                    {staticMessage.node_id && (
                        <MdClose
                            className="StaticMessageItem__svgActionable"
                            size={iconSize}
                            onClick={() => {
                                deleteStaticMessage(
                                    staticMessage.key,
                                    () => {
                                        //@ts-ignore
                                        fetchMessages(selectedCampaign?.id);
                                    },
                                    selectedCampaign,
                                );
                            }}
                        />
                    )}
                </div>
            </div>
            {showStaticMessagePortal && (
                <StaticMessagePortal
                    message={staticMessage}
                    onClose={() => {
                        setShowStaticMessagePortal(false);
                    }}
                    onSuccess={(selectedCampaign) => {
                        setShowStaticMessagePortal(false);
                        fetchMessages(selectedCampaign?.id);
                    }}
                    selectedCampaign={selectedCampaign}
                    hasCloseIcon
                />
            )}
        </>
    );
};
