import React from 'react';
import {Clone} from '@app/common/icons';
import {getCookie} from '@app/utils/auth';
import {getAutoWidgetScriptUrl} from '@app/utils/client-url';

interface ApplicationInfoProps {
    applicationId?: string;
    widgetScriptUrl?: string;
    addTextCopiedNotification?: any;
    customLauncher?: boolean;
}

export class ApplicationInfo extends React.Component<ApplicationInfoProps> {
    handleClickCopy = (lines) => {
        const {addTextCopiedNotification} = this.props;
        const codeSnippetElement = document.createElement('textarea');
        codeSnippetElement.innerText = lines.join('\n');
        document.body.appendChild(codeSnippetElement);
        codeSnippetElement.select();
        document.execCommand('copy');
        codeSnippetElement.remove();
        addTextCopiedNotification('Your widget snippet');
    };

    getSnippetArray = () => {
        const isBeta = getCookie('beta');
        const {applicationId, widgetScriptUrl} = this.props;
        return [
            '<script>',
            '  window.GLR = {',
            isBeta === 'true' ? `    isBeta: true,` : '',
            `    appId: '${applicationId}'`,
            '  };',
            '  (function(w, d, s){',
            `    var j = document.createElement(s); j.async = 1; j.type = 'text/javascript'; j.src = '${widgetScriptUrl}';`,
            '    w.GLR = w.GLR || {};',
            '    d.getElementsByTagName("head")[0].appendChild(j);',
            '  })(window, document, "script");',
            '</script>',
        ];
    };

    scriptWithCustomButton = () => {
        const isBeta = getCookie('beta');
        const {applicationId, widgetScriptUrl} = this.props;
        return [
            '<script>',
            '  window.GLR = {',
            isBeta === 'true' ? `    isBeta: true,` : '',
            `    appId: '${applicationId}'`,
            '  };',
            '  (function(w, d, s){',
            `    var j = document.createElement(s); j.async = 1; j.type = 'text/javascript'; j.src = '${widgetScriptUrl}';`,
            '    w.GLR = w.GLR || {};',
            '    j.onload = () => {',
            '      window.kare.addBeacon({',
            '        beaconName: "launcher",',
            "        beaconType: 'fab',",
            "        htmlClickableElementId: 'karehq-widget-launcher-button'",
            '     });',
            '     const button = document.getElementById("karehq-widget-launcher-button");',
            '     if(!button){',
            '       console.warn("Karehq widget custom launcher button not found.")',
            '       console.warn("Please add in your button the id karehq-widget-launcher-button")',
            '       return',
            '     }',
            "     button.addEventListener('click', () => {",
            '       window.kare.open();',
            '     });',
            '     window.kare.onClose(() => {',
            '       // Display the button',
            "       button.style.display = 'flex';",
            '     });',
            '     window.kare.onOpen(() => {',
            '       // Hide the button. The widget is currently opened.',
            "       button.style.display = 'none';",
            '     });',
            '    }',
            '    d.getElementsByTagName("head")[0].appendChild(j);',
            '  })(window, document, "script");',
            '</script>',
        ];
    };

    getAutomaticScriptArrayWithCustomButton = () => {
        const {applicationId} = this.props;
        return [
            `<script src="${getAutoWidgetScriptUrl()}?appId=${applicationId}" async>`,
            '</script>',
        ];
    };

    getAutomaticScriptArray = () => {
        const {applicationId} = this.props;
        return [
            `<script src="${getAutoWidgetScriptUrl()}?appId=${applicationId}" async>`,
            '</script>',
        ];
    };

    render() {
        const {applicationId, customLauncher} = this.props;

        const autoScriptLines = !customLauncher
            ? this.getAutomaticScriptArray()
            : this.getAutomaticScriptArrayWithCustomButton();
        const lines = !customLauncher
            ? this.getSnippetArray()
            : this.scriptWithCustomButton();

        return (
            <section className="settings__application-info">
                <h1>Installation</h1>
                <p>Preferred method (self managed):</p>
                <section className="settings__application-info-code">
                    <header>
                        <div>Application ID: {applicationId}</div>
                        <div
                            onClick={() => {
                                this.handleClickCopy(lines);
                            }}
                            className="copy-button"
                        >
                            Copy <Clone size={10} />
                        </div>
                    </header>
                    <div className="settings__application-info-columns">
                        <pre className="settings__application-info-numbers">
                            {lines.map((line, index) => {
                                return (
                                    <code key={line + index}>{index + 1}</code>
                                );
                            })}
                        </pre>
                        <pre>
                            {lines.map((line, index) => {
                                return <code key={line + index}>{line}</code>;
                            })}
                        </pre>
                    </div>
                </section>
                <p>Alternative method (managed by Dialpad)</p>
                <section className="settings__application-info-code">
                    <header>
                        <div>Application ID: {applicationId}</div>
                        <div
                            onClick={() => {
                                this.handleClickCopy(autoScriptLines);
                            }}
                            className="copy-button"
                        >
                            Copy <Clone size={10} />
                        </div>
                    </header>
                    <div className="settings__application-info-columns">
                        <pre className="settings__application-info-numbers">
                            {autoScriptLines.map((line, index) => {
                                return (
                                    <code key={line + index}>{index + 1}</code>
                                );
                            })}
                        </pre>
                        <pre>
                            {autoScriptLines.map((line, index) => {
                                return <code key={line + index}>{line}</code>;
                            })}
                        </pre>
                    </div>
                </section>

                <section className="settings__application-info__callbacks">
                    <h1>Custom callbacks</h1>
                    <p>
                        You can check our{' '}
                        <a href="https://developer.karehq.com/custom-callbacks">
                            documentation about callbacks
                        </a>
                    </p>
                </section>
                <section>
                    <h1>Cookie disclaimer</h1>
                    <p>
                        The Dialpad client uses the following cookies and
                        browser storage utilities:
                    </p>
                    <ol>
                        <li>
                            {
                                "Dialpad's own session storage entries for proper client functionality."
                            }
                        </li>
                        <li>Cookie for session storage to keep session open</li>
                    </ol>
                </section>
            </section>
        );
    }
}
