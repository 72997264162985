import React, {Component} from 'react';
import cx from 'classnames';

import {DocumentList} from './document-list';
import {DocumentSummary} from './document-summary';
import {getSelectedDocumentSelector} from '@app/redux/improve/documents/selectors';
import {connect} from 'react-redux';
import {Portal} from 'react-portal';

interface DocumentsModalProps {
    selectedDocument?: any;
    closeModal?: any;
    showModal?: boolean;
    editing?: boolean;
    change?: any;
    fields?: any;
    index?: any;
    isEditing?: any;
}
export class DocumentsModalComponent extends Component<DocumentsModalProps> {
    render() {
        return (
            <Portal>
                <div
                    className={cx({
                        'open-modal': this.props.showModal,
                    })}
                >
                    <div
                        className="modal-overlay"
                        onClick={this.props.closeModal}
                    />
                    {this.props.selectedDocument ? (
                        <DocumentSummary {...this.props} />
                    ) : (
                        <DocumentList {...this.props} />
                    )}
                </div>
            </Portal>
        );
    }
}
const mapStateToProps = (state) => ({
    selectedDocument: getSelectedDocumentSelector(state),
});

export const DocumentsModal = connect(mapStateToProps)(DocumentsModalComponent);
