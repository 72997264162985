import React, {useContext, useState, useEffect} from 'react';
import {Moment} from 'moment';
import {values} from 'lodash';

import './DomainsTable.scss';
import {Card} from '@app/components/Card/Card';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {StoreContext} from '@app/state';
import {Table} from '@app/components/Table/Table';

export interface Props {
    minDate: Moment;
    maxDate: Moment;
}

interface DomainData {
    data: any;
    loading: boolean;
    error: string;
}

const initialDataState: DomainData = {
    data: {},
    loading: true,
    error: '',
};

export const DomainsTable: React.FC<Props> = (props) => {
    const {entryPageApi} = useContext(StoreContext);
    const [data, setData] = useState<DomainData>(initialDataState);
    const getData = async () => {
        const response = await entryPageApi.getTrafficSourceByDomain(
            props.minDate,
            props.maxDate,
        );
        if (response.data) {
            setData({
                loading: false,
                error: '',
                data: response.data || {},
            });
        } else {
            setData({
                loading: false,
                error: response.error || '',
                data: {},
            });
        }
    };

    useEffect(() => {
        getData();
    }, [props.minDate, props.maxDate]);

    const showEmptyState = () => {
        if (
            !data ||
            !data.data.entries ||
            values(data.data.entries).length === 0
        ) {
            return 'empty';
        }
        return '';
    };

    return (
        <Card>
            <CardTitle
                title="Traffic sources"
                className="DomainsTable__title"
            />
            <ErrorLoadingWrapper
                height={'400px'}
                isLoading={data.loading}
                error={data.error}
                emptyState={showEmptyState()}
                render={() => {
                    return (
                        <Table
                            showPosition={false}
                            maxRowsVisible={5}
                            columns={[
                                'source',
                                'started conversations',
                                'questions asked',
                            ]}
                            rows={values(data.data.entries).map((e) => {
                                return [
                                    {value: e.domain},
                                    {value: e.counters?.opened || 0},
                                    {value: e.counters?.engaged || 0},
                                ];
                            })}
                        />
                    );
                }}
            />
        </Card>
    );
};
