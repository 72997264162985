import React from 'react';
import {get, map} from 'lodash';
import {DiagramEngine} from '@projectstorm/react-diagrams-core';
import cx from 'classnames';
import {
    MdCreate,
    MdInsertPhoto,
    MdCallSplit,
    MdLibraryBooks,
    MdChatBubble,
    MdTextsms,
    MdCall,
    MdVideocam,
    MdLaunch,
} from 'react-icons/md';

import './BlockNodeWidget.scss';
import {DefaultNodeModel} from './DefaultNodeModel';
import {DefaultPortLabel} from '../port/DefaultPortLabelWidget';
import {Query} from '../query';
import {TopPort} from './connector_top';
import {BottomPort} from './connector_bottom';
import {Satisfied} from '../../../assets/icons/satisfied';
import axios from 'axios';
import {AUTHORIZATION} from '../../../redux/constants';

export interface DefaultNodeProps {
    node: DefaultNodeModel;
    engine: DiagramEngine;
    isResponse: boolean;
    setRoot: any;
    editNode: any;
    resp: any;
    nodeType: any;
}

/**
 * Default node that models the DefaultNodeModel. It creates two columns
 * for both all the input ports on the left, and the output ports on the right.
 */
export class DefaultNodeWidget extends React.Component<DefaultNodeProps> {
    state = {
        showParents: false,
    };
    generatePort = (port) => {
        return (
            <DefaultPortLabel
                engine={this.props.engine}
                port={port}
                key={port.getID()}
            />
        );
    };
    renderBlocksRow = (blocks: any) => {
        const numberImages = blocks.filter((b) => b.type === 'image').length;
        const numberVideos = blocks.filter((b) => b.type === 'video').length;
        const numberHandovers = blocks.filter(
            (b) => b.type === 'handover',
        ).length;
        const numberActions = blocks.filter((b) => b.type === 'webext').length;
        const numberChoices = blocks.filter((b) => b.type === 'choices').length;
        const numberCxMetrics = blocks.filter(
            (b) => b.type === 'cx-question',
        ).length;
        const numberQuotes = 0;
        if (
            !numberImages &&
            !numberVideos &&
            !numberHandovers &&
            !numberActions &&
            !numberChoices &&
            !numberCxMetrics &&
            !numberQuotes
        ) {
            return null;
        }
        return (
            <div className="BlockNodeWidget__blocksRow">
                {numberImages > 0 && (
                    <div>
                        <MdInsertPhoto />
                        {numberImages}
                    </div>
                )}
                {numberVideos > 0 && (
                    <div>
                        <MdVideocam />
                        {numberVideos}
                    </div>
                )}{' '}
                {numberActions > 0 && (
                    <div>
                        <Query />
                        {numberActions}
                    </div>
                )}{' '}
                {numberHandovers > 0 && (
                    <div>
                        <MdCall />
                        {numberHandovers}
                    </div>
                )}
                {numberChoices > 0 && (
                    <div>
                        <MdTextsms />
                        {numberChoices}
                    </div>
                )}
                {numberCxMetrics > 0 && (
                    <div>
                        <Satisfied size={12} color="#676f73" />
                        {numberCxMetrics}
                    </div>
                )}
                {numberQuotes > 0 && (
                    <div>
                        <MdLibraryBooks />
                        {numberQuotes}
                    </div>
                )}
            </div>
        );
    };

    getDocumentContentLink(document): string {
        if (axios && axios.defaults && document) {
            const {headers, baseURL} = axios.defaults;
            return `${baseURL}/v3.0/kbm/nodes/${
                document.id
            }/content/public?token=${headers[AUTHORIZATION].slice(7)}`;
        }
        return '';
    }

    render() {
        const {node, nodeType = {}, editNode, setRoot, resp = {}} = this.props;
        const {showParents} = this.state;

        const inbounds = get(resp, 'inbounds');
        const blocks = get(resp, ['response', 'blocks'], []);
        const type = get(resp, 'type');
        const status = get(resp, 'status');
        const isResponse = type === 'response';
        const queries = get(resp, ['response', 'queries', 'length'], 0);
        const url = get(resp, ['content', 'url'], null);
        const linksOut = Object.keys(
            get(this.props, ['node', 'portsOut', '0', 'links'], {}),
        );
        const choiceText = get(
            this.props,
            ['node', 'options', 'resp', 'choiceText'],
            '',
        );
        return nodeType === 'choice_title' ? (
            <div
                className={cx('BlockNodeWidget__choiceNode', {
                    ['BlockNodeWidget__noTitle']: choiceText?.length === 0,
                })}
                data-default-node-name={node.getOptions().id}
            >
                <div className="BlockNodeWidget__infoIcon">
                    <MdCallSplit />
                </div>

                <span>{choiceText}</span>
                <div className="BlockNodeWidget__ports">
                    <div className="BlockNodeWidget__portIn">
                        {map(node.getInPorts(), this.generatePort)}
                    </div>
                    <div className="BlockNodeWidget__portOut">
                        {map(node.getOutPorts(), this.generatePort)}
                    </div>
                </div>
                <div className="BlockNodeWidget__bottomPortAttachment">
                    <BottomPort />
                </div>
            </div>
        ) : (
            <div
                //@ts-ignore
                data-default-node-name={node.getOptions().name}
                className={`BlockNodeWidget__container BlockNodeWidget__${type} BlockNodeWidget__${status}`}
                onMouseLeave={() => {
                    this.setState({
                        showParents: false,
                    });
                }}
            >
                <div className="BlockNodeWidget__topPortAttachment">
                    <TopPort />
                </div>

                <div className="BlockNodeWidget__typeIconContainer">
                    {isResponse ? <MdChatBubble /> : <MdLibraryBooks />}
                </div>

                <div
                    className={`BlockNodeWidget__menu BlockNodeWidget__${showParents} BlockNodeWidget__${type}`}
                >
                    {inbounds?.length > 0 && (
                        <span
                            onClick={(e) => {
                                e.stopPropagation();
                                this.setState({
                                    showParents: !showParents,
                                });
                            }}
                        >
                            <MdCallSplit />
                        </span>
                    )}
                    {isResponse && (
                        <span
                            onClick={(e) => {
                                e.stopPropagation();
                                editNode(resp);
                            }}
                        >
                            <MdCreate />
                        </span>
                    )}
                    <span
                        onClick={(e) => {
                            e.stopPropagation();
                            if (isResponse) {
                                const confirm = window.confirm(
                                    'Are you sure you want to navigate to this node?',
                                );
                                if (confirm) {
                                    setRoot(resp.id);
                                }
                            } else if (url) {
                                window.open(url);
                            } else {
                                window.open(this.getDocumentContentLink(resp));
                            }
                        }}
                    >
                        <MdLaunch />
                    </span>
                    <div
                        className={`BlockNodeWidget__parentContainer BlockNodeWidget__${type}`}
                    >
                        {showParents && (
                            <div className="BlockNodeWidget__parents">
                                <div
                                    className={'BlockNodeWidget__parentsTitle'}
                                >
                                    {inbounds.length} Parents
                                </div>
                                <ul>
                                    {inbounds
                                        .filter((i) => !!i)
                                        .map((i) => (
                                            <li
                                                key={`parent_${i.id}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setRoot(i.id);
                                                }}
                                            >
                                                {i.title}
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div className="BlockNodeWidget__innerContainer">
                    <div className="BlockNodeWidget__title">
                        <span>{get(resp, ['title'], '')}</span>
                    </div>
                    {}
                    {type !== 'content' && queries > 0 && (
                        <>
                            <div className="BlockNodeWidget__queriesContainer">
                                <Query />
                                {queries} queries
                            </div>
                            <div className="BlockNodeWidget__bottom">
                                {this.renderBlocksRow(blocks)}
                            </div>
                        </>
                    )}
                    <div className="BlockNodeWidget__ports">
                        <div className="BlockNodeWidget__portIn">
                            {map(node.getInPorts(), this.generatePort)}
                        </div>
                        <div className="BlockNodeWidget__portOut">
                            {map(node.getOutPorts(), this.generatePort)}
                        </div>
                    </div>
                </div>
                {linksOut.length > 0 && (
                    <div className="BlockNodeWidget__bottomPortAttachment">
                        <BottomPort />
                    </div>
                )}
            </div>
        );
    }
}
