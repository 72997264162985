import React, {Fragment} from 'react';
// @ts-ignore
import {AutoSizer, List} from 'react-virtualized';
import {Add, ChatBubble} from '@app/common/icons';
import {QueryResponseListAutoResponseItem} from './queries-response-list-auto-response-item';
import {getFilteredResponses} from '../utils';
import {redux} from '@app/redux';
import {getCluster, getClusterId} from '@app/redux/improve/cards/selectors';
import {filterQuery} from '@app/redux/improve/responses/selectors';
import {createImproveSelectedResponse} from '@app/redux/improve/responses/dispatchers';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {get} from 'lodash';
import {DISAMBIGUATION_CLUSTER} from '@app/redux/improve/constants';

interface QueriesResponseListProps {
    areResponsesFetching?: boolean;
    isResponseFetching?: boolean;
    isLoading?: boolean;
    responses?: any[];
    getResponse?: any;
    cluster?: any;
    getAllPublishedResponses?: any;
    filterQuery?: string;
    history?: any;
    org?: any;
    hoverResponse?: any;
    location?: any;
    cardId?: string;
    createFakeResponseDispatcher?: any;
    getImprovePageResponse?: any;
    forceAllResponses?: boolean;
}

export class QueriesResponseListComponent extends React.Component<QueriesResponseListProps> {
    timeoutId = null;
    private list = null;
    state = {showHoverInfoId: null};

    handleResponseClick = (response) => {
        if (!response) {
            this.props.history.push(`?compose=new`);
        } else if (!response.id) {
            this.props.createFakeResponseDispatcher(response);
        } else {
            this.props.getImprovePageResponse(response.id);
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.cardId !== this.props.cardId) {
            this.props.getAllPublishedResponses();
        }
    }

    componentDidMount() {
        const {getAllPublishedResponses} = this.props;
        const preloadId = get(this.props, ['match', 'params', 'id']);
        if (preloadId) {
            getAllPublishedResponses();
        }
    }

    getResponsesOrClusterNodes = () => {
        const {cluster, filterQuery, responses, forceAllResponses} = this.props;
        const searchResults = get(cluster, ['nodes']);

        if (
            !forceAllResponses &&
            cluster &&
            cluster.cluster_type === DISAMBIGUATION_CLUSTER &&
            searchResults &&
            searchResults.length > 0
        ) {
            return getFilteredResponses(searchResults, filterQuery);
        }

        return getFilteredResponses(responses, filterQuery);
    };

    renderResponse = ({index, key, style}) => {
        const responses = this.getResponsesOrClusterNodes();

        return (
            <div
                className="queries-response-item"
                style={style}
                key={key}
                onClick={() => {
                    if (responses && responses[index]) {
                        this.handleResponseClick(responses[index]);
                    }
                }}
            >
                <ChatBubble size={15} />
                <span>{get(responses, [index, 'title'])} </span>
            </div>
        );
    };

    render() {
        const {areResponsesFetching, filterQuery} = this.props;
        const responses = this.getResponsesOrClusterNodes();
        return (
            <div className="queries-response-list-container">
                {!areResponsesFetching ? (
                    responses ? (
                        <Fragment>
                            <QueryResponseListAutoResponseItem />
                            <Link
                                className="queries-response-list-create-button"
                                to="?compose=new"
                                replace={true}
                            >
                                <Add size={22} /> <span>New response</span>
                            </Link>
                            <AutoSizer
                                disableHeight={false}
                                disableWidth={false}
                            >
                                {({height, width}) => (
                                    <List
                                        ref={(r) => (this.list = r)}
                                        rowRenderer={this.renderResponse}
                                        height={height - 20}
                                        rowHeight={40}
                                        overscanRowCount={5}
                                        rowCount={
                                            getFilteredResponses(
                                                responses,
                                                filterQuery,
                                            ).length
                                        }
                                        width={width}
                                    />
                                )}
                            </AutoSizer>
                        </Fragment>
                    ) : (
                        'No Data'
                    )
                ) : (
                    'Loading'
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    org: redux.org.selectors.orgSelector(state),
    // @ts-ignore
    cardId: getClusterId(state),
    cluster: getCluster(state),
    responses: redux.knowledgeBase.selectors.publishedResponsesSelector(state),
    isResponseFetching:
        redux.responses.selectors.isResponseFetchingSelector(state),
    areResponsesFetching:
        redux.knowledgeBase.selectors.publishedResponsesLoadingSelector(state),
    filterQuery: filterQuery(state),
    isLoading:
        redux.org.selectors.isOrgLoadingSelector(state) ||
        redux.application.selectors.isApplicationLoadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    createFakeResponseDispatcher: (response) => {
        createImproveSelectedResponse(dispatch)(response);
    },
    getAllPublishedResponses:
        redux.knowledgeBase.dispatchers.getAllPublishedResponsesDispatcher(
            dispatch,
        ),
    getImprovePageResponse: (id) => {
        return dispatch(
            redux.rest.actions[
                redux.responses.constants.GET_IMPROVE_PAGE_RESPONSE
            ]({
                id: id,
            }),
        );
    },
    getResponse: (id) =>
        dispatch(
            redux.rest.actions[redux.responses.constants.GET_RESPONSE]({
                id: id,
            }),
        ),
});

export const QueriesResponseList = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withRouter(QueriesResponseListComponent)),
);
