import React, {Component} from 'react';
import {get} from 'lodash';

import {getTimestamp} from '../utils/timestamp';
import {ThumbDown, ThumbUp, AttachFile, LocalPhone} from '@app/common/icons';
import {
    CHOICE_ESCALATED,
    CHOICE_FEEDBACK_DOWN,
    CHOICE_FEEDBACK_UP,
    CHOICE_RESPONSE,
    CHOICE_RESOLUTION,
    OPEN_DOCUMENT,
    OPEN_LINK,
    OPEN_RESPONSE,
} from '../utils';
import {withRouter} from 'react-router-dom';
import {ResponseOrContentNavigator} from './response-conent-navigator';
import {MdVisibility} from 'react-icons/md';

interface MessageUserChoiceProps {
    message: any;
    onMouseEnter: any;
    onMouseLeave: any;
    isHovering: boolean;
}

export class MessageUserChoiceInternal extends Component<MessageUserChoiceProps> {
    state = {
        openDocument: false,
        documentId: '',
        choiceClick: false,
    };

    getResponseId = () => {
        const {message} = this.props;
        return get(message, 'headers.resource_ids');
    };

    getTextByAspect = (message) => {
        const body = get(message, 'event_body.in_click');
        const aspect = body?.aspect;
        const choiceId = body?.choice_id;
        const text = get(message, 'body.text');

        if (aspect === OPEN_LINK && !text) {
            return null;
        }

        switch (aspect) {
            case OPEN_RESPONSE:
            case CHOICE_RESPONSE:
            case CHOICE_RESOLUTION:
                return (
                    <div className="console-activity-message message-user-choice message-user-choice__response">
                        <div>
                            <div>
                                <p>Clicked</p>
                                <p className="message-user-choice__bubble">
                                    {text}
                                </p>
                            </div>
                        </div>
                    </div>
                );
            case CHOICE_FEEDBACK_DOWN:
                return (
                    <div className="console-activity-message message-user-choice message-user-choice__thumbs_down red">
                        <div>
                            <div className="message-feedback-container">
                                <p>
                                    <ThumbDown /> THUMBS DOWN
                                </p>
                                <a
                                    onClick={() => {
                                        this.setState({
                                            choiceClick: true,
                                        });
                                    }}
                                >
                                    <MdVisibility /> <span>See response</span>
                                </a>
                            </div>
                        </div>
                    </div>
                );
            case CHOICE_FEEDBACK_UP:
                return (
                    <div className="console-activity-message message-user-choice message-user-choice__thumbs_up green">
                        <div>
                            <div className="message-feedback-container">
                                <p>
                                    <ThumbUp />
                                    THUMBS UP
                                </p>
                                <a
                                    onClick={() => {
                                        this.setState({
                                            choiceClick: true,
                                        });
                                    }}
                                >
                                    <MdVisibility /> <span>See response</span>
                                </a>
                            </div>
                        </div>
                    </div>
                );
            case OPEN_DOCUMENT:
                return (
                    <div className="console-activity-message message-user-choice message-user-choice__open-document">
                        <div>
                            <div>
                                <span>Opened: {text}</span>
                                <AttachFile />
                            </div>
                        </div>
                    </div>
                );
            case CHOICE_ESCALATED:
                return (
                    <div className="console-activity-message message-user-choice message-user-choice__escalate">
                        <div>
                            <div>
                                <LocalPhone size={10} /> Escalated
                            </div>
                        </div>
                    </div>
                );
            case OPEN_LINK:
                return (
                    <div className="console-activity-message message-user-choice message-user-choice__opened-link">
                        <div>
                            <a target="_blank" rel="noreferrer" href={choiceId}>
                                <p>NAVIGATED</p> <p>{text}</p>
                            </a>
                        </div>
                    </div>
                );
        }
    };

    render() {
        const {message, isHovering, onMouseEnter, onMouseLeave} = this.props;
        const text = this.getTextByAspect(message);
        const timestamp = getTimestamp(message);

        if (!text) {
            return null;
        }
        return (
            <div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className="message-user-choice__container console-activity-message-container"
            >
                {text}
                {isHovering && (
                    <time className="console-activity-message-timestamp">
                        {timestamp}
                    </time>
                )}
                {this.state.choiceClick && (
                    <ResponseOrContentNavigator
                        nodeId={this.getResponseId()}
                        onClose={() => {
                            this.setState({
                                choiceClick: false,
                            });
                        }}
                    />
                )}
            </div>
        );
    }
}

export const MessageUserChoice = withRouter(MessageUserChoiceInternal);
