export const formatThousands = (n: number) => {
    return n && n.toString
        ? n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        : '0';
};

export const calcRatio = (number: number, total: number) => {
    return !number
        ? 0
        : total === 0
        ? 0
        : parseFloat(((number / total) * 100).toFixed(1));
};

export const fixDecimals = (n: number) => {
    return n && n.toFixed ? parseFloat(n.toFixed(1)) : 0;
};

export const npsEnabled = () => {
    return process.env.NPS_ENABLED;
};

export const uuidv4 = () => {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
            c ^
            (window?.crypto?.getRandomValues(new Uint8Array(1))[0] &
                (15 >> (c / 4)))
        ).toString(16),
    );
};

export const COLOR_DATAVIZ_BLUE = '#2E91FF';
export const COLOR_DATAVIZ_GREEN = '#36A385';
export const COLOR_DATAVIZ_ORANGE = '#FDAC7A';
