import {KareLibWebCrawler} from '@app/dashboard';
import React from 'react';
import Modal from 'react-modal';
import {MODAL_BASE_CONFIG} from '@app/utils/modals';

interface NewWebcrawlerFormProps {
    closeModal?: any;
    host?: any;
    onSuccess?: any;
}

export class NewWebcrawlerFormComponent extends React.Component<NewWebcrawlerFormProps> {
    render() {
        return (
            <Modal
                isOpen={true}
                onRequestClose={this.props.closeModal}
                style={{
                    ...MODAL_BASE_CONFIG,
                    content: {
                        ...MODAL_BASE_CONFIG.content,
                        padding: '20px',
                        width: '620px',
                    },
                }}
            >
                <KareLibWebCrawler {...this.props} />
            </Modal>
        );
    }
}

export const NewWebcrawlerForm = NewWebcrawlerFormComponent;
