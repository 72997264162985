import React, {useState} from 'react';
import {get} from 'lodash';
import {BlockControls} from '../block-controls';
import {ChatBubble} from '@app/common/icons';
import {withRouter} from 'react-router-dom';
import cx from 'classnames';

export const renderInactiveChoices = (
    choices,
    title,
    index,
    fields,
    removeBlock,
    activateBlock,
    onClick,
    isBroken,
    setIsBroken,
) => {
    const blockClassname = cx(
        'kare-block kare-block--inactive kare-block-choice',
        {'kare-block-broken': isBroken},
    );
    return (
        <div className="kare-block-wrapper">
            <section className={blockClassname}>
                <h1>{title}</h1>
                <section className="choices-container">
                    <div className="side-bar" />
                    <div className="choices">
                        {choices.map((choice) => {
                            const broken = get(choice, 'broken', false);
                            const classname = cx('kare-h4', {
                                'choice-broken-title': get(
                                    choice,
                                    'broken',
                                    false,
                                ),
                            });
                            if (broken && !isBroken) {
                                setIsBroken(true);
                            }
                            return (
                                <article
                                    key={choice.node}
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        if (onClick) {
                                            onClick(choice);
                                        }
                                    }}
                                >
                                    <h1 className={classname}>
                                        <ChatBubble size={10} />{' '}
                                        {choice.text || choice.title}
                                    </h1>
                                    <p>{choice.text ? choice.title : ''}</p>
                                </article>
                            );
                        })}
                    </div>
                </section>
            </section>
            {choices && index >= 0 && fields && removeBlock && (
                <BlockControls
                    index={index}
                    fields={fields}
                    removeBlock={removeBlock}
                    activateBlock={activateBlock}
                />
            )}
        </div>
    );
};
export const DialogBlockFieldComponent = (props) => {
    const {input, index, fields, removeBlock, choices} = props;
    const title = get(input, ['value', 'choices_block', 'text']);
    const [isBroken, setIsBroken] = useState(false);
    return renderInactiveChoices(
        choices,
        title,
        index,
        fields,
        removeBlock,
        props.activateBlock,
        (choice) => {
            props.history.push(`/responses?compose=${choice.node}`);
        },
        isBroken,
        setIsBroken,
    );
};

export const DialogBlockField = withRouter(DialogBlockFieldComponent);
