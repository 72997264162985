import React, {Fragment} from 'react';
import {get} from 'lodash';
import {connect} from 'react-redux';

import {redux} from '@app/redux';
import {ZendeskForm} from './zendesk-form';
import {WebcrawlerList} from './webcrawler-list';
import {Spinner} from '@app/common';
import {withRouter} from 'react-router-dom';
import {PLANS} from '@app/plans/constants';
import {GET_SERVICES, services} from '@app/redux/integrations/constants';
import {isProviderConnected} from '@app/redux/integrations/selectors';

interface KnowledgeManagementProps {
    organisation?: any;
    getKnowledgeBase?: any;
    knowledgeBase?: any;
    showModal?: any;
    getIntegrations?: any;
    getProviderConnected?: any;
    activeSubscription?: any;
    isLoading?: boolean;
    history?: any;
}

export class KnowledgeManagementComponent extends React.Component<KnowledgeManagementProps> {
    componentDidMount() {
        this.props.getIntegrations();
        this.props.getKnowledgeBase();
    }

    handleShowModal = () => {
        const {showModal} = this.props;
        showModal();
    };

    renderContent = () => {
        const {
            activeSubscription,
            isLoading,
            organisation,
            getProviderConnected,
        } = this.props;
        const tier = get(activeSubscription, 'tier');
        if (isLoading) {
            return <Spinner withCenteredContainer={true} />;
        }

        return (
            <Fragment>
                <div className={'knowledge-management'}>
                    <h1 className="kare-h4 u-margin-bottom">Data import</h1>{' '}
                    <div className="knowledge-management__integrations">
                        <ZendeskForm
                            organisation={organisation}
                            connected={getProviderConnected(services.ZENDESK)}
                            showModal={tier === PLANS.free}
                            handleShowModal={this.handleShowModal}
                        />
                    </div>
                    <WebcrawlerList
                        organisation={organisation}
                        showModal={tier === PLANS.free}
                        handleShowModal={this.handleShowModal}
                    />
                </div>
            </Fragment>
        );
    };

    render() {
        return (
            <section className="c-panel__item">{this.renderContent()}</section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        organisation: redux.org.selectors.orgSelector(state),
        knowledgeBase:
            redux.knowledgeBase.selectors.getKnowledgeBaseSelector(state),
        getProviderConnected: isProviderConnected(state),
        isLoading:
            redux.integrations.selectors.isGetSubscriptionLoadingSelector(
                state,
            ),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getIntegrations: () => {
            return dispatch(redux.rest.actions[GET_SERVICES]());
        },
        getKnowledgeBase: () => {
            return dispatch(
                redux.rest.actions[
                    redux.knowledgeBase.constants.ACTIONS.GET_KNOWLEDGEBASE
                ](),
            );
        },
    };
};

export const KnowledgeManagement = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(KnowledgeManagementComponent),
);
