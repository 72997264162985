import React from 'react';
import {ESCALATION_METHODS} from './constants';
import {Email, Link, Sms} from '@app/common/icons';
import {validators} from '@app/utils';
import {COMPANY_SETTINGS_FORM_NAME} from '../company-form';
import {MdPhone, MdEmail, MdChatBubble} from 'react-icons/md';
import {DialpadCallback} from '@app/assets/icons/dialpad-callback';

export const getEscalationIcon = (method, size = 12, noRender = false) => {
    switch (method) {
        case ESCALATION_METHODS.PHONE:
            return noRender ? MdPhone : <MdPhone size={size} />;
        case ESCALATION_METHODS.EMAIL:
            return noRender ? Email : <MdEmail size={size} />;
        case ESCALATION_METHODS.URL:
        case ESCALATION_METHODS.RPA:
            return noRender ? Link : <Link size={size} />;
        case ESCALATION_METHODS.CUSTOM:
        case ESCALATION_METHODS.ZENDESK:
            return noRender ? MdChatBubble : <MdChatBubble size={size} />;
        case ESCALATION_METHODS.SMS:
            return noRender ? Sms : <Sms size={size} />;
        case ESCALATION_METHODS.DIALPAD_CALLBACK:
            return noRender ? (
                DialpadCallback
            ) : (
                <DialpadCallback size={size} color={'#676f73'} />
            );
        default:
            return undefined;
    }
};

export const getEditingEscalationValueLabel = (method) => {
    switch (method) {
        case ESCALATION_METHODS.PHONE:
            return 'Phone';
        case ESCALATION_METHODS.EMAIL:
            return 'Email address';
        case ESCALATION_METHODS.URL:
            return 'URL';
        case ESCALATION_METHODS.ZENDESK:
            return 'Zendesk';
        case ESCALATION_METHODS.SMS:
            return 'SMS';
        case ESCALATION_METHODS.CUSTOM:
            return 'Custom value';
        case ESCALATION_METHODS.RPA:
            return 'RPA';
        case ESCALATION_METHODS.DIALPAD_CALLBACK:
            return 'Dialpad Callback';
        default:
            return 'Select...';
    }
};

export const getEscalationValueValidation = (method) => {
    switch (method) {
        case ESCALATION_METHODS.EMAIL:
            return [validators.required, validators.email];
        case ESCALATION_METHODS.URL:
            return [validators.required, validators.website];
        case ESCALATION_METHODS.SMS:
            return [validators.required, validators.phone];
        case ESCALATION_METHODS.DIALPAD_CALLBACK:
            return [validators.required, validators.callcenter];
        default:
            return [() => undefined];
    }
};

export const dispatchBlurAction = (dispatch, index) => {
    dispatch({
        type: '@@redux-form/BLUR',
        meta: {
            form: COMPANY_SETTINGS_FORM_NAME,
            field: `escalation_entries[${index}].name`,
            touch: true,
        },
    });
    dispatch({
        type: '@@redux-form/BLUR',
        meta: {
            form: COMPANY_SETTINGS_FORM_NAME,
            field: `escalation_entries[${index}].value`,
            touch: true,
        },
    });
};
