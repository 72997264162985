import {map} from 'lodash';
import {
    LOCALES,
    DEFAULT_LOCALE_EU,
    DEFAULT_LOCALE_US,
    LANGUAGES,
    COUNTRIES,
} from './constants';
import {ZONES} from '@app/zone';
import * as constants from './constants';

export {constants};

export const getLocaleName = (locale) => LOCALES[locale];

export const getLanguageCodeFromLocale = (locale) =>
    LOCALES[locale].substring(1, 2);

export const getLanguageFromLocale = (locale) => LOCALES[locale].split(' ')[0];

export const getCountryCode = (locale) => {
    const parts = locale.split('-');
    return typeof parts[1] === 'string' ? parts[1] : '';
};
export const getLanguageCode = (locale) => {
    const parts = locale.split('-');
    return typeof parts[0] === 'string' ? parts[0] : '';
};

export const getDefaultLocaleForZone = (zone) => {
    if (zone === ZONES.US) {
        return DEFAULT_LOCALE_US;
    }
    return DEFAULT_LOCALE_EU;
};
// TODO(rupertrutland): Refactor this into a nice locales module, and test these functions.
export const getLanguageAndCountry = (locale) => {
    if (!locale) {
        return {country: '', language: ''};
    }
    const country = getCountryCode(locale);
    const language = getLanguageCode(locale);
    return {country: COUNTRIES[country], language: LANGUAGES[language]};
};

export const getLanguageAndCountryString = (locale) => {
    if (!locale) {
        return '';
    }
    const {country, language} = getLanguageAndCountry(locale);
    return `${language} ${country}`;
};

export const getLocaleList = () => {
    return map(LOCALES, (label, value) => {
        return {label, value};
    });
};
