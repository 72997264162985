import styled from '@emotion/styled';

export const H2 = styled.h2`
    color: #263238;
    font-weight: 700;
    margin-bottom: 10px;
`;

export const P = styled.p`
    color: #263238;
`;
