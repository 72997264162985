import React from 'react';
import {Error} from '@app/common/icons';
import {Button} from '@app/common';

interface ResponseNotFoundProps {
    history?: any;
}

export class ResponseNotFound extends React.Component<ResponseNotFoundProps> {
    handleClickSearchResponses = () => {
        this.props.history.push('/responses');
    };
    render() {
        return (
            <section className="response-not-found">
                <div className="response-not-found-inner">
                    <Error size={120} />
                    <h1>Response not found</h1>
                    <p>
                        Unfortunately we could not find the response you were
                        looking for.
                    </p>
                    <Button outlined onClick={this.handleClickSearchResponses}>
                        Search responses
                    </Button>
                </div>
            </section>
        );
    }
}
