import React from 'react';
import {InsertDriveFile} from '@app/common/icons';

interface DocumentExternalUrlLinkProps {
    url: string;
}

/**
 * Reusable portal component.
 */
export class DocumentExternalUrlLink extends React.Component<DocumentExternalUrlLinkProps> {
    render() {
        const {url} = this.props;
        if (!url) {
            return null;
        }
        return (
            <a
                className="knowledge-base__document-external-url-link"
                target="_blank"
                href={url}
                rel="noreferrer"
            >
                <InsertDriveFile />
                <span>View original</span>
            </a>
        );
    }
}
