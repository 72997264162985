import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {change} from 'redux-form';

import {redux} from '@app/redux';
import {RESPONSE_FORM} from '../../../constants';
import {
    Button,
    DocumentExternalUrlLink,
    Markdown,
    PanelBody,
    PanelFooter,
    PanelHeader,
    PortalSidebar,
} from '@app/common';
import {documentDetailAdapter} from './utils';
import {documentBodySelector} from '@app/redux/documents/selectors';

interface RelatedDocumentDetailProps {
    document?: any;
    match?: any;
    getDocument?: any;
    attachDocument?: any;
    isDocumentFetching?: boolean; // Is the document being fetched from the server, i.e loading.
    editorLink?: string;
    relatedDocumentsLink?: string;
    documentId?: string;
    attachedDocument?: any;
    body?: any;
    location?: any;
    unattachDocument?: any;
    closeDocumentsSidebar?: any;
    onClickClose?: any;
}

/**
 * Document Detail Component.
 * This is used when editing a response and relating documents to it.
 */
export class RelatedDocumentDetailComponent extends React.Component<RelatedDocumentDetailProps> {
    componentDidMount() {
        this.props.getDocument(this.props.documentId);
    }

    isDocumentAttached = () => {
        const {document, attachedDocument} = this.props;
        const isDocumentAttached = attachedDocument.id === document.id;
        return isDocumentAttached;
    };

    handleClickAttachmentButton = () => {
        const isDocumentAttached = this.isDocumentAttached();
        if (isDocumentAttached) {
            this.props.unattachDocument();
        } else {
            this.props.attachDocument(this.props.documentId);
        }
        this.props.closeDocumentsSidebar();
    };

    renderLinkButton = () => {
        const {isDocumentFetching} = this.props;
        const isDocumentAttached = this.isDocumentAttached(); // document.id;
        return (
            <Button
                disabled={isDocumentFetching}
                onClick={this.handleClickAttachmentButton}
            >
                {isDocumentAttached ? 'Remove' : 'Attach'} document
            </Button>
        );
    };

    render() {
        const {document, isDocumentFetching, onClickClose, body} = this.props;
        const documentDetail = documentDetailAdapter({
            document,
            isDocumentFetching,
            isDocumentAttached: this.isDocumentAttached(),
        });
        return (
            <PortalSidebar>
                <PanelHeader
                    invertColors={true}
                    title={documentDetail.headerTitle}
                    onClose={onClickClose}
                />
                <PanelBody>
                    <section className="knowledge-base__related-document-detail">
                        <h1 className="knowledge-base__related-document-detail-title">
                            {documentDetail.documentTitle}
                        </h1>
                        <Markdown>{body}</Markdown>
                    </section>
                </PanelBody>
                {!_.isEmpty(document) && (
                    <PanelFooter>
                        {this.renderLinkButton()}{' '}
                        <DocumentExternalUrlLink url={document.external_url} />
                    </PanelFooter>
                )}
            </PortalSidebar>
        );
    }
}

/**
 * Map redux store state to props for the component.
 * @param {Object} state
 * @param {Object} props
 */
const mapStateToProps = (state) => {
    return {
        document: redux.documents.selectors.documentSelector(state),
        body: documentBodySelector(state),
        isDocumentFetching:
            redux.documents.selectors.isDocumentFetchingSelector(state),
        attachedDocument:
            redux.responses.selectors.attachedDocumentSelector(state),
    };
};

/**
 * Map action dispatching to component props.
 * Search responses, get all responses and delete a single response.
 * @param {Function} dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getDocument: (id) => {
            return dispatch(
                redux.rest.actions[redux.documents.constants.GET_DOCUMENT]({
                    id,
                }),
            );
        },
        attachDocument:
            redux.responses.dispatchers.attachDocumentDispatcher(dispatch),
        unattachDocument: () => {
            dispatch(change(RESPONSE_FORM, 'document', null));
        },
    };
};

export const RelatedDocumentDetail = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(RelatedDocumentDetailComponent),
);
