import React from 'react';
import ReactTags from 'react-tag-autocomplete';
import {connect} from 'react-redux';
import {redux} from '@app/redux';
import {getFilteredLabelsSelector} from '@app/redux/knowledge-base/selectors';
import {GET_LABELS} from '@app/redux/knowledge-base/constants';
import {get, find} from 'lodash';

interface FormLessTagsInputProps {
    label?: string;
    selectedTags?: any[];
    setSelectedTags?: any;
    getLabels?: any;
}

/**
 * Tags input tightly coupled with Redux form (all of our forms use it).
 */
export class FormLessTagsInputComponent extends React.Component<FormLessTagsInputProps> {
    state = {
        allowNew: true,
        currentInputText: '',
    };

    componentDidMount() {
        this.props.getLabels();
    }

    /**
     * Use redux-form's onChange method to propagate the delete event.
     */
    handleDelete = (i) => {
        const tags = this.props.selectedTags.slice(0);
        tags.splice(i, 1);
        this.props.setSelectedTags(tags);
    };

    /**
     * Do not allow addition of duplicate tags.
     */
    handleInputChange = (tagName) => {
        const value = this.props.selectedTags || [];
        const currentTagNames = value.map((item) => item.name.toLowerCase());
        this.setState({currentInputText: tagName});
        if (currentTagNames.includes(tagName.toLowerCase())) {
            this.setState({allowNew: false});
        } else {
            this.setState({allowNew: true});
        }
    };

    /**
     * Use redux-form's onChange method to propagate the change event.
     */
    handleAddition = (tag) => {
        const name = get(tag, 'name');
        if (name.length > 0) {
            const foundTag = find(this.props.selectedTags, {name});
            if (!foundTag) {
                const tags = [].concat(this.props.selectedTags, tag);
                this.props.setSelectedTags(tags);
            }
        }
    };

    render() {
        const {selectedTags} = this.props;
        const labels = get(this.props, ['suggestionTags']) || [];
        return (
            <div className="c-field-tags c-field-tags__form-less">
                <ReactTags
                    tags={selectedTags}
                    suggestions={labels.map((label, i) => ({
                        name: label,
                        key: i,
                    }))}
                    minQueryLength={0}
                    handleDelete={this.handleDelete}
                    handleAddition={this.handleAddition}
                    handleInputChange={this.handleInputChange}
                    maxSuggestionsLength={20}
                    allowNew={this.state.allowNew}
                    placeholder="Add labels"
                />
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    suggestionTags: getFilteredLabelsSelector(state),
});
const mapDispatchToProps = (dispatch) => ({
    getLabels: () => {
        dispatch(redux.rest.actions[GET_LABELS]());
    },
});
export const FormLessTagsInput = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FormLessTagsInputComponent);
