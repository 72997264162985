import {Spinner} from '@app/common';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import React, {
    FunctionComponent as Fc,
    useEffect,
    useContext,
    useState,
    Fragment,
} from 'react';
import {Over} from '@app/assets/icons/over';
import {Under} from '@app/assets/icons/under';
import {DistributionNode} from '@app/types/Distribution';
import {StoreContext} from '@app/state';
import './UnderRepresented.scss';

type INodeType = 'under_represented' | 'over_represented';

const QUERIES_KEY = 'queries_count';
const TITLE_KEY = 'title';

interface IUnderRepresentedProps {
    nodeType: INodeType;
    history: any;
}

export const UnderRepresented: Fc<IUnderRepresentedProps> = ({
    nodeType,
    history,
}) => {
    const {nodes} = useContext(StoreContext);

    const [underRepresented, setUnderRepresented] = useState(
        [] as DistributionNode[],
    );
    const [loadingUnderRepresented, setLoadingUnderRepresented] =
        useState(true);
    const [sortKey, setSortKey] = useState(QUERIES_KEY);
    const [sortOrder, setSortOrder] = useState(1);

    const getunderRepresented = async (type: INodeType) => {
        setLoadingUnderRepresented(true);
        const res = await nodes.getDistribution();

        setUnderRepresented(res.data[type] || []);
        setLoadingUnderRepresented(false);
    };

    const getOrderedResponses = (
        responses: DistributionNode[],
    ): DistributionNode[] => {
        return responses.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) {
                return sortOrder;
            }
            if (a[sortKey] < b[sortKey]) {
                return sortOrder * -1;
            }
            return 0;
        });
    };

    const getIcon = (nodeType: INodeType) => {
        switch (nodeType) {
            case 'under_represented':
                return <Under />;
            case 'over_represented':
                return <Over />;
            default:
                return <Under />;
        }
    };

    const getNoDataMessage = (nodeType: INodeType) => {
        switch (nodeType) {
            case 'under_represented':
                return 'There are no responses without enough queries';
            case 'over_represented':
                return 'There are no responses with too many queries';
            default:
                return 'There are no responses without enough queries';
        }
    };

    useEffect(() => {
        getunderRepresented(nodeType);
    }, [nodeType]);

    return (
        <div className="UnderRepresented__container">
            {loadingUnderRepresented ? (
                <Spinner />
            ) : underRepresented && underRepresented.length > 0 ? (
                <Fragment>
                    <div className="UnderRepresented__tableHeader">
                        <span
                            onClick={() => {
                                if (sortKey !== TITLE_KEY) {
                                    setSortKey(TITLE_KEY);
                                } else {
                                    setSortOrder(sortOrder * -1);
                                }
                            }}
                        >
                            <span>Title</span>
                            {sortKey === TITLE_KEY ? (
                                sortOrder === -1 ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                )
                            ) : null}
                        </span>
                        <span
                            onClick={() => {
                                if (sortKey !== QUERIES_KEY) {
                                    setSortKey(QUERIES_KEY);
                                } else {
                                    setSortOrder(sortOrder * -1);
                                }
                            }}
                        >
                            <span>Queries</span>
                            {sortKey === QUERIES_KEY ? (
                                sortOrder === -1 ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                )
                            ) : null}
                        </span>
                    </div>
                    <ul>
                        {getOrderedResponses(underRepresented).map((i) => {
                            return (
                                <li
                                    key={i.id}
                                    onClick={() => {
                                        history.push(`?compose=${i.id}`);
                                    }}
                                >
                                    {getIcon(nodeType)}
                                    {i.title}
                                    <small>{i.queries_count}</small>
                                </li>
                            );
                        })}
                    </ul>
                </Fragment>
            ) : (
                <div className="UnderRepresented__noData">
                    <h2>{getNoDataMessage(nodeType)}</h2>
                </div>
            )}
        </div>
    );
};
