import React from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import {BlockControls} from '../block-controls';
import {Button} from '@app/components/inputs/buttons/Button';

export const renderInactiveCTABlock = (title, buttonText) => {
    return (
        <section className="kare-block kare-block--inactive kare-block-cta">
            <h1>{title}</h1>
            <section className="choices-container">
                <div className="side-bar" />
                <Button text={buttonText} type="confirm" />
            </section>
        </section>
    );
};

interface CTABlockFieldProps {
    input?: any;
    selectDocument?: any;
    activateBlock?: any;
    removeBlock?: any;
    fields?: any;
    index?: number;
}

export const CTABlockField = (props: CTABlockFieldProps) => {
    const {index, fields, removeBlock, activateBlock, input} = props;
    const ctaBlock = get(input, ['value', 'call_to_action_block']);
    const blockClassname = cx('kare-block kare-block--inactive kare-block-cta');

    return (
        <div className="kare-block-wrapper">
            <section className={blockClassname}>
                <h1>{ctaBlock.text}</h1>
                <section className="choices-container">
                    <div className="side-bar" />
                    <Button text={ctaBlock.call_to_action} type="confirm" />
                </section>
            </section>
            {index >= 0 && fields && removeBlock && (
                <BlockControls
                    index={index}
                    fields={fields}
                    removeBlock={removeBlock}
                    activateBlock={activateBlock}
                />
            )}
        </div>
    );
};
