import React from 'react';
// @ts-ignore
import RCTooltip from 'rc-tooltip';

interface TooltipProps {
    children?: any; // The element that you click/hover on to show the tooltip
    // @todo: (Rupert) allow multiple elements in the overlay
    overlay?: any; // Contents of the tooltip
    tooltipProps?: any;
    isSimple?: boolean;
}

export class Tooltip extends React.Component<TooltipProps> {
    render() {
        const {children, tooltipProps, overlay, isSimple} = this.props;
        // TODO(rupertrutland): Try and stop propagation of clicks here.
        return (
            <RCTooltip
                overlay={<div className="c-tooltip-overlay">{overlay}</div>}
                arrowContent={<div className="rc-tooltip-arrow-inner" />}
                trigger={['click']}
                destroyTooltipOnHide={true}
                overlayClassName={isSimple ? 'rc-tooltip--is-simple' : ''}
                {...tooltipProps}
            >
                {children}
            </RCTooltip>
        );
    }
}
