import React from 'react';
import Icon from 'react-icon-base';

export const Automation = (props) => {
    return (
        <Icon viewBox="0 0 15 15" {...props}>
            <svg viewBox="0 0 15 15" width="15" height="12">
                <defs>
                    <path
                        d="M13.7317473,4.62703312 L10.8120694,5.98954949 L8.92238894,4.21341208 L8.92238894,3.07798177 L10.8120694,1.30184437 L13.7317473,2.66436073 C14.0399355,2.81034463 14.3967851,2.67247095 14.542769,2.37239294 C14.6887529,2.06420471 14.5508792,1.70735519 14.2508012,1.56137129 L11.0715963,0.0772016766 C10.7634081,-0.0687822201 10.3984483,-0.00390048823 10.1551418,0.239406006 L8.74396418,1.53704064 C8.59798028,1.34239545 8.37089422,1.21263198 8.11136729,1.21263198 C7.66530538,1.21263198 7.30034564,1.57759173 7.30034564,2.02365363 L7.30034564,2.83467528 L4.7212968,2.83467528 C4.33200641,1.73979605 3.22901697,0.993656139 1.94760277,1.2694035 C1.00681765,1.47215891 0.203906222,2.28318056 0.0335916761,3.2401861 C-0.144833086,4.31073468 0.406661634,5.24340957 1.23390372,5.73813278 L3.31011913,12.5669351 L0.812172458,12.5669351 L0.812172458,15 L11.3554539,15 L11.3554539,12.5669351 L8.41955552,12.5669351 L4.38877793,5.08120524 C4.52665161,4.88656005 4.64019464,4.68380464 4.7212968,4.45671858 L7.30034564,4.45671858 L7.30034564,5.26774022 C7.30034564,5.71380213 7.66530538,6.07876187 8.11136729,6.07876187 C8.37089422,6.07876187 8.59798028,5.94899841 8.74396418,5.75435321 L10.1551418,7.05198785 C10.3984483,7.29529434 10.7634081,7.36017608 11.0715963,7.21419218 L14.2508012,5.73002256 C14.5589894,5.58403867 14.6887529,5.22718914 14.542769,4.91900092 C14.3967851,4.61892291 14.0399355,4.48104923 13.7317473,4.62703312 Z M2.43421575,4.45671858 C1.98815385,4.45671858 1.62319411,4.09175883 1.62319411,3.64569693 C1.62319411,3.19963502 1.98815385,2.83467528 2.43421575,2.83467528 C2.88027766,2.83467528 3.2452374,3.19963502 3.2452374,3.64569693 C3.2452374,4.09175883 2.88027766,4.45671858 2.43421575,4.45671858 Z"
                        id="path-888888"
                    />
                </defs>
                <g
                    id="action-automation-icon"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <mask id="mask-888888" fill="white">
                        <use xlinkHref="#path-888888" />
                    </mask>
                    <use
                        id="Shape"
                        fill="currentColor"
                        fillRule="nonzero"
                        xlinkHref="#path-888888"
                    />
                </g>
            </svg>
        </Icon>
    );
};
