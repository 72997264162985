import React from 'react';
import {ResponsiveTreeMapHtml} from '@nivo/treemap';

import './TreeMap.scss';
import {transformClustersDataToTreeMap} from '@app/utils/Clusters';

interface ITreeMapProps {
    data: any;
    onClick: any;
}

export const TreeMap: React.FunctionComponent<ITreeMapProps> = ({
    data,
    onClick,
}) => {
    const treemapData = transformClustersDataToTreeMap(data);

    return (
        <ResponsiveTreeMapHtml
            data={treemapData}
            identity="id"
            value="value"
            innerPadding={2}
            outerPadding={2}
            tile={'binary'}
            margin={{top: 10, right: 10, bottom: 10, left: 10}}
            label={(d: any) => `${d.data?.name}`}
            leavesOnly
            onClick={(d) => {
                onClick(d?.data);
            }}
            colors={(d) => d.data?.color}
            labelTextColor={'white'}
            borderColor={{from: 'color', modifiers: [['darker', 0.3]]}}
            animate={false}
            orientLabel={false}
            motionConfig={{
                tension: 90,
                damping: 11,
            }}
            nodeOpacity={1}
        />
    );
};
