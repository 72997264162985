export const types = {
    SUBMIT: 'integrations/SUBMIT',
    SUBMIT_SUCCESS: 'integrations/SUBMIT_SUCCESS',
    SUBMIT_FAILURE: 'integrations/SUBMIT_FAILURE',
};

export const services = {
    ZENDESK: 'zendesk',
};

export const GET_SERVICES = 'authoredServices';

export const ZENDESK_IMPORT = 'zendeskImport';
export const ZENDESK_DISCONNECT = 'zendeskDisconnect';
