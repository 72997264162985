import moment from 'moment';

import {QueriesResponse} from '@app/types/Queries';
import {DATA_KEYS, getChartColor} from './DataRenderingUtils';

export const transformQueriesDataToBarChart = (
    queriesData: QueriesResponse,
) => {
    const c = queriesData.counters;
    const queries = c.reduce((a, b) => a + (b.counters.queries || 0), 0);

    return {
        counters: c
            .sort((a, b) => a.timestamp - b.timestamp)
            .map((d) => {
                return {
                    timeseries: moment.unix(d.timestamp).format('DD-MM'),
                    queries: d.counters.queries,
                    queriesColor: getChartColor(DATA_KEYS.RESOLVED),
                };
            }),
        totals: {queries},
    };
};

export const transformQueriesDataToGroupedBarChart = (
    queriesData: QueriesResponse,
) => {
    const c = queriesData.counters.sort((a, b) => a.timestamp - b.timestamp);

    const resolved = c.reduce(
        (a, b) => a + (b.counters ? b.counters.responses || 0 : 0 || 0),
        0,
    );

    // successful =
    const handed = c.reduce(
        (a, b) => a + (b.counters ? b.counters.choices || 0 : 0 || 0),
        0,
    );
    const missing = c.reduce(
        (a, b) => a + (b.counters ? b.counters.missing || 0 : 0 || 0),
        0,
    );
    const queries = c.reduce(
        (a, b) => a + (b.counters ? b.counters.queries || 0 : 0 || 0),
        0,
    );
    return {
        counters: queriesData.counters.map((d) => {
            const answered = d.counters
                ? d.counters.queries - d.counters.choices - d.counters.missing
                : 0;
            const uncertain = d.counters ? d.counters.choices : 0;
            const missing = d.counters ? d.counters.missing : 0;
            return {
                timeseries: moment.unix(d.timestamp).format('DD-MM'),
                answered: answered || 0,
                answeredColor: d.counters
                    ? getChartColor(DATA_KEYS.RESOLVED)
                    : 0,
                uncertain: uncertain || 0,
                uncertainColor: d.counters
                    ? getChartColor(DATA_KEYS.HANDED)
                    : 0,
                missing: missing || 0,
                missingColor: d.counters
                    ? getChartColor(DATA_KEYS.ESCALATED)
                    : 0,
            };
        }),
        totals: {
            resolved: resolved || 0,
            handed: handed || 0,
            missing: missing || 0,
            queries: queries || 0,
        },
    };
};
