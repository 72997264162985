import {
    CHANGE_SELECTED_CONVERSATION,
    SELECTED_CONVERSATION_ID,
} from '../constants';

const initialState = {
    [SELECTED_CONVERSATION_ID]: undefined,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_SELECTED_CONVERSATION:
            return {...state, [SELECTED_CONVERSATION_ID]: action.payload.id};
        default:
            return state;
    }
};
