import React from 'react';
import {NavLink as RouterNavLink} from 'react-router-dom';
import cx from 'classnames';

import {isAnyRouteActive} from './utils';

interface NavLinkProps {
    children?: any;
    onClick?: any;
    to: string;
    exact?: boolean;
    external?: boolean;
    bottom?: boolean;
    highlight?: boolean;
    activeRoutes?: any[];
    isInverted?: boolean;
}

export const NavLink = ({
    exact = true,
    activeRoutes,
    children,
    to,
    external,
    isInverted,
    onClick = () => {},
}: NavLinkProps) => {
    const linkClass = cx('kare-nav-link', {
        'kare-nav-link--is-inverted': isInverted,
    });

    const isNavLinkActive = (match, location) => {
        const routes = activeRoutes ? [to, ...activeRoutes] : [to];
        return match || isAnyRouteActive(routes, location.pathname);
    };

    return external ? (
        <a className={linkClass} href={to} target="_blank" rel="noreferrer">
            {children}
        </a>
    ) : (
        <RouterNavLink
            className={linkClass}
            activeClassName="is-active"
            exact={exact}
            isActive={isNavLinkActive}
            to={to}
            onClick={onClick}
        >
            {children}
        </RouterNavLink>
    );
};
