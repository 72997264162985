import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Close, Edit, Publish, Settings} from '@material-ui/icons';
import cx from 'classnames';

import './ChatterTable.scss';
import {
    deleteChatterMessage,
    listOnlyChatterNodes,
    modifyStatusChatterMessage,
    unpublishChatterMessage,
} from './Api';

interface ChatterTableProps {
    activeTab: string;
}

export const ChatterTable: React.FC<ChatterTableProps> = (props) => {
    const history = useHistory();
    const [chatterMessages, setChatterMessages] = useState<any[]>([]);
    useEffect(() => {
        listOnlyChatterNodes((list: any[]) => {
            setChatterMessages(list);
        });
    }, [history.location.search]);

    const deleteNode = (node) => {
        if (node.status === 'published') {
            unpublishChatterMessage(node.id, () => {
                listOnlyChatterNodes((list: any[]) => {
                    setChatterMessages(list);
                });
            });
        } else {
            deleteChatterMessage(node.id, () => {
                listOnlyChatterNodes((list: any[]) => {
                    setChatterMessages(list);
                });
            });
        }
    };

    const promote = (node) => {
        if (node.status === 'unpublished') {
            modifyStatusChatterMessage(node.id, 'published', () => {
                listOnlyChatterNodes((list: any[]) => {
                    setChatterMessages(list);
                });
            });
        } else if (node.status === 'deleted') {
            modifyStatusChatterMessage(node.id, 'unpublished', () => {
                listOnlyChatterNodes((list: any[]) => {
                    setChatterMessages(list);
                });
            });
        }
    };

    const filteredMessages = chatterMessages.filter(
        (c) => c.status === props.activeTab,
    );

    return (
        <div className="ChatterTable__root">
            <div className="ChatterTable__table">
                {filteredMessages.map((chatter) => {
                    const chatterMessageClass = cx('ChatterTable__container', {
                        ['ChatterTable__chatterUnpublished']:
                            chatter.status === 'unpublished',
                        ['ChatterTable__noBottomBorder']:
                            filteredMessages.length === 1,
                    });
                    return (
                        <div key={chatter.id} className={chatterMessageClass}>
                            <div>
                                <Settings />
                                {chatter.title}
                            </div>
                            <div className="ChatterTable__icons">
                                <Edit
                                    onClick={() => {
                                        history.push(`?compose=${chatter.id}`);
                                    }}
                                />
                                {(chatter.status === 'unpublished' ||
                                    chatter.status === 'deleted') && (
                                    <Publish
                                        onClick={() => {
                                            promote(chatter);
                                        }}
                                    />
                                )}
                                {(chatter.status === 'published' ||
                                    chatter.status === 'unpublished') && (
                                    <Close
                                        onClick={() => {
                                            deleteNode(chatter);
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
