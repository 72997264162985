import React, {Component, Fragment} from 'react';
import axios from 'axios';
import {addNotification} from 'reapop';
import {differenceBy, get, uniqBy} from 'lodash';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {Button} from '@app/common';
import {FastForward, FastRewind} from '@app/common/icons';
import {QUERY_LIST_FORM} from '@app/redux/improve/queries/constants';
import {redux} from '@app/redux';

import Notification from '@app/utils/notifications';
import {
    decreaseSelectedCardIndexAction,
    increaseSelectedCardIndexAction,
} from '@app/redux/improve/cards/actions';
import {dismissCluster} from '../query-list/remove-queries';
import {clustersSelector} from '@app/redux/improve/cards/selectors';
import {MdArrowBack, MdSnooze} from 'react-icons/md';
import {withRouter} from 'react-router-dom';

interface QueriesCardActionsProps {
    numberOfSelectedQueries?: number;
    approveResponse?: any;
    dismissCluster?: any;
    pristine?: boolean;
    selectedClusterIndex?: number;
    clustersToSkip?: any[];
    clusterLimit?: number;
    isLoading?: boolean;
    isNewResponse?: boolean;
    hasResponses?: boolean;
    selectedResponse?: any;
    cluster?: any;
    responseData?: any;
    skipCard?: any;
    previousCard?: any;
    createAndApproveResponse?: any;
    showError?: any;
    isCardEmpty?: boolean;
    isFirstCluster?: boolean;
    selectedQueries?: number;
    getCluster?: any;
    preloadId?: string;
    history?: any;
    origin?: any;
}

export class QueriesCardActionsComponent extends Component<QueriesCardActionsProps> {
    getDifferentQueries = () => {
        const {cluster, selectedQueries} = this.props;
        const queries = get(cluster, 'queries', []);
        return differenceBy(queries, selectedQueries, 'id');
    };

    removeQueriesFromCluster = () => {
        const {cluster} = this.props;
        const differentQueries = this.getDifferentQueries();
        return axios
            .patch(`/v3.0/improve/clusters/${cluster.id}`, {
                cluster: {
                    approved: differentQueries.length === 0,
                    queries: differentQueries,
                },
            })
            .then((r) => {
                if (r.status === 200) {
                    this.props.getCluster(this.props.cluster.id);
                }
            });
    };

    handleClickApprove = () => {
        this.props
            .approveResponse(this.props, this.getDifferentQueries())
            .then(() => {
                this.removeQueriesFromCluster().catch(() => {
                    return this.props.showError();
                });
            })
            .catch(() => {
                return this.props.showError();
            });
    };

    handleSkipClick = () => {
        this.props.skipCard();
    };
    handlePreviousClick = () => {
        this.props.previousCard();
    };
    handleDismissClick = (preloadId = undefined) => {
        return this.props.dismissCluster(preloadId);
    };

    isPreviousDisabled = () => {
        const {clustersToSkip} = this.props;
        let isSomePreviousClusterAvailable = clustersToSkip.length === 0;

        for (let i = this.props.selectedClusterIndex - 1; i >= 0; i--) {
            if (!clustersToSkip.includes(i)) {
                isSomePreviousClusterAvailable = true;
            }
        }

        return this.props.isFirstCluster || !isSomePreviousClusterAvailable;
    };

    isNextDisabled = () => {
        let isSomeNextClusterAvailable = false;
        const clustersLength = get(this.props, [
            'clusters',
            'clusters',
            'length',
        ]);

        for (
            let i = this.props.selectedClusterIndex;
            i < this.props.clusterLimit;
            i++
        ) {
            if (!this.props.clustersToSkip.includes(i)) {
                isSomeNextClusterAvailable = true;
            }
        }

        return (
            this.props.isCardEmpty ||
            !isSomeNextClusterAvailable ||
            clustersLength === this.props.selectedClusterIndex + 1
        );
    };

    render() {
        const {selectedQueries} = this.props;
        return (
            <div className="queries-card-actions">
                <div className="queries-card-actions__left-actions">
                    {!this.props.preloadId ? (
                        <Button
                            className="queries-card-actions__dismiss"
                            onClick={() => this.handleDismissClick()}
                            disabled={this.props.isCardEmpty}
                            inverted
                        >
                            <MdSnooze size={16} /> <span>Snooze</span>
                        </Button>
                    ) : (
                        <Fragment>
                            <Button
                                className="queries-card-actions__dismiss"
                                onClick={() => {
                                    this.props.history.push(this.props.origin);
                                }}
                                inverted
                            >
                                <MdArrowBack size={16} /> <span>Back</span>
                            </Button>
                            <Button
                                className="queries-card-actions__dismiss"
                                onClick={() => {
                                    this.handleDismissClick(
                                        this.props.preloadId,
                                    ).then(() => {
                                        this.props.history.push(
                                            this.props.origin,
                                        );
                                    });
                                }}
                                disabled={this.props.isCardEmpty}
                                inverted
                            >
                                <MdSnooze size={16} /> <span>Snooze</span>
                            </Button>
                        </Fragment>
                    )}
                </div>

                <div className="queries-card-actions__right-actions">
                    {!this.props.preloadId && (
                        <Fragment>
                            <Button
                                className="queries-card-actions__previous"
                                onClick={this.handlePreviousClick}
                                disabled={this.isPreviousDisabled()}
                                inverted
                            >
                                <FastRewind size={16} /> <span>Previous</span>
                            </Button>
                            <Button
                                className="queries-card-actions__skip"
                                onClick={this.handleSkipClick}
                                disabled={this.isNextDisabled()}
                                inverted
                            >
                                <FastForward size={16} /> <span>Next</span>
                            </Button>
                        </Fragment>
                    )}

                    <Button
                        className="queries-card-actions__accept-response"
                        onClick={this.handleClickApprove}
                        showSpinner={this.props.isLoading}
                        disabled={
                            !this.props.selectedResponse ||
                            (selectedQueries && selectedQueries === 0)
                        }
                    >
                        <span>APPROVE RESPONSE</span>
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        numberOfSelectedQueries:
            redux.improve.queries.selectors.getNumberOfSelectedQueriesFromQueryList(
                state,
            ),
        isCardEmpty: redux.improve.cards.selectors.areClustersEmpty(state),
        clusterLimit: redux.improve.cards.selectors.getClusterLimit(state),
        isLoading: redux.improve.cards.selectors.isClusterPatchingError(state),
        isFirstCluster:
            redux.improve.cards.selectors.isFirstClusterSelector(state),
        isNewResponse: redux.improve.responses.selectors.isNewResponse(state),
        hasResponses:
            redux.knowledgeBase.selectors.numPublishedResponsesSelector(state) >
            0,
        responseData:
            redux.improve.responses.selectors.getResponseFormData(state),
        selectedQueriesFromQueryListForm:
            redux.improve.queries.selectors.getSelectedQueriesFromQueryListForm(
                state,
            ),
        selectedClusterIndex:
            redux.improve.cards.selectors.getSelectedClusterIndex(state),
        selectedQueries:
            redux.improve.queries.selectors.getSelectedQueries(state),
        clustersToSkip: redux.improve.cards.selectors.getClustersToSkip(state),
        cluster: redux.improve.cards.selectors.getCluster(state),
        clusters: clustersSelector(state),
        selectedResponse:
            redux.responses.selectors.improvePageResponseSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    approveResponse: (props, pendingQueries) => {
        const queries = uniqBy(props.selectedQueries, 'query');
        const selectedResponse = props.selectedResponse;
        const {preloadId} = props;
        const currentQueries =
            get(selectedResponse, ['response', 'queries'], []) || [];
        if (selectedResponse.id) {
            return axios
                .put(`/v3.0/kbm/nodes/${selectedResponse.id}`, {
                    ...selectedResponse,
                    response: {
                        ...selectedResponse.response,
                        type: 'response',
                        queries: [
                            ...currentQueries,
                            ...queries.map((q) => q.query),
                        ],
                    },
                })
                .then(() => {
                    if (preloadId) {
                        if (pendingQueries.length === 0) {
                            props.history.push(props.origin);
                        }
                    } else {
                        dispatch(
                            redux.rest.actions[
                                redux.responses.constants
                                    .GET_IMPROVE_PAGE_RESPONSE
                            ].reset(),
                        );
                        return dispatch(increaseSelectedCardIndexAction());
                    }
                });
        } else {
            return axios
                .post(`/v3.0/kbm/nodes`, {
                    ...selectedResponse,
                    response: {
                        ...selectedResponse.response,
                        type: 'response',
                        queries: [
                            ...currentQueries,
                            ...queries.map((q) => q.query),
                        ],
                    },
                })
                .then(() => {
                    if (preloadId) {
                        if (pendingQueries.length === 0) {
                            props.history.push(props.origin);
                        }
                    } else {
                        dispatch(
                            redux.rest.actions[
                                redux.responses.constants
                                    .GET_IMPROVE_PAGE_RESPONSE
                            ].reset(),
                        );
                        return dispatch(increaseSelectedCardIndexAction());
                    }
                });
        }
    },
    // TODO(rr): Reafactor this. The method has "and" in it. :/
    skipCard: () => {
        dispatch(increaseSelectedCardIndexAction());
        dispatch(
            redux.rest.actions[
                redux.responses.constants.GET_IMPROVE_PAGE_RESPONSE
            ].reset(),
        );
    },
    previousCard: () => {
        dispatch(decreaseSelectedCardIndexAction());
        dispatch(
            redux.rest.actions[
                redux.responses.constants.GET_IMPROVE_PAGE_RESPONSE
            ].reset(),
        );
    },
    dismissCluster: (preloadId) => dispatch(dismissCluster(preloadId)),
    showError: () => dispatch(addNotification(Notification.networkError())),
    getCluster: (id) => {
        dispatch(redux.rest.actions[redux.improve.constants.GET_CLUSTER]({id}));
    },
});

export const QueriesCardActions = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(
        reduxForm({
            form: QUERY_LIST_FORM,
        })(QueriesCardActionsComponent),
    ),
);
