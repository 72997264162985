import React from 'react';

import './RadialChartsBottomLegend.scss';

export interface RadialChartsBottomLegendProps {
    firstSource: {
        ratio: number;
        value: number;
        label: string;
        color: string;
        icon: any;
    };
    secondSource: {
        ratio: number;
        value: number;
        label: string;
        color: string;
        icon: any;
    };
}

export const RadialChartsBottomLegend: React.FC<
    RadialChartsBottomLegendProps
> = ({firstSource, secondSource}) => {
    const FirstIcon = firstSource.icon;
    const SecondIcon = secondSource.icon;
    return (
        <div className="RadialChartsBottomLegend__container">
            <div>
                <h3>
                    <FirstIcon style={{color: firstSource.color}} />{' '}
                    {firstSource.label}
                </h3>
                <span>
                    <strong>{firstSource.ratio}</strong>%
                </span>
                <small>{firstSource.value}</small>
            </div>
            <div>
                <h3>
                    <SecondIcon style={{color: secondSource.color}} />
                    {secondSource.label}
                </h3>
                <span>
                    <strong>{secondSource.ratio}</strong>%
                </span>
                <small>{secondSource.value}</small>
            </div>
        </div>
    );
};
