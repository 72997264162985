import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {mapValues, get, set, unset} from 'lodash';
import {getFormValues, SubmissionError} from 'redux-form';
import {addNotification as notify} from 'reapop';

import {ErrorBoundary, PanelItem, Spinner} from '@app/common';
import {CompanyForm} from './company-form';
import {WidgetForm} from './widget-form';
import {clientUrl} from '../utils';
import {PERMISSION_KEYS} from '@app/constants';
import {KnowledgeManagement} from './knowledge-management';
import {redux} from '@app/redux';
import {
    FIELDS,
    WIDGET_FORM_NAME,
    widgetDefaults,
} from './widget-form/constants';
import {updateOrg} from './utils';
import Notification from '@app/utils/notifications';
import {ApplicationForm} from './application-form';
import {ApplicationInfo} from './application-info';
import {UserSettings} from './user-settings';
import {Automations} from './automations-form';
import {hasScope} from '@app/utils/auth';
import axios from 'axios';

interface SettingsProps {
    updateOrg?: any;
    values?: any;
    createApplication?: any;
    updateAccount?: any;
    getWidget?: any;
    updateWidget?: any;
    history?: any;
    widget?: any;
    location?: any;
    object?: any;
    org?: any;
    applicationId?: string;
    match: any;
    addTextCopiedNotification?: any;
    email?: string;
    scopes?: any[];
    isAppLoading?: boolean;
}

export class SettingsComponent extends React.Component<SettingsProps> {
    state = {
        showModal: false,
    };

    showModal = () => {
        this.setState({showModal: true});
    };

    handleSubmitCompanyForm = (values) => {
        const {org} = this.props;
        this.props.updateOrg(
            {
                ...values,
                escalation_entries: values?.escalation_entries.map((val) => {
                    if (!val.value) val.value = '';
                    return val;
                }),
            },
            org,
        );
    };

    handleSubmitWidgetForm = (id) => {
        return (values) => {
            const image = get(values, [FIELDS.AVATAR_IMAGE], []);
            if (image && image.file) {
                const formData = new FormData();
                formData.append('asset', image.file);
                axios
                    .post<FormData>(`v3.0/kbm/assets/pictures`, formData)
                    .then((response: any) => {
                        if (response && response.data && response.data.url) {
                            set(values, [FIELDS.AVATAR_IMAGE], {
                                title: response.data.name,
                                blob: response.data.url,
                                size: image.size,
                            });
                            set(values, [FIELDS.SHOW_POPOVER], false);
                            set(values, [FIELDS.SHOW_POPOVER_NODE], false);
                            const showWidgetMenu = get(
                                values,
                                [FIELDS.SHOW_WIDGET_MENU],
                                false,
                            );
                            this.cleanWidgetMenuSettings(
                                showWidgetMenu,
                                values,
                            );
                            this.props.updateWidget(id, values);
                        }
                    });
            } else {
                if (!image.blob) {
                    set(values, [FIELDS.SHOW_AVATAR], false);
                    set(values, [FIELDS.AVATAR_IMAGE], undefined);
                }
                set(values, [FIELDS.SHOW_POPOVER], false);
                set(values, [FIELDS.SHOW_POPOVER_NODE], false);
                const showWidgetMenu = get(
                    values,
                    [FIELDS.SHOW_WIDGET_MENU],
                    false,
                );
                this.cleanWidgetMenuSettings(showWidgetMenu, values);
                this.props.updateWidget(id, values);
            }
        };
    };

    cleanWidgetMenuSettings(showWidgetMenu, values) {
        if (!showWidgetMenu) {
            unset(values, [FIELDS.WEB_EXTENSIONS]);
            if (this.props.org.webext_hostname) {
                values[FIELDS.WEB_EXTENSIONS] = {
                    HOSTNAME: this.props.org.webext_hostname,
                };
            }
        } else {
            const actions = get(
                values,
                [FIELDS.WEB_EXTENSIONS, FIELDS.WEB_EXTENSIONS_ACTIONS],
                [],
            ).filter((a) => a.enabled);
            const links = get(
                values,
                [FIELDS.WEB_EXTENSIONS, FIELDS.WEB_EXTENSIONS_LINKS],
                [],
            ).filter((a) => a.url !== '');
            if (actions.length === 0 && links.length === 0) {
                unset(values, [FIELDS.WEB_EXTENSIONS]);
                set(values, [FIELDS.SHOW_WIDGET_MENU], false);
            }

            if (actions.length > 0) {
                set(
                    values,
                    [FIELDS.WEB_EXTENSIONS, FIELDS.WEB_EXTENSIONS_ACTIONS],
                    actions,
                );
            } else {
                unset(values, [
                    FIELDS.WEB_EXTENSIONS,
                    FIELDS.WEB_EXTENSIONS_ACTIONS,
                ]);
            }
        }
    }

    render() {
        const widgetScriptUrl = clientUrl.getWidgetScriptUrl();
        const {
            applicationId,
            widget,
            location,
            addTextCopiedNotification,
            email,
            scopes,
            values,
            isAppLoading,
        } = this.props;
        const hasSettingsScope = hasScope(
            scopes,
            PERMISSION_KEYS.MIND.SETTINGS,
        );
        const tabs = [];
        if (hasSettingsScope) {
            tabs.push('company');
        }
        tabs.push('user-settings');
        if (hasSettingsScope) {
            tabs.push('widget');
        }
        if (hasSettingsScope) {
            tabs.push('knowledge', 'automations');
        }

        const appIdValid = applicationId && applicationId !== '';

        const tabName = this.props.match.params.tab.split('/')[0];
        const indexOfTab = tabs.indexOf(tabName);
        const selectedIndex = indexOfTab === -1 ? 0 : indexOfTab;
        const widgetInitialValues = mapValues(widgetDefaults, (value, key) => {
            return widget[key] !== undefined ||
                [
                    FIELDS.SHOW_AVATAR,
                    FIELDS.AVATAR_IMAGE,
                    FIELDS.CALL_TO_ACTION,
                    FIELDS.POPOVER_TEXT,
                    FIELDS.POPOVER_NODE,
                    FIELDS.LOGO_URL,
                    FIELDS.DRAG_AND_DROP_ENABLED,
                    FIELDS.RESIZE_CONTROLS_ENABLED,
                    FIELDS.RELOAD_CONVERSATION_ENABLED,
                    FIELDS.CUSTOM_CSS,
                    FIELDS.SHOW_WIDGET_MENU,
                    FIELDS.WEB_EXTENSIONS,
                    FIELDS.SHOW_ESCALATION_BUTTON,
                    FIELDS.ENABLE_TRACKING_BETA,
                    FIELDS.ENABLE_TRACKING_TRY_NOW_PAGE,
                ].includes(key)
                ? widget[key]
                : value;
        });

        if (!widgetInitialValues[FIELDS.WIDGET_POPOVER]) {
            if (widgetInitialValues[FIELDS.SHOW_POPOVER_NODE]) {
                widgetInitialValues[FIELDS.WIDGET_POPOVER] =
                    FIELDS.POPOVER_NODE;
            } else if (widgetInitialValues[FIELDS.SHOW_POPOVER]) {
                widgetInitialValues[FIELDS.WIDGET_POPOVER] =
                    FIELDS.POPOVER_TEXT;
            }
        }
        return (
            <ErrorBoundary>
                {isAppLoading || !email ? (
                    <Spinner withCenteredContainer />
                ) : (
                    <div className="kare-console-settings">
                        <Helmet title="Settings" />
                        <Tabs
                            selectedIndex={selectedIndex}
                            onSelect={(index) => {
                                return this.props.history.push(
                                    `/settings/${tabs[index]}`,
                                );
                            }}
                        >
                            <div className="u-margin-bottom">
                                <TabList className="react-tabs__tab-list">
                                    {tabs.map((tab) => (
                                        <Tab key={tab}>
                                            {tab.replace('-', ' ')}
                                        </Tab>
                                    ))}
                                </TabList>
                            </div>
                            {hasSettingsScope && (
                                <TabPanel>
                                    <div className="u-margin-bottom">
                                        <PanelItem>
                                            <ErrorBoundary>
                                                <CompanyForm
                                                    onSubmit={
                                                        this
                                                            .handleSubmitCompanyForm
                                                    }
                                                    initialValues={
                                                        this.props.org
                                                    }
                                                    showModal={this.showModal}
                                                />
                                            </ErrorBoundary>
                                        </PanelItem>
                                    </div>
                                </TabPanel>
                            )}
                            <TabPanel>
                                <div className="u-margin-bottom">
                                    <PanelItem>
                                        <ErrorBoundary>
                                            <UserSettings
                                                email={email}
                                                scopes={scopes}
                                            />
                                        </ErrorBoundary>
                                    </PanelItem>
                                </div>
                            </TabPanel>
                            {hasSettingsScope && appIdValid && (
                                <TabPanel>
                                    <PanelItem>
                                        <ErrorBoundary>
                                            <WidgetForm
                                                /*@ts-ignore*/
                                                onSubmit={this.handleSubmitWidgetForm(
                                                    applicationId,
                                                )}
                                                initialValues={
                                                    widgetInitialValues
                                                }
                                                applicationId={applicationId}
                                            />
                                        </ErrorBoundary>
                                    </PanelItem>
                                    <div className="u-margin-bottom">
                                        <PanelItem label="Client Application">
                                            <ErrorBoundary>
                                                {applicationId ? (
                                                    <ApplicationInfo
                                                        applicationId={
                                                            applicationId
                                                        }
                                                        widgetScriptUrl={
                                                            widgetScriptUrl
                                                        }
                                                        addTextCopiedNotification={
                                                            addTextCopiedNotification
                                                        }
                                                        customLauncher={
                                                            values &&
                                                            values[
                                                                'FAB_STYLE'
                                                            ] === 'CUSTOM'
                                                        }
                                                    />
                                                ) : (
                                                    <ApplicationForm
                                                        location={location}
                                                        onSubmit={
                                                            this.props
                                                                .createApplication
                                                        }
                                                    />
                                                )}
                                            </ErrorBoundary>
                                        </PanelItem>
                                    </div>
                                </TabPanel>
                            )}
                            {hasSettingsScope && (
                                <Fragment>
                                    {' '}
                                    <TabPanel>
                                        <div className="u-margin-bottom">
                                            <KnowledgeManagement
                                                showModal={this.showModal}
                                            />
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="u-margin-bottom">
                                            <Automations />
                                        </div>
                                    </TabPanel>
                                </Fragment>
                            )}
                        </Tabs>
                    </div>
                )}
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        org: redux.org.selectors.orgSelector(state),
        widget: redux.widget.selectors.widgetSelector(state),
        values: getFormValues(WIDGET_FORM_NAME)(state),
        applicationId: redux.application.selectors.applicationIdSelector(state),
        email: redux.user.selectors.emailSelector(state),
        scopes: redux.user.selectors.scopesSelector(state),
        isAppLoading:
            redux.application.selectors.isApplicationLoadingSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateOrg: updateOrg(dispatch),
        updateWidget: (id, params) => {
            dispatch(
                redux.rest.actions[redux.widget.constants.UPDATE_WIDGET](
                    {id},
                    {body: JSON.stringify(params)},
                ),
            )
                .then(() => {
                    dispatch(notify(Notification.widgetSettingsUpdated()));
                    // We have to fetch the data again the the form does not remain "dirty"
                    // This is because the update is actually a POST request, and returns an empty response.
                    return dispatch(
                        redux.rest.actions[redux.widget.constants.GET_WIDGET]({
                            id,
                        }),
                    );
                })
                .catch((error) => {
                    throw new SubmissionError(error);
                });
        },
        createApplication: (params) =>
            dispatch(
                redux.rest.actions[
                    redux.application.constants.APPLICATION
                ].post({}, {body: JSON.stringify(params)}),
            )
                .then(() => {
                    return dispatch(notify(Notification.applicationCreated()));
                })
                .catch((error) => {
                    throw new SubmissionError(error);
                }),
        addTextCopiedNotification: (title) => {
            dispatch(notify(Notification.textCopiedToClipboard(title)));
        },
    };
};

export const Settings = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingsComponent);
