import React, {useContext, useEffect, useState, Fragment} from 'react';
import cx from 'classnames';
import {get} from 'lodash';

import {
    ArrowDropDown,
    ArrowDropUp,
    ChatBubble,
    LibraryBooks,
    Textsms,
    ThumbDown,
    ThumbUp,
} from '@material-ui/icons';
import {MdChatBubble} from 'react-icons/md';

import './Impressions.scss';
import {Card} from '@app/components/Card/Card';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {StoreContext} from '@app/state';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {LabelFilter} from '@app/components/LabelFilter/LabelFilter';

export interface ImpressionsProps {
    history: any;
}
interface ImpressionsData {
    data: any;
    loading: boolean;
    error: string;
}
const defaultState: ImpressionsData = {data: {}, loading: true, error: ''};
const defaultTagsState = {data: null, loading: true, error: ''};
const TITLE_KEY = 'title';
const IMPRESSIONS_KEY = 'impressions';
const THUMBSUP_KEY = 'feedback_up';
const THUMBSDOWN_KEY = 'feedback_down';

export const Impressions: React.FC<ImpressionsProps> = ({history}) => {
    const {
        analytics,
        library,
        date: {minDate, maxDate},
    } = useContext(StoreContext);

    const [data, setData] = useState<ImpressionsData>(defaultState);
    const [labelsToFilter, setLabelsToFilter] = useState([]);
    const [activeSort, setActiveSort] = useState(IMPRESSIONS_KEY);
    const [tags, setTags] = useState(defaultTagsState);
    const [sortStates, setSortStates] = useState({
        [TITLE_KEY]: 1,
        [IMPRESSIONS_KEY]: -1,
        [THUMBSUP_KEY]: 1,
        [THUMBSDOWN_KEY]: 1,
    });

    const getAnalytics = async () => {
        setData(defaultState);
        const res = await analytics.getAggregatedResolutionCountersByNode(
            minDate,
            maxDate,
        );
        setData({data: res.data, error: res.error, loading: false});
    };
    const getTags = async () => {
        setTags(defaultTagsState);
        const res = await library.getTags();
        setTags({
            data: get(res, ['data', 'tags'], null),
            error: res.error,
            loading: false,
        });
    };

    useEffect(() => {
        getAnalytics();
        getTags();
    }, [minDate, maxDate]);

    const getEntryIcon = (entryType: string) => {
        switch (entryType) {
            case 'content':
                return <LibraryBooks />;
            case 'response':
                return <MdChatBubble />;
            case 'chattering':
                return <Textsms />;
            default:
                return <ChatBubble />;
        }
    };

    const getSortIcons = (state: number) => {
        if (state === -1) {
            return <ArrowDropDown />;
        }
        return <ArrowDropUp />;
    };

    const handleSort = (sortKey: string) => {
        if (activeSort !== sortKey) {
            setActiveSort(sortKey);
        } else {
            setSortStates({
                ...sortStates,
                [sortKey]: sortStates[sortKey] * -1,
            });
        }
    };

    const showEmptyState = () => {
        if (
            !data ||
            !data.data ||
            !data.data.entries ||
            data.data.entries.length === 0
        ) {
            return 'empty';
        }

        return '';
    };
    return (
        <Card>
            <CardTitle title={'Impressions by responses and content'} />
            {tags.data && tags.data.length > 0 && (
                <div className="Impressions__labelsFilterContainer">
                    <h4>Show only</h4>
                    <LabelFilter
                        placeholder={'+ Add'}
                        availableTags={tags.data}
                        selectedTags={labelsToFilter}
                        setSelectedTags={setLabelsToFilter}
                    />
                </div>
            )}

            <ErrorLoadingWrapper
                height={'400px'}
                isLoading={data.loading}
                error={data.error}
                emptyState={showEmptyState()}
                render={() => {
                    const sortedEntries = Object.keys(data.data.entries)
                        .sort((a, b) => {
                            const firstEntry =
                                activeSort === TITLE_KEY
                                    ? data.data.entries[a]
                                    : data.data.entries[a].counters;
                            const secondEntry =
                                activeSort === TITLE_KEY
                                    ? data.data.entries[b]
                                    : data.data.entries[b].counters;
                            const firstValue =
                                typeof firstEntry[activeSort] === 'string'
                                    ? firstEntry[activeSort]
                                          .toLowerCase()
                                          .trim()
                                    : firstEntry[activeSort];
                            const secondValue =
                                typeof secondEntry[activeSort] === 'string'
                                    ? secondEntry[activeSort]
                                          .toLowerCase()
                                          .trim()
                                    : secondEntry[activeSort];

                            if (firstValue > secondValue) {
                                return sortStates[activeSort];
                            }
                            if (firstValue < secondValue) {
                                return sortStates[activeSort] * -1;
                            }
                            return 0;
                        })
                        .filter((entry) => {
                            const currentEntriesLabels = get(
                                data,
                                [`data`, 'entries', entry, 'labels'],
                                [],
                            );
                            if (labelsToFilter && labelsToFilter.length === 0) {
                                return true;
                            } else {
                                let match = false;
                                for (
                                    let i = 0, len = labelsToFilter.length;
                                    i < len;
                                    i++
                                ) {
                                    for (
                                        let j = 0,
                                            len2 = currentEntriesLabels.length;
                                        j < len2;
                                        j++
                                    ) {
                                        if (
                                            labelsToFilter
                                                .map((l) => l.name)
                                                .includes(
                                                    currentEntriesLabels[j],
                                                )
                                        ) {
                                            match = true;
                                            break;
                                        }
                                    }
                                }
                                return match;
                            }
                        });
                    return (
                        <div className="Impressions__container">
                            {labelsToFilter.length > 0 &&
                            sortedEntries &&
                            sortedEntries.length === 0 ? (
                                <div className="Impressions__empty">
                                    <h2>
                                        There are no responses matching these
                                        filters
                                    </h2>
                                </div>
                            ) : (
                                <Fragment>
                                    <div className="Impressions__tableTitle">
                                        <span
                                            className={cx(
                                                'Impressions__title',
                                                {
                                                    ['active']:
                                                        activeSort ===
                                                        TITLE_KEY,
                                                },
                                            )}
                                            onClick={() => {
                                                handleSort(TITLE_KEY);
                                            }}
                                        >
                                            Title{' '}
                                            {getSortIcons(
                                                sortStates[TITLE_KEY],
                                            )}
                                        </span>
                                        <span
                                            className={cx(
                                                'Impressions__impressions',
                                                {
                                                    ['active']:
                                                        activeSort ===
                                                        IMPRESSIONS_KEY,
                                                },
                                            )}
                                            onClick={() => {
                                                handleSort(IMPRESSIONS_KEY);
                                            }}
                                        >
                                            Impressions{' '}
                                            {getSortIcons(
                                                sortStates[IMPRESSIONS_KEY],
                                            )}
                                        </span>
                                        <span
                                            className={cx('Impressions__last', {
                                                ['active']:
                                                    activeSort === THUMBSUP_KEY,
                                            })}
                                            onClick={() => {
                                                handleSort(THUMBSUP_KEY);
                                            }}
                                        >
                                            <ThumbUp />

                                            {getSortIcons(
                                                sortStates[THUMBSUP_KEY],
                                            )}
                                        </span>
                                        <span
                                            className={cx('Impressions__last', {
                                                ['active']:
                                                    activeSort ===
                                                    THUMBSDOWN_KEY,
                                            })}
                                            onClick={() => {
                                                handleSort(THUMBSDOWN_KEY);
                                            }}
                                        >
                                            <ThumbDown />

                                            {getSortIcons(
                                                sortStates[THUMBSDOWN_KEY],
                                            )}
                                        </span>
                                    </div>
                                    <ul>
                                        {sortedEntries.map((entry) => {
                                            const entryData =
                                                data.data.entries[entry];
                                            return (
                                                <li
                                                    key={entry}
                                                    onClick={() => {
                                                        if (
                                                            entryData.type ===
                                                            'content'
                                                        ) {
                                                            history.push(
                                                                `/library/${entry}`,
                                                            );
                                                        } else {
                                                            history.push(
                                                                `/responses?compose=${entry}`,
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {getEntryIcon(
                                                        entryData.type,
                                                    )}
                                                    <div className="Impressions__title">
                                                        {entryData.title}
                                                    </div>
                                                    <div className="Impressions__count">
                                                        {
                                                            entryData.counters
                                                                .impressions
                                                        }
                                                    </div>
                                                    <div className="Impressions__count">
                                                        {
                                                            entryData.counters
                                                                .feedback_up
                                                        }
                                                    </div>
                                                    <div className="Impressions__count">
                                                        {
                                                            entryData.counters
                                                                .feedback_down
                                                        }
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </Fragment>
                            )}
                        </div>
                    );
                }}
            />
        </Card>
    );
};
