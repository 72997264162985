import React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {deleteDetails, getProviderName, loadDetails} from './utils';
import {services} from '@app/redux/integrations';
import {redux} from '@app/redux';
import {updateZendeskToken} from '@app/settings/utils';

interface componentProps {
    updateOrgError: string;
    updateZendeskToken: any;
    location: any;
    match: any;
    org: any;
}

class component extends React.Component<componentProps> {
    componentDidMount() {
        const {
            match: {
                params: {provider},
            },
            location: {search},
        } = this.props;

        const contactDetails = loadDetails(provider);
        const urlParams = new URLSearchParams(search);

        switch (provider) {
            case services.ZENDESK:
                this.connectZendesk(urlParams, contactDetails);
                break;
        }

        deleteDetails(provider);
    }

    connectZendesk(urlParams, contactDetails) {
        const accessToken = urlParams.get('access_token');

        if (accessToken.length > 0) {
            this.props.updateZendeskToken({
                token: accessToken,
                subdomain: contactDetails.domain,
            });
        }
    }

    render() {
        const {
            updateOrgError,
            match: {
                params: {provider},
            },
        } = this.props;
        if (updateOrgError) {
            return (
                <div className="kare-center-block">
                    <div className="kare-single-column-container">
                        <h1>Error:</h1>
                        <p className="kare-text-error u-margin-bottom-large">{`${updateOrgError}`}</p>
                        <section className="submit-button-container">
                            <Link to={'/login'} className="c-btn">
                                Return to console
                            </Link>
                        </section>
                    </div>
                </div>
            );
        }
        return (
            <div className="kare-center-block">
                <div className="kare-single-column-container">
                    <h2 className="kare-h2 u-margin-bottom">Thank you</h2>

                    <p className="u-margin-bottom-large">
                        Your {getProviderName(provider)} account its now
                        connected to Kare.
                    </p>

                    <section className="submit-button-container">
                        <Link to={'/login'} className="c-btn">
                            Return to console
                        </Link>
                    </section>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        org: redux.org.selectors.orgSelector(state),
        updateOrgError: redux.org.selectors.updateOrgErrorSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateZendeskToken: (params) => updateZendeskToken(dispatch)(params),
    };
};

export const Complete = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(component),
);
