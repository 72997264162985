import React, {useEffect, useState} from 'react';
import arrayMutators from 'final-form-arrays';
import {Form} from 'react-final-form';
import {toast, ToastContainer} from 'react-toastify';
import './WebCrawlerForm.scss';
import {
    additionalRobots,
    ApiDomain,
    customAgent,
    forceCrawl,
    ignoreLocale,
    forceLocale,
    ignoreMeta,
    onlyGivenPages,
} from '@app/api/model';
import {Domain, getDomain, putWebcrawlerDomain} from '@app/api/webcrawler';
import {BaseUrl} from '@app/components/Form/BaseUrl';
import {ExtraOptions} from '@app/components/Form/ExtraOptions';
import {Labels} from '@app/components/Form/Labels';
import {StartingPages} from '@app/components/Form/StartingPages';
import {SubmitControls} from '@app/components/Form/SubmitControls';
import {Loading} from '@app/components/Loading/Loading';
import {MdClose} from 'react-icons/md';

export interface IWebCrawlerForm {
    host?: string | undefined;
    closeModal?: () => void;
    onSuccess?: () => void;
}

const getInitialState = (domain: ApiDomain | undefined) => ({
    startingPages: domain
        ? domain.starting_pages
            ? domain.starting_pages
            : ['']
        : [''],
    labels: domain ? domain.labels : [],
    host: domain ? domain.host : '',
    robots: domain ? domain.additional_robots : '',
    customAgent: domain ? domain.custom_agent : '',
    onlyCrawlStartingPages:
        domain?.flags?.find((f) => f === onlyGivenPages) !== undefined || true,
    ignoreMeta:
        domain && domain.flags?.find((f) => f === ignoreMeta) !== undefined,
    forceCrawl:
        domain && domain.flags?.find((f) => f === forceCrawl) !== undefined,
    hasCustomAgent:
        domain && domain.flags?.find((f) => f === customAgent) !== undefined,
    hasRobots:
        domain &&
        domain.flags?.find((f) => f === additionalRobots) !== undefined,
    ignoreLocale:
        domain && domain.flags?.find((f) => f === ignoreLocale) !== undefined,
    forceLocale:
        domain && domain.flags?.find((f) => f === forceLocale) !== undefined,
    forcedLocales: domain ? domain.forced_locales.join(',') : '',
});

export const WebCrawlerForm: React.FunctionComponent<IWebCrawlerForm> = (
    props,
) => {
    const {host, closeModal, onSuccess} = props;
    const [domain, setDomain] = useState<ApiDomain | undefined>(undefined);
    const [initialValues, setInitialValues] = useState(getInitialState(domain));
    const [isLoading, setIsLoading] = useState(!!host);

    useEffect(() => {
        const fetchDomain = async () => {
            if (host || host !== '') {
                const result = await getDomain(host!);
                setIsLoading(false);
                if (result.err) {
                    toast.error(
                        'Unknown error fetching the domain. Please try again later',
                    );
                } else {
                    setDomain(result.data.domains[0]);
                    setInitialValues(getInitialState(result.data.domains[0]));
                }
            }
        };
        fetchDomain();
    }, [host, setIsLoading, setDomain]);

    const onSubmit = (values: Domain) => {
        // Send request with events
        (async () => {
            const result = await putWebcrawlerDomain(values);
            if (result.err) {
                if (result.err.status === 500) {
                    toast.error('Unknown error. Try again later please.');
                } else {
                    toast.error(
                        `Error saving content to be crawled: ${result.err.data?.message}`,
                    );
                }
            } else {
                toast.success('New content source added correctly.');
                onSuccess();
                closeModal();
            }
        })();
    };

    return (
        <>
            {isLoading && (
                <div className="WebCrawlerForm__loading">
                    <Loading />
                </div>
            )}
            {!isLoading && (
                <Form
                    onSubmit={onSubmit}
                    mutators={{
                        ...arrayMutators,
                    }}
                    validate={(values: any) => {
                        const errors: any = {};
                        if (values.host === '' || values.host === undefined) {
                            errors.host = 'Required';
                        }
                        return errors;
                    }}
                    initialValues={initialValues}
                    render={({handleSubmit, values}) => (
                        <form
                            onSubmit={handleSubmit}
                            className="WebCrawlerForm__container"
                        >
                            <h1 className="WebCrawlerForm__titleText">
                                Import a new website{' '}
                                <MdClose
                                    onClick={() => {
                                        closeModal();
                                    }}
                                />
                            </h1>
                            <div className="WebCrawlerForm__titleContainer">
                                The webcrawler will download all public facing
                                content of the selected domain, index it and
                                point users to the source webpage when the
                                resource is selected. Please be aware of
                                Copyright and of other restrictions that might
                                apply to a domain before importing it. Please
                                read our Terms of service for more information.
                            </div>
                            <BaseUrl host={host} />
                            <StartingPages />
                            <Labels />
                            <ExtraOptions values={values} />
                            <SubmitControls
                                onSubmit={handleSubmit}
                                onCancel={() => {
                                    closeModal();
                                }}
                            />
                            <ToastContainer />
                        </form>
                    )}
                />
            )}
        </>
    );
};
