import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {reducer as form} from 'redux-form';
import {reducer as notificationsReducer} from 'reapop';
import createEncryptor from 'redux-persist-transform-encrypt';

import {rest} from './rest';
import {knowledgeBase} from './knowledge-base';
import {activity} from './activity';
import {application} from './application';
import {conversations} from './conversations';
import integrationsReducer from './integrations';
import featuresReducer from './features';
import {responsesTable} from './responses-table';
import {documentsTable} from './documents-table';
import {queries} from './improve/queries';
import {responses} from './improve/responses';
import {LOGIN} from './user/constants';
import {documents} from './improve/documents';
import {locale} from './locale';
import {LOCALE} from './constants';
import {dialogs} from './dialogs';
import {feedbacks} from './feedback';
import {timeseriesQueries} from './queries';

// @ts-ignore
const encryptor = createEncryptor({
    secretKey:
        'K4w=nA2Z^zRXv9mZECH6$Kw#a+DASM85ANuJdn#zEQVM%PkEAQNxnE7-JyKzjKNGyqcA*&NN*3+Da9t!Je3?Tewr+8#dKtewqM^8s3_y5r^Ra6WyB^QF5yLAE5EWk#qR@%Pc',
});

const authPersistConfig = {
    transforms: [encryptor],
    key: 'auth',
    storage: storage,
    whitelist: ['data'],
};
const localePersistConfig = {
    key: LOCALE,
    storage: storage,
    whitelist: [locale.constants.CONTENT_LOCALE],
};
/**
 * The root reducer for the redux store.
 */
export const rootReducer = combineReducers({
    ...rest.reducers,
    dialogs: dialogs.reducer,
    knowledgeBase: knowledgeBase.reducer,
    activity: activity.reducer,
    application: application.reducer,
    conversations: conversations.reducer,
    feedbacks: feedbacks.reducer,
    queries: queries.reducer,
    timeseriesQueries: timeseriesQueries.reducer,
    integrationsReducer,
    featuresReducer,
    responsesTable: responsesTable.reducer,
    documentsTable: documentsTable.reducer,
    form: form.plugin({
        ...queries.formReducer,
    }),
    manage: combineReducers({
        responses: responses.reducer,
        documents: documents.reducer,
    }),
    notifications: notificationsReducer(),
    // Override the LOGIN entry from redux-api
    // so that we can persist the token.
    // We have to do a nested persist so we only store the token
    // and not the email and password.
    // @see: https://github.com/rt2zz/redux-persist#nested-persists
    [LOGIN]: persistReducer(authPersistConfig, rest.reducers[LOGIN]),
    [LOCALE]: persistReducer(localePersistConfig, locale.reducer),
});
