import React from 'react';
import {Link} from 'react-router-dom';

export class ResetPasswordComplete extends React.Component {
    render() {
        return (
            <div className="kare-center-block">
                <div className="kare-single-column-container">
                    <h2 className="kare-h2 u-margin-bottom">Password reset</h2>

                    <p className="u-margin-bottom-large">
                        Your password has been recovered. You can now log back
                        in the console.
                    </p>

                    <section className="submit-button-container">
                        <Link to="/login" className="c-btn">
                            Return to console
                        </Link>
                    </section>
                </div>
            </div>
        );
    }
}
