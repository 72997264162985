import React from 'react';
import Icon from 'react-icon-base';

export const SomewhatDissatisfied = (props) => (
    <Icon viewBox="0 0 15 15" {...props}>
        <defs>
            <path
                d="M7.4925,0 C11.64,0 15,3.36 15,7.5 C15,11.64 11.64,15 7.4925,15 C3.3525,15 0,11.64 0,7.5 C0,3.36 3.3525,0 7.4925,0 Z M7.5,1.5 C4.185,1.5 1.5,4.185 1.5,7.5 C1.5,10.815 4.185,13.5 7.5,13.5 C10.815,13.5 13.5,10.815 13.5,7.5 C13.5,4.185 10.815,1.5 7.5,1.5 Z M7.5,9.75 C8.3325,9.75 9.0975,9.9975 9.735,10.4175 C9.6225,10.5375 9.0675,11.1675 8.9775,11.2725 C8.5425,11.0175 8.0475,10.875 7.5,10.875 C6.9525,10.875 6.4575,11.0175 6.015,11.265 C5.64,10.845 5.6325,10.835625 5.6278125,10.8290625 L5.62110211,10.8206289 C5.6044213,10.8008333 5.54041667,10.7279167 5.2575,10.41 C5.9025,9.9975 6.6675,9.75 7.5,9.75 Z M10.125,4.5 C10.7463203,4.5 11.25,5.00367966 11.25,5.625 C11.25,6.24632034 10.7463203,6.75 10.125,6.75 C9.50367966,6.75 9,6.24632034 9,5.625 C9,5.00367966 9.50367966,4.5 10.125,4.5 Z M4.875,4.5 C5.49632034,4.5 6,5.00367966 6,5.625 C6,6.24632034 5.49632034,6.75 4.875,6.75 C4.25367966,6.75 3.75,6.24632034 3.75,5.625 C3.75,5.00367966 4.25367966,4.5 4.875,4.5 Z"
                id="path-4"></path>
        </defs>
        <g
            id="icon-feedback-somewhat_dissatisfied"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd">
            <mask id="mask-4" fill="white">
                <use xlinkHref="#path-4"></use>
            </mask>
            <use
                id="Combined-Shape"
                fill="currentColor"
                fillRule="nonzero"
                xlinkHref="#path-4"></use>
        </g>
    </Icon>
);
