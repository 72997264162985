import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {get} from 'lodash';
import packageJson from '../package.json';
import Modal from 'react-modal';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {AppContainer} from 'react-hot-loader';
import 'react-toastify/dist/ReactToastify.css';
import {getZone} from './app/zone';

const version = get(packageJson, ['version']);

Sentry.init({
    dsn: 'https://e2e1bfdbdd3544ed9a2f659dad718e1d@o298232.ingest.sentry.io/6051033',
    integrations: [new Integrations.BrowserTracing()],
    release: `gluru-dashboard@${version}`,
    environment: getZone(),
    beforeSend: (event) => {
        if (window.location.hostname === 'localhost') {
            return null;
        }
        return event;
    },
});

async function getFacts() {
    if (process.env.NODE_ENV !== 'development') {
        const factsResponse = await axios.get(
            `${location.protocol}//${location.host}/facts`,
        );
        return factsResponse.data;
    }

    return {
        zone: 'stg',
        google_recaptcha_token: process.env.GOOGLE_RECAPTCHA_TOKEN_CLIENT,
        version: 'whatever',
        auths: [
            {
                name: 'default',
            },
            {
                name: 'dialpad_sandbox',
                client: {
                    clientID: 'tZzJMMS4vGZXNz6LZ9wvfgqy9',
                    endpoint: 'https://sandbox.dialpad.com/',
                },
            },
        ],
    };
}

async function start() {
    const auth = await require('./app/utils/auth');

    let facts;
    try {
        facts = await getFacts();
    } catch (err) {
        return await initReact();
    }

    // @ts-ignore
    window.ZONE = facts.zone;

    auth.setCookie('ZONE', facts.zone);
    auth.setCookie('GOOGLE_RECAPTCHA_TOKEN', facts.google_recaptcha_token);
    auth.setCookie('VERSION', facts.version);
    auth.saveAuths(facts.auths);

    return await initReact();
}

async function initReact() {
    const reduxModule = await require('./app/redux');
    const indexModule = await require('./app/App');

    const reduxConfig = reduxModule.redux.configureStore();

    const {store, persistor} = reduxConfig;
    // Hack to force removal of root key in redux-persist.
    const render = (Component) => {
        ReactDOM.render(
            <AppContainer>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Router>
                            <Component />
                        </Router>
                    </PersistGate>
                </Provider>
            </AppContainer>,
            document.getElementById('app'),
        );
    };
    render(indexModule.Index);

    Modal.setAppElement('#app');

    if (module && module.hot) {
        module.hot.accept('./app/App', () => {
            const NextRootComponent = require('./app/App');
            render(NextRootComponent);
        });
    }
}

start();
