import React, {Fragment} from 'react';
import cx from 'classnames';
import {Helmet} from 'react-helmet';
import {get} from 'lodash';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {SubmissionError, submit, change} from 'redux-form';
import {addNotification} from 'reapop';

import Notification from '@app/utils/notifications';
import {redux} from '@app/redux';
import {RESPONSE_FORM} from '../constants';
import {adaptResponsePayload} from './response-form/utils';
import {
    Button,
    ErrorBoundary,
    KnowledgeBasePortal,
    PanelBody,
    PanelHeader,
    PortalMain,
    Spinner,
    Tooltip,
} from '@app/common';
import {ResponseForm} from './response-form';
import {DocumentsSidebar} from './documents-sidebar';
import {getInitialValues} from '../utils';
import {Clone, Delete, FileDownload, OptionsTooltip} from '@app/common/icons';
import {querystring, blocks as blocksUtil} from '@app/utils';
import {isActive, isArchived, isDraft} from './utils';
import {ResponseNotFound} from './response-not-found';
import {
    TYPE,
    NODE_TYPES,
    NODE_STATUSES,
    COMPOSE,
    CLONE,
    WITH_DOCUMENT,
    NEW,
    STATUS,
    CLUSTER,
} from '@app/constants';
import {dialogChoiceSelector} from '@app/redux/dialogs/selectors';
import {documentSelector} from '@app/redux/documents/selectors';
import {
    improvePageResponseSelector,
    responseFormSelector,
} from '@app/redux/responses/selectors';
import {createImproveSelectedResponse} from '@app/redux/improve/responses/dispatchers';

interface ResponsePortalProps {
    submitResponseForm?: any;
    createResponse?: any;
    updateResponse?: any;
    title?: string;
    isResponseFetching?: boolean;
    isResponseUpdating?: boolean;
    history?: {
        push?: any;
        replace?: any;
    };
    location?: any;
    attachedDocument?: any;
    getResponse?: any;
    response?: any;
    hideResponseForm?: any;
    [COMPOSE]?: string;
    [CLONE]?: string;
    [WITH_DOCUMENT]?: string;
    updateImproveResponse?: any;
    getResponseCluster?: any;
    resetResponse?: any;
    setResponseToArchived?: any;
    isResponseNotFound?: boolean;
    isResponseClusterFetching?: boolean;
    currentStatus?: string;
    onClose?: any;
    setResponseToPublished?: any;
    setResponseToDraft?: any;
    chooseAnotherResponse?: any;
    isChattering?: boolean;
}

/**
 * The Portal for response editing.
 * This can be further developed to include the documents as well.
 * TODO(rupertrutland): Refactor this component - it's convoluted.
 */
export class ResponsePortalComponent extends React.Component<ResponsePortalProps> {
    state = {
        isDocumentsSidebarVisible: false,
        isChatteringActiveInState: false,
    };

    componentDidMount() {
        if (this.isNewResponse()) {
            return;
        }
        const id = this.getId();
        if (id) {
            this.props.getResponse(id);
        }
        const clusterId = this.getClusterId();
        if (clusterId) {
            this.props.getResponseCluster(clusterId);
        }
    }

    isAutoAnswer = () => {
        return (
            new window.URL(window.location.href).searchParams.get(
                'auto-response',
            ) === 'true'
        );
    };

    componentWillUnmount() {
        this.props.resetResponse();
    }

    handleClickDocuments = () => {
        this.setState({
            isDocumentsSidebarVisible: true,
        });
    };

    closeDocumentsSidebar = () => {
        this.setState({
            isDocumentsSidebarVisible: false,
        });
    };

    componentDidUpdate(prevProps) {
        if (this.isNewResponse()) {
            return;
        }
        const prevId = get(prevProps, [COMPOSE]);
        const newId = this.getId();
        if (prevId !== newId) {
            this.props.getResponse(this.getId());
        }
    }

    handleClickDelete = () => {
        const shouldDelete = confirm(
            'Are you sure you want to archive this response?',
        );
        if (shouldDelete) {
            this.props.setResponseToArchived();
            this.submitForm();
        }
    };

    handleClickClone = () => {
        const {
            history: {push},
            location,
        } = this.props;
        push(querystring.getCloneResponsePath(location, this.getId()));
    };

    getId = () => {
        return this.props[COMPOSE];
    };

    getClusterId = () => {
        return this.props[CLUSTER];
    };

    isCloning = () => {
        return !!this.props[CLONE];
    };

    isEditing = () => {
        return this.props[COMPOSE] !== NEW && !this.isCloning();
    };

    isNewResponse = () => {
        return this.props[COMPOSE] === NEW;
    };

    isResponseNotFound = () => {
        const {response} = this.props;
        return !response && !this.isNewResponse();
    };

    getFormTitle = (isChatteringTypeActive) => {
        const {response, isResponseNotFound} = this.props;

        if (this.getClusterId()) {
            return 'Add a new response from auto-answer';
        }

        if (this.isCloning()) {
            return 'Clone response';
        }
        if (this.isNewResponse()) {
            return isChatteringTypeActive
                ? 'Add a new chatter message'
                : 'Add a new response';
        }
        if (isArchived(response)) {
            return 'Update an archived response';
        }
        if (isActive(response)) {
            return isChatteringTypeActive
                ? 'Update chatter message'
                : 'Update a response';
        }
        if (isDraft(response)) {
            return 'Update a draft response';
        }
        if (isResponseNotFound) {
            return 'Response Not found';
        }
        return '';
    };

    setEditorRef = () => {};

    renderPanelBody = (isChatteringTypeActive) => {
        const {
            isResponseClusterFetching,
            isResponseFetching,
            response,
            isResponseNotFound,
            isResponseUpdating,
            history,
        } = this.props;

        const initialValues = getInitialValues(
            this.isNewResponse(),
            this.isCloning(),
            response,
            this.props[WITH_DOCUMENT],
            isChatteringTypeActive,
            this.getClusterId(),
        );

        if (isResponseFetching || isResponseClusterFetching) {
            return <Spinner />;
        }
        if (isResponseNotFound) {
            return <ResponseNotFound history={history} />;
        }
        const type = get(response, 'type');
        if (type === 'content') {
            return (
                <div className="response-content-error">
                    <h1>{"You can't edit documents"}</h1>
                </div>
            );
        }

        return (
            <ResponseForm
                initialValues={initialValues}
                isCloning={this.isCloning()}
                setEditorRef={this.setEditorRef}
                onSubmit={this.handleSubmitForm}
                blocksUtil={blocksUtil}
                isChatteringTypeActive={this.getIsChatteringTypeActive()}
            >
                <Helmet title={this.getFormTitle(isChatteringTypeActive)} />
                <Button
                    className="response-portal-submit-button"
                    onClick={this.handleClickPublish}
                    disabled={isResponseUpdating}
                >
                    {isActive(response) && !this.isCloning()
                        ? 'Save'
                        : 'Publish'}
                </Button>
                {!isChatteringTypeActive ? (
                    <div className="response-portal-submit__tooltip">
                        <Tooltip
                            overlay={this.renderTooltipActions()}
                            tooltipProps={{
                                placement: 'left',
                            }}
                        >
                            <OptionsTooltip
                                size={20}
                                className="knowledge-base__panel-footer-link-options"
                            />
                        </Tooltip>
                    </div>
                ) : (
                    <Button
                        className="response-portal-submit__tooltip response-portal-submit__chattering-delete"
                        inverted
                        onClick={this.handleClickDelete}
                        icon={Delete}
                        noPadding={true}
                    >
                        Delete
                    </Button>
                )}
            </ResponseForm>
        );
    };

    handleSubmitForm = (values) => {
        const numberOfBlocks = get(this.props, ['currentBlocks', 'length'], 0);
        const {currentStatus} = this.props;
        const isChatteringTypeActive = this.getIsChatteringTypeActive();
        if (
            numberOfBlocks < 1 &&
            currentStatus !== NODE_STATUSES.DRAFT &&
            !isChatteringTypeActive
        ) {
            throw new SubmissionError({
                _error: 'NO_BLOCKS',
            });
        }
        return !this.isEditing()
            ? this.handleCreateResponse(values)
            : this.handleUpdateResponse(this.getId())(values);
    };

    handleClosePortal = () => {
        const {
            history: {replace},
            location,
            onClose,
        } = this.props;
        if (onClose) {
            onClose();
        } else {
            replace(querystring.getCloseResponseFormPath(location));
        }
    };

    submitForm = () => {
        const {submitResponseForm} = this.props;
        setTimeout(() => {
            submitResponseForm();
        }, 0);
    };

    handleClickPublish = () => {
        this.props.setResponseToPublished();
        this.submitForm();
    };

    handleClickSaveDraft = () => {
        this.props.setResponseToDraft();
        this.submitForm();
    };

    handleClickRevertToDraft = () => {
        this.props.setResponseToDraft();
        this.submitForm();
    };

    getClosedEditorUrl = () => {
        const {location} = this.props;
        return `${location.pathname}${querystring.removeQuerystrings(
            location.search,
            querystring.responseQuerystringKeys,
        )}`;
    };

    handleCreateResponse = (response) => {
        const {createResponse, chooseAnotherResponse} = this.props;
        return createResponse(response).then((createdEntity) => {
            this.props.updateImproveResponse(createdEntity);
            this.handleClosePortal();
            chooseAnotherResponse();
        });
    };

    handleUpdateResponse = (id) => (response) => {
        const {updateResponse} = this.props;
        return updateResponse(id)(response).then(() => {
            this.handleClosePortal();
        });
    };

    renderTooltipActions = () => {
        const {response} = this.props;
        if (this.isCloning() || this.isNewResponse()) {
            // New response
            return (
                <Fragment>
                    <Button
                        inverted
                        onClick={this.handleClickSaveDraft}
                        icon={FileDownload}
                        noPadding={true}
                    >
                        Save as draft
                    </Button>
                </Fragment>
            );
        }
        if (isDraft(response)) {
            return (
                <Fragment>
                    <Button
                        inverted
                        onClick={this.handleClickSaveDraft}
                        icon={FileDownload}
                        noPadding={true}
                    >
                        Update draft
                    </Button>
                    <Button
                        inverted
                        onClick={this.handleClickDelete}
                        icon={Delete}
                        noPadding={true}
                    >
                        Archive
                    </Button>
                    <Button
                        inverted
                        onClick={this.handleClickClone}
                        icon={Clone}
                        noPadding={true}
                    >
                        Clone
                    </Button>
                </Fragment>
            );
        }

        if (isArchived(response)) {
            return (
                <Fragment>
                    <Button
                        inverted
                        onClick={this.handleClickRevertToDraft}
                        icon={FileDownload}
                        noPadding={true}
                    >
                        Revert to draft
                    </Button>
                    <Button
                        inverted
                        onClick={this.handleClickClone}
                        icon={Clone}
                        noPadding={true}
                    >
                        Clone
                    </Button>
                </Fragment>
            );
        }
        if (isActive(response)) {
            return (
                <Fragment>
                    <Button
                        inverted
                        onClick={this.handleClickRevertToDraft}
                        icon={FileDownload}
                        noPadding={true}
                    >
                        Revert to draft
                    </Button>
                    <Button
                        inverted
                        onClick={this.handleClickDelete}
                        icon={Delete}
                        noPadding={true}
                    >
                        Archive
                    </Button>
                    <Button
                        inverted
                        onClick={this.handleClickClone}
                        icon={Clone}
                        noPadding={true}
                    >
                        Clone
                    </Button>
                </Fragment>
            );
        }
        return null;
    };

    getIsChatteringTypeActive() {
        const {
            response,
            location: {search},
        } = this.props;

        return (
            querystring.getValueByKey(search, STATUS) === NODE_TYPES.CHATTER ||
            get(response, TYPE) === NODE_TYPES.CHATTER ||
            this.props.isChattering
        );
    }

    /**
     * Return the portal wrapper with the editor form inside.
     */
    render() {
        const {isResponseNotFound} = this.props;

        const isChatteringTypeActive = this.getIsChatteringTypeActive();
        return (
            <ErrorBoundary>
                <KnowledgeBasePortal className="response-portal">
                    {this.state.isDocumentsSidebarVisible && (
                        <DocumentsSidebar
                            closeDocumentsSidebar={this.closeDocumentsSidebar}
                        />
                    )}
                    <PortalMain
                        className={cx({
                            'response-not-found': isResponseNotFound,
                        })}
                    >
                        <PanelHeader
                            title={this.getFormTitle(isChatteringTypeActive)}
                            onClose={this.handleClosePortal}
                        />
                        <PanelBody>
                            {this.renderPanelBody(isChatteringTypeActive)}
                        </PanelBody>
                    </PortalMain>
                </KnowledgeBasePortal>
            </ErrorBoundary>
        );
    }
}

/**
 * Map redux store state to props for the component.
 * @param {Object} state
 */
const mapStateToProps = (state, props) => {
    const isResponseUpdating =
        redux.responses.selectors.isResponseUpdatingSelector(state) ||
        redux.responses.selectors.isResponseCreatingSelector(state);
    const isResponseFetching =
        redux.responses.selectors.isResponseFetchingSelector(state);
    const isResponseClusterFetching =
        redux.responses.selectors.isResponseClusterFetchingSelector(state);
    return {
        autoResponse: improvePageResponseSelector(state),
        isResponseClusterFetching,
        isResponseUpdating,
        currentBlocks: responseFormSelector(state, 'blocks'),
        currentStatus: responseFormSelector(state, 'status'),
        isResponseFetching,
        selectedDocument: documentSelector(state),
        response: redux.responses.selectors.responseSelector(
            state,
            props[CLUSTER],
        ),
        choice: dialogChoiceSelector(state),
        isResponseNotFound:
            redux.responses.selectors.responseNotFoundSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateImproveResponse: (response) => {
            return createImproveSelectedResponse(dispatch)(response);
        },
        createResponse: (response) => {
            return dispatch(
                redux.rest.actions[redux.responses.constants.CREATE_RESPONSE](
                    null,
                    {
                        body: adaptResponsePayload(response),
                    },
                ),
            ).catch((error) => {
                dispatch(addNotification(Notification.responseCreationError()));
                throw new SubmissionError(error);
            });
        },
        updateResponse: (id) => (response) => {
            return dispatch(
                redux.rest.actions[redux.responses.constants.UPDATE_RESPONSE](
                    {id: `${id}`},
                    {body: adaptResponsePayload(response)},
                ),
            ).catch((error) => {
                dispatch(addNotification(Notification.responseUpdateError()));
                throw new SubmissionError(error);
            });
        },

        // Manually submit the response form as the submit button is not actually inside the form.
        submitResponseForm: () => {
            return dispatch(submit(RESPONSE_FORM));
        },

        resetResponse: () => {
            return dispatch(
                redux.rest.actions[
                    redux.responses.constants.GET_RESPONSE
                ].reset(),
            );
        },

        getResponse: (id) => {
            return dispatch(
                redux.rest.actions[redux.responses.constants.GET_RESPONSE]({
                    id: id,
                }),
            );
        },

        getResponseCluster: (id) => {
            return dispatch(
                redux.rest.actions[
                    redux.responses.constants.GET_RESPONSE_CLUSTER
                ]({
                    id: id,
                }),
            );
        },
        setResponseToDraft: () => {
            return dispatch(
                change(RESPONSE_FORM, 'status', NODE_STATUSES.DRAFT),
            );
        },
        setResponseToArchived: () => {
            return dispatch(
                change(RESPONSE_FORM, 'status', NODE_STATUSES.ARCHIVED),
            );
        },
        setResponseToPublished: () => {
            return dispatch(
                change(RESPONSE_FORM, 'status', NODE_STATUSES.PUBLISHED),
            );
        },
        chooseAnotherResponse: () =>
            dispatch(
                redux.rest.actions[
                    redux.responses.constants.GET_IMPROVE_PAGE_RESPONSE
                ].reset(),
            ),
    };
};

export const ResponsePortal = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ResponsePortalComponent),
);
