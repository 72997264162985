import React from 'react';
import {get} from 'lodash';
import {MarkdownEditor, Markdown} from '@app/common';
import {BlockControls} from '../block-controls';

export const renderInactiveText = (
    text,
    index,
    fields,
    removeBlock,
    activateBlock,
    handleClick,
) => {
    return (
        <div className="kare-block-wrapper">
            <div
                className="kare-block kare-block--inactive"
                onClick={() => {
                    if (handleClick) {
                        handleClick();
                    }
                }}
            >
                <Markdown>{text}</Markdown>
            </div>
            {index >= 0 && fields && removeBlock && activateBlock && (
                <BlockControls
                    index={index}
                    fields={fields}
                    removeBlock={removeBlock}
                    activateBlock={activateBlock}
                />
            )}
        </div>
    );
};

interface TextBlockFieldProps {
    input?: any;
    removeBlock?: any;
    index?: number;
    fields?: any;
}

export class TextBlockField extends React.Component<TextBlockFieldProps, any> {
    constructor(props) {
        super(props);
        // If it's a newly added block then we want it to be open straight away, i.e if it doesn't have a value yet.
        const value = get(props, ['input', 'value', 'text_block', 'text'], '');
        const hasValue = !!value;
        this.state = {active: !hasValue};
    }

    handleChange = (event) => {
        const {
            input: {onChange},
        } = this.props;
        const value = event.target.value;
        onChange({type: 'video', video_block: {url: value}});
    };

    deactivateEditor = () => {
        this.setState({active: false});
    };

    handleClick = () => {
        this.activateBlock;
    };

    handleBlur = () => {};

    activateBlock = () => {
        this.setState({active: true});
    };

    render() {
        const {input, removeBlock, index, fields} = this.props;
        const value = get(input, ['value'], {});
        const markdown = get(value, ['text_block', 'text'], '');
        if (this.state.active) {
            return (
                <MarkdownEditor
                    // Shouldn't be an index really.
                    key={name}
                    id={name}
                    {...input}
                    previousValue={markdown}
                    onBlur={this.handleBlur}
                    deactivateEditor={this.deactivateEditor}
                    removeBlock={removeBlock}
                />
            );
        }
        return renderInactiveText(
            markdown,
            index,
            fields,
            removeBlock,
            this.activateBlock,
            this.handleClick,
        );
    }
}
