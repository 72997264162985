import {rest} from './rest';
import * as selectors from './selectors';
import * as constants from './constants';
import * as dispatchers from './dispatchers';

/**
 * Public API for the documents redux module.
 */
export const user = {
    dispatchers,
    rest,
    selectors,
    constants,
};
