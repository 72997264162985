import Axios from 'axios';
import {Moment} from 'moment';

export class EntryPageAPI {
    constructor() {}

    async getTrafficSourceByDomain(minDate: Moment, maxDate: Moment) {
        const URL = `/v3.1/analytics/aggregated/entry_urls/domain?from=${minDate.unix()}&to=${maxDate.unix()}`;
        return this.callApi(URL);
    }

    async getTrafficSourceByURL(minDate: Moment, maxDate: Moment) {
        const URL = `/v3.1/analytics/aggregated/entry_urls/domain,url?from=${minDate.unix()}&to=${maxDate.unix()}`;
        return this.callApi(URL);
    }

    async callApi(URL: string) {
        const res = {
            data: null,
            error: null,
        };
        try {
            const req = await Axios.get(URL);
            res.data = req.data;
        } catch (e) {
            res.error = e;
        }
        return res;
    }
}
