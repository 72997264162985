import React from 'react';
import {toast} from 'react-toastify';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {redux} from '@app/redux';
import {getSubdomainFromURL} from '@app/utils/client-url';
import {getZone} from '@app/zone';
import {getAuthorizeUrl, getAuthorizeEndpoints} from './utils';
import {auth, getAuths, setCookie} from '@app/utils/auth';
import {Dialpad} from '@app/assets/icons';
import {LoginTitle} from './authorize.styles';
import {Spinner} from '@app/common/spinner';
import {OAUTH_CSRF_COOKIE} from '../../constants';

interface DialpadLoginStartProps {
    zone?: string;
    subdomain?: string;
    providerName?: string;
    loginHint?: string;
    successRedirect?: string;
    authorizeUrls?: {
        [key: string]: string;
    };
    authsMap?: any;
    logout?: any;
}

const getRandomToken = (bytes_entropy) => {
    const bytes = new Uint8Array(bytes_entropy);
    crypto.getRandomValues(bytes);
    const pairs = [...bytes].map((b) => b.toString(16));
    return pairs.join('');
};

class DialpadOAuthLoginStartComponent extends React.Component<DialpadLoginStartProps> {
    componentDidMount() {
        this.props.logout();
        if (!this.props.authsMap.has(this.props.providerName)) {
            toast.error('provider not found in this organisation');
            return;
        }
        const state = getRandomToken(16);
        setCookie(OAUTH_CSRF_COOKIE, state);
        const authorizeUrl = getAuthorizeUrl(
            this.props.authorizeUrls,
            this.props.authsMap.get(this.props.providerName),
            this.props.loginHint,
            this.props.successRedirect,
            state,
        );
        window.location.replace(authorizeUrl);
    }

    render() {
        if (!this.props.authsMap.has(this.props.providerName)) {
            return (
                <div className="loginWrapper">
                    <div className="loginContainer">
                        <h2 className="title">
                            Provider not found in organisation
                        </h2>
                    </div>
                </div>
            );
        }

        return (
            <div className="loginWrapper">
                <div className="loginContainer">
                    <h2 className="title">Dialpad Single Sign-on</h2>
                    <Spinner withCenteredContainer={true} />
                    <Dialpad size={15} color="#6C3DFF" />
                    <LoginTitle>Logging in with Dialpad...</LoginTitle>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        zone: getZone(),
        subdomain: getSubdomainFromURL(),
        authorizeUrls: getAuthorizeEndpoints(),
        authsMap: (() => {
            const auths = getAuths();
            const authsMap = new Map();
            auths.map((auth) => {
                authsMap.set(auth.name, auth);
            });
            return authsMap;
        })(),
        providerName: (() => {
            const url = new URL(window.location as any);
            return url.searchParams.get('provider');
        })(),
        loginHint: (() => {
            const url = new URL(window.location as any);
            return url.searchParams.get('login_hint');
        })(),
        successRedirect: (() => {
            const url = new URL(window.location as any);
            return url.searchParams.get('redirect');
        })(),
    };
};

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        return auth.logout({redux, dispatch});
    },
});

export const DialpadLoginStart = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
        // @ts-ignore
    )(DialpadOAuthLoginStartComponent),
);
