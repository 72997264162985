import React from 'react';

import {
    AccountCircle,
    FiberManualRecord as FiberManualRecordIcon,
    ThumbDown,
    ThumbUp,
    VerifiedUser,
} from '@material-ui/icons';

export const RESOURCE_KEYS = {
    CONVERSATIONS: 'conversations',
    QUERIES: 'queries',
    FEEDBACK: 'feedback',
    CX_METRICS: 'cxmetrics',
    distribution: '',
};

export const DATA_KEYS = {
    OPENED: 'opened',
    NOT_OPENED: 'notOpened',
    DESKTOP: 'Desktop',
    MOBILE: 'Mobile',
    HELPFUL: 'helpful',
    ENGAGED: 'engaged',
    REGISTERED: 'registered',
    MISSING: 'missing',
    WINDOWS: 'Windows',
    LINUX: 'Linux',
    OSX: 'Mac OS X',
    ANDROID: 'Android',
    VISITOR: 'visitor',
    UNHELPFUL: 'unhelpful',
    RESPONSES: 'responses',
    LIBRARY: 'library',
    TOTAL: 'total',
    BOUNCED: 'bounced',
    RESOLVED: 'resolved',
    CHATTERING: 'chattering',
    RESPONSE: 'response',
    CONTENT: 'content',
    HANDED: 'handed',
    ANSWERED: 'answered',
    UNCERTAIN: 'uncertain',
    ESCALATED: 'escalated',
    NODATA: 'nodata',
    UNRESOLVED: 'unresolved',
    MISSING_KNOWLEDGE: 'missing-knowledge',
    IMPROVE_ANSWER: 'improve-answer',
    AUTO_ANSWER: 'auto-answer',
    QUERIES: 'queries',
    DISAMBIGUATION: 'disambiguation',
    POSITIVE: 'positive',
    NEGATIVE: 'negative',
    P1: 'p1',
    P5: 'p5',
    P_PERCEIVED: 'p_perceived',
    GREATLY_DISSATISFIED: 'greatlyDissatisfied',
    DISSATISFIED: 'dissatisfied',
    MODERATELY_DISSATISFIED: 'moderatelyDissatisfied',
    NEUTRAL: 'neutral',
    MODERATELY_SATISFIED: 'moderatelySatisfied',
    SATISFIED: 'satisfied',
    GREATLY_SATISFIED: 'greatlySatisfied',
    DETRACTORS: 'detractors',
    PROMOTERS: 'promoters',
    EMPTY: 'empty',
};

export const getLabelFromDataKeys = (dataKey: string) => {
    switch (dataKey) {
        case DATA_KEYS.P1:
            return 'P@1';
        case DATA_KEYS.P5:
            return 'P@5';
        case DATA_KEYS.RESPONSE:
            return 'Responses';
        case DATA_KEYS.CHATTERING:
            return 'Chattering';
        case DATA_KEYS.QUERIES:
            return 'Queries';
        case DATA_KEYS.CONTENT:
            return 'Content';
        case DATA_KEYS.P_PERCEIVED:
            return 'Perceived';
        case DATA_KEYS.HELPFUL:
            return 'Helpful';
        case DATA_KEYS.ENGAGED:
            return 'Engaged';
        case DATA_KEYS.LIBRARY:
            return 'Library';
        case DATA_KEYS.RESPONSES:
            return 'Responses';
        case DATA_KEYS.REGISTERED:
            return 'Registered';
        case DATA_KEYS.VISITOR:
            return 'Visitor';
        case DATA_KEYS.UNHELPFUL:
            return 'Unhelpful';
        case DATA_KEYS.TOTAL:
            return 'Total';
        case DATA_KEYS.BOUNCED:
            return 'Bounced';
        case DATA_KEYS.RESOLVED:
            return 'Resolved';
        case DATA_KEYS.HANDED:
            return 'Handed over';
        case DATA_KEYS.ANSWERED:
            return 'Answered';
        case DATA_KEYS.UNCERTAIN:
            return 'Uncertain';
        case DATA_KEYS.ESCALATED:
            return 'Escalated';
        case DATA_KEYS.MISSING:
            return 'Missing';
        case DATA_KEYS.UNRESOLVED:
            return 'Unresolved';
        case DATA_KEYS.GREATLY_DISSATISFIED:
            return 'Greatly Dissatisfied';
        case DATA_KEYS.DISSATISFIED:
            return 'Dissatisfied';
        case DATA_KEYS.MODERATELY_DISSATISFIED:
            return 'Moderately Dissatisfied';
        case DATA_KEYS.NEUTRAL:
            return 'Neutral';
        case DATA_KEYS.MODERATELY_SATISFIED:
            return 'Moderately Satisfied';
        case DATA_KEYS.SATISFIED:
            return 'Satisfied';
        case DATA_KEYS.GREATLY_SATISFIED:
            return 'Greatly Satisfied';
        case DATA_KEYS.DETRACTORS:
            return 'Detractors';
        case DATA_KEYS.PROMOTERS:
            return 'Promoters';
        case DATA_KEYS.EMPTY:
            return 'Empty';
        default:
            return 'N/A';
    }
};

export const getChartColor = (key: string) => {
    switch (key) {
        case DATA_KEYS.P_PERCEIVED:
        case DATA_KEYS.HELPFUL:
        case DATA_KEYS.ENGAGED:
        case DATA_KEYS.REGISTERED:
        case DATA_KEYS.IMPROVE_ANSWER:
        case DATA_KEYS.ANDROID:
        case DATA_KEYS.OPENED:
        case DATA_KEYS.GREATLY_SATISFIED:
            return '#36A385';
        case DATA_KEYS.VISITOR:
        case DATA_KEYS.LIBRARY:
        case DATA_KEYS.DISAMBIGUATION:
        case DATA_KEYS.WINDOWS:
        case DATA_KEYS.P1:
            return '#2E91FF';
        case DATA_KEYS.UNHELPFUL:
        case DATA_KEYS.MISSING_KNOWLEDGE:
        case DATA_KEYS.LINUX:
        case DATA_KEYS.DISSATISFIED:
        case DATA_KEYS.DETRACTORS:
            return '#EA697A';
        case DATA_KEYS.TOTAL:
        case DATA_KEYS.BOUNCED:
        case DATA_KEYS.NOT_OPENED:
        case DATA_KEYS.EMPTY:
            return '#CFCDD8';
        case DATA_KEYS.RESOLVED:
        case DATA_KEYS.ANSWERED:
        case DATA_KEYS.POSITIVE:
            return '#36A385';
        case DATA_KEYS.HANDED:
        case DATA_KEYS.UNCERTAIN:
            return '#2E91FF';
        case DATA_KEYS.CHATTERING:
        case DATA_KEYS.AUTO_ANSWER:
        case DATA_KEYS.P5:
        case DATA_KEYS.NEUTRAL:
        case DATA_KEYS.ESCALATED:
        case DATA_KEYS.MISSING:
            return '#FDAC7A';
        case DATA_KEYS.OSX:
            return '#d6b1bd';
        case DATA_KEYS.UNRESOLVED:
        case DATA_KEYS.NODATA:
            return '#CFCDD8';
        case DATA_KEYS.GREATLY_DISSATISFIED:
        case DATA_KEYS.NEGATIVE:
            return '#E8586B';
        case DATA_KEYS.MODERATELY_DISSATISFIED:
            return '#ED7989';
        case DATA_KEYS.MODERATELY_SATISFIED:
            return '#FDAC7A';
        case DATA_KEYS.SATISFIED:
        case DATA_KEYS.PROMOTERS:
            return '#36A385';
        default:
            return '#36A385';
    }
};

export const getLegendIcon = (
    id: string,
    color = 'black',
    noRender = false,
) => {
    switch (id) {
        case 'helpful':
            return noRender ? ThumbUp : <ThumbUp style={{color}} />;
        case 'unhelpful':
            return noRender ? ThumbDown : <ThumbDown style={{color}} />;
        case 'registered':
            return noRender ? VerifiedUser : <VerifiedUser style={{color}} />;
        case 'visitor':
            return noRender ? AccountCircle : <AccountCircle style={{color}} />;
        default:
            return noRender ? (
                FiberManualRecordIcon
            ) : (
                <FiberManualRecordIcon style={{color}} />
            );
    }
};
