import React, {Component} from 'react';
import cx from 'classnames';
import axios from 'axios';

import ScrollBar from 'react-perfect-scrollbar';
import {Field, FieldArray, reduxForm} from 'redux-form';
import {
    QUERY_ARRAY_OF_FIELDS,
    QUERY_LIST_FORM,
} from '@app/redux/improve/queries/constants';
import {Spinner} from '@app/common';
import {connect} from 'react-redux';

import {
    getNumberOfSelectedQueriesFromQueryList,
    getQueryList,
    isQueryListLoading,
} from '@app/redux/improve/queries/selectors';
import {
    isClusterPatchingError,
    getCluster,
} from '@app/redux/improve/cards/selectors';
import {removeAllSelectedQueries} from '@app/redux/improve/queries/dispatchers';
import {redux} from '@app/redux';
import {increaseSelectedCardIndexAction} from '@app/redux/improve/cards/actions';
import {MdVisibilityOff} from 'react-icons/md';

interface QueryListProps {
    queryList?: {
        id?: string;
        query?: string;
        query_count?: number;
    }[];
    handleSubmit?: any;
    removeSelections?: any;
    selectedQueries?: number;
    pristine?: boolean;
    isLoading?: boolean;
    removeQuery?: any;
    cluster?: any;
    skipCard?: any;
}

export class QueryListComponent extends Component<QueryListProps> {
    handleClickRemoveQuery = (queryId) => () => {
        this.props.removeQuery(queryId);
    };

    renderQueryFieldArray = ({fields}) => {
        const checkboxClassname = cx('manage__query-list-item-checkbox', {
            'manage__query-list-item-checkbox--visible': !this.props.pristine,
        });

        return fields.map((field, index) => {
            const {
                query: queryText,
                id: queryId,
                query_count: count,
            } = fields.get(index);
            return (
                <div className="manage__query-list-item-container" key={field}>
                    <label htmlFor={field}>
                        <Field
                            name={`${field}.checked`}
                            component="input"
                            type="checkbox"
                            id={`${field}.checked`}
                        />
                        <div className={checkboxClassname}>
                            <div />
                        </div>
                    </label>
                    <div className="manage__query-list-item">
                        {queryText}
                        <div className={'manage__query-list-item__count'}>
                            {count}
                        </div>
                    </div>
                    <MdVisibilityOff
                        size={20}
                        className="manage__query-list-item-remove"
                        onClick={() => {
                            axios
                                .put(`/v3.0/improve/muted-queries`, {
                                    queries: [queryId],
                                })
                                .then(() => {
                                    const newQueries = fields
                                        .map((field, index) =>
                                            fields.get(index),
                                        )
                                        .filter((q) => q.id !== queryId);
                                    axios
                                        .patch(
                                            `/v3.0/improve/clusters/${this.props.cluster.id}`,
                                            {
                                                cluster: {
                                                    approved: false,
                                                    queries: newQueries,
                                                },
                                            },
                                        )
                                        .then(() => {
                                            if (fields.length > 1) {
                                                fields.remove(index);
                                            } else {
                                                this.props.skipCard();
                                            }
                                        });
                                });
                        }}
                    />
                </div>
            );
        });
    };

    render() {
        return (
            <div className="manage__query-list-container">
                {this.props.pristine || this.props.selectedQueries === 0 ? (
                    <h2 className="response-card-title">Customer queries</h2>
                ) : (
                    <div className="response-card-selected-queries-container">
                        <h2 className="response-card-title">
                            {this.props.selectedQueries}{' '}
                            {this.props.selectedQueries > 1
                                ? 'queries'
                                : 'query'}{' '}
                            selected
                        </h2>
                        <span onClick={this.props.removeSelections}>
                            Deselect all
                        </span>
                    </div>
                )}
                {!this.props.isLoading ? (
                    <ScrollBar>
                        <form onSubmit={this.props.handleSubmit}>
                            <FieldArray
                                name={QUERY_ARRAY_OF_FIELDS}
                                component={this.renderQueryFieldArray}
                            />
                        </form>
                    </ScrollBar>
                ) : (
                    <div className="manage__query-list-spinner-container a">
                        <Spinner />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cluster: getCluster(state),
    selectedQueries: getNumberOfSelectedQueriesFromQueryList(state),
    queryList: getQueryList(state),
    initialValues: {
        [QUERY_ARRAY_OF_FIELDS]: getQueryList(state).map((q) => {
            return {
                query: q.query,
                id: q.id,
                query_count: q.query_count,
                checked: false,
            };
        }),
    },
    isLoading: isQueryListLoading(state) || isClusterPatchingError(state),
});

const mapDispatchToProps = (dispatch) => ({
    skipCard: () => {
        dispatch(increaseSelectedCardIndexAction());
        dispatch(
            redux.rest.actions[
                redux.responses.constants.GET_IMPROVE_PAGE_RESPONSE
            ].reset(),
        );
    },
    removeSelections: removeAllSelectedQueries(dispatch),
});

export const QueryList = connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    reduxForm({
        form: QUERY_LIST_FORM,
        enableReinitialize: true,
    })(QueryListComponent),
);
