import Axios, {AxiosResponse} from 'axios';
import {ClustersResponse} from '@app/types/Clusters';

export class Clusters {
    cache = {};

    constructor() {}

    storeClustersData = (URL: string, data: ClustersResponse) => {
        this.cache[URL] = data;
    };

    async getClusters(forceRefresh = false) {
        const URL = `v3.1/improve/clusters?limit=100&next_cursor=&approved=false`;
        if (this.cache[URL] && !forceRefresh) {
            return {data: this.cache[URL], error: null};
        }
        const res = {
            data: null,
            error: null,
        };
        try {
            const req: AxiosResponse<any> = await Axios.get(URL);
            const clusters = req.data.clusters || [];
            const clusterDetails = (await Axios.all(
                clusters.map((c) => Axios.get(`v3.1/improve/clusters/${c.id}`)),
            )) as any;

            res.data = clusterDetails.map((cd) => cd.data);
            this.storeClustersData(URL, res.data);
        } catch (e) {
            res.error = e;
        }

        return res;
    }
}
