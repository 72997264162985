import React, {Component, Fragment} from 'react';
import {redux} from '@app/redux';
import {connect} from 'react-redux';
import {ChatBubble, Close, Edit, Search} from '@app/common/icons';
import cx from 'classnames';
import {Spinner} from '@app/common';
import {blocks as blocksUtil} from '@app/utils';
import {get} from 'lodash';
import {BLOCK_KEYS, BLOCK_TYPES} from '@app/constants';
import {MdArrowUpward, MdArrowDownward} from 'react-icons/md';

interface DialogCreationModalProps {
    responses?: any[];
    response?: any;
    getAllPublishedResponses?: any;
    addDialog?: any;
    closeModal?: any;
    dialog?: any[];
    areResponsesLoading?: boolean;
    currentDialog?: any[];
    editingBlock?: boolean;
    outboundNodes?: any[];
}

export class DialogCreationModalComponent extends Component<DialogCreationModalProps> {
    state = {
        selectedResponses: [],
        dialogIntroText: '',
        responsesSearchText: '',
    };

    componentDidMount() {
        this.props.getAllPublishedResponses();
        const {currentDialog, editingBlock} = this.props;
        if (currentDialog && editingBlock) {
            this.setState({
                selectedResponses: get(
                    currentDialog,
                    ['choices_block', 'choices'],
                    [],
                ),
                dialogIntroText: get(
                    currentDialog,
                    ['choices_block', 'text'],
                    '',
                ),
            });
        }
    }
    addResponseToDialog = (response) => {
        this.setState({
            selectedResponses: [
                ...this.state.selectedResponses,
                {node: response.id, text: '', title: response.title},
            ],
        });
    };

    handleAddDialog = (e) => {
        e.preventDefault();
        const {dialogIntroText} = this.state;
        this.props.addDialog({
            type: BLOCK_TYPES.CHOICES,
            [BLOCK_KEYS.CHOICES_BLOCK]: {
                text: dialogIntroText,
                choices: this.state.selectedResponses,
            },
        });
        this.props.closeModal();
    };

    removeResponseFromDialog = (response) => {
        const filteredResponses = this.state.selectedResponses.filter(
            (r) => r.node !== response.node,
        );
        this.setState({
            selectedResponses: filteredResponses,
        });
    };

    changeResponseEditState = (responseToEdit, editing) => {
        const {selectedResponses} = this.state;
        this.setState({
            selectedResponses: selectedResponses.map((response) =>
                response.node === responseToEdit.node
                    ? {...response, editing}
                    : response,
            ),
        });
    };

    isResponseSelected = (response) => {
        return (
            this.state.selectedResponses.filter((r) => r.node === response.id)
                .length > 0
        );
    };

    handleInputKeyPres = (e, r) => {
        if (e.key === 'Enter') {
            this.changeResponseEditState(r, false);
        }
    };

    handleTitleChange = (e, r) => {
        const {selectedResponses} = this.state;
        this.setState({
            selectedResponses: selectedResponses.map((response) =>
                response.node === r.node
                    ? {...response, text: e.target.value}
                    : response,
            ),
        });
    };

    removeEditedTitle = (r) => {
        this.setState(
            {
                selectedResponses: this.state.selectedResponses.map(
                    (response) => {
                        return response.node === r.node
                            ? {...response, text: ''}
                            : response;
                    },
                ),
            },
            () => {
                this.changeResponseEditState(r, false);
            },
        );
    };

    handleChangeDialogIntroText = (event) => {
        this.setState({dialogIntroText: event.target.value});
    };

    handleChangeResponsesSearchText = (event) => {
        this.setState({responsesSearchText: event.target.value});
    };
    handleReOrderChoice = (i, direction) => {
        const {selectedResponses} = this.state;

        const modifier = direction === 'up' ? -1 : +1;

        /**
         * Swap position using ES6 destructuring
         */
        [selectedResponses[i], selectedResponses[i + modifier]] = [
            selectedResponses[i + modifier],
            selectedResponses[i],
        ];

        this.setState({
            selectedResponses: selectedResponses,
        });
    };
    render() {
        const {areResponsesLoading, responses, response, outboundNodes} =
            this.props;

        const {responsesSearchText} = this.state;

        const blocks = [
            {
                choices_block: {
                    choices: this.state.selectedResponses,
                },
                type: 'choices',
            },
        ];
        const richBlocks = blocksUtil.getBlocksWithNodeData(blocks, [
            ...responses,
            ...outboundNodes,
        ]);
        const richChoiceBlock = richBlocks.find(
            (block) => block.type === 'choices',
        );
        const {dialogIntroText} = this.state;
        const richChoices = get(richChoiceBlock, 'choices_block.choices', []);

        return (
            <div className="dialog-creation-modal">
                <h2>Select responses to add as dialog options</h2>
                <div className="dialog-creation-modal__content">
                    <div className="dialog-creation-modal__responses">
                        <h3>All responses</h3>
                        <div className="dialog-creation-modal__responses-search">
                            <label htmlFor="response-search-text">
                                <Search size={18} />
                            </label>
                            <input
                                id="response-search-text"
                                type="text"
                                placeholder="Search in your responses"
                                value={this.state.responsesSearchText}
                                onChange={this.handleChangeResponsesSearchText}
                            />
                        </div>
                        {areResponsesLoading ? (
                            <Spinner />
                        ) : responses.length > 0 ? (
                            responses
                                .filter(
                                    (r) => get(r, 'id') !== get(response, 'id'),
                                )
                                .filter((r) =>
                                    r.title
                                        .toLowerCase()
                                        .includes(
                                            responsesSearchText.toLowerCase(),
                                        ),
                                )
                                .map((r) => {
                                    const isResponseSelected =
                                        this.isResponseSelected(r);
                                    return (
                                        <div
                                            className={cx(
                                                'dialog-creation-modal__response',
                                                {
                                                    selected:
                                                        isResponseSelected,
                                                },
                                            )}
                                            key={r.id}
                                            onClick={() => {
                                                if (!isResponseSelected) {
                                                    this.addResponseToDialog(r);
                                                }
                                            }}
                                        >
                                            <ChatBubble size={16} />
                                            <span>{r.title}</span>
                                        </div>
                                    );
                                })
                        ) : (
                            <div>No responses</div>
                        )}
                    </div>
                    <div className="dialog-creation-modal__detail">
                        <h3>Dialog options</h3>
                        {richChoices.length > 0 ? (
                            <div>
                                <div className="dialog-creation-modal__detail-input-wrapper">
                                    <input
                                        onChange={
                                            this.handleChangeDialogIntroText
                                        }
                                        value={dialogIntroText}
                                    />
                                </div>

                                {richChoices.map((r, i) => {
                                    const title = get(r, ['title'], '');
                                    const text = get(r, ['text'], '');
                                    return (
                                        <div
                                            key={r.node}
                                            className={cx(
                                                'dialog-creation-modal__selected-response',
                                                {editing: r.editing},
                                            )}
                                        >
                                            <div
                                                className={
                                                    'dialog-creation-modal__selected-response__index'
                                                }
                                            >
                                                {i + 1}
                                            </div>
                                            <ChatBubble
                                                className={
                                                    'dialog-creation-modal__selected-response__chat'
                                                }
                                                size={16}
                                            />
                                            {!r.editing ? (
                                                <div
                                                    className={cx(
                                                        'dialog-creation-modal__selected-response__title',
                                                        {edited: !!r.text},
                                                    )}
                                                >
                                                    <strong>
                                                        {title || text}
                                                    </strong>
                                                    {text !== title &&
                                                    !!title ? (
                                                        <span>{text}</span>
                                                    ) : (
                                                        <span>&nbsp;</span>
                                                    )}
                                                </div>
                                            ) : (
                                                <div
                                                    className={
                                                        'dialog-creation-modal__selected-response__edition'
                                                    }
                                                >
                                                    <div className="dialog-creation-modal__selected-response__edition-input">
                                                        <strong>{title}</strong>
                                                        <input
                                                            value={r.text}
                                                            autoFocus={true}
                                                            type="text"
                                                            onChange={(e) => {
                                                                this.handleTitleChange(
                                                                    e,
                                                                    r,
                                                                );
                                                            }}
                                                            onKeyPress={(e) => {
                                                                this.handleInputKeyPres(
                                                                    e,
                                                                    r,
                                                                );
                                                            }}
                                                        />
                                                        <Close
                                                            onClick={() => {
                                                                this.removeEditedTitle(
                                                                    r,
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div className="dialog-creation-modal__selected-response__actions">
                                                {r.editing ? (
                                                    <span
                                                        className="dialog-creation-modal__selected-response__done"
                                                        onClick={() => {
                                                            this.changeResponseEditState(
                                                                r,
                                                                false,
                                                            );
                                                        }}
                                                    >
                                                        DONE
                                                    </span>
                                                ) : (
                                                    <Fragment>
                                                        <Edit
                                                            className="dialog-creation-modal__selected-response__edit"
                                                            onClick={() => {
                                                                this.changeResponseEditState(
                                                                    r,
                                                                    true,
                                                                );
                                                            }}
                                                        />
                                                        <Close
                                                            onClick={() => {
                                                                this.removeResponseFromDialog(
                                                                    r,
                                                                );
                                                            }}
                                                            className={
                                                                'dialog-creation-modal__selected-response__close'
                                                            }
                                                            size={22}
                                                        />
                                                        {i > 0 && (
                                                            <MdArrowUpward
                                                                className="dialog-creation-modal__reorder-icon"
                                                                size={18}
                                                                onClick={() => {
                                                                    this.handleReOrderChoice(
                                                                        i,
                                                                        'up',
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                        {i <
                                                            richChoices.length -
                                                                1 && (
                                                            <MdArrowDownward
                                                                className="dialog-creation-modal__reorder-icon"
                                                                size={18}
                                                                onClick={() => {
                                                                    this.handleReOrderChoice(
                                                                        i,
                                                                        'down',
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    </Fragment>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                                <button
                                    onClick={this.handleAddDialog}
                                    className={
                                        'c-btn dialog-creation-modal__create-button'
                                    }
                                >
                                    {!this.props.editingBlock &&
                                        'Create dialog'}
                                    {this.props.editingBlock && 'Save dialog'}
                                </button>
                            </div>
                        ) : (
                            <div>No selected responses</div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    responses: redux.knowledgeBase.selectors.publishedResponsesSelector(state),
    response: redux.responses.selectors.responseSelector(state),
    outboundNodes: redux.responses.selectors.outboundNodesSelector(state),
    areResponsesLoading:
        redux.knowledgeBase.selectors.publishedResponsesLoadingSelector(state),
});
const mapDispatchToProps = (dispatch) => ({
    getAllPublishedResponses:
        redux.knowledgeBase.dispatchers.getAllPublishedResponsesDispatcher(
            dispatch,
        ),
});

export const DialogCreationModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DialogCreationModalComponent);
