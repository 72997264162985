import {DistributionResponse} from '@app/types/Distribution';

const getDistributionColor = (n: number) => {
    if (n < 5) {
        return '#f9b299';
    }
    if (n > 50) {
        return '#e4d594';
    }
    return '#36A385';
};
export const transformDistributionDataToBarChart = (
    data: DistributionResponse,
) => {
    if (data && data.distribution) {
        return Object.keys(data.distribution).map((d) => ({
            timeseries: d,
            queries: data.distribution[d],
            queriesColor: getDistributionColor(parseInt(d)),
        }));
    } else {
        return [];
    }
};
