import React from 'react';

import './SubmitControls.scss';
import {Button} from '../inputs/buttons/Button';

export interface ISubmitControls {
    onSubmit: () => void;
    onCancel: () => void;
}

export const SubmitControls: React.FunctionComponent<ISubmitControls> = (
    props,
) => {
    return (
        <div className="SubmitControls__container">
            <Button
                text="Cancel"
                type="cancel"
                className="SubmitControls__button"
                onClick={props.onCancel}
            />
            <Button
                text="Save"
                type="confirm"
                className="SubmitControls__button"
                onClick={props.onSubmit}
            />
        </div>
    );
};
