import React from 'react';
import Icon from 'react-icon-base';

interface LauncherProps {
    color?: string;
    style?: any;
}

export class Launcher extends React.Component<LauncherProps> {
    render() {
        return (
            <Icon viewBox="0 0 48 48" {...this.props}>
                <g>
                    <path
                        d="M0,24 C0,19.1462202 1.44139723,14.6286471 3.91943682,10.8520358 C4.44920071,10.0446587 5.02634265,9.27114607 5.64690793,8.5354526 C6.62217834,7.37924859 7.70469901,6.31645319 8.87911929,5.36241707 C13.0064758,2.00957371 18.2688708,0 24,0 C37.2533333,0 48,10.7466667 48,24 C48,37.2533333 37.2533333,48 24,48 C10.7466667,48 0,37.2533333 0,24 Z M27,32 L27,29.6219475 C32.1756816,28.2898192 36,23.5915183 36,18 C36,11.372583 30.627417,6 24,6 C17.372583,6 12,11.372583 12,18 C12,19.6568542 13.3431458,21 15,21 C16.6568542,21 18,19.6568542 18,18 C18,14.6862915 20.6862915,12 24,12 C27.3137085,12 30,14.6862915 30,18 C30,21.3137085 27.3137085,24 24,24 C22.3431458,24 21,25.3431458 21,27 L21,32 C21,33.6568542 22.3431458,35 24,35 C25.6568542,35 27,33.6568542 27,32 Z M21,41 C21,42.6568542 22.3431458,44 24,44 C25.6568542,44 27,42.6568542 27,41 C27,39.3431458 25.6568542,38 24,38 C22.3431458,38 21,39.3431458 21,41 Z"
                        id="FAB-Question-Mark"
                    />
                </g>
            </Icon>
        );
    }
}
