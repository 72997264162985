import React from 'react';
import {get} from 'lodash';
import {Document} from '@app/common/icons';
import {Markdown} from '@app/common';
import {selectDocumentAction} from '@app/redux/improve/documents/actions';
import {BlockControls} from '../block-controls';
import {connect} from 'react-redux';
import cx from 'classnames';

export const renderInactiveQuote = (
    block,
    index,
    fields,
    activateBlock,
    removeBlock,
) => {
    const quote = get(block, ['quote_block', 'quote'], '');
    const url = get(block, ['quote_block', 'url']);
    const title = get(block, ['quote_block', 'title']);
    const broken = get(block, ['quote_block', 'broken'], false);

    const blockClassname = cx(
        'kare-block kare-block__quote kare-block--inactive',
        {'kare-block-broken': broken},
    );
    const quoteTitleClassName = cx('kare-block__quote-title', {
        'kare-block__quote-title-broken': broken,
    });
    return (
        <div className="kare-block-wrapper">
            <div className={blockClassname}>
                {quote && (
                    <div className="kare-block__quote-text">
                        <Markdown>{quote}</Markdown>
                    </div>
                )}
                {url && title ? (
                    <a href={url} className={quoteTitleClassName}>
                        <Document /> <span>{title}</span>
                    </a>
                ) : title ? (
                    <span className={quoteTitleClassName}>
                        <Document /> <span>{title}</span>
                    </span>
                ) : (
                    <div className="kare-block__quote-title kare-block__quote-title--missing">
                        <Document /> <span>{'Unpublished'}</span>
                    </div>
                )}
            </div>
            {index >= 0 && fields && removeBlock && (
                <BlockControls
                    index={index}
                    fields={fields}
                    removeBlock={removeBlock}
                    activateBlock={activateBlock}
                />
            )}
        </div>
    );
};

interface QuoteBlockFieldProps {
    input?: any;
    selectDocument?: any;
    activateBlock?: any;
    removeBlock?: any;
    fields?: any[];
    index?: number;
}

export class QuoteBlockFieldComponent extends React.Component<QuoteBlockFieldProps> {
    state = {
        active: false,
    };

    handleChange = (event) => {
        const {
            input: {onChange},
        } = this.props;
        const value = event.target.value;
        onChange({type: 'quote', quote_block: {quote: value}});
    };

    handleBlur = (event) => {
        this.handleChange(event);
        this.setState({active: false});
    };

    activateBlock = () => {
        const {input, selectDocument, activateBlock} = this.props;
        const value = get(input, ['value'], {});
        const node = get(value, ['quote_block', 'node'], '');
        activateBlock();
        selectDocument(node);
    };

    render() {
        const {removeBlock, fields, index, input} = this.props;

        const value = get(input, ['value'], {});

        return renderInactiveQuote(
            value,
            index,
            fields,
            this.activateBlock,
            removeBlock,
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    selectDocument: (id, title, url) => {
        return dispatch(selectDocumentAction(id, title, url));
    },
});

export const QuoteBlockField = connect(
    null,
    mapDispatchToProps,
)(QuoteBlockFieldComponent);
