import React, {FunctionComponent as Fc} from 'react';

interface INeutralProps {
    size: number;
    color: string;
}

export const Neutral: Fc<INeutralProps> = ({size, color}) => (
    <svg
        viewBox="0 0 15 15"
        height={`${size}px`}
        width={`${size}px`}
        style={{color: color}}
    >
        <defs>
            <path
                d="M5.25,9 L9.75,9 L9.75,10.125 L5.25,10.125 L5.25,9 Z M10.125,6.75 C10.7463203,6.75 11.25,6.24632034 11.25,5.625 C11.25,5.00367966 10.7463203,4.5 10.125,4.5 C9.50367966,4.5 9,5.00367966 9,5.625 C9,6.24632034 9.50367966,6.75 10.125,6.75 Z M4.875,6.75 C5.49632034,6.75 6,6.24632034 6,5.625 C6,5.00367966 5.49632034,4.5 4.875,4.5 C4.25367966,4.5 3.75,5.00367966 3.75,5.625 C3.75,6.24632034 4.25367966,6.75 4.875,6.75 Z M7.4925,0 C3.3525,0 0,3.36 0,7.5 C0,11.64 3.3525,15 7.4925,15 C11.64,15 15,11.64 15,7.5 C15,3.36 11.64,0 7.4925,0 Z M7.5,13.5 C4.185,13.5 1.5,10.815 1.5,7.5 C1.5,4.185 4.185,1.5 7.5,1.5 C10.815,1.5 13.5,4.185 13.5,7.5 C13.5,10.815 10.815,13.5 7.5,13.5 Z"
                id="path-2"
            ></path>
        </defs>
        <g
            id="icon-feedback-neutral"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <mask id="mask-2" fill="white">
                <use xlinkHref="#path-2"></use>
            </mask>
            <use
                id="icon/social/sentiment_neutral_24px"
                fill="currentColor"
                fillRule="nonzero"
                xlinkHref="#path-2"
            ></use>
        </g>
    </svg>
);
