import {ACTIONS, CONTENT_LOCALE} from '../constants';
import {PAYLOAD} from '../../constants';

const initialState = {
    [CONTENT_LOCALE]: undefined,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SET_CONTENT_LOCALE:
            return {
                ...state,
                [CONTENT_LOCALE]: action[PAYLOAD][CONTENT_LOCALE],
            };
        case ACTIONS.RESET_CONTENT_LOCALE:
            return {
                ...state,
                [CONTENT_LOCALE]: undefined,
            };
        default:
            return state;
    }
};
