import moment from 'moment';

import {
    ConversationCounters,
    ConversationsAggregatedResponse,
    ConversationsResponse,
} from '@app/types/Conversations';
import {DATA_KEYS, getChartColor} from './DataRenderingUtils';
import {calcRatio, fixDecimals} from './Common';

export const transformConversationsDataToBarChart = (
    data: ConversationsResponse,
) => {
    const counters = data?.counters
        ?.sort((a, b) => a.timestamp - b.timestamp)
        .map((d) => {
            const counters: ConversationCounters =
                d.counters || ({} as ConversationCounters);
            const escalated = counters.escalated || 0;
            const resolved = counters.resolved || 0;
            const handover = counters.handover || 0;
            const unresolved = counters.unresolved || 0;

            return {
                timeseries: moment.unix(d.timestamp).format('DD-MM'),
                resolved: resolved,
                resolvedColor: getChartColor(DATA_KEYS.RESOLVED),
                handed: handover,
                handedColor: getChartColor(DATA_KEYS.HANDED),
                escalated: escalated,
                escalatedColor: getChartColor(DATA_KEYS.ESCALATED),
                unresolved: unresolved,
                unresolvedColor: getChartColor(DATA_KEYS.UNRESOLVED),
            };
        });
    const total = data.counters.reduce((a, b) => {
        const c: ConversationCounters =
            b.counters || ({} as ConversationCounters);
        return (
            a +
            ((c.conversations || 0) - (c.bounced || 0) - (c.not_opened || 0))
        );
    }, 0);

    if (total === 0) {
        return {
            counters: [],
            total: 0,
            totals: {
                resolved: 0,
                unresolved: 0,
                escalated: 0,
                handover: 0,
            },
        };
    }
    return {
        counters,
        total: total,
        totals: {
            resolved: data.counters.reduce((a, b) => {
                const c: ConversationCounters =
                    b.counters || ({} as ConversationCounters);
                return a + (c.resolved || 0);
            }, 0),
            unresolved: data.counters.reduce((a, b) => {
                const c: ConversationCounters =
                    b.counters || ({} as ConversationCounters);
                return a + (c.unresolved || 0);
            }, 0),
            escalated: data.counters.reduce((a, b) => {
                const c: ConversationCounters =
                    b.counters || ({} as ConversationCounters);
                return a + (c.escalated || 0);
            }, 0),
            handover: data.counters.reduce((a, b) => {
                const c: ConversationCounters =
                    b.counters || ({} as ConversationCounters);
                return a + (c.handover || 0);
            }, 0),
        },
    };
};

export const transformAggregatedConversationsDataToRadialChart = (
    data: ConversationsAggregatedResponse,
) => {
    const counters = data.counters || {
        conversations: 0,
        bounced: 0,
        not_opened: 0,
    };
    const notOpened = counters.not_opened;
    const totalOpenedConversations = counters.conversations - notOpened;
    const bounced = counters.bounced;
    const engaged = totalOpenedConversations - bounced;
    const engagedRatio = calcRatio(engaged, totalOpenedConversations);
    const bouncedRatio = calcRatio(bounced, totalOpenedConversations);
    return {
        plotData:
            totalOpenedConversations > 0
                ? {
                      engaged: engagedRatio,
                      bounced: bouncedRatio,
                  }
                : {nodata: 100},
        legendData: {
            engagedValue: engaged,
            bouncedValue: bounced,
            engaged: engagedRatio,
            bounced: bouncedRatio,
        },
    };
};
export const transformAggregatedOpportunityDataToRadialChart = (data: any) => {
    const handoverClicks = data?.entries?.handover?.counters?.clicks || 0;
    const webextClicks = data?.entries?.webext?.counters?.clicks || 0;
    const totalClicks = handoverClicks + webextClicks;

    const handoverRatio = calcRatio(handoverClicks, totalClicks);
    const webextRatio = calcRatio(webextClicks, totalClicks);

    return {
        plotData:
            totalClicks > 0
                ? {
                      handover: handoverRatio,
                      webext: webextRatio,
                  }
                : {nodata: 100},
        legendData: {
            handoverValue: handoverClicks,
            webextValue: webextClicks,
            handover: handoverRatio,
            webext: webextRatio,
        },
    };
};
export const transformAggregatedJourneyDataToRadialChart = (data: any) => {
    const webext = data?.entries?.webext;
    let totalClicks = 0;
    let filteredAutomations = [];
    if (webext) {
        const keys = Object.keys(webext);
        totalClicks = keys.reduce((a, v) => {
            return a + webext[v].counters?.clicks || 0;
        }, 0);
        filteredAutomations = Object.keys(webext)
            .map((k) => webext[k])
            .filter((w) => w.counters.clicks > 0);
    }

    return {
        plotData:
            filteredAutomations?.length > 0
                ? {automations: filteredAutomations, totalClicks: totalClicks}
                : {nodata: {counters: {clicks: 100}}},
        legendData: {},
    };
};

export const transformAggregatedOpenRateToRadialChart = (
    data: ConversationsAggregatedResponse,
) => {
    const counters = data.counters || {
        conversations: 0,
        bounced: 0,
        not_opened: 0,
    };
    const total = counters.conversations;
    const notOpened = counters.not_opened || 0;
    const opened = total - notOpened;
    const openedRation = calcRatio(opened, total);
    const notOpenedRatio = calcRatio(notOpened, total);
    return {
        plotData:
            total > 0
                ? {
                      opened: opened,
                      notOpened: notOpened,
                  }
                : {nodata: 100},
        legendData: {
            openedValue: opened,
            notOpenedValue: notOpened,
            opened: openedRation,
            notOpened: notOpenedRatio,
        },
    };
};

export const transformAggregatedConversationsDataToHandledChart = (
    data: ConversationsAggregatedResponse,
    dataKey = '',
) => {
    const c = data.counters || {
        conversations: 0,
        bounced: 0,
        handover: 0,
        escalated: 0,
        unresolved: 0,
        not_opened: 0,
        resolved: 0,
    };

    const conversations = c.conversations;
    const bounced = c.bounced;
    const handover = c.handover;
    const escalated = c.escalated;
    const unresolved = c.unresolved;
    const notOpened = c.not_opened;
    const resolved = c.resolved;

    const engaged = conversations - (notOpened + bounced);

    const total = resolved + handover + escalated + unresolved;
    const handled = calcRatio(resolved + handover + escalated, total);
    const deflected = calcRatio(resolved + unresolved, total);
    const automated = calcRatio(resolved, total);

    const resolvedRatio = calcRatio(
        engaged - (escalated + handover + unresolved),
        engaged,
    );

    const handoverRatio = calcRatio(handover, engaged);
    const escalatedRatio = calcRatio(escalated, engaged);
    const unresolvedRatio = calcRatio(unresolved, engaged);

    const getResolved = () => {
        switch (dataKey) {
            case 'deflected':
                return fixDecimals(deflected);
            case 'handled':
                return fixDecimals(handled);
            case 'automated':
                return fixDecimals(automated);
            default:
                return handled;
        }
    };

    return {
        totals: {
            resolved: getResolved(),
            conversations: conversations,
        },
        plotData: {
            resolved: getResolved(),
            unresolved: fixDecimals(100 - getResolved()),
        },
        legendData: {
            resolved: resolvedRatio + '%',
            handed: handoverRatio + '%',
            escalated: escalatedRatio + '%',
            unresolved: unresolvedRatio + '%',
        },
    };
};
