import {
    CHANGE_SELECTED_MAX_DATE,
    CHANGE_SELECTED_MIN_DATE,
    DATE,
} from '../constants';

const initialState = {
    [DATE]: {
        minDate: undefined,
        maxDate: undefined,
    },
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_SELECTED_MIN_DATE:
            return {
                ...state,
                [DATE]: {
                    minDate: action.payload.minDate,
                    maxDate: state[DATE].maxDate,
                },
            };
        case CHANGE_SELECTED_MAX_DATE:
            return {
                ...state,
                [DATE]: {
                    minDate: state[DATE].minDate,
                    maxDate: action.payload.maxDate,
                },
            };
        default:
            return state;
    }
};
