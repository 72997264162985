import React from 'react';
import {values} from 'lodash';
import {useHistory} from 'react-router-dom';
import {MdChat, MdChatBubble} from 'react-icons/md';
import {LibraryBooks} from '@material-ui/icons';

import './ExitsResponseTable.scss';
import {Card} from '@app/components/Card/Card';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {Table} from '@app/components/Table/Table';
import {Call} from '@app/state';
import {ExitPageByNode} from '@app/state/ExitPage';

export interface ExitsResponseTableProps {
    metrics: Call<ExitPageByNode | null>;
}

export const ExitsResponseTable: React.FC<ExitsResponseTableProps> = (
    props,
) => {
    const history = useHistory();
    const getEmptyState = () => {
        if (props.metrics.loading) {
            return null;
        }

        if (!props.metrics.data) {
            return 'No data';
        }

        return null;
    };

    return (
        <Card>
            <CardTitle
                title="Top exits from responses"
                className="ExitsResponseTable__title"
            />
            <ErrorLoadingWrapper
                height={'400px'}
                isLoading={props.metrics.loading}
                error={props.metrics.error || ''}
                emptyState={getEmptyState()}
                render={() => {
                    return (
                        <Table
                            columns={['Title', 'Exits']}
                            rows={values(props.metrics.data).map((m) => {
                                return {
                                    values: [
                                        {
                                            value: m.title,
                                            label: (
                                                <span className="ExitsResponseTable__titleNode">
                                                    {m.node_type ===
                                                        'response' && (
                                                        <MdChatBubble />
                                                    )}
                                                    {m.node_type ===
                                                        'content' && (
                                                        <LibraryBooks />
                                                    )}
                                                    {m.node_type ===
                                                        'chattering' && (
                                                        <MdChat />
                                                    )}
                                                    {m.title}
                                                </span>
                                            ),
                                        },
                                        {value: m.counters?.answers || 0},
                                    ],
                                    click: () => {
                                        if (m.node_id?.length > 0) {
                                            history.push(
                                                `/dialog/${m.node_id}`,
                                            );
                                        }
                                    },
                                };
                            })}
                        />
                    );
                }}
            />
        </Card>
    );
};
