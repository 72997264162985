import React from 'react';
import Icon from 'react-icon-base';

export const ChatBubbleCheck = (props) => (
    <Icon viewBox="0 0 17 17" {...props}>
        <g id="Page-1">
            <path
                id="all-done"
                className="cls-1"
                d="M14.7,3A1.3,1.3,0,0,1,16,4.3v7.8a1.45,1.45,0,0,1-1.3,1.46H5.6L3,16V5A2,2,0,0,1,5,3ZM13,2,4,2A2,2,0,0,0,2,4H2v7L0,13V2A2,2,0,0,1,2,0h9.38A1.62,1.62,0,0,1,13,1.63ZM8.47,11.2l5.17-5.42-.81-.85L8.47,9.5,6.41,7.34l-.81.85Z"
            />
        </g>
    </Icon>
);
