export const DASHBOARD_FORM = 'DASHBOARD_FORM';
export const VIEWING_OPTION_DAILY = 'daily';
export const VIEWING_OPTION_HOURLY = 'hourly';
export const MONTH_LABEL_FORMAT = 'MMMM';
export const HOUR_LABEL_FORMAT = 'H';
export const DATE_LABEL_FORMAT = 'DD/MM/YY';
export const DEFAULT_VIEWING_OPTION = VIEWING_OPTION_HOURLY;
export const VIEWING_OPTIONS = [
    {key: VIEWING_OPTION_DAILY, label: 'Daily'},
    {key: VIEWING_OPTION_HOURLY, label: 'Hourly'},
];
export const DATE_FORM_FIELD_FROM = 'from';
export const DATE_FORM_FIELD_TO = 'to';
export const CHART_CONFIG = {
    width: 900,
    height: 340,
    margin: {top: 30, bottom: 10, left: -35},
    resolvedKey: 'resolved',
    escalatedKey: 'escalated',
    bouncedKey: 'bounced',
    deflectedKey: 'deflected',
    handoverKey: 'handover',
    resolvedColor: '#00b957',
    responsesKey: 'responses',
    disambiguationsKey: 'disambiguations',
    disambiguationsColor: '#03a9f4',
    missingKey: 'missing',
    missingColor: '#d3262e',
    deflectedColor: '#00b957',
    handoverKeyColor: '#03a9f4',
    responsesColor: '#00b957',
    escalatedColor: '#d3262e',
    bouncedColor: '#859ba5',
    stackId: 'conversations',
    gridColor: '#edf0f3',
    barSize: 5,
    labels: {
        size: '10px',
        colour: '#263238',
        xAxis: {
            yOffset: 10,
            lineOffset: '1.2em',
        },
    },
};

export const ACCOUNT_STATUS_STATIC_DATA = {
    company: {
        title: 'Complete company details',
        detail: 'Setup a contact point for your customers to contact you when they need more help.',
        button: {
            text: 'COMPANY DETAILS',
            link: '/settings/company',
        },
    },
    responses: {
        title: 'Create your first response',
        detail: 'Responses are your answers to specific customer questions.',
        button: {
            text: 'RESPONSES',
            link: '/responses',
        },
    },
    widget: {
        title: 'Publish your widget',
        detail: 'Customise your widget the way you want it to look and publish it on your website.',
        button: {
            text: 'WIDGET SETTINGS',
            link: '/settings/widget',
        },
    },
};
