import React from 'react';
import {QueriesResponseList} from './queries-response-list';
import {QueriesResponseSearch} from './queries-response-search';
import {QUERIES_RESPONSE_SEARCH} from './queries-response-search/constants';
import {SelectedResponse} from '../selected-response';
import {NewResponse} from '../new-response';
import {
    isAutoAnswerCluster,
    isImproveAnswerCluster,
    isNewResponse,
} from '@app/redux/improve/responses/selectors';
import {FILTER_QUERY_RESPONSES} from '@app/redux/improve/responses/constants';
import {connect} from 'react-redux';
import {redux} from '@app/redux';
import {getCluster} from '@app/redux/improve/cards/selectors';
import {createImproveSelectedResponse} from '@app/redux/improve/responses/dispatchers';
import {get} from 'lodash';
import {
    AUTO_ANSWER_CLUSTER,
    DISAMBIGUATION_CLUSTER,
} from '@app/redux/improve/constants';

interface QueriesResponseProps {
    filterQueryResponses?: any;
    isNewResponse?: boolean;
    selectedResponse?: any;
    handleNewResponse?: any;
    resetImprovePageResponse?: any;
    cluster?: any;
    createFakeResponseDispatcher?: any;
}

export class QueriesResponseComponent extends React.Component<QueriesResponseProps> {
    state = {
        newResponse: false,
        forceAllResponses: false,
    };

    handleSubmit = (values) => {
        this.props.filterQueryResponses(values[QUERIES_RESPONSE_SEARCH]);
    };

    handleNewResponseClick = () => {
        this.props.handleNewResponse();
    };

    handleForceAllResponses = () => {
        this.setState({
            forceAllResponses: !this.state.forceAllResponses,
        });
    };

    componentDidMount() {
        this.props.resetImprovePageResponse();

        const clusterType = get(this.props.cluster, 'cluster_type');
        const clusterNodes = get(this.props.cluster, 'nodes');
        if (
            clusterType === AUTO_ANSWER_CLUSTER &&
            clusterNodes &&
            clusterNodes.length === 1
        ) {
            this.props.createFakeResponseDispatcher({
                ...clusterNodes[0],
                blocks: get(clusterNodes[0], ['response', 'blocks']),
                queries: get(clusterNodes[0], ['response', 'queries']),
            });
        }
    }

    render() {
        return (
            <div className="queries-response-container">
                {this.renderQueryResponseCardComponent()}
            </div>
        );
    }

    renderQueryResponseCardComponent = () => {
        const {isNewResponse, selectedResponse, cluster} = this.props;

        if (selectedResponse) {
            return <SelectedResponse selectedResponse={selectedResponse} />;
        }
        if (isNewResponse) {
            return <NewResponse />;
        }

        const isDisambiguation =
            cluster.cluster_type === DISAMBIGUATION_CLUSTER;
        const responseSearchTitle = isDisambiguation
            ? 'Please either select a response or create a new dialog'
            : 'Choose your response';
        return (
            <div className="queries-response-container__list-documents">
                <QueriesResponseSearch
                    isDisambiguation={
                        isDisambiguation && !this.state.forceAllResponses
                    }
                    onSubmit={this.handleSubmit}
                    handleForceAllResponses={this.handleForceAllResponses}
                    title={responseSearchTitle}
                />
                <QueriesResponseList
                    onNewResponseClick={this.handleNewResponseClick}
                    forceAllResponses={this.state.forceAllResponses}
                />
            </div>
        );
    };
}
const mapStateToProps = (state) => ({
    selectedResponse:
        redux.responses.selectors.improvePageResponseSelector(state),
    cluster: getCluster(state),
    isNewResponse: isNewResponse(state),
    isAutoAnswerCluster: isAutoAnswerCluster(state),
    isImproveAnswerCluster: isImproveAnswerCluster(state),
    isDocumentFetching:
        redux.documents.selectors.isDocumentFetchingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    createFakeResponseDispatcher: (response) => {
        createImproveSelectedResponse(dispatch)(response);
    },
    resetImprovePageResponse: () =>
        dispatch(
            redux.rest.actions[
                redux.responses.constants.GET_IMPROVE_PAGE_RESPONSE
            ].reset(),
        ),
    filterQueryResponses: (query) =>
        dispatch({type: FILTER_QUERY_RESPONSES, payload: query}),
    handleNewResponse: () => dispatch(),
});

export const QueriesResponse = connect(
    mapStateToProps,
    mapDispatchToProps,
)(QueriesResponseComponent);
