export const passwordMatchValidation = (value, allValues) => {
    return value !== allValues.password ? `Passwords don't match` : undefined;
};

export const passwordStrengthValidation = (value) => {
    const passwordValidation = {
        lengthValidator: {
            message: 'Check that your password contains at least 8 characters',
            regex: /.{8}/,
        },
        upperCaseValidator: {
            message:
                'Check that your password contains at least 1 uppercase character',
            regex: /(?=.*[A-Z])/,
        },
        lowerCaseValidator: {
            message:
                'Check that your password contains at least 1 lowercase character',
            regex: /(?=.*[a-z])/,
        },
        alphaNumericValidator: {
            message: 'Check that your password contains at least 1 number',
            regex: /(?=.*\d)/,
        },
        specialCharacterValidator: {
            message:
                'Check that your password contains at least 1 special character: (!@#£$*%^&[]{}();:,./?<>)',
            regex: /(?=.[!@#£$&*%^&[\]{}\\;:,./?<>()])/,
        },
    };
    if (value) {
        for (const validation in passwordValidation) {
            if (
                passwordValidation.hasOwnProperty(validation) &&
                !value.match(passwordValidation[validation].regex)
            ) {
                return passwordValidation[validation].message;
            }
        }
    }
    return undefined;
};
