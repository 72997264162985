import React, {Fragment} from 'react';
import {GenericModal} from './generic-modal';
import {WideModal} from './wide-modal';
import {Close} from '@app/common/icons';

export const TYPES = {
    GENERIC: 'GENERIC',
    WIDE: 'WIDE',
};

interface KareModalProps {
    type?: string;
    title?: string;
    children: any;
    closeModal: any;
    hasCloseIcon?: boolean;
}

/**
 * Creating new Modals
 * New modal types must include a click handler on the outer element that calls
 * event.stopPropagation(). This is the only requirement and is used to allow clicking outside
 * of the modal to close the modal.
 */
export class KareModal extends React.Component<KareModalProps> {
    handleClickClose = () => {
        const {closeModal} = this.props;
        closeModal();
    };

    renderClose = () => {
        const {title, hasCloseIcon = true} = this.props;
        return (
            <div className="kare-modal-header">
                <h1 className="kare-modal-title">{title}</h1>
                {hasCloseIcon && (
                    <Close size={21} onClick={this.handleClickClose} />
                )}
            </div>
        );
    };

    renderModal = () => {
        const {type, children} = this.props;
        switch (type) {
            case TYPES.GENERIC:
                return (
                    <GenericModal>
                        {this.renderClose()}
                        {children}
                    </GenericModal>
                );
            case TYPES.WIDE:
                return (
                    <WideModal>
                        {this.renderClose()}
                        {children}
                    </WideModal>
                );
            default:
                return (
                    <GenericModal>
                        {this.renderClose()}
                        {children}
                    </GenericModal>
                );
        }
    };

    render() {
        return (
            <Fragment>
                <div className="kare-modal-overlay">{this.renderModal()}</div>
            </Fragment>
        );
    }
}
