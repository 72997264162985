import React from 'react';
import get from 'lodash/get';
import {Markdown} from '@app/common';

interface MessageAttachmentProps {
    message: any;
}

export const MessageQuote = (props: MessageAttachmentProps) => {
    const {message} = props;

    const body = message.body;

    const text = body.text;
    if (!text) return null;

    const action = get(message, 'event_body.out_text.actions[0]', '');
    const buttonText = get(action, 'text', '');
    const href = get(action, ['on_click', '2', 'params', 'href'], '');
    return (
        <div
            className={
                'console-activity-message-container message-quote__container console-activity--out'
            }
        >
            <div className="message-quote__body">
                <div className="message-quote__md">
                    <Markdown>{text}</Markdown>
                </div>
                <div className="message-quote__link">
                    <a href={href} target="_blank" rel="noreferrer">
                        {buttonText}
                    </a>
                </div>
            </div>
        </div>
    );
};
