import React from 'react';
import Select from 'react-select';

interface MultiselectInputProps {
    input: any;
    options: any[];
    label?: string;
}

export class MultiselectInput extends React.Component<MultiselectInputProps> {
    render() {
        const {input, options, label} = this.props;
        return (
            <div className="kare-multiselect-input">
                {label && <label htmlFor={input.name}>{label}</label>}
                <Select
                    className="react-select-container"
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    options={options}
                    onChange={input.onChange}
                    isMulti={true}
                    value={input.value}
                    styles={{
                        multiValueRemove: (base) => ({
                            ...base,
                            color: '#676f73',
                            '&:hover': {
                                backgroundColor: '#D81616',
                                color: '#fff',
                            },
                        }),
                    }}
                />
            </div>
        );
    }
}
