import React, {useState} from 'react';
import {ArrowDropDown, ArrowDropUp} from '@material-ui/icons';
import cx from 'classnames';

import './Table.scss';

export type Row = {value: string; label?: string}[];

export interface TableProps {
    columns: string[];
    rows: Row[];
    rowStyle?: (rowPosition: number) => string;
    maxRowsVisible?: number;
    isCellAMetric?: (rowPosition: number) => boolean;
    showPosition?: boolean;
    showPercentage?: boolean;
}

const isNumber = (value: any) => {
    return !Number.isNaN(Number(value));
};

const sortIncr = (activeSortPosition: number) => (r1: Row, r2: Row) => {
    const value1ToSort =
        r1.values?.length > 0
            ? r1.values[activeSortPosition].value
            : r1[activeSortPosition].value;
    const value2ToSort =
        r2.values?.length > 0
            ? r2.values[activeSortPosition].value
            : r2[activeSortPosition].value;

    if (!isNumber(value1ToSort)) {
        if (value1ToSort < value2ToSort) {
            return -1;
        } else {
            return 1;
        }
    } else {
        const numberValue1 = parseInt(value1ToSort);
        const numberValue2 = parseInt(value2ToSort);
        if (numberValue1 < numberValue2) {
            return -1;
        } else {
            return 1;
        }
    }
};

const sortDecr = (activeSortPosition: number) => (r1: Row, r2: Row) => {
    const value1ToSort =
        r1.values?.length > 0
            ? r1.values[activeSortPosition].value
            : r1[activeSortPosition].value;
    const value2ToSort =
        r2.values?.length > 0
            ? r2.values[activeSortPosition].value
            : r2[activeSortPosition].value;
    if (!isNumber(value1ToSort)) {
        if (value1ToSort > value2ToSort) {
            return -1;
        } else {
            return 1;
        }
    } else {
        const numberValue1 = parseFloat(value1ToSort);
        const numberValue2 = parseFloat(value2ToSort);
        if (numberValue1 > numberValue2) {
            return -1;
        } else {
            return 1;
        }
    }
};

export const Table: React.FC<TableProps> = ({
    maxRowsVisible = -1,
    rowStyle = () => '',
    isCellAMetric = undefined,
    ...props
}) => {
    const [currentRowHover, setCurrentRowHover] = useState(null);
    const [activeSortPosition, setActiveSortPosition] = useState(1);
    const [seeAll, setSeeAll] = useState(maxRowsVisible <= 0);
    const [sortStates, setSortStates] = useState<{[key: string]: number}>(
        props.columns.reduce((acc, value, index) => {
            acc[value] = 1;
            if (index === 1) {
                acc[value] = -1;
            }
            return acc;
        }, {}),
    );

    const rowsSorted = [].concat(
        props.rows?.values?.length > 0 ? props.rows.values : props.rows,
    );

    rowsSorted.sort(
        sortStates[props.columns[activeSortPosition]] === 1
            ? sortIncr(activeSortPosition)
            : sortDecr(activeSortPosition),
    );

    const getColumnPosition = (index: number) => index;

    const getSortIcons = (state: number) => {
        if (state === -1) {
            return <ArrowDropDown />;
        }
        return <ArrowDropUp />;
    };

    const columns = props.columns;
    const columnLength = columns.length;
    const isMetric = (position: number) => position > 0;
    if (!isCellAMetric) {
        isCellAMetric = isMetric;
    }

    const metricColumnsLength = columns.reduce((acc, v, index) => {
        if (isCellAMetric!(index)) {
            acc++;
        }
        return acc;
    }, 0);

    const percentagePerNonMetricCell =
        50 / (columnLength - metricColumnsLength);

    const gridCss = columns.reduce((acc, v, index) => {
        if (!isCellAMetric!(index)) {
            acc += `${percentagePerNonMetricCell}% `;
        } else {
            acc += `minmax(0, 1fr) `;
        }
        return acc;
    }, '');

    const handleMouseOver = (rowIndex: number) => {
        setCurrentRowHover(rowIndex);
    };
    const shouldBeHighlighted = (rowIndex: number) => {
        return rowIndex === currentRowHover;
    };

    return (
        <div className="Table__container">
            <div
                className="Table__tableContainer"
                style={{
                    gridTemplateColumns: gridCss,
                }}
            >
                {columns.map((c, index) => {
                    return (
                        <div
                            onClick={() => {
                                setSortStates({
                                    ...sortStates,
                                    [c]: -1 * sortStates[c],
                                });
                                setActiveSortPosition(getColumnPosition(index));
                            }}
                            key={index}
                            className={cx('Table__column', {
                                ['Table__active']:
                                    activeSortPosition ===
                                    getColumnPosition(index),
                                ['Table__metric']: isCellAMetric!(index),
                            })}
                        >
                            <span>
                                {c}
                                {getSortIcons(sortStates[c])}
                            </span>
                        </div>
                    );
                })}
                {rowsSorted.map((r: Row, position: number) => {
                    if (!seeAll && position >= maxRowsVisible) {
                        return null;
                    }
                    const rows: any = r.values?.length > 0 ? r.values : r;
                    return (
                        <>
                            {rows.map(
                                (
                                    v: {value: string; label?: string},
                                    index: number,
                                ) => {
                                    return (
                                        <div
                                            key={index}
                                            className={cx(
                                                'Table__rowValue',
                                                {
                                                    ['Table__last']:
                                                        index === r.length - 1,
                                                    ['Table__first']:
                                                        index === 0,
                                                    ['Table__highlighted']:
                                                        shouldBeHighlighted(
                                                            position,
                                                        ),
                                                    ['Table__metric']:
                                                        isCellAMetric!(index),
                                                },
                                                rowStyle(position),
                                            )}
                                            onMouseOver={() => {
                                                handleMouseOver(position);
                                            }}
                                            onMouseOut={() => {
                                                handleMouseOver(null as any);
                                            }}
                                            onClick={() => {
                                                //@ts-ignore
                                                if (r.click) {
                                                    //@ts-ignore
                                                    r.click();
                                                }
                                            }}
                                        >
                                            {v.label ? v.label : v.value}
                                        </div>
                                    );
                                },
                            )}
                        </>
                    );
                })}
            </div>
            {maxRowsVisible > 0 && rowsSorted.length > maxRowsVisible && (
                <div
                    className="Table__seeAll"
                    onClick={() => {
                        setSeeAll(!seeAll);
                    }}
                >
                    {!seeAll && (
                        <span>
                            See all <ArrowDropDown />
                        </span>
                    )}
                    {seeAll && (
                        <span>
                            Collapse list <ArrowDropUp />
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};
