import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {MdNewReleases} from 'react-icons/md';

import {NavLink} from '../nav-link';
import {HelpOutline, ExitToApp, Settings} from '@app/common/icons';
import {auth} from '@app/utils';
import {redux} from '@app/redux';

import {getCookie} from '@app/utils/auth';
import {getDashboardDomain} from '@app/utils/client-url';
import {Dialpad} from '@app/assets/icons';

interface AccountMenuProps {
    logout?: any;
    history?: any;
    email?: string;
    widget?: any;
    subdomain?: string;
    isBeta?: boolean;
}

export class AccountMenuComponent extends React.Component<AccountMenuProps> {
    handleClickWidgetLauncher = () => {
        //@ts-ignore
        window.kare && window.kare.open();
    };

    handleClickLogout = () => {
        const {logout, history} = this.props;
        // Go to the homepage before logout - user never sees this.
        // So that we do not go to `/login?redirect=some-previous-page`
        // We just go to `/login`
        return logout().then(() => {
            history.push('/login');
        });
    };

    render() {
        const {email, subdomain, isBeta} = this.props;

        return (
            <section className="kare-side-bar__account-menu">
                <header>
                    <Dialpad size={36} color="#6C3DFF" />
                    <div className="kare-side-bar__account-menu-details">
                        <h1>{subdomain}</h1>
                        <p>{email}</p>
                    </div>
                </header>
                <div>
                    <NavLink to="/settings/company" isInverted>
                        <Settings size={15} />
                        <span>Settings</span>
                    </NavLink>
                    {!isBeta && (
                        <div
                            className="kare-nav-link kare-nav-link--is-inverted"
                            onClick={() => {
                                window.location.href = `https://${
                                    this.props.subdomain
                                }.beta.${getDashboardDomain()}`;
                            }}
                        >
                            <MdNewReleases size={15} />
                            <span>Preview BETA</span>
                        </div>
                    )}
                    <div
                        className="kare-nav-link kare-nav-link--is-inverted"
                        onClick={this.handleClickLogout}
                    >
                        <ExitToApp size={15} />
                        <span>Logout</span>
                    </div>
                </div>
            </section>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(
                redux.locale.actionCreators.resetContentLocaleActionCreator(),
            );
            return auth.logout({redux, dispatch});
        },
    };
};

const mapStateToProps = (state) => {
    return {
        subdomain: redux.org.selectors.orgSubdomainSelector(state),
        widget: redux.widget.selectors.widgetSelector(state),
        email: redux.user.selectors.emailSelector(state),
        scopes: redux.user.selectors.scopesSelector(state),
        isBeta: JSON.parse(getCookie('beta')),
    };
};

export const AccountMenu = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AccountMenuComponent),
);
