import {addNotification} from 'reapop';

import {getResponseTransformer} from './transformers';
import {METHODS} from '../constants';
import {
    UPDATE_RESPONSE,
    CREATE_RESPONSE,
    GET_RESPONSE,
    BULK_UPDATE_RESPONSES,
    GET_PUBLISHED_RESPONSES,
    GET_DRAFT_RESPONSES,
    GET_ARCHIVED_RESPONSES,
    GET_PUBLISHED_CHATTER_RESPONSES,
    GET_IMPROVE_PAGE_RESPONSE,
    GET_OUTBOUND_NODES,
    GET_IMPROVE_PAGE_OUTBOUND_NODES,
    GET_RESPONSE_CLUSTER,
    GET_INBOUND_NODES,
} from './constants';
import Notification from '../../utils/notifications';
import {
    getAllPublishedChatterResponsesDispatcher,
    getAllPublishedResponsesDispatcher,
    getAllArchivedResponsesDispatcher,
    getAllDraftResponsesDispatcher,
} from '../knowledge-base/dispatchers';
import {NODE_TYPES, TYPE, NODE_STATUSES, STATUS} from '@app/constants';
import {improvePageResponseSelector} from './selectors';
import {get} from 'lodash';
import {responsesTable} from '../responses-table';
import {uuidv4} from '@app/utils/Common';
import {createImproveSelectedResponse} from '../improve/responses/dispatchers';

const {GET, POST, PATCH, PUT} = METHODS;

const getAllResponses = ({dispatch}) => {
    getAllPublishedResponsesDispatcher(dispatch)();
    getAllPublishedChatterResponsesDispatcher(dispatch)();
    getAllArchivedResponsesDispatcher(dispatch)();
    getAllDraftResponsesDispatcher(dispatch)();
};

/**
 * The redux-api configuration for the responses module.
 */
export const rest = {
    [GET_PUBLISHED_CHATTER_RESPONSES]: {
        url: `/v3.0/kbm/nodes?${STATUS}=${NODE_STATUSES.PUBLISHED}&${TYPE}=${NODE_TYPES.CHATTER}`,
        options: {
            method: GET,
        },
    },
    [GET_PUBLISHED_RESPONSES]: {
        url: `/v3.0/kbm/nodes?${STATUS}=${NODE_STATUSES.PUBLISHED}&${TYPE}=${NODE_TYPES.RESPONSE}`,
        options: {
            method: GET,
        },
    },

    [GET_ARCHIVED_RESPONSES]: {
        url: `/v3.0/kbm/nodes?${STATUS}=${NODE_STATUSES.ARCHIVED}&${TYPE}=${NODE_TYPES.RESPONSE}`,
        options: {
            method: GET,
        },
    },

    [GET_DRAFT_RESPONSES]: {
        url: `/v3.0/kbm/nodes?${STATUS}=${NODE_STATUSES.DRAFT}&${TYPE}=${NODE_TYPES.RESPONSE}`,
        options: {
            method: GET,
        },
    },

    /**
     * Get a single response.
     */
    [GET_RESPONSE]: {
        url: '/v3.0/kbm/nodes/:id',
        transformer: getResponseTransformer(uuidv4),
        options: {
            method: GET,
        },
        postfetch: [
            ({data, actions, dispatch}) => {
                dispatch(
                    actions[GET_OUTBOUND_NODES]({
                        id: data.id,
                    }),
                );
            },
        ],
    },
    [GET_OUTBOUND_NODES]: {
        url: `/v3.0/kbm/nodes/:id/edges/outbound`,
        options: {
            method: GET,
        },
    },
    [GET_INBOUND_NODES]: {
        url: `/v3.0/kbm/nodes/:id/edges/inbound`,
        options: {
            method: GET,
        },
    },

    [GET_IMPROVE_PAGE_RESPONSE]: {
        url: '/v3.0/kbm/nodes/:id',
        transformer: getResponseTransformer(uuidv4),
        options: {
            method: GET,
        },
        postfetch: [
            ({data, actions, dispatch}) => {
                createImproveSelectedResponse(dispatch)(data);
                dispatch(
                    actions[GET_IMPROVE_PAGE_OUTBOUND_NODES]({
                        id: data.id,
                    }),
                );
            },
        ],
    },
    [GET_IMPROVE_PAGE_OUTBOUND_NODES]: {
        url: `/v3.0/kbm/nodes/:id/edges/outbound`,
        options: {
            method: GET,
        },
    },
    /**
     * Create a single response.
     */
    [CREATE_RESPONSE]: {
        url: '/v3.0/kbm/nodes',
        options: {
            method: POST,
        },
        postfetch: [
            ({data, dispatch}) => {
                dispatch(addNotification(Notification.responseCreated(data)));
            },
            // ({ actions, dispatch }) => {
            // TODO(rupertrutland): We need to set this to the created response:
            // dispatch(actions[GET_IMPROVE_PAGE_RESPONSE].reset());
            // },
            getAllResponses,
        ],
    },
    /**
     * Update a response.
     */
    [UPDATE_RESPONSE]: {
        url: '/v3.0/kbm/nodes/:id',
        options: {
            method: PUT,
        },
        postfetch: [
            ({data, dispatch}) => {
                dispatch(addNotification(Notification.responseUpdated(data)));
            },
            // If we're on the improve page response viewer, we need to refetch that data.
            ({data, actions, dispatch, getState}) => {
                const state = getState();
                const improvePageResponse = improvePageResponseSelector(state);
                const improvePageResponseId = get(improvePageResponse, ['id']);

                const responseId = get(data, ['id']);
                const responseStatus = get(data, [STATUS]);

                if (responseId === improvePageResponseId) {
                    if (responseStatus === NODE_STATUSES.PUBLISHED) {
                        dispatch(
                            actions[GET_IMPROVE_PAGE_RESPONSE]({
                                id: responseId,
                            }),
                        );
                    } else {
                        dispatch(actions[GET_IMPROVE_PAGE_RESPONSE].reset());
                    }
                }
            },
            getAllResponses,
        ],
    },

    [BULK_UPDATE_RESPONSES]: {
        url: '/v3.0/kbm/nodes/bulk',
        options: {
            method: PATCH,
        },
        postfetch: [
            getAllResponses,
            ({dispatch}) => {
                dispatch(responsesTable.actionCreators.resetSelectedRows());
            },
        ],
    },
    [GET_RESPONSE_CLUSTER]: {
        url: `/v3.0/improve/clusters/:id`,
        options: {
            method: GET,
        },
    },
};
