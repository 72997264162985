import React from 'react';

// @ts-ignore
import './ResourceSelectionButton.scss';
import {MdClose, MdEdit} from 'react-icons/md';

interface ResourceSelectionButtonProps {
    label?: string;
    placeholder: string;
    placeholderIcon?: any;
    icon?: any;
    text: string;
    onClick: () => any;
    onClear?: () => any;
}

export const ResourceSelectionButton: React.FunctionComponent<
    ResourceSelectionButtonProps
> = (props) => {
    return (
        <div className="ResourceSelectionButton__container">
            {props.label && (
                <span className="ResourceSelectionButton__label">
                    {props.label?.toUpperCase()}
                </span>
            )}
            <div
                className="ResourceSelectionButton__buttonContainer"
                onClick={() => {
                    if (!props.text) {
                        props.onClick();
                    }
                }}
            >
                {!props.text && (
                    <React.Fragment>
                        <span className="ResourceSelectionButton__iconLeft">
                            {props.placeholderIcon && props.placeholderIcon}
                        </span>
                        <span
                            className="ResourceSelectionButton__placeholderText"
                            onClick={props.onClick}
                        >
                            {props.placeholder}
                        </span>
                        <MdEdit
                            className="ResourceSelectionButton__iconEdit"
                            size={20}
                            onClick={props.onClick}
                        />
                    </React.Fragment>
                )}
                {props.text && (
                    <React.Fragment>
                        <span className="ResourceSelectionButton__iconLeft">
                            {props.icon && props.icon}
                        </span>
                        <span onClick={props.onClick}>{props.text}</span>
                        <MdEdit
                            className="ResourceSelectionButton__iconEdit"
                            size={20}
                            onClick={props.onClick}
                        />
                        {props.onClear && (
                            <MdClose size={20} onClick={props.onClear} />
                        )}
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};
