import React, {useContext, useEffect, useState} from 'react';
import {AccountCircle, VerifiedUser} from '@material-ui/icons';
import {ResponsivePie} from '@nivo/pie';

import './CustomerTypeChart.scss';
import {StoreContext} from '@app/state';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {transformTrafficSourceDataToCustomerRadialChart} from '@app/utils/TimeSeriesDataTransform';
import {UsersResponse} from '@app/types/TimeSeries';
import {DATA_KEYS, getChartColor} from '@app/utils/DataRenderingUtils';
import {RadialChartsBottomLegend} from '@app/components/RadialChartsBottomLegend/RadialChartsBottomLegend';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {RadialContentLoader} from '@app/components/ContentLoaders/RadialContentLoader';

const newDefaultInitialState = {
    data: {} as UsersResponse,
    error: null,
    loading: true,
};

export const CustomerTypeChart: React.FC = () => {
    const [trafficSources, setCustomerType] = useState(newDefaultInitialState);

    const {timeSeries} = useContext(StoreContext);
    const {minDate, maxDate} = useContext(StoreContext).date;

    const showEmptyState = () => {
        if (!trafficSources.data || !trafficSources.data.counters) {
            return 'No data to show';
        }
        return null;
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted)
            setCustomerType({
                data: {} as UsersResponse,
                error: null,
                loading: true,
            });

        timeSeries
            .getAggregatedUsers(minDate, maxDate)
            .then((res) => {
                if (isMounted)
                    setCustomerType({
                        loading: false,
                        error: res.error,
                        data: res.data,
                    });
            })
            .catch(console.error);
        return () => {
            isMounted = false;
        };
    }, [minDate, maxDate]);

    return (
        <div className="CustomerTypeChart__container">
            <div className="CustomerTypeChart__content">
                <CardTitle title={'Customer types'} />
                <ErrorLoadingWrapper
                    height={'291px'}
                    error={trafficSources.error}
                    isLoading={trafficSources.loading}
                    CustomLoading={RadialContentLoader}
                    emptyState={showEmptyState()}
                    render={() => {
                        const transformedData =
                            transformTrafficSourceDataToCustomerRadialChart(
                                trafficSources.data,
                            );
                        const chartData = Object.keys(
                            transformedData.plotData,
                        ).map((k) => ({
                            id: k,
                            label: k,
                            value: transformedData.plotData[k],
                            color: getChartColor(k),
                        }));
                        return (
                            <>
                                <div className="CustomerTypeChart__chartContainer">
                                    <ResponsivePie
                                        data={chartData}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            bottom: 20,
                                            left: 20,
                                        }}
                                        sortByValue={false}
                                        innerRadius={0.7}
                                        padAngle={1}
                                        colors={(c: any) =>
                                            c.data.color as string
                                        }
                                        borderWidth={0}
                                        enableArcLabels={false}
                                        enableArcLinkLabels={false}
                                        isInteractive={false}
                                    />
                                </div>
                                <RadialChartsBottomLegend
                                    firstSource={{
                                        value: trafficSources.data.counters
                                            .registered,
                                        ratio: transformedData.legendData
                                            .registered,
                                        label: 'User',
                                        color: getChartColor(
                                            DATA_KEYS.REGISTERED,
                                        ),
                                        icon: VerifiedUser,
                                    }}
                                    secondSource={{
                                        value: trafficSources.data.counters
                                            .visitor,
                                        ratio: transformedData.legendData
                                            .visitor,
                                        label: 'Visitor',
                                        color: getChartColor(DATA_KEYS.VISITOR),
                                        icon: AccountCircle,
                                    }}
                                />
                            </>
                        );
                    }}
                />
            </div>
        </div>
    );
};
