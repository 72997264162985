import React, {Fragment, useContext, useState} from 'react';
import values from 'lodash/values';
import {useHistory} from 'react-router-dom';

import './AutomationsTable.scss';
import {StoreContext} from '@app/state';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {Card} from '@app/components/Card/Card';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {Table} from '@app/components/Table/Table';
import {calcRatio} from '@app/utils/Common';
import {DropDown} from '@app/components/inputs/dropdown/DropDown';

export interface AutomationsTableProps {}

interface AutomationsTableData {
    data: any;
    loading: boolean;
    error: string;
}

const initialDataState: AutomationsTableData = {
    data: {},
    loading: true,
    error: '',
};

export const AutomationsTable: React.FC<AutomationsTableProps> = (props) => {
    const {analytics} = useContext(StoreContext);
    const {minDate, maxDate} = useContext(StoreContext).date;
    const [data, setData] = useState<AutomationsTableData>(initialDataState);
    const history = useHistory();
    const [selectedDomain, setSelectedDomain] = useState({
        value: null,
        label: null,
    });

    const getAutomationsList = (entries: any) => {
        if (entries) {
            return values(entries).reduce((acc: any, curr) => {
                const currValues = values(curr);
                acc[currValues[0]['automation_id']] = {
                    automation_id: currValues[0]['automation_id'],
                    automation_title:
                        currValues[0]['automation_title'] ||
                        currValues[0]['title'],
                };
                return acc;
            }, {});
        }
        return {};
    };

    const getData = async () => {
        const response = await analytics.getAggregatedAutomationsByNodeId(
            minDate,
            maxDate,
        );
        if (response.data) {
            setData({
                loading: false,
                error: '',
                data: response.data,
            });
            if (response.data?.entries) {
                const keys = values(getAutomationsList(response.data?.entries));
                setSelectedDomain({
                    value: keys[0].automation_id,
                    label: keys[0].automation_title,
                });
            }
        } else {
            setData({
                loading: false,
                error: response.error || '',
                data: {},
            });
        }
    };

    React.useEffect(() => {
        getData();
    }, [minDate, maxDate]);

    const showEmptyState = () => {
        if (
            !data ||
            !data.data.entries ||
            values(data.data.entries).length === 0
        ) {
            return 'empty';
        }
        return '';
    };

    return (
        <Card>
            <ErrorLoadingWrapper
                height={'400px'}
                isLoading={data.loading}
                error={data.error}
                emptyState={showEmptyState()}
                render={() => {
                    const keys = values(getAutomationsList(data.data.entries));
                    return (
                        <Fragment>
                            <div className="AutomationsTable__titleContainer">
                                <CardTitle
                                    title="Entry points"
                                    className="AutomationsTable__title"
                                />
                                {data.data.entries &&
                                    values(data.data.entries).length > 0 &&
                                    !data.error && (
                                        <DropDown
                                            className="AutomationsTable__dropdown"
                                            value={{
                                                label: selectedDomain.label,
                                                value: selectedDomain.value,
                                            }}
                                            options={keys.map((k) => ({
                                                value: k.automation_id,
                                                label:
                                                    k.automation_title ||
                                                    k.title,
                                            }))}
                                            onChange={(v) => {
                                                setSelectedDomain(v);
                                            }}
                                        />
                                    )}
                            </div>
                            <Table
                                showPosition={false}
                                columns={[
                                    'title',
                                    'impressions',
                                    'clicks',
                                    'clickthrough',
                                ]}
                                rows={values(
                                    data.data.entries[selectedDomain.value],
                                ).map((e) => {
                                    return {
                                        values: [
                                            {value: e.title},
                                            {value: e.counters.impressions},
                                            {value: e.counters.clicks},
                                            {
                                                value: `${calcRatio(
                                                    e.counters.clicks,
                                                    e.counters.impressions,
                                                )} %`,
                                            },
                                        ],
                                        click: (nodeId) => {
                                            if (e.node_id?.length > 0) {
                                                history.push(
                                                    `/dialog/${e.node_id}`,
                                                );
                                            }
                                        },
                                    };
                                })}
                            />
                        </Fragment>
                    );
                }}
            />
        </Card>
    );
};
