import React from 'react';
import InsertChart from '@material-ui/icons/InsertChart';
import cx from 'classnames';
import './EmptyState.scss';

interface IEmptyStateProps {
    className?: string;
}

export const EmptyState: React.FunctionComponent<IEmptyStateProps> = (
    props,
) => {
    return (
        <div className={cx('EmptyState__container', props.className)}>
            <InsertChart />
            <h1>No activity</h1>
        </div>
    );
};
