import {METHODS} from '@app/redux/constants';
import {
    GET_MUTED_QUERIES,
    DELETE_MUTED_QUERIES,
} from '@app/redux/improve/queries/constants';
import {getAllMutedQueriesSelector} from '@app/redux/improve/queries/selectors';
import {appendMutedQueries} from '@app/redux/improve/queries/actions';

const {GET, DELETE} = METHODS;

export const rest = {
    [GET_MUTED_QUERIES]: {
        url: `/v3.1/improve/muted-queries`,
        options: {
            method: GET,
        },
        postfetch: [
            ({data, dispatch, getState}) => {
                const state = getState();
                const allMutedQueries = getAllMutedQueriesSelector(state);
                if (
                    !JSON.stringify(allMutedQueries).includes(
                        JSON.stringify(data?.queries),
                    )
                ) {
                    dispatch(appendMutedQueries(data?.queries));
                }
            },
        ],
    },
    [DELETE_MUTED_QUERIES]: {
        url: `/v3.1/improve/muted-queries`,
        options: {
            method: DELETE,
        },
    },
};
