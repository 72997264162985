import {SubmissionError} from 'redux-form';
import {addNotification} from 'reapop';
import {get} from 'lodash';

import Notification from '@app/utils/notifications';
import {redux} from '@app/redux';
import {DEFAULT_LOCALE_EU} from '@app/utils/locale/constants';
import {FIELDS, widgetDefaults} from '../widget-form/constants';
import {ZENDESK_IMPORT} from '@app/redux/integrations/constants';

const getOrgPayload = ({org, params}) => {
    const orgDefault = {
        available_supported_locales: [DEFAULT_LOCALE_EU],
    };

    return {
        ...orgDefault,
        ...{
            ...org,
            [FIELDS.PRIMARY_COLOR_HEX]:
                get(org, 'primary_color_hex') ||
                widgetDefaults[FIELDS.PRIMARY_COLOR_HEX],
            [FIELDS.FONT_FAMILY]:
                get(org, 'font_family_text') ||
                widgetDefaults[FIELDS.FONT_FAMILY],
            [FIELDS.LOGO_URL]:
                get(org, 'logo_url') || widgetDefaults[FIELDS.LOGO_URL],
        },
        ...params,
    };
};

export const updateOrg = (dispatch) => (params, org) => {
    return dispatch(
        redux.rest.actions[redux.org.constants.UPDATE_ORG](
            {},
            {
                body: JSON.stringify(
                    getOrgPayload({
                        org,
                        params,
                    }),
                ),
            },
        ),
    )
        .then(() => {
            dispatch(redux.rest.actions[redux.org.constants.ORG].get());
            return dispatch(addNotification(Notification.orgUpdated()));
        })
        .catch((error) => {
            // Special format for submission errors in redux-form:
            // @see: https://redux-form.com/7.4.2/docs/api/submissionerror.md/
            dispatch(redux.rest.actions[redux.org.constants.ORG].get());
            throw new SubmissionError({_error: error.message});
        });
};

export const updateZendeskToken = (dispatch) => (params) => {
    return dispatch(
        redux.rest.actions[ZENDESK_IMPORT]({}, {body: JSON.stringify(params)}),
    )
        .then(() => {
            dispatch(redux.rest.actions[redux.org.constants.ORG].get());
            return dispatch(addNotification(Notification.orgUpdated()));
        })
        .catch((error) => {
            // Special format for submission errors in redux-form:
            // @see: https://redux-form.com/7.4.2/docs/api/submissionerror.md/
            dispatch(addNotification(Notification.importIntegrationError()));
            throw new SubmissionError({_error: error.message});
        });
};
