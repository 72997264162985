import React, {useState} from 'react';
import cx from 'classnames';
import {useDropzone} from 'react-dropzone';
import Modal from 'react-modal';
import {toast} from 'react-toastify';
import {Form} from 'react-final-form';
import axios from 'axios';
import {Button} from '@app/common';
import {MODAL_BASE_CONFIG} from '@app/utils/modals';
import {DocumentMetadataEditor} from '@app/common/document-metadata-editor';
import {MdClose, MdLibraryBooks} from 'react-icons/md';
import {CheckCircle} from '@app/common/icons';
import {IoMdCloseCircle} from 'react-icons/io';

interface LibraryFiltersProps {
    addSource?: any;
    setShowUploadModal?: any;
    filters?: any[];
    active?: any;
    showUploadModal?: boolean;
    reloadData?: any;
}

type FileStatus = 'Success' | 'Error';

interface SubmitableFile {
    file: File;
    status: FileStatus;
}

export const LibraryFilters = (props: LibraryFiltersProps) => {
    const [isLoading, setIsloading] = useState(false);
    const [files, setFiles] = useState<SubmitableFile[]>(null);
    const [labels, setLabels] = useState<string[]>([]);
    const {filters, active, setShowUploadModal} = props;

    const supportedFileExtensions = ['.docx'];
    const isValidFileExtension = (filename: string) =>
        supportedFileExtensions.includes(
            filename.slice(filename.lastIndexOf('.')),
        );

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                ['.docx'],
            'application/msword': ['.doc'],
        },
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                setFiles(
                    acceptedFiles.map(
                        (f) =>
                            ({
                                file: f,
                                status: isValidFileExtension(f.name)
                                    ? 'Success'
                                    : 'Error',
                            } as SubmitableFile),
                    ),
                );
            }
        },
    });

    const submitFile = async (file: File, serializedLabels: string) => {
        const formData = new FormData();
        formData.append('content', file);
        formData.append('labels', serializedLabels);

        try {
            const response = await axios.post(
                `v3.0/ingestion/upload?status=published`,
                formData,
            );
            if (response && response.data && (response.data as any).id) {
                toast.success(
                    `Your file '${file.name}' has been uploaded successfully`,
                );
            }
        } catch (e) {
            toast.error(
                `There has been an error uploading file '${file.name}'`,
            );
        }
    };

    const onFormSubmit = async () => {
        setIsloading(true);
        const serializedLabels = JSON.stringify(labels);
        await Promise.all(
            files
                .filter((f) => f.status === 'Success')
                .map((f) => submitFile(f.file, serializedLabels)),
        );
        setIsloading(false);
        setFiles(null);
        props.setShowUploadModal(false);
        props.reloadData();
    };

    const renderDocIcon = (status: FileStatus) => {
        if (status === 'Success') {
            return (
                <span>
                    <CheckCircle
                        className="assets-uploader-document-icon-check"
                        size={16}
                    />
                </span>
            );
        }
        return (
            <span className="assets-uploader-document-icon-close">
                {status} <IoMdCloseCircle size={20} />
            </span>
        );
    };

    return (
        <div className="library-filters">
            <Modal
                isOpen={props.showUploadModal}
                style={{
                    ...MODAL_BASE_CONFIG,
                    content: {
                        ...MODAL_BASE_CONFIG.content,
                        width: '800px',
                        height: '520px',
                        border: '0',
                    },
                }}
            >
                <Form
                    onSubmit={onFormSubmit}
                    render={(renderprops) => {
                        return (
                            <section className="access-uploader-block-modal image-block-modal">
                                <h1>
                                    Upload documents{' '}
                                    <MdClose
                                        onClick={() => {
                                            setShowUploadModal(false);
                                        }}
                                        size={24}
                                    />
                                </h1>
                                <div className="assets-uploader">
                                    {!files ? (
                                        <div
                                            {...getRootProps()}
                                            className={cx(
                                                'assets-uploader__drag access-uploader-wrapper',
                                                {
                                                    dragging: isDragActive,
                                                },
                                            )}
                                        >
                                            <input {...getInputProps()} />
                                            <div className="assets-uploader__drag-add">
                                                <p>
                                                    Click here or drag and drop
                                                    to upload documents
                                                </p>
                                                <p className="assets-uploader__description">
                                                    Only .docx file format is
                                                    allowed
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <DocumentMetadataEditor
                                                showLabelEditIcon
                                                documentLabels={[]}
                                                onSaveDocumentLabels={setLabels}
                                            />
                                            <div className="access-uploader-list-wrapper">
                                                {files.map((f) => {
                                                    return (
                                                        <div
                                                            className="assets-uploader-document"
                                                            key={f.file.name}
                                                        >
                                                            <span
                                                                className={cx({
                                                                    ['assets-uploader-invalid-doc']:
                                                                        f.status ===
                                                                        'Error',
                                                                })}
                                                            >
                                                                <MdLibraryBooks
                                                                    size={20}
                                                                    className="assets-uploader-document-icon-lib"
                                                                />
                                                                {f.file.name}
                                                            </span>
                                                            {renderDocIcon(
                                                                f.status,
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </>
                                    )}
                                </div>

                                {files && (
                                    <div className="access-uploader-block-modal-buttons">
                                        <Button
                                            onClick={() => {
                                                setShowUploadModal(false);
                                                setFiles(null);
                                            }}
                                            outlined
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            showSpinner={isLoading}
                                            disabled={
                                                isLoading ||
                                                !files?.some(
                                                    (f) =>
                                                        f.status === 'Success',
                                                )
                                            }
                                            onClick={() => {
                                                renderprops.form.submit();
                                            }}
                                        >
                                            Add
                                        </Button>
                                    </div>
                                )}
                            </section>
                        );
                    }}
                />
            </Modal>

            <ul>
                {filters.map((filter) => {
                    return (
                        <li
                            key={filter.key}
                            className={cx({
                                active:
                                    filter.key === active ||
                                    (filter.key === 'all' && !active),
                            })}
                            onClick={() => {
                                if (
                                    filter.key !== active &&
                                    !(filter.key === 'all' && !active)
                                ) {
                                    filter.onClick();
                                }
                            }}
                        >
                            {filter.title}
                        </li>
                    );
                })}

                <li onClick={props.addSource}>Add new source +</li>
            </ul>
        </div>
    );
};
