import {get} from 'lodash';

export const dialogChoiceSelector = (state) =>
    get(state, ['dialogs', 'choice'], null);

export const dialogQuoteSelector = (state) => {
    /*
  node: "node_02da3594-aefd-11e9-b374-0a580a080381"
  text: null
   */
    return get(state, ['dialogs', 'quote'], null);
};
