export const GET_DOCUMENTS = 'getDocuments';
export const GET_PUBLISHED_DOCUMENTS = 'GET_PUBLISHED_DOCUMENTS';
export const GET_ARCHIVED_DOCUMENTS = 'GET_ARCHIVED_DOCUMENTS';
// TODO(rupertrutland): Move related document logic to the responses redux module.
export const GET_RELATED_DOCUMENTS = 'getRelatedDocuments';
export const GET_DOCUMENT = 'getDocument';
export const GET_BODY_DOCUMENT = 'getBodyDocument';
export const BULK_UPDATE_DOCUMENTS = 'BULK_UPDATE_DOCUMENTS';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const PATCH_DOCUMENT = 'PATCH_DOCUMENT';
/**
 * The document that is linked to a response.
 */
export const GET_ATTACHED_DOCUMENT_FIELD_DOCUMENT =
    'getAttachedDocumentFieldDocument';

export const SAVE_BODY_ACTION = 'saveBodyAction';
