import React from 'react';
import Icon from 'react-icon-base';

export const Zendesk = (props) => (
    <Icon viewBox="0 0 15 15" size={12} {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
        >
            <defs>
                <path
                    id="zendesk-a"
                    d="M6.92889262,4.50266838 L6.92889262,12.8680387 L1.42108547e-14,12.8680387 L6.92889262,4.50266838 Z M6.92889262,1.5 C6.92889262,3.41336086 5.37780718,4.96444631 3.46444631,4.96444631 C1.55108545,4.96444631 0,3.41336086 0,1.5 L6.92889262,1.5 Z M8.07036616,12.8680387 C8.07036616,10.9546779 9.62145161,9.40359243 11.5348125,9.40359243 C13.4481733,9.40359243 14.9992588,10.9546779 14.9992588,12.8680387 L8.07036616,12.8680387 Z M8.07036616,9.86537036 L8.07036616,1.5 L15,1.5 L8.07036616,9.86537036 Z"
                />
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id="zendesk-b" fill="#fff">
                    <use xlinkHref="#zendesk-a" />
                </mask>
                <use fill="#263238" xlinkHref="#zendesk-a" />
                <g fill="#263238" mask="url(#zendesk-b)">
                    <rect width="15" height="15" />
                </g>
            </g>
        </svg>
    </Icon>
);
