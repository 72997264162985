import {rest} from './rest';
import * as selectors from './selectors';
import * as actions from './actions';
import * as constants from './constants';
import {reducer} from './reducers';

/**
 * Public API for the conversations redux module.
 */
export const conversations = {
    rest,
    selectors,
    constants,
    actions,
    reducer,
};
