import React, {Fragment} from 'react';
import ReactTable from 'react-table';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import cx from 'classnames';
import {orderBy, get} from 'lodash';
import moment from 'moment';

import {
    Spinner,
    KareMultiselectCheckbox,
    ErrorBoundary,
    KareLabel,
} from '@app/common';
import {redux} from '@app/redux';
import {MdLaunch} from 'react-icons/md';

interface DocumentsTableProps {
    items: any[];
    disabled?: any[];
    isLoading?: boolean;
    history?: any;
    resetSelectedRows?: any;
    addSelectedRows?: any;
    removeSelectedRows?: any;
    selectedRows?: any[];
    original?: any;
}

/**
 * Table component for the documents.
 */
export class DocumentsTableComponent extends React.Component<DocumentsTableProps> {
    componentWillUnmount() {
        const {resetSelectedRows} = this.props;
        resetSelectedRows();
    }

    handleClickRow = (response) => {
        const encodedId = encodeURI(response.id);
        this.props.history.push(
            `/library/${encodedId}${this.props.history.location.search}`,
        );
    };

    handleClickViewOriginal = (url) => (event) => {
        event.stopPropagation();
        window.open(url, '_blank');
    };

    handleClickCheckbox = (id) => (event) => {
        event.stopPropagation();
        const {addSelectedRows, removeSelectedRows, selectedRows} = this.props;
        if (selectedRows.includes(id)) {
            return removeSelectedRows([id]);
        }
        if (event.shiftKey) {
            // TODO(rupertrutland): Manage shift clicking.
        }
        return addSelectedRows([id]);
    };

    render() {
        const {items, isLoading, selectedRows} = this.props;
        const orderedItems = orderBy(items, [(o) => o.title.toLowerCase()]);

        const className = cx(
            '-striped',
            'knowledge-base-table',
            'documents__documents-table',
            {
                'knowledge-base-table--has-selected-rows':
                    selectedRows.length > 0,
            },
        );
        const columns = [
            {
                accessor: 'id',
                sortable: false,
                resizable: false,
                width: 30,
                Header: () => {
                    return null;
                },
                Cell: (props) => {
                    const {
                        original: {id},
                    } = props;
                    return (
                        <KareMultiselectCheckbox
                            onClick={this.handleClickCheckbox(id)}
                            isChecked={selectedRows.includes(id)}
                        />
                    );
                },
            },
            {
                Header: 'Title',
                accessor: 'title',
                sortable: true,
                resizable: false,
                maxWidth: undefined,
                Cell: (props) => {
                    return (
                        <div className={'knowledge-base-table__title-label'}>
                            <span>
                                {props.value}{' '}
                                {props.original.archived && (
                                    <Fragment>- [Archived]</Fragment>
                                )}
                            </span>
                            {props.original.labels &&
                                props.original.labels.map((tag, i) => (
                                    <div
                                        className={
                                            'knowledge-base-table__label-container'
                                        }
                                        key={`${tag}-${i}`}
                                        onClick={(e) => {
                                            this.props.history.push(`/library`);
                                            this.props.history.push(
                                                `/library?labelFilter=${tag}`,
                                            );
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        <KareLabel
                                            text={tag}
                                            showRemoveButton={false}
                                        />
                                    </div>
                                ))}
                            <span
                                className="knowledge-base-table__dialog"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.props.history.push(
                                        `/dialog/${get(props, [
                                            'original',
                                            'id',
                                        ])}`,
                                    );
                                }}
                            >
                                <MdLaunch /> View in dialog map
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: 'Source',
                accessor: 'content.source',
                width: 140,
                resizable: false,
                Cell: (props) => {
                    const source = get(
                        props,
                        ['original', 'content', 'source'],
                        null,
                    );
                    return (
                        <div className={'knowledge-base-table__title-label'}>
                            {source
                                ? source.charAt(0).toUpperCase() +
                                  source.slice(1)
                                : 'N/A'}
                        </div>
                    );
                },
            },
            {
                Header: 'Last updated',
                accessor: 'updated_at',
                sortable: true,
                resizable: false,
                width: 140,
                Cell: (props) => {
                    const date = get(props, ['original', 'updated_at'], null);
                    return (
                        <div className={'knowledge-base-table__title-label'}>
                            {date
                                ? moment.unix(date).format('DD/MM/YYYY HH:mm')
                                : 'N/A'}
                        </div>
                    );
                },
            },
        ];

        return (
            <ErrorBoundary>
                <ReactTable
                    className={className}
                    data={orderedItems}
                    columns={columns}
                    loading={isLoading}
                    showPagination={true}
                    defaultPageSize={20}
                    loadingText={<Spinner />}
                    getTrProps={(state, rowInfo) => ({
                        onClick: () => {
                            this.handleClickRow(rowInfo.original);
                        },
                    })}
                />
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedRows: redux.documentsTable.selectors.selectedRowsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    addSelectedRows: (rows) =>
        dispatch(redux.documentsTable.actionCreators.addSelectedRows(rows)),
    removeSelectedRows: (rows) =>
        dispatch(redux.documentsTable.actionCreators.removeSelectedRows(rows)),
    resetSelectedRows: () =>
        dispatch(redux.documentsTable.actionCreators.resetSelectedRows()),
});

// @ts-ignore
export const DocumentsTableComp = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DocumentsTableComponent),
);
