import React from 'react';

interface PortalSidebarProps {
    children: any;
}

/**
 * Reusable sidebar for the portal
 */
export class PortalSidebar extends React.Component<PortalSidebarProps> {
    render() {
        return (
            <section className="knowledge-base__portal-sidebar">
                {this.props.children}
            </section>
        );
    }
}
