import {
    NodeModel,
    NodeModelGenerics,
    PortModelAlignment,
} from '@projectstorm/react-diagrams-core';
import {DefaultPortModel} from '../port/DefaultPortModel';
import {BasePositionModelOptions} from '@projectstorm/react-canvas-core';
import {AdvancedPortModel} from '../port/AdvancedPortModel';

export interface DefaultNodeModelOptions extends BasePositionModelOptions {
    resp: any;
    setRootNode: string;
    isResponse: boolean;
    editNode: any;
}

export interface DefaultNodeModelGenerics extends NodeModelGenerics {
    OPTIONS: DefaultNodeModelOptions;
}

export class DefaultNodeModel extends NodeModel<DefaultNodeModelGenerics> {
    protected portsIn: DefaultPortModel[];
    protected portsOut: DefaultPortModel[];

    constructor(
        isResponse: boolean,
        resp: any,
        setRootNode: any,
        editNode: any,
        nodeType?: string,
    ) {
        super({
            type: 'default',
            id: resp.id,
            isResponse,
            resp,
            setRootNode,
            editNode,
            //@ts-ignore
            nodeType,
        });
        this.portsOut = [];
        this.portsIn = [];
    }

    doClone(lookupTable: {}, clone: any): void {
        clone.portsIn = [];
        clone.portsOut = [];
        super.doClone(lookupTable, clone);
    }

    removePort(port: DefaultPortModel): void {
        super.removePort(port);
        if (port.getOptions().in) {
            this.portsIn.splice(this.portsIn.indexOf(port), 1);
        } else {
            this.portsOut.splice(this.portsOut.indexOf(port), 1);
        }
    }

    addPort<T extends DefaultPortModel>(port: T): T {
        super.addPort(port);
        if (port.getOptions().in) {
            if (this.portsIn.indexOf(port) === -1) {
                this.portsIn.push(port);
            }
        } else {
            if (this.portsOut.indexOf(port) === -1) {
                this.portsOut.push(port);
            }
        }
        return port;
    }

    addInPort(label: string, after = true): DefaultPortModel {
        // @ts-ignore
        const p = new DefaultPortModel({
            in: true,
            name: label,
            label: label,
            curvyness: 4,
            alignment: PortModelAlignment.TOP,
        });
        if (!after) {
            this.portsIn.splice(0, 0, p);
        }
        return this.addPort(p);
    }

    addOutPort(label: string, after = true): DefaultPortModel {
        // @ts-ignore
        const p = new DefaultPortModel({
            in: false,
            name: label,
            label: label,
            curvyness: 4,
            alignment: PortModelAlignment.BOTTOM,
        });
        if (!after) {
            this.portsOut.splice(0, 0, p);
        }
        return this.addPort(p);
    }

    getInPorts(): AdvancedPortModel[] {
        // @ts-ignore
        return this.portsIn;
    }

    getOutPorts(): AdvancedPortModel[] {
        // @ts-ignore
        return this.portsOut;
    }
}
