import React, {useEffect, useState} from 'react';
import createEngine, {DiagramModel} from '@projectstorm/react-diagrams';
import {useHistory, useRouteMatch} from 'react-router-dom';

import axios from 'axios';
import {SimplePortFactory} from './SimplePortFactory';
import './Dialog.scss';
import {CanvasWidget} from '@projectstorm/react-canvas-core';
import {DefaultPortModel} from './port/DefaultPortModel';
import {DefaultNodeFactory} from './node/DefaultNodeFactory';
import {DefaultNodeModel as CustomNodeModel} from './node/DefaultNodeModel';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';
import {AdvancedLinkFactory} from './link/AdvancedLinkFactory';
import bg from './dialogmap_bg_pattern.png';
import {MdAdd, MdChevronLeft, MdRemove, MdZoomOutMap} from 'react-icons/md';
import cx from 'classnames';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import {Button} from '@app/common';

export const Dialog = () => {
    const NODE_WIDTH = 260;
    const STARTING_POSITION_Y = 50;
    const VERTICAL_OFFSET = 280;
    const HORIZONTAL_OFFSET = 50;
    let CURRENT_POSITION_Y = STARTING_POSITION_Y;
    const match = useRouteMatch();
    const nodeIdFromProps = get(match, ['params', 'id'], null);
    const ZOOM_STEPPING = 2;

    const [fetched, setFetched] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(null);
    const [fetchedNodeList, setFetchedNodeList] = useState(false);
    const [rootNode, setRootNode] = useState(nodeIdFromProps);
    const [nodesList, setNodesList] = useState([]);
    const [engine, setEngine] = useState(null);
    const history = useHistory();

    const editNode = (node) => {
        history.push(`?compose=${node.id}`);
    };

    const handleClick = (node) => {
        history.push(`/dialog/${node}`);
        setFetched(false);
        setRootNode(node);
    };
    const getMaxHorizontalElements = (renderNodeList) => {
        const nodeList: any = Object.values(renderNodeList);
        let max = 0;
        nodeList.map((l) => {
            if (l.length > max) {
                max = l.length;
            }
        });
        return max;
    };

    const setPositionX = (
        maxHorizontalElements: number,
        numElements: number,
        currentIndex: number,
    ) => {
        return (
            ((maxHorizontalElements - numElements) / 2 + currentIndex) *
                NODE_WIDTH +
            HORIZONTAL_OFFSET
        );
    };

    const prepareAndRenderCanvas = (model: any) => {
        const eng = createEngine();
        eng.getPortFactories().registerFactory(
            //@ts-ignore
            new SimplePortFactory('block', () => new DefaultPortModel()),
        );
        eng.getNodeFactories().registerFactory(new DefaultNodeFactory());
        eng.getLinkFactories().registerFactory(new AdvancedLinkFactory());
        eng.setModel(model);
        setTimeout(() => {
            eng.zoomToFit();
            //@ts-ignore
            setZoomLevel(eng.model.getZoomLevel());
        }, 0);
        setEngine(eng);
    };

    const increaseYPosition = () => {
        CURRENT_POSITION_Y = CURRENT_POSITION_Y + VERTICAL_OFFSET;
    };

    const renderNode = (node, positionY, positionX, i, model) => {
        const nodeModel = new CustomNodeModel(
            true,
            node,
            handleClick,
            editNode,
        );
        nodeModel.addInPort('in');
        nodeModel.addOutPort('out');
        nodeModel.setPosition(positionX, positionY);

        model.addNode(nodeModel);
    };

    const renderNodeList = (nodeList, model) => {
        nodeList.map((node, i) => {
            renderNode(
                node.nodeModel.options.resp,
                node.positionY,
                node.positionX,
                i,
                model,
            );
        });
    };

    const calculatePositionXOffset = (total, index) => {
        if (total === 1) {
            return 0;
        }
        const mid = total / 2;
        return (index - mid) * NODE_WIDTH;
    };

    const renderLinks = (model, data) => {
        const outboundList: any = Object.values(data.outbound_edges);

        outboundList.map((outboundNodes) => {
            if (outboundNodes?.length > 0) {
                outboundNodes.map((n) => {
                    const sourceNode = model.getNode(n.from);
                    const targetNode = model.getNode(n.to);

                    if (sourceNode && targetNode) {
                        const choiceBlocks = get(
                            sourceNode,
                            ['options', 'resp', 'response', 'blocks'],
                            [],
                        ).filter((b) => b.type === 'choices');

                        if (
                            choiceBlocks?.length &&
                            get(data, ['nodes', n.to, 'type'], null) ===
                                'response'
                        ) {
                            let choiceBlock = null;
                            choiceBlock = choiceBlocks.find((c) => {
                                choiceBlock = find(
                                    c?.choices_block?.choices,
                                    (blockNode) =>
                                        blockNode.node ===
                                        targetNode?.options?.id,
                                );
                                return choiceBlock;
                            });
                            if (choiceBlock?.choices_block) {
                                const blocksLength = choiceBlocks.length;
                                const currentBlockIndex = findIndex(
                                    choiceBlocks,
                                    (c) => isEqual(c, choiceBlock),
                                );

                                const targetChoicesBlockChoices = get(
                                    choiceBlock,
                                    ['choices_block', 'choices'],
                                    [],
                                );
                                const title =
                                    sourceNode.id +
                                    '_' +
                                    targetChoicesBlockChoices
                                        .map((cb) => {
                                            return cb.node;
                                        })
                                        .join('_');
                                const text = get(
                                    choiceBlock,
                                    ['choices_block', 'text'],
                                    '',
                                );

                                const prevTitleNodeFound = model.getNode(title);

                                const titleNode = prevTitleNodeFound
                                    ? prevTitleNodeFound
                                    : new CustomNodeModel(
                                          false,
                                          {id: title, choiceText: text},
                                          null,
                                          null,
                                          'choice_title',
                                      );
                                const titleOutPort = prevTitleNodeFound
                                    ? prevTitleNodeFound.getOutPorts()[0]
                                    : titleNode.addOutPort(title + 'out');
                                const titleInPort = prevTitleNodeFound
                                    ? prevTitleNodeFound.getOutPorts()[0]
                                    : titleNode.addInPort('in');
                                const targetInputPort = targetNode.addInPort(
                                    title + 'in',
                                );
                                const sourceOutputPort = get(
                                    sourceNode.getOutPorts(),
                                    [0],
                                    {},
                                );
                                const titleTargetLink =
                                    titleOutPort.link(targetInputPort);

                                if (!prevTitleNodeFound) {
                                    const sourceTitleLink =
                                        sourceOutputPort.link(titleInPort);
                                    titleNode.setPosition(
                                        sourceNode.getPosition().x +
                                            calculatePositionXOffset(
                                                blocksLength,
                                                currentBlockIndex,
                                            ),
                                        sourceNode.getPosition().y + 150,
                                    );
                                    model.addLink(sourceTitleLink);
                                }

                                model.addNode(titleNode);
                                model.addLink(titleTargetLink);
                            }
                        } else {
                            const sourceOutputPort = get(
                                sourceNode.getOutPorts(),
                                [0],
                                {},
                            );
                            const targetInputPort = targetNode.addInPort('in');
                            const link = sourceOutputPort.link(targetInputPort);
                            model.addLink(link);
                        }
                    }
                });
            }
        });
    };

    const generateModel = (data: {
        nodes: any;
        inbound_edges: any;
        outbound_edges: any;
    }) => {
        const model = new DiagramModel();

        const root = new CustomNodeModel(
            true,
            {
                ...data.nodes[rootNode],
                outbounds: get(data.outbound_edges, rootNode, []).map(
                    (outbound) => {
                        return get(data, ['nodes', outbound.to], null);
                    },
                ),
                inbounds: get(data.inbound_edges, rootNode, []).map(
                    (inbound) => {
                        return get(data, ['nodes', inbound.from], null);
                    },
                ),
            },
            handleClick,
            editNode,
        );
        model.addNode(root);
        root.addOutPort('in');
        root.addInPort('out');
        let queue = [data.nodes[rootNode]];
        const rootStartingPosition = {
            positionY: STARTING_POSITION_Y,
            positionX: 300,
        };
        const toRender = [[{nodeModel: root, ...rootStartingPosition}]];
        let visited = [];
        let rendered = [{nodeModel: root, ...rootStartingPosition}];
        increaseYPosition();
        let current = null;
        let remainingNodesInLevel = [
            {nodeModel: root, ...rootStartingPosition},
        ];
        const nextLevelNodes = [];
        let currentNodePositionX = HORIZONTAL_OFFSET;
        let currentNodePositionIndex = 1;
        while (queue.length) {
            current = queue.shift();
            if (!visited.includes(current.id)) {
                const oEdges = data.outbound_edges[current.id];

                const targets = Object.keys(oEdges).map(
                    (e) => data.nodes[oEdges[e].to],
                );

                const nonRendered = targets
                    .filter((n) => {
                        return !rendered
                            .map((rend) =>
                                get(rend, ['nodeModel', 'options', 'id']),
                            )
                            .includes(n.id);
                    })
                    .map((n) => {
                        //@ts-ignore
                        const nodeModel = new CustomNodeModel(
                            true,
                            {
                                ...n,
                                outbounds: get(
                                    data.outbound_edges,
                                    n.id,
                                    [],
                                ).map((outbound) => {
                                    return get(
                                        data,
                                        ['nodes', outbound.to],
                                        null,
                                    );
                                }),
                                inbounds: get(data.inbound_edges, n.id, []).map(
                                    (inbound) => {
                                        return get(
                                            data,
                                            ['nodes', inbound.from],
                                            null,
                                        );
                                    },
                                ),
                            },
                            handleClick,
                        );
                        nodeModel.addOutPort('out');
                        nodeModel.addInPort('in');
                        const found = remainingNodesInLevel.find(
                            (remaining) =>
                                //@ts-ignore
                                remaining.nodeModel.options.id === current.id,
                        );
                        if (!found) {
                            increaseYPosition();
                            currentNodePositionX = 50;
                            currentNodePositionIndex = 1;
                            remainingNodesInLevel = nextLevelNodes.filter(
                                (nln) =>
                                    //@ts-ignore
                                    rendered.map((r) => r.id).includes(nln.id),
                            ) as any;
                        }
                        const nodetoRender = {
                            nodeModel: nodeModel,

                            positionY: CURRENT_POSITION_Y,
                            positionX:
                                currentNodePositionX * currentNodePositionIndex,
                        };
                        currentNodePositionIndex++;
                        return nodetoRender;
                    });
                if (nonRendered?.length > 0) {
                    toRender.push(nonRendered);
                    rendered = [...rendered, ...nonRendered];
                    nextLevelNodes.push(...nonRendered);

                    remainingNodesInLevel = remainingNodesInLevel.filter(
                        (remaining) =>
                            remaining.nodeModel.getID() !== current.id,
                    );
                }

                queue = [...queue, ...targets];
                visited = [...visited, current.id];
            }
        }

        const levelGroups = groupBy(flatten(toRender), 'positionY');
        const maxHorizontalElements = getMaxHorizontalElements(levelGroups);

        toRender.map((nodeList) => {
            const updatedNodeList = nodeList.map((n) => {
                const currentLevelElements = get(levelGroups, [n.positionY], 0);
                return {
                    ...n,
                    positionX: setPositionX(
                        maxHorizontalElements,
                        currentLevelElements.length,
                        findIndex(
                            currentLevelElements,
                            (e) =>
                                e.nodeModel.options.id ===
                                //@ts-ignore
                                n.nodeModel.options.id,
                        ),
                    ),
                };
            });
            renderNodeList(updatedNodeList, model);
        });
        renderLinks(model, data);

        prepareAndRenderCanvas(model);
    };

    useEffect(() => {
        if (rootNode && !fetched) {
            setFetched(true);
            CURRENT_POSITION_Y = STARTING_POSITION_Y;
            axios.get(`v3.1/kbm/nodes/${rootNode}/subgraph`).then((r) => {
                generateModel(r.data);
            });
        }
    }, [rootNode]);

    return (
        <div className="Dialog__container">
            {!rootNode &&
                (fetchedNodeList && nodesList.length === 0 ? (
                    <h1>There are no nodes available to explore</h1>
                ) : (
                    <div className="Dialog__empty">
                        <h1>Please select a root node to explore</h1>
                        {nodesList.map((n) => {
                            return (
                                <p
                                    onClick={() => {
                                        handleClick(n.id);
                                    }}
                                >
                                    {n.title}
                                </p>
                            );
                        })}
                    </div>
                ))}
            <div
                className="Dialog__canvas"
                style={{background: rootNode ? `url(${bg})` : 'none'}}
            >
                {engine && <CanvasWidget engine={engine as any} />}
            </div>
            <Button
                className="Dialog__backButton"
                onClick={() => {
                    history.goBack();
                }}
            >
                <MdChevronLeft size={16} />
                Back
            </Button>
            <div className="Dialog__zoomButtons">
                <div
                    className="Dialog__zoomIn"
                    onClick={() => {
                        const newZoom = zoomLevel + ZOOM_STEPPING;
                        engine.model.setZoomLevel(newZoom);
                        setZoomLevel(newZoom);
                    }}
                >
                    <MdAdd size={16} />
                </div>
                <div
                    className={cx('Dialog__zoomOut', {
                        ['disabled']: zoomLevel === 1,
                    })}
                    onClick={() => {
                        let newZoom = zoomLevel - ZOOM_STEPPING;
                        newZoom = newZoom < 1 ? 1 : newZoom;
                        engine.model.setZoomLevel(newZoom);
                        setZoomLevel(newZoom);
                    }}
                    //@ts-ignore
                    disabled={zoomLevel === 1}
                >
                    <MdRemove size={16} />
                </div>{' '}
                <div
                    className="Dialog__fitToScreen"
                    onClick={() => {
                        engine.zoomToFitNodes();
                        const newZoom = engine.model.getZoomLevel();
                        setZoomLevel(newZoom);
                    }}
                >
                    <MdZoomOutMap size={14} />
                </div>
            </div>
        </div>
    );
};
