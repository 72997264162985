import React, {Component} from 'react';
import {Response} from '../response';
import {redux} from '@app/redux';
import {isAutoResponse} from '@app/redux/improve/responses/selectors';
import {connect} from 'react-redux';
import {getCluster} from '@app/redux/improve/cards/selectors';

interface SelectedResponseProps {
    selectedResponse?: any;
    getDocument?: any;
    newResponse?: boolean;
    isAutoResponse?: boolean;
}

export class SelectedResponseComponent extends Component<SelectedResponseProps> {
    render() {
        return <Response selectedResponse={this.props.selectedResponse} />;
    }
}

const mapStateToProps = (state) => {
    return {
        cluster: getCluster(state),
        document: state.getDocument.data,
        isAutoResponse: isAutoResponse(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getResponse: (id) => {
            return dispatch(
                redux.rest.actions[redux.responses.constants.GET_RESPONSE]({
                    id: id,
                }),
            );
        },
        getDocument: (id) => {
            return dispatch(
                redux.rest.actions[redux.documents.constants.GET_DOCUMENT]({
                    id,
                }),
            );
        },
    };
};

export const SelectedResponse = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectedResponseComponent);
