import React from 'react';

interface TagsProps {
    tags?: any[];
}

export class Tags extends React.Component<TagsProps> {
    render() {
        let {tags} = this.props;
        tags = tags || [];
        return (
            <div className="c-tags">
                {tags.map((tag, index) => {
                    return (
                        // Temporary fix until we force tags to be unique.
                        <span className="c-tag" key={`${tag}${index}`}>
                            {tag}
                        </span>
                    );
                })}
            </div>
        );
    }
}
