import React from 'react';
import {Link} from 'react-router-dom';
import {Automation} from '@app/common/icons';

export const NoContent = () => {
    return (
        <section className="kare-empty-state">
            <Automation size={60} />
            <h1>Automations</h1>
            <p>
                Connect to your automations host to see the available
                automations to add in your responses.
            </p>
            <Link to="/settings/automations" className="c-btn">
                Connect your host of automations
            </Link>
        </section>
    );
};
