import React from 'react';

/**
 * NoRelatedDocumentResults Component.
 */
export class NoRelatedDocumentResults extends React.Component {
    render() {
        return (
            <section className="knowledge-base__no-related-document-results">
                <h1 className="knowledge-base__no-related-document-results-title">
                    No results found. Please, try a new search term.
                </h1>
            </section>
        );
    }
}
