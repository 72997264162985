import React, {useContext, useState} from 'react';
import {keys, values} from 'lodash';
import {MdChatBubble as Published, MdWarning} from 'react-icons/md';
import {useHistory} from 'react-router-dom';

import './BeaconsTable.scss';
import {StoreContext} from '@app/state';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {Card} from '@app/components/Card/Card';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {Table} from '@app/components/Table/Table';
import {calcRatio} from '@app/utils/Common';
import {DropDown} from '@app/components/inputs/dropdown/DropDown';

export interface BeaconsTableProps {}

interface BeaconsTableData {
    data: any;
    loading: boolean;
    error: string;
}

const initialDataState: BeaconsTableData = {
    data: {},
    loading: true,
    error: '',
};

export const BeaconsTable: React.FC<BeaconsTableProps> = (props) => {
    const {beaconsApi} = useContext(StoreContext);
    const {minDate, maxDate} = useContext(StoreContext).date;
    const [data, setData] = useState<BeaconsTableData>(initialDataState);
    const history = useHistory();

    const [selectedDomain, setSelectedDomain] = useState(null);
    const getData = async () => {
        const response = await beaconsApi.getBeaconsByUrlAndNode(
            minDate,
            maxDate,
        );
        if (response.data) {
            setData({
                loading: false,
                error: '',
                data: response.data,
            });
            setSelectedDomain(keys(response.data.entries)[0]);
        } else {
            setData({
                loading: false,
                error: response.error || '',
                data: {},
            });
        }
    };

    React.useEffect(() => {
        getData();
    }, [minDate, maxDate]);

    const showEmptyState = () => {
        if (
            !data ||
            !data.data.entries ||
            values(data.data.entries).length === 0
        ) {
            return 'empty';
        }
        return '';
    };
    let rowsForTable = [];
    let flattedBeacons = [];
    if (showEmptyState() === '') {
        flattedBeacons = values(data.data.entries[selectedDomain]).flatMap(
            (entry) => {
                return values(entry);
            },
        );
        rowsForTable = flattedBeacons.map((e) => {
            return {
                values: [
                    {
                        value: e.title,
                        label: (
                            <span className={'BeaconsTable__title'}>
                                {e.status === 'published' && (
                                    <Published className="BeaconsTable__svgResponse" />
                                )}
                                {e.status !== 'published' && <MdWarning />}
                                {e.title}
                            </span>
                        ),
                    },
                    {
                        value: e.url,
                        label: (
                            <span
                                title={e.url}
                                className="BeaconsTable__urlCell"
                            >
                                {e.url}
                            </span>
                        ),
                    },
                    {value: e.counters.impressions},
                    {value: e.counters.clicks},
                    {
                        value: e.counters.clicks / e.counters.impressions,
                        label: `${calcRatio(
                            e.counters.clicks,
                            e.counters.impressions,
                        )}%`,
                    },
                ],
                click: () => {
                    if (e.node_id?.length > 0) {
                        history.push(`/dialog/${e.node_id}`);
                    }
                },
            };
        });
    }

    return (
        <Card>
            <div className="BeaconsTable__titleContainer">
                <CardTitle
                    title="Tooltips on"
                    className="BeaconsTable__cardTitle"
                />
                {data.data.entries &&
                    values(data.data.entries).length > 0 &&
                    !data.error && (
                        <DropDown
                            className="BeaconsTable__dropdown"
                            value={{
                                label: selectedDomain,
                                value: selectedDomain,
                            }}
                            options={keys(data.data.entries).map((k) => ({
                                label: k,
                                value: k,
                            }))}
                            onChange={(v) => {
                                setSelectedDomain(v.label);
                            }}
                        />
                    )}
            </div>
            <ErrorLoadingWrapper
                height={'400px'}
                isLoading={data.loading}
                error={data.error}
                emptyState={showEmptyState()}
                render={() => {
                    return (
                        <Table
                            showPosition={false}
                            columns={[
                                'Response title',
                                'URL',
                                'Impressions',
                                'Clicks',
                                'Clickthrough',
                            ]}
                            rowStyle={(position: number) => {
                                if (
                                    flattedBeacons[position]['status'] !==
                                    'published'
                                ) {
                                    return 'BeaconsTable__brokenNode';
                                } else return '';
                            }}
                            isCellAMetric={(position: number) => position > 1}
                            rows={rowsForTable}
                        />
                    );
                }}
            />
        </Card>
    );
};
