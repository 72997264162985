import React from 'react';
import {Field} from 'redux-form';
import {reduxForm} from 'redux-form';

import {RESET_PASSWORD_FORM} from './constants';
import {KareTextInput} from '@app/common';
import {passwordStrengthValidation} from '@app/common/password-validation';
import {Form, StyledButton} from './resetPasswordForm.styles';

interface ResetPasswordFormComponentProps {
    handleSubmit: any;
    pristine?: boolean;
}

const isRequired = (value) => (value ? undefined : 'Required');

export const ResetPasswordFormComponent = ({
    handleSubmit,
    pristine,
}: ResetPasswordFormComponentProps) => {
    return (
        <Form onSubmit={handleSubmit}>
            <Field
                component={KareTextInput}
                name="password"
                label="New password"
                validate={[isRequired, passwordStrengthValidation]}
                type="password"
            />
            <section className="submit-button-container">
                <StyledButton disabled={pristine} onClick={handleSubmit}>
                    Submit
                </StyledButton>
            </section>
        </Form>
    );
};

export const ResetPasswordForm = reduxForm({
    form: RESET_PASSWORD_FORM,
})(ResetPasswordFormComponent);
