import {ACTIONS, DYNAMIC_KEYS} from './constants';
import {PAYLOAD, ENTRIES, ERROR} from '../constants';

/**
 * Add a search query to the store.
 * Used for searching through knowledgeBase.
 */
export const searchKnowledgeBase = (query) => {
    return {
        type: ACTIONS.SEARCH_TEXT,
        query,
    };
};

const getSetEntriesAction =
    (key) =>
    (entries = []) => {
        return {
            type: `knowledgeBase/SET_${key}`,
            [PAYLOAD]: {
                [ENTRIES]: entries,
            },
        };
    };

const getSetErrorAction = (key) => (error) => {
    return {
        type: `knowledgeBase/SET_${key}_ERROR`,
        [PAYLOAD]: {
            [ERROR]: error,
        },
    };
};

const getUnsetErrorAction = (key) => () => {
    return {
        type: `knowledgeBase/UNSET_${key}_ERROR`,
    };
};

const getLoadingAction = (key) => () => {
    return {
        type: `knowledgeBase/SET_${key}_LOADING`,
    };
};

const getUnsetLoadingAction = (key) => () => {
    return {
        type: `knowledgeBase/UNSET_${key}_LOADING`,
    };
};

// All content
export const setAllContentError = getSetErrorAction(DYNAMIC_KEYS.ALL_CONTENT);
export const unsetAllContentError = getUnsetErrorAction(
    DYNAMIC_KEYS.ALL_CONTENT,
);
export const setAllContentLoading = getLoadingAction(DYNAMIC_KEYS.ALL_CONTENT);
export const unsetAllContentLoading = getUnsetLoadingAction(
    DYNAMIC_KEYS.ALL_CONTENT,
);

// Published chatter responses
export const setPublishedChatterResponses = getSetEntriesAction(
    DYNAMIC_KEYS.PUBLISHED_CHATTER_RESPONSES,
);
export const setPublishedChatterResponsesError = getSetErrorAction(
    DYNAMIC_KEYS.PUBLISHED_CHATTER_RESPONSES,
);
export const unsetPublishedChatterResponsesError = getUnsetErrorAction(
    DYNAMIC_KEYS.PUBLISHED_CHATTER_RESPONSES,
);
export const setPublishedChatterResponsesLoading = getLoadingAction(
    DYNAMIC_KEYS.PUBLISHED_CHATTER_RESPONSES,
);
export const unsetPublishedChatterResponsesLoading = getUnsetLoadingAction(
    DYNAMIC_KEYS.PUBLISHED_CHATTER_RESPONSES,
);
// Published responses
export const setPublishedResponses = getSetEntriesAction(
    DYNAMIC_KEYS.PUBLISHED_RESPONSES,
);
export const setPublishedResponsesError = getSetErrorAction(
    DYNAMIC_KEYS.PUBLISHED_RESPONSES,
);
export const unsetPublishedResponsesError = getUnsetErrorAction(
    DYNAMIC_KEYS.PUBLISHED_RESPONSES,
);
export const setPublishedResponsesLoading = getLoadingAction(
    DYNAMIC_KEYS.PUBLISHED_RESPONSES,
);
export const unsetPublishedResponsesLoading = getUnsetLoadingAction(
    DYNAMIC_KEYS.PUBLISHED_RESPONSES,
);

// Archived responses
export const setArchivedResponses = getSetEntriesAction(
    DYNAMIC_KEYS.ARCHIVED_RESPONSES,
);
export const setArchivedResponsesError = getSetErrorAction(
    DYNAMIC_KEYS.ARCHIVED_RESPONSES,
);
export const unsetArchivedResponsesError = getUnsetErrorAction(
    DYNAMIC_KEYS.ARCHIVED_RESPONSES,
);
export const setArchivedResponsesLoading = getLoadingAction(
    DYNAMIC_KEYS.ARCHIVED_RESPONSES,
);
export const unsetArchivedResponsesLoading = getUnsetLoadingAction(
    DYNAMIC_KEYS.ARCHIVED_RESPONSES,
);

// Draft responses
export const setDraftResponses = getSetEntriesAction(
    DYNAMIC_KEYS.DRAFT_RESPONSES,
);
export const setDraftResponsesError = getSetErrorAction(
    DYNAMIC_KEYS.DRAFT_RESPONSES,
);
export const unsetDraftResponsesError = getUnsetErrorAction(
    DYNAMIC_KEYS.DRAFT_RESPONSES,
);
export const setDraftResponsesLoading = getLoadingAction(
    DYNAMIC_KEYS.DRAFT_RESPONSES,
);
export const unsetDraftResponsesLoading = getUnsetLoadingAction(
    DYNAMIC_KEYS.DRAFT_RESPONSES,
);

// Published documents
export const setPublishedDocuments = getSetEntriesAction(
    DYNAMIC_KEYS.PUBLISHED_DOCUMENTS,
);
export const setPublishedDocumentsError = getSetErrorAction(
    DYNAMIC_KEYS.PUBLISHED_DOCUMENTS,
);
export const unsetPublishedDocumentsError = getUnsetErrorAction(
    DYNAMIC_KEYS.PUBLISHED_DOCUMENTS,
);
export const setPublishedDocumentsLoading = getLoadingAction(
    DYNAMIC_KEYS.PUBLISHED_DOCUMENTS,
);
export const unsetPublishedDocumentsLoading = getUnsetLoadingAction(
    DYNAMIC_KEYS.PUBLISHED_DOCUMENTS,
);

// ARCHIVED documents
export const setArchivedDocuments = getSetEntriesAction(
    DYNAMIC_KEYS.ARCHIVED_DOCUMENTS,
);
export const setArchivedDocumentsError = getSetErrorAction(
    DYNAMIC_KEYS.ARCHIVED_DOCUMENTS,
);
export const unsetArchivedDocumentsError = getUnsetErrorAction(
    DYNAMIC_KEYS.ARCHIVED_DOCUMENTS,
);
export const setArchivedDocumentsLoading = getLoadingAction(
    DYNAMIC_KEYS.ARCHIVED_DOCUMENTS,
);
export const unsetArchivedDocumentsLoading = getUnsetLoadingAction(
    DYNAMIC_KEYS.ARCHIVED_DOCUMENTS,
);

// Related documents
export const setRelatedDocuments = getSetEntriesAction(
    DYNAMIC_KEYS.RELATED_DOCUMENTS,
);
export const setRelatedDocumentsError = getSetErrorAction(
    DYNAMIC_KEYS.RELATED_DOCUMENTS,
);
export const unsetRelatedDocumentsError = getUnsetErrorAction(
    DYNAMIC_KEYS.RELATED_DOCUMENTS,
);
export const setRelatedDocumentsLoading = getLoadingAction(
    DYNAMIC_KEYS.RELATED_DOCUMENTS,
);
export const unsetRelatedDocumentsLoading = getUnsetLoadingAction(
    DYNAMIC_KEYS.RELATED_DOCUMENTS,
);

// Related responses
export const setRelatedResponses = getSetEntriesAction(
    DYNAMIC_KEYS.RELATED_RESPONSES,
);
export const setRelatedResponsesError = getSetErrorAction(
    DYNAMIC_KEYS.RELATED_RESPONSES,
);
export const unsetRelatedResponsesError = getUnsetErrorAction(
    DYNAMIC_KEYS.RELATED_RESPONSES,
);
export const setRelatedResponsesLoading = getLoadingAction(
    DYNAMIC_KEYS.RELATED_RESPONSES,
);
export const unsetRelatedResponsesLoading = getUnsetLoadingAction(
    DYNAMIC_KEYS.RELATED_RESPONSES,
);

// Inbound nodes
export const setInboundNodes = getSetEntriesAction(DYNAMIC_KEYS.INBOUND_NODES);
export const setInboundNodesError = getSetErrorAction(
    DYNAMIC_KEYS.INBOUND_NODES,
);
export const unsetInboundNodesError = getUnsetErrorAction(
    DYNAMIC_KEYS.INBOUND_NODES,
);
export const setInboundNodesLoading = getLoadingAction(
    DYNAMIC_KEYS.INBOUND_NODES,
);
export const unsetInboundNodesLoading = getUnsetLoadingAction(
    DYNAMIC_KEYS.INBOUND_NODES,
);
