import {UsersResponse} from '@app/types/TimeSeries';
import {DATA_KEYS, getChartColor} from './DataRenderingUtils';
import {TrafficSourcesResponse} from '@app/types/TimeSeries';
import {calcRatio} from './Common';

export const transformTrafficSourceDataToSunburst = (
    data: TrafficSourcesResponse,
) => {
    if (!data || !data.counters) {
        return;
    }
    const desktopPlatforms = Object.keys(data.counters)
        .filter((c) => c.includes('desktop.'))
        .map((c) => {
            const platformName = c.replace(/desktop./g, '');
            return {
                name: platformName,
                color: getChartColor(platformName),
                loc: data.counters[c],
            };
        });

    const desktop = {
        name: 'Desktop',
        color: getChartColor(DATA_KEYS.WINDOWS),
        children: desktopPlatforms,
    };

    const mobilePlatforms = Object.keys(data.counters)
        .filter((c) => c.includes('mobile.'))
        .map((c) => {
            const platformName = c.replace(/mobile./g, '');
            return {
                name: platformName,
                color: getChartColor(platformName),
                loc: data.counters[c],
            };
        });

    const mobile = {
        name: 'Mobile',
        color: getChartColor(DATA_KEYS.ANDROID),
        children: mobilePlatforms,
    };

    return {
        name: 'traffic',
        color: 'yellow',
        children: [
            desktop,
            mobile,
            {name: 'Unknown', loc: data.counters.unknown},
        ],
    };
};

export const transformTrafficSourceDataToCustomerRadialChart = (
    data: UsersResponse,
) => {
    const {counters} = data;

    const registered = counters.registered || 0;
    const visitor = counters.visitor || 0;
    const total = registered + visitor;
    const registeredRatio = calcRatio(registered, total);
    const visitorRatio = calcRatio(visitor, total);

    return {
        plotData:
            total > 0
                ? {
                      registered,
                      visitor,
                  }
                : {nodata: 100},
        legendData: {registered: registeredRatio, visitor: visitorRatio},
    };
};
