import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {Button, KareTextInput} from '@app/common';

interface ApplicationFormProps {
    createApplication?: any;
    handleSubmit?: any;
    location: any;
}

export class ApplicationFormComponent extends React.Component<ApplicationFormProps> {
    private applicationForm: HTMLFormElement;
    render() {
        const {handleSubmit} = this.props;
        return (
            <form
                ref={(el) => {
                    this.applicationForm = el;
                }}
                onSubmit={handleSubmit}
            >
                <Field
                    name="name"
                    label="Application Name"
                    component={KareTextInput}
                />
                <section className="submit-button-container">
                    <Button>Create Application</Button>
                </section>
            </form>
        );
    }
}

export const ApplicationForm = reduxForm({
    form: 'createApplicationForm',
    enableReinitialize: true,
})(ApplicationFormComponent);
