import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {redux} from '@app/redux';
import {RelatedDocumentDetail} from './related-document-detail';
import {RelatedDocuments} from './related-documents';

interface DocumentsSidebarProps {
    relatedDocumentsLink?: string;
    editorLink?: string;
    getAllRelatedDocuments?: any;
    location?: any;
    documents?: any[];
    areDocumentsFetching?: boolean;
    closeDocumentsSidebar?: any;
}

/**
 * Main documents component.
 * TODO(rupertrutland): Make the search occur only on hitting enter or pressing a button
 * and rearrange the level of the state.
 */
export class DocumentsSidebarComponent extends React.Component<DocumentsSidebarProps> {
    state = {
        query: '',
        documentId: null,
    };

    /**
     * Set up the pagination and get the first set of documents.
     */
    componentDidMount() {
        this.props.getAllRelatedDocuments();
    }

    /**
     * Set the query when we search so that we can use it later for the infinite scroll.
     */
    search = (query) => {
        this.setState({query});
    };

    closeDetailView = () => {
        this.setState({
            documentId: null,
        });
    };

    selectDocument = (id) => {
        this.setState({
            documentId: id,
        });
    };

    /**
     * Return the documents container.
     */
    render() {
        const {areDocumentsFetching, documents} = this.props;
        const {query} = this.state;
        const visibleDocuments = query
            ? documents.filter((document) =>
                  document.title.toLowerCase().includes(query.toLowerCase()),
              )
            : documents;

        if (this.state.documentId) {
            return (
                <RelatedDocumentDetail
                    documentId={this.state.documentId}
                    onClickClose={this.closeDetailView}
                    closeDocumentsSidebar={this.props.closeDocumentsSidebar}
                />
            );
        }
        return (
            <RelatedDocuments
                selectDocument={this.selectDocument}
                query={query}
                documents={visibleDocuments}
                areDocumentsFetching={areDocumentsFetching}
                search={this.search}
                closeDocumentsSidebar={this.props.closeDocumentsSidebar}
            />
        );
    }
}

/**
 * Map redux store state to props for the component.
 * @param {Object} state
 * @param {Object} props
 */
const mapStateToProps = (state) => {
    return {
        documents:
            redux.knowledgeBase.selectors.relatedDocumentsSelector(state),
        areDocumentsFetching:
            redux.knowledgeBase.selectors.relatedDocumentsLoadingSelector(
                state,
            ),
    };
};

/**
 * Map action dispatching to component props.
 * Search responses, get all responses and delete a single response.
 * @param {Function} dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getAllRelatedDocuments:
            redux.knowledgeBase.dispatchers.getAllRelatedDocumentsDispatcher(
                dispatch,
            ),
    };
};

export const DocumentsSidebar = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DocumentsSidebarComponent),
);
