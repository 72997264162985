import React from 'react';

export class QueriesInputError extends React.Component {
    render() {
        return (
            <div className="queries-input-error">
                Please enter at least one query.
            </div>
        );
    }
}
