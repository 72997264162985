import React from 'react';

import {ChatBubbleCheck} from '@app/common/icons';

export class NoMoreCards extends React.Component {
    render() {
        return (
            <div className="kare-empty-state kare-empty-state--icon-primary">
                <ChatBubbleCheck size={120} />
                <h1>
                    You are all caught up with your customers common questions.
                </h1>
                <p>
                    Come back again later for suggestions to further improve
                    your knowledge base. Checkout the Activity page for real
                    time view of your customer interactions.
                </p>
            </div>
        );
    }
}
