import React from 'react';

import {StaticMessages} from './StaticMessages';

import {H2, P} from './AudienceDefault.styles';

export interface AudienceDefaultProps {
    insideModal?: boolean;
    selectedCampaign?: any;
}

export const AudienceDefault: React.FC<AudienceDefaultProps> = ({
    insideModal,
    selectedCampaign,
}) => {
    return (
        <div>
            {!insideModal && (
                <>
                    <H2>Default</H2>
                    <P>
                        Default messages are designed to support the flow of the
                        conversation. They will be used in certain conditions
                        (like greetings, user feedback, etc), but not when user
                        asks a specific question. Customise your default
                        messages to establish your company's tone of voice.
                    </P>
                </>
            )}
            <StaticMessages selectedCampaign={selectedCampaign} />
        </div>
    );
};
