import React, {FunctionComponent, useState} from 'react';
import cx from 'classnames';
import './Expand.scss';
import {Knowledge} from './Knowledge/Knowledge';
import {Missing} from './Missing/Missing';
import {Muted} from './Muted/Muted';
import {SearchBar} from '../../common';

interface IExpandProps {
    history: any;
}

export const Expand: FunctionComponent<IExpandProps> = ({history}) => {
    const [value, setValue] = useState('');

    const getActiveTabBasedOnPathName = (pathname: string) => {
        switch (pathname) {
            case '/expand/knowledge':
                return 'knowledge';
            case '/expand/missing':
                return 'missing';
            case '/expand/muted':
                return 'muted';
            default:
                return 'knowledge';
        }
    };

    const renderContentByTab = (tab: string) => {
        switch (tab) {
            case 'knowledge':
                return <Knowledge history={history} />;
            case 'missing':
                return <Missing history={history} />;
            case 'muted':
                return <Muted history={history} filter={value} />;
            default:
                return <Knowledge history={history} />;
        }
    };

    const changeActiveTab = (active: string) => {
        setValue('');
        history.push(`/expand/${active}`);
    };

    return (
        <div className="expand__container">
            <div className="expand__titleContainer">
                <h1 className="expand__title">Expand</h1>
                {getActiveTabBasedOnPathName(history.location.pathname) ===
                    'muted' && (
                    <div>
                        <SearchBar
                            name={'muted-queries-search'}
                            onChange={(event) => {
                                setValue(event.target.value);
                            }}
                            value={value}
                            hideCross={true}
                            filterButtonHandler={() => {}}
                            placeholder={'Search in your muted queries'}
                            filterCTA={''}
                        />
                    </div>
                )}
            </div>

            <ul className="expand__expandTabs">
                <li
                    key="knowledge"
                    className={cx({
                        active:
                            getActiveTabBasedOnPathName(
                                history.location.pathname,
                            ) === 'knowledge',
                    })}
                    onClick={() => {
                        changeActiveTab('knowledge');
                    }}
                >
                    Expand Knowledge
                </li>
                <li
                    key="missing"
                    className={cx({
                        active:
                            getActiveTabBasedOnPathName(
                                history.location.pathname,
                            ) === 'missing',
                    })}
                    onClick={() => {
                        changeActiveTab('missing');
                    }}
                >
                    Missing Knowledge
                </li>
                <li
                    key="muted"
                    className={cx({
                        active:
                            getActiveTabBasedOnPathName(
                                history.location.pathname,
                            ) === 'muted',
                    })}
                    onClick={() => {
                        changeActiveTab('muted');
                    }}
                >
                    Muted queries
                </li>
            </ul>
            <div className="expand__content">
                <>
                    {renderContentByTab(
                        getActiveTabBasedOnPathName(history.location.pathname),
                    )}
                </>
            </div>
        </div>
    );
};
