import {LibraryResponse} from '@app/types/Library';
import {calcRatio} from './Common';

export const transformLibraryDataToRadialChart = (data: LibraryResponse) => {
    const content = data.CONTENT || 0;
    const chattering = data.CHATTERING || 0;
    const response = data.RESPONSE || 0;
    const total = content + chattering + response;

    const libraryRatio = calcRatio(data.CONTENT, total) || 0;
    const responsesRatio = calcRatio(data.RESPONSE, total) || 0;
    const chatteringRatio = calcRatio(data.CHATTERING, total) || 0;

    return {
        plotData: {
            library: libraryRatio,
            responses: responsesRatio,
            chattering: chatteringRatio,
        },
        legendData: {
            library: content,
            responses: response,
            chattering: chattering,
        },
    };
};
