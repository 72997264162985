import React from 'react';
import {ResponsivePie} from '@nivo/pie';

import './ExitByKind.scss';
import {Card} from '@app/components/Card/Card';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {ChartLegend} from '@app/components/ChartLegend/ChartLegend';
import {RadialContentLoader} from '@app/components/ContentLoaders/RadialContentLoader';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {Call} from '@app/state';
import {ExitPageMetric} from '@app/state/ExitPage';
import {
    calcRatio,
    COLOR_DATAVIZ_GREEN,
    COLOR_DATAVIZ_ORANGE,
    COLOR_DATAVIZ_BLUE,
} from '@app/utils/Common';

export interface ExitByKindProps {
    metrics: Call<ExitPageMetric | null>;
}

export const ExitByKind: React.FC<ExitByKindProps> = (props) => {
    const getEmptyState = () => {
        if (props.metrics.loading) {
            return null;
        }

        if (!props.metrics.data || !props.metrics.data.counters) {
            return 'No data';
        }

        return null;
    };
    return (
        <Card>
            <CardTitle title="Exit by kind" />
            <ErrorLoadingWrapper
                height={'200px'}
                error={props.metrics.error ? props.metrics.error : ''}
                isLoading={props.metrics.loading}
                CustomLoading={RadialContentLoader}
                emptyState={getEmptyState()}
                render={() => {
                    return (
                        <div className="ExitByKind__container">
                            <div className="ExitByKind__chartContainer">
                                <ResponsivePie
                                    data={[
                                        {
                                            id: 'missings',
                                            label: 'After a missing answer',
                                            value:
                                                props.metrics.data?.counters
                                                    ?.missings || 0,
                                            color: COLOR_DATAVIZ_BLUE,
                                        },
                                        {
                                            id: 'disambiguations',
                                            label: 'After a disambiguation',
                                            value:
                                                props.metrics.data?.counters
                                                    ?.disambiguations || 0,
                                            color: COLOR_DATAVIZ_ORANGE,
                                        },
                                        {
                                            id: 'answers',
                                            label: 'After an answer',
                                            value:
                                                props.metrics.data?.counters
                                                    ?.answers || 0,
                                            color: COLOR_DATAVIZ_GREEN,
                                        },
                                    ]}
                                    margin={{
                                        top: 20,
                                        right: 20,
                                        bottom: 20,
                                        left: 20,
                                    }}
                                    sortByValue={false}
                                    innerRadius={0.7}
                                    padAngle={1}
                                    colors={(c: any) => c.data.color as string}
                                    borderWidth={0}
                                    enableArcLinkLabels={false}
                                    enableArcLabels={false}
                                    isInteractive={false}
                                />
                            </div>
                            <ChartLegend
                                items={[
                                    {
                                        color: COLOR_DATAVIZ_BLUE,
                                        title: 'After a missing answer',
                                        value:
                                            props.metrics.data?.counters
                                                ?.missings || 0,
                                        percentage: calcRatio(
                                            props.metrics.data?.counters
                                                ?.missings || 0,
                                            props.metrics.data?.counters
                                                ?.conversations || 0,
                                        ),
                                    },
                                    {
                                        color: COLOR_DATAVIZ_ORANGE,
                                        title: 'After a disambiguation',
                                        value:
                                            props.metrics.data?.counters
                                                ?.disambiguations || 0,
                                        percentage: calcRatio(
                                            props.metrics.data?.counters
                                                ?.disambiguations || 0,
                                            props.metrics.data?.counters
                                                ?.conversations || 0,
                                        ),
                                    },
                                    {
                                        color: COLOR_DATAVIZ_GREEN,
                                        title: 'After an answer',
                                        value:
                                            props.metrics.data?.counters
                                                ?.answers || 0,
                                        percentage: calcRatio(
                                            props.metrics.data?.counters
                                                ?.answers || 0,
                                            props.metrics.data?.counters
                                                ?.conversations || 0,
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    );
                }}
            />
        </Card>
    );
};
