import {redux} from '../index';
import {setActiveResponseId} from './actions';
import {formatDateViewingOption} from './utils';

export const getActivityDispatcher = (dispatch) => (params) => {
    dispatch(
        redux.rest.actions[redux.activity.constants.GET_ACTIVITY](
            formatDateViewingOption(params.dateRange),
        ),
    );
};

export const getResponseCountersDispatcher = (dispatch) => (params) => {
    dispatch(
        redux.rest.actions[redux.activity.constants.GET_RESPONSE_COUNTERS](
            formatDateViewingOption(params.dateRange),
        ),
    );
};

export const getResponseQueriesDispatcher = (dispatch) => (params) => {
    dispatch(
        redux.rest.actions[redux.activity.constants.GET_RESPONSE_QUERIES](
            params,
        ),
    );
};

export const setActiveResponseIdDispatcher =
    (dispatch) => (activeResponseId) => {
        dispatch(setActiveResponseId(activeResponseId));
    };
