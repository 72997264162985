import './_index.scss';
/**
 * Content copied from https://github.com/LouisBarranqueiro/reapop-theme-wybo/blob/master/index.js
 * Following instructions from https://github.com/LouisBarranqueiro/reapop/blob/master/docs/api.md#customize-or-create-a-theme
 */

// media breakpoint - small screen min width
const smallScreenMin = 768;

// default className for NotificationsSystem component
const notificationsSystemClassName = 'notifications-system';

// default className for NotificationsContainer component
const notificationsContainerClassName = {
    main: 'notifications-container',
    position: function position(_position) {
        return 'notifications-container--' + _position;
    },
};

// default transition for Notification component
const notificationsContainerTransition = {
    enterTimeout: 500,
    leaveTimeout: 900,
    name: {
        enter: 'notification-wrapper-enter',
        leave: 'notification-wrapper-leave',
    },
};

// default className for Notification component
const notificationClassName = {
    main: 'notification',
    wrapper: 'notification-wrapper',
    meta: 'notification-meta',
    title: 'notification-title',
    message: 'notification-message',
    // `fa` corresponds to font-awesome's class name
    icon: 'fa ' + 'notification-icon',
    // Hacks to stop warnings, these are unused classes.
    image: '_1',
    button: '_2',
    buttonText: '_3',
    imageContainer: '_4',
    dismissible: '_5',
    status: function status(_status) {
        return 'notification--' + _status;
    },
    buttons: function buttons(count) {
        if (count === 0) {
            return '';
        } else if (count === 1) {
            return 'notification--buttons-1';
        } else if (count === 2) {
            return 'notification--buttons-2';
        }
        return 'notification-buttons';
    },
    closeButtonContainer: 'notification-close-button-container',
    closeButton: 'fa ' + 'notification-close-button',
};

export const theme = {
    smallScreenMin: smallScreenMin,
    notificationsSystem: {
        className: notificationsSystemClassName,
    },
    notificationsContainer: {
        className: notificationsContainerClassName,
        transition: notificationsContainerTransition,
    },
    notification: {
        className: notificationClassName,
    },
};
