import {METHODS} from '../constants';
import {
    GET_FEEDBACK_TIMESERIES,
    GET_AGGREGATED_FEEDBACK,
    GET_CX_METRIC_ASPECT_FEEDBACKS,
} from './constants';

const {GET} = METHODS;

export const rest = {
    [GET_CX_METRIC_ASPECT_FEEDBACKS]: {
        url: '/v3.0/activity/events?uri=in%2Fcx%2Ffeedback&aspect=:aspect&from=:minDate&to=:maxDate',
        options: {
            method: GET,
        },
    },
    [GET_AGGREGATED_FEEDBACK]: {
        url: '/v3.1/analytics/aggregated/feedbacks?from=:minDate&to=:maxDate',
        options: {
            method: GET,
        },
    },
    [GET_FEEDBACK_TIMESERIES]: {
        url: '/v3.1/analytics/timeseries/feedbacks/daily?from=:minDate&to=:maxDate',
        options: {
            method: GET,
        },
    },
};
