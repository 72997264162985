import React from 'react';
import {KareError} from '@app/common';

interface ErrorBoundaryProps {
    children: any;
}

// Design: https://projects.invisionapp.com/d/main#/console/15528636/328533888/preview

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
    state = {error: null, errorInfo: null};

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
    }

    handleClickRefresh = () => {
        window.location.reload();
    };

    render() {
        const {errorInfo, error} = this.state;
        if (errorInfo) {
            return <KareError errorInfo={errorInfo} error={error} />;
        }
        return this.props.children;
    }
}
