import React, {Fragment} from 'react';

import {Close, Search} from '@app/common/icons';
import {KareTextInput} from '@app/common';
import {Button} from '@app/common/button';

interface SearchBarProps {
    onChange?: any;
    filterButtonHandler?: any;
    value: string;
    showFilterButton?: boolean;
    placeholder?: string;
    onKeyPress?: any;
    hideCross?: boolean;
    name?: string;
    filterCTA?: string;
}

export class SearchBar extends React.Component<SearchBarProps> {
    render() {
        const {
            onChange,
            value,
            hideCross,
            placeholder = 'Search in your knowledge',
            name,
            onKeyPress,
            filterButtonHandler,
        } = this.props;

        const handleButtonClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            filterButtonHandler();
        };

        return (
            <div className="c-icon-field knowledge-base__search-bar">
                <div className="knowledge-base__search-bar__input-container">
                    <KareTextInput
                        input={{
                            name: name,
                            type: 'text',
                            onKeyPress: onKeyPress,
                            onChange: onChange,
                            value: value,
                        }}
                        placeholder={placeholder}
                        meta={{}}
                    />

                    <Search size={24} />
                    {value && !hideCross && (
                        <a onClick={() => onChange({target: {value: ''}})}>
                            <Close size={18} />
                        </a>
                    )}
                </div>
                {!hideCross && (
                    <Fragment>
                        {this.props.showFilterButton ? (
                            <Button
                                className="knowledge-base__filter-button"
                                outlined
                                onClick={(e) => handleButtonClick(e)}
                            >
                                Filter by label
                            </Button>
                        ) : (
                            <Button
                                className="knowledge-base__filter-button knowledge-base__filter-clear "
                                outlined
                                onClick={(e) => handleButtonClick(e)}
                            >
                                Clear all filters
                            </Button>
                        )}
                    </Fragment>
                )}
            </div>
        );
    }
}
