import React from 'react';
import {Switch, Route} from 'react-router';
import {ConnectIntegration} from './connect';

interface IntegrationsProps {
    match: any;
}

export const Integrations = ({match}: IntegrationsProps) => {
    return (
        <Switch>
            <Route
                path={`${match.path}/:provider/:preFilledDomain?`}
                component={ConnectIntegration}
            />
        </Switch>
    );
};
