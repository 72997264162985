import {NODE_TYPES} from '@app/constants';
import {get} from 'lodash';

export const getInitialValues = (
    isNewResponse = undefined,
    isClonedResponse = undefined,
    response = undefined,
    responseFormDocumentId = undefined,
    isChatteringTypeActive = undefined,
    clusterId = undefined,
) => {
    const contentKey = isChatteringTypeActive
        ? NODE_TYPES.CHATTER
        : NODE_TYPES.RESPONSE;
    let initialValues: any = {
        document: {
            id: undefined,
        },
        [contentKey]: {},
        blocks: [],
    };
    // TODO(rupertrutland): Fix this function/retest
    if (isNewResponse) {
        initialValues.type = isChatteringTypeActive
            ? NODE_TYPES.CHATTER
            : NODE_TYPES.RESPONSE;
    }
    if (response) {
        initialValues = {...response};
        if (initialValues.type === NODE_TYPES.CONTENT) {
            initialValues.type = NODE_TYPES.RESPONSE;
        }
        if (isClonedResponse && !clusterId) {
            initialValues.title = `${response.title}`;
        }
        initialValues.blocks = get(response, [contentKey, 'blocks'], []);
    }
    if (responseFormDocumentId) {
        initialValues.document.id = responseFormDocumentId;
    }
    return initialValues;
};

export const getTableItems = (query, responses, labelsToFilter) => {
    const normalizedLabelsToFilter = labelsToFilter.map((l) => l.name);
    return responses.filter((response) => {
        let queryMatch = true;
        let allLabelsMatch = true;
        if (!query && !labelsToFilter.length) {
            return true;
        }
        if (
            query &&
            response.title.toLowerCase().indexOf(query.toLowerCase()) === -1
        ) {
            queryMatch = false;
        }
        if (response.labels) {
            normalizedLabelsToFilter.map((tag) => {
                if (!response.labels.includes(tag)) {
                    allLabelsMatch = false;
                }
            });
        }
        if (
            (!response.labels ||
                (response.labels && response.labels.length === 0)) &&
            normalizedLabelsToFilter.length > 0
        ) {
            allLabelsMatch = false;
        }
        return queryMatch && allLabelsMatch;
    });
};
