import * as selectors from './selectors';
import * as constants from './constants';
import * as actionCreators from './action-creators';
import {reducer} from './reducer';

export const locale = {
    selectors,
    constants,
    actionCreators,
    reducer,
};

// TODO(rupertrutland): Finish up, comment out and make pr.
