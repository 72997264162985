import React from 'react';
import {KareTextInput, Button} from '@app/common';
import {Field, Form} from 'react-final-form';
import {validators} from '@app/utils';
import {toast} from 'react-toastify';

const handleSubmit = (props) => (values) => {
    props
        .setAutomationEnvironment(values.automations_url)
        .then(() => {
            props.closeModal();
            props.setHasError(false);
        })
        .catch(() => {
            props.setHasError(true);
            toast.error('Automation url not saved. Please try again later.');
        });
};

export const SyncEnvironment = (props) => {
    return (
        <Form
            onSubmit={handleSubmit(props)}
            render={(renderProps) => {
                const {handleSubmit} = renderProps;
                return (
                    <div className="add-hostname-automation-form">
                        <form onSubmit={handleSubmit}>
                            <span className="label">URL</span>
                            <Field
                                id="base_url"
                                name="automations_url"
                                placeholder="https://www.example.com"
                                /*@ts-ignore*/
                                component={KareTextInput}
                                validate={validators.website}
                            />
                            <div className="form-button">
                                <Button className="kare-modal-form-buttons-submit">
                                    ADD AUTOMATION
                                </Button>
                            </div>
                        </form>
                    </div>
                );
            }}
        />
    );
};
