import {CONTENT_TYPE, METHODS} from '../constants';
import {
    BULK_UPDATE_DOCUMENTS,
    GET_ARCHIVED_DOCUMENTS,
    GET_ATTACHED_DOCUMENT_FIELD_DOCUMENT,
    GET_BODY_DOCUMENT,
    GET_DOCUMENT,
    GET_PUBLISHED_DOCUMENTS,
    GET_RELATED_DOCUMENTS,
    PATCH_DOCUMENT,
    UPDATE_DOCUMENT,
} from './constants';
import {
    getAllArchivedDocumentsDispatcher,
    getAllPublishedDocumentsDispatcher,
} from '../knowledge-base/dispatchers';
import {NODE_TYPES, TYPE, NODE_STATUSES, STATUS} from '../../constants';
import {documentsTable} from '../documents-table';
import {redux} from '../index';

const {GET, PATCH, PUT} = METHODS;

const getAllDocuments = ({dispatch}) => {
    getAllPublishedDocumentsDispatcher(dispatch)();
    getAllArchivedDocumentsDispatcher(dispatch)();
};

/**
 * The redux-api configuration for the documents module.
 */
export const rest = {
    [GET_PUBLISHED_DOCUMENTS]: {
        url: `/v3.0/kbm/nodes?${TYPE}=${NODE_TYPES.CONTENT}&${STATUS}=${NODE_STATUSES.PUBLISHED}`,
        options: {
            method: GET,
        },
    },

    [GET_ARCHIVED_DOCUMENTS]: {
        url: `/v3.0/kbm/nodes?${TYPE}=${NODE_TYPES.CONTENT}&${STATUS}=unpublished`,
        options: {
            method: GET,
        },
    },
    /**
     * Get documents related to a response.
     */
    [GET_RELATED_DOCUMENTS]: {
        url: `/v3.0/kbm/nodes?${TYPE}=${NODE_TYPES.CONTENT}&${STATUS}=${NODE_STATUSES.PUBLISHED}`,
        options: {
            method: GET,
        },
    },

    /**
     * Get a single document document.
     * Used for showing the document detail page via the response editor.
     */
    [GET_DOCUMENT]: {
        url: `/v3.0/kbm/nodes/:id?${TYPE}=${NODE_TYPES.CONTENT}`,
        options: {
            method: GET,
        },
        postfetch: [
            ({data, dispatch}) => {
                // fetch body of document
                dispatch(
                    redux.rest.actions[GET_BODY_DOCUMENT]({
                        id: data.id,
                    }),
                );
            },
        ],
    },
    [GET_BODY_DOCUMENT]: {
        url: `/v3.0/kbm/nodes/:id/content/md`,
        options: {
            method: GET,
            headers: {[CONTENT_TYPE]: 'text/plain', accept: 'text/plain'},
        },
    },
    [UPDATE_DOCUMENT]: {
        url: '/v3.0/kbm/nodes/:id',
        options: {
            method: PUT,
        },
        postfetch: [getAllDocuments],
    },
    [PATCH_DOCUMENT]: {
        url: '/v3.0/kbm/nodes/:id',
        options: {
            method: PATCH,
        },
        postfetch: [getAllDocuments],
    },
    [BULK_UPDATE_DOCUMENTS]: {
        url: '/v3.0/kbm/nodes/bulk',
        options: {
            method: PATCH,
        },
        postfetch: [
            getAllDocuments,
            ({dispatch}) => {
                dispatch(documentsTable.actionCreators.resetSelectedRows());
            },
        ],
    },

    /**
     * Get a single document.
     * Used for showing the attached document inside the response form.
     */
    [GET_ATTACHED_DOCUMENT_FIELD_DOCUMENT]: {
        url: '/v3.0/kbm/nodes/:id',
        options: {
            method: GET,
        },
    },
};
