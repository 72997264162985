import React from 'react';
import {Card, Grid} from '@material-ui/core';

import './Performance.scss';
import {DistributionChart} from './DistributionChart/DistributionChart';
import {SourcesChart} from './SourcesChart/SourcesChart';
import {ClusterTreeMap} from './ClusterTreeMap/ClusterTreeMap';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {BrokenCard} from './BrokenCard/BrokenCard';

interface IPerformanceProps {
    history: any;
}

export const Performance: React.FunctionComponent<IPerformanceProps> = ({
    history,
}) => {
    return (
        <Grid container spacing={2}>
            <React.Fragment>
                <Grid item xs={6}>
                    <Card>
                        <SourcesChart />
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card style={{height: '100%'}}>
                        <BrokenCard history={history} />
                    </Card>
                </Grid>
                <Grid item xs={12} className="Performance__treemap">
                    <Card>
                        <CardTitle
                            title={'Expand knowledge using historical data'}
                        />
                        <ClusterTreeMap history={history} />
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <DistributionChart history={history} />
                    </Card>
                </Grid>
            </React.Fragment>
        </Grid>
    );
};
