import React from 'react';
import cx from 'classnames';

interface CellWrapperProps {
    children?: any;
    isCenterAligned?: boolean;
    isDark?: boolean;
    className?: string;
}

export class CellWrapper extends React.Component<CellWrapperProps> {
    render() {
        const {children, isCenterAligned, isDark, className} = this.props;
        const classNames = cx(className, 'dashboard-responses-cell-wrapper', {
            'dashboard-responses-cell-wrapper--align-center': !!isCenterAligned,
            'dashboard-responses-cell-wrapper--dark': !!isDark,
        });
        return <div className={classNames}>{children}</div>;
    }
}
