import Axios from 'axios';
import {Moment} from 'moment';

interface CxMetricsServerResponse {
    from: number;
    to: number;
    entries: {
        nps: {
            aspect: string;
            metric_id: string;
            metric_key: string;
            question: string;
            counters: {
                'nps.detractors': number;
                'nps.neutral': number;
                'nps.promoters': number;
                answers: number;
                feedbacks: number;
                impressions: number;
            };
        }[];
        csat: {
            aspect: string;
            metric_id: string;
            metric_key: string;
            question: string;
            counters: {
                'csat.dissatisfied': number;
                'csat.neutral': number;
                'csat.satisfied': number;
                answers: number;
                feedbacks: number;
                impressions: number;
            };
        }[];
        ces: {
            aspect: string;
            metric_id: string;
            metric_key: string;
            question: string;
            counters: {
                'ces.dissatisfied': number;
                'ces.greatly_dissatisfied': number;
                'ces.greatly_satisfied': number;
                'ces.moderately_dissatisfied': number;
                'ces.moderately_satisfied': number;
                'ces.neutral': number;
                'ces.satisfied': number;
                answers: number;
                feedbacks: number;
                impressions: number;
            };
        }[];
    };
}

export class CxMetrics {
    cache = {};
    loading = false;

    constructor() {}

    storeCxMetricsData = (URL: string, data: CxMetricsServerResponse) => {
        this.cache[URL] = data;
        this.loading = false;
    };

    async getAggregatedCxMetrics(
        minDate: Moment,
        maxDate: Moment,
        forceRefresh = false,
    ) {
        const URL = `/v3.1/analytics/aggregated/cx_metrics/aspect,metric_id?from=${minDate.unix()}&to=${maxDate.unix()}`;

        if (this.cache[URL] && !forceRefresh) {
            return {data: this.cache[URL], error: null};
        }
        const res = {
            data: null,
            error: null,
        };
        try {
            const req = await Axios.get(URL);
            res.data = req.data;
            this.storeCxMetricsData(URL, req.data);
        } catch (e) {
            res.error = e;
        }

        return res;
    }
}
