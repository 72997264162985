import React from 'react';

export const DialpadAI = () => (
    <svg
        width="14"
        height="11.27"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.2502 3.411C17.0785 3.411 17.75 2.7395 17.75 1.91116C17.75 1.08282 17.0785 0.411316 16.2502 0.411316C15.4218 0.411316 14.7503 1.08282 14.7503 1.91116C14.7503 2.7395 15.4218 3.411 16.2502 3.411Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.65398 3.06305C6.31416 3.06305 5.72913 3.38676 5.42482 4.38028L5.42421 4.38226L2.54199 13.675C2.35022 14.2933 1.69353 14.639 1.07523 14.4473C0.456923 14.2555 0.111153 13.5988 0.302925 12.9805L3.18332 3.69371L3.18396 3.6916C3.65254 2.16415 4.88051 0.718754 6.65398 0.718754C8.42693 0.718754 9.65306 2.16108 10.1225 3.6862L10.1229 3.68761L12.3416 10.8407L12.3421 10.8421C12.6467 11.8328 13.2306 12.1556 13.5702 12.1556C14.403 12.1556 15.0779 11.4806 15.0779 10.6478V5.7505C15.0779 5.10314 15.6027 4.57835 16.2501 4.57835C16.8974 4.57835 17.4222 5.10314 17.4222 5.7505V10.6478C17.4222 12.7753 15.6977 14.4999 13.5702 14.4999C11.7991 14.4999 10.5714 13.0582 10.1018 11.5327L10.1013 11.531L7.88253 4.37794L7.88211 4.37655C7.57736 3.38535 6.99461 3.06305 6.65398 3.06305Z"
            fill="white"
        />
    </svg>
);
