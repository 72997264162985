import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {KareError, Spinner} from '@app/common';
import {Inbox} from '@app/common/icons';
import {PERMISSION_KEYS} from '@app/constants';
import {redux} from '@app/redux';
import {scopesSelector} from '@app/redux/user/selectors';
import {hasScope} from '@app/utils/auth';
import {Conversations} from './conversations/conversations';
import {Messages} from './messages';
import {Feedback} from './feedback';

interface ActivityProps {
    requiredPermissions: any[];
    scopes: any[];
    conversationsError: any;
    applicationId: string;
    isLoading: boolean;
    match: any;
}

export class ActivityComponent extends React.Component<ActivityProps> {
    render() {
        const {
            conversationsError,
            applicationId,
            isLoading,
            match,
            scopes,
            requiredPermissions,
        } = this.props;

        const {section} = match.params;

        if (conversationsError !== null && conversationsError !== undefined) {
            return <KareError error={conversationsError} />;
        }
        if (isLoading) {
            return <Spinner withCenteredContainer={true} />;
        }
        if (
            hasScope(this.props.scopes, PERMISSION_KEYS.MIND.SETTINGS) &&
            !applicationId
        ) {
            return (
                <div className="kare-empty-state">
                    <Inbox size={60} />
                    <h1>Start self serving your customers</h1>
                    <p>
                        You haven’t receive any traffic from your customers yet.{' '}
                        <br />
                        Publish your widget on your website to start self
                        serving your customers.
                    </p>
                    <Link className="c-btn" to="/settings/widget">
                        Publish widget
                    </Link>
                </div>
            );
        }
        return section !== 'feedback' ? (
            <div className="o-panel console-activity">
                <Conversations />
                <Messages
                    scopes={scopes}
                    requiredPermissions={requiredPermissions}
                />
            </div>
        ) : (
            <div className="o-panel console-activity">
                <Feedback />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        conversationsError:
            redux.conversations.selectors.conversationsErrorSelector(state),
        isLoading:
            redux.org.selectors.isOrgLoadingSelector(state) ||
            redux.application.selectors.isApplicationLoadingSelector(state),
        applicationId: redux.application.selectors.applicationIdSelector(state),
        scopes: scopesSelector(state),
    };
};

export const Activity = connect(mapStateToProps)(ActivityComponent);
