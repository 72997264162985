import {
    ADD_CHOICE,
    ADD_CHOICE_ERROR,
    ADD_QUOTE,
    ADD_QUOTE_ERROR,
    REMOVE_QUOTE,
} from './constants';

const initialState = {
    quote: null,
    dialog: null,
    error: null,
};
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CHOICE:
            return {...state, choice: action.payload};
        case ADD_CHOICE_ERROR:
            return {...state, error: action.payload, choice: null};
        case ADD_QUOTE:
            return {...state, quote: action.payload};
        case ADD_QUOTE_ERROR: {
            return {...state, error: action.payload, choice: null};
        }
        case REMOVE_QUOTE:
            return {...state, quote: null};
        default:
            return state;
    }
};
