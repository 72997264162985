import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import {get} from 'lodash';
import {MdSmartphone, MdDesktopWindows} from 'react-icons/md';
import {
    STATUS_ACTIVE,
    STATUS_BOUNCED,
    STATUS_ESCALATED,
    STATUS_RESOLVED,
    CONVERSATION_STATS,
} from './constants';
import {UAParser} from 'ua-parser-js';

interface ConversationProps {
    conversation?: any;
    getMessages?: any;
    onClick?: any;
    isActive?: boolean;
    status?: string;
    filters?: any;
}

export class Conversation extends React.Component<ConversationProps> {
    localizedTime = (time) => {
        //@ts-ignore
        const userLang = navigator.language || navigator.userLanguage;
        moment.locale(userLang);
        const t = moment.unix(time);
        return ''.concat(t.format('L'), ' ', t.format('LT'));
    };

    render() {
        const {conversation, isActive, onClick, status} = this.props;
        const {stats, last_message_time} = conversation;
        const className = cx('console-activity-conversation', {
            'console-activity-conversation--resolved':
                status === STATUS_RESOLVED,
            'console-activity-conversation--escalated':
                status === STATUS_ESCALATED,
            'console-activity-conversation--not-bounced':
                status === STATUS_ACTIVE,
            'console-activity-conversation--bounced': status === STATUS_BOUNCED,
            'console-activity-conversation--active': isActive,
        });

        const userId = get(conversation, [CONVERSATION_STATS.USER_ID]);
        const userAgent = UAParser(
            get(conversation.metadata, 'user_agent', ''),
        );
        const os = get(userAgent, ['os', 'name'], '').toLowerCase();

        let platform = 'desktop';
        if (
            os.includes('linux') ||
            os.includes('windows') ||
            os.includes('mac')
        ) {
            platform = 'desktop';
        } else {
            platform = 'mobile';
        }
        const userIdColumn = cx(
            'console-activity-conversations-list__column console-activity-conversations-list__column-1 console-activity-conversations-list__user-id',
            {visitor: !userId},
        );
        return (
            <div className={className} onClick={onClick}>
                <div className="console-activity-conversations-list__row">
                    <div className={userIdColumn}>
                        {platform === 'desktop' ? (
                            <MdDesktopWindows size={15} />
                        ) : (
                            <MdSmartphone size={15} />
                        )}{' '}
                        <span>{userId ? userId : 'Visitor'}</span>
                    </div>
                    <div className="console-activity-conversations-list__column console-activity-conversations-list__column-2">
                        {get(stats, CONVERSATION_STATS.QUERIES, 0)}
                    </div>
                    <div className="console-activity-conversations-list__column console-activity-conversations-list__column-3">
                        {get(stats, CONVERSATION_STATS.MISSING, 0)}
                    </div>
                    <div className="console-activity-conversations-list__column console-activity-conversations-list__column-4">
                        {get(stats, CONVERSATION_STATS.FORMS, 0)}
                    </div>
                    <div className="console-activity-conversations-list__column console-activity-conversations-list__column-5">
                        {get(stats, CONVERSATION_STATS.WEBEXT, 0)}
                    </div>
                    <div className="console-activity-conversations-list__column console-activity-conversations-list__column-6">
                        {get(stats, CONVERSATION_STATS.LINKS, 0)}
                    </div>
                    <div className="console-activity-conversations-list__column console-activity-conversations-list__column-7">
                        {get(stats, CONVERSATION_STATS.THUMBS_UP, 0)}
                    </div>
                    <div className="console-activity-conversations-list__column console-activity-conversations-list__column-8">
                        {get(stats, CONVERSATION_STATS.THUMBS_DOWN, 0)}
                    </div>
                    <div
                        className={cx(
                            'console-activity-conversations-list__column console-activity-conversations-list__column-9 console-activity-conversations-list-status',
                            {
                                'console-activity-conversations-list-status__escalated':
                                    status.toLowerCase() === 'escalated',
                                'console-activity-conversations-list-status__handedover':
                                    status.toLowerCase() === 'handed-over',
                                'console-activity-conversations-list-status__resolved':
                                    status.toLowerCase() === 'resolved',
                                'console-activity-conversations-list-status__unresolved':
                                    status.toLowerCase() === 'unresolved',
                            },
                        )}
                    >
                        {status}
                    </div>
                    <div className="console-activity-conversations-list__column console-activity-conversations-list__column-10">
                        <div className="console-activity-conversation-timestamp">
                            {this.localizedTime(last_message_time)}
                        </div>
                    </div>
                </div>
                <div className="console-activity-conversations-list__row"></div>
            </div>
        );
    }
}
