export const GET_CONVERSATIONS = 'getConversations';
export const GET_CONVERSATION_MESSAGES = 'getConversationMessages';
export const GET_CONVERSATIONS_TIMESERIES = 'getConversationsTimeseries';
export const GET_AGGREGATED_CONVERSATIONS_TIMESERIES =
    'getAggregatedConversationsTimeseries';
export const CHANGE_SELECTED_CONVERSATION =
    'Activity/ChangeSelectedConversation';
export const SELECTED_CONVERSATION_ID = 'selectedConversationId';
export const CONVERSATIONS_STATE_FIELD = 'conversations';
export const META_DATA = 'metadata';
