import React from 'react';
import {Field} from 'react-final-form';
import {isWebsite} from '@app/containers/Form/validators';
import './BaseUrl.scss';
import {KareTextInput} from '../../common';

interface IBaseUrl {
    host: string | undefined;
    onChange?: (value: string) => any | undefined;
}

export const BaseUrl: React.FunctionComponent<IBaseUrl> = (props) => {
    return (
        <Field name="host" validate={isWebsite}>
            {({input, meta}) => {
                return (
                    <div className="BaseUrl__container">
                        <KareTextInput
                            placeholder="Add base url..."
                            label="Base URL"
                            input={{...input}}
                            disabled={!!props.host}
                            meta={{
                                error: {
                                    show: meta.error && meta.touched,
                                    message: meta.error,
                                },
                            }}
                            {...props}
                        />
                    </div>
                );
            }}
        </Field>
    );
};
