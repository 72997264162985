import {startsWith} from 'lodash';

import {validators} from '@app/utils';
import {
    startsWithErrorMessage,
    maxUrlsErrorMessage,
    atLeastOneInvalidUrl,
} from './constants';

export const startsWithHttps = (value) => {
    if (typeof value === 'string' && !startsWith(value, 'https://')) {
        return startsWithErrorMessage;
    }
};

export const max5URLs = (value) => {
    if (value && value.length > 5) {
        return maxUrlsErrorMessage;
    }
};

export const mulitpleWebsites = (value) => {
    return (
        value &&
        value.reduce((prev, next) => {
            if (prev) {
                return prev;
            }
            return validators.website(next) && atLeastOneInvalidUrl;
        }, undefined)
    );
};
