export const GET_CX_METRIC_ASPECT_FEEDBACKS = 'getCxMetricAspectFeedbacks';
export const GET_AGGREGATED_FEEDBACK = 'getAggregatedFeedback';
export const GET_FEEDBACK_TIMESERIES = 'getFeedbackTimeseries';
export const CHANGE_SELECTED_ASPECT = 'changeSelectedAspect';
export const CHANGE_SELECTED_MESSAGE = 'changeSelectedMessage';
export const CHANGE_SELECTED_METRIC_ID = 'changeSelectedMetricId';
export const CHANGE_SELECTED_MIN_DATE = 'changeSelectedMinDate';
export const CHANGE_SELECTED_MAX_DATE = 'changeSelectedMaxDate';
export const SELECTED_ASPECT = 'selectedAspect';
export const SELECTED_MESSAGE = 'selectedMessage';
export const SELECTED_METRIC_ID = 'selectedMetricId';
export const SELECTED_MIN_DATE = 'selectedMinDate';
export const SELECTED_MAX_DATE = 'selectedMaxDate';
export const FEEDBACK_STATE_FIELD = 'feedbacks';
export const META_DATA = 'metadata';
