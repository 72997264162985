import React from 'react';
import {Trash, Create, ArrowUpward, ArrowDownward} from '@app/common/icons';

interface BlockControlsProps {
    index?: number;
    fields?: any;
    activateBlock?: any;
    removeBlock?: any;
}

export class BlockControls extends React.Component<BlockControlsProps> {
    handleClickMoveUp = () => {
        const {index, fields} = this.props;
        if (index > 0) {
            fields.swap(index, index - 1);
        }
    };
    handleClickEdit = () => {
        const {activateBlock} = this.props;
        activateBlock();
    };
    handleClickTrash = () => {
        const {removeBlock} = this.props;
        removeBlock();
    };
    handleClickMoveDown = () => {
        const {index, fields} = this.props;
        if (index < fields.length - 1) {
            fields.swap(index, index + 1);
        }
    };

    render() {
        return (
            <section className="kare-block-controls">
                <div
                    className="kare-block-controls-edit"
                    onClick={this.handleClickEdit}
                >
                    <Create size={10} />
                </div>
                <div
                    className="kare-block-controls-up"
                    onClick={this.handleClickMoveUp}
                >
                    <ArrowUpward size={10} />
                </div>
                <div
                    className="kare-block-controls-down"
                    onClick={this.handleClickMoveDown}
                >
                    <ArrowDownward size={10} />
                </div>
                <div
                    className="kare-block-controls-trash"
                    onClick={this.handleClickTrash}
                >
                    <Trash size={10} />
                </div>
            </section>
        );
    }
}
