import {ResponsivePie} from '@nivo/pie';
import cx from 'classnames';
import React from 'react';
import {CardTitle} from '../CardTitle/CardTitle';
import './RadialChart.scss';
import {getChartColor} from '@app/utils/DataRenderingUtils';

interface IRadialChartProps {
    title?: string;
    plotData: any;
    direction?: 'row' | 'column';
    extraOptions?: {[name: string]: string | number};
    hero?: {value: number; title: string};
    isPercentage?: boolean;
}

export const RadialChart: React.FunctionComponent<IRadialChartProps> = ({
    title,
    plotData,
    extraOptions,
    hero,
    isPercentage = true,
}) => {
    const chartData = Object.keys(plotData).map((k) => ({
        id: k,
        label: k,
        value: plotData[k],
        color: getChartColor(k),
    }));
    return (
        <div className="RadialChart__container">
            {title && <CardTitle title={title} />}
            <div
                className={cx('RadialChart__content', {
                    ['RadialChart__column']: true,
                })}
                style={{flexDirection: 'column'}}
            >
                <div className="RadialChart__left">
                    <ResponsivePie
                        {...extraOptions}
                        data={chartData}
                        margin={{top: 20, right: 20, bottom: 20, left: 20}}
                        sortByValue={false}
                        innerRadius={0.7}
                        padAngle={1}
                        colors={(c: any) => c.data.color as string}
                        borderWidth={0}
                        enableArcLinkLabels={false}
                        enableArcLabels={false}
                        isInteractive={false}
                    />
                </div>
                {hero && (
                    <div className="RadialChart__hero">
                        <small>
                            {hero.value}
                            <span className="RadialChart__percentage">
                                {isPercentage ? '%' : ''}
                            </span>
                        </small>
                        <strong>{hero.title}</strong>
                    </div>
                )}
            </div>
        </div>
    );
};
