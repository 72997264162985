import React from 'react';
// @ts-ignore
import {Bar, BarChart, CartesianGrid, XAxis, YAxis} from 'recharts';

import {CHART_CONFIG} from '../../constants';
import {getBarWidth} from './utils';
import {XAxisTickRenderer} from './x-tick-renderer';

interface DashboardActivityChartProps {
    viewingOption?: string;
    activityData?: {
        date: any;
        name: string;
        resolved: number;
        unresolved: number;
        bounced: number;
        escalated: number;
        handover: number;
    }[];
}

export class DashboardActivityChart extends React.Component<DashboardActivityChartProps> {
    labelFormatter = (labelValue) => (labelValue === 0 ? '' : labelValue);

    render() {
        const {activityData, viewingOption} = this.props;
        const barWidth = getBarWidth(activityData.length);
        return (
            <div className="dashboard-activity__chart">
                <span className="dashboard-activity__chart-y-axis-title">
                    CONVERSATIONS
                </span>
                <BarChart
                    width={CHART_CONFIG.width}
                    height={CHART_CONFIG.height}
                    data={activityData}
                    margin={CHART_CONFIG.margin}
                >
                    <CartesianGrid
                        strokeDasharray="0"
                        vertical={false}
                        stroke={CHART_CONFIG.gridColor}
                    />
                    <XAxis
                        tickLine={false}
                        dataKey="name"
                        tickMargin={0}
                        minTickGap={0}
                        tick={<XAxisTickRenderer resolution={viewingOption} />}
                    />
                    <YAxis
                        allowDecimals={false}
                        tickLine={false}
                        tickFormatter={this.labelFormatter}
                        tick={{fontSize: CHART_CONFIG.labels.size}}
                        width={70}
                        domain={[0, 'dataMax']}
                    />
                    <Bar
                        dataKey={CHART_CONFIG.handoverKey}
                        fill={CHART_CONFIG.handoverKeyColor}
                        stackId={CHART_CONFIG.stackId}
                        barSize={barWidth}
                    />
                    <Bar
                        dataKey={CHART_CONFIG.deflectedKey}
                        fill={CHART_CONFIG.deflectedColor}
                        stackId={CHART_CONFIG.stackId}
                        barSize={barWidth}
                    />
                    <Bar
                        dataKey={CHART_CONFIG.escalatedKey}
                        fill={CHART_CONFIG.escalatedColor}
                        stackId={CHART_CONFIG.stackId}
                        barSize={barWidth}
                    />
                </BarChart>
            </div>
        );
    }
}
