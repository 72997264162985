import {CHART_CONFIG} from '../../constants';
import floor from 'lodash/floor';

export const getBarWidth = (barsNumber) =>
    floor(CHART_CONFIG.width / barsNumber);
export const calculateInterval = (dataLength) => {
    if (dataLength <= 8) {
        return 0;
    }
    return dataLength;
};

export const tickSplitter = (value) => value.split(' ');
