import React, {Fragment} from 'react';
import {Helmet} from 'react-helmet';
import cx from 'classnames';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';

import {get, isString} from 'lodash';
import {QUERY_LIST_FORM} from '@app/redux/improve/queries/constants';
import {redux} from '@app/redux';
import {
    areClustersErroringSelector,
    areClustersLoadingSelector,
    clusterErrorSelector,
    clustersErrorSelector,
    clustersSelector,
    getCluster,
    getClusterLimit,
    getPedingClustersSelector,
    getSelectedClusterIndex,
    getSelectedClusterType,
    isClusterErroringSelector,
    isClusterLoadingSelector,
    isEndOfcurrentClusters,
    isLastClusterSelector,
    nextCursorSelector,
} from '@app/redux/improve/cards/selectors';
import {QueriesCard} from './queries-card';
import {Spinner} from '@app/common';
import {QueriesCardActions} from './queries-card/queries-card-actions';
import {ErrorMessage} from './error-message';
import {resetSelectedClusterIndexAction} from '@app/redux/improve/cards/actions';
import {ClusterHeader} from './cluster-header';
import {getSelectedResponse} from '@app/redux/improve/responses/selectors';
import {NoMoreCards} from './no-more-cards';
import {ProcessingData} from './processing-data';
import {InactiveState} from './inactive-state';
import {scopesSelector} from '@app/redux/user/selectors';
import {hasScope} from '@app/utils/auth';
import {PERMISSION_KEYS} from '@app/constants';
import {getValueByKey} from '@app/utils/querystring';
import {resetSelectResponseState} from '@app/redux/improve/responses/dispatchers';

interface ImproveProps {
    pristine?: boolean;
    isResponseSelected?: boolean;
    getClusters?: any;
    getCluster?: any;
    isEndOfcurrentClusters?: boolean;
    isLoading?: boolean;
    resetSelectedClusterIndex?: any;
    isClusterLoading?: boolean;
    areClustersLoading?: boolean;
    clusters?: any;
    cluster?: any;
    isEndOfCurrentClusters?: boolean;
    clusterType?: string;
    contentLocale?: string;
    selectedClusterType?: string;
    nextCursor?: string;
    areClustersErroring?: boolean;
    isClusterErroring?: boolean;
    isLastCluster?: boolean;
    application?: any;
    selectedClusterIndex?: number;
    pendingClusters?: number;
    clusterLimit?: number;
    scopes?: any[];
    resetSelectedResponse?: any;
    getAllPublishedResponses?: any;
    applicationId?: string;
    clusterError?: string;
    clustersError?: string;
}

export class ImproveComponent extends React.Component<ImproveProps> {
    state = {
        origin: 'knowledge',
    };

    getInitialCluster = (force = false, preloadId = null) => {
        const {clusterLimit} = this.props;

        this.props.getClusters(clusterLimit, null, force).then((data) => {
            const firstCluster = get(data, ['clusters', 0]);
            if (preloadId) {
                this.props.getCluster(preloadId);
            } else {
                if (firstCluster) {
                    this.props.getCluster(firstCluster.id);
                }
            }
        });
    };
    componentDidMount() {
        const preloadId = get(this.props, ['match', 'params', 'id'], null);
        this.props.resetSelectedResponse();
        this.setState({
            origin: this.getOrigin(this.props),
        });
        if (!preloadId) {
            this.props.getAllPublishedResponses();
        }
        return this.getInitialCluster(false, preloadId);
    }

    componentDidUpdate(prevProps) {
        const preloadId = get(this.props, ['match', 'params', 'id'], null);

        const {contentLocale, nextCursor, clusterLimit} = this.props;
        const {contentLocale: prevContentLocale} = prevProps;
        const currentSelectedCluster = this.props.selectedClusterIndex;
        const prevSelectedCluster = prevProps.selectedClusterIndex;
        if (
            prevContentLocale !== contentLocale ||
            currentSelectedCluster !== prevSelectedCluster
        ) {
            const newClusterId = get(this.props, [
                'clusters',
                'clusters',
                currentSelectedCluster,
                'id',
            ]);
            if (!nextCursor && !newClusterId && !preloadId) {
                this.getInitialCluster(true);
            }
            if (newClusterId) {
                this.props.getCluster(newClusterId);
            } else {
                if (nextCursor) {
                    this.props.resetSelectedClusterIndex();
                    this.props.getClusters(clusterLimit, nextCursor);
                }
            }
        }
    }

    getOrigin = (props) => {
        return getValueByKey(props.location.search, 'origin');
    };

    render() {
        const {
            areClustersErroring,
            isClusterErroring,
            isEndOfcurrentClusters,
            areClustersLoading,
            isClusterLoading,
            isLastCluster,
            applicationId,
            clusterError,
            clustersError,
        } = this.props;

        // We don't have access to the code so we check the error message for a match.
        const processingMatch = 'currently processing your queries';
        const clustersErrorMessage = get(clustersError, 'message');
        const clusterErrorMessage = get(clusterError, 'message');
        const preloadId = get(this.props, ['match', 'params', 'id'], null);

        if (
            (clusterError &&
                isString(clusterErrorMessage) &&
                clusterErrorMessage.includes(processingMatch)) ||
            (clustersError &&
                isString(clustersErrorMessage) &&
                clustersErrorMessage.includes(processingMatch))
        ) {
            return <ProcessingData />;
        }

        const hasSettingsScope = hasScope(
            this.props.scopes,
            PERMISSION_KEYS.MIND.SETTINGS,
        );

        if (hasSettingsScope && !applicationId) {
            return <InactiveState />;
        }

        const managePanelContainer = cx('o-panel', 'manage-panel', {
            empty: isLastCluster,
        });
        if (areClustersErroring || isClusterErroring) {
            return <ErrorMessage />;
        }

        return (
            <div className={managePanelContainer}>
                <Helmet title="Train your AI agent to better serve your customers" />
                {!isEndOfcurrentClusters && (
                    <h1>
                        Improve your self service coverage by managing your
                        responses to common queries.
                    </h1>
                )}
                <div className="manage__container manage__queries-container">
                    <div className="queries-card-container">
                        {areClustersLoading || isClusterLoading ? (
                            <div className="manage__spinner">
                                <Spinner />
                            </div>
                        ) : isEndOfcurrentClusters ? (
                            <NoMoreCards />
                        ) : (
                            <Fragment>
                                <ClusterHeader
                                    cluster={this.props.cluster}
                                    pendingClusters={this.props.pendingClusters}
                                    selectedClusterIndex={
                                        this.props.selectedClusterIndex
                                    }
                                />
                                <QueriesCard />
                            </Fragment>
                        )}
                    </div>
                    {!isEndOfcurrentClusters && (
                        <QueriesCardActions
                            preloadId={preloadId}
                            origin={this.state.origin}
                        />
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    application: redux.application.selectors.applicationSelector(state),
    isLoading:
        redux.org.selectors.isOrgLoadingSelector(state) ||
        redux.application.selectors.isApplicationLoadingSelector(state),
    nextCursor: nextCursorSelector(state),
    isLastCluster: isLastClusterSelector(state),
    clusterLimit: getClusterLimit(state),
    selectedClusterType: getSelectedClusterType(state),
    clusters: clustersSelector(state),
    selectedClusterIndex: getSelectedClusterIndex(state),
    cluster: getCluster(state),
    pendingClusters: getPedingClustersSelector(state),
    isResponseSelected: get(getSelectedResponse(state), 'id', null) !== null,
    isClusterLoading: isClusterLoadingSelector(state),
    areClustersLoading: areClustersLoadingSelector(state),
    isEndOfcurrentClusters: isEndOfcurrentClusters(state),
    areClustersErroring: areClustersErroringSelector(state),
    isClusterErroring: isClusterErroringSelector(state),
    contentLocale: redux.locale.selectors.contentLocaleSelector(state),
    applicationId: redux.application.selectors.applicationIdSelector(state),
    clusterError: clusterErrorSelector(state),
    clustersError: clustersErrorSelector(state),
    scopes: scopesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    getClusters: (limit = 100, nextCursor, force = false) => {
        const method = force
            ? redux.rest.actions[redux.improve.constants.GET_CLUSTERS].force
            : redux.rest.actions[redux.improve.constants.GET_CLUSTERS];
        return dispatch(
            method({
                limit,
                next_cursor: nextCursor,
                approved: false,
            }),
        ).catch(() => {});
    },
    getCluster: (id) => {
        dispatch(redux.rest.actions[redux.improve.constants.GET_CLUSTER]({id}));
    },
    resetSelectedClusterIndex: () =>
        dispatch(resetSelectedClusterIndexAction()),
    getAllPublishedResponses:
        redux.knowledgeBase.dispatchers.getAllPublishedResponsesDispatcher(
            dispatch,
        ),
    resetSelectedResponse: resetSelectResponseState(dispatch),
});

export const Improve = connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    reduxForm({
        form: QUERY_LIST_FORM,
    })(ImproveComponent),
);
