import React from 'react';
import moment from 'moment';
import cx from 'classnames';

import {Refresh} from '@app/common/icons';
import {get_first_and_last_conversation} from './utils';

interface ConversationsHeaderProps {
    isRefreshing?: boolean;
    isLoadingMore?: boolean;
    conversations?: any[];
    onRefresh?: any;
}

export const ConversationsHeader = (props: ConversationsHeaderProps) => {
    const {isRefreshing, isLoadingMore, conversations, onRefresh} = props;

    const showTimeFrame = (firstConvers, lastConvers) => {
        if (firstConvers === null || lastConvers === null) {
            return <p className="timeFrame">No conversations</p>;
        }
        if (firstConvers === lastConvers) {
            return (
                <p className="timeFrame">
                    On{' '}
                    {moment.unix(firstConvers).format('YYYY MMMM D - HH:mm:ss')}
                </p>
            );
        }
        return (
            <p className="timeFrame">
                From{' '}
                {moment.unix(firstConvers).format('YYYY MMMM D - HH:mm:ss')} to{' '}
                {moment.unix(lastConvers).format('YYYY MMMM D - HH:mm:ss')}
            </p>
        );
    };

    const refreshContainerClassName = cx('activity-refresh-container', {
        'activity-refresh-container--not-refreshing': !isRefreshing,
    });

    const refreshingIconClassName = cx(
        'activity-refresh-container__refreshing-icon',
        {
            'activity-refresh-container__refreshing-icon--refreshing':
                isRefreshing && !isLoadingMore,
        },
    );

    const [first, last] = get_first_and_last_conversation(conversations);
    return (
        <header>
            <h1>
                Conversations
                {showTimeFrame(first, last)}
            </h1>
            <div className={refreshContainerClassName} onClick={onRefresh}>
                <Refresh size={20} className={refreshingIconClassName} />
                <h6>
                    {isRefreshing && !isLoadingMore ? 'Refreshing' : 'Refresh'}
                </h6>
            </div>
        </header>
    );
};
