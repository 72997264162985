import React from 'react';
import {Field} from 'redux-form';
import {reduxForm} from 'redux-form';
import {Button, KareTextInput} from '@app/common';
import {
    passwordMatchValidation,
    passwordStrengthValidation,
} from '@app/common/password-validation';
import {Prompt} from 'react-router-dom';
import {validators} from '@app/utils';
import {MdClose} from 'react-icons/md';

interface PasswordResetFormProps {
    handleSubmit?: any;
    pristine?: boolean;
    dirty?: boolean;
    submitting?: boolean;
    error?: string;
    onCloseModal: any;
}

export class PasswordResetFormComponent extends React.Component<PasswordResetFormProps> {
    render() {
        const {handleSubmit, pristine, error, dirty, submitting, onCloseModal} =
            this.props;
        return (
            <form
                onSubmit={handleSubmit}
                className="settings__password-reset-form"
            >
                <h1>
                    Change password{' '}
                    <MdClose
                        onClick={() => {
                            onCloseModal();
                        }}
                    />
                </h1>
                <Prompt
                    message="Discard unsaved changes?"
                    when={dirty && !submitting}
                />
                {error && <p className="kare-text-error">{error}</p>}
                <p>
                    Your password must be between 8 and 24 characters long, and
                    include at least one uppercase letter, one lowercase letter,
                    one number and one symbol.
                </p>
                <Field
                    name="currentPassword"
                    component={KareTextInput}
                    placeholder="Current Password"
                    type="password"
                    props={{tight: true}}
                    validate={validators.required}
                />
                <Field
                    name="password"
                    component={KareTextInput}
                    placeholder="Password"
                    validate={passwordStrengthValidation}
                    type="password"
                    props={{tight: true}}
                />
                <Field
                    name="passwordConfirm"
                    component={KareTextInput}
                    placeholder="Repeat Password"
                    validate={[passwordMatchValidation]}
                    type="password"
                    props={{tight: true}}
                />
                <section className="submit-button-container">
                    <Button disabled={pristine} onClick={handleSubmit}>
                        Update
                    </Button>
                </section>
            </form>
        );
    }
}

export const PasswordResetForm = reduxForm({
    form: 'passwordReset',
    enableReinitialize: true,
    passwordMatchValidation,
})(PasswordResetFormComponent);
