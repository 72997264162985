import {CHANGE_SELECTED_CONVERSATION} from '../constants';

export const changeSelectedConversation = (id) => {
    return {
        type: CHANGE_SELECTED_CONVERSATION,
        payload: {
            id,
        },
    };
};
