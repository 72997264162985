import React from 'react';

interface PortalMainProps {
    children?: any;
    className?: string;
}

/**
 * Reusable sidebar for the portal
 */
export class PortalMain extends React.Component<PortalMainProps> {
    render() {
        const {className} = this.props;
        return (
            <section
                className={`${
                    className ? className + ' ' : ''
                }knowledge-base__portal-main`}
            >
                {this.props.children}
            </section>
        );
    }
}
