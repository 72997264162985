import React, {useContext, useEffect, useState} from 'react';
import {values} from 'lodash';

import './Clickthrough.scss';
import {Table} from '@app/components/Table/Table';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {Card} from '@app/components/Card/Card';
import {StoreContext} from '@app/state';
import {calcRatio} from '@app/utils/Common';

interface IClickthroughProps {
    history: any;
}

interface ClickThroughData {
    data: any;
    loading: boolean;
    error: string;
}

const initialDataState = {
    data: {},
    loading: true,
    error: '',
};

export const Clickthrough: React.FunctionComponent<IClickthroughProps> = ({
    history,
}) => {
    const {analytics, date} = useContext(StoreContext);
    const {minDate, maxDate} = useContext(StoreContext).date;
    const [data, setData] = useState<ClickThroughData>(initialDataState);
    const getData = async () => {
        const response = await analytics.getAggregatedExternalLinks(
            minDate,
            maxDate,
        );
        if (response.data) {
            setData({
                loading: false,
                error: '',
                data: response.data,
            });
        } else {
            setData({
                loading: false,
                error: response.error || '',
                data: {},
            });
        }
    };

    useEffect(() => {
        getData();
    }, [minDate, maxDate]);

    const showEmptyState = () => {
        if (!data?.data?.entries || values(data.data.entries).length === 0) {
            return 'empty';
        }
        return '';
    };

    return (
        <Card>
            <div className="Clickthrough__titleContainer">
                <CardTitle
                    title="Traffic sources"
                    className="Clickthrough__title"
                />
            </div>
            <ErrorLoadingWrapper
                height={'400px'}
                isLoading={data.loading}
                error={data.error}
                emptyState={showEmptyState()}
                render={() => {
                    return (
                        <Table
                            showPercentage={false}
                            showPosition={false}
                            maxRowsVisible={values(data.data.entries).length}
                            columns={[
                                'source',
                                'impressions',
                                'clicks',
                                'clickthrough',
                            ]}
                            rows={values(data.data.entries).map((e) => {
                                return {
                                    values: [
                                        {value: e.url},
                                        {value: e.counters.impressions},
                                        {value: e.counters.clicks},
                                        {
                                            value: `${calcRatio(
                                                e.counters.clicks,
                                                e.counters.impressions,
                                            )}%`,
                                        },
                                    ],
                                    click: () => {
                                        window.open(e.url, '_blank');
                                    },
                                };
                            })}
                        />
                    );
                }}
            />
        </Card>
    );
};
