import {METHODS} from '../constants';

import {REGISTER, VALIDATE_SUBDOMAIN, VERIFY_ACCOUNT} from './constants';

const {POST} = METHODS;

export const rest = {
    [REGISTER]: {
        url: '/v3.0/settings/org',
        options: {
            method: POST,
        },
    },
    [VALIDATE_SUBDOMAIN]: {
        url: '/v3.0/settings/org/validate-subdomain',
        options: {
            method: POST,
        },
    },
    [VERIFY_ACCOUNT]: {
        url: '/v3.0/settings/org/activate',
        options: {
            method: POST,
        },
    },
};
