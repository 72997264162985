import React from 'react';
import {Field, Form} from 'react-final-form';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import './_index.scss';
import {LOGIN_FORM} from '../../constants';
import {Button, CheckboxInput, KareTextInput} from '@app/common';
import {getSubdomainFromURL} from '@app/utils/client-url';
import {getZone} from '@app/zone';
import {getSuccessUrl} from '../../utils';
import {REMEMBER_ME} from '@app/redux/user/constants';
import {get} from 'lodash';
import {getCaptchaToken} from '@app/utils/recaptcha/recaptcha';
import {setCookie, eraseCookie} from '@app/utils/auth';
import {toast} from 'react-toastify';
import {passwordGrantDispatcher} from '@app/redux/user/dispatchers';

const isRequired = (value) => (value ? undefined : 'Required');

interface LoginFormProps {
    pristine?: any;
    zone?: string;
    subdomain?: string;
    values?: any;
    invalid?: boolean;
    submitting?: boolean;
    login?: (payload: {}) => any;
    location?: {search: string};
    history?: {
        push?: any;
        replace?: any;
    };
    initialValues?: any;
}

const LoginFormComponent: React.FC<LoginFormProps> = (props) => {
    const {login, location, history, initialValues} = props;

    return (
        <Form
            onSubmit={(values) => {
                getCaptchaToken('login', (token) => {
                    const payload = Object.assign(
                        {captcha_token: token},
                        values,
                    );

                    login(payload)
                        .then(() => {
                            eraseCookie(REMEMBER_ME);
                            setCookie(
                                REMEMBER_ME,
                                get(payload, REMEMBER_ME, false),
                            );
                            const successURL = getSuccessUrl(location.search);
                            history.push(successURL);
                            window.location.reload();
                        })
                        .catch((e) => {
                            const error = e?.error;
                            error.includes('ResourceExhausted')
                                ? toast.error('Account is locked')
                                : toast.error('Password or email is incorrect');
                        });
                });
            }}
            initialValues={initialValues}
        >
            {(props) => (
                <form className="login-form" onSubmit={props.handleSubmit}>
                    {process.env.START && (
                        <Field
                            component={KareTextInput}
                            name="subdomain"
                            label="Company domain"
                            validate={isRequired}
                        />
                    )}
                    <Field
                        component={KareTextInput}
                        name="email"
                        label="Email address"
                        validate={isRequired}
                    />
                    <Field
                        component={KareTextInput}
                        name="password"
                        label="Password"
                        type="password"
                        validate={isRequired}
                    />
                    <Field
                        name={REMEMBER_ME}
                        type="checkbox"
                        component={CheckboxInput}
                        label={'Remember me'}
                    />
                    <Button
                        className="loginButton"
                        disabled={props.submitting || props.pristine}
                    >
                        Login
                    </Button>
                    <Link className="forgotPasswordLink" to="/reset-password/">
                        Forgot password?
                    </Link>
                    <div className="content-recaptcha-branding">
                        This site is protected by reCAPTCHA and the Google
                        <a href="https://policies.google.com/privacy">
                            {' '}
                            Privacy Policy{' '}
                        </a>{' '}
                        and
                        <a href="https://policies.google.com/terms">
                            {' '}
                            Terms of Service{' '}
                        </a>
                        apply.
                    </div>
                </form>
            )}
        </Form>
    );
};

const mapStateToProps = (state) => {
    return {
        zone: getZone(),
        subdomain: getSubdomainFromURL(),
        initialValues: {
            subdomain: getSubdomainFromURL(),
        },
        values: get(state, ['form', 'LOGIN_FORM', 'values'], {}),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (params) => passwordGrantDispatcher(dispatch, params),
    };
};

export const LoginForm = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(LoginFormComponent),
);
