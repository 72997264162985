import {getCookie} from '../auth';

export const getCaptchaToken = (action, func) => {
    //@ts-ignore
    window.grecaptcha
        .execute(getCookie('GOOGLE_RECAPTCHA_TOKEN'), {action})
        .then((token) => {
            func(token);
        });
};
