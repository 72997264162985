import React from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import {MdAttachFile} from 'react-icons/md';
import {BlockControls} from '../block-controls';
import {BLOCK_KEYS, BLOCK_TYPES} from '@app/constants';

interface AttachmentsBlockFieldProps {
    input?: any;
    selectDocument?: any;
    activateBlock?: any;
    removeBlock?: any;
    fields?: any;
    index?: number;
}

export const AttachmentsBlockField = (props: AttachmentsBlockFieldProps) => {
    const {index, fields, removeBlock, activateBlock, input} = props;
    const attachmentBlock = get(input, ['value', BLOCK_KEYS.ATTACHMENT_BLOCK]);
    const blockClassname = cx(
        'kare-block kare-block--inactive kare-block-attachment-section',
    );
    return (
        <div className="kare-block-wrapper">
            <section className={blockClassname}>
                {attachmentBlock[BLOCK_TYPES.ATTACHMENTS].map((a) => {
                    return (
                        <div
                            key={a.id}
                            className="attachment-block-field-container"
                        >
                            <div className="attachment-block-field-title-container">
                                <MdAttachFile size={10} />
                                <div className="attachment-block-field-text-container">
                                    {a.title}
                                    <div className="attachment-block-field-filename">
                                        {a.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </section>
            {index >= 0 && fields && removeBlock && (
                <BlockControls
                    index={index}
                    fields={fields}
                    removeBlock={removeBlock}
                    activateBlock={activateBlock}
                />
            )}
        </div>
    );
};
