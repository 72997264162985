import React, {useEffect, useState} from 'react';
import {BarChart} from '@app/components/BarChart/BarChart';
import {calcRatio, formatThousands} from '@app/utils/Common';
import {transformConversationsDataToBarChart} from '@app/utils/ConversationDataTransform';
import {
    DATA_KEYS,
    getChartColor,
    RESOURCE_KEYS,
} from '@app/utils/DataRenderingUtils';
import './ActivityBarChart.scss';
import {EmptyState} from '@app/components/EmptyState/EmptyState';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {BarChartContentLoader} from '@app/components/ContentLoaders/BarChartContentLoader';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {get} from 'lodash';
import {Moment} from 'moment';
import {
    conversationsTimeseriesErrorSelector,
    getConversationsTimeseriesSelector,
    isConversationsTimeseriesFetchingSelector,
} from '@app/redux/conversations/selectors';
import {dateSelector} from '@app/redux/application/selectors';
import {redux} from '@app/redux';
import {GET_CONVERSATIONS_TIMESERIES} from '@app/redux/conversations/constants';
import {connect} from 'react-redux';

interface IActivityBarChartProps {
    date: {
        minDate: Moment;
        maxDate: Moment;
    };
    getConversations: any;
    conversationsSelector: any;
    conversationsErrorSelector: boolean;
    conversationsLoadingSelector: boolean;
}

interface ActivityBarData {
    data: {conversations: any};
    loading: boolean;
    error: string;
}

const initialDataState: ActivityBarData = {
    data: {conversations: {}},
    loading: true,
    error: '',
};
const ActivityBarChartComponent = (props: IActivityBarChartProps) => {
    const selectedTab = RESOURCE_KEYS.CONVERSATIONS;
    const [data, setData] = useState<ActivityBarData>(initialDataState);
    const {
        date,
        getConversations,
        conversationsSelector,
        conversationsErrorSelector,
        conversationsLoadingSelector,
    } = props;

    const getBarChartKeys = (selectedTab: string) => {
        switch (selectedTab) {
            case RESOURCE_KEYS.CONVERSATIONS:
                return [
                    DATA_KEYS.RESOLVED,
                    DATA_KEYS.HANDED,
                    DATA_KEYS.ESCALATED,
                    DATA_KEYS.UNRESOLVED,
                ];
            case RESOURCE_KEYS.QUERIES:
                return [RESOURCE_KEYS.QUERIES];
            default:
                return [
                    DATA_KEYS.RESOLVED,
                    DATA_KEYS.HANDED,
                    DATA_KEYS.ESCALATED,
                    DATA_KEYS.UNRESOLVED,
                ];
        }
    };

    const showEmptyState = () => {
        if (
            !data ||
            !data.data ||
            !data.data.conversations ||
            !data.data.conversations.counters ||
            data.data.conversations.counters.length == 0
        ) {
            return 'empty';
        }

        return '';
    };

    useEffect(() => {
        if (!conversationsLoadingSelector && date?.minDate && date?.maxDate) {
            getConversations(date.minDate, date.maxDate);
        }
    }, [date]);

    useEffect(() => {
        setData({
            loading: conversationsLoadingSelector,
            error: conversationsErrorSelector ? 'Error' : '',
            data: {conversations: conversationsSelector},
        });
    }, [
        conversationsLoadingSelector,
        conversationsErrorSelector,
        conversationsSelector,
    ]);

    const percentageTotal = (data) => {
        return data.reduce((acc, value) => {
            const escalated = get(value, 'escalated', 0);
            const handed = get(value, 'handed', 0);
            const resolved = get(value, 'resolved', 0);
            const unresolved = get(value, 'unresolved', 0);
            return acc + escalated + handed + resolved + unresolved;
        }, 0);
    };

    return (
        <ErrorLoadingWrapper
            height={'443px'}
            isLoading={data.loading}
            error={data.error}
            emptyState={showEmptyState()}
            CustomLoading={BarChartContentLoader}
            render={() => {
                const convData = transformConversationsDataToBarChart(
                    data.data.conversations,
                );
                const getBarChartData = (selectedTab: string) => {
                    switch (selectedTab) {
                        case RESOURCE_KEYS.CONVERSATIONS:
                            return convData.counters;
                        default:
                            return convData.counters;
                    }
                };
                const charData = getBarChartData(selectedTab);
                const allTotal = percentageTotal(charData);

                return (
                    <div className="ActivityBarChart__container">
                        <div className="ActivityBarChart__header">
                            <ul>
                                <li>
                                    <span>
                                        <span>All conversations</span>
                                    </span>
                                    <strong>{formatThousands(allTotal)}</strong>
                                </li>
                                <li>
                                    <span
                                        className="ActivityBarChart__subtitle"
                                        style={{
                                            color: getChartColor(
                                                DATA_KEYS.RESOLVED,
                                            ),
                                        }}
                                    >
                                        <FiberManualRecordIcon />{' '}
                                        <span>Resolved</span>
                                    </span>
                                    <strong>
                                        {calcRatio(
                                            convData.totals.resolved,
                                            allTotal,
                                        )}{' '}
                                        <span>%</span>
                                    </strong>
                                    <small>
                                        {formatThousands(
                                            convData.totals.resolved,
                                        )}
                                    </small>
                                </li>
                                <li>
                                    <span
                                        className="ActivityBarChart__subtitle"
                                        style={{
                                            color: getChartColor(
                                                DATA_KEYS.ESCALATED,
                                            ),
                                        }}
                                    >
                                        <FiberManualRecordIcon />{' '}
                                        <span>Escalated</span>
                                    </span>
                                    <strong>
                                        {calcRatio(
                                            convData.totals.escalated,
                                            allTotal,
                                        )}{' '}
                                        <span>%</span>
                                    </strong>
                                    <small>
                                        {formatThousands(
                                            convData.totals.escalated,
                                        )}
                                    </small>
                                </li>
                                <li>
                                    <span
                                        className="ActivityBarChart__subtitle"
                                        style={{
                                            color: getChartColor(
                                                DATA_KEYS.HANDED,
                                            ),
                                        }}
                                    >
                                        <FiberManualRecordIcon />{' '}
                                        <span>Handed over</span>
                                    </span>
                                    <strong>
                                        {calcRatio(
                                            convData.totals.handover,
                                            allTotal,
                                        )}{' '}
                                        <span>%</span>
                                    </strong>
                                    <small>
                                        {formatThousands(
                                            convData.totals.handover,
                                        )}
                                    </small>
                                </li>
                                <li>
                                    <span
                                        className="ActivityBarChart__subtitle"
                                        style={{
                                            color: getChartColor(
                                                DATA_KEYS.UNRESOLVED,
                                            ),
                                        }}
                                    >
                                        <FiberManualRecordIcon />{' '}
                                        <span>Unresolved</span>
                                    </span>
                                    <strong>
                                        {calcRatio(
                                            convData.totals.unresolved,
                                            allTotal,
                                        )}{' '}
                                        <span>%</span>
                                    </strong>
                                    <small>
                                        {formatThousands(
                                            convData.totals.unresolved,
                                        )}
                                    </small>
                                </li>
                            </ul>
                        </div>
                        <div className="ActivityBarChart__chart">
                            {charData.length > 0 ? (
                                <BarChart
                                    data={charData}
                                    keys={getBarChartKeys(selectedTab)}
                                    index={'timeseries'}
                                    tickRotation={65}
                                />
                            ) : (
                                <EmptyState className="ActivityBarChart__empty" />
                            )}
                        </div>
                    </div>
                );
            }}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        conversationsSelector: getConversationsTimeseriesSelector(state),
        conversationsLoadingSelector:
            isConversationsTimeseriesFetchingSelector(state),
        conversationsErrorSelector: conversationsTimeseriesErrorSelector(state),
        date: dateSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getConversations: (minDate: Moment, maxDate: Moment) =>
            dispatch(
                redux.rest.actions[GET_CONVERSATIONS_TIMESERIES].force({
                    minDate: minDate.unix(),
                    maxDate: maxDate.unix(),
                }),
            ),
    };
};

export const ActivityBarChart = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ActivityBarChartComponent as any);
