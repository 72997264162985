import React from 'react';

export interface HandedContentLoaderProps {}

export const HandedContentLoader: React.FC<HandedContentLoaderProps> = (
    props,
) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="169"
            height="170"
            viewBox="0 0 169 170"
        >
            <defs>
                <rect
                    id="path-1"
                    width="200"
                    height="320"
                    x="0"
                    y="0"
                    rx="2"
                ></rect>
                <filter
                    id="filter-2"
                    width="103.5%"
                    height="102.2%"
                    x="-1.8%"
                    y="-.8%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="1"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
                    ></feColorMatrix>
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-205 -144)">
                    <g transform="translate(190 114)">
                        <g>
                            <use
                                fill="#000"
                                filter="url(#filter-2)"
                                xlinkHref="#path-1"
                            ></use>
                            <use fill="#FFF" xlinkHref="#path-1"></use>
                        </g>
                        <g transform="translate(15 30)">
                            <rect
                                width="60"
                                height="9"
                                x="55"
                                y="58"
                                fill="#DFE1E1"
                                rx="4"
                            ></rect>
                            <g fill="#E0E0E0" transform="translate(5)">
                                <g>
                                    <path d="M80 0c44.183 0 80 35.817 80 80h-22.857c0-31.56-25.584-57.143-57.143-57.143-31.56 0-57.143 25.584-57.143 57.143H0C0 35.817 35.817 0 80 0z"></path>
                                </g>
                            </g>
                            <rect
                                width="90"
                                height="9"
                                x="40"
                                y="96"
                                fill="#DFE1E1"
                                rx="4"
                            ></rect>
                            <rect
                                width="90"
                                height="9"
                                x="40"
                                y="110"
                                fill="#DFE1E1"
                                rx="4"
                            ></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
