import React from 'react';
import get from 'lodash/get';
import {MdAttachment} from 'react-icons/md';

interface MessageAttachmentProps {
    message: any;
}

export const MessageAttachment = (props: MessageAttachmentProps) => {
    const {message} = props;
    const action = get(message, 'event_body.out_text.actions[0]', '');
    const buttonText = get(action, 'text', '');
    const href = get(action, ['on_click', '2', 'params', 'href'], '');
    return (
        <div
            className={
                'console-activity-message-container message-attachment__container console-activity--out'
            }
        >
            <div className="message-attachment__body">
                <MdAttachment />
                <a href={href} target="_blank" rel="noreferrer">
                    {buttonText}
                </a>
            </div>
        </div>
    );
};
