import {get, values} from 'lodash';

import {DATA, ERROR, LOADING} from '../../constants';
import {
    CONVERSATIONS_STATE_FIELD,
    GET_AGGREGATED_CONVERSATIONS_TIMESERIES,
    GET_CONVERSATION_MESSAGES,
    GET_CONVERSATIONS,
    GET_CONVERSATIONS_TIMESERIES,
    META_DATA,
    SELECTED_CONVERSATION_ID,
} from '../constants';
import {IN_HI} from '@app/activity/messages/message-factory/utils';

export const conversationsSelector = (state) => {
    const conversationsObject = get(
        state,
        [GET_CONVERSATIONS, DATA, CONVERSATIONS_STATE_FIELD],
        {},
    );
    return values(conversationsObject).sort(
        (a, b) => b.last_message_time - a.last_message_time,
    );
};

export const conversationsNextPageSelector = (state) => {
    return get(state, [GET_CONVERSATIONS, DATA, 'next_cursor'], '');
};

export const conversationsEndListSelector = (state) => {
    return get(state, [GET_CONVERSATIONS, DATA, 'endList'], false);
};

export const conversationMessagesSelector = (state) =>
    get(state, [GET_CONVERSATION_MESSAGES, DATA, 'events'], []).filter(
        (message) => message.uri !== IN_HI,
    );

export const isConversationMessagesFetchingSelector = (state) => {
    return get(state, [GET_CONVERSATION_MESSAGES, LOADING]);
};

export const isConversationMessagesErrorSelector = (state) => {
    const error = get(state, [GET_CONVERSATION_MESSAGES, ERROR]);
    return error !== null && error !== undefined;
};

export const areConversationsFetchingSelector = (state) => {
    return get(state, [GET_CONVERSATIONS, LOADING]) === true;
};

export const conversationsErrorSelector = (state) => {
    return get(state, [GET_CONVERSATIONS, ERROR]);
};

export const getSelectedConversationId = (state) => {
    return get(state, ['conversations', SELECTED_CONVERSATION_ID]);
};

export const getSelectedConversationMetaData = (state) => {
    return get(state, [
        GET_CONVERSATIONS,
        DATA,
        CONVERSATIONS_STATE_FIELD,
        getSelectedConversationId(state),
        META_DATA,
    ]);
};

export const getSelectedConversationUserId = (state) => {
    return get(state, [
        GET_CONVERSATIONS,
        DATA,
        CONVERSATIONS_STATE_FIELD,
        getSelectedConversationId(state),
        'user_id',
    ]);
};

export const getSelectedConversationStatus = (state) => {
    return get(state, [
        GET_CONVERSATIONS,
        DATA,
        CONVERSATIONS_STATE_FIELD,
        getSelectedConversationId(state),
        'status',
    ]);
};

export const getAggregatedConversationsTimeseriesSelector = (state) => {
    return get(state, [GET_AGGREGATED_CONVERSATIONS_TIMESERIES, DATA]);
};

export const isAggregatedConversationsTimeseriesFetchingSelector = (state) => {
    return get(state, [GET_AGGREGATED_CONVERSATIONS_TIMESERIES, LOADING]);
};

export const aggregatedConversationsTimeseriesErrorSelector = (state) => {
    return get(state, [GET_AGGREGATED_CONVERSATIONS_TIMESERIES, ERROR]);
};

export const getConversationsTimeseriesSelector = (state) => {
    return get(state, [GET_CONVERSATIONS_TIMESERIES, DATA]);
};

export const isConversationsTimeseriesFetchingSelector = (state) => {
    return get(state, [GET_CONVERSATIONS_TIMESERIES, LOADING]);
};

export const conversationsTimeseriesErrorSelector = (state) => {
    return get(state, [GET_CONVERSATIONS_TIMESERIES, ERROR]);
};
