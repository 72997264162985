import {ACTIONS} from './constants';

/**
 * Initial state for the knowledgeBase module.
 */
const initialState = {
    activeResponseId: null,
};

/**
 * The reducer for the knowledgeBase module.
 * @param {Object} state
 * @param {String} action
 */
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SET_ACTIVE_RESPONSE_ID:
            return {
                ...state,
                activeResponseId: action.activeResponseId,
            };

        default:
            return state;
    }
};
