import React from 'react';
import {Field} from 'redux-form';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';

import {RESET_PASSWORD_EMAIL_FORM} from './constants';
import {KareTextInput} from '@app/common';

import {getSubdomainFromURL} from '@app/utils/client-url';

interface ResetPasswordEmailFormProps {
    handleSubmit: any;
    pristine?: boolean;
    subdomain?: string;
}

import {Form, StyledButton} from './resetPasswordEmailForm.styles';

const isRequired = (value) => (value ? undefined : 'Required');

export class ResetPasswordEmailFormComponent extends React.Component<ResetPasswordEmailFormProps> {
    render() {
        const {handleSubmit, pristine, subdomain} = this.props;
        return (
            <Form onSubmit={handleSubmit}>
                {!subdomain && (
                    <Field
                        component={KareTextInput}
                        name="subdomain"
                        label="Domain"
                        validate={isRequired}
                    />
                )}
                <Field
                    component={KareTextInput}
                    name="email"
                    label="Email"
                    validate={isRequired}
                />
                <StyledButton disabled={pristine} onClick={handleSubmit}>
                    Send reset password email
                </StyledButton>
            </Form>
        );
    }
}

const mapStateToProps = () => {
    return {
        subdomain: getSubdomainFromURL(),
        initialValues: {
            subdomain: getSubdomainFromURL(),
        },
    };
};

export const ResetPasswordEmailForm = connect(mapStateToProps)(
    reduxForm({
        form: RESET_PASSWORD_EMAIL_FORM,
    })(ResetPasswordEmailFormComponent),
);
