import React, {useCallback, useState} from 'react';
import {get} from 'lodash';
import axios, {AxiosResponse} from 'axios';
import ReactPlayer from 'react-player';
import Modal from 'react-modal';
import {Form} from 'react-final-form';
import {useDropzone} from 'react-dropzone';
import cx from 'classnames';
import {connect} from 'react-redux';

import {BlockControls} from '../block-controls';
import {Button} from '@app/common';
import {Link} from '@app/common/icons';
import {MODAL_BASE_CONFIG} from '@app/utils/modals';
import {redux} from '@app/redux';
import {UPLOAD_ASSET} from '@app/redux/knowledge-base/constants';

interface VideoBlockFieldComponentProps {
    removeBlock?: any;
    fields?: any;
    index?: number;
    input?: any;
}

export const renderInactiveVideo = (url) => {
    return (
        <div className="kare-block kare-block--inactive">
            <div className="kare-video-block-player-wrapper">
                <ReactPlayer
                    url={url}
                    width="100%"
                    height="100%"
                    controls={true}
                />
            </div>
            <div className="media-block-url">
                <Link size={10} />
                <span>{url}</span>
            </div>
        </div>
    );
};

export const VideoBlockFieldComponent = (
    props: VideoBlockFieldComponentProps,
) => {
    const {removeBlock, fields, index, input} = props;
    const onDrop = useCallback((videos) => {
        setVideo(videos[0]);
        const reader = new FileReader();
        reader.onload = (e) => {
            setThumb(e.target.result);
        };
        reader.readAsDataURL(videos[0]);
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
    });

    const [activeDragAnDrop, setActiveDragAnDrop] = useState(true);
    const [video, setVideo] = useState(
        get(input, ['value', 'video_block', 'url'], ''),
    );
    const [thumb, setThumb] = useState(null);
    const [url, setUrl] = useState(
        get(input, ['value', 'video_block', 'url'], ''),
    );
    const [active, setActive] = useState<boolean>(!url);
    const handleClickCancel = () => {
        setThumb(null);
        setVideo(null);
        setActive(false);
    };
    const handleClickAdd = (videoUrl) => {
        const {
            input: {onChange},
        } = props;
        onChange({type: 'video', video_block: {url: videoUrl}});
        setActive(false);
    };

    const handleChange = (event) => {
        const value = event.target.value;
        setUrl({url: value});
    };

    const activateBlock = () => {
        const {input} = props;
        const url = get(input, ['value', 'video_block', 'url'], '');
        setActive(true);
        setUrl(url);
    };
    const formUrl = get(input, ['value', 'video_block', 'url'], '');

    const name = 'video_url';

    return (
        <div className="kare-block-wrapper">
            {renderInactiveVideo(formUrl)}
            <BlockControls
                index={index}
                fields={fields}
                removeBlock={removeBlock}
                activateBlock={activateBlock}
            />
            {active && (
                <Modal
                    isOpen
                    style={{
                        ...MODAL_BASE_CONFIG,
                        content: {
                            ...MODAL_BASE_CONFIG.content,
                            width: '360px',
                            height: '370px',
                            border: '0',
                        },
                    }}
                >
                    <Form
                        onSubmit={() => {
                            const formData = new FormData();
                            if (video) {
                                formData.append('asset', video);
                                axios
                                    .post(`v3.1/kbm/assets/videos`, formData)
                                    .then((response: AxiosResponse<any>) => {
                                        if (
                                            response &&
                                            response.data &&
                                            response.data.url
                                        ) {
                                            handleClickAdd(response.data.url);
                                        }
                                    });
                            }
                        }}
                        render={(renderProps) => {
                            return (
                                <section className="media-block-modal video-block-modal">
                                    <h1>
                                        {formUrl && 'Update video'}
                                        {!formUrl && 'Choose a video to add'}
                                    </h1>
                                    <div className="asset-upload__selector">
                                        <span
                                            className={cx(
                                                'asset-upload__selector-btn',
                                                {
                                                    active: activeDragAnDrop,
                                                },
                                            )}
                                            onClick={() => {
                                                setActiveDragAnDrop(true);
                                            }}
                                        >
                                            Upload
                                        </span>
                                        <span
                                            className={cx(
                                                'asset-upload__selector-btn',
                                                {
                                                    active: !activeDragAnDrop,
                                                },
                                            )}
                                            onClick={() => {
                                                setActiveDragAnDrop(false);
                                            }}
                                        >
                                            Video from Url
                                        </span>
                                    </div>
                                    {formUrl && (
                                        <div
                                            className="media-block-modal-current-image"
                                            title={formUrl}
                                        >
                                            {formUrl}
                                        </div>
                                    )}
                                    {activeDragAnDrop && (
                                        <div className="assets-uploader">
                                            <div
                                                {...getRootProps()}
                                                className={cx(
                                                    'assets-uploader__drag',
                                                    {
                                                        active:
                                                            !!thumb ||
                                                            !!formUrl,
                                                        dragging: isDragActive,
                                                    },
                                                )}
                                            >
                                                <input {...getInputProps()} />
                                                {!video && !formUrl && (
                                                    <span className="assets-uploader__drag-add">
                                                        Drag and drop video here
                                                        or <a>click here</a>
                                                    </span>
                                                )}
                                                {video && thumb && (
                                                    <div className="assets-video_preview">
                                                        <strong className="assets-video__file-name">
                                                            {video.name}
                                                        </strong>
                                                        <ReactPlayer
                                                            url={thumb}
                                                            width="100%"
                                                            height="100%"
                                                            controls
                                                        />
                                                    </div>
                                                )}
                                                {formUrl && !thumb && (
                                                    <div className="assets-video_preview">
                                                        <ReactPlayer
                                                            url={formUrl}
                                                            width="100%"
                                                            height="100%"
                                                            controls
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {!activeDragAnDrop && (
                                        <div>
                                            <label
                                                htmlFor={name}
                                                className="kare-text-input__label"
                                            >
                                                Video URL
                                            </label>
                                            <input
                                                placeholder="https://www.mypage.com/video.mpg"
                                                type="text"
                                                key={name}
                                                id={name}
                                                value={get(url, 'url')}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    )}
                                    <div className="media-block-modal-buttons">
                                        <Button
                                            onClick={handleClickCancel}
                                            outlined
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                if (!activeDragAnDrop) {
                                                    handleClickAdd(
                                                        get(url, 'url'),
                                                    );
                                                } else {
                                                    renderProps.form.submit();
                                                }
                                            }}
                                        >
                                            {formUrl && 'Update'}
                                            {!formUrl && 'Add'}
                                        </Button>
                                    </div>
                                </section>
                            );
                        }}
                    />
                    {/* TODO(rr): Refactor this out for the video block. */}
                </Modal>
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    uploadAsset: (values) => {
        dispatch(
            redux.rest.actions[UPLOAD_ASSET](
                {},
                {
                    body: values.body,
                },
            ),
        );
    },
});

export const VideoBlockField = connect(
    null,
    mapDispatchToProps,
)(VideoBlockFieldComponent);
