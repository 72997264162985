import React, {Fragment} from 'react';
import ReactMarkdown from 'react-markdown';
import {splitByIframe, iframeRegex} from './utils';
import remarkGfm from 'remark-gfm';

const sanitizeMarkdown = (md) =>
    md
        .split('\n')
        .filter((t) => t !== '')
        .join('\n\n');

interface MarkdownProps {
    children?: any;
}

/**
 * Reusable Markdown to HTML component.
 * Expects markdown as its children.
 */

export class Markdown extends React.Component<MarkdownProps> {
    render() {
        let text = this.props.children;

        if (!text) {
            return null;
        }
        text = sanitizeMarkdown(text);
        const parts = splitByIframe(text);
        return (
            <Fragment>
                {parts.map((part) => {
                    if (part.match(iframeRegex)) {
                        return (
                            <div
                                key={part}
                                className="chat__text-message-iframe"
                                dangerouslySetInnerHTML={{__html: part}}
                            />
                        );
                    }
                    return (
                        <ReactMarkdown
                            key={part}
                            className="markdown"
                            remarkPlugins={[remarkGfm]}
                        >
                            {part}
                        </ReactMarkdown>
                    );
                })}
            </Fragment>
        );
    }
}
