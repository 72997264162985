import * as clientUrl from './client-url';
import * as dom from './dom';
import * as notifications from './notifications';
import * as querystring from './querystring';
import * as url from './url';
import * as validators from './validators';
import * as locale from './locale';
import * as color from './color';
import * as blocks from './blocks';
import * as permissions from './permissions';

import {auth} from './auth';

/**
 * Main utils API.
 * @example
 * import { api } from '../../utils';
 */
export {
    clientUrl,
    color,
    dom,
    notifications,
    querystring,
    url,
    validators,
    locale,
    auth,
    blocks,
    permissions,
};
