import React, {useEffect, useState} from 'react';
import {reduxFormArrayValueAdapter} from './utils';
import {connect} from 'react-redux';
import {redux} from '@app/redux';
import {getFilteredLabelsSelector} from '@app/redux/knowledge-base/selectors';
import {GET_LABELS} from '@app/redux/knowledge-base/constants';
import {MdEdit, MdLabel} from 'react-icons/md';
import cx from 'classnames';
import {KareLabel} from '@app/common';

interface TagsInputProps {
    input?: any;
    labels?: string[];
    getLabels?: any;
}

/**
 * Tags input tightly coupled with Redux form (all of our forms use it).
 */
const TagsInputComponent = (props: TagsInputProps) => {
    const [isEditingLabels, setIsEditingLabels] = useState(false);

    useEffect(() => {
        props.getLabels();
    }, []);

    /**
     * Use redux-form's onChange method to propagate the delete event.
     */
    const onDeletedLabel = (e, label) => {
        const tags = props.input.value.filter((l) => l.name != label);
        props.input.onChange(tags);
    };

    /**
     * Use redux-form's onChange method to propagate the change event.
     */
    const onSelectedLabel = (e, label) => {
        const tags = [...props.input.value, {name: label}];
        props.input.onChange(tags);
    };

    const {input, labels = []} = props;
    const documentLabels = reduxFormArrayValueAdapter(input).value;
    const documentHasLabels = () => documentLabels.length > 0;
    const iconSize = 20;

    return (
        <div
            className={cx('response-context', {
                'response-context-editing': isEditingLabels,
            })}
        >
            <div className="response-context-label">
                <MdLabel size={iconSize} className="response-context-icons" />
                {documentHasLabels() &&
                    documentLabels.map((l, ind) => (
                        <span
                            className="response-context-label-wrapper"
                            key={`${ind}-${l.name}`}
                        >
                            <KareLabel
                                text={l.name}
                                showRemoveButton={isEditingLabels}
                                handleClose={onDeletedLabel}
                            />
                        </span>
                    ))}
                {isEditingLabels && (
                    <KareLabel
                        showRemoveButton={false}
                        editable
                        onSelectItem={onSelectedLabel}
                        autoCompleteList={labels.map((label, i) => ({
                            label: label,
                            id: `d-${i}-${label}`,
                        }))}
                    />
                )}
                {!documentHasLabels() && !isEditingLabels && (
                    <span className="response-context-text">
                        <b>No labels</b>
                    </span>
                )}
            </div>
            {isEditingLabels && (
                <b
                    className="response-context-save-text"
                    onClick={() => {
                        setIsEditingLabels(false);
                    }}
                >
                    DONE
                </b>
            )}
            {!isEditingLabels && (
                <MdEdit
                    size={iconSize}
                    className="response-context-edit-icon"
                    onClick={() => {
                        setIsEditingLabels(true);
                    }}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    labels: getFilteredLabelsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    getLabels: () => {
        dispatch(redux.rest.actions[GET_LABELS]());
    },
});

export const TagsInput = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TagsInputComponent);
