import React from 'react';
import {assignStaticMessageToChatterMessage, deleteStaticMessage} from './api';
import {ListResponses} from './ListResponses';
import {Message, Response} from './models';
import {Modal} from '@app/components/modals/generic/Modal';

interface StaticMessagePortalProps {
    message: Message;
    onClose: (...args: any) => any;
    onSuccess: (...args: any) => any;
    selectedCampaign?: any;
    hasCloseIcon?: boolean;
}

export const StaticMessagePortal: React.FC<StaticMessagePortalProps> = ({
    message,
    onClose,
    hasCloseIcon,
    selectedCampaign,
    onSuccess,
}) => {
    const [activeResponse, setActiveResponse] = React.useState(message.node_id);
    return (
        <Modal
            closeModal={onClose}
            title={`Select a default message for "${message.title}"`}
            hasCloseIcon={hasCloseIcon}
        >
            <ListResponses
                activeResponse={activeResponse}
                onClearResponse={() => {
                    deleteStaticMessage(
                        message.key,
                        () => {
                            onSuccess(selectedCampaign);
                        },
                        selectedCampaign,
                    );
                }}
                onResponseSelected={(response: Response) => {
                    setActiveResponse(response.id);
                    assignStaticMessageToChatterMessage(
                        message.key,
                        response.id,
                        message.title,
                        () => {
                            onSuccess(selectedCampaign);
                        },
                        selectedCampaign,
                    );
                }}
            />
        </Modal>
    );
};
