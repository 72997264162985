import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {isEmpty, get} from 'lodash';

import {redux} from '@app/redux';
import {DocumentDetail} from './document-detail';
import {
    KnowledgeBasePortal,
    PortalMain,
    PanelBody,
    PanelHeader,
    PanelFooter,
    Button,
    ErrorBoundary,
} from '@app/common';
import {querystring} from '@app/utils';
import {NODE_STATUSES, STATUS} from '@app/constants';
import {RelatedResponses} from '@app/documents/document-portal/related-responses';
import {bulkUpdateStatus} from '@app/documents';
import {updateQueryStringParameter} from '@app/common/kare-status-tabs';
import {GET_BODY_DOCUMENT} from '@app/redux/documents/constants';

interface DocumentPortalProps {
    title: string;
    match: any;
    document: any;
    body: string;
    getDocument: any;
    archiveDocument: any;
    unarchiveDocument: any;
    resetDocument: any;
    deleteDocument: any;
    history: any;
    location: any;
    documentId: any;
    bulkArchiveDocuments: any;
    onClose: any;
}

/**
 * The Portal for response editing.
 * This can be further developed to include the documents as well.
 */
export class DocumentPortalComponent extends React.Component<DocumentPortalProps> {
    state = {
        openRelatedResponses: false,
        isEditingDocument: false,
    };

    componentDidMount() {
        this.props.resetDocument();
        this.props.getDocument(this.getDocumentId(this.props));
    }

    getDocumentId(props) {
        if (props.documentId && props.documentId !== '') {
            return props.documentId;
        } else {
            return props.match.params.documentId;
        }
    }

    componentWillUnmount() {
        this.props.resetDocument();
    }

    componentDidUpdate(prevProps) {
        const prevDocumentId = this.getDocumentId(prevProps);
        const nextDocumentId = this.getDocumentId(this.props);
        if (nextDocumentId !== prevDocumentId) {
            this.props.getDocument(nextDocumentId);
        }
    }

    handleClickDeleted = (document) => () => {
        const {deleteDocument} = this.props;
        const confirmText = `Are you sure you want to delete this content?`;

        if (!confirm(confirmText)) {
            return;
        }
        deleteDocument(document).then(() => {
            this.props.history.push(
                `/library${updateQueryStringParameter(
                    this.props.history.location.search,
                    'status',
                    NODE_STATUSES.ARCHIVED,
                )}`,
            );
        });
    };

    handleClickArchive = (document) => () => {
        const {bulkArchiveDocuments} = this.props;
        const confirmText = `Are you sure you want to archive this content?
It will not be used to automatically respond to your customer. You can still manually link it in your responses.`;
        if (!confirm(confirmText)) {
            return;
        }
        bulkArchiveDocuments([document].map((d) => d.id)).then(() => {
            this.props.history.push(
                `/library${updateQueryStringParameter(
                    this.props.history.location.search,
                    'status',
                    NODE_STATUSES.DRAFT,
                )}`,
            );
        });
    };

    handleClickUnarchive = (document) => () => {
        const {unarchiveDocument} = this.props;
        const confirmText = `Are you sure you want to publish this content?
It will be used to automatically respond to your customer.`;
        if (!confirm(confirmText)) {
            return;
        }
        unarchiveDocument(document).then(() => {
            this.props.history.push(
                `/library?${STATUS}=${NODE_STATUSES.PUBLISHED}`,
            );
        });
    };

    handleClickCreate = (id) => () => {
        const {
            history: {push},
            location,
        } = this.props;
        push(querystring.getNewResponseWithDocumentPath(location, id));
    };

    renderPublishButton = (document) => {
        if (document[STATUS] === NODE_STATUSES.PUBLISHED) {
            return (
                <Button
                    className="knowledge-base-document-button"
                    outlined
                    onClick={this.handleClickArchive(document)}
                >
                    Archive
                </Button>
            );
        }
        return (
            <Button
                className="knowledge-base-document-button"
                outlined
                onClick={this.handleClickUnarchive(document)}
            >
                Publish
            </Button>
        );
    };

    documentBody;

    isDocumentUploaded = (document) => {
        return (
            document &&
            document.type === 'content' &&
            document.content.source === 'uploads'
        );
    };

    /**
     * Return the portal wrapper with the editor form inside.
     */
    render() {
        const {document, location, onClose, body} = this.props;
        this.documentBody = body;
        const url = get(document, ['content', 'url']);
        const panelHeaderTitle = (
            <Fragment>
                View document{' '}
                {document.archived && <Fragment>- [Archived]</Fragment>}
            </Fragment>
        );
        return (
            <ErrorBoundary>
                <KnowledgeBasePortal>
                    <PortalMain>
                        <PanelHeader
                            title={panelHeaderTitle}
                            closeLink={`/library${location.search}`}
                            onClose={onClose}
                        />
                        {document && (
                            <Fragment>
                                <PanelBody>
                                    {this.state.openRelatedResponses && (
                                        <RelatedResponses
                                            onClose={(e) => {
                                                this.setState({
                                                    openRelatedResponses: false,
                                                });
                                            }}
                                            documentId={this.getDocumentId(
                                                this.props,
                                            )}
                                        />
                                    )}
                                    <DocumentDetail
                                        document={document}
                                        body={body}
                                        isEditingDocument={
                                            this.state.isEditingDocument
                                        }
                                    />
                                </PanelBody>
                                {!isEmpty(document) && (
                                    <PanelFooter>
                                        <div className="knowledge-base-document-footer">
                                            {!this.state
                                                .openRelatedResponses && (
                                                <Button
                                                    className="knowledge-base-document-linked-button"
                                                    outlined
                                                    onClick={(e) => {
                                                        this.setState({
                                                            openRelatedResponses:
                                                                true,
                                                        });
                                                    }}
                                                >
                                                    View linked responses
                                                </Button>
                                            )}
                                            {this.renderPublishButton(document)}
                                        </div>
                                    </PanelFooter>
                                )}
                            </Fragment>
                        )}
                    </PortalMain>
                </KnowledgeBasePortal>
            </ErrorBoundary>
        );
    }
}

/**
 * Map redux store state to props for the component.
 * @param {Object} state
 */
const mapStateToProps = (state) => {
    return {
        document: redux.documents.selectors.documentSelector(state),
        body: get(state, [GET_BODY_DOCUMENT, 'data', 'data']),
    };
};

const updateDocument = ({dispatch, status, document}) => {
    return dispatch(
        redux.rest.actions[redux.documents.constants.UPDATE_DOCUMENT](
            {
                id: document.id,
            },
            {
                body: JSON.stringify({
                    ...document,
                    status,
                }),
            },
        ),
    );
};

/**
 * Map action dispatching to component props.
 * Search responses, get all responses and delete a single response.
 * @param {Function} dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getDocument: (id) => {
            return dispatch(
                redux.rest.actions[redux.documents.constants.GET_DOCUMENT]({
                    id,
                }),
            );
        },
        bulkArchiveDocuments: (ids) => {
            return bulkUpdateStatus({
                dispatch,
                ids,
                status: NODE_STATUSES.DRAFT,
            });
        },
        archiveDocument: (document) => {
            return updateDocument({
                dispatch,
                status: NODE_STATUSES.DRAFT,
                document,
            });
        },
        unarchiveDocument: (document) => {
            return updateDocument({
                dispatch,
                status: NODE_STATUSES.PUBLISHED,
                document,
            });
        },
        deleteDocument: (document) => {
            return updateDocument({
                dispatch,
                status: NODE_STATUSES.ARCHIVED,
                document,
            });
        },
        resetDocument: () => {
            return dispatch(
                redux.rest.actions[
                    redux.documents.constants.GET_DOCUMENT
                ].reset() &&
                    redux.rest.actions[
                        redux.documents.constants.GET_BODY_DOCUMENT
                    ].reset(),
            );
        },
    };
};

export const DocumentPortal = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DocumentPortalComponent),
);
