import styled from '@emotion/styled';

import {Button} from '@app/common';

export const Form = styled.form`
    width: 100%;
`;

export const StyledButton = styled(Button)`
    width: 100%;
`;
