import React, {Fragment} from 'react';
import cx from 'classnames';
import {Conversation} from './conversation';
import {getFilteredConversations} from './utils';
import ScrollBar from 'react-perfect-scrollbar';

interface ConversationListProps {
    onEndListReached?: any;
    conversations?: any;
    filters?: any;
    selectedConversationId?: string;
    onConversationClicked?: any;
    isEndList?: boolean;
    conversationsError?: string;
    onLoadMore?: any;
    isLoadingMore?: boolean;
    isRefreshing?: boolean;
}

export const ConversationList = (props: ConversationListProps) => {
    const {
        onEndListReached,
        conversations,
        filters,
        selectedConversationId,
        onConversationClicked,
        isEndList,
        conversationsError,
        onLoadMore,
        isLoadingMore,
        isRefreshing,
    } = props;

    return (
        <ScrollBar onYReachEnd={onEndListReached}>
            <Fragment>
                {getFilteredConversations(conversations, filters).map(
                    (conversation) => {
                        return (
                            <Conversation
                                isActive={
                                    selectedConversationId === conversation.id
                                }
                                onClick={() => {
                                    onConversationClicked(conversation.id);
                                }}
                                key={conversation.id}
                                conversation={conversation}
                                filters={filters}
                                status={conversation.status}
                            />
                        );
                    },
                )}
                {filters.length > 0 && !isEndList && !conversationsError && (
                    <div
                        className="c-btn c-btn--secondary console-activity-conversations__load-more"
                        onClick={onLoadMore}
                    >
                        LOAD MORE
                    </div>
                )}
                {filters.length > 0 && isEndList && (
                    <div className="console-activity-conversations__no-more">
                        No more conversations to load
                    </div>
                )}
                {isLoadingMore && isRefreshing && (
                    <div
                        className={cx(
                            {active: false},
                            'console-activity-conversations-list__column-loading-more',
                        )}
                    >
                        Loading more conversations...
                    </div>
                )}
            </Fragment>
        </ScrollBar>
    );
};
