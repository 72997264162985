import {get, isEmpty} from 'lodash';

import {DATA, LOADING, ERROR} from '../constants';
import {
    GET_ATTACHED_DOCUMENT_FIELD_DOCUMENT,
    GET_BODY_DOCUMENT,
    GET_DOCUMENT,
} from './constants';

export const isDocumentFetchingSelector = (state) => {
    return get(state, [GET_DOCUMENT, LOADING]) === true;
};
export const isDocumentErroringSelector = (state) => {
    return !!get(state, [GET_DOCUMENT, ERROR]);
};

/**
 * Select a single document.
 * @param {Object} state
 */
export const documentSelector = (state) => get(state, [GET_DOCUMENT, DATA]);
export const documentBodySelector = (state) =>
    get(state, [GET_BODY_DOCUMENT, 'data', 'data']);

/**
 * Select the attached document.
 * @param {Object} state
 */
export const attachedDocumentFieldDocumentSelector = (state) => {
    const document = get(state, [GET_ATTACHED_DOCUMENT_FIELD_DOCUMENT, DATA]);
    return document;
};

export const isAttachedDocumentFieldDocumentFetchingSelector = (state) => {
    return get(state, [GET_ATTACHED_DOCUMENT_FIELD_DOCUMENT, LOADING]) === true;
};

export const attachedDocumentFieldDocumentErrorSelector = (state) => {
    const error = get(state, [GET_ATTACHED_DOCUMENT_FIELD_DOCUMENT, ERROR]);
    return !isEmpty(error) && error;
};
