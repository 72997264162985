import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import cx from 'classnames';

import './ChatterMessages.scss';
import {StoreProvider} from '@app/state';
import {ChatterTable} from './ChatterTable';
import {Button} from '@app/components/inputs/buttons/Button';

interface ChatterMessagesProps {}

export const ChatterMessages: React.FC<ChatterMessagesProps> = () => {
    const history = useHistory();
    const pathname = history.location.pathname.split('/');
    let active = pathname[pathname.length - 1];
    if (
        active !== 'published' &&
        active !== 'unpublished' &&
        active !== 'deleted'
    ) {
        active = 'published';
        history.push('/chattering/published', history.location.state);
    }
    const [activeTab, setActiveTab] = useState<string>(active);
    const activeTabCx = cx('ChatterMessages__tab', {
        ['ChatterMessages__activeTab']: activeTab === 'published',
    });
    const unpublishedActiveCx = cx('ChatterMessages__tab', {
        ['ChatterMessages__activeTab']: activeTab === 'unpublished',
    });
    const deletedActiveCx = cx('ChatterMessages__tab', {
        ['ChatterMessages__activeTab']: activeTab === 'deleted',
        ['ChatterMessages__tabDeleted']: true,
    });
    return (
        <StoreProvider>
            <div className="ChatterMessages__chatterMessagesWrapper">
                <div className="ChatterMessages__header">
                    <h1 className="ChatterMessages__title">Chatter messages</h1>
                    <Button
                        text="New chatter"
                        type="confirm"
                        onClick={() => {
                            history.push(`?compose=new&status=chattering`);
                        }}
                    />
                </div>
                <div className="ChatterMessages__description">
                    Chatter messages are used for a natural feeling in the
                    conversations. Customise your chatter messages or add new
                    ones for different queries.
                </div>
                <div className="ChatterMessages__tabs">
                    <div
                        className={activeTabCx}
                        onClick={() => {
                            setActiveTab('published');
                            history.push('/chattering/published');
                        }}
                    >
                        Published
                    </div>
                    <div
                        className={unpublishedActiveCx}
                        onClick={() => {
                            setActiveTab('unpublished');
                            history.push('/chattering/unpublished');
                        }}
                    >
                        Unpublished
                    </div>
                    <div
                        className={deletedActiveCx}
                        onClick={() => {
                            setActiveTab('deleted');
                            history.push('/chattering/deleted');
                        }}
                    >
                        Deleted
                    </div>
                </div>
                <ChatterTable activeTab={activeTab} />
                <ToastContainer />
            </div>
        </StoreProvider>
    );
};
