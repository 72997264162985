import {
    CHANGE_SELECTED_ASPECT,
    CHANGE_SELECTED_MIN_DATE,
    CHANGE_SELECTED_MAX_DATE,
    CHANGE_SELECTED_MESSAGE,
    CHANGE_SELECTED_METRIC_ID,
} from '../constants';

export const changeSelectedMessage = (messageObj) => {
    return {
        type: CHANGE_SELECTED_MESSAGE,
        payload: {
            ...messageObj,
        },
    };
};

export const changeSelectedAspect = (aspect) => {
    return {
        type: CHANGE_SELECTED_ASPECT,
        payload: {
            aspect,
        },
    };
};

export const changeSelectedMetricId = (id) => {
    return {
        type: CHANGE_SELECTED_METRIC_ID,
        payload: {
            id,
        },
    };
};

export const changeSelectedMinDate = (minDate) => {
    return {
        type: CHANGE_SELECTED_MIN_DATE,
        payload: {
            minDate,
        },
    };
};

export const changeSelectedMaxDate = (maxDate) => {
    return {
        type: CHANGE_SELECTED_MAX_DATE,
        payload: {
            maxDate,
        },
    };
};
