export const MODAL_BASE_CONFIG = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    content: {
        left: '50%',
        top: '50%',
        bottom: 'auto',
        padding: '15px',
        transform: 'translateY(-50%) translateX(-50%)',
    },
};
