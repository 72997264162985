import React from 'react';
import cx from 'classnames';

interface KareStatusTabsProps {
    tabs: any[];
}

export const POSITION = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
};

export class KareStatusTabs extends React.Component<KareStatusTabsProps> {
    renderTabs = (tabs) => {
        return tabs.map((tab) => {
            const className = cx('kare-status-tab', {
                'kare-status-tab--active': tab.isActive,
            });
            return (
                <div
                    key={tab.title}
                    onClick={tab.onClick}
                    className={className}
                >
                    {tab.title}
                </div>
            );
        });
    };
    render() {
        const {tabs} = this.props;

        const leftTabs = tabs.filter((tab) => tab.position === POSITION.LEFT);
        const rightTabs = tabs.filter((tab) => tab.position === POSITION.RIGHT);

        return (
            <section className="kare-status-tabs">
                <div className="kare-status-tabs-left">
                    {this.renderTabs(leftTabs)}
                </div>
                <div className="kare-status-tabs-right">
                    {this.renderTabs(rightTabs)}
                </div>
            </section>
        );
    }
}
export const updateQueryStringParameter = (uri, key, value) => {
    const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
        return uri + separator + key + '=' + value;
    }
};

export const upsertQueryStringInURL = (base, uri, key, value) => {
    return `${base}${updateQueryStringParameter(uri, key, value)}`;
};
