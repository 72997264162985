import React from 'react';
import Axios, {AxiosResponse} from 'axios';
import {withRouter} from 'react-router-dom';

import {DocumentPortal} from '@app/documents/document-portal';
import {ResponsePortal} from '@app/responses/response-portal';

const ResponseOrContentNavigatorInternal = (props) => {
    React.useEffect(() => {
        Axios.get(`/v3.0/kbm/nodes/${props.nodeId}`).then(
            (response: AxiosResponse<any>) => {
                setIsChattering(response.data.type === 'chattering');
                handleNavigation(
                    response.data.type === 'response' ||
                        response.data.type === 'chattering',
                );
            },
        );
    }, []);
    const [openDocument, setOpenDocument] = React.useState(false);
    const [openResponse, setOpenResponse] = React.useState(false);
    const [isChattering, setIsChattering] = React.useState(false);

    const handleNavigation = (isResponse) => {
        if (isResponse) {
            setOpenResponse(true);
        } else {
            setOpenDocument(true);
        }
    };

    if (openResponse) {
        return (
            <ResponsePortal
                isChattering={isChattering}
                compose={props.nodeId}
                onClose={() => {
                    setOpenResponse(false);
                    props.onClose();
                }}
            />
        );
    } else if (openDocument) {
        return (
            <DocumentPortal
                documentId={props.nodeId}
                onClose={() => {
                    setOpenDocument(false);
                    props.onClose();
                }}
            />
        );
    } else {
        return null;
    }
};

export const ResponseOrContentNavigator = withRouter(
    ResponseOrContentNavigatorInternal,
);
