import React from 'react';
import cx from 'classnames';
import './BorderlessTextInput.scss';

interface IBorderlessTextInput {
    classname: string;
    placeholder: string;
    value: string;
    onChange?: (...args: any) => any | undefined;
    onKeyPressed?: (...args: any) => any | undefined;
}

export const BorderlessTextInput: React.FunctionComponent<
    IBorderlessTextInput
> = (props) => {
    const containerClassName = cx(
        props.classname,
        'BorderlessTextInput__container',
    );

    return (
        <div className={containerClassName}>
            <input
                type="text"
                placeholder={props.placeholder}
                className={'BorderlessTextInput__input'}
                value={props.value}
                onChange={(e) => {
                    e.preventDefault();
                    props.onChange?.(e.target.value);
                }}
                onKeyDown={(event) => {
                    props.onKeyPressed?.(event.key);
                }}
            />
        </div>
    );
};
