import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';

import {SUBDOMAIN_FORM} from '../constants';
import {Button, KareTextInput} from '@app/common';
import {getZone} from '@app/zone';

const isRequired = (value) => (value ? undefined : 'Required');

interface SubdomainFormProps {
    handleSubmit?: any;
    pristine?: boolean;
    zone?: string;
    subdomain?: string;
    invalid?: boolean;
    submitting?: boolean;
    onSubmit?: () => void;
}

export class SubdomainFormComponent extends React.Component<SubdomainFormProps> {
    render() {
        const {handleSubmit, pristine, invalid, submitting} = this.props;
        return (
            <form className="login-form" onSubmit={handleSubmit}>
                <Field
                    component={KareTextInput}
                    name="subdomain"
                    validate={isRequired}
                    tight={true}
                    label="Company domain"
                />
                <section className="submit-button-container">
                    <Button
                        disabled={pristine || invalid || submitting}
                        onClick={handleSubmit}
                        className="u-1/1"
                        showSpinner={submitting}
                    >
                        Next
                    </Button>
                </section>
                <div className="content-recaptcha-branding">
                    This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy">
                        {' '}
                        Privacy Policy{' '}
                    </a>{' '}
                    and
                    <a href="https://policies.google.com/terms">
                        {' '}
                        Terms of Service{' '}
                    </a>
                    apply.
                </div>
            </form>
        );
    }
}

const mapStateToProps = () => {
    return {
        zone: getZone(),
    };
};

export const SubdomainForm = connect(mapStateToProps)(
    reduxForm({
        form: SUBDOMAIN_FORM,
    })(SubdomainFormComponent),
);
