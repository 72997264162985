import React from 'react';

interface WideModalProps {
    children: any;
}

export class WideModal extends React.Component<WideModalProps> {
    handleClickModal = (event) => {
        event.stopPropagation();
    };

    render() {
        const {children} = this.props;
        return (
            <section
                onClick={this.handleClickModal}
                className="kare-modal kare-modal-wide"
            >
                {children}
            </section>
        );
    }
}
