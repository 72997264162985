import React from 'react';
import {MdEdit, MdDelete} from 'react-icons/md';

import './WebCrawlerTooltip.scss';

export interface WebCrawlerTooltipProps {
    onEdit: () => void;
    onDelete: () => void;
    closeTooltip: () => void;
}

export const WebCrawlerTooltip: React.FC<WebCrawlerTooltipProps> = (props) => {
    return (
        <div className="WebCrawlerTooltip__container">
            <div className="WebCrawlerTooltip__tooltip">
                <div className="WebCrawlerTooltip__tooltipInner">
                    <div
                        className="WebCrawlerTooltip__item"
                        onClick={() => {
                            props.closeTooltip();
                            props.onEdit();
                        }}
                    >
                        <MdEdit />
                        Edit
                    </div>
                    <div
                        className="WebCrawlerTooltip__item"
                        onClick={() => {
                            props.closeTooltip();
                            props.onDelete();
                        }}
                    >
                        <MdDelete />
                        Delete
                    </div>
                </div>
            </div>
        </div>
    );
};
