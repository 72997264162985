/**
 * Our available zones.
 */
export const ZONES = {
    LOCAL: 'local',
    DEVELOP: 'dev',
    STAGING: 'stg',
    EU: 'eu',
    US: 'us',
};

/**
 * For some zones it should not be possible to change zones.
 */
export const REDIRECT_ZONES = [ZONES.EU, ZONES.US];
