import React from 'react';

export class ResponseTitleError extends React.Component {
    render() {
        return (
            <div className="query-response__title-error">
                <div className="query-response__title-error-triangle" />
                Please choose a title for your response. Keep it short and
                descriptive (between 5 and 280 characters). Your customers will
                see it when we are not sure which response to answer with.
            </div>
        );
    }
}
