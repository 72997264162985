import React from 'react';
import {uniqBy} from 'lodash';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {redux} from '@app/redux';
import {Spinner, PortalSidebar, PanelHeader, PanelSummary} from '@app/common';
import {NoRelatedDocumentResults} from './no-related-document-results';
import {SearchBar} from './search-bar';

interface RelatedDocumentsProps {
    documentBasePath?: string;
    editorLink?: string;
    documents?: any[];
    areDocumentsFetching?: boolean;
    onScroll?: any;
    attachedDocument?: any;
    match?: any;
    location?: any;
    search?: any;
    selectDocument?: any;
    closeDocumentsSidebar?: any;
    query?: string;
}

export class RelatedDocumentsComponent extends React.Component<RelatedDocumentsProps> {
    handleClickDocument = (id) => () => {
        this.props.selectDocument(id);
    };

    renderDocumentList = () => {
        const {documents, attachedDocument} = this.props;
        return uniqBy(documents, 'id').map((document) => {
            const {id, tags, title} = document;
            return (
                <PanelSummary
                    tags={tags}
                    title={title}
                    key={id}
                    onClick={this.handleClickDocument(id)}
                    isAttached={document.id === attachedDocument.id}
                />
            );
        });
    };

    render() {
        const {onScroll, areDocumentsFetching, documents, search, query} =
            this.props;
        const isLoading = areDocumentsFetching;
        return (
            <PortalSidebar>
                <PanelHeader
                    invertColors={true}
                    title="Documents"
                    onClose={this.props.closeDocumentsSidebar}
                >
                    <SearchBar query={query} search={search} />
                </PanelHeader>

                <section
                    onScroll={onScroll}
                    className="knowledge-base__related-documents knowledge-base__panel-body"
                >
                    {isLoading && <Spinner />}
                    {!isLoading && this.renderDocumentList()}
                    {!isLoading && documents.length === 0 && (
                        <NoRelatedDocumentResults />
                    )}
                </section>
            </PortalSidebar>
        );
    }
}

/**
 * Map redux store state to props for the component.
 * @param {Object} state
 * @param {Object} props
 */
const mapStateToProps = (state) => {
    return {
        attachedDocument:
            redux.responses.selectors.attachedDocumentSelector(state),
    };
};

export const RelatedDocuments = withRouter(
    connect(mapStateToProps)(RelatedDocumentsComponent),
);
