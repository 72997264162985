import React from 'react';
import ReactTable from 'react-table';
import {connect} from 'react-redux';
import cx from 'classnames';
import moment from 'moment';
import {withRouter} from 'react-router-dom';
import {orderBy, get} from 'lodash';
import {
    MdCall,
    MdCallSplit,
    MdImage,
    MdLaunch,
    MdVideocam,
} from 'react-icons/md';

import {Document, Automation} from '@app/common/icons';
import {
    ErrorBoundary,
    KareLabel,
    KareMultiselectCheckbox,
    Spinner,
} from '@app/common';
import {querystring} from '@app/utils';
import {redux} from '@app/redux';
import {Query as QuerySvg} from '@app/containers/Dialog/query';

interface ResponsesTableProps {
    deleteResponse?: any;
    items: any[];
    disabled?: any[];
    isLoading?: boolean;
    history?: any;
    resetSelectedRows?: any;
    addSelectedRows?: any;
    removeSelectedRows?: any;
    selectedRows?: any[];
    original?: any;
}

/**
 * Table component for the responses.
 */
export class ResponsesTableComponent extends React.Component<ResponsesTableProps> {
    componentWillUnmount() {
        const {resetSelectedRows} = this.props;
        resetSelectedRows();
    }

    handleClickRow = (response) => {
        const {history} = this.props;
        history.push(
            querystring.getEditResponsePath(history.location, response.id),
        );
    };

    handleClickCheckbox = (id) => (event) => {
        event.stopPropagation();
        const {addSelectedRows, removeSelectedRows, selectedRows} = this.props;
        if (selectedRows.includes(id)) {
            return removeSelectedRows([id]);
        }
        if (event.shiftKey) {
            // TODO(rupertrutland): Manage shift clicking.
        }
        return addSelectedRows([id]);
    };

    getQueriesNumber = (response) => {
        return get(response, ['response', 'queries', 'length'], 0);
    };
    getOutboundsNumber = (response) =>
        get(response, ['response', 'blocks'], [])
            .filter((b) => b.type === 'choices')
            .reduce(
                (acc, curr) =>
                    acc + get(curr, ['choices_block', 'choices', 'length'], 0),
                0,
            );
    getDocumentsNumber = (response) =>
        get(response, ['response', 'blocks'], []).filter(
            (b) => b.type === 'quote',
        ).length;
    getImagesNumber = (response) =>
        get(response, ['response', 'blocks'], []).filter(
            (b) => b.type === 'image',
        ).length;
    getVideosNumber = (response) =>
        get(response, ['response', 'blocks'], []).filter(
            (b) => b.type === 'video',
        ).length;
    getWebextNumber = (response) =>
        get(response, ['response', 'blocks'], []).filter(
            (b) => b.type === 'webext',
        ).length;
    getHandoversNumber = (response) =>
        get(response, ['response', 'blocks'], []).filter(
            (b) => b.type === 'handover',
        ).length;

    render() {
        const {items, selectedRows} = this.props;
        const orderedItems = orderBy(items, [
            (o) => o?.title?.toLowerCase(),
        ]).map((i: any) => {
            return {
                ...i,
                tableCounters: {
                    queries: this.getQueriesNumber(i),
                    outbounds: this.getOutboundsNumber(i),
                    documents: this.getDocumentsNumber(i),
                    images: this.getImagesNumber(i),
                    videos: this.getVideosNumber(i),
                    webext: this.getWebextNumber(i),
                    handovers: this.getHandoversNumber(i),
                },
            };
        });

        const className = cx('-striped', 'knowledge-base-table', {
            'knowledge-base-table--has-selected-rows': selectedRows.length > 0,
        });
        const columns = [
            //TODO (1.21.0) Uncomment when backend is implemented
            {
                accessor: 'id',
                sortable: false,
                resizable: false,
                width: 30,
                Header: () => {
                    return null;
                },
                Cell: (props) => {
                    const {
                        original: {id},
                    } = props;
                    return (
                        <KareMultiselectCheckbox
                            onClick={this.handleClickCheckbox(id)}
                            isChecked={selectedRows.includes(id)}
                        />
                    );
                },
            },
            {
                Header: 'Title',
                accessor: 'title',
                sortable: true,
                Cell: (props) => {
                    return (
                        <div className={'knowledge-base-table__title-label'}>
                            <span>
                                {props.original.enabled === false
                                    ? '[DRAFT] '
                                    : ''}
                                {props.value}
                            </span>
                            {props.original.labels &&
                                props.original.labels.map((tag, i) => (
                                    <div
                                        className={
                                            'knowledge-base-table__label-container'
                                        }
                                        key={`${tag}-${i}`}
                                        onClick={(e) => {
                                            const search =
                                                get(
                                                    this.props,
                                                    ['location', 'search'],
                                                    '?',
                                                ) || '?';
                                            this.props.history.push(
                                                `/responses${search}&labelFilter=${tag}`,
                                            );
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        <KareLabel
                                            text={tag}
                                            showRemoveButton={false}
                                        />
                                    </div>
                                ))}
                            <span
                                className={'knowledge-base-table__dialog'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.props.history.push(
                                        `/dialog/${get(props, [
                                            'original',
                                            'id',
                                        ])}`,
                                    );
                                }}
                            >
                                <MdLaunch /> View in dialog map
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: <QuerySvg />,
                accessor: 'tableCounters.queries',
                sortable: true,
                width: 60,
                Cell: (props) => {
                    return props.original.tableCounters.queries;
                },
            },
            {
                Header: (
                    <div className={'split-icon'}>
                        <MdCallSplit />
                    </div>
                ),
                accessor: 'tableCounters.outbounds',
                sortable: true,
                width: 60,
                Cell: (props) => {
                    return props.original.tableCounters.outbounds;
                },
            },

            {
                Header: <Document />,
                accessor: 'tableCounters.documents',
                sortable: true,
                width: 60,
                Cell: (props) => {
                    return props.original.tableCounters.documents;
                },
            },
            {
                Header: <MdImage />,
                accessor: 'tableCounters.images',
                sortable: true,
                width: 60,
                Cell: (props) => {
                    return props.original.tableCounters.images;
                },
            },
            {
                Header: <MdVideocam />,
                accessor: 'tableCounters.videos',
                sortable: true,
                width: 60,
                Cell: (props) => {
                    return props.original.tableCounters.videos;
                },
            },
            {
                Header: <Automation />,
                accessor: 'tableCounters.webext',
                sortable: true,
                width: 60,
                Cell: (props) => {
                    return props.original.tableCounters.webext;
                },
            },
            {
                Header: <MdCall />,
                accessor: 'tableCounters.handovers',
                sortable: true,
                width: 60,
                Cell: (props) => {
                    return props.original.tableCounters.handovers;
                },
            },
            {
                Header: 'Last updated',
                accessor: 'updated_at',
                sortable: true,
                width: 120,
                Cell: ({value}) => {
                    return moment.unix(value).format('DD/MM/YYYY HH:mm');
                },
            },
        ];
        return (
            <ErrorBoundary>
                <ReactTable
                    className={className}
                    data={orderedItems}
                    columns={columns}
                    resizable={false}
                    showPagination={true}
                    defaultPageSize={20}
                    loading={this.props.isLoading}
                    loadingText={<Spinner />}
                    getTrProps={(state, rowInfo) => ({
                        onClick: () => {
                            this.handleClickRow(rowInfo.original);
                        },
                    })}
                />
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedRows: redux.responsesTable.selectors.selectedRowsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    addSelectedRows: (rows) =>
        dispatch(redux.responsesTable.actionCreators.addSelectedRows(rows)),
    removeSelectedRows: (rows) =>
        dispatch(redux.responsesTable.actionCreators.removeSelectedRows(rows)),
    resetSelectedRows: () =>
        dispatch(redux.responsesTable.actionCreators.resetSelectedRows()),
});

export const ResponsesTable = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ResponsesTableComponent),
);
