import {isMobilePhone} from 'validator';

const required = (value) => (value ? undefined : 'Required');

export const isValidJSON = (value) => {
    if (!value) {
        return false;
    }

    return /^[\],:{}\s]*$/.test(
        JSON.stringify(value)
            // eslint-disable-next-line no-useless-escape
            .replace(/\\["\\\/bfnrtu]/g, '@')
            .replace(
                // eslint-disable-next-line no-useless-escape
                /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                ']',
            )
            .replace(/(?:^|:|,)(?:\s*\[)+/g, ''),
    );
};

export const emailMatchValidation = (value, allValues) => {
    return value !== allValues.email ? `Emails don't match` : undefined;
};

const checkJSON = (value) => {
    if (
        !value ||
        /^[\],:{}\s]*$/.test(
            value
                // eslint-disable-next-line no-useless-escape
                .replace(/\\["\\\/bfnrtu]/g, '@')
                .replace(
                    // eslint-disable-next-line no-useless-escape
                    /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                    ']',
                )
                .replace(/(?:^|:|,)(?:\s*\[)+/g, ''),
        )
    ) {
        return undefined;
    } else {
        return 'Invalid JSON';
    }
};

const website = (value) => {
    value = value !== undefined ? value.trim() : value;
    const websiteRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return websiteRegex.test(value) ? undefined : 'Please provide a valid URL';
};

const email = (value) => {
    return /\S+@\S+\.\S+/.test(value) ? undefined : 'Invalid email address';
};

const callcenter = (value) => {
    return value ? undefined : 'Invalid Callcenter';
};

const termsOfService = (value) => {
    if (!value) {
        return 'You must accept the Terms and Conditions before proceeding.';
    }
};

const match = (matchKey, errorMessage) => (value, values) => {
    if (values[matchKey] === value) {
        return undefined;
    }
    return errorMessage;
};

export const websites = (value) => {
    value = value || [];
    return value.reduce((previous, next, index) => {
        const error = !!next && website(next);
        if (error) {
            return previous ? previous.concat(index) : [index];
        }
        return previous;
    }, undefined);
};

const phone = (value) => {
    if (!isMobilePhone(value)) {
        return 'Invalid phone number';
    }
};

const maxLength = (max) => (value) => {
    return value && value.length > max
        ? `Must be ${max} characters or less`
        : undefined;
};

export const maxLength1000 = maxLength(1000);
export const maxLength500 = maxLength(500);
export const maxLength280 = maxLength(280);
export const maxLength150 = maxLength(150);
export const maxLength50 = maxLength(50);
export const maxLength15 = maxLength(15);
export const maxLength10 = maxLength(10);

const minLength = (min) => (value) => {
    return value && value.length < min ? `Must be at least ${min}` : undefined;
};

export const minLength15 = minLength(15);
export const minLength5 = minLength(5);
export const minLength10 = minLength(10);
export const minLength1 = minLength(1);

export {
    required,
    website,
    email,
    termsOfService,
    match,
    phone,
    checkJSON,
    callcenter,
};
