export const GET_ACTIVITY = 'getActivity';
export const GET_RESPONSE_COUNTERS = 'getResponseCounters';
export const GET_RESPONSE_QUERIES = 'getResponseQueries';
export const RESPONSE_COUNTERS = 'resolution_counters';
export const RESPONSE_QUERIES = 'resolution_queries';

export const ACTIONS = {
    SET_ACTIVE_RESPONSE_ID: 'SET_ACTIVE_RESPONSE_ID',
};

export const ACTIVITY = 'activity';
export const ACTIVE_RESPONSE_ID = 'activeResponseId';
