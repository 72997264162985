import React from 'react';
import {Grid} from '@material-ui/core';

import {Card} from '@app/components/Card/Card';
import {Opportunity} from './Opportunity/Opportunity';
import {Journey} from './Journey/Journey';
import {AutomationsTable} from './AutomationsTable/AutomationsTable';

export interface AutomationProps {
    history: any;
}

export const Automation: React.FC<AutomationProps> = (props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Card>
                    <Opportunity />
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card>
                    <Journey />
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <AutomationsTable />
                </Card>
            </Grid>
        </Grid>
    );
};
