import React from 'react';
import {get} from 'lodash';
import {connect} from 'react-redux';

import {WebCrawlerList} from '@app/containers/WebCrawlerList/WebcrawlerList';
import {updateOrg} from '../../utils';
import {Button} from '@app/common';
import {redux} from '@app/redux';
import {Add} from '@app/common/icons';
import {NewWebcrawlerForm} from './new-webcrawler-form';

interface WebcrawlerListProps {
    showModal?: boolean;
    handleShowModal?: any;
    organisation?: any;
    updateOrg?: any;
    org?: any;
}

export class WebcrawlerListComponent extends React.Component<WebcrawlerListProps> {
    state = {
        isFormOpen: false,
        initialValues: {},
        host: '',
        triggerRefresh: false,
    };

    handleClickAdd = () => {
        this.setState({triggerRefresh: false});
        if (this.props.showModal) {
            this.props.handleShowModal();
        } else {
            this.openForm();
        }
    };

    handleClickCloseForm = () => {
        this.closeForm();
        this.setState({host: ''});
    };

    closeForm = () => {
        this.setState({isFormOpen: false, initialValues: {}});
    };

    openForm = (initialValues = {}) => {
        this.setState({isFormOpen: true, initialValues});
    };

    renderListItems = () => {
        return (
            <WebCrawlerList
                triggerRefresh={this.state.triggerRefresh}
                showModal={(host) => {
                    this.setState({
                        host,
                    });
                }}
            />
        );
    };

    deleteUrl = (url) => {
        const {organisation, org} = this.props;
        const crawlingList = get(organisation, ['crawl_urls_map'], {}) || {};
        delete crawlingList[url];
        this.props.updateOrg({crawl_urls_map: crawlingList}, org).then(() => {
            this.closeForm();
        });
    };

    render() {
        return (
            <section className="settings__knowledge-management-webcrawler-list settings-section">
                <h1>Import your online content</h1>
                <p>
                    Setup a webcrawler to import and keep your online help
                    center in sync with your Dialpad base. We will use them to
                    automatically generate responses to answer your customers.{' '}
                </p>
                <Button
                    className="settings-knowledge-management-add-new"
                    inverted
                    icon={Add}
                    onClick={this.handleClickAdd}
                >
                    Add new content source
                </Button>
                {this.renderListItems()}
                {(this.state.isFormOpen || this.state.host !== '') && (
                    <NewWebcrawlerForm
                        closeModal={this.handleClickCloseForm}
                        host={this.state.host}
                        onSuccess={() => {
                            this.setState({triggerRefresh: true});
                        }}
                    />
                )}
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        org: redux.org.selectors.orgSelector(state),
        updateOrgLoading: redux.org.selectors.updateOrgLoadingSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateOrg: updateOrg(dispatch),
    };
};

export const WebcrawlerList = connect(
    mapStateToProps,
    mapDispatchToProps,
)(WebcrawlerListComponent);
