import {url} from '../../utils';

const {deserializeQs} = url;

export const types = {
    LOAD: 'features/LOAD',
};

const initialState = [];

export function loadFeatures(features = []) {
    return {
        type: types.LOAD,
        features,
    };
}

export default function featuresReducer(state = initialState, action) {
    if (action.type === types.LOAD) {
        return [...state, ...action.features];
    }
    return state;
}

export function isFeatureEnabled(state, feature) {
    return state.featuresReducer.indexOf(feature) !== -1;
}

export function featuresFromQs(qs) {
    const featuresStr: string = deserializeQs(qs).features.toString() || '';
    return featuresStr.split(',');
}
