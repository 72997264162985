import {get} from 'lodash';

import {
    GET_CLUSTER,
    GET_CLUSTER_QUERIES,
    GET_CLUSTERS,
    SAVE_CLUSTER,
} from '../../constants';
import {DATA, ERROR, LOADING} from '../../../constants';
import {getSelectedQueries} from '../../queries/selectors';
import {
    CLUSTER_LIMIT_KEY,
    CLUSTER_TYPE,
    CLUSTERS_TO_SKIP,
    SELECTED_CLUSTER_INDEX,
} from '../constants';

export const getClusterResponseId = (state) => {
    // backend is returning resolution_id
    return getCluster(state).resolution_id;
};

export const getClusterStatsQuestionsAnswered = (state) => {
    return get(getCluster(state), ['stats', 'queries'], 0);
};

export const getClusterNextPage = (state) => {
    return get(state, `${GET_CLUSTERS}.${DATA}.next_url`);
};

export const getClusterQueriesLength = (state) => {
    return getClusterQueries(state).length;
};

export const isClusterPatchingError = (state) => {
    return get(state, `${SAVE_CLUSTER}.loading`, false);
};

export const areAllCardQueriesSelected = (state) => {
    return getClusterQueries(state).length === getSelectedQueries(state).length;
};

export const isSavingAClusterError = (state) => {
    return get(state, [SAVE_CLUSTER, ERROR]);
};

export const areClustersEmpty = (state) => {
    return get(state, [GET_CLUSTERS, DATA, 'clusters'], []).length === 0;
};

export const isEndOfcurrentClusters = (state) => {
    return (
        get(state, [GET_CLUSTERS, DATA, 'clusters'], []).length ===
        getSelectedClusterIndex(state)
    );
};

export const getClusterQueriesUrl = (state) =>
    get(getCluster(state), 'cluster_queries_url');

export const getSelectedClusterIndex = (state) =>
    get(state, [GET_CLUSTERS, SELECTED_CLUSTER_INDEX]);

export const getSelectedClusterType = (state) =>
    get(getCluster(state), CLUSTER_TYPE);

export const getClusterLimit = (state) =>
    get(state, [GET_CLUSTERS, CLUSTER_LIMIT_KEY]);

export const getClusterId = (state, index) =>
    get(state, [GET_CLUSTERS, DATA, 'clusters', index, 'id']);

export const clustersSelector = (state) =>
    get(state, `${GET_CLUSTERS}.${DATA}`);

export const getCluster = (state) => {
    return get(state, `${GET_CLUSTER}.${DATA}`);
};

export const getClusterType = (state) => get(getCluster(state), [CLUSTER_TYPE]);

export const isClusterErroringSelector = (state) =>
    get(state, [GET_CLUSTER, ERROR], null) !== null;

export const isClusterLoadingSelector = (state) =>
    get(state, [GET_CLUSTER, LOADING], false);

export const areClustersLoadingSelector = (state) =>
    get(state, `${GET_CLUSTERS}.loading`, false);

export const areClustersErroringSelector = (state) =>
    !!get(state, [GET_CLUSTERS, ERROR], false);

export const getPedingClustersSelector = (state) =>
    get(state, [GET_CLUSTERS, DATA, 'stats', 'pending_count']);

export const getClusterQueries = (state) =>
    get(state, `${GET_CLUSTER_QUERIES}.${DATA}.queries`, []);

export const nextCursorSelector = (state) =>
    get(state, [GET_CLUSTERS, DATA, 'next_cursor']);

export const getClustersToSkip = (state) =>
    get(state, [GET_CLUSTERS, CLUSTERS_TO_SKIP]);

export const isFirstClusterSelector = (state) =>
    get(state, [GET_CLUSTERS, SELECTED_CLUSTER_INDEX], 0) === 0;

export const isLastClusterSelector = (state) =>
    !get(state, [GET_CLUSTERS, DATA, 'next_cursor'], null);

export const clusterErrorSelector = (state) => get(state, [GET_CLUSTER, ERROR]);
export const clustersErrorSelector = (state) =>
    get(state, [GET_CLUSTERS, ERROR]);
