import React from 'react';
import {Link} from 'react-router-dom';
import cx from 'classnames';

import {Tags} from '@app/common/tags';
import {Attachment} from '@app/common/icons';

interface PanelSummaryProps {
    title: string;
    to?: string;
    isAttached?: boolean;
    tags?: any[];
    onClick?: any;
}

/**
 * Panel Summary Component.
 */
export class PanelSummary extends React.Component<PanelSummaryProps> {
    render() {
        const {title, to, isAttached, tags, onClick} = this.props;
        const className = cx('knowledge-base__panel-summary', {
            'knowledge-base__panel-summary--is-document-attached': isAttached,
            'knowledge-base__panel-summary--has-tags': tags && tags.length,
        });
        const Component = onClick ? 'div' : Link;
        return (
            <Component to={to} onClick={onClick} className={className}>
                <span>{title}</span>{' '}
                {isAttached && (
                    <span className="knowledge-base__panel-summary-attached-label">
                        <Attachment className="knowledge-base__panel-summary-attached-icon" />
                        ATTACHED
                    </span>
                )}
                <Tags tags={tags} />
            </Component>
        );
    }
}
