import React from 'react';

import './ErrorLoadingWrapper.scss';
import {Loading} from '../Loading/Loading';
import {EmptyState} from '../EmptyState/EmptyState';

export interface ErrorLoadingWrapperProps {
    height: string;
    isLoading: boolean;
    CustomLoading?: any;
    error: string | null;
    CustomError?: React.ReactNode;
    render: () => React.ReactNode;
    emptyState?: string | null;
    CustomEmptyState?: React.ReactNode;
}

export const ErrorLoadingWrapper: React.FC<ErrorLoadingWrapperProps> = ({
    height,
    isLoading,
    CustomLoading,
    error,
    CustomError,
    render,
    CustomEmptyState,
    emptyState = null,
}) => {
    return (
        <div className="ErrorLoadingWrapper__container">
            {isLoading ? (
                <div className="ErrorLoadingWrapper__loading" style={{height}}>
                    {CustomLoading ? CustomLoading() : <Loading />}
                </div>
            ) : error ? (
                <div className="ErrorLoadingWrapper__error" style={{height}}>
                    {CustomError ? (
                        CustomError
                    ) : (
                        <h3>There has been an error loading the data</h3>
                    )}
                </div>
            ) : emptyState ? (
                <div className="ErrorLoadingWrapper__empty" style={{height}}>
                    {CustomEmptyState ? CustomEmptyState : <EmptyState />}
                </div>
            ) : (
                render()
            )}
        </div>
    );
};
