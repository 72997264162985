import 'react-dates/initialize';
import {DateRangePicker} from 'react-dates';
import React from 'react';

import {getInitialDateValues} from '../../utils';
import {
    DATE_FORM_FIELD_FROM,
    DATE_FORM_FIELD_TO,
    DATE_LABEL_FORMAT,
} from '../../constants';

interface SingleDatePickerFieldProps {
    startDate?: any;
    endDate?: any;
    autoFocus?: boolean;
    meta?: any;
    input?: any;
}

export class SingleDatePickerField extends React.Component<
    SingleDatePickerFieldProps,
    any
> {
    constructor(props) {
        super(props);
        this.state = {
            focusedInput: null,
            startDate: getInitialDateValues().from,
            endDate: getInitialDateValues().to,
            focused: null,
        };
    }
    handleFocusChange = (focusedInput) => {
        this.setState({focusedInput});
    };

    handleDatesChange = ({startDate, endDate}) => {
        this.setState({
            startDate,
            endDate,
        });
        this.props.input.onChange({
            from: startDate,
            to: endDate,
        });
    };

    render() {
        return (
            <div>
                <DateRangePicker
                    hideKeyboardShortcutsPanel={true}
                    startDate={this.state.startDate}
                    startDateId={DATE_FORM_FIELD_FROM}
                    endDate={this.state.endDate}
                    isOutsideRange={() => false}
                    startDatePlaceholderText={'From'}
                    displayFormat={DATE_LABEL_FORMAT}
                    endDatePlaceholderText={'To'}
                    endDateId={DATE_FORM_FIELD_TO}
                    focusedInput={this.state.focusedInput}
                    onDatesChange={this.handleDatesChange}
                    onFocusChange={this.handleFocusChange}
                    keepOpenOnDateSelect={true}
                    firstDayOfWeek={1}
                    minimumNights={0}
                />
            </div>
        );
    }
}
