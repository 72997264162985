import axios from 'axios';
import {get} from 'lodash';
import {toast} from 'react-toastify';

export const listOnlyChatterNodes = (onSuccess) => {
    const useNull = () => null;
    axios
        .all([
            axios
                .get(
                    `/v3.1/kbm/nodes?type=chattering&status=published&limit=1000`,
                )
                .catch(useNull),
            axios
                .get(
                    `/v3.1/kbm/nodes?type=chattering&status=unpublished&limit=1000`,
                )
                .catch(useNull),
            axios
                .get(
                    `/v3.1/kbm/nodes?type=chattering&status=deleted&limit=1000`,
                )
                .catch(useNull),
        ])
        .then(
            axios.spread((published, unpublished, deleted) => {
                onSuccess([
                    ...get(published, ['data', 'nodes'], []),
                    ...get(unpublished, ['data', 'nodes'], []),
                    ...get(deleted, ['data', 'nodes'], []),
                ]);
            }),
        );
};

export const modifyStatusChatterMessage = (id, status, onSuccess) => {
    axios
        .patch('/v3.1/kbm/nodes/bulk', {
            nodes: [{id: id, status, field_masks: ['status']}],
        })
        .then((r) => {
            if (get(r, ['data', 'nodes', '0', 'status'], '') === 'ok') {
                onSuccess();
            } else {
                toast.error('Error deleting this chatter message');
            }
        })
        .catch(() => {
            toast.error('Error deleting this chatter message');
        });
};

export const unpublishChatterMessage = (id, onSuccess) => {
    axios
        .patch('/v3.1/kbm/nodes/bulk', {
            nodes: [{id: id, status: 'unpublished', field_masks: ['status']}],
        })
        .then((r) => {
            if (get(r, ['data', 'nodes', '0', 'status'], '') === 'ok') {
                onSuccess();
            } else {
                toast.error('Error deleting this chatter message');
            }
        })
        .catch(() => {
            toast.error('Error deleting this chatter message');
        });
};

export const deleteChatterMessage = (id, onSuccess) => {
    axios
        .patch('/v3.1/kbm/nodes/bulk', {
            nodes: [{id: id, status: 'deleted', field_masks: ['status']}],
        })
        .then((r) => {
            if (get(r, ['data', 'nodes', '0', 'status'], '') === 'ok') {
                onSuccess();
            } else {
                toast.error('Error deleting this chatter message');
            }
        })
        .catch(() => {
            toast.error('Error deleting this chatter message');
        });
};
