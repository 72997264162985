import React, {Component} from 'react';
import turndown from 'turndown';
import ScrollBar from 'react-perfect-scrollbar';
import cx from 'classnames';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {get} from 'lodash';

import {ArrowBack, Close, OpenDocument} from '@app/common/icons';
import {Button, Markdown, Spinner} from '@app/common';
import {
    documentBodySelector,
    documentSelector,
    isDocumentFetchingSelector,
} from '@app/redux/documents/selectors';
import {getSelectedDocumentSelector} from '@app/redux/improve/documents/selectors';
import {RESET_SELECT_DOCUMENT} from '@app/redux/improve/documents/constants';
import {redux} from '@app/redux';
import {GET_DOCUMENT} from '@app/redux/documents/constants';
import {ADD_QUOTE} from '@app/redux/dialogs/constants';

interface DocumentSummaryProps {
    selectedDocumentSummary?: {
        id?: string;
        title?: string;
        url?: string;
    };
    document?: any;
    body?: any;
    isLoading?: boolean;
    editing?: boolean;
    getDocument?: any;
    resetSelectedDocument?: any;
    closeModal?: any;
    change?: any;
    fields?: any;
    index?: number;
    isEditing?: boolean;
}

export class DocumentSummaryComponent extends Component<DocumentSummaryProps> {
    quoteButton = null;
    state = {
        selection: null,
        showTooltip: false,
    };

    componentDidMount() {
        this.props.getDocument();
        document.addEventListener('mouseup', this.handleMouseUp);
    }

    handleMouseUp = (event) => {
        const turndownService = new turndown({headingStyle: 'atx'});
        const documentBody = document.querySelector(
            '#modal-body__document-body',
        );
        const target = event.target;
        const selection = turndownService.turndown(
            window.getSelection().getRangeAt(0).cloneContents(),
        );
        if (
            documentBody &&
            documentBody.contains(target) &&
            selection !== this.state.selection
        ) {
            this.setState({selection});
        }
    };

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleMouseUp);
    }

    handleCloseClick = () => {
        this.props.closeModal();
    };

    handleBackClick = () => {
        this.props.resetSelectedDocument();
    };

    handleLinkSource = (input, quote = undefined) => {
        input.preventDefault();
        const {document, fields, index} = this.props;
        if (!(quote && !this.state.selection)) {
            const {id} = this.props.selectedDocumentSummary;
            const title = get(document, 'title');
            const url = get(document, ['content', 'url'], '');
            const block = {
                quote_block: {
                    node: id,
                    quote: quote ? this.state.selection : null,
                    title,
                    url,
                },
                type: 'quote',
            };
            if (this.props.isEditing) {
                fields.splice(index, 1, block);
            } else {
                fields.insert(index, block);
            }
            this.props.resetSelectedDocument();
            this.props.closeModal();
        }
    };

    render() {
        const {isLoading, document, editing, body} = this.props;
        const title = get(document, 'title');
        const url = get(document, ['content', 'url'], '');
        return (
            <div
                className={cx('query-response__documents-modal', {
                    editing: editing,
                })}
            >
                <div className="modal-title">
                    <div className="modal-title__first-line">
                        <ArrowBack
                            size={22}
                            onClick={this.handleBackClick}
                            className="modal-title__arrow"
                        />
                        <h1>{title}</h1>
                        <Close
                            size={30}
                            onClick={this.handleCloseClick}
                            className="modal-title__close"
                        />
                    </div>
                    <div className="modal-title__second-line">
                        <a href={url} target="_blank" rel="noreferrer">
                            {url}
                            <OpenDocument />
                        </a>
                    </div>
                    <div className="modal-title__separator" />
                </div>
                <div className="modal-body">
                    {isLoading ? (
                        <div>
                            <Spinner withCenteredContainer={true} />
                        </div>
                    ) : (
                        <div className="modal-body__content">
                            <div className="modal-body__scrollbar">
                                <ScrollBar option={{suppressScrollX: true}}>
                                    <div id="modal-body__document-body">
                                        <Markdown>{body}</Markdown>
                                    </div>
                                </ScrollBar>
                            </div>
                        </div>
                    )}
                    {!isLoading && (
                        <div className="modal-body__butons">
                            <Button
                                inverted
                                onClick={(input) => {
                                    this.handleLinkSource(input);
                                }}
                            >
                                Link without quote
                            </Button>
                            <Button
                                className={cx('model-body__button-quote', {
                                    disabled: !this.state.selection,
                                })}
                                disabled={
                                    !this.state.selection ||
                                    this.state.selection.length > 2000
                                }
                                ref={(ref) => {
                                    this.quoteButton = ref;
                                }}
                                onMouseEnter={this.handleMouseEnter}
                                onMouseLeave={this.handleMouseLeave}
                                onClick={(input) => {
                                    this.handleLinkSource(input, true);
                                }}
                            >
                                Link with quote
                            </Button>
                        </div>
                    )}
                </div>
                {this.state.showTooltip && this.renderTooltip()}
            </div>
        );
    }
    handleMouseEnter = () => {
        this.setState({showTooltip: true});
    };
    handleMouseLeave = () => {
        this.setState({showTooltip: false});
    };
    renderTooltip = () => {
        return ReactDOM.createPortal(
            <div
                className={cx('modal-body__tooltip', {
                    hidden: this.state.selection,
                })}
                style={{
                    top: `${
                        this.quoteButton.getBoundingClientRect().bottom + 10
                    }px`,
                    right: `calc(100vw - ${
                        this.quoteButton.getBoundingClientRect().right
                    }px)`,
                }}
            >
                Use your cursor to select the text you want to quote in your
                response.
            </div>,
            document.getElementsByTagName('body')[0],
        );
    };
}
const mapStateToProps = (state) => ({
    document: documentSelector(state),
    body: documentBodySelector(state),
    isLoading: isDocumentFetchingSelector(state),
    selectedDocumentSummary: getSelectedDocumentSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    addQuote: (quoteData) => {
        dispatch({type: ADD_QUOTE, payload: quoteData});
    },
    getDocument: () => dispatch(fetchSelectedDocument()),
    resetSelectedDocument: () => dispatch({type: RESET_SELECT_DOCUMENT}),
});

const fetchSelectedDocument = () => (dispatch, getState) => {
    const selectedDocument = getSelectedDocumentSelector(getState());
    const {id} = selectedDocument;
    dispatch(redux.rest.actions[GET_DOCUMENT]({id}));
};

export const DocumentSummary = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DocumentSummaryComponent);
