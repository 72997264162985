import React from 'react';
import errorScreen from './error_screen.svg';

export class AccessDenied extends React.Component {
    render() {
        return (
            <section className="kare-access-denied">
                <div className="error-message-inner">
                    <img src={errorScreen} alt="access denied" />
                    <h1>Access denied.</h1>
                    <p>
                        You do not have access to that page.
                        <br />
                        Why not try going somewhere else?
                    </p>
                </div>
            </section>
        );
    }
}
