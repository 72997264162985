import React from 'react';

import './EntryPage.scss';
import {StoreContext} from '@app/state';
import {DomainsTable} from './DomainsTable';
import {UrlsTable} from './UrlsTable';

export interface EntryPageProps {}

export const EntryPage: React.FC<EntryPageProps> = () => {
    const {minDate, maxDate} = React.useContext(StoreContext).date;
    return (
        <React.Fragment>
            <div className="EntryPage__domainsTable">
                <DomainsTable minDate={minDate} maxDate={maxDate} />
            </div>
            <div className="EntryPage__urlsTable">
                <UrlsTable minDate={minDate} maxDate={maxDate} />
            </div>
        </React.Fragment>
    );
};
