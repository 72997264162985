import React from 'react';
import cx from 'classnames';

interface CharacterCountProps {
    count: number;
    limit: number;
}

export class CharacterCount extends React.Component<CharacterCountProps> {
    render() {
        const {count, limit} = this.props;
        const className = cx('character-count', {
            // 70% threshold to show the user that they're approaching the limit.
            'character-count--warning': count / limit > 0.7,
        });
        return (
            <div className={className}>
                {limit - count} / {limit}
            </div>
        );
    }
}
