import React, {useEffect, useState} from 'react';

import './StaticMessages.scss';
import {checkChatterMessagesValidity, listStaticMessages} from './api';
import {StaticMessageItem} from './StaticMessageItem';
import {Message, Response} from './models';
import {Loading} from '@app/components/Loading/Loading';

export interface StaticMessagesProps {
    selectedCampaign?: any;
}

export const StaticMessages: React.FC<StaticMessagesProps> = ({
    selectedCampaign,
}) => {
    const [messages, setMessages] = useState<[Message?]>([]);
    const [nodes, setNodes] = useState<[Response?]>([]);
    const [nodesCalled, setNodesCalled] = useState(false);

    const fetchListStaticMessages = (id) => {
        setMessages([]);
        listStaticMessages((result) => {
            setMessages(
                result.messages.sort((a: Message, b: Message) => {
                    if (a.key < b.key) return -1;
                    else if (a.key > b.key) return 1;
                    else return 0;
                }),
            );
        }, id);
    };

    useEffect(() => {
        fetchListStaticMessages(selectedCampaign?.id);
    }, []);

    useEffect(() => {
        if (messages.length > 0) {
            const ids: string[] = messages
                .filter((m: Message) => m.node_id)
                .map((m: Message) => m.node_id);
            checkChatterMessagesValidity(ids, (result) => {
                setNodes(result);
                setNodesCalled(true);
            });
        }
    }, [messages]);

    return (
        <div className="StaticMessages__container">
            {nodesCalled &&
                messages.map((m: Message) => {
                    const node = nodes.find(
                        (n: Response) => n.id === m.node_id,
                    );
                    return (
                        <div key={m.key}>
                            <StaticMessageItem
                                node={node}
                                message={m}
                                fetchListStaticMessages={
                                    fetchListStaticMessages
                                }
                                selectedCampaign={selectedCampaign}
                            />
                        </div>
                    );
                })}
            {!nodesCalled && <Loading withCenteredContainer={true} />}
        </div>
    );
};
