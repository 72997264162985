import React from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import cx from 'classnames';

interface CustomTBodyProps {
    children?: any;
    className?: string;
}

export class CustomTBody extends React.Component<CustomTBodyProps> {
    render() {
        return (
            <div
                {...this.props}
                className={cx('rt-tbody', this.props.className || [])}
            >
                <ScrollBar>{this.props.children}</ScrollBar>
            </div>
        );
    }
}
