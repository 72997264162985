export const SELECT_RESPONSE_ACTION =
    '@@improve/responses/SELECT_RESPONSE_ACTION';
export const RESET_RESPONSE_CARD = '@@improve/responses/RESET_RESPONSE_CARD';
export const FILTER_QUERY_RESPONSES =
    '@@improve/responses/FILTER_QUERY_RESPONSE';

export const IMPROVE_ANSWER_ACTION =
    '@@improve/responses/IMPROVE_ANSWER_ACTION';

export const AUTO_ANSWER_ACTION = '@@improve/responses/AUTO_ANSWER_ACTION';

export const RESPONSE_FORM = 'responseForm';
export const RESPONSE_FORM_TITLE_FIELD = 'responseSubject';
export const RESPONSE_FORM_BODY_FIELD = 'responseContent';
export const RESPONSE_FORM_SOURCE = 'responseSource';
export const RESPONSE_FORM_TAGS = 'responseTags';

export const IMPROVE_STATE_KEY = 'manage';
export const IMPROVE_RESPONSES_STATE_KEY = 'responses';

export const IMPROVE_CREATE_SELECTED_RESPONSE =
    '@@improve/responses/CREATE_FAKE_RESPONSE';

export const IMPROVE_RESET_SELECTED_RESPONSE =
    '@@improve/responses/RESET_SELECTED_RESPONSE';
