import {
    GET_ACTIVITY,
    GET_RESPONSE_COUNTERS,
    GET_RESPONSE_QUERIES,
} from './constants';
import {METHODS} from '../constants';
import {
    getActivityTransformer,
    responseCountersTransformer,
} from './transformers';
import {responseCountersSelector} from './selectors';
import {
    getResponseQueriesDispatcher,
    setActiveResponseIdDispatcher,
} from './dispatchers';

const {GET} = METHODS;

export const rest = {
    /**
     * Get activity
     */
    [GET_ACTIVITY]: {
        url: 'v3.0/analytics/activity/totals-counters?resolution=:viewingOption',
        transformer: getActivityTransformer,
        options: {
            method: GET,
        },
    },
    [GET_RESPONSE_COUNTERS]: {
        url: 'v3.0/analytics/activity/resolution-counters?resolution=:viewingOption',
        options: {
            method: GET,
        },
        transformer: responseCountersTransformer,
        postfetch: [
            ({dispatch, getState}) => {
                const state = getState();
                const responses = responseCountersSelector(state);
                if (!responses || responses.length === 0) {
                    return;
                }
                const responseId = responses[0].resolution_id;
                const payload = {
                    url: responses[0].resolution_queries_list_url,
                };
                setActiveResponseIdDispatcher(dispatch)(responseId);
                getResponseQueriesDispatcher(dispatch)(payload);
            },
        ],
    },
    [GET_RESPONSE_QUERIES]: {
        url: ':url',
        options: {
            method: GET,
        },
    },
};
