import React from 'react';

interface SpinnerProps {
    size?: number;
    color?: string;
    border?: number;
}

export class Spinner extends React.Component<SpinnerProps> {
    render() {
        const size = this.props.size || 100;
        const color = this.props.color || '#3A1C95';
        const border = this.props.border || 6;

        const spinnerStyle = {
            width: `${size}px`,
            height: `${size}px`,
        };
        const innerSpinnerStyle = {
            width: `${size - border}px`,
            height: `${size - border}px`,
            border: `${border}px solid ${color}`,
            borderColor: `${color} transparent transparent transparent`,
        };
        return (
            <div className="btn-spinner" style={spinnerStyle}>
                <div style={innerSpinnerStyle} />
                <div style={innerSpinnerStyle} />
                <div style={innerSpinnerStyle} />
                <div style={innerSpinnerStyle} />
            </div>
        );
    }
}
