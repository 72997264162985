import {Field, formValueSelector, reduxForm, FormSection} from 'redux-form';
import {connect} from 'react-redux';
import {map} from 'lodash';
import React, {Fragment} from 'react';

import {
    Button,
    Colorpicker,
    KareDropdown,
    KareSwitch,
    KareTextInput,
    Label,
    RadioInput,
    Spinner,
} from '@app/common';
import {LOCALES} from '@app/utils/locale/constants';
import {LauncherPreview} from './launcher-preview';
import {WidgetPreview} from './widget-preview';
import {startsWithHttps} from '../validators';
import {FIELDS, websafeFonts, WIDGET_FORM_NAME} from './constants';
import {redux} from '@app/redux';
import {Prompt} from 'react-router-dom';
import {WebExtensionForm} from './webextension-selector-form';
import {KareAvatarUpload} from '@app/common/kare-avatar-upload/KareAvatarUpload';

interface WidgetFormProps {
    handleSubmit?: any;
    getWidget?: any;
    invalid?: boolean;
    dirty?: boolean;
    error?: string;
    applicationId?: string;
    fontFamily?: string;
    widget?: any;
    font_family_text?: string;
    show_popover?: boolean;
    isWidgetDataLoading?: boolean;
    submitting?: boolean;
    popOverText?: string;
    primaryColor?: string;
    logoUrl?: string;
    launcherType?: string;
    initialValues?: any;
    bannerMessageText?: string;
    bannerHeight?: number;
    showMenuForm?: boolean;
    showAvatarForm?: boolean;
    position_enabled?: boolean;
    resize_controls_enabled?: boolean;
    org?: any;
    actions?: any[];
    links?: any[];
    change?: any;
    avatar?: any;
}

export interface Avatar {
    blob?: string;
    size?: number;
    title?: string;
    file?: File;
}

const {
    FONT_FAMILY,
    PRIMARY_COLOR_HEX,
    LOGO_URL,
    AVATAR_IMAGE,
    SHOW_AVATAR,
    LAUNCHER_TYPE,
    BANNER_MESSAGE_TEXT,
    // RESIZE_CONTROLS_ENABLED,
    RELOAD_CONVERSATION_ENABLED,
    SHOW_ESCALATION_BUTTON,
    ENABLE_TRACKING_BETA,
    ENABLE_TRACKING_TRY_NOW_PAGE,
    SHOW_WIDGET_MENU,
    BANNER_HEIGHT,
    CUSTOM_CSS,
} = FIELDS;

export class WidgetFormComponent extends React.Component<WidgetFormProps> {
    getLocaleOptions = () => {
        return map(LOCALES, (name, locale) => {
            return {value: locale, label: name};
        });
    };

    getFontOptions = () => {
        return websafeFonts.map((font) => {
            return {
                value: font,
                label: font,
            };
        });
    };

    renderAvatar = () => {
        const {showAvatarForm, avatar} = this.props;

        return (
            <div className="avatar">
                <div className="widget-avatar">
                    <h1 className="kare-h4">Avatar</h1>
                    <Field
                        style={{display: 'inline-block'}}
                        component={KareSwitch}
                        label={'Use avatar in conversation'}
                        name={SHOW_AVATAR}
                    />
                    {showAvatarForm && (
                        <div className="avatar-image">
                            <Field
                                style={{display: 'inline-block'}}
                                props={{
                                    avatar,
                                }}
                                component={KareAvatarUpload}
                                name={AVATAR_IMAGE}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    renderLauncherPopOver = () => {
        const {
            showMenuForm,
            /* position_enabled,
            resize_controls_enabled,*/
            org,
            actions,
            links,
        } = this.props;

        return (
            <div className="launcher-popover">
                <div className="widget-appbar-actions">
                    <h1 className="kare-h4">Widget top bar preferences</h1>
                    <Field
                        style={{display: 'inline-block'}}
                        component={KareSwitch}
                        label={'Show menu control'}
                        name={SHOW_WIDGET_MENU}
                    />
                    {showMenuForm && (
                        <Fragment>
                            <button
                                className="c-btn c-btn--inverted c-btn--has-icon"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        openWidgetMenuConfigurator: true,
                                    });
                                }}
                            >
                                Add extensions and links to the menu
                            </button>
                            {this.state.openWidgetMenuConfigurator && (
                                <FormSection name={FIELDS.WEB_EXTENSIONS}>
                                    <WebExtensionForm
                                        {...this.props}
                                        organisation={org}
                                        closeModal={() => {
                                            this.setState({
                                                openWidgetMenuConfigurator:
                                                    false,
                                            });
                                        }}
                                        actions={actions}
                                        links={links}
                                    />
                                </FormSection>
                            )}
                        </Fragment>
                    )}
                    <Field
                        component={KareSwitch}
                        label={'Show escalation control'}
                        name={SHOW_ESCALATION_BUTTON}
                    />
                    <Field
                        component={KareSwitch}
                        label={'Show reload control'}
                        name={RELOAD_CONVERSATION_ENABLED}
                    />

                    <h1 className="kare-h4 title">Tracking</h1>
                    <Field
                        component={KareSwitch}
                        label={'Enable tracking from try now page'}
                        name={ENABLE_TRACKING_TRY_NOW_PAGE}
                    />
                    <Field
                        component={KareSwitch}
                        label={'Enable tracking in beta domain'}
                        name={ENABLE_TRACKING_BETA}
                    />

                    <h1 className="kare-h4 title">
                        New response scroll behaviour
                    </h1>
                    <div className="launcher-scroll-options">
                        <Field
                            type="radio"
                            name={FIELDS.SCROLL_FOCUS}
                            label="Scroll to start"
                            value={'FOCUS_START'}
                            component={RadioInput}
                        />{' '}
                        <Field
                            type="radio"
                            name={FIELDS.SCROLL_FOCUS}
                            label="Scroll to end"
                            value={'FOCUS_END'}
                            component={RadioInput}
                        />
                    </div>

                    <h1 className="kare-h4">Advanced settings</h1>
                    <label className="widgetLabel">Custom CSS</label>
                    <span className="smallInfo">
                        This will be directly injected. Please double check that
                        they do what you expect them to do before saving.
                    </span>
                    <Field
                        style={{resize: 'auto'}}
                        component="textarea"
                        label={'Custom CSS'}
                        name={CUSTOM_CSS}
                        placeholder="Example: .glr-widget a { text-decoration: underline !important; }"
                    />
                </div>
            </div>
        );
    };

    state = {
        openWidgetMenuConfigurator: false,
        openResponses: false,
    };

    componentDidMount() {
        this.props.getWidget(this.props.applicationId);
    }

    componentDidUpdate(prevProps) {
        const {
            showMenuForm,
            change,
            org: {webext_hostname},
        } = this.props;

        const {showMenuForm: prevShowMenuForm} = prevProps;
        if (!prevShowMenuForm && showMenuForm) {
            change(`${FIELDS.WEB_EXTENSIONS}.HOSTNAME`, webext_hostname);
        }
        if (prevShowMenuForm && !showMenuForm) {
            change(`${FIELDS.WEB_EXTENSIONS}.HOSTNAME`, '');
        }
    }

    render() {
        const {
            handleSubmit,
            submitting,
            dirty,
            error,
            applicationId,
            fontFamily,
            initialValues,
            primaryColor,
            logoUrl,
            isWidgetDataLoading,
            bannerMessageText,
            bannerHeight,
        } = this.props;
        if (isWidgetDataLoading) {
            return <Spinner />;
        }

        return (
            <form className="settings__widget-form" onSubmit={handleSubmit}>
                <Prompt
                    message="Discard unsaved changes?"
                    when={dirty && !submitting}
                />
                <h1 className="kare-h4 u-margin-bottom-large">
                    Widget Preview
                </h1>
                <div className="settings_widget-form-columns">
                    <div className="settings_widget-form-first-column">
                        {error && <p className="kare-text-error">{error}</p>}
                        <Field
                            label="Primary Color"
                            props={{className: 'form-row'}}
                            name={PRIMARY_COLOR_HEX}
                            component={Colorpicker}
                        />
                        <Field
                            className="ftux__company-profile-field"
                            name={FONT_FAMILY}
                            component={KareDropdown}
                            label="Font"
                            props={{
                                options: this.getFontOptions(),
                            }}
                            format={(value) => {
                                return value
                                    ? {
                                          value,
                                          label: value,
                                      }
                                    : null;
                            }}
                            normalize={(value) => {
                                return value.value;
                            }}
                            placeholder="Select a font to user on the widget."
                        />
                        <Label text="Font example">
                            <p style={{fontFamily}}>
                                {'The quick brown fox jumps over the lazy dog'}
                            </p>
                        </Label>
                        <Field
                            name={LOGO_URL}
                            component={KareTextInput}
                            label="Logo"
                            tip="Customise the logo you want to appear on your widget."
                            warn={startsWithHttps}
                        />
                        <Field
                            name={BANNER_MESSAGE_TEXT}
                            component={KareTextInput}
                            label="Banner message text"
                            tip="Customise the text you want to appear in the banner message."
                        />
                        <Field
                            name={BANNER_HEIGHT}
                            component={KareTextInput}
                            label="Banner height"
                            tip="Customise the height of your banner to adapt it with your logo."
                            parse={(value) => Number(value)}
                            type="number"
                        />
                    </div>
                    <WidgetPreview
                        fontFamily={fontFamily}
                        primaryColor={primaryColor}
                        logoUrl={logoUrl}
                        bannerMessageText={bannerMessageText}
                        bannerHeight={bannerHeight}
                    />
                </div>
                {this.renderAvatar()}
                <div className="u-margin-bottom">
                    <LauncherPreview
                        applicationId={applicationId}
                        fontFamily={fontFamily}
                        initialValues={initialValues}
                    />
                </div>
                <div>{this.renderLauncherPopOver()}</div>
                <section className="submit-button-container">
                    <Button type="submit">Save changes</Button>
                </section>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        show_popover: formValueSelector(WIDGET_FORM_NAME)(
            state,
            FIELDS.WIDGET_POPOVER_ENABLED,
        ),
        show_popover_node:
            formValueSelector(WIDGET_FORM_NAME)(
                state,
                FIELDS.WIDGET_POPOVER,
            ) === FIELDS.POPOVER_NODE,
        resize_controls_enabled: formValueSelector(WIDGET_FORM_NAME)(
            state,
            FIELDS.RESIZE_CONTROLS_ENABLED,
        ),
        show_popover_text:
            formValueSelector(WIDGET_FORM_NAME)(
                state,
                FIELDS.WIDGET_POPOVER,
            ) === FIELDS.POPOVER_TEXT,
        popover_node: formValueSelector(WIDGET_FORM_NAME)(
            state,
            FIELDS.POPOVER_NODE,
        ),
        showMenuForm: formValueSelector(WIDGET_FORM_NAME)(
            state,
            SHOW_WIDGET_MENU,
        ),
        showAvatarForm: formValueSelector(WIDGET_FORM_NAME)(state, SHOW_AVATAR),
        fontFamily: formValueSelector(WIDGET_FORM_NAME)(state, FONT_FAMILY),
        popOverText: formValueSelector(WIDGET_FORM_NAME)(
            state,
            FIELDS.POPOVER_TEXT,
        ),
        logoUrl: formValueSelector(WIDGET_FORM_NAME)(state, LOGO_URL),
        primaryColor: formValueSelector(WIDGET_FORM_NAME)(
            state,
            PRIMARY_COLOR_HEX,
        ),
        launcherType: formValueSelector(WIDGET_FORM_NAME)(state, LAUNCHER_TYPE),
        bannerMessageText: formValueSelector(WIDGET_FORM_NAME)(
            state,
            BANNER_MESSAGE_TEXT,
        ),
        isWidgetDataLoading:
            redux.widget.selectors.isWidgetDataLoadingSelector(state),
        bannerHeight: formValueSelector(WIDGET_FORM_NAME)(state, BANNER_HEIGHT),
        actions:
            formValueSelector(WIDGET_FORM_NAME)(
                state,
                `${FIELDS.WEB_EXTENSIONS}.${FIELDS.WEB_EXTENSIONS_ACTIONS}`,
            ) || [],
        links:
            formValueSelector(WIDGET_FORM_NAME)(
                state,
                `${FIELDS.WEB_EXTENSIONS}.${FIELDS.WEB_EXTENSIONS_LINKS}`,
            ) || [],
        org: redux.org.selectors.orgSelector(state),
        avatar: formValueSelector(WIDGET_FORM_NAME)(state, AVATAR_IMAGE),
    };
};

const mapDispatchToProps = (dispatch) => ({
    getWidget: (id) =>
        dispatch(redux.rest.actions[redux.widget.constants.GET_WIDGET]({id})),
});

export const WidgetForm = connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    reduxForm({
        form: WIDGET_FORM_NAME,
    })(WidgetFormComponent),
);
