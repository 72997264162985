import _ from 'lodash';
import {CONVERSATION_STATS} from './conversation/constants';

export const get_first_and_last_conversation = (conversations) => {
    const orderedConversations = _.orderBy(conversations, 'last_message_time');
    let firstConvers = null;
    let lastConvers = null;
    if (orderedConversations && orderedConversations.length > 0) {
        firstConvers = orderedConversations[0].last_message_time;
        if (orderedConversations.length > 1) {
            lastConvers =
                orderedConversations[orderedConversations.length - 1]
                    .last_message_time;
        } else {
            lastConvers = firstConvers;
        }
    }
    return [firstConvers, lastConvers];
};

export const getFilteredConversations = (conversations, filters) => {
    if (!conversations) {
        return [];
    }
    return conversations.filter((c) => {
        let someZeroFound = false;
        filters.forEach((f) => {
            if (f === CONVERSATION_STATS.USER_ID) {
                if (!_.get(c, CONVERSATION_STATS.USER_ID, null)) {
                    someZeroFound = true;
                }
            } else {
                if (_.get(_.get(c, 'stats'), f, 0) === 0) {
                    someZeroFound = true;
                    return;
                }
            }
        });
        return !someZeroFound;
    });
};
