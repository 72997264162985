import React from 'react';
import {getCookie} from '@app/utils/auth';
import {getDashboardDomain} from '@app/utils/client-url';

interface BetaPreviewProps {
    subdomain: string;
}

export const BetaPreview = (props: BetaPreviewProps) => {
    const isBeta = JSON.parse(getCookie('beta'));
    if (!isBeta || props.subdomain === null) {
        return null;
    }
    return (
        <div
            className="kare-nav-bottom-section-beta-preview kare-nav-link"
            onClick={() => {
                window.location.href = `https://${
                    props.subdomain
                }.${getDashboardDomain()}`;
            }}
        />
    );
};
