import React, {Fragment} from 'react';
import {Field, reduxForm} from 'redux-form';
import {debounce} from 'lodash';

import {QUERIES_RESPONSE_FORM, QUERIES_RESPONSE_SEARCH} from './constants';
import {Spinner} from '@app/common';
import {AnotherResponse, Search} from '@app/common/icons';
import {connect} from 'react-redux';

interface QueriesResponseSearchProps {
    handleSubmit?: any;
    title?: string;
    isDisambiguation?: boolean;
    forceAllResponses?: boolean;
    handleForceAllResponses?: any;
}

export class QueriesResponseSearchComponent extends React.Component<
    QueriesResponseSearchProps,
    any
> {
    private readonly debouncedOnChange: any;
    constructor(props) {
        super(props);
        this.state = {
            debouncing: false,
        };
        this.debouncedOnChange = debounce(() => {
            this.setState({debouncing: false});
            this.props.handleSubmit();
        }, 350);
    }

    handleOnChange = () => {
        this.setState({debouncing: true});
        this.debouncedOnChange();
    };

    render() {
        return (
            <form
                className="queries-response-search-form"
                onSubmit={this.props.handleSubmit}
            >
                {this.props.isDisambiguation &&
                !this.props.forceAllResponses ? (
                    <div
                        className="queries-response-search-disambiguation"
                        onClick={this.props.handleForceAllResponses}
                    >
                        <AnotherResponse size={22} />
                        <span>See all responses</span>
                    </div>
                ) : (
                    <Fragment>
                        <h2 className="response-card-title">
                            {this.props.title}
                        </h2>
                        <div className="queries-response-search-field-container">
                            <Search />
                            <Field
                                onChange={this.handleOnChange}
                                component="input"
                                type="search"
                                normalize={(query) => query.toLowerCase()}
                                name={QUERIES_RESPONSE_SEARCH}
                                placeholder="Search for a response or create one"
                            />
                            {this.state.debouncing && (
                                <Spinner size={20} border={2} />
                            )}
                        </div>
                    </Fragment>
                )}
            </form>
        );
    }
}
const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = () => {
    return {};
};

export const QueriesResponseSearch = reduxForm({
    form: QUERIES_RESPONSE_FORM,
})(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(QueriesResponseSearchComponent),
);
