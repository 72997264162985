import React from 'react';
import {
    BarDatumWithColor,
    ResponsiveBar,
    BarTooltipProps,
    BarDatum,
} from '@nivo/bar';
import {AxisProps} from '@nivo/axes';
import {InheritedColorConfig} from '@nivo/colors/dist/types/inheritedColor';

import {getLabelFromDataKeys} from '@app/utils/DataRenderingUtils';

import './BarChart.scss';

interface BarChartProps {
    data: object[];
    keys: string[];
    index: string;
    groupMode?: 'stacked' | 'grouped';
    tickRotation?: number;
    margin?: any;
    layout?: 'horizontal' | 'vertical';
    axisBottom?: AxisProps | null;
    enableLabel?: boolean;
    labelColorConfig?: InheritedColorConfig<any>;
    label?: (...args: any) => string;
    height?: number;
    animate?: boolean;
    tooltip?: React.FC<BarTooltipProps<BarDatumWithColor>>;
}

export const BarChart: React.FunctionComponent<BarChartProps> = ({
    data,
    keys,
    index,
    groupMode = 'stacked',
    tickRotation = 0,
    margin = {top: 30, right: 30, bottom: 50, left: 60},
    layout = 'vertical',
    axisBottom = {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: tickRotation,
    },
    enableLabel = false,
    labelColorConfig = {from: 'color', modifiers: [['darker', 1.6]]},
    label,
    height,
    tooltip = ({id, value}) => (
        <div className="bar-chart-tooltip">
            {getLabelFromDataKeys(id.toString())} <strong>{value}</strong>
        </div>
    ),
    animate = true,
}) => {
    return (
        <div className="BarChart__container" style={{height: `${height}px`}}>
            <ResponsiveBar
                data={data as BarDatum[]}
                keys={keys}
                indexBy={index}
                groupMode={groupMode}
                margin={margin}
                layout={layout}
                enableLabel={enableLabel}
                labelTextColor={labelColorConfig}
                label={label}
                padding={0.3}
                borderWidth={3}
                colors={(c: any) => c.data[`${c.id}Color`]}
                axisTop={null}
                axisRight={null}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                }}
                axisBottom={axisBottom}
                labelSkipWidth={12}
                labelSkipHeight={12}
                animate={animate}
                motionConfig={{
                    tension: 90,
                    damping: 15,
                }}
                tooltip={tooltip}
            />
        </div>
    );
};
