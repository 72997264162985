import React from 'react';

interface ValidationWarningProps {
    children?: any;
}
export class ValidationWarning extends React.Component<ValidationWarningProps> {
    render() {
        const {children} = this.props;
        return <p className="c-field-warning">{children}</p>;
    }
}
