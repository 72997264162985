import React, {FunctionComponent as Fc} from 'react';
import {CX_TYPES} from './constants';
import './CxMetrics.scss';
import {Dissatisfied} from '@app/assets/icons/dissatisfied';
import {DATA_KEYS, getChartColor} from '@app/utils/DataRenderingUtils';
import {Neutral} from '@app/assets/icons/neutral';
import {Satisfied} from '@app/assets/icons/satisfied';
import {GreatlyDissatisfied} from '@app/assets/icons/greatly-dissatisfied';
import {ModeratelyDissatisfied} from '@app/assets/icons/moderately-dissatisfied';
import {ModeratelySatisfied} from '@app/assets/icons/moderately-satisfied';
import {GreatlySatisfied} from '@app/assets/icons/greatly-satisfied';

interface IFormulaSchemaProps {
    cxType: string;
}

export const FormulaSchema: Fc<IFormulaSchemaProps> = ({cxType}) => {
    const iconSize = 20;
    const colors = {
        greatlyDissatisfied: getChartColor(DATA_KEYS.GREATLY_DISSATISFIED),
        dissatisfied: getChartColor(DATA_KEYS.DISSATISFIED),
        moderatelyDissatisfied: getChartColor(
            DATA_KEYS.MODERATELY_DISSATISFIED,
        ),
        neutral: getChartColor(DATA_KEYS.NEUTRAL),
        moderatelySatisfied: getChartColor(DATA_KEYS.MODERATELY_SATISFIED),
        satisfied: getChartColor(DATA_KEYS.SATISFIED),
        greatlySatisfied: getChartColor(DATA_KEYS.GREATLY_SATISFIED),
    };

    const repeatElements = (
        times: number,
        func: (index: number) => any,
        index = 0,
        retValAcc: any = [],
    ): any => {
        if (times) {
            retValAcc.push(func(index));
            repeatElements(--times, func, ++index, retValAcc);
        }
        return retValAcc;
    };

    switch (cxType) {
        case CX_TYPES.NPS:
            return (
                <React.Fragment>
                    <div className="CxMetrics__icons">
                        {repeatElements(7, (index) => (
                            <div
                                key={'icon-dissatisfied' + index}
                                className="CxMetrics__icon"
                            >
                                <Dissatisfied
                                    size={iconSize}
                                    color={colors.dissatisfied}
                                />
                            </div>
                        ))}
                        {repeatElements(2, (index) => (
                            <div
                                key={'icon-neutral' + index}
                                className="CxMetrics__icon"
                            >
                                <Neutral
                                    size={iconSize}
                                    color={colors.neutral}
                                />
                            </div>
                        ))}
                        {repeatElements(2, (index) => (
                            <div
                                key={'icon-satisfied' + index}
                                className="CxMetrics__icon"
                            >
                                <Satisfied
                                    size={iconSize}
                                    color={colors.satisfied}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="CxMetrics__scores">
                        {repeatElements(7, (index) => (
                            <div
                                key={'score-dissatisfied' + index}
                                className="CxMetrics__score"
                            >
                                <span style={{color: colors.dissatisfied}}>
                                    {index}
                                </span>
                            </div>
                        ))}
                        {repeatElements(
                            2,
                            (index) => (
                                <div
                                    key={'score-neutral' + index}
                                    className="CxMetrics__score"
                                >
                                    <span style={{color: colors.neutral}}>
                                        {index}
                                    </span>
                                </div>
                            ),
                            7,
                        )}
                        {repeatElements(
                            2,
                            (index) => (
                                <div
                                    key={'score-satisfied' + index}
                                    className="CxMetrics__score"
                                >
                                    <span style={{color: colors.satisfied}}>
                                        {index}
                                    </span>
                                </div>
                            ),
                            9,
                        )}
                    </div>
                </React.Fragment>
            );
        case CX_TYPES.CSAT:
            return (
                <React.Fragment>
                    <div className="CxMetrics__fractionTop">
                        <span
                            style={{color: getChartColor(DATA_KEYS.SATISFIED)}}
                        >
                            Satisfied Customers
                        </span>
                        <Satisfied
                            size={20}
                            color={getChartColor(DATA_KEYS.SATISFIED)}
                        />
                    </div>
                    <div className="CxMetrics__fractionBottom">
                        <span>Total number of votes</span>
                    </div>
                </React.Fragment>
            );
        case CX_TYPES.CES:
            return (
                <React.Fragment>
                    <div className="CxMetrics__icons">
                        <div className="CxMetrics__icon">
                            <GreatlyDissatisfied
                                size={iconSize}
                                color={colors.greatlyDissatisfied}
                            />
                        </div>
                        <div className="CxMetrics__icon">
                            <Dissatisfied
                                size={iconSize}
                                color={colors.dissatisfied}
                            />
                        </div>
                        <div className="CxMetrics__icon">
                            <ModeratelyDissatisfied
                                size={iconSize}
                                color={colors.moderatelyDissatisfied}
                            />
                        </div>
                        <div className="CxMetrics__icon">
                            <Neutral size={iconSize} color={colors.neutral} />
                        </div>
                        <div className="CxMetrics__icon">
                            <ModeratelySatisfied
                                size={iconSize}
                                color={colors.moderatelySatisfied}
                            />
                        </div>
                        <div className="CxMetrics__icon">
                            <Satisfied
                                size={iconSize}
                                color={colors.satisfied}
                            />
                        </div>
                        <div className="CxMetrics__icon">
                            <GreatlySatisfied
                                size={iconSize}
                                color={colors.greatlySatisfied}
                            />
                        </div>
                    </div>
                    <div className="CxMetrics__difficultyHelper">
                        <div className="CxMetrics__difficult">
                            <span>Difficult</span>
                        </div>
                        <div className="CxMetrics__neutral">
                            <span>&nbsp;</span>
                        </div>
                        <div className="CxMetrics__easy">
                            <span>Easy</span>
                        </div>
                    </div>
                </React.Fragment>
            );
        default:
            return null;
    }
};
