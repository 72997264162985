import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import {redux} from '@app/redux';
import {Check} from '@app/common/icons';
import {ACCOUNT_STATUS_STATIC_DATA} from '../constants';

interface EmptyStateProps {
    isWidgetPublished?: boolean;
    areCompanyDetailsComplete?: boolean;
}

export class EmptyStateComponent extends React.Component<EmptyStateProps> {
    render() {
        const {isWidgetPublished, areCompanyDetailsComplete} = this.props;
        const rows = [
            {
                title: ACCOUNT_STATUS_STATIC_DATA.company.title,
                description: ACCOUNT_STATUS_STATIC_DATA.company.detail,
                linkText: ACCOUNT_STATUS_STATIC_DATA.company.button.text,
                href: ACCOUNT_STATUS_STATIC_DATA.company.button.link,
                stepComplete: areCompanyDetailsComplete,
            },
        ];

        rows.push({
            title: ACCOUNT_STATUS_STATIC_DATA.widget.title,
            description: ACCOUNT_STATUS_STATIC_DATA.widget.detail,
            linkText: ACCOUNT_STATUS_STATIC_DATA.widget.button.text,
            href: ACCOUNT_STATUS_STATIC_DATA.widget.button.link,
            stepComplete: isWidgetPublished,
        });

        return (
            <section className="dashboard-empty-state">
                <div className="dashboard-empty-state-body">
                    <h1>Get started</h1>
                    <h2>
                        Set up your account to start self-serving your
                        customers.
                    </h2>
                    <div className="dashboard-empty-state-rows">
                        {rows.map((row, index) => {
                            return (
                                <section
                                    key={row.title}
                                    className="dashboard-empty-state-row"
                                >
                                    <div className="dashboard-empty-state-row-left">
                                        <h1>
                                            <span>{index + 1}.</span>
                                            {row.title}
                                        </h1>
                                        <p>{row.description}</p>
                                    </div>
                                    {row.stepComplete ? (
                                        <div className="step-complete-check">
                                            <Check size={15} />
                                        </div>
                                    ) : (
                                        <Link className="c-btn" to={row.href}>
                                            {row.linkText}
                                        </Link>
                                    )}
                                </section>
                            );
                        })}
                    </div>
                </div>
                <div className="dashboard-empty-state-footer">
                    <p>
                        If you have previous customer conversations on any
                        channel, get in touch with us. We will import your data
                        and provide you congnitive insights, so you can start
                        bootstrapping your self service.
                    </p>
                    <div className="dashboard-empty-state-buttons">
                        <a
                            className="c-btn c-btn--outlined"
                            href="mailto:support@karehq.com"
                        >
                            Get in touch
                        </a>
                        <a
                            className="c-btn c-btn--inverted"
                            href="/company_domain.csv"
                        >
                            Download sample CSV
                        </a>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => ({
    isWidgetPublished:
        redux.onboarding.selectors.isWidgetPublishedSelector(state),
    areCompanyDetailsComplete:
        redux.onboarding.selectors.areCompanyDetailsCompleteSelector(state),
});

export const EmptyState = connect(mapStateToProps)(EmptyStateComponent);
