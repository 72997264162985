import {get} from 'lodash';
import * as constants from './constants';
import {DATA, LOADING} from '../constants';

export const isGetSubscriptionLoadingSelector = (state) => {
    return get(state, [constants.GET_SERVICES, LOADING]);
};

export const isProviderConnected = (state) => (provider) => {
    return get(state, [constants.GET_SERVICES, DATA, provider], false);
};
