export const QUERY_LIST_FORM = 'QuerySelectionForm';
export const QUERY_ARRAY_OF_FIELDS = 'QueriesArray';
export const REMOVE_ALL_SELECTED_QUERIES_ACTION_TYPE =
    '@@improve/queries/REMOVE_SELECTED_QUERIES';
export const APPEND_MUTED_QUERIES_ACTION_TYPE =
    '@@improve/queries/APPEND_MUTED_QUERIES_ACTION_TYPE';
export const REMOVE_MUTED_QUERIES_ACTION_TYPE =
    '@@improve/queries/REMOVE_MUTED_QUERIES_ACTION_TYPE';
export const MUTED_QUERIES = 'MUTED_QUERIES';
export const GET_MUTED_QUERIES = 'GET_MUTED_QUERIES';
export const DELETE_MUTED_QUERIES = 'DELETE_MUTED_QUERIES';
