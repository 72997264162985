import React from 'react';
import {Send, LocalPhone, Remove} from '@app/common/icons';
import {color} from '@app/utils';
import {widgetDefaults, FIELDS} from '../constants';

interface WidgetPreviewProps {
    primaryColor?: string;
    fontFamily?: string;
    logoUrl?: any;
    bannerMessageText?: any;
    bannerHeight?: any;
}

export class WidgetPreview extends React.Component<WidgetPreviewProps> {
    render() {
        const {primaryColor, fontFamily} = this.props;
        let {logoUrl, bannerMessageText} = this.props;
        bannerMessageText = bannerMessageText
            ? bannerMessageText
            : widgetDefaults[FIELDS.BANNER_MESSAGE_TEXT];
        logoUrl = logoUrl ? logoUrl : widgetDefaults[FIELDS.LOGO_URL];
        bannerMessageText = bannerMessageText
            ? bannerMessageText
            : widgetDefaults[FIELDS.BANNER_MESSAGE_TEXT];
        const welcome_message = 'Welcome, I am here to answer your questions.';
        const contrastingTextColor =
            color.getContrastingTextColor(primaryColor);
        return (
            <section style={{fontFamily}} className="settings__widget-preview">
                <div
                    style={{
                        backgroundColor: primaryColor,
                    }}
                    className="settings__widget-preview-banner"
                >
                    <div className="settings__widget-preview-banner-top-bar">
                        <LocalPhone
                            size={16}
                            style={{
                                color: primaryColor,
                                backgroundColor: contrastingTextColor,
                            }}
                        />
                        <Remove
                            size={16}
                            style={{
                                color: primaryColor,
                                backgroundColor: contrastingTextColor,
                            }}
                        />
                    </div>
                    <div className="settings__widget-preview-banner-logo-section">
                        <img src={logoUrl} />
                    </div>
                    <div
                        style={{
                            fontFamily,
                            color: contrastingTextColor,
                        }}
                        className="settings__widget-preview-banner-tag-section"
                    >
                        <span>{bannerMessageText}</span>
                    </div>
                </div>
                <div
                    style={{
                        fontFamily,
                    }}
                    className="settings__widget-preview-messages"
                >
                    <div className="settings__widget-preview-message">
                        {welcome_message}
                    </div>
                </div>
                <div
                    style={{
                        fontFamily,
                    }}
                    className="settings__widget-preview-composer"
                >
                    <span>Ask a question</span>
                    <Send size={24} />
                </div>
            </section>
        );
    }
}
