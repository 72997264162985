import {get} from 'lodash';
import {
    GET_CONVERSATION_METRICS,
    GET_FEEDBACK_METRICS,
    GET_QUERY_METRICS,
    GET_TRAFFIC_SOURCES_METRICS,
} from './constants';
import {DATA, ERROR, LOADING} from '../constants';

const metrics = {
    conversation: GET_CONVERSATION_METRICS,
    query: GET_QUERY_METRICS,
    feedback: GET_FEEDBACK_METRICS,
};

export const getConversationMetrics = (state) => {
    return get(state, [GET_CONVERSATION_METRICS, 'data']);
};

export const getMetrics = (metric, state) => {
    return get(state, [metrics[metric], 'data']);
};

export const getQueryMetrics = (state) => {
    return get(state, [GET_QUERY_METRICS, 'data']);
};
export const getFeedbackMetrics = (state) => {
    return get(state, [GET_FEEDBACK_METRICS, 'data']);
};

export const isMetricsLoading = (metric, state) => {
    return get(state, [metrics[metric], LOADING]);
};

export const isAnyMetricsApiLoading = (state) => {
    return (
        isMetricsLoading('conversation', state) ||
        isMetricsLoading('query', state) ||
        isMetricsLoading('feedback', state)
    );
};

export const isAllMetricsEmpty = (state) => {
    return (
        get(getConversationMetrics(state), 'counters', []).length === 0 &&
        get(getQueryMetrics(state), 'counters', []).length === 0 &&
        get(getFeedbackMetrics(state), 'counters', []).length === 0
    );
};

export const getTrafficSourcesTimeseriesSelector = (state) => {
    return get(state, [GET_TRAFFIC_SOURCES_METRICS, DATA]);
};

export const isTrafficSourcesTimeseriesFetchingSelector = (state) => {
    return get(state, [GET_TRAFFIC_SOURCES_METRICS, LOADING]);
};

export const trafficSourcesTimeseriesErrorSelector = (state) => {
    return get(state, [GET_TRAFFIC_SOURCES_METRICS, ERROR]);
};
