import React, {useState} from 'react';
import './ActionListSelector.scss';
import {ActionList} from '@app/components/list/ActionsList';
import {Button} from '../inputs/buttons/Button';
import {TextInput} from '../inputs/text/TextInput';

interface ActionListSelectorProps {
    actions: any[];
    onSelectedAction?: (action: any) => void;
    onClear?: () => void;
}

export const ActionListSelector: React.FunctionComponent<
    ActionListSelectorProps
> = (props: ActionListSelectorProps) => {
    const [filteredActions, setFilteredActions] = useState(props.actions);
    const [selectedAction, setSelectedAction] = useState<any | null>(null);
    if (!props.actions || props.actions.length === 0) {
        return null;
    }
    return (
        <div className="ActionList__container">
            <TextInput
                classname="ActionList__inputContainer"
                placeholder="Search in your actions"
                onChange={(text: string) => {
                    setFilteredActions(
                        props.actions.filter((a) =>
                            a.title.toLowerCase().includes(text.toLowerCase()),
                        ),
                    );
                }}
                {...props}
            />
            <ActionList
                actions={filteredActions}
                onActionSelected={(action: any) => {
                    setSelectedAction(action);
                }}
            />
            <div className="ActionList__buttons">
                <Button
                    text="Cancel"
                    type="cancel"
                    onClick={() => {
                        if (props.onClear) {
                            props.onClear();
                        }
                    }}
                />
                <Button
                    text="ADD"
                    type="confirm"
                    onClick={() => {
                        if (props.onSelectedAction && selectedAction) {
                            props.onSelectedAction(selectedAction);
                        }
                    }}
                />
            </div>
        </div>
    );
};
