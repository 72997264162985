import React from 'react';
import Icon from 'react-icon-base';

export const SomewhatSatisfied = (props) => (
    <Icon viewBox="0 0 15 15" {...props}>
        <defs>
            <path
                d="M7.4925,0 C11.64,0 15,3.36 15,7.5 C15,11.64 11.64,15 7.4925,15 C3.3525,15 0,11.64 0,7.5 C0,3.36 3.3525,0 7.4925,0 Z M7.5,1.5 C4.185,1.5 1.5,4.185 1.5,7.5 C1.5,10.815 4.185,13.5 7.5,13.5 C10.815,13.5 13.5,10.815 13.5,7.5 C13.5,4.185 10.815,1.5 7.5,1.5 Z M8.9775,10.1025 C9.7275,10.95 9.0075,10.1475 9.735,10.965 C9.09,11.3775 8.3325,11.625 7.5,11.625 C6.675,11.625 5.9175,11.385 5.2725,10.9725 C5.385,10.845 5.94,10.215 6.03,10.11 C6.465,10.365 6.9525,10.5 7.5,10.5 C8.0475,10.5 8.535,10.365 8.9775,10.1025 Z M10.125,4.5 C10.7463203,4.5 11.25,5.00367966 11.25,5.625 C11.25,6.24632034 10.7463203,6.75 10.125,6.75 C9.50367966,6.75 9,6.24632034 9,5.625 C9,5.00367966 9.50367966,4.5 10.125,4.5 Z M4.875,4.5 C5.49632034,4.5 6,5.00367966 6,5.625 C6,6.24632034 5.49632034,6.75 4.875,6.75 C4.25367966,6.75 3.75,6.24632034 3.75,5.625 C3.75,5.00367966 4.25367966,4.5 4.875,4.5 Z"
                id="path-5"></path>
        </defs>
        <g
            id="icon-feedback-somewhat_satisfied"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd">
            <mask id="mask-5" fill="white">
                <use xlinkHref="#path-5"></use>
            </mask>
            <use
                id="Combined-Shape"
                fill="currentColor"
                fillRule="nonzero"
                xlinkHref="#path-5"></use>
        </g>
    </Icon>
);
