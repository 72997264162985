import React from 'react';
import cx from 'classnames';
import './Checkbox.scss';
import {useState} from 'react';
import question from './question.svg';

interface ICheckbox {
    text: string;
    selected: boolean | undefined;
    onChange?: (isSelected: boolean) => any | undefined;
    helpMessage: string | undefined;
}

export const Checkbox: React.FunctionComponent<ICheckbox> = (props) => {
    const [showHelp, setShowHelp] = useState(false);
    return (
        <div className="Checkbox__container">
            <div
                className="Checkbox__checkboxContainer"
                onClick={() => {
                    props.onChange?.(!props.selected);
                }}
            >
                <div
                    className={cx('Checkbox__checkbox', {
                        ['active']: props.selected,
                    })}
                ></div>
                <div className="Checkbox__label">{props.text}</div>
            </div>
            {props.helpMessage && (
                <div className="Checkbox__helpContainer">
                    <img
                        className="Checkbox__helpIcon"
                        src={question}
                        onMouseEnter={() => {
                            setShowHelp(true);
                        }}
                        onMouseLeave={() => {
                            setShowHelp(false);
                        }}
                    />
                    {props.helpMessage && showHelp && (
                        <div className="Checkbox__helpText">
                            {props.helpMessage}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
