import styled from '@emotion/styled';

export const StyledLink = styled.a`
    font-family: 'Inter', 'Roboto Mono', arial, sans-serif;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: normal;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
    height: 32px;
    color: #3a1c95;
    border: 1px solid #3a1c95;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        text-decoration: none;
        background-color: $color-purple-100;
    }
`;

export const LoginTitle = styled.span`
    margin-left: 16px;
    color: #3a1c95;
`;
