import Axios, {AxiosResponse} from 'axios';
import axios from 'axios';
import {toast} from 'react-toastify';

import {TypeContent} from './UploadContentModal';

export const uploadFile = (
    type: TypeContent,
    file: File,
    onSuccess: () => {},
) => {
    const formData = new FormData();
    if (type === TypeContent.DOCUMENT) {
        formData.append('content', file);

        Axios.post(`v3.0/ingestion/upload?status=published`, formData).then(
            (response: AxiosResponse<any>) => {
                if (response && response.data && response.data.id) {
                    toast.success('Your file has been uploaded successfully');
                    onSuccess();
                }
            },
            () => {
                toast.error('There has been an error uploading your file');
            },
        );
    }
};

export const attachContent = (type: TypeContent, content, onSuccess) => {
    switch (type) {
        case TypeContent.DOCUMENT:
            return uploadFile(type, content, onSuccess);
        case TypeContent.PHOTO:
        case TypeContent.VIDEO:
            return uploadFile(type, content, onSuccess);
        case TypeContent.ATTACHMENT:
            return uploadAttachment(type, content, onSuccess);
    }
};

export const getMediaType = (media) => {
    if (!media) {
        return undefined;
    }
    if (media.type.includes('image/')) {
        return 'photo';
    }
    if (media.type.includes('video/')) {
        return 'video';
    }
    return media.type;
};

export const uploadAttachment = (type: TypeContent, media, onSuccess) => {
    const mediaType = getMediaType(media);
    switch (mediaType) {
        case 'photo':
            return uploadMedia(TypeContent.PHOTO, media, onSuccess);
        case 'video':
            return uploadMedia(TypeContent.VIDEO, media, onSuccess);
        default:
            return uploadAssetDocument(media, onSuccess);
    }
};

const uploadAssetDocument = (media, onSuccess) => {
    const formData = new FormData();
    formData.append('asset', media);
    axios
        .post(`v3.0/kbm/assets/attachments`, formData)
        .then((response: AxiosResponse<any>) => {
            if (response && response.data && response.data.url) {
                onSuccess(response.data.url);
            }
        });
};

export const uploadMedia = (type: TypeContent, media, onSuccess) => {
    const formData = new FormData();
    formData.append('asset', media);
    const urlAsset = type === TypeContent.PHOTO ? 'pictures' : 'videos';
    axios
        .post(`v3.0/kbm/assets/${urlAsset}`, formData)
        .then((response: AxiosResponse<any>) => {
            if (response && response.data && response.data.url) {
                onSuccess(response.data.url);
            }
        });
};
