import React from 'react';
import ConnectForm from './connect-form';
import config from '@app/../../config/integrations';
import {buildAuthUrl, getProviderName, persistDetails} from './utils';

interface ConnectProps {
    match: any;
}

class Connect extends React.Component<ConnectProps> {
    handleConnect = (values) => {
        const {
            match: {
                params: {provider},
            },
        } = this.props;
        persistDetails(provider, values);

        // @ts-ignore
        window.location = buildAuthUrl(provider, values, config[provider]);
    };

    render() {
        const {
            match: {
                params: {provider, preFilledDomain},
            },
        } = this.props;
        return (
            <div className="kare-center-block">
                <div className="kare-single-column-container">
                    <h2 className="kare-h2 u-margin-bottom">
                        Connect {getProviderName(provider)}
                    </h2>

                    <p className="u-margin-bottom-large">
                        Authorise Dialpad to{' '}
                        <strong>capture Help Center articles</strong>, and{' '}
                        <strong>identify frequent questions</strong> from your
                        customers.
                    </p>

                    <ConnectForm
                        provider={provider}
                        onSubmit={this.handleConnect}
                        initialValues={{domain: preFilledDomain}}
                    />
                </div>
            </div>
        );
    }
}

export default Connect;
