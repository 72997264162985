import React, {useContext, useEffect, useState} from 'react';

import './ClusterTreeMap.scss';
import {TreeMap} from '@app/components/TreeMap/TreeMap';
import {StoreContext} from '@app/state';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';

interface IClusterTreeMapProps {
    history: any;
}
const defaultInitialState = {
    data: null,
    loading: true,
    error: null,
};
export const ClusterTreeMap: React.FunctionComponent<IClusterTreeMapProps> = ({
    history,
}) => {
    const [clustersData, setClustersData] = useState(defaultInitialState);

    const {
        clusters,
        date: {minDate, maxDate},
    } = useContext(StoreContext);
    const handleOnClick = (node: {nodeId: string}) => {
        history.push(`/improve/${node.nodeId}?origin=/bi/performance`);
    };
    const getClusters = async () => {
        setClustersData(defaultInitialState);
        const res = await clusters.getClusters();
        setClustersData({data: res.data, error: res.error, loading: false});
    };
    const showEmptyState = () => {
        if (!clustersData.data || clustersData.data.length === 0) {
            return 'No data';
        }
        return '';
    };

    useEffect(() => {
        getClusters();
    }, [minDate, maxDate]);
    return (
        <ErrorLoadingWrapper
            height={'200px'}
            isLoading={clustersData.loading}
            error={clustersData.error}
            emptyState={showEmptyState()}
            CustomError={<h3>We are currently processing your queries</h3>}
            CustomEmptyState={<h3>No responses to show</h3>}
            render={() => {
                return (
                    <div className="ClusterTreeMap__container">
                        <TreeMap
                            data={clustersData.data}
                            onClick={handleOnClick}
                        />
                    </div>
                );
            }}
        />
    );
};
