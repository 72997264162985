import React, {useState} from 'react';
// @ts-ignore
import './ActionList.scss';
import {MdFlashOn} from 'react-icons/md';
import cx from 'classnames';

interface ActionListProps {
    actions: any;
    onActionSelected?: (action: any, position: number) => void;
}

export const ActionList: React.FunctionComponent<ActionListProps> = (props) => {
    const [selectedAction, setSelectedAction] = useState<any | null>(null);
    if (!props.actions || props.actions.length === 0) {
        return null;
    }
    return (
        <div className="ActionList__container">
            {props.actions.map((a: any, i: number) => {
                const selectedCx = cx('ActionList__item', {
                    ['ActionList__itemSelected']:
                        selectedAction && a.name === selectedAction.name,
                });
                return (
                    <div
                        className={selectedCx}
                        key={a.name}
                        onClick={() => {
                            setSelectedAction(a);
                            if (props.onActionSelected) {
                                props.onActionSelected(a, i);
                            }
                        }}
                    >
                        <MdFlashOn />
                        {a.title}
                    </div>
                );
            })}
        </div>
    );
};
