import React from 'react';
import {Bar, BarChart, CartesianGrid, XAxis, YAxis} from 'recharts';
import {CHART_CONFIG} from '../../constants';
import {getBarWidth} from './utils';
import {XAxisTickRenderer} from './x-tick-renderer';

interface DashboardActivityQueriesChartProps {
    viewingOption?: string;
    activityData?: {
        date: any;
        name: string;
        resolved: number;
        bounced: number;
        escalated: number;
    }[];
}

export class DashboardActivityQueriesChart extends React.Component<DashboardActivityQueriesChartProps> {
    labelFormatter = (labelValue) => (labelValue === 0 ? '' : labelValue);

    render() {
        const {activityData} = this.props;
        const barWidth = getBarWidth(activityData.length);
        return (
            <div className="dashboard-activity__chart">
                <span className="dashboard-activity__chart-y-axis-title">
                    QUERIES
                </span>
                <BarChart
                    width={CHART_CONFIG.width}
                    height={CHART_CONFIG.height}
                    data={activityData}
                    margin={CHART_CONFIG.margin}
                >
                    <CartesianGrid
                        strokeDasharray="0"
                        vertical={false}
                        stroke={CHART_CONFIG.gridColor}
                    />
                    <XAxis
                        tickLine={false}
                        dataKey="name"
                        tickMargin={0}
                        minTickGap={0}
                        tick={<XAxisTickRenderer />}
                    />
                    <YAxis
                        allowDecimals={false}
                        tickLine={false}
                        tickFormatter={this.labelFormatter}
                        tick={{fontSize: CHART_CONFIG.labels.size}}
                        width={70}
                        domain={[0, 'dataMax']}
                    />
                    <Bar
                        dataKey={CHART_CONFIG.responsesKey}
                        fill={CHART_CONFIG.responsesColor}
                        stackId={CHART_CONFIG.stackId}
                        barSize={barWidth}
                    />
                    <Bar
                        dataKey={CHART_CONFIG.disambiguationsKey}
                        fill={CHART_CONFIG.disambiguationsColor}
                        stackId={CHART_CONFIG.stackId}
                        barSize={barWidth}
                    />
                    <Bar
                        dataKey={CHART_CONFIG.missingKey}
                        fill={CHART_CONFIG.missingColor}
                        stackId={CHART_CONFIG.stackId}
                        barSize={barWidth}
                    />
                </BarChart>
            </div>
        );
    }
}
