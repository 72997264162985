import React from 'react';
import {Link} from 'react-router-dom';
import cx from 'classnames';
import {withRouter} from 'react-router';

import {Close, ChevronLeft} from '@app/common/icons';

interface PanelHeaderProps {
    closeLink?: string;
    title?: any;
    backLink?: string;
    invertColors?: boolean;
    children?: any;
    onClose?: any;
    history?: any;
}

/**
 * Reusable component that acts as a header for a portal panel.
 */
export class PanelHeaderComponent extends React.Component<PanelHeaderProps> {
    handleClickClose = () => {
        const {closeLink, onClose, history} = this.props;
        if (onClose) {
            onClose();
        } else if (closeLink) {
            history.push(closeLink);
        }
    };

    renderCloseButton = () => {
        return (
            <div
                onClick={this.handleClickClose}
                className="response-editor__portal-header-close-button"
            >
                <Close size={21} />
            </div>
        );
    };

    render() {
        const {title, backLink, children, invertColors = false} = this.props;
        const className = cx('response-editor__portal-header', {
            invertColors,
        });

        return (
            <header className={className}>
                <div>
                    {backLink && (
                        <Link
                            to={backLink}
                            className="knowledge-base__portal-header-back-link"
                        >
                            <ChevronLeft size={40} />
                        </Link>
                    )}
                    <h1 className="response-editor__portal-header-title">
                        {title}
                    </h1>
                    {this.renderCloseButton()}
                </div>
                {children}
            </header>
        );
    }
}

export const PanelHeader = withRouter(PanelHeaderComponent);
