import {get} from 'lodash';
const isAppendRequest = (options) => {
    return get(options, ['request', 'pathvars', 'append']);
};
/**
 * If its an append request (load more) we append the new data.
 * If not, we update the previous result
 * object
 * @param data
 * @param prevData
 * @returns { sessions: [data]}
 */
export const getActivityTransformer = (data, prevData, options) => {
    return isAppendRequest(options) ? [...prevData.sessions, ...data] : data;
};

export const responseCountersTransformer = (data) => {
    if (!data) {
        return data;
    }
    const sortedData = {
        ...data,
        resolution_counters: data.resolution_counters.sort(
            (a, b) => b.responses - a.responses,
        ),
    };
    return sortedData;
};
