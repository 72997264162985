import React, {useContext, useEffect, useState} from 'react';
import {get} from 'lodash';
import {ResponsivePie} from '@nivo/pie';
import stc from 'string-to-color';

import './Journey.scss';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {StoreContext} from '@app/state';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {RadialContentLoader} from '@app/components/ContentLoaders/RadialContentLoader';
import {transformAggregatedJourneyDataToRadialChart} from '../../../utils/ConversationDataTransform';
import {calcRatio} from '@app/utils/Common';
import {ChartLegend} from '@app/components/ChartLegend/ChartLegend';

export interface JourneyProps {}
const initialState = {
    data: {} as any,
    error: null,
    loading: true,
};

export const Journey: React.FC<JourneyProps> = () => {
    const [journey, setJourney] = useState(initialState);
    const {analytics} = useContext(StoreContext);
    const {minDate, maxDate} = useContext(StoreContext).date;

    const getOpportunities = async () => {
        setJourney(initialState);
        const res = await analytics.getAggregatedWebext(minDate, maxDate);
        setJourney({
            loading: false,
            error: res.error,
            data: res.data,
        });
    };
    const showEmptyState = () => {
        const webext = get(journey, ['data', 'entries', 'webext'], {});

        const keys = Object.keys(webext);
        if (!(keys.length > 0)) {
            return 'No data';
        }

        const transformedData = transformAggregatedJourneyDataToRadialChart(
            journey.data,
        );

        if (
            !transformedData.plotData.automations ||
            transformedData.plotData.automations.length === 0
        ) {
            return 'No data';
        }

        return null;
    };
    useEffect(() => {
        getOpportunities();
    }, [minDate, maxDate]);

    const transformedData = transformAggregatedJourneyDataToRadialChart(
        journey.data,
    );

    return (
        <div>
            <CardTitle title={'Journey automations'} />
            <ErrorLoadingWrapper
                height={'200px'}
                error={journey.error}
                isLoading={journey.loading}
                CustomLoading={RadialContentLoader}
                emptyState={showEmptyState()}
                render={() => {
                    const chartData = Object.keys(
                        transformedData.plotData.automations,
                    ).map((k) => {
                        const automation =
                            transformedData?.plotData?.automations[k];
                        return {
                            id: automation.id || automation.automation_id,
                            label:
                                automation.title || automation.automation_title,
                            value: automation.counters.clicks,
                            ratio: calcRatio(
                                automation.counters.clicks,
                                transformedData?.plotData?.totalClicks,
                            ),
                            color: stc(automation.automation_title),
                        };
                    });
                    return (
                        <div className="Journey__container">
                            <div className="Journey__chartContainer">
                                <ResponsivePie
                                    data={chartData}
                                    margin={{
                                        top: 20,
                                        right: 20,
                                        bottom: 20,
                                        left: 20,
                                    }}
                                    sortByValue={false}
                                    innerRadius={0.7}
                                    padAngle={1}
                                    colors={(c: any) => c.data.color as string}
                                    borderWidth={0}
                                    enableArcLinkLabels={false}
                                    enableArcLabels={false}
                                />
                            </div>
                            <ChartLegend
                                oneLineLegend={chartData.length > 3}
                                items={chartData.map((d) => {
                                    return {
                                        color: d.color,
                                        title: d.label,
                                        value: d.value,
                                        percentage: d.ratio,
                                    };
                                })}
                            />
                        </div>
                    );
                }}
            />
        </div>
    );
};
