import Axios from 'axios';

export class API {
    async callApi(URL: string) {
        const res = {
            data: null,
            error: null,
        };
        try {
            const req = await Axios.get(URL);
            res.data = req.data;
        } catch (e) {
            res.error = e;
        }
        return res;
    }
}
