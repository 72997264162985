import React from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {redux} from './redux';
import './_index.scss';
import {EmptyLayout, MainLayout} from './common/layouts';
import {constants as emptyLayoutConstants} from './common/layouts/empty-layout';
import {Integrations} from './common/integrations';
import {Complete} from './common/integrations/connect/complete';
import {Dashboard} from './dashboard/';
import {Responses} from './responses';
import {Documents} from './documents';
import {Login} from './login';
import {NotFound} from './not-found';
import {Settings} from './settings';
import {Activity} from './activity';
import {Widget} from './widget';
import {
    RequestResetPassword,
    ResetPassword,
    ResetPasswordComplete,
    ResetPasswordEmailSent,
} from './reset-password';
import {Improve} from './improve';
import {querystring, url, permissions} from './utils';
import {StyleGuide} from './styleguide';
import {ResponsePortal} from './responses/response-portal';
import {Refresh} from './refresh';
import {isEmpty} from 'lodash';
import {Spinner} from './common';
import {COMPOSE, PERMISSION_KEYS} from './constants';
import {ToastContainer} from 'react-toastify';
import {Actions} from './actions';
import {LockDown} from './lockdown';
import {Copyright} from './copyright';
import {getCookie, saveOrgIdOnCookies, setCookie, hasScope} from './utils/auth';
import {Treemap} from './treemap';
import {
    KareLibDashboard,
    KareLibImprove,
    KareLibAudience,
    KareLibDialog,
    ChatterMessages,
} from '@app/dashboard';
import {
    DialpadLoginComplete,
    DIALPAD_LOGIN_REDIRECT_URL,
    DialpadLoginStart,
    DIALPAD_LOGIN_START_URL,
} from './login/providers/dialpad';
import {DIALPAD_SUPER_ADMIN_LOGIN_URL} from './login/providers/dialpad/urls';
import {SuperAdminLoginComplete} from './login/super-admin';

interface IndexProps {
    location?: {
        pathname?: string;
        search?: string;
    };
    sendLocationChangeEvent?: any;
    org?: any;
    getOrganisation?: any;
    getApplications?: any;
    isLoggedIn?: boolean;
    getWidget?: any;
    scopes?: any[];
    history?: any;
    userId?: string;
    application?: any;
    isApplicationLoading?: boolean;
}

export class IndexComponent extends React.Component<IndexProps> {
    state = {orgDataLoaded: false};

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {isLoggedIn, location, scopes} = this.props;
        // Add beta boolean in cookie to determine later if the console is mode Preview Beta or not, and thus give feedback.
        const paths = window.location.host.split('.');
        setCookie(
            'beta',
            JSON.stringify(paths.length >= 2 && paths[1] === 'beta'),
        );

        const currentLocation = `${location.pathname}${location.search}`;

        if (isLoggedIn && currentLocation !== '/lockdown') {
            this.getApplicationConfiguration();
            this.getOrg();
        }

        if (scopes.length > 0) {
            if (
                !hasScope(scopes, PERMISSION_KEYS.MIND.ALL) &&
                !hasScope(scopes, PERMISSION_KEYS.MIND.KNOWLEDGE.READ) &&
                !hasScope(scopes, PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE) &&
                !hasScope(scopes, PERMISSION_KEYS.MIND.DIALOG)
            ) {
                this.props.history.push('/settings');
            }
        }

        const script = document.createElement('script');

        script.src = `https://www.google.com/recaptcha/api.js?render=${getCookie(
            'GOOGLE_RECAPTCHA_TOKEN',
        )}`;
        script.async = true;

        document.body.appendChild(script);
    }

    componentDidUpdate(prevProps) {
        const {location, org, isLoggedIn, scopes, history} = this.props;

        const currentLocation = `${location.pathname}${location.search}`;
        if (currentLocation !== '/lockdown') {
            if (
                (isLoggedIn && !prevProps.isLoggedIn) ||
                (prevProps.scopes.length === 0 && scopes.length > 0)
            ) {
                this.getApplicationConfiguration();
                this.getOrg();
                if (scopes.length > 0) {
                    if (
                        !hasScope(scopes, PERMISSION_KEYS.MIND.ALL) &&
                        !hasScope(
                            scopes,
                            PERMISSION_KEYS.MIND.KNOWLEDGE.READ,
                        ) &&
                        !hasScope(
                            scopes,
                            PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE,
                        ) &&
                        !hasScope(scopes, PERMISSION_KEYS.MIND.DIALOG)
                    ) {
                        history.push('/settings');
                    }
                }
            }
            if (isEmpty(prevProps.org) && !isEmpty(org)) {
                this.setState({orgDataLoaded: true});
            }
        }
    }

    getOrg() {
        const {getOrganisation} = this.props;
        getOrganisation()
            .then((d) => {
                saveOrgIdOnCookies(d);
            })
            .catch((error) => {
                if (error === 'isLoading') return;
                console.warn('Caught Error -> getOrganisation: ', error);
            });
    }

    getApplicationConfiguration() {
        const {scopes, getApplications, getWidget} = this.props;
        if (
            hasScope(scopes, PERMISSION_KEYS.MIND.KNOWLEDGE.READ) ||
            hasScope(scopes, PERMISSION_KEYS.MIND.DIALOG) ||
            hasScope(scopes, PERMISSION_KEYS.MIND.SETTINGS)
        ) {
            // We need to call this to get the logo.
            getApplications().then((response) => {
                getWidget(response.data[0].id);
            });
        }
    }

    render() {
        const {
            isLoggedIn,
            location,
            userId,
            org,
            application,
            isApplicationLoading,
            scopes,
            history,
        } = this.props;
        const postLoginRedirect = url.getLoginRedirectUrl(
            location.pathname,
            location.search,
        );

        const backgroundColor = '#F7F7F9';

        const currentLocation = `${location.pathname}${location.search}`;

        const search = location.search;
        const responseFormProps =
            querystring.getResponsePortalPropsFromQuerystring(search);
        const hasKnowledgePermission = permissions.hasRequiredPermissions(
            [
                PERMISSION_KEYS.MIND.KNOWLEDGE.READ, //,
                //PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE
            ],
            scopes,
        );
        return (
            <>
                <ToastContainer style={{zIndex: 99999}} />

                {isLoggedIn &&
                !this.state.orgDataLoaded &&
                currentLocation !== '/refresh' &&
                currentLocation !== '/lockdown' ? (
                    <div className="kare-home__spinner">
                        <h2>Loading organization data...</h2>
                        <Spinner />
                    </div>
                ) : (
                    <>
                        {hasKnowledgePermission &&
                            !!responseFormProps[COMPOSE] && (
                                <ResponsePortal {...responseFormProps} />
                            )}
                        <Switch>
                            <Route
                                exact
                                path={'/lockdown'}
                                component={LockDown}
                                key={'lockdown'}
                            />
                            <Route
                                exact
                                path={'/copyright'}
                                component={Copyright}
                                key={'copyright'}
                            />
                            <Route
                                exact
                                path={'/refresh'}
                                component={Refresh}
                                key={'refresh'}
                            />
                            <EmptyLayout
                                path={DIALPAD_LOGIN_START_URL}
                                component={DialpadLoginStart}
                                key="dialpad-login-start"
                            />
                            {isLoggedIn && userId && org && org.id && (
                                <EmptyLayout
                                    withLogo
                                    path="/widget/:id"
                                    component={Widget}
                                    key="widget"
                                    logoPlacement={
                                        emptyLayoutConstants.LOGO_PLACEMENT
                                            .TOP_LEFT
                                    }
                                />
                            )}
                            {isLoggedIn && userId && org?.id && (
                                <EmptyLayout
                                    exact
                                    path="/complete/:provider"
                                    component={Complete}
                                    key="complete"
                                />
                            )}
                            {/* userId is needed here, as we need it in the MainLayout when we include the Kare Widget. */}
                            {isLoggedIn && [
                                <Redirect from="/login" to="/" key="login" />,
                                <MainLayout
                                    path="/dialog/:id"
                                    component={KareLibDialog}
                                    key="dialog"
                                    requiredPermissions={[
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.READ,
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE,
                                    ]}
                                />,
                                <MainLayout
                                    path="/activity/:section?"
                                    component={Activity}
                                    key="activity"
                                    requiredPermissions={[
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.READ,
                                    ]}
                                />,
                                <Route
                                    path="/connect"
                                    component={Integrations}
                                    key="integrations"
                                />,
                                <Route
                                    path="/"
                                    exact
                                    render={() => (
                                        <Redirect
                                            application={application}
                                            from="/"
                                            to="/bi/kpis"
                                        />
                                    )}
                                    key="home"
                                />,
                                <MainLayout
                                    path="/improve/:id?"
                                    component={Improve}
                                    key="improve"
                                    requiredPermissions={[
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.READ,
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE,
                                    ]}
                                />,
                                <MainLayout
                                    path={['/consolidate/:section?', '/expand']}
                                    component={KareLibImprove}
                                    key="consolidate"
                                    requiredPermissions={[
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.READ,
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE,
                                    ]}
                                />,
                                <MainLayout
                                    path="/actions"
                                    component={Actions}
                                    key="actions"
                                    requiredPermissions={[
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.READ,
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE,
                                    ]}
                                />,
                                <MainLayout
                                    path="/treemap"
                                    component={Treemap}
                                    key="treemap"
                                    requiredPermissions={[
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.READ,
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE,
                                    ]}
                                />,
                                <MainLayout
                                    path="/responses/:id?"
                                    component={Responses}
                                    key="responses"
                                    //@ts-ignore
                                    scopes={scopes}
                                    requiredPermissions={[
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.READ,
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE,
                                    ]}
                                />,
                                <MainLayout
                                    path="/chattering"
                                    component={ChatterMessages}
                                    key="chattering"
                                    //@ts-ignore
                                    scopes={scopes}
                                    requiredPermissions={[
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.READ,
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE,
                                    ]}
                                />,
                                <MainLayout
                                    path="/library/:id?"
                                    component={Documents}
                                    key="documents"
                                    //@ts-ignore
                                    scopes={scopes}
                                    requiredPermissions={[
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.READ,
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE,
                                    ]}
                                />,
                                <MainLayout
                                    path="/audience/default"
                                    component={KareLibAudience}
                                    key="audiencedefault"
                                    //@ts-ignore
                                    scopes={scopes}
                                    requiredPermissions={[
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.READ,
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE,
                                    ]}
                                />,
                                <MainLayout
                                    path="/settings/:tab"
                                    component={Settings}
                                    key="settings-tab"
                                    //@ts-ignore
                                    scopes={scopes}
                                    requiredPermissions={[]} // Settings use their own mechanism.
                                />,
                                <Route
                                    path="/settings"
                                    exact
                                    render={() => (
                                        <Redirect
                                            from="/settings"
                                            to="/settings/company"
                                        />
                                    )}
                                    key="settings"
                                />,
                                <MainLayout
                                    exact
                                    path="/dashboard" // This is actually a welcome page.
                                    component={Dashboard}
                                    key="dashboard"
                                    isLoading={isApplicationLoading}
                                    requiredPermissions={[
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.READ,
                                    ]}
                                />,
                                <MainLayout
                                    exact
                                    background={backgroundColor}
                                    path="/bi/:section?/:type?"
                                    component={() => {
                                        return (
                                            <KareLibDashboard
                                                history={history}
                                            />
                                        );
                                    }}
                                    key="business-intelligence"
                                    isLoading={isApplicationLoading}
                                    requiredPermissions={[
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.READ,
                                    ]}
                                />,
                                <MainLayout
                                    exact
                                    background={backgroundColor}
                                    path="/bi/:section?"
                                    component={() => {
                                        return (
                                            <KareLibDashboard
                                                history={history}
                                            />
                                        );
                                    }}
                                    key="business-intelligence"
                                    isLoading={isApplicationLoading}
                                    requiredPermissions={[
                                        PERMISSION_KEYS.MIND.KNOWLEDGE.READ,
                                    ]}
                                />,
                                <MainLayout
                                    exact
                                    path="/styleguide"
                                    component={StyleGuide}
                                    key="styleguide"
                                    requiredPermissions={[]}
                                />,
                                <MainLayout
                                    component={NotFound}
                                    key="nf"
                                    requiredPermissions={[]}
                                />,
                            ]}
                            {!isLoggedIn && [
                                <EmptyLayout
                                    exact
                                    path="/login"
                                    component={Login}
                                    key="login"
                                />, // TODO(rupertrutland): Move the specific reset password routes to within the main reset password component.
                                <EmptyLayout
                                    exact
                                    path="/reset-password"
                                    component={RequestResetPassword}
                                    key="reset-password"
                                />,
                                <EmptyLayout
                                    exact
                                    path="/reset-password/email-sent"
                                    component={ResetPasswordEmailSent}
                                    key="reset-email"
                                />,
                                <EmptyLayout
                                    exact
                                    path="/reset-password/recover"
                                    component={ResetPassword}
                                    key="reset-recover"
                                />,
                                <EmptyLayout
                                    exact
                                    path="/reset-password/success"
                                    component={ResetPasswordComplete}
                                    key="reset-success"
                                />,

                                <EmptyLayout
                                    path={DIALPAD_SUPER_ADMIN_LOGIN_URL}
                                    component={SuperAdminLoginComplete}
                                    key="dialpad-superadmin-login-complete"
                                />,
                                <EmptyLayout
                                    path={DIALPAD_LOGIN_REDIRECT_URL}
                                    component={DialpadLoginComplete}
                                    key="dialpad-login-complete"
                                />,

                                <Redirect
                                    from="/"
                                    to={postLoginRedirect}
                                    key="root"
                                />,
                            ]}
                        </Switch>
                    </>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    org: redux.org.selectors.orgSelector(state),
    application: redux.application.selectors.applicationSelector(state),
    isOrgLoading: redux.org.selectors.isOrgLoadingSelector(state),
    isApplicationLoading:
        redux.application.selectors.isApplicationLoadingSelector(state),
    isLoggedIn: redux.user.selectors.isLoggedInSelector(),
    userId: redux.user.selectors.idSelector(state),
    scopes: redux.user.selectors.scopesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    getWidget: (id) => {
        return dispatch(
            redux.rest.actions[redux.widget.constants.GET_WIDGET]({id}),
        );
    },
    getOrganisation: () => {
        return dispatch(redux.rest.actions[redux.org.constants.ORG].get());
    },
    getApplications: () => {
        return dispatch(
            redux.rest.actions[redux.application.constants.APPLICATION].get(),
        );
    },
});
export const Index = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(IndexComponent),
);
