import React, {useEffect, useState} from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import {Spinner, Button} from '@app/common';
import cx from 'classnames';
import {BLOCK_KEYS, BLOCK_TYPES} from '@app/constants';
import {get} from 'lodash';
import {connect} from 'react-redux';
import {orgSelector} from '@app/redux/org/selectors';
import {getEscalationIcon} from '@app/settings/escalation-settings/utils';

interface HandoverCreationModalProps {
    currentHandover?: any;
    addHandover?: any;
    closeModal?: any;
}

const HandoverCreationModalComponent = (props: HandoverCreationModalProps) => {
    const [search] = useState('');
    const [message, setMessage] = useState('');
    const [CTA, setCTA] = useState('');
    const [selectedHandover, setSelectedHandover] = useState<any>({name: null});
    const [type, setType] = useState('self');
    const [handovers, setHandovers] = useState([]);

    useEffect(() => {
        const {currentHandover} = props;
        const handoverBlock = get(currentHandover, 'handover_block', null);
        const target = get(handoverBlock, 'target');
        if (target) {
            setType(target);
        }
        setHandovers(get(props, ['org', 'escalation_entries'], []));

        if (handoverBlock) {
            setSelectedHandover(handoverBlock);
            setMessage(handoverBlock.text);
            setCTA(handoverBlock.call_to_action);
        }
    }, []);

    const loading = false;

    const handleAddHandover = (e) => {
        e.preventDefault();
        props.addHandover({
            type: BLOCK_TYPES.HANDOVER,
            [BLOCK_KEYS.HANDOVER_BLOCK]: {
                call_to_action: CTA,
                target: type,
                text: message,
                escalation_id: selectedHandover.id,
            },
        });
        props.closeModal();
    };

    const shouldAddButtonBeDisabled = () => {
        return !(!!message && !!get(selectedHandover, 'id') && !!CTA);
    };

    return (
        <div className="handover-creation-modal">
            <h2>Select a handover to add</h2>
            <div className="handover-creation-modal__content">
                <div className="handover-creation-modal__responses">
                    {/* <h3>All responses</h3>
          <div className="extension-creation-modal__responses-search">
            <label htmlFor="response-search-text">
              <Search size={18} />
            </label>
            <input
              id="response-search-text"
              type="text"
              placeholder="Search in your responses"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </div>*/}
                    <ScrollBar>
                        {loading ? (
                            <Spinner />
                        ) : handovers && handovers.length > 0 ? (
                            <div>
                                {handovers
                                    .filter((e) =>
                                        e.name
                                            .toLowerCase()
                                            .includes(search.toLowerCase()),
                                    )
                                    .map((e) => {
                                        return (
                                            <div
                                                key={e.name}
                                                className={cx(
                                                    'handover-creation-modal__response',
                                                    {
                                                        selected:
                                                            e.id ===
                                                            selectedHandover.id,
                                                    },
                                                )}
                                                onClick={() => {
                                                    setSelectedHandover(e);
                                                    setCTA(e.name);
                                                }}
                                            >
                                                {getEscalationIcon(
                                                    get(e, 'method'),
                                                    20,
                                                )}
                                                <span>{e.name}</span>
                                            </div>
                                        );
                                    })}
                            </div>
                        ) : (
                            <div>No handovers</div>
                        )}
                    </ScrollBar>
                </div>
                <div className="handover-creation-modal__detail">
                    <h3>Customize your messages</h3>
                    <textarea
                        placeholder="Type your message here"
                        name="message"
                        id="message"
                        cols={30}
                        rows={10}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <input
                        placeholder="Type your button call to action here"
                        type="text"
                        name="cta"
                        value={CTA}
                        onChange={(e) => setCTA(e.target.value)}
                    />
                    {CTA && CTA.length > 0 && (
                        <Button
                            outlinedPrimary={true}
                            icon={getEscalationIcon(
                                selectedHandover.method,
                                12,
                                true,
                            )}
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                        >
                            {CTA}
                        </Button>
                    )}
                </div>
                <Button
                    onClick={handleAddHandover}
                    disabled={shouldAddButtonBeDisabled()}
                    className={'handover-creation-modal__create-button'}
                >
                    Add
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    org: orgSelector(state),
});

export const HandoverCreationModal = connect(mapStateToProps)(
    HandoverCreationModalComponent,
);
