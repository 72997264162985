import React from 'react';

import {ThumbDown, ThumbUp} from '@app/common/icons';
import {CHOICE_FEEDBACK_DOWN, CHOICE_FEEDBACK_UP} from '../utils';
import cx from 'classnames';

interface FeedbackChoiceProps {
    message: any;
    messages: any;
}

export const FeedbackChoice = (props: FeedbackChoiceProps) => {
    const {message, messages} = props;

    const getFeedbackChoice = (aspect) => {
        switch (aspect) {
            case CHOICE_FEEDBACK_DOWN:
                return (
                    <div className="feedback-choice__thumbs_down">
                        <p className="feedback-choice">
                            <ThumbDown /> <span>1</span>
                        </p>
                    </div>
                );
            case CHOICE_FEEDBACK_UP:
                return (
                    <div className="feedback-choice__thumbs_up">
                        <p className="feedback-choice">
                            <ThumbUp /> <span>1</span>
                        </p>
                    </div>
                );
            default: {
                return null;
            }
        }
    };

    const choice = message.parent_id
        ? messages?.find((msg) => msg.parent_id === message.id)
        : message;

    const messageIndex = messages
        .map((currentMessage) => currentMessage.id)
        .indexOf(message.id);

    const choiceIcon = getFeedbackChoice(choice?.aspect);
    const previousComponent = messages[messageIndex - 1];

    const className = cx({
        'feedback-choice-wrapper__left': previousComponent.uri === 'out/text',
        'feedback-choice-wrapper__right':
            previousComponent.uri === 'out/cx/question',
    });

    return (
        <div className={`feedback-choice-wrapper ${className}`}>
            {choiceIcon}
        </div>
    );
};
