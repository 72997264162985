import React, {Fragment, useEffect, useState} from 'react';
import {Button, KareModal} from '@app/common';
import {SyncEnvironment} from './form';
import {redux} from '@app/redux';
import {connect} from 'react-redux';
import axios from 'axios';
import {CONTENT_LOCALE} from '../../redux/constants';

interface AutomationsFormProps {
    org: any;
    setAutomationEnvironment: any;
}

const AutomationsForm = ({
    org,
    org: {webext_hostname},
    setAutomationEnvironment,
}: AutomationsFormProps) => {
    const [shouldOpen, setShouldOpen] = useState(false);
    const [automations, setAutomations] = useState([]);
    const [hideActions, setHideActions] = useState(false);
    const [hasError, setHasError] = useState(false);
    const instance = axios.create();
    useEffect(() => {
        if (webext_hostname !== undefined) {
            if (instance.defaults.headers[CONTENT_LOCALE])
                delete instance.defaults.headers[CONTENT_LOCALE];
            instance
                .get(`${webext_hostname}/conf/registry`)
                .then((result) => {
                    setAutomations(result.data);
                    setHasError(false);
                })
                .catch(() => {
                    setHasError(true);
                });
        }
    }, [org]);

    return (
        <div className="automations-container">
            <div className="automations-row">
                <div className="automations-col-1">AUTOMATIONS</div>
                <div className="automations-col-2">
                    Automations are actions that your customers can complete
                    without the help from your agents. Contact us to start your
                    automations library.
                    <a
                        className={'c-btn'}
                        href={
                            'mailto:support@karehq.com?subject=Request to start automations library'
                        }
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        CONTACT
                    </a>
                </div>
            </div>
            {webext_hostname !== undefined && !hasError && (
                <div className="automations-row">
                    <div className="automations-col-1">ENVIRONMENT</div>
                    <div className="automations-col-2">
                        <div className="automations-hostname-container">
                            <div>
                                Enter the provided Dialpad Automation
                                Environment URL below.
                            </div>
                            <div className="automations-hostname">
                                <span>{webext_hostname} </span>
                                <Button
                                    inverted
                                    onClick={() => {
                                        setHideActions(true);
                                        setAutomations([]);
                                        setAutomationEnvironment(org)('');
                                    }}
                                >
                                    DISCONNECT
                                </Button>
                            </div>
                            {!hideActions && (
                                <div className="automations-actions">
                                    {automations.length > 0 && (
                                        <Fragment>
                                            {automations.length} ACTION
                                            {automations.length > 1
                                                ? 'S'
                                                : ''}{' '}
                                            CONNECTED
                                        </Fragment>
                                    )}
                                    {automations.length === 0 && (
                                        <Fragment>No actions found</Fragment>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {(webext_hostname === undefined ||
                (!!webext_hostname && hasError)) && (
                <div className="automations-row">
                    <div className="automations-col-1">ENVIRONMENT</div>
                    <div className="automations-col-2">
                        Enter the provided Dialpad Automation Environment URL
                        below.
                        <Button
                            onClick={() => {
                                setShouldOpen(true);
                                setHideActions(false);
                            }}
                            outlined
                        >
                            SYNC AN ENVIRONMENT
                        </Button>
                    </div>
                </div>
            )}
            {shouldOpen && (
                <KareModal
                    title={'Sync an automation environment'}
                    closeModal={() => setShouldOpen(false)}
                >
                    <SyncEnvironment
                        setHasError={setHasError}
                        setAutomationEnvironment={setAutomationEnvironment(org)}
                        closeModal={() => setShouldOpen(false)}
                    />
                </KareModal>
            )}
            <div />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        org: redux.org.selectors.orgSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    setAutomationEnvironment: (org) => (values) => {
        org.webext_hostname = values.trim();
        return dispatch(
            redux.rest.actions[redux.org.constants.ORG].put(
                {},
                {
                    body: JSON.stringify(org),
                },
            ),
        );
    },
});

export const Automations = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AutomationsForm);
