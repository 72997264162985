import {rest} from './rest';
import * as selectors from './selectors';
import * as constants from './constants';

/**
 * Public API for the documents redux module.
 */
export const documents = {
    rest,
    selectors,
    constants,
};
