import React from 'react';
import {CircularProgress} from '@material-ui/core';

import './Loading.scss';

interface ILoadingProps {
    withCenteredContainer?: boolean;
}

export const Loading: React.FunctionComponent<ILoadingProps> = (
    props: ILoadingProps,
) => {
    return (
        <div className="Loading__container">
            <CircularProgress />
        </div>
    );
};
