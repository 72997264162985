import React from 'react';

interface ValidationErrorProps {
    children?: any;
    input?: any;
    meta?: any;
}

export const ValidationError = ({children}: ValidationErrorProps) => {
    return <p className="c-field-error">{children}</p>;
};
