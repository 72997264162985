import {METHODS} from '../constants';
import {
    GET_AGGREGATED_QUERIES_TIMESERIES,
    GET_QUERIES_TIMESERIES,
} from './constants';

const {GET} = METHODS;

export const rest = {
    [GET_AGGREGATED_QUERIES_TIMESERIES]: {
        url: '/v3.0/analytics/aggregated/queries?from=:minDate&to=:maxDate',
        options: {
            method: GET,
        },
    },
    [GET_QUERIES_TIMESERIES]: {
        url: '/v3.0/analytics/timeseries/queries/daily?from=:minDate&to=:maxDate',
        options: {
            method: GET,
        },
    },
};
