import Axios from 'axios';
import {Moment} from 'moment';

export class BeaconsApi {
    constructor() {}

    async getBeaconsByUrlAndNode(minDate: Moment, maxDate: Moment) {
        const URL = `/v3.0/analytics/aggregated/beacons/domain,url,node_id?from=${minDate.unix()}&to=${maxDate.unix()}`;
        return this.callApi(URL);
    }

    async callApi(URL: string) {
        const res = {
            data: null,
            error: null,
        };
        try {
            const req = await Axios.get(URL);
            res.data = req.data;
        } catch (e) {
            res.error = e;
        }
        return res;
    }
}
