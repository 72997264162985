import {redux} from '../index';
import {formatDateViewingOption} from '../activity/utils';

export const getConversationMetrics = (dispatch) => (params) => {
    const requestParams = formatDateViewingOption(params.dateRange);
    dispatch(
        redux.rest.actions[redux.metrics.constants.GET_CONVERSATION_METRICS]({
            resolution: requestParams.viewingOption,
            ...requestParams,
        }),
    );
};

export const getQueryMetrics = (dispatch) => (params) => {
    const requestParams = formatDateViewingOption(params.dateRange);
    dispatch(
        redux.rest.actions[redux.metrics.constants.GET_QUERY_METRICS]({
            resolution: requestParams.viewingOption,
            ...requestParams,
        }),
    );
};

export const getFeedbackMetrics = (dispatch) => (params) => {
    const requestParams = formatDateViewingOption(params.dateRange);
    dispatch(
        redux.rest.actions[redux.metrics.constants.GET_FEEDBACK_METRICS]({
            resolution: requestParams.viewingOption,
            ...requestParams,
        }),
    );
};
