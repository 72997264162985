import React from 'react';

import './LoadingModal.scss';
import {Loading} from './Loading';

export interface LoadingModalProps {
    text: string | undefined;
}

export const LoadingModal: React.FC<LoadingModalProps> = (props) => {
    return (
        <div className="LoadingModal__container">
            {props.text}
            <Loading />
        </div>
    );
};
