import React from 'react';
import cx from 'classnames';

import {ThumbDown, ThumbUp, Automation} from '@app/common/icons';
import {Query as QuerySvg} from '@app/containers/Dialog/query';
import {FaQuestionCircle} from 'react-icons/fa';
import {MdEmail, MdLink} from 'react-icons/md';
import {Tooltip, IconButton, makeStyles} from '@material-ui/core';
import {CONVERSATION_STATS} from './conversation/constants';

interface ConversationListHeaderProps {
    filters?: any[];
    onFilterClick?: any;
}

const useStyles = makeStyles({
    tooltip: {
        fontSize: '10px',
        fontWeight: 400,
    },
});

export const ConversationListHeader = (props: ConversationListHeaderProps) => {
    const {filters, onFilterClick} = props;

    const classes = useStyles();

    const iconStyles = {
        button: {
            width: 12,
            height: 12,
            padding: 0,
            color: '#444150',
        },
    };

    return (
        <section className="console-activity-conversations-list__header">
            <div
                style={{cursor: 'pointer'}}
                className={cx(
                    {
                        active: filters.includes(CONVERSATION_STATS.USER_ID),
                    },
                    'console-activity-conversations-list__column console-activity-conversations-list__column-1',
                )}
                onClick={() => {
                    onFilterClick(CONVERSATION_STATS.USER_ID);
                }}
            >
                Username
            </div>
            <div
                className={cx(
                    {
                        active: filters.includes(CONVERSATION_STATS.QUERIES),
                    },
                    'console-activity-conversations-list__column console-activity-conversations-list__column-2',
                )}
                onClick={() => {
                    onFilterClick(CONVERSATION_STATS.QUERIES);
                }}
            >
                <Tooltip
                    classes={{tooltip: classes.tooltip}}
                    placement="top"
                    arrow
                    title="Queries"
                >
                    <IconButton style={iconStyles.button}>
                        <QuerySvg title="Queries" />
                    </IconButton>
                </Tooltip>
            </div>
            <div
                className={cx(
                    {
                        active: filters.includes(CONVERSATION_STATS.MISSING),
                    },
                    'console-activity-conversations-list__column console-activity-conversations-list__column-3',
                )}
                onClick={() => {
                    onFilterClick(CONVERSATION_STATS.MISSING);
                }}
            >
                <Tooltip
                    classes={{tooltip: classes.tooltip}}
                    placement="top"
                    arrow
                    title="Missing answers"
                >
                    <IconButton style={iconStyles.button}>
                        <FaQuestionCircle />
                    </IconButton>
                </Tooltip>
            </div>
            <div
                className={cx(
                    {
                        active: filters.includes(CONVERSATION_STATS.FORMS),
                    },
                    'console-activity-conversations-list__column console-activity-conversations-list__column-4',
                )}
                onClick={() => {
                    onFilterClick(CONVERSATION_STATS.FORMS);
                }}
            >
                <Tooltip
                    classes={{tooltip: classes.tooltip}}
                    placement="top"
                    arrow
                    title="Messages"
                >
                    <IconButton style={iconStyles.button}>
                        <MdEmail />
                    </IconButton>
                </Tooltip>
            </div>
            <div
                className={cx(
                    {
                        active: filters.includes(CONVERSATION_STATS.WEBEXT),
                    },
                    'console-activity-conversations-list__column console-activity-conversations-list__column-5',
                )}
                onClick={() => {
                    onFilterClick(CONVERSATION_STATS.WEBEXT);
                }}
            >
                <Tooltip
                    classes={{tooltip: classes.tooltip}}
                    placement="top"
                    arrow
                    title="Automations"
                >
                    <IconButton style={iconStyles.button}>
                        <Automation />
                    </IconButton>
                </Tooltip>
            </div>
            <div
                className={cx(
                    {
                        active: filters.includes(CONVERSATION_STATS.LINKS),
                    },
                    'console-activity-conversations-list__column console-activity-conversations-list__column-6',
                )}
                onClick={() => {
                    onFilterClick(CONVERSATION_STATS.LINKS);
                }}
            >
                <Tooltip
                    classes={{tooltip: classes.tooltip}}
                    placement="top"
                    arrow
                    title="Links"
                >
                    <IconButton style={iconStyles.button}>
                        <MdLink />
                    </IconButton>
                </Tooltip>
            </div>
            <div
                className={cx(
                    {
                        active: filters.includes(CONVERSATION_STATS.THUMBS_UP),
                    },
                    'console-activity-conversations-list__column console-activity-conversations-list__column-7',
                )}
                onClick={() => {
                    onFilterClick(CONVERSATION_STATS.THUMBS_UP);
                }}
            >
                <Tooltip
                    classes={{tooltip: classes.tooltip}}
                    placement="top"
                    arrow
                    title="Thumbs up"
                >
                    <IconButton style={iconStyles.button}>
                        <ThumbUp />
                    </IconButton>
                </Tooltip>
            </div>
            <div
                className={cx(
                    {
                        active: filters.includes(
                            CONVERSATION_STATS.THUMBS_DOWN,
                        ),
                    },
                    'console-activity-conversations-list__column console-activity-conversations-list__column-8',
                )}
                onClick={() => {
                    onFilterClick(CONVERSATION_STATS.THUMBS_DOWN);
                }}
            >
                <Tooltip
                    classes={{tooltip: classes.tooltip}}
                    placement="top"
                    arrow
                    title="Thumbs down"
                >
                    <IconButton style={iconStyles.button}>
                        <ThumbDown />
                    </IconButton>
                </Tooltip>
            </div>
            <div
                className={cx(
                    {active: false},
                    'console-activity-conversations-list__column console-activity-conversations-list__column-9',
                )}
            >
                Status
            </div>
            <div
                className={cx(
                    {active: false},
                    'console-activity-conversations-list__column console-activity-conversations-list__column-10',
                )}
            >
                Time
            </div>
        </section>
    );
};
