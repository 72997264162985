import {DIALPAD_AUTH, DIALPAD_SANDBOX_AUTH} from '../../constants';
import {DIALPAD_LOGIN_REDIRECT_URL, DIALPAD_LOGIN_START_URL} from './urls';

export const getStartUrl = (provider) => {
    const protocol = window.location.protocol;
    const u = new URL(
        `${protocol}//${window.location.host}${DIALPAD_LOGIN_START_URL}`,
    );
    u.searchParams.append('provider', provider.name);
    return u.toString();
};

export const getRedirectUrl = (provider, successRedirect) => {
    const protocol = window.location.protocol;
    const u = new URL(
        `${protocol}//${window.location.host}${DIALPAD_LOGIN_REDIRECT_URL}`,
    );
    u.searchParams.append('provider', provider.name);
    if (successRedirect) {
        u.searchParams.append('redirect', successRedirect);
    }
    return u.toString();
};

export const getAuthorizeUrl = (
    authorizeUrls,
    provider,
    loginHint,
    successRedirect,
    state,
) => {
    const url = new URL(authorizeUrls.get(provider.name));
    url.searchParams.append(`client_id`, provider.client.clientID);
    url.searchParams.append(
        `redirect_uri`,
        getRedirectUrl(provider, successRedirect),
    );
    url.searchParams.append(`state`, state);
    url.searchParams.append('scope', 'openid user_v2');
    if (loginHint) {
        url.searchParams.append('login_hint', loginHint);
    }
    return url.toString();
};

export const getAuthorizeEndpoints = () => {
    return new Map([
        [DIALPAD_AUTH, `https://dialpad.com/oauth2/authorize`],
        [DIALPAD_SANDBOX_AUTH, `https://sandbox.dialpad.com/oauth2/authorize`],
    ]);
};
