import {PERMISSION_KEYS} from '@app/constants';

export const hasRequiredPermissions = (
    requiredPermissions,
    actualPermissions,
) => {
    if (actualPermissions.includes(PERMISSION_KEYS.MIND.ALL)) {
        return true;
    }
    const userHasRequiredPermissions = requiredPermissions.reduce(
        (prev, permission) => {
            if (!prev) {
                return prev;
            }
            if (!actualPermissions.includes(permission)) {
                return false;
            }
            return prev;
        },
        true,
    );
    return userHasRequiredPermissions;
};
