import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Field, formValueSelector, reduxForm} from 'redux-form';

import {
    // MOBILE,
    TABLET_DESKTOP,
    LAUNCHER_SIZE_LARGE,
    LAUNCHER_SIZE_MEDIUM,
    LAUNCHER_SIZE_SMALL,
    LAUNCHER_POSITION_TOP,
    LAUNCHER_POSITION_MIDDLE,
    LAUNCHER_POSITION_BOTTOM,
    LAUNCHER_SIDE_RIGHT,
    LAUNCHER_SIDE_LEFT,
    BRANDED,
    BOOKMARK,
    FLOATING,
    CUSTOM,
    BUTTON_COLOR_SCHEME_LIGHT,
    BUTTON_COLOR_SCHEME_DARK,
    BOOKMARK_ICON_BRANDED,
    BOOKMARK_ICON_QUESTION_MARK,
    BRANDED_BADGE_STYLE_DOT,
    BRANDED_BADGE_STYLE_NUMBER,
} from './constants';
import {FIELDS} from '../constants';
import {RadioInput, KareTextInput, Colorpicker, KareSwitch} from '@app/common';
import {WIDGET_FORM_NAME} from '../constants';
import {HorizontalLauncher} from './horizontal-launcher';
import {VerticalLauncher} from './vertical-launcher';
import {CustomLauncher} from './custom-launcher';

interface LauncherPreviewProps {
    primaryColor?: string;
    change?: any;
    applicationId?: string;
    fontFamily?: string;
    ctaFormValue?: string;
    launcherSize?: string;
    launcherType?: string;
    launcherPosition?: string;
    popOverText?: string;
    showPopOver?: boolean;
    launcherSidePosition?: string;
    position_enabled?: boolean;
    fabStyle?: any;
    fabColor?: string;
    fabColorScheme?: any;
    fabBookmarkIcon?: any;
    deviceType?: any;
    showBadgeStyle?: any;
}

const {
    LAUNCHER_SIZE,
    LAUNCHER_TYPE,
    LAUNCHER_POSITION,
    LAUNCHER_SIDE,
    POPOVER_TEXT,
    CALL_TO_ACTION,
    PRIMARY_COLOR_HEX,
    SHOW_POPOVER,
    FAB_STYLE,
    DEVICE_TYPE,
    FAB_COLOR,
    FAB_COLOR_SCHEME,
    FAB_BOOKMARK_ICON,
    FAB_BADGE_STYLE,
    FAB_BADGE_ENABLED,
    DRAG_AND_DROP_ENABLED,
} = FIELDS;

export class LauncherPreviewComponent extends React.Component<
    LauncherPreviewProps,
    any
> {
    state = {
        hover: false,
        // will have to load from config when backend is implemented
        selectedLauncher: TABLET_DESKTOP,
        selectedFabStyle: this.props.fabStyle,
        selectedFabColor: this.props.fabColor || this.props.primaryColor,
        selectedFabColorScheme: this.props.fabColorScheme,
        selectedBookmarkIcon: this.props.fabBookmarkIcon,
    };

    turnHoverOn = () => {
        this.setState({hover: true});
    };

    turnHoverOff = () => {
        this.setState({hover: false});
    };
    handleMouseEnter = () => {
        this.turnHoverOn();
    };

    handleMouseLeave = () => {
        this.turnHoverOff();
    };

    getWidgetTestLink = () => {
        const {applicationId} = this.props;
        return `/widget/${applicationId}`;
    };

    handleClickButton = (event) => {
        event.preventDefault();
        const {applicationId} = this.props;
        // If there is no application id we don't want to open a new tab.
        if (!applicationId) {
            return;
        }
        window.open(this.getWidgetTestLink(), '_blank');
    };

    handleSelectedLauncherChange = (selectedLauncher) => {
        this.props.change(LAUNCHER_TYPE, selectedLauncher);
    };

    handleSelectedDeviceChange = (selectedDevice) => {
        this.props.change(DEVICE_TYPE, selectedDevice);
    };

    handleSelectedFabStyleChange = (e) => {
        this.setState({selectedFabStyle: e.currentTarget.value});
    };

    handleSelectedFabColorChange = (e) => {
        this.setState({selectedFabColor: e});
    };

    handleSelectedFabColorSchemeChange = (e) => {
        this.setState({selectedFabColorScheme: e.currentTarget.value});
    };

    handleSelectedFabBookmarkIcon = (e) => {
        this.setState({selectedBookmarkIcon: e.currentTarget.value});
    };

    renderLauncherPreview = () => {
        const {
            launcherSize,
            ctaFormValue,
            popOverText,
            launcherSidePosition,
            launcherPosition,
            showPopOver,
        } = this.props;
        const {
            selectedFabStyle,
            selectedFabColorScheme,
            selectedFabColor,
            selectedBookmarkIcon,
        } = this.state;

        const isIconLightColor =
            selectedFabColorScheme === BUTTON_COLOR_SCHEME_LIGHT;

        const whiteColor = '#FFF';
        const textColor = {
            color: isIconLightColor ? selectedFabColor : whiteColor,
        };

        const iconStyle =
            selectedFabStyle === (FLOATING || CUSTOM)
                ? {
                      fill: isIconLightColor ? selectedFabColor : whiteColor,
                  }
                : selectedFabStyle === BOOKMARK
                ? {
                      color: isIconLightColor ? whiteColor : selectedFabColor,
                      fill: isIconLightColor ? selectedFabColor : whiteColor,
                  }
                : {
                      color: isIconLightColor ? selectedFabColor : whiteColor,
                      fill: isIconLightColor ? whiteColor : selectedFabColor,
                  };

        const buttonStyle = {
            backgroundColor: isIconLightColor ? whiteColor : selectedFabColor,
            color: isIconLightColor ? selectedFabColor : whiteColor,
        };

        const text = ctaFormValue || '';

        switch (selectedFabStyle) {
            default:
            case BRANDED || FLOATING:
                return (
                    <Fragment>
                        <div className="launcher-preview__window">
                            <div className="launcher-preview__window__header">
                                <div className="window__header__dot" />
                                <div className="window__header__dot" />
                                <div className="window__header__dot" />
                            </div>
                            <HorizontalLauncher
                                iconStyle={iconStyle}
                                buttonStyle={buttonStyle}
                                onMouseEnter={this.handleMouseEnter}
                                onMouseLeave={this.handleMouseLeave}
                                onClick={this.handleClickButton}
                                fabStyle={selectedFabStyle}
                                launcherSize={launcherSize}
                                launcherPosition={launcherPosition}
                                launcherSidePosition={launcherSidePosition}
                                text={text}
                                textStyle={textColor}
                                popOverText={popOverText}
                                windowPreview={true}
                                showPopOver={showPopOver}
                            />
                        </div>
                        <HorizontalLauncher
                            iconStyle={iconStyle}
                            buttonStyle={buttonStyle}
                            onMouseEnter={this.handleMouseEnter}
                            onMouseLeave={this.handleMouseLeave}
                            onClick={this.handleClickButton}
                            fabStyle={selectedFabStyle}
                            launcherSize={launcherSize}
                            launcherPosition={launcherPosition}
                            launcherSidePosition={launcherSidePosition}
                            text={text}
                            textStyle={textColor}
                            popOverText={popOverText}
                            windowPreview={false}
                            showPopOver={showPopOver}
                        />
                    </Fragment>
                );
            case BOOKMARK:
                return (
                    <Fragment>
                        <div className="launcher-preview__window">
                            <div className="launcher-preview__window__header">
                                <div className="window__header__dot" />
                                <div className="window__header__dot" />
                                <div className="window__header__dot" />
                            </div>
                            <VerticalLauncher
                                buttonStyle={buttonStyle}
                                iconStyle={iconStyle}
                                onMouseEnter={this.handleMouseEnter}
                                onMouseLeave={this.handleMouseLeave}
                                onClick={this.handleClickButton}
                                bookmarkIcon={selectedBookmarkIcon}
                                launcherSize={launcherSize}
                                launcherPosition={launcherPosition}
                                launcherSidePosition={launcherSidePosition}
                                text={text}
                                windowPreview={true}
                                popOverText={popOverText}
                                showPopOver={showPopOver}
                            />
                        </div>
                        <VerticalLauncher
                            buttonStyle={buttonStyle}
                            iconStyle={iconStyle}
                            onMouseEnter={this.handleMouseEnter}
                            onMouseLeave={this.handleMouseLeave}
                            onClick={this.handleClickButton}
                            bookmarkIcon={selectedBookmarkIcon}
                            launcherSize={launcherSize}
                            launcherPosition={launcherPosition}
                            launcherSidePosition={launcherSidePosition}
                            text={text}
                            popOverText={popOverText}
                            windowPreview={false}
                            showPopOver={showPopOver}
                        />
                    </Fragment>
                );
        }
    };

    renderFABStyleField = () => {
        const buttonStyleOptions = [
            {value: BRANDED, label: 'Default'},
            {value: FLOATING, label: 'Question mark'},
            {value: BOOKMARK, label: 'Bookmark'},
            {value: CUSTOM, label: 'Custom'},
        ];

        return (
            <div className="launcher-preview__field-wrapper u-margin-bottom-large">
                <div className="u-margin-bottom">
                    <label>Floating activation button style</label>
                </div>
                <div className="launcher-preview__field-wrapper-options">
                    {buttonStyleOptions.map((buttonStyle) => (
                        <Field
                            key={buttonStyle.value}
                            type="radio"
                            name={FAB_STYLE}
                            label={buttonStyle.label}
                            value={buttonStyle.value}
                            component={RadioInput}
                            onChange={this.handleSelectedFabStyleChange}
                        />
                    ))}
                </div>
            </div>
        );
    };

    renderFABColorSchemeField = () => {
        const {selectedFabStyle} = this.state;

        if (selectedFabStyle === CUSTOM) {
            return null;
        }

        const buttonStyleOptions = [
            {value: BUTTON_COLOR_SCHEME_DARK, label: 'Dark'},
            {value: BUTTON_COLOR_SCHEME_LIGHT, label: 'Light'},
        ];

        return (
            <div className="launcher-preview__field-wrapper u-margin-bottom-large">
                <div className="u-margin-bottom">
                    <label>Floating activation button color scheme</label>
                </div>
                <div className="launcher-preview__field-wrapper-options">
                    {buttonStyleOptions.map((buttonStyle) => (
                        <Field
                            key={buttonStyle.value}
                            type="radio"
                            name={FAB_COLOR_SCHEME}
                            label={buttonStyle.label}
                            value={buttonStyle.value}
                            component={RadioInput}
                            onChange={this.handleSelectedFabColorSchemeChange}
                        />
                    ))}
                </div>
            </div>
        );
    };

    renderLauncherHorizontalPositionField = () => {
        const {selectedFabStyle} = this.state;

        if (selectedFabStyle === CUSTOM) {
            return null;
        }

        const buttonStyleOptions = [
            {value: LAUNCHER_SIDE_LEFT, label: 'Left'},
            {value: LAUNCHER_SIDE_RIGHT, label: 'Right'},
        ];

        return (
            <div className="launcher-preview__field-wrapper u-margin-bottom-large">
                <div className="u-margin-bottom">
                    <label>Launcher & Widget horizontal position</label>
                </div>
                <div className="launcher-preview__field-wrapper-options">
                    {buttonStyleOptions.map((buttonStyle) => (
                        <Field
                            key={buttonStyle.value}
                            type="radio"
                            name={LAUNCHER_SIDE}
                            label={buttonStyle.label}
                            value={buttonStyle.value}
                            component={RadioInput}
                        />
                    ))}
                </div>
            </div>
        );
    };

    renderLauncherVerticalPositionField = () => {
        const {selectedFabStyle} = this.state;

        if (selectedFabStyle !== BOOKMARK) {
            return null;
        }

        const buttonStyleOptions = [
            {value: LAUNCHER_POSITION_TOP, label: 'Top'},
            {value: LAUNCHER_POSITION_MIDDLE, label: 'Middle'},
            {value: LAUNCHER_POSITION_BOTTOM, label: 'Bottom'},
        ];

        return (
            <div className="launcher-preview__field-wrapper u-margin-bottom-large">
                <div className="u-margin-bottom">
                    <label>Launcher vertical position</label>
                </div>
                <div className="launcher-preview__field-wrapper-options">
                    {buttonStyleOptions.map((buttonStyle) => (
                        <Field
                            key={buttonStyle.value}
                            type="radio"
                            name={LAUNCHER_POSITION}
                            label={buttonStyle.label}
                            value={buttonStyle.value}
                            component={RadioInput}
                        />
                    ))}
                </div>
            </div>
        );
    };

    renderLauncherSizeField = () => {
        const {selectedFabStyle} = this.state;

        if (selectedFabStyle === CUSTOM) {
            return null;
        }

        const buttonStyleOptions = [
            {value: LAUNCHER_SIZE_SMALL, label: 'Small'},
            {value: LAUNCHER_SIZE_MEDIUM, label: 'Medium'},
            {value: LAUNCHER_SIZE_LARGE, label: 'Large'},
        ];

        return (
            <div className="launcher-preview__field-wrapper u-margin-bottom-large">
                <div className="u-margin-bottom">
                    <label>Floating Activation Button Size</label>
                </div>
                <div className="launcher-preview__field-wrapper-options">
                    {buttonStyleOptions.map((buttonStyle) => (
                        <Field
                            key={buttonStyle.value}
                            type="radio"
                            name={LAUNCHER_SIZE}
                            label={buttonStyle.label}
                            value={buttonStyle.value}
                            component={RadioInput}
                        />
                    ))}
                </div>
            </div>
        );
    };

    renderBookmarkButtonIconField = () => {
        const {selectedFabStyle} = this.state;

        if (selectedFabStyle !== BOOKMARK) {
            return null;
        }

        const buttonStyleOptions = [
            {value: BOOKMARK_ICON_BRANDED, label: 'Default'},
            {value: BOOKMARK_ICON_QUESTION_MARK, label: 'Question mark'},
        ];

        return (
            <div className="launcher-preview__field-wrapper u-margin-bottom-large">
                <div className="u-margin-bottom">
                    <label>Floating activation button icon</label>
                </div>
                <div className="launcher-preview__field-wrapper-options">
                    {buttonStyleOptions.map((buttonStyle) => (
                        <Field
                            key={buttonStyle.value}
                            type="radio"
                            name={FAB_BOOKMARK_ICON}
                            label={buttonStyle.label}
                            value={buttonStyle.value}
                            component={RadioInput}
                            onChange={this.handleSelectedFabBookmarkIcon}
                        />
                    ))}
                </div>
            </div>
        );
    };

    renderFABBadgeStyleField = () => {
        const buttonStyleOptions = [
            {value: BRANDED_BADGE_STYLE_DOT, label: 'Dot'},
            {value: BRANDED_BADGE_STYLE_NUMBER, label: 'Number'},
        ];

        return (
            <div className="launcher-preview__field-wrapper u-margin-bottom-large">
                <div className="u-margin-bottom">
                    <label>Floating activation button icon</label>
                </div>
                <div className="launcher-preview__field-wrapper-options">
                    {buttonStyleOptions.map((buttonStyle) => (
                        <Field
                            key={buttonStyle.value}
                            type="radio"
                            name={FAB_BADGE_STYLE}
                            label={buttonStyle.label}
                            value={buttonStyle.value}
                            component={RadioInput}
                        />
                    ))}
                </div>
            </div>
        );
    };

    renderDragDropField = () => {
        const {position_enabled, deviceType} = this.props;

        if (deviceType !== TABLET_DESKTOP) {
            return null;
        }

        return (
            <Fragment>
                <div className="u-margin-bottom">
                    <label>Drag and drop</label>
                </div>
                <Field
                    component={KareSwitch}
                    name={DRAG_AND_DROP_ENABLED}
                    label={
                        position_enabled
                            ? 'Drag and drop enabled'
                            : 'Drag and drop disabled'
                    }
                />
            </Fragment>
        );
    };

    renderCallToActionField = () => {
        const {selectedFabStyle} = this.state;

        if (selectedFabStyle !== BOOKMARK && selectedFabStyle !== FLOATING) {
            return null;
        }

        return (
            <div className="launcher-preview__cta">
                <Field
                    name={CALL_TO_ACTION}
                    component={KareTextInput}
                    label="Call to action"
                />
            </div>
        );
    };

    renderBadgeStyleSwitchField = () => {
        const {selectedFabStyle} = this.state;

        if (selectedFabStyle === CUSTOM) {
            return null;
        }

        return (
            <Fragment>
                <div className="u-margin-bottom">
                    <label>Floating Action Button Badge</label>
                </div>
                <Field
                    component={KareSwitch}
                    label={'Enable badge'}
                    name={FAB_BADGE_ENABLED}
                />
            </Fragment>
        );
    };

    renderFABColorPickerField = () => {
        const {selectedFabStyle} = this.state;

        if (selectedFabStyle === CUSTOM) {
            return null;
        }

        return (
            <Field
                label="Floating Action Button Color"
                props={{className: 'form-row'}}
                name={FAB_COLOR}
                component={Colorpicker}
                onChange={this.handleSelectedFabColorChange}
            />
        );
    };

    render() {
        const {showBadgeStyle, fabStyle} = this.props;

        return (
            <div className="launcher-preview">
                <div className="launcher-preview__settings">
                    <div className="u-margin-bottom-large">
                        <h1 className="kare-h4">Widget Launcher</h1>
                        {/*<DeviceSelector
                            deviceType={deviceType}
                            handleSelectedLauncherChange={
                                this.handleSelectedDeviceChange
                            }
                        />*/}
                    </div>
                    {this.renderFABStyleField()}
                    {fabStyle === CUSTOM && <CustomLauncher />}
                    {this.renderFABColorPickerField()}
                    {this.renderFABColorSchemeField()}
                    {this.renderBookmarkButtonIconField()}
                    {this.renderLauncherHorizontalPositionField()}
                    {this.renderLauncherVerticalPositionField()}
                    {this.renderLauncherSizeField()}
                    {/*this.renderBadgeStyleSwitchField()*/}
                    {showBadgeStyle && this.renderFABBadgeStyleField()}
                    {this.renderDragDropField()}
                    {this.renderCallToActionField()}
                </div>
                <div className="launcher-preview__container">
                    {this.renderLauncherPreview()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    deviceType: formValueSelector(WIDGET_FORM_NAME)(state, DEVICE_TYPE),
    fabStyle: formValueSelector(WIDGET_FORM_NAME)(state, FAB_STYLE),
    fabColor: formValueSelector(WIDGET_FORM_NAME)(state, FAB_COLOR),
    fabColorScheme: formValueSelector(WIDGET_FORM_NAME)(
        state,
        FAB_COLOR_SCHEME,
    ),
    fabBookmarkIcon: formValueSelector(WIDGET_FORM_NAME)(
        state,
        FAB_BOOKMARK_ICON,
    ),
    launcherType: formValueSelector(WIDGET_FORM_NAME)(state, LAUNCHER_TYPE),
    launcherSize: formValueSelector(WIDGET_FORM_NAME)(state, LAUNCHER_SIZE),
    launcherPosition: formValueSelector(WIDGET_FORM_NAME)(
        state,
        LAUNCHER_POSITION,
    ),
    launcherSidePosition: formValueSelector(WIDGET_FORM_NAME)(
        state,
        LAUNCHER_SIDE,
    ),
    popOverText: formValueSelector(WIDGET_FORM_NAME)(state, POPOVER_TEXT),
    showPopOver: formValueSelector(WIDGET_FORM_NAME)(state, SHOW_POPOVER),
    ctaFormValue: formValueSelector(WIDGET_FORM_NAME)(state, CALL_TO_ACTION),
    primaryColor: formValueSelector(WIDGET_FORM_NAME)(state, PRIMARY_COLOR_HEX),
    showBadgeStyle: formValueSelector(WIDGET_FORM_NAME)(
        state,
        FAB_BADGE_ENABLED,
    ),
    position_enabled: formValueSelector(WIDGET_FORM_NAME)(
        state,
        FIELDS.DRAG_AND_DROP_ENABLED,
    ),
});

export const LauncherPreview = reduxForm({
    form: WIDGET_FORM_NAME,
    enableReinitialize: true,
})(connect(mapStateToProps)(LauncherPreviewComponent));
