import React from 'react';
import cx from 'classnames';

import './TabBar.scss';

interface ITabBarProps {
    setActiveTab: (tab: string) => void;
    activeTab: string;
    tabs: {value: string; label: string}[];
}

export const TabBar: React.FunctionComponent<ITabBarProps> = ({
    setActiveTab,
    activeTab,
    tabs,
}) => {
    return (
        <div className="TabBar__container">
            <ul>
                {tabs.map((t: {label: string; value: string}) => {
                    return (
                        <li
                            key={t.value}
                            className={cx({
                                ['TabBar__selected']: activeTab === t.value,
                            })}
                            onClick={() => {
                                setActiveTab(t.value);
                            }}
                        >
                            {t.label}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
