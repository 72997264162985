/* eslint-disable react/prop-types */
import React from 'react';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {Route} from 'react-router-dom';
import {withRouter} from 'react-router-dom';

import {Navigation} from './navigation';
import {redux} from '@app/redux';
import {KareNotificationsSystem} from './kare-notifications-system';
import {scopesSelector} from '@app/redux/user/selectors';
import {isAllowed} from '@app/with-permissions';
import {AccessDenied} from '@app/with-permissions/access-denied';

import {Spinner} from '@app/common';
import {PromptActionBanner} from '@app/common/layouts/main-layout/prompt-action-banner';
import {multipleApplicationSelector} from '@app/redux/application/selectors';

interface MainLayoutComponentProps {
    component?: any;
    user?: any;
    getLoggedInUser?: any;
    requiredPermissions?: any[];
    scopes?: any[];
    background?: string;
    numResponsesUsed?: any;
    numResponsesAllowed?: any;
    numDocumentsUsed?: any;
    numDocumentsAllowed?: any;
    path?: any;
    exact?: any;
    isLoading?: any;
    location: any;
    multipleApps: boolean;
}

export class MainLayoutComponent extends React.Component<MainLayoutComponentProps> {
    componentDidMount() {
        const {user, getLoggedInUser} = this.props;

        if (user && user.email) {
            this.startWidget(user);
        } else {
            getLoggedInUser().then((user) => {
                this.startWidget(user);
            });
        }
    }

    componentDidUpdate(prevProps) {
        const {user, getLoggedInUser} = this.props;

        if (user && user.email) {
            this.startWidget(user);
        } else {
            getLoggedInUser().then((user) => {
                this.startWidget(user);
            });
        }
    }

    startWidget = (user) => {
        const {location} = this.props;
        const kareWidgetScript = document.getElementById('kare-widget-script');
        if (
            !location.pathname.startsWith('/widget') &&
            user &&
            user.email &&
            !kareWidgetScript
        ) {
            const j = document.createElement('script');
            j.async = Boolean(1);
            j.type = 'text/javascript';
            j.src = `https://widget.us.karehq.com/latest.js`;
            (j.id = 'kare-widget-script'),
                //@ts-ignore
                (window.GLR = {
                    appId: '32178084-1c76-49ed-a574-fb5b8ef937ce',
                    showLauncher: false,
                    userId: user.email,
                });
            document.getElementsByTagName('head')[0].appendChild(j);
        }
    };

    render() {
        const {
            component: Component,
            numResponsesUsed,
            numResponsesAllowed,
            numDocumentsUsed,
            numDocumentsAllowed,
            scopes,
            requiredPermissions,
            user,
            isLoading,
            multipleApps,
            ...rest
        } = this.props;

        // Do not render content unless permissions are right.
        const enforceRestrictions = (requiredPermissions, scopes) => {
            if (isAllowed(requiredPermissions, scopes)) {
                return Component;
            } else if (
                !isLoading &&
                user?.id &&
                !isAllowed(requiredPermissions, scopes)
            ) {
                return AccessDenied;
            } else {
                return () => {
                    return (
                        <div className="loadingWrapper">
                            <Spinner />
                        </div>
                    );
                };
            }
        };
        const RestrictedContent = enforceRestrictions(
            requiredPermissions,
            scopes,
        );

        const promptActionBanner = multipleApps ? <PromptActionBanner /> : null;
        const classes = multipleApps
            ? 'layouts__main-layout layouts__main-layout--has-prompt-banner'
            : 'layouts__main-layout';

        if (location.pathname.startsWith('/widget')) {
            return null;
        }

        return (
            <Route
                {...rest}
                render={(matchProps) => (
                    <div className={classes}>
                        {/*
              Set the base title. Further additions will be added in the pattern:
              "Some Title | Kare Console"
              If no title is set then default to:
              "Kare Console"
            */}
                        <Helmet
                            defaultTitle="Dialpad Console"
                            titleTemplate="%s | Dialpad Console"
                        />
                        {/* <NotificationSystem theme={theme} /> */}
                        <KareNotificationsSystem />
                        <Navigation />
                        <main>
                            <RestrictedContent
                                // Pass down props needed for permissions
                                scopes={scopes}
                                requiredPermissions={requiredPermissions}
                                {...matchProps}
                            />
                            {promptActionBanner}
                        </main>
                    </div>
                )}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: redux.user.selectors.userSelector(state),
        scopes: scopesSelector(state),
        multipleApps: multipleApplicationSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    getLoggedInUser: () => redux.user.dispatchers.getUserDispatcher(dispatch),
});

export const MainLayout = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MainLayoutComponent),
);
