import React, {useState} from 'react';

import './UploadFromURL.scss';
import {TextInput} from '@app/components/inputs/text/TextInput';

export interface UploadFromURLProps {
    setContent: (url: String) => void;
    setFilename: (filename: String) => void;
}

export const UploadFromURL: React.FC<UploadFromURLProps> = (props) => {
    const [url, setUrl] = useState('');
    return (
        <div className="UploadFromURL__container">
            <div className="UploadFromURL__inputHeader">
                <div>URL</div>
                <div>Maximum 2 MB</div>
            </div>
            <div className="UploadFromURL__inputContent">
                <TextInput
                    value={url}
                    onChange={(v) => {
                        setUrl(v);
                        const filename = new URL(v);
                        const data = filename.pathname.split('/');
                        props.setContent(v);
                        props.setFilename(data[data.length - 1]);
                    }}
                />
            </div>
        </div>
    );
};
