import React from 'react';

import {Field} from 'react-final-form';
import {FieldArray} from 'react-final-form-arrays';
import {MdClose} from 'react-icons/md';
import './StartingPages.scss';
import {Label} from '../text/Label';
import {KareTextInput} from '@app/common';
import {BorderlessButton} from '../inputs/buttons/BorderlessButton';
import {Checkbox} from '../inputs/checkbox/Checkbox';

interface IStartingPages {}

export const StartingPages: React.FunctionComponent<IStartingPages> = () => {
    return (
        <div className="StartingPages__container">
            <Label text="Starting page" />
            <FieldArray name="startingPages">
                {({fields}) => {
                    return (
                        <div className="StartingPages__fieldArrayContainer">
                            {fields.map((name, index) => (
                                <div key={name}>
                                    <Field
                                        name={name}
                                        validate={(value: string) => {
                                            if (
                                                value &&
                                                !value.startsWith('/')
                                            ) {
                                                return 'Starting page have to start with /';
                                            }
                                        }}
                                    >
                                        {({input, meta}) => (
                                            <div className="StartingPages__inputContainer">
                                                <KareTextInput
                                                    placeholder="Add starting page url... (e.g: /faqs.html)"
                                                    value={fields.value[index]}
                                                    input={{...input}}
                                                    meta={{...meta}}
                                                />
                                                <MdClose
                                                    className="StartingPages__removeInput"
                                                    size={20}
                                                    onClick={() => {
                                                        if (
                                                            fields.length === 1
                                                        ) {
                                                            return;
                                                        }
                                                        fields.remove(index);
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                </div>
                            ))}
                            <BorderlessButton
                                text="+ Add new page"
                                onClick={() => {
                                    fields.push('');
                                }}
                            />
                        </div>
                    );
                }}
            </FieldArray>
            <Field name="onlyCrawlStartingPages">
                {({input}) => (
                    <Checkbox
                        text="Only crawl starting pages"
                        onChange={input.onChange}
                        selected={input.value}
                        helpMessage="Indicate the crawler to only process the starting pages and stop navigating further."
                    />
                )}
            </Field>
        </div>
    );
};
