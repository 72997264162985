import React, {
    FunctionComponent as Fc,
    useContext,
    useEffect,
    useState,
} from 'react';
//@ts-ignore
import {AxisTick} from '@nivo/axes';
import {RadialChart} from '@app/components/RadialChart/RadialChart';
import {Card, Grid} from '@material-ui/core';
import {StoreContext} from '@app/state';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import './CxMetrics.scss';
import {
    transformAggregatedCxMetricsDataToRadialChart,
    transformCxMetricsDataToBarChart,
} from '@app/utils/CxMetricsDataTransform';
import {CxMetricsAggregatedResponse, MetricObject} from '@app/types/CxMetrics';
import {BarChart} from '@app/components/BarChart/BarChart';
import {CX_TYPES} from './constants';
import {Dissatisfied} from '@app/assets/icons/dissatisfied';
import {Neutral} from '@app/assets/icons/neutral';
import {Satisfied} from '@app/assets/icons/satisfied';
import {GreatlyDissatisfied} from '@app/assets/icons/greatly-dissatisfied';
import {ModeratelyDissatisfied} from '@app/assets/icons/moderately-dissatisfied';
import {GreatlySatisfied} from '@app/assets/icons/greatly-satisfied';
import {
    DATA_KEYS,
    getChartColor,
    getLabelFromDataKeys,
} from '@app/utils/DataRenderingUtils';
import {MdExpandMore, MdExpandLess} from 'react-icons/md';
import {Spinner} from '@app/components/button/spinner';
import {EmptyState} from '@app/components/EmptyState/EmptyState';
import {CSSTransition} from 'react-transition-group';
import {FormulaSchema} from './utils';
import {ReadReviews} from '../../common/icons/read-reviews';

interface CxMetricsProps {
    heroTitle: string;
    cxType: string;
}

const initialDataState: {
    data: {cxMetrics: CxMetricsAggregatedResponse};
    loading: boolean;
    error: string;
} = {
    data: {
        cxMetrics: {
            from: 0,
            to: 0,
            entries: undefined,
        },
    },
    loading: true,
    error: '',
};

export const customTick: Fc = (
    tick: any,
    tickData: {key: string; value: number},
) => {
    if (!tickData[tick.tickIndex]) return null;

    const key = tickData[tick.tickIndex].key;
    switch (key) {
        case DATA_KEYS.GREATLY_DISSATISFIED:
            return (
                <g transform={`translate(${tick.x - 10}, ${tick.y})`}>
                    <GreatlyDissatisfied size={20} color={getChartColor(key)} />
                </g>
            );
        case DATA_KEYS.DISSATISFIED:
        case DATA_KEYS.DETRACTORS:
            return (
                <g transform={`translate(${tick.x - 10}, ${tick.y})`}>
                    <Dissatisfied size={20} color={getChartColor(key)} />
                </g>
            );
        case DATA_KEYS.MODERATELY_DISSATISFIED:
            return (
                <g transform={`translate(${tick.x - 10}, ${tick.y})`}>
                    <ModeratelyDissatisfied
                        size={20}
                        color={getChartColor(key)}
                    />
                </g>
            );
        case DATA_KEYS.NEUTRAL:
            return (
                <g transform={`translate(${tick.x - 10}, ${tick.y})`}>
                    <Neutral size={20} color={getChartColor(key)} />
                </g>
            );
        case DATA_KEYS.MODERATELY_SATISFIED:
            return (
                <g transform={`translate(${tick.x - 10}, ${tick.y})`}>
                    <ModeratelyDissatisfied
                        size={20}
                        color={getChartColor(key)}
                    />
                </g>
            );
        case DATA_KEYS.SATISFIED:
        case DATA_KEYS.PROMOTERS:
            return (
                <g transform={`translate(${tick.x - 10}, ${tick.y})`}>
                    <Satisfied size={20} color={getChartColor(key)} />
                </g>
            );
        case DATA_KEYS.GREATLY_SATISFIED:
            return (
                <g transform={`translate(${tick.x - 10}, ${tick.y})`}>
                    <GreatlySatisfied size={20} color={getChartColor(key)} />
                </g>
            );
        default:
            return null;
    }
};

interface ICxMetricsInitialState {
    metricKeys?: string[];
    showFormula?: {[x: string]: boolean};
    hideFormula?: {[x: string]: boolean};
}

export const CxMetrics: Fc<CxMetricsProps> = ({heroTitle, cxType}) => {
    const {cxMetrics} = useContext(StoreContext);
    const {minDate, maxDate} = useContext(StoreContext).date;

    const initialState: ICxMetricsInitialState = {
        metricKeys: undefined,
        showFormula: undefined,
    };
    const [data, setData] = useState(initialDataState);
    const [metricKeys, setMetricKeys] = useState(initialState.metricKeys);
    const [hideFormula, setHideFormula] = useState<boolean[]>([]);
    const [showFormula, setShowFormula] = useState<boolean[]>([]);

    const getData = async () => {
        setData(initialDataState);
        try {
            const cxMetricsRequest = await cxMetrics.getAggregatedCxMetrics(
                minDate,
                maxDate,
            );

            if (cxMetricsRequest.error) {
                setData({
                    loading: false,
                    error: 'Error',
                    data: {cxMetrics: {}},
                });
            } else {
                setData({
                    loading: false,
                    error: '',
                    data: {
                        cxMetrics: cxMetricsRequest.data,
                    },
                });
            }
        } catch (e) {
            setData({
                loading: false,
                error: 'Error',
                data: {cxMetrics: {}},
            });
        }
    };

    useEffect(() => {
        getData();
    }, [minDate, maxDate]);

    useEffect(() => {
        if (data.data.cxMetrics?.entries?.[cxType]) {
            setMetricKeys(Object.keys(data.data.cxMetrics.entries[cxType]));
        }
    }, [data]);

    useEffect(() => {
        setHideFormula(metricKeys?.map(() => true) || []);
        setShowFormula(metricKeys?.map(() => false) || []);
    }, [metricKeys]);

    const showEmptyState =
        !data.data.cxMetrics || !data.data.cxMetrics.entries
            ? 'There is no data to show'
            : null;

    const renderSpinner = () => {
        return <Spinner />;
    };

    return (
        <ErrorLoadingWrapper
            height="244px"
            error={data.error}
            isLoading={data.loading}
            CustomLoading={renderSpinner}
            emptyState={showEmptyState}
            render={() => {
                const dataToUse = (metricId: string) => {
                    if (!data.data.cxMetrics?.entries?.[cxType])
                        return {
                            radialChart: undefined,
                            barChart: undefined,
                        };

                    return {
                        radialChart:
                            transformAggregatedCxMetricsDataToRadialChart(
                                data.data.cxMetrics,
                                cxType,
                                metricId,
                            ),
                        barChart: transformCxMetricsDataToBarChart(
                            data.data.cxMetrics,
                            cxType,
                            metricId,
                        ),
                    };
                };

                const getBarChartKeys = () => {
                    switch (cxType) {
                        case CX_TYPES.NPS:
                            return ['detractors', 'neutral', 'promoters'];
                        case CX_TYPES.CSAT:
                            return ['dissatisfied', 'neutral', 'satisfied'];
                        case CX_TYPES.CES:
                            return [
                                'greatlyDissatisfied',
                                'dissatisfied',
                                'moderatelyDissatisfied',
                                'neutral',
                                'moderatelySatisfied',
                                'satisfied',
                                'greatlySatisfied',
                            ];
                        default:
                            return [];
                    }
                };

                const getTickValues = (
                    cxMetricBarChartData: any,
                    getType?: string,
                ): string[] | number[] | {key: string; value: number}[] => {
                    const accumulator: number[] = [];
                    const ticks = Object.keys(cxMetricBarChartData)
                        .filter(
                            (prop: string) =>
                                cxMetricBarChartData[prop] > 0 &&
                                prop !== 'cxmetrics' &&
                                prop !== 'answers',
                        )
                        .map((prop) => {
                            const currentPos = accumulator.reduce(
                                (acc, value) => acc + value,
                                0,
                            );
                            accumulator.push(cxMetricBarChartData[prop]);
                            return {
                                key: prop,
                                value:
                                    currentPos + cxMetricBarChartData[prop] / 2,
                            };
                        });

                    return getType ? ticks.map((a) => a[getType]) : ticks;
                };

                const getCountValueProperty = (id: string) => {
                    switch (id) {
                        case DATA_KEYS.GREATLY_DISSATISFIED:
                            return `${cxType}.greatly_dissatisfied`;
                        case DATA_KEYS.MODERATELY_DISSATISFIED:
                            return `${cxType}.moderately_dissatisfied`;
                        case DATA_KEYS.MODERATELY_SATISFIED:
                            return `${cxType}.moderately_satisfied`;
                        case DATA_KEYS.GREATLY_SATISFIED:
                            return `${cxType}.greatly_satisfied`;
                        default:
                            return `${cxType}.${id}`;
                    }
                };

                const renderFormulaInfo = () => {
                    switch (cxType) {
                        case CX_TYPES.NPS:
                            return (
                                <div
                                    className={
                                        'CxMetrics__formulaInfo' + cxType
                                    }
                                >
                                    <span>FORMULA</span>
                                    <span style={{fontSize: 12}}>
                                        Net Promoter Score® ={' '}
                                        <span
                                            style={{
                                                color: getChartColor(
                                                    DATA_KEYS.PROMOTERS,
                                                ),
                                            }}
                                        >
                                            % Promoters
                                        </span>{' '}
                                        -{' '}
                                        <span
                                            style={{
                                                color: getChartColor(
                                                    DATA_KEYS.DETRACTORS,
                                                ),
                                            }}
                                        >
                                            % Detractors
                                        </span>
                                    </span>
                                </div>
                            );
                        case CX_TYPES.CSAT:
                            return (
                                <div
                                    className={
                                        'CxMetrics__formulaInfo' + cxType
                                    }
                                >
                                    <span>FORMULA</span>
                                    <span className="CxMetrics__satisfactionLabel">
                                        Satisfaction Score
                                    </span>
                                    <span>=</span>
                                </div>
                            );
                        case CX_TYPES.CES:
                            return (
                                <div
                                    className={
                                        'CxMetrics__formulaInfo' + cxType
                                    }
                                >
                                    <span>FORMULA</span>
                                    <span style={{fontSize: 12}}>
                                        Net Easy Score ={' '}
                                        <span
                                            style={{
                                                color: getChartColor(
                                                    DATA_KEYS.SATISFIED,
                                                ),
                                            }}
                                        >
                                            % Easy
                                        </span>{' '}
                                        -{' '}
                                        <span
                                            style={{
                                                color: getChartColor(
                                                    DATA_KEYS.DISSATISFIED,
                                                ),
                                            }}
                                        >
                                            % Difficult
                                        </span>
                                    </span>
                                </div>
                            );
                        default:
                            return null;
                    }
                };

                return (
                    <Grid container spacing={2}>
                        {data.data.cxMetrics?.entries &&
                        metricKeys?.some(
                            (metricKey) =>
                                data.data.cxMetrics.entries?.[cxType][metricKey]
                                    ?.counters?.answers,
                        ) ? (
                            metricKeys?.map((property, index: number) => {
                                const cxMetricRawData: MetricObject = data.data
                                    .cxMetrics.entries
                                    ? data.data.cxMetrics.entries[cxType][
                                          property
                                      ]
                                    : undefined;
                                if (!cxMetricRawData) return;

                                const {metric_id: metricId} = cxMetricRawData;
                                const hideFormulas = [...hideFormula];
                                const showFormulas = [...showFormula];

                                const hideFormulaContainer = () => {
                                    hideFormulas[index] = true;
                                    showFormulas[index] = false;
                                    setHideFormula(hideFormulas);
                                    setShowFormula(showFormulas);
                                };

                                const showFormulaContainer = () => {
                                    hideFormulas[index] = false;
                                    showFormulas[index] = true;
                                    setHideFormula(hideFormulas);
                                    setShowFormula(showFormulas);
                                };

                                const {
                                    barChart: cxMetricBarChartData,
                                    radialChart: cxMetricRadialChartData,
                                } = dataToUse(metricId);

                                return cxMetricRadialChartData &&
                                    cxMetricBarChartData &&
                                    !!cxMetricRawData.counters.answers ? (
                                    <Grid
                                        key={`${property}-${index}`}
                                        item
                                        xs={12}
                                    >
                                        <Card className="CxMetrics__cardContainer">
                                            <div className="CxMetrics__kareTitleContainer">
                                                <div className="CxMetrics__kareCardTitle">
                                                    <h1>
                                                        {
                                                            cxMetricRawData.metric_id
                                                        }
                                                    </h1>
                                                </div>
                                                <div className="CxMetrics__kareCardStats">
                                                    <div className="CxMetrics__stats">
                                                        <span className="CxMetrics__counter">
                                                            {
                                                                cxMetricRawData
                                                                    .counters
                                                                    .impressions
                                                            }{' '}
                                                        </span>
                                                        <span className="CxMetrics__label">
                                                            Views
                                                        </span>
                                                    </div>
                                                    <div className="CxMetrics__stats">
                                                        <span className="CxMetrics__counter">
                                                            {
                                                                cxMetricRawData
                                                                    .counters
                                                                    .answers
                                                            }{' '}
                                                        </span>
                                                        <span className="CxMetrics__label">
                                                            Votes
                                                        </span>
                                                    </div>
                                                    <div className="CxMetrics__stats">
                                                        <ReadReviews className="read-reviews-icon" />
                                                        <span className="CxMetrics__counter">
                                                            {
                                                                cxMetricRawData
                                                                    .counters
                                                                    .feedbacks
                                                            }{' '}
                                                        </span>
                                                        <span className="CxMetrics__label">
                                                            Feedback
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="CxMetrics__kareGraphContainer">
                                                <div className="CxMetrics__radialChart">
                                                    <RadialChart
                                                        extraOptions={{
                                                            startAngle: -90,
                                                            endAngle: 90,
                                                        }}
                                                        plotData={
                                                            cxMetricRadialChartData?.plotData
                                                        }
                                                        direction="column" // change for self served
                                                        hero={{
                                                            value:
                                                                Math.round(
                                                                    cxMetricRadialChartData
                                                                        .legendData
                                                                        .positive ||
                                                                        cxMetricRadialChartData
                                                                            .legendData
                                                                            .neutral ||
                                                                        cxMetricRadialChartData
                                                                            .legendData
                                                                            .negative,
                                                                ) || 0,
                                                            title: heroTitle,
                                                        }}
                                                        isPercentage={false}
                                                    />
                                                </div>
                                                <div className="CxMetrics__barChart">
                                                    <div className="CxMetrics__questionTitle">
                                                        <span>
                                                            {
                                                                cxMetricRawData.question
                                                            }
                                                        </span>
                                                    </div>
                                                    <BarChart
                                                        data={[
                                                            cxMetricBarChartData,
                                                        ]}
                                                        keys={getBarChartKeys()}
                                                        index="scoreType"
                                                        layout="horizontal"
                                                        enableLabel
                                                        animate={false}
                                                        height={200}
                                                        label={(d) =>
                                                            `${d.value}%`
                                                        }
                                                        margin={{
                                                            top: 0,
                                                            right: 20,
                                                            left: 20,
                                                            bottom: 70,
                                                        }}
                                                        axisBottom={{
                                                            tickSize: 20,
                                                            tickPadding: 0,
                                                            tickRotation: 0,
                                                            renderTick: (d) =>
                                                                customTick(
                                                                    // @ts-ignore
                                                                    d,
                                                                    getTickValues(
                                                                        cxMetricBarChartData,
                                                                    ),
                                                                ),
                                                            // @ts-ignore
                                                            tickValues:
                                                                getTickValues(
                                                                    cxMetricBarChartData,
                                                                    'value',
                                                                ),
                                                        }}
                                                        labelColorConfig={{
                                                            from: 'color',
                                                            modifiers: [
                                                                ['brighter', 5],
                                                            ],
                                                        }}
                                                        tooltip={({
                                                            id,
                                                            value,
                                                            color,
                                                        }) => (
                                                            <div className="CxMetrics__barChartTooltip">
                                                                <span
                                                                    style={{
                                                                        color,
                                                                        marginRight:
                                                                            '5px',
                                                                    }}
                                                                >
                                                                    {getLabelFromDataKeys(
                                                                        id.toString(),
                                                                    )}
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        fontWeight: 600,
                                                                    }}
                                                                >
                                                                    {
                                                                        cxMetricRawData
                                                                            .counters[
                                                                            getCountValueProperty(
                                                                                id.toString(),
                                                                            )
                                                                        ]
                                                                    }{' '}
                                                                    ({value}%)
                                                                </span>
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'CxMetrics__kareFormulaContainer'
                                                }
                                            >
                                                <div className="CxMetrics__label">
                                                    {hideFormulas[index] && (
                                                        <div
                                                            onClick={
                                                                showFormulaContainer
                                                            }
                                                            className="CxMetrics__labelContainer"
                                                        >
                                                            <span>
                                                                Show Formula{' '}
                                                            </span>
                                                            <MdExpandMore
                                                                size={20}
                                                            />
                                                        </div>
                                                    )}
                                                    <CSSTransition
                                                        in={showFormulas[index]}
                                                        timeout={{
                                                            appear: 300,
                                                            enter: 300,
                                                            exit: 0,
                                                        }}
                                                        classNames={{
                                                            enter: 'CxMetrics__hideFormulaEnter',
                                                            enterActive:
                                                                'CxMetrics__hideFormulaEnterActive',
                                                            enterDone:
                                                                'CxMetrics__hideFormulaEnterDone',
                                                            exit: 'CxMetrics__hideFormulaExit',
                                                            exitActive:
                                                                'CxMetrics__hideFormulaExitActive',
                                                            exitDone:
                                                                'CxMetrics__hideFormulaExitDone',
                                                        }}
                                                        onEnter={
                                                            showFormulaContainer
                                                        }
                                                        onExited={
                                                            hideFormulaContainer
                                                        }
                                                        unmountOnExit
                                                    >
                                                        <React.Fragment>
                                                            <div className="CxMetrics__formulaPanel">
                                                                {renderFormulaInfo()}
                                                                {cxType !==
                                                                    CX_TYPES.CSAT && (
                                                                    <div className="CxMetrics__divider">
                                                                        &nbsp;
                                                                    </div>
                                                                )}
                                                                <div
                                                                    className={
                                                                        'CxMetrics__formulaSchema' +
                                                                        cxType
                                                                    }
                                                                >
                                                                    <FormulaSchema
                                                                        cxType={
                                                                            cxType
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <button
                                                                type="button"
                                                                onClick={
                                                                    hideFormulaContainer
                                                                }
                                                                className="CxMetrics__labelContainer"
                                                            >
                                                                <span>
                                                                    Hide Formula{' '}
                                                                </span>
                                                                <MdExpandLess
                                                                    size={20}
                                                                />
                                                            </button>
                                                        </React.Fragment>
                                                    </CSSTransition>
                                                </div>
                                            </div>
                                        </Card>
                                    </Grid>
                                ) : undefined;
                            })
                        ) : (
                            <div className="CxMetrics__emptyState">
                                <EmptyState />
                            </div>
                        )}
                    </Grid>
                );
            }}
        />
    );
};
