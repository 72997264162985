import {rest} from './rest';
import * as selectors from './selectors';
import * as actions from './actions';
import * as constants from './constants';
import {reducer} from './reducers';

export const feedbacks = {
    rest,
    selectors,
    constants,
    actions,
    reducer,
};
