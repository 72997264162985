import React from 'react';
import {
    getClusterColor,
    getClusterHeaderIcon,
    getClusterQueriesNumber,
    getClusterQueriesSubText,
    getClusterQueriesText,
    getClusterTitle,
} from './utils';

interface ClusterHeaderProps {
    pendingClusters?: number;
    cluster?: any;
    selectedClusterIndex?: number;
}

export const ClusterHeader = ({
    pendingClusters,
    cluster,
    selectedClusterIndex,
}: ClusterHeaderProps) => {
    const queriesNumber = getClusterQueriesNumber(cluster);
    const clusterType = cluster.cluster_type;
    return (
        <div className="manage-answer-information">
            <h2 style={{backgroundColor: getClusterColor(clusterType)}}>
                <span>{getClusterTitle(clusterType)}</span>
                <span>
                    {pendingClusters - selectedClusterIndex - 1} cards left
                </span>
            </h2>
            <div className="manage-answer-information__content">
                <strong>
                    {getClusterHeaderIcon(clusterType)}
                    {getClusterQueriesText(clusterType, queriesNumber)}
                </strong>
                <span>{getClusterQueriesSubText(clusterType)}</span>
            </div>
        </div>
    );
};
