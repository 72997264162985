import React from 'react';
import PropTypes from 'prop-types';
// @ts-ignore
import ReactPlayer from 'react-player';
import {get} from 'lodash';
export const MessageVideo = (props) => {
    const {message} = props;
    return (
        <div
            className={
                'message-disambiguation__container console-activity--out console-activity-message-container'
            }
        >
            <div className={'message-disambiguation__body'}>
                <ReactPlayer
                    url={get(message, 'body.text')}
                    width="100%"
                    height="100%"
                    controls={true}
                />
            </div>
        </div>
    );
};

MessageVideo.propTypes = {
    message: PropTypes.object,
};
