import {
    APPLICATION_JSON,
    ACCEPT_LANGUAGE,
    CONTENT_TYPE,
    ACCEPT,
    AUTHORIZATION,
    BEARER,
    CONTENT_LOCALE,
} from '../constants';
import {constants} from '../../utils/locale';

export const getRequestOptions = (token, contentLocale) => {
    const options = {
        headers: {
            [ACCEPT]: APPLICATION_JSON,
            [CONTENT_TYPE]: APPLICATION_JSON,
            [ACCEPT_LANGUAGE]:
                window.navigator.language ||
                /*@ts-ignore*/
                window.navigator.userLanguage,
        },
    };
    options.headers[CONTENT_LOCALE] =
        contentLocale || constants.DEFAULT_LOCALE_EU;
    if (token) {
        options.headers[AUTHORIZATION] = `${BEARER} ${token}`;
    }
    return options;
};

/**
 * Adapter response copied and tweaked from redux-api lib.
 */
export const adapterFetch = (fetch) => {
    return function (url, opts) {
        return fetch(url, opts).then((resp) => {
            return toJSON(resp)
                .then((data) => {
                    if (resp.status >= 400) {
                        return Promise.reject(data);
                    }
                    return Promise.resolve(data);
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        });
    };
};

function processData(data) {
    try {
        return JSON.parse(data);
    } catch (err) {
        return data;
    }
}

function toJSON(resp) {
    if (resp.text) {
        return resp.text().then(processData);
    } else if (resp instanceof Promise) {
        return resp.then(processData);
    } else {
        return Promise.resolve(resp).then(processData);
    }
}
