import floor from 'lodash/floor';

export const getResolvedRatio = (
    resolved,
    handover,
    bounced,
    conversations,
) => {
    if (conversations === 0 || resolved > conversations) {
        return '-';
    }
    return floor((resolved / (conversations - bounced)) * 100, 0);
};

export const getBounceRateRatio = (bounced, conversations) => {
    if (conversations === 0 || bounced > conversations) {
        return '-';
    }
    return floor(((conversations - bounced) / conversations) * 100.0);
};

export const mapDataToPieChartModel = (resolved, handover, escalated) => {
    return [
        {
            name: 'resolved',
            value: resolved,
        },
        {
            name: 'handover',
            value: handover,
        },
        {
            name: 'escalated',
            value: escalated,
        },
    ];
};

export const mapBounceDataToPieChart = (conversations, bounced) => {
    return [
        {
            name: 'engaged',
            value: conversations - bounced,
        },
        {
            name: 'bounced',
            value: bounced,
        },
    ];
};
