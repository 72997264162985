import React from 'react';
import {ResponseTitleError, CharacterCount} from '@app/common';

interface TitleInputProps {
    meta?: {
        error: string;
        visited: boolean;
        touched: boolean;
        submitFailed: boolean;
    };
    maxLength?: number;
    placeholder?: string;
    input?: {
        value: string;
    };
}

export class TitleInput extends React.Component<TitleInputProps> {
    render() {
        const {
            meta: {error, submitFailed},
            placeholder,
            input,
        } = this.props;

        return (
            <div className="knowledge-base__response-form__title">
                {submitFailed && error && <ResponseTitleError />}
                <input {...input} type="text" placeholder={placeholder} />
                <CharacterCount limit={280} count={input.value.length} />
            </div>
        );
    }
}
