export const AUTO_ANSWER_CLUSTER = 'auto-answer';
export const IMPROVE_ANSWER_CLUSTER = 'improve-answer';
export const MISSING_KNOWLEDGE_CLUSTER = 'missing-knowledge';
export const DISAMBIGUATION_CLUSTER = 'disambiguation';

export const WINDOW_FAKE_CARD = 'fakeCard';

export const GET_CLUSTERS = 'getClusters';
export const GET_CLUSTER = 'getCluster';
export const SAVE_CLUSTER = 'saveCluster';
export const DELETE_CLUSTER = 'deleteCluster';
export const GET_CLUSTER_QUERIES = 'getClusterQueries';
