import React, {Fragment} from 'react';
import {withRouter} from 'react-router';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {DatePicker} from './date-picker';
import {DashboardActivity} from './dashboard-activity';
import {Responses} from './responses';
import {Queries} from './queries';
import {ErrorBoundary} from '../common';
import {InsertChart} from '@app/common/icons';
import {redux} from '@app/redux';
import {PERMISSION_KEYS} from '@app/constants';
import {EmptyState} from './empty-state';
import {
    isAllMetricsEmpty,
    isAnyMetricsApiLoading,
} from '@app/redux/metrics/selectors';
import {scopesSelector} from '@app/redux/user/selectors';
import {hasScope} from '@app/utils/auth';

interface DashboardProps {
    isAllMetricsEmpty?: boolean;
    isSetupComplete?: boolean;
    isMetricsAPILoading?: boolean;
    scopes?: any[];
    history?: any;
}

export class DashboardComponent extends React.Component<DashboardProps> {
    componentDidMount() {
        const scopes = this.props.scopes;
        if (scopes.length > 0) {
            if (
                !hasScope(scopes, PERMISSION_KEYS.MIND.ALL) &&
                !hasScope(scopes, PERMISSION_KEYS.MIND.KNOWLEDGE.READ) &&
                !hasScope(scopes, PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE) &&
                !hasScope(scopes, PERMISSION_KEYS.MIND.DIALOG)
            ) {
                this.props.history.push('/settings');
            }
        }
    }

    render() {
        const {
            isAllMetricsEmpty,
            isSetupComplete,
            isMetricsAPILoading,
            scopes,
        } = this.props;

        if (scopes.length > 0) {
            if (
                !hasScope(scopes, PERMISSION_KEYS.MIND.ALL) &&
                !hasScope(scopes, PERMISSION_KEYS.MIND.KNOWLEDGE.READ) &&
                !hasScope(scopes, PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE) &&
                !hasScope(scopes, PERMISSION_KEYS.MIND.DIALOG)
            ) {
                return null;
            }
        }

        if (!isSetupComplete) {
            return <EmptyState />;
        }

        return (
            <ErrorBoundary>
                <div className="o-panel flex">
                    <Helmet title="Dashboard" />
                    <DatePicker />
                    {isAllMetricsEmpty && !isMetricsAPILoading ? (
                        <div className="kare-empty-state">
                            <InsertChart size={60} />
                            <h1>No activity between the selected dates</h1>
                            <p>
                                On your dashboard, you can see the overview of
                                the customer activity you receive through your
                                self service widget.
                                <br />
                                It seems like no customer activity took place
                                within the selected dates. Please change the
                                range you’ve selected to see more analytics.
                            </p>
                        </div>
                    ) : (
                        <Fragment>
                            <DashboardActivity />
                            <Queries />
                            <Responses />
                        </Fragment>
                    )}
                </div>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => {
    const scopes = redux.user.selectors.scopesSelector(state);
    const hasSettingsPermission = hasScope(
        scopes,
        PERMISSION_KEYS.MIND.SETTINGS,
    );
    return {
        isMetricsAPILoading: isAnyMetricsApiLoading(state),
        isAllMetricsEmpty: isAllMetricsEmpty(state),
        isSetupComplete: redux.onboarding.selectors.isSetupCompleteSelector(
            state,
            hasSettingsPermission,
        ),
        scopes: scopesSelector(state),
    };
};

export const Dashboard = withRouter(
    connect(mapStateToProps)(DashboardComponent),
);
