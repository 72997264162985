import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Spinner} from '@app/common';
import {refreshTokenSelector, tokenSelector} from '@app/redux/user/selectors';
import {refreshTokenGrantDispatcher} from '@app/redux/user/dispatchers';
import {redux} from '@app/redux';
import {auth, removeLocalStorage, setCookie, getCookie} from '@app/utils/auth';
import {isEmpty} from 'lodash';
import {REMEMBER_ME, USER_DATA_STORAGE_KEY} from '@app/redux/user/constants';

interface RefreshProps {
    token?: string;
    refreshToken?: string;
    logout?: any;
    refreshTokenAction?: any;
}

export class RefreshComponent extends React.Component<RefreshProps> {
    handleInvalidRefresh = () => {
        this.props.logout().then(() => {
            removeLocalStorage();
            window.location.href = '/login';
        });
    };

    handleRefreshResponse = (data) => {
        if (
            data &&
            !isEmpty(data) &&
            JSON.parse(String(getCookie(REMEMBER_ME) || false))
        ) {
            setCookie(
                USER_DATA_STORAGE_KEY,
                // TODO change this if backend changes variable names to b
                // consistent with login
                JSON.stringify({
                    access_token: data.access_token,
                    refresh: data.refresh_token,
                }),
            );

            window.location.href = '/bi';
        } else {
            this.handleInvalidRefresh();
        }
    };

    componentDidMount() {
        this.props.refreshTokenAction(this.props.refreshToken).then(
            (d) => {
                this.handleRefreshResponse(d);
            },
            () => {
                this.handleInvalidRefresh();
            },
        );
    }

    render() {
        return <Spinner withCenteredContainer />;
    }
}

const mapStateToProps = () => ({
    refreshToken: refreshTokenSelector(),
    token: tokenSelector(),
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(redux.locale.actionCreators.resetContentLocaleActionCreator());
        return auth.logout({redux, dispatch});
    },
    refreshTokenAction: (t) => {
        return refreshTokenGrantDispatcher(dispatch, t);
    },
});

export const Refresh = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RefreshComponent),
);
