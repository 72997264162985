import {ACTIONS, DYNAMIC_KEYS} from './constants';
import {ERROR, LOADING, ENTRIES, PAYLOAD} from '../constants';

const {
    PUBLISHED_RESPONSES,
    PUBLISHED_CHATTER_RESPONSES,
    DRAFT_RESPONSES,
    ARCHIVED_RESPONSES,
    PUBLISHED_DOCUMENTS,
    ARCHIVED_DOCUMENTS,
    RELATED_DOCUMENTS,
    RELATED_RESPONSES,
    INBOUND_NODES,
    ALL_CONTENT,
} = DYNAMIC_KEYS;

const initialKnowledgeState = {
    [ENTRIES]: [],
    [LOADING]: false,
    [ERROR]: null,
};
/**
 * Initial state for the knowledgeBase module.
 */
const initialState = {
    preloadedDisambiguationResponse: null,
    query: '',
    [PUBLISHED_CHATTER_RESPONSES]: initialKnowledgeState,
    [PUBLISHED_RESPONSES]: initialKnowledgeState,
    [ARCHIVED_RESPONSES]: initialKnowledgeState,
    [DRAFT_RESPONSES]: initialKnowledgeState,
    [PUBLISHED_DOCUMENTS]: initialKnowledgeState,
    [ARCHIVED_DOCUMENTS]: initialKnowledgeState,
    [RELATED_DOCUMENTS]: initialKnowledgeState,
    [RELATED_RESPONSES]: initialKnowledgeState,
    [INBOUND_NODES]: initialKnowledgeState,
    ALL_CONTENT: {
        [LOADING]: false,
        [ERROR]: null,
    },
};

/**
 * The reducer for the knowledgeBase module.
 * @param {Object} state
 * @param {String} action
 */
export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.SEARCH_TEXT:
            return {
                ...state,
                query: action.query,
            };

        // All content
        case ACTIONS.SET_ALL_CONTENT_LOADING:
            return {
                ...state,
                [ALL_CONTENT]: {
                    /*@ts-ignore*/
                    ...state[ALL_CONTENT],
                    [LOADING]: true,
                },
            };
        case ACTIONS.UNSET_ALL_CONTENT_LOADING:
            return {
                ...state,
                [ALL_CONTENT]: {
                    /*@ts-ignore*/
                    ...state[ALL_CONTENT],
                    [LOADING]: false,
                },
            };
        case ACTIONS.SET_ALL_CONTENT_ERROR:
            return {
                ...state,
                [ALL_CONTENT]: {
                    /*@ts-ignore*/
                    ...state[ALL_CONTENT],
                    [ERROR]: action[PAYLOAD][ERROR],
                },
            };
        case ACTIONS.UNSET_ALL_CONTENT_ERROR:
            return {
                ...state,
                [ALL_CONTENT]: {
                    /*@ts-ignore*/
                    ...state[ALL_CONTENT],
                    [ERROR]: null,
                },
            };

        // PUBLISHED CHATTER RESPONSES
        case ACTIONS.SET_PUBLISHED_CHATTER_RESPONSES:
            return {
                ...state,
                [PUBLISHED_CHATTER_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[PUBLISHED_CHATTER_RESPONSES],
                    [ENTRIES]: action[PAYLOAD][ENTRIES],
                },
            };
        case ACTIONS.SET_PUBLISHED_CHATTER_RESPONSES_LOADING:
            return {
                ...state,
                [PUBLISHED_CHATTER_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[PUBLISHED_CHATTER_RESPONSES],
                    [LOADING]: true,
                },
            };
        case ACTIONS.UNSET_PUBLISHED_CHATTER_RESPONSES_LOADING:
            return {
                ...state,
                [PUBLISHED_CHATTER_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[PUBLISHED_CHATTER_RESPONSES],
                    [LOADING]: false,
                },
            };
        case ACTIONS.SET_PUBLISHED_CHATTER_RESPONSES_ERROR:
            return {
                ...state,
                [PUBLISHED_CHATTER_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[PUBLISHED_CHATTER_RESPONSES],
                    [ERROR]: action[PAYLOAD][ERROR],
                },
            };
        case ACTIONS.UNSET_PUBLISHED_CHATTER_RESPONSES_ERROR:
            return {
                ...state,
                [PUBLISHED_CHATTER_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[PUBLISHED_CHATTER_RESPONSES],
                    [ERROR]: null,
                },
            };

        // PUBLISHED RESPONSES
        case ACTIONS.SET_PUBLISHED_RESPONSES:
            return {
                ...state,
                [PUBLISHED_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[PUBLISHED_RESPONSES],
                    [ENTRIES]: action[PAYLOAD][ENTRIES],
                },
            };
        case ACTIONS.SET_PUBLISHED_RESPONSES_LOADING:
            return {
                ...state,
                [PUBLISHED_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[PUBLISHED_RESPONSES],
                    [LOADING]: true,
                },
            };
        case ACTIONS.UNSET_PUBLISHED_RESPONSES_LOADING:
            return {
                ...state,
                [PUBLISHED_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[PUBLISHED_RESPONSES],
                    [LOADING]: false,
                },
            };
        case ACTIONS.SET_PUBLISHED_RESPONSES_ERROR:
            return {
                ...state,
                [PUBLISHED_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[PUBLISHED_RESPONSES],
                    [ERROR]: action[PAYLOAD][ERROR],
                },
            };
        case ACTIONS.UNSET_PUBLISHED_RESPONSES_ERROR:
            return {
                ...state,
                [PUBLISHED_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[PUBLISHED_RESPONSES],
                    [ERROR]: null,
                },
            };

        // ARCHIVED RESPONSES
        case ACTIONS.SET_ARCHIVED_RESPONSES:
            return {
                ...state,
                [ARCHIVED_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[ARCHIVED_RESPONSES],
                    [ENTRIES]: action[PAYLOAD][ENTRIES],
                },
            };
        case ACTIONS.SET_ARCHIVED_RESPONSES_LOADING:
            return {
                ...state,
                [ARCHIVED_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[ARCHIVED_RESPONSES],
                    [LOADING]: true,
                },
            };
        case ACTIONS.UNSET_ARCHIVED_RESPONSES_LOADING:
            return {
                ...state,
                [ARCHIVED_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[ARCHIVED_RESPONSES],
                    [LOADING]: false,
                },
            };
        case ACTIONS.SET_ARCHIVED_RESPONSES_ERROR:
            return {
                ...state,
                [ARCHIVED_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[ARCHIVED_RESPONSES],
                    [ERROR]: action[PAYLOAD][ERROR],
                },
            };
        case ACTIONS.UNSET_ARCHIVED_RESPONSES_ERROR:
            return {
                ...state,
                [ARCHIVED_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[ARCHIVED_RESPONSES],
                    [ERROR]: null,
                },
            };

        // DRAFT RESPONSES
        case ACTIONS.SET_DRAFT_RESPONSES:
            return {
                ...state,
                [DRAFT_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[DRAFT_RESPONSES],
                    [ENTRIES]: action[PAYLOAD][ENTRIES],
                },
            };
        case ACTIONS.SET_DRAFT_RESPONSES_LOADING:
            return {
                ...state,
                [DRAFT_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[DRAFT_RESPONSES],
                    [LOADING]: true,
                },
            };
        case ACTIONS.UNSET_DRAFT_RESPONSES_LOADING:
            return {
                ...state,
                [DRAFT_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[DRAFT_RESPONSES],
                    [LOADING]: false,
                },
            };
        case ACTIONS.SET_DRAFT_RESPONSES_ERROR:
            return {
                ...state,
                [DRAFT_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[DRAFT_RESPONSES],
                    [ERROR]: action[PAYLOAD][ERROR],
                },
            };
        case ACTIONS.UNSET_DRAFT_RESPONSES_ERROR:
            return {
                ...state,
                [DRAFT_RESPONSES]: {
                    /*@ts-ignore*/
                    ...state[DRAFT_RESPONSES],
                    [ERROR]: null,
                },
            };

        // PUBLISHED DOCUMENTS
        case ACTIONS.SET_PUBLISHED_DOCUMENTS:
            return {
                ...state,
                [PUBLISHED_DOCUMENTS]: {
                    /*@ts-ignore*/
                    ...state[PUBLISHED_DOCUMENTS],
                    [ENTRIES]: action[PAYLOAD][ENTRIES],
                },
            };
        case ACTIONS.SET_PUBLISHED_DOCUMENTS_LOADING:
            return {
                ...state,
                [PUBLISHED_DOCUMENTS]: {
                    /*@ts-ignore*/
                    ...state[PUBLISHED_DOCUMENTS],
                    [LOADING]: true,
                },
            };
        case ACTIONS.UNSET_PUBLISHED_DOCUMENTS_LOADING:
            return {
                ...state,
                [PUBLISHED_DOCUMENTS]: {
                    /*@ts-ignore*/
                    ...state[PUBLISHED_DOCUMENTS],
                    [LOADING]: false,
                },
            };
        case ACTIONS.SET_PUBLISHED_DOCUMENTS_ERROR:
            return {
                ...state,
                [PUBLISHED_DOCUMENTS]: {
                    /*@ts-ignore*/
                    ...state[PUBLISHED_DOCUMENTS],
                    [ERROR]: action[PAYLOAD][ERROR],
                },
            };
        case ACTIONS.UNSET_PUBLISHED_DOCUMENTS_ERROR:
            return {
                ...state,
                [PUBLISHED_DOCUMENTS]: {
                    /*@ts-ignore*/
                    ...state[PUBLISHED_DOCUMENTS],
                    [ERROR]: null,
                },
            };

        // ARCHIVED DOCUMENTS
        case ACTIONS.SET_ARCHIVED_DOCUMENTS:
            return {
                ...state,
                [ARCHIVED_DOCUMENTS]: {
                    /*@ts-ignore*/
                    ...state[ARCHIVED_DOCUMENTS],
                    [ENTRIES]: action[PAYLOAD][ENTRIES],
                },
            };
        case ACTIONS.SET_ARCHIVED_DOCUMENTS_LOADING:
            return {
                ...state,
                [ARCHIVED_DOCUMENTS]: {
                    /*@ts-ignore*/
                    ...state[ARCHIVED_DOCUMENTS],
                    [LOADING]: true,
                },
            };
        case ACTIONS.UNSET_ARCHIVED_DOCUMENTS_LOADING:
            return {
                ...state,
                [ARCHIVED_DOCUMENTS]: {
                    /*@ts-ignore*/
                    ...state[ARCHIVED_DOCUMENTS],
                    [LOADING]: false,
                },
            };
        case ACTIONS.SET_ARCHIVED_DOCUMENTS_ERROR:
            return {
                ...state,
                [ARCHIVED_DOCUMENTS]: {
                    /*@ts-ignore*/
                    ...state[ARCHIVED_DOCUMENTS],
                    [ERROR]: action[PAYLOAD][ERROR],
                },
            };
        case ACTIONS.UNSET_ARCHIVED_DOCUMENTS_ERROR:
            return {
                ...state,
                [ARCHIVED_DOCUMENTS]: {
                    /*@ts-ignore*/
                    ...state[ARCHIVED_DOCUMENTS],
                    [ERROR]: null,
                },
            };

        // RELATED DOCUMENTS
        case ACTIONS.SET_RELATED_DOCUMENTS:
            return {
                ...state,
                [RELATED_DOCUMENTS]: {
                    /*@ts-ignore*/
                    ...state[RELATED_DOCUMENTS],
                    [ENTRIES]: action[PAYLOAD][ENTRIES],
                },
            };
        case ACTIONS.SET_RELATED_DOCUMENTS_LOADING:
            return {
                ...state,
                [RELATED_DOCUMENTS]: {
                    /*@ts-ignore*/
                    ...state[RELATED_DOCUMENTS],
                    [LOADING]: true,
                },
            };
        case ACTIONS.UNSET_RELATED_DOCUMENTS_LOADING:
            return {
                ...state,
                [RELATED_DOCUMENTS]: {
                    /*@ts-ignore*/
                    ...state[RELATED_DOCUMENTS],
                    [LOADING]: false,
                },
            };
        case ACTIONS.SET_RELATED_DOCUMENTS_ERROR:
            return {
                ...state,
                [RELATED_DOCUMENTS]: {
                    /*@ts-ignore*/
                    ...state[RELATED_DOCUMENTS],
                    [ERROR]: action[PAYLOAD][ERROR],
                },
            };
        case ACTIONS.UNSET_RELATED_DOCUMENTS_ERROR:
            return {
                ...state,
                [RELATED_DOCUMENTS]: {
                    /*@ts-ignore*/
                    ...state[RELATED_DOCUMENTS],
                    [ERROR]: null,
                },
            };
        // INBOUND NODES
        case ACTIONS.SET_INBOUND_NODES:
            return {
                ...state,
                [INBOUND_NODES]: {
                    /*@ts-ignore*/
                    ...state[INBOUND_NODES],
                    [ENTRIES]: action[PAYLOAD][ENTRIES],
                },
            };
        case ACTIONS.SET_INBOUND_NODES_LOADING:
            return {
                ...state,
                [INBOUND_NODES]: {
                    /*@ts-ignore*/
                    ...state[INBOUND_NODES],
                    [LOADING]: true,
                },
            };
        case ACTIONS.UNSET_INBOUND_NODES_LOADING:
            return {
                ...state,
                [INBOUND_NODES]: {
                    /*@ts-ignore*/
                    ...state[INBOUND_NODES],
                    [LOADING]: false,
                },
            };
        case ACTIONS.SET_INBOUND_NODES_ERROR:
            return {
                ...state,
                [INBOUND_NODES]: {
                    /*@ts-ignore*/
                    ...state[INBOUND_NODES],
                    [ERROR]: action[PAYLOAD][ERROR],
                },
            };
        case ACTIONS.UNSET_INBOUND_NODES_ERROR:
            return {
                ...state,
                [INBOUND_NODES]: {
                    /*@ts-ignore*/
                    ...state[INBOUND_NODES],
                    [ERROR]: null,
                },
            };
        case ACTIONS.GET_KNOWLEDGEBASE:
            return state;
        case ACTIONS.UPDATE_KNOWLEDGEBASE: {
            return {
                ...state,
                [ACTIONS.UPDATE_KNOWLEDGEBASE]: {
                    /*@ts-ignore*/
                    ...state[ACTIONS.UPDATE_KNOWLEDGEBASE],
                    private: action.isPrivate,
                },
            };
        }
        default:
            return state;
    }
};
