import React from 'react';
import cx from 'classnames';
import {getTimestamp} from '../utils/timestamp';
import {Markdown} from '@app/common';

interface MessageResolutionProps {
    message: any;
    isHovering: boolean;
    onMouseEnter: any;
    resetCache: any;
    onMouseLeave: any;
}

export class MessageResolutionMissing extends React.Component<MessageResolutionProps> {
    render() {
        const {message, isHovering, onMouseEnter, onMouseLeave} = this.props;

        const timestamp = getTimestamp(message);
        const text = message.body?.text;

        const isUserMessage = message.uri.match(/^in\//);
        const className = cx(
            'console-activity-message-container',
            'console-activity-message',
            'console-activity-message-resolution-missing',
            {
                'console-activity-message--in console-activity--in':
                    isUserMessage,
                'console-activity-message--out console-activity--out':
                    !isUserMessage,
            },
        );

        if (!text) {
            return null;
        }

        return (
            <section
                className={className}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <div>
                    <h1>MISSING ANSWER</h1>
                    <Markdown>{text}</Markdown>
                </div>
                {isHovering && (
                    <time className="console-activity-message-timestamp">
                        {timestamp}
                    </time>
                )}
            </section>
        );
    }
}
