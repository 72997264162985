import {get} from 'lodash';
import {GET_LABELS, DYNAMIC_KEYS, ACTIONS} from './constants';
import {ENTRIES, LOADING, ERROR} from '../constants';

const {
    PUBLISHED_RESPONSES,
    ARCHIVED_RESPONSES,
    DRAFT_RESPONSES,
    RELATED_DOCUMENTS,
    RELATED_RESPONSES,
    PUBLISHED_DOCUMENTS,
    ARCHIVED_DOCUMENTS,
    ALL_CONTENT,
    PUBLISHED_CHATTER_RESPONSES,
    INBOUND_NODES,
} = DYNAMIC_KEYS;

/**
 * Select the current search query.
 * @param {Object} state
 */
export const searchQuerySelector = (state) => {
    return state.knowledgeBase.query;
};
export const getFilteredLabelsSelector = (state) => {
    return get(state, [GET_LABELS, 'data', 'tags'], []);
};

export const getKnowledgeBaseSelector = (state) => {
    return get(state, [ACTIONS.GET_KNOWLEDGEBASE, 'data'], undefined);
};

export const getAppSelector = (state) => {
    return get(state, [ACTIONS.GET_APP, 'data'], undefined);
};

const getEntriesSelector = (key) => (state) => {
    return state.knowledgeBase[key][ENTRIES];
};
const getNumEntriesSelector = (key) => (state) => {
    return state.knowledgeBase[key][ENTRIES].length;
};
const getLoadingSelector = (key) => (state) => {
    return state.knowledgeBase[key][LOADING];
};
const getErrorSelector = (key) => (state) => {
    return state.knowledgeBase[key][ERROR];
};

// All content
export const allContentLoadingSelector = getLoadingSelector(ALL_CONTENT);
export const allContentErrorSelector = getErrorSelector(ALL_CONTENT);

// Published chatter responses
export const publishedChatterResponsesSelector = getEntriesSelector(
    PUBLISHED_CHATTER_RESPONSES,
);
export const numPublishedChatterResponsesSelector = getNumEntriesSelector(
    PUBLISHED_CHATTER_RESPONSES,
);
export const publishedChatterResponsesLoadingSelector = getLoadingSelector(
    PUBLISHED_CHATTER_RESPONSES,
);
export const publishedChatterResponsesErrorSelector = getErrorSelector(
    PUBLISHED_CHATTER_RESPONSES,
);

// Published responses
export const publishedResponsesSelector =
    getEntriesSelector(PUBLISHED_RESPONSES);
export const numPublishedResponsesSelector =
    getNumEntriesSelector(PUBLISHED_RESPONSES);
export const publishedResponsesLoadingSelector =
    getLoadingSelector(PUBLISHED_RESPONSES);
export const publishedResponsesErrorSelector =
    getErrorSelector(PUBLISHED_RESPONSES);

// Archived responses
export const archivedResponsesSelector = getEntriesSelector(ARCHIVED_RESPONSES);
export const numArchivedResponsesSelector =
    getNumEntriesSelector(ARCHIVED_RESPONSES);
export const archivedResponsesLoadingSelector =
    getLoadingSelector(ARCHIVED_RESPONSES);
export const archivedResponsesErrorSelector =
    getErrorSelector(ARCHIVED_RESPONSES);

// Draft responses
export const draftResponsesSelector = getEntriesSelector(DRAFT_RESPONSES);
export const numDraftResponsesSelector = getNumEntriesSelector(DRAFT_RESPONSES);
export const draftResponsesLoadingSelector =
    getLoadingSelector(DRAFT_RESPONSES);
export const draftResponsesErrorSelector = getErrorSelector(DRAFT_RESPONSES);

// Published Documents
export const publishedDocumentsSelector =
    getEntriesSelector(PUBLISHED_DOCUMENTS);
export const numPublishedDocumentsSelector =
    getNumEntriesSelector(PUBLISHED_DOCUMENTS);
export const publishedDocumentsLoadingSelector =
    getLoadingSelector(PUBLISHED_DOCUMENTS);
export const publishedDocumentsErrorSelector =
    getErrorSelector(PUBLISHED_DOCUMENTS);

// Archived Documents
export const archivedDocumentsSelector = getEntriesSelector(ARCHIVED_DOCUMENTS);
export const numArchivedDocumentsSelector =
    getNumEntriesSelector(ARCHIVED_DOCUMENTS);
export const archivedDocumentsLoadingSelector =
    getLoadingSelector(ARCHIVED_DOCUMENTS);
export const archivedDocumentsErrorSelector =
    getErrorSelector(ARCHIVED_DOCUMENTS);

// Related Documents
export const relatedDocumentsSelector = getEntriesSelector(RELATED_DOCUMENTS);
export const numRelatedDocumentsSelector =
    getNumEntriesSelector(RELATED_DOCUMENTS);
export const relatedDocumentsLoadingSelector =
    getLoadingSelector(RELATED_DOCUMENTS);
export const relatedDocumentsErrorSelector =
    getErrorSelector(RELATED_DOCUMENTS);

// Related Responses
export const relatedResponsesSelector = getEntriesSelector(RELATED_RESPONSES);
export const numRelatedResponsesSelector =
    getNumEntriesSelector(RELATED_RESPONSES);
export const relatedResponsesLoadingSelector =
    getLoadingSelector(RELATED_RESPONSES);
export const relatedResponsesErrorSelector =
    getErrorSelector(RELATED_RESPONSES);

// Related Responses
export const inboundNodesSelector = getEntriesSelector(INBOUND_NODES);
export const numInboundNodesSelector = getNumEntriesSelector(INBOUND_NODES);
export const inboundNodesLoadingSelector = getLoadingSelector(INBOUND_NODES);
export const inboundNodesErrorSelector = getErrorSelector(INBOUND_NODES);
