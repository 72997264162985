import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import {redux} from '@app/redux';
import {
    PortalSidebar,
    PanelHeader,
    PanelBody,
    PanelFooter,
    PanelSummary,
    Spinner,
    Button,
    ErrorBoundary,
} from '@app/common';
import {querystring} from '@app/utils';

interface RelatedResponsesProps {
    match: any;
    history: any;
    location: any;
    inboundNodes: any[];
    getRelatedResponses: any;
    onClose: any;
    inboundNodesLoading: boolean;
    documentId: string;
}

export class RelatedResponsesComponent extends React.Component<RelatedResponsesProps> {
    state = {
        nodes: [],
    };

    componentDidMount() {
        this.props.getRelatedResponses({id: this.getDocumentId(this.props)});
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.inboundNodes !== this.props.inboundNodes &&
            this.props.inboundNodes.length > 0
        ) {
            this.setState({
                nodes: this.props.inboundNodes,
            });
        }
    }

    getDocumentId(props) {
        if (props.documentId && props.documentId !== '') {
            return props.documentId;
        } else {
            const {
                match: {
                    params: {documentId},
                },
            } = props;
            return documentId;
        }
    }

    handleClickRelatedResponse = (responseId) => () => {
        const {
            history: {push},
            location,
        } = this.props;
        // TODO(rupertrutland): Should there be a redirect of some sort as well? - Test this manually.
        // Cannot currently test as the backend is broken.
        push(querystring.getEditResponsePath(location, responseId));
    };

    renderRelatedResponses = () => {
        const inboundNodes = this.state.nodes;
        if (inboundNodes.length === 0) {
            return (
                <p className="knowledge-base__panel-related-responses-no-results">
                    No results found
                </p>
            );
        }

        // Fix a bug from backend, returns repeated nodes. One day we'll fix the real bug, but for now...
        const uniqueNodes = [];
        const filteredNodes = inboundNodes.filter((item) => {
            if (uniqueNodes.indexOf(item.node.id) === -1) {
                uniqueNodes.push(item.node.id);
                return true;
            } else {
                return false;
            }
        });

        return (
            <div className={'knowledge-base__panel-related-responses'}>
                {filteredNodes.map((response) => {
                    const {id, title, tags} = response.node;
                    return (
                        <PanelSummary
                            onClick={this.handleClickRelatedResponse(id)}
                            title={title}
                            tags={tags}
                            key={id}
                        />
                    );
                })}
            </div>
        );
    };

    handleClickCreateRelatedResponse = (id) => () => {
        const {
            history: {push},
            location: {search},
        } = this.props;
        // Force closing the related document drawer as we will have two modals open at the same time.
        // TODO(rupertrutland): find a better way to do this.
        const customLocation = {
            pathname: `/library/${this.getDocumentId(this.props)}${search}`,
            search,
        };
        push(querystring.getNewResponseWithDocumentPath(customLocation, id));
    };

    /**
     * Return the portal wrapper with the editor form inside.
     */
    render() {
        const {inboundNodesLoading} = this.props;
        const {
            location: {search},
            match: {
                params: {documentId},
            },
        } = this.props;
        return (
            <ErrorBoundary>
                <PortalSidebar>
                    <PanelHeader
                        title="Related responses"
                        invertColors={true}
                        onClose={this.props.onClose}
                        closeLink={`/library/${documentId}${search}`}
                    />
                    {inboundNodesLoading && <Spinner />}
                    <PanelBody>{this.renderRelatedResponses()}</PanelBody>
                    <PanelFooter>
                        <Button
                            onClick={this.handleClickCreateRelatedResponse(
                                documentId,
                            )}
                        >
                            Create a related response
                        </Button>
                    </PanelFooter>
                </PortalSidebar>
            </ErrorBoundary>
        );
    }
}

/**
 * Map redux store state to props for the component.
 * @param {Object} state
 */
const mapStateToProps = (state) => {
    return {
        inboundNodes: redux.knowledgeBase.selectors.inboundNodesSelector(state),
        inboundNodesLoading:
            redux.knowledgeBase.selectors.inboundNodesLoadingSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRelatedResponses:
            redux.knowledgeBase.dispatchers.getAllInboundNodesDispatcher(
                dispatch,
            ),
    };
};

export const RelatedResponses = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RelatedResponsesComponent),
);
