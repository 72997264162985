import React, {useCallback, useState} from 'react';
import axios from 'axios';
import cx from 'classnames';
import {get} from 'lodash';
import {useDropzone} from 'react-dropzone';
import {Form} from 'react-final-form';
import Modal from 'react-modal';
import {connect} from 'react-redux';
import {Button} from '@app/common';
import {Link} from '@app/common/icons';
import {redux} from '@app/redux';
import {UPLOAD_ASSET} from '@app/redux/knowledge-base/constants';
import {MODAL_BASE_CONFIG} from '@app/utils/modals';
import {BlockControls} from '../block-controls';

export const renderInactiveImage = (img) => {
    return (
        <div className="kare-block kare-block--inactive">
            <img src={img} alt={img} />
            <div className="media-block-url">
                <Link size={10} />
                <span>{img}</span>
            </div>
        </div>
    );
};

interface ImageBlockFieldComponentProps {
    input?: any;
    selectDocument?: any;
    activateBlock?: any;
    removeBlock?: any;
    fields?: any[];
    index?: number;
}

export const ImageBlockFieldComponent = (
    props: ImageBlockFieldComponentProps,
) => {
    const {removeBlock, fields, index, input} = props;
    const onDrop = useCallback((images) => {
        setImage(images[0]);
        const reader = new FileReader();
        reader.onload = (e) => {
            setThumb(e.target.result);
        };
        reader.readAsDataURL(images[0]);
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
    });

    const [activeDragAnDrop, setActiveDragAnDrop] = useState(true);
    const [image, setImage] = useState(null);
    const [thumb, setThumb] = useState(null);
    const [url, setUrl] = useState(
        get(input, ['value', 'image_block', 'url'], ''),
    );
    const [active, setActive] = useState(!url);
    const handleClickCancel = () => {
        setActive(false);
        setThumb(null);
        setImage(null);
    };
    const handleClickAdd = (forcedUrl = null) => {
        const {
            input: {onChange},
        } = props;
        onChange({
            type: 'image',
            image_block: {url: forcedUrl || get(url, url)},
        });
        setActive(false);
    };

    const handleChange = (event) => {
        const value = event.target.value;
        setUrl({url: value});
    };

    const activateBlock = () => {
        const {input} = props;
        const url = get(input, ['value', 'image_block', 'url'], '');
        setActive(true);
        setUrl(url);
    };
    const formUrl = get(input, ['value', 'image_block', 'url'], '');
    const name = 'image_url';

    return (
        <div className="kare-block-wrapper">
            {renderInactiveImage(formUrl)}
            <BlockControls
                index={index}
                fields={fields}
                removeBlock={removeBlock}
                activateBlock={activateBlock}
            />
            {active && (
                <Modal
                    isOpen={true}
                    style={{
                        ...MODAL_BASE_CONFIG,
                        content: {
                            ...MODAL_BASE_CONFIG.content,
                            width: '400px',
                            height: '300px',
                            border: '0',
                        },
                    }}
                >
                    <Form
                        onSubmit={() => {
                            const formData = new FormData();
                            if (image) {
                                formData.append('asset', image);

                                axios
                                    .post(`v3.1/kbm/assets/pictures`, formData)
                                    .then((r: any) => {
                                        if (r && r.data && r.data.url) {
                                            handleClickAdd(r.data.url);
                                        }
                                    });
                            }
                        }}
                        render={(renderProps) => {
                            return (
                                <section className="media-block-modal image-block-modal">
                                    <h1>
                                        {url && 'Update image'}
                                        {!url && 'Choose an image to add'}
                                    </h1>
                                    <div className={'asset-upload__selector'}>
                                        <span
                                            className={cx(
                                                'asset-upload__selector-btn',
                                                {
                                                    active: activeDragAnDrop,
                                                },
                                            )}
                                            onClick={() => {
                                                setActiveDragAnDrop(true);
                                            }}
                                        >
                                            Upload
                                        </span>
                                        <span
                                            className={cx(
                                                'asset-upload__selector-btn',
                                                {
                                                    active: !activeDragAnDrop,
                                                },
                                            )}
                                            onClick={() => {
                                                setActiveDragAnDrop(false);
                                            }}
                                        >
                                            Image from Url
                                        </span>
                                    </div>
                                    {url && url.url && (
                                        <div
                                            className="media-block-modal-current-image"
                                            title={url}
                                        >
                                            <img
                                                src={url.url}
                                                alt={url.value}
                                            />
                                        </div>
                                    )}
                                    {activeDragAnDrop && (
                                        <div className="assets-uploader">
                                            <div
                                                {...getRootProps()}
                                                className={cx(
                                                    'assets-uploader__drag',
                                                    {
                                                        active: !!thumb,
                                                        dragging: isDragActive,
                                                    },
                                                )}
                                            >
                                                <input {...getInputProps()} />
                                                {!image && !url && (
                                                    <span className="assets-uploader__drag-add">
                                                        Drag and drop images
                                                        here or{' '}
                                                        <a>click here</a>
                                                    </span>
                                                )}
                                                {image && thumb && (
                                                    <>
                                                        <strong
                                                            className="assets-image__file-name"
                                                            title={image.name}
                                                        >
                                                            {image.name}
                                                        </strong>
                                                        <img
                                                            className="thumbnail"
                                                            src={thumb}
                                                            alt="thumbnail"
                                                        />
                                                    </>
                                                )}
                                                {!image && url && (
                                                    <img
                                                        src={url}
                                                        alt={url.value}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {!activeDragAnDrop && (
                                        <div>
                                            <label
                                                htmlFor={name}
                                                className="kare-text-input__label"
                                            >
                                                Image URL
                                            </label>
                                            <input
                                                placeholder="https://myimage.jpg"
                                                type="text"
                                                key={name}
                                                id={name}
                                                value={get(url, 'url')}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    )}
                                    <div className="media-block-modal-buttons">
                                        <Button
                                            onClick={handleClickCancel}
                                            outlined
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                if (!activeDragAnDrop) {
                                                    handleClickAdd(
                                                        get(url, 'url'),
                                                    );
                                                } else {
                                                    renderProps.form.submit();
                                                }
                                            }}
                                        >
                                            {url && 'Update'}
                                            {!url && 'Add'}
                                        </Button>
                                    </div>
                                </section>
                            );
                        }}
                    />
                    {/* TODO(rr): Refactor this out for the video block. */}
                </Modal>
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    uploadAsset: (values) => {
        dispatch(
            redux.rest.actions[UPLOAD_ASSET](
                {},
                {
                    body: values.body,
                },
            ),
        );
    },
});

export const ImageBlockField = connect(
    null,
    mapDispatchToProps,
)(ImageBlockFieldComponent);
