export const HORIZONTAL_LAUNCHER = 'HORIZONTAL_LAUNCHER';
export const VERTICAL_LAUNCHER = 'VERTICAL_LAUNCHER';
export const CUSTOM_LAUNCHER = 'CUSTOM_LAUNCHER';

export const MOBILE = 'MOBILE';
export const TABLET_DESKTOP = 'TABLET_DESKTOP';

export const BUTTON_COLOR_SCHEME_DARK = 'DARK';
export const BUTTON_COLOR_SCHEME_LIGHT = 'LIGHT';

export const BRANDED = 'BRANDED';
export const FLOATING = 'FLOATING';
export const BOOKMARK = 'BOOKMARK';
export const CUSTOM = 'CUSTOM';

export const BRANDED_BADGE_STYLE_DOT = 'DOT';
export const BRANDED_BADGE_STYLE_NUMBER = 'NUMBER';

export const BOOKMARK_ICON_BRANDED = 'BRANDED';
export const BOOKMARK_ICON_QUESTION_MARK = 'QUESTION_MARK';

export const LAUNCHER_SIZE_SMALL = 'small';
export const LAUNCHER_SIZE_MEDIUM = 'medium';
export const LAUNCHER_SIZE_LARGE = 'large';

export const LAUNCHER_POSITION_TOP = 'top';
export const LAUNCHER_POSITION_MIDDLE = 'middle';
export const LAUNCHER_POSITION_BOTTOM = 'bottom';

export const LAUNCHER_SIDE_LEFT = 'left';
export const LAUNCHER_SIDE_RIGHT = 'right';
