import {CONTENT_TYPE, METHODS} from '../constants';
import {GET_LABELS, UPLOAD_ASSET, ACTIONS} from './constants';

const {GET, POST, PATCH} = METHODS;
export const rest = {
    [GET_LABELS]: {url: '/v3.1/kbm/tags', options: {method: GET}},
    [UPLOAD_ASSET]: {
        url: '/v3.1/kbm/assets/pictures',
        options: {
            headers: {[CONTENT_TYPE]: 'multipart/form-data; boundary=AaB03x'},
            method: POST,
        },
    },
    [ACTIONS.GET_KNOWLEDGEBASE]: {
        url: '/v3.1/kbm',
        options: {
            method: GET,
        },
    },
    [ACTIONS.UPDATE_KNOWLEDGEBASE]: {
        url: '/v3.1/kbm',
        options: {
            method: PATCH,
        },
    },
    [ACTIONS.GET_APP]: {
        url: '/v3.1/iam/apps/:id',
        options: {
            method: GET,
        },
    },
    [ACTIONS.UPDATE_APP]: {
        url: '/v3.1/iam/apps/:id',
        options: {
            method: PATCH,
        },
    },
};
