import React, {useState, useRef} from 'react';
import {MdSettings} from 'react-icons/md';

import './WebCrawlerItem.scss';
import {Domain} from '@app/api/webcrawler';
import {WebCrawlerTooltip} from './WebCrawlerTooltip';
import {useClickOutsideComponent} from '@app/hooks/ClickOutsideComponentHook';

export interface WebCrawlerItemProps {
    domain: Domain;
    onEdit: () => void;
    onDelete: () => void;
}

export const WebCrawlerItem: React.FC<WebCrawlerItemProps> = (props) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const wRef = useRef(null);
    useClickOutsideComponent(wRef, () => {
        setShowTooltip(false);
    });
    return (
        <React.Fragment>
            <div className="WebCrawlerItem__container">
                <div className="WebCrawlerItem__host">{props.domain.host}</div>
                <MdSettings
                    size={10}
                    onClick={() => {
                        setShowTooltip(true);
                    }}
                />
            </div>
            {showTooltip && (
                <div ref={wRef}>
                    <WebCrawlerTooltip
                        {...props}
                        closeTooltip={() => {
                            setShowTooltip(false);
                        }}
                    />
                </div>
            )}
        </React.Fragment>
    );
};
