export const DEV_ENV = 'development';

export const NODE_TYPES = {
    RESPONSE: 'response',
    CONTENT: 'content',
    CHATTER: 'chattering',
};

export const NODE_STATUSES = {
    ARCHIVED: 'deleted',
    DRAFT: 'unpublished',
    PUBLISHED: 'published',
};

export const TYPE = 'type';
export const VALUE = 'value';
export const STATUS = 'status';
export const COMPOSE = 'compose';
export const CLUSTER = 'cluster';
export const LIMIT = 'limit';
export const CLONE = 'clone';
export const NEW = 'new';
export const WITH_DOCUMENT = 'withDocument';
export const TEXT = 'text';
export const URL = 'url';
export const USER = 'user';
export const SCOPES = 'scopes';
export const LABELS = 'labels';

export const FIELD_MASKS = 'field_masks';

export const BLOCK_TYPES = {
    TEXT: 'text',
    VIDEO: 'video',
    IMAGE: 'image',
    QUOTE: 'quote',
    CHOICES: 'choices',
    EXTENSION: 'webext',
    HANDOVER: 'handover',
    CHATTERING: 'chattering',
    CTA: 'call-to-action',
    ATTACHMENTS: 'attachments',
    CX_QUESTION: 'cx-question',
    WORKFLOW: 'workflow',
};

export const BLOCK_KEYS = {
    TEXT_BLOCK: 'text_block',
    VIDEO_BLOCK: 'video_block',
    IMAGE_BLOCK: 'image_block',
    QUOTE_BLOCK: 'quote_block',
    CHOICES_BLOCK: 'choices_block',
    EXTENSION_BLOCK: 'webext_block',
    HANDOVER_BLOCK: 'handover_block',
    CTA_BLOCK: 'call_to_action_block',
    ATTACHMENT_BLOCK: 'attachments_block',
    CX_QUESTION_BLOCK: 'cx_question_block',
    WORKFLOW_BLOCK: 'workflow_block',
};

export const CX_BLOCK_TYPES = {
    NPS: 'nps',
    CSAT: 'csat',
    CES: 'ces',
};

export const PERMISSION_KEYS = {
    MIND: {
        ALL: 'mind.all',
        DIALOG: 'mind.dialog',
        SETTINGS: 'mind.settings',
        IAM: 'mind.iam',
        KNOWLEDGE: {
            READ: 'mind.knowledge.read',
            WRITE: 'mind.knowledge.write',
        },
    },
};

export const PERMISSIONS = {
    [PERMISSION_KEYS.MIND.ALL]: 'Admin',
    [PERMISSION_KEYS.MIND.DIALOG]: 'Dialog',
    [PERMISSION_KEYS.MIND.SETTINGS]: 'Settings',
    [PERMISSION_KEYS.MIND.IAM]: 'IAM',
    [PERMISSION_KEYS.MIND.KNOWLEDGE.READ]: 'Read',
    [PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE]: 'Write',
};

export const renderTitle = (type) => {
    switch (type) {
        case CX_BLOCK_TYPES.NPS:
            return 'Net Promoter Score (NPS)®';
        case CX_BLOCK_TYPES.CSAT:
            return 'Customer Satisfaction Scale (CSAT)';
        case CX_BLOCK_TYPES.CES:
            return 'Customer Effort Score (CES)';
    }
};

export const AUDIENCE_OPTIONS = [
    {value: 'any', name: 'Any'},
    {value: 'visitor', name: 'Visitor'},
    {value: 'registered', name: 'Registered'},
];

export const DEVICE_OPTIONS = [
    {value: 'any', name: 'Any'},
    {value: 'desktop', name: 'Desktop'},
    {value: 'tablet', name: 'Tablet'},
    {value: 'mobile', name: 'Mobile'},
];

export const OS_OPTIONS = [
    {value: 'any', name: 'Any'},
    {value: 'windows', name: 'Windows'},
    {value: 'linux', name: 'Linux'},
    {value: 'macos', name: 'MacOS'},
    {value: 'ios', name: 'iOS'},
    {value: 'android', name: 'Android'},
];

export const BROWSER_OPTIONS = [
    {value: 'any', name: 'Any'},
    {value: 'chrome', name: 'Chrome'},
    {value: 'safari', name: 'Safari'},
    {value: 'ie', name: 'Internet Explorer'},
    {value: 'edge', name: 'Edge'},
    {value: 'firefox', name: 'Firefox'},
    {value: 'opera', name: 'Opera'},
];
