import React, {Component} from 'react';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import cx from 'classnames';
import {getTimestamp} from '../utils/timestamp';
import {Document, ChatBubble} from '@app/common/icons';
import {querystring, permissions} from '@app/utils';
import {PERMISSION_KEYS} from '@app/constants';
import {connect} from 'react-redux';
import {redux} from '@app/redux';

interface MessageDisabiguation {
    message: any;
    isHovering: any;
    onMouseEnter: any;
    onMouseLeave: any;
    history: any;
    location: any;
    scopes: any;
}

export class MessageDisabiguationComponent extends Component<MessageDisabiguation> {
    static propTypes = {
        message: PropTypes.object.isRequired,
        history: PropTypes.shape({
            push: PropTypes.func,
        }),
        location: PropTypes.shape({
            search: PropTypes.string,
        }),
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        isHovering: PropTypes.bool,
        scopes: PropTypes.array,
    };
    // @ts-ignore
    handleFooterItemClick = (action) => () => {
        const {
            history: {push},
            location,
        } = this.props;
        const onClickHandlers = get(action, 'on_click', []);
        const command = onClickHandlers.find(
            (actionCommand) => actionCommand.command === 'send',
        );
        const documentId = get(
            command,
            'params.m.message_body.in_click.body.linkedDocID',
        );
        if (documentId) {
            return push(`/library/${documentId}`);
        }
        const responseId = get(command, 'params.m.header.resource_ids');
        if (responseId) {
            return push(querystring.getEditResponsePath(location, responseId));
        }
    };

    getActionData = (action) => {
        const {
            history: {push},
            location,
            scopes,
        } = this.props;
        const hasKnowledgePermission = permissions.hasRequiredPermissions(
            [
                PERMISSION_KEYS.MIND.KNOWLEDGE.READ,
                PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE,
            ],
            scopes,
        );
        const onClickHandlers = get(action, 'on_click', []);
        const command = onClickHandlers.find(
            (actionCommand) => actionCommand.command === 'send',
        );
        const documentId = get(
            command,
            'params.m.message_body.in_click.body.linkedDocID',
        );
        if (documentId) {
            return {
                onClick: hasKnowledgePermission
                    ? () => push(`/library/${documentId}`)
                    : null,
                type: 'document',
                icon: <Document size={15} />,
            };
        }
        const choiceId = get(
            command,
            'params.m.message_body.in_click.choice_id',
        );
        if (choiceId) {
            return {
                onClick: hasKnowledgePermission
                    ? () =>
                          push(
                              querystring.getEditResponsePath(
                                  location,
                                  choiceId,
                              ),
                          )
                    : null,
                type: 'response',
                icon: <ChatBubble size={15} />,
            };
        }
        const responseId = get(command, 'params.m.header.resource_ids');
        if (responseId) {
            return {
                onClick: hasKnowledgePermission
                    ? () =>
                          push(
                              querystring.getEditResponsePath(
                                  location,
                                  responseId,
                              ),
                          )
                    : null,
                type: 'response',
                icon: <ChatBubble size={15} />,
            };
        }
        return null;
    };

    renderDisambiguationFooter = (message) => {
        const actions = get(
            message,
            ['event_body', 'out_text', 'footer', 'actions'],
            [],
        );
        const {timestamp} = message.created_at;
        return actions.map((action, index) => {
            const actionData = this.getActionData(action);
            if (!actionData) {
                return null;
            }
            const className = cx('message-disambiguation__footer-item', {
                'message-disambiguation__footer-item--is-clickable':
                    actionData.onClick,
            });
            return (
                <div
                    onClick={actionData.onClick ? actionData.onClick : () => {}}
                    className={className}
                    key={`${index}${timestamp}${action.text}`}
                >
                    <div className="message-disambiguation__footer-item-title">
                        {actionData.icon} {action.text}
                    </div>
                </div>
            );
        });
    };

    render() {
        const {message, isHovering, onMouseEnter, onMouseLeave} = this.props;
        const timestamp = getTimestamp(message);
        const body = get(message, ['event_body', 'out_text']);
        const actions = get(
            message,
            ['event_body', 'out_text', 'footer', 'actions'],
            [],
        );
        if (!body && actions.length === 0) {
            return null;
        }
        return (
            <div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className="message-disambiguation__container console-activity--out console-activity-message-container"
            >
                <div>
                    <div className="message-disambiguation__body">
                        <div>{body.text}</div>
                    </div>
                    <div className="message-disambiguation__footer">
                        {this.renderDisambiguationFooter(message)}
                    </div>
                </div>

                {isHovering && (
                    <time className="console-activity-message-timestamp">
                        {timestamp}
                    </time>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        scopes: redux.user.selectors.scopesSelector(state),
    };
};

export const MessageWithDisambiguation = withRouter(
    connect(mapStateToProps)(MessageDisabiguationComponent),
);
