import floor from 'lodash/floor';

export const getResolvedRatio = (resolved, conversations) => {
    if (conversations === 0 || resolved > conversations) {
        return '-';
    }
    return floor((resolved / conversations) * 100, 0);
};

export const mapDataToPieChartModel = (responses, disambiguations, missing) => {
    return [
        {
            name: 'responses',
            value: responses,
        },
        {
            name: 'disambiguations',
            value: disambiguations,
        },
        {
            name: 'missing',
            value: missing,
        },
    ];
};
