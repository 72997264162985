export const ESCALATION_SETTINGS_FORM = 'escalationSettingsForm';
export const ESCALATION_METHOD = 'escalationMethod';
export const ESCALATION_VALUE = 'escalationValue';

export const AVAILABLE_ESCALATION_METHODS = [
    'url',
    'email',
    'zendesk',
    'phone',
    'webext',
    'custom',
    'dialpad_callback',
];

export const ESCALATION_FORM_FIELDS = {
    METHOD: 'escalationMethod',
    VALUE: 'escalationValue',
    NAME: 'escalationName',
};

export const ESCALATION_METHODS = {
    PHONE: 'phone',
    EMAIL: 'email',
    ZENDESK: 'zendesk',
    URL: 'url',
    INTERCOM: 'intercom',
    SMS: 'sms',
    CUSTOM: 'custom',
    DIALPAD_CALLBACK: 'dialpad_callback',
    RPA: 'webext',
};
