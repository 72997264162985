import styled from '@emotion/styled';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Container = styled.div`
    width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const H2 = styled.h2`
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    margin: 36px 0 16px 0;
`;
