import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {toast} from 'react-toastify';
import axios from 'axios';
import {ErrorBoundary} from '@app/common';
import {LoginForm} from './providers/login-form';
import {DialpadLogin} from './providers/dialpad';
import {SubdomainForm} from './subdomain-form';
import {getAuths} from '@app/utils/auth';
import {getSubdomainFromURL, getDashboardDomain} from '@app/utils/client-url';
import {DEFAULT_AUTH, DIALPAD_AUTH, DIALPAD_SANDBOX_AUTH} from './constants';

interface LoginProps {
    subdomain?: string;
    location?: any;
    auths?: {name: string}[];
}

export class LoginComponent extends React.Component<LoginProps> {
    handleSubdomainSubmit = (params) => {
        const orgDomain = `${params.subdomain}.${getDashboardDomain()}`;

        axios
            .get(`/resolve?domain=${orgDomain}`)
            .then((r) => {
                if (r && r.data) {
                    window.location.host = orgDomain;
                } else {
                    toast.error('Domain not found.');
                }
            })
            .catch(() => {
                // should be able to raise error here!
                toast.error('Domain not found.');
            });
    };

    render() {
        if (!this.props.subdomain && !process.env.START) {
            // in case the subdomain is not defined. Show a previous
            return (
                <ErrorBoundary>
                    <div className="kare-center-block kare-login">
                        <div className="kare-single-column-container">
                            <h2 className="title">Login with Single Sign-on</h2>
                            <SubdomainForm
                                /*@ts-ignore*/
                                onSubmit={this.handleSubdomainSubmit}
                            />
                        </div>
                    </div>
                </ErrorBoundary>
            );
        }

        const authsMap = new Map();
        this.props.auths.map((auth) => {
            authsMap.set(auth.name, auth);
        });

        return (
            <ErrorBoundary>
                <div className="loginWrapper">
                    <div className="loginContainer">
                        {authsMap.has(DIALPAD_SANDBOX_AUTH) && (
                            <DialpadLogin
                                provider={authsMap.get(DIALPAD_SANDBOX_AUTH)}
                            />
                        )}
                        {authsMap.has(DIALPAD_AUTH) && (
                            <DialpadLogin
                                provider={authsMap.get(DIALPAD_AUTH)}
                            />
                        )}
                        {authsMap.has(DEFAULT_AUTH) && (
                            <>
                                <h2 className="title">
                                    Login with Kare account
                                </h2>
                                <LoginForm />
                            </>
                        )}
                    </div>
                </div>
            </ErrorBoundary>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        subdomain: getSubdomainFromURL(),
        auths: getAuths(),
    };
};

export const Login = withRouter(
    connect(null, mapDispatchToProps)(LoginComponent),
);
