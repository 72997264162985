import React from 'react';
import Icon from 'react-icon-base';

export const BottomPort = (props) => (
    <Icon viewBox="0 0 16 8" {...props}>
        <svg
            width="16px"
            height="8px"
            viewBox="0 0 16 8"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>connector_bottom</title>
            <g
                id="Iteration-1.1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Dialog-blocks---Connections"
                    transform="translate(-504.000000, -267.000000)"
                    fill="#92989B"
                >
                    <g
                        id="Group-4"
                        transform="translate(412.000000, 108.000000)"
                    >
                        <path
                            d="M108,159 C108,163.418278 104.418278,167 100,167 C95.581722,167 92,163.418278 92,159 L108,159 Z"
                            id="connector_bottom"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    </Icon>
);
