import React from 'react';
import cx from 'classnames';

import {Launcher, BrandedFABIcon} from '@app/common/icons';
import {BOOKMARK_ICON_BRANDED} from '../constants';

interface VerticalLauncherProps {
    buttonStyle?: any;
    iconStyle?: any;
    onMouseEnter?: any;
    onMouseLeave?: any;
    onClick?: any;
    launcherSize?: string;
    launcherPosition?: string;
    launcherSidePosition?: string;
    text: string;
    popOverText?: string;
    windowPreview?: boolean;
    showPopOver?: boolean;
    bookmarkIcon?: string;
}

export class VerticalLauncher extends React.Component<VerticalLauncherProps> {
    render() {
        const {
            buttonStyle,
            iconStyle,
            launcherSize,
            launcherPosition,
            launcherSidePosition,
            text,
            showPopOver,
            popOverText,
            bookmarkIcon,
        } = this.props;

        const widgetClass = cx(
            'launcher-preview__vertical-demo',
            `launcher-preview__vertical-demo--size-${launcherSize}`,
            `launcher-preview__vertical-demo--position-${launcherPosition}`,
            `launcher-preview__vertical-demo--side-${launcherSidePosition}`,
        );
        return (
            <div className={widgetClass}>
                {popOverText && showPopOver && this.props.windowPreview && (
                    <div className="launcher-popover__preview-container">
                        <div className="launcher-popover__preview">
                            {this.props.popOverText}
                        </div>
                    </div>
                )}
                <button
                    style={buttonStyle}
                    onMouseEnter={this.props.onMouseEnter}
                    onMouseLeave={this.props.onMouseLeave}
                    onClick={this.props.onClick}
                >
                    {text && <span>{text}</span>}
                    {bookmarkIcon === BOOKMARK_ICON_BRANDED ? (
                        <BrandedFABIcon
                            style={{
                                ...iconStyle,
                                width: '16px',
                                height: '16px',
                            }}
                        />
                    ) : (
                        <Launcher style={iconStyle} />
                    )}
                </button>
            </div>
        );
    }
}
