import React from 'react';
import {CHART_CONFIG} from '../../../constants';
import {tickSplitter} from '../utils';

interface XAxisTickRendererProps {
    x?: number;
    y?: number;
    textAnchor?: string;
    payload?: any;
}

export class XAxisTickRenderer extends React.Component<XAxisTickRendererProps> {
    render() {
        const {x, y, textAnchor, payload} = this.props;
        const splitValue = tickSplitter(payload.value);
        if (!splitValue || splitValue.length !== 2) {
            return null;
        }
        const day = splitValue[0];
        const month = splitValue[1];

        return (
            <text
                x={x}
                y={y + CHART_CONFIG.labels.xAxis.yOffset}
                textAnchor={textAnchor}
                fill={CHART_CONFIG.labels.colour}
                style={{fontSize: CHART_CONFIG.labels.size}}
            >
                <tspan x={x}>{day}</tspan>
                <tspan x={x} dy={CHART_CONFIG.labels.xAxis.lineOffset}>
                    {month}
                </tspan>
            </text>
        );
    }
}
