import {get} from 'lodash';
import {LIST_DIALPAD_CALLCENTERS, CALLCENTERS} from './constants';
import {DATA, LOADING} from '@app/redux/constants';

export const dialpadCallCenterList = (state) => {
    return get(state, [LIST_DIALPAD_CALLCENTERS, DATA, CALLCENTERS], []).map(
        (dialpadCallcenter) => ({
            value: JSON.stringify(dialpadCallcenter),
            label: dialpadCallcenter.name,
        }),
    );
};

export const isDialpadCallCenterListLoadingSelector = (state) => {
    return get(state, [LIST_DIALPAD_CALLCENTERS, LOADING]);
};
