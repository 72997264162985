import React from 'react';
import cx from 'classnames';
import {ResponsivePie} from '@nivo/pie';

import './BounceRateChart.scss';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {
    DATA_KEYS,
    getChartColor,
    getLegendIcon,
} from '@app/utils/DataRenderingUtils';
import {RadialChartsBottomLegend} from '@app/components/RadialChartsBottomLegend/RadialChartsBottomLegend';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {transformAggregatedConversationsDataToRadialChart} from '@app/utils/ConversationDataTransform';
import {RadialContentLoader} from '@app/components/ContentLoaders/RadialContentLoader';
import {ChartDataTypes} from '../Activity';

interface BounceRateChartProps {
    legendPosition: 'right' | 'bottom';
    conversationsData: ChartDataTypes;
}

export const BounceRateChart: React.FC<BounceRateChartProps> = ({
    legendPosition,
    conversationsData,
}) => {
    const showEmptyState = () => {
        if (
            !conversationsData.data ||
            !conversationsData.data.conversations.counters ||
            conversationsData.data.conversations.counters === 0
        ) {
            return 'No data';
        }
        return null;
    };

    const chartAndLegend = cx({
        ['BounceRateChart__chartAndLegendContainerRow']:
            legendPosition === 'right',
        ['BounceRateChart__chartAndLegendContainerColumn']:
            legendPosition === 'bottom',
    });

    return (
        <div className="BounceRateChart__container">
            <div className="BounceRateChart__content">
                <CardTitle title={'Bounce rate'} />
                <ErrorLoadingWrapper
                    height={'291px'}
                    error={conversationsData.error}
                    isLoading={conversationsData.loading}
                    CustomLoading={RadialContentLoader}
                    emptyState={showEmptyState()}
                    render={() => {
                        const transformedData =
                            transformAggregatedConversationsDataToRadialChart(
                                conversationsData.data.conversations,
                            );
                        const chartData = Object.keys(
                            transformedData.plotData,
                        ).map((k) => ({
                            id: k,
                            label: k,
                            value: transformedData.plotData[k],
                            color: getChartColor(k),
                        }));
                        return (
                            <div className={chartAndLegend}>
                                <div className="BounceRateChart__chartContainer">
                                    <ResponsivePie
                                        data={chartData}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            bottom: 20,
                                            left: 20,
                                        }}
                                        sortByValue={false}
                                        innerRadius={0.7}
                                        padAngle={1}
                                        colors={(c: any) =>
                                            c.data.color as string
                                        }
                                        borderWidth={0}
                                        enableArcLabels={false}
                                        enableArcLinkLabels={false}
                                    />
                                </div>
                                <RadialChartsBottomLegend
                                    firstSource={{
                                        value: transformedData.legendData
                                            .engagedValue,
                                        ratio: transformedData.legendData
                                            .engaged,
                                        label: 'Engaged',
                                        color: getChartColor(DATA_KEYS.ENGAGED),
                                        icon: getLegendIcon('', '', true),
                                    }}
                                    secondSource={{
                                        value: transformedData.legendData
                                            .bouncedValue,
                                        ratio: transformedData.legendData
                                            .bounced,
                                        label: 'Bounced',
                                        color: getChartColor(DATA_KEYS.BOUNCED),
                                        icon: getLegendIcon('', '', true),
                                    }}
                                />
                            </div>
                        );
                    }}
                />
            </div>
        </div>
    );
};
