export const transformBrokenDataToBrokenCard = (data: any) => {
    const acc = {
        brokenChoices: 0,
        brokenQuotes: 0,
    };

    if (data && data.nodes) {
        data.nodes.map((n) => {
            acc.brokenChoices += n.broken_choices ? n.broken_choices.length : 0;
            acc.brokenQuotes += n.broken_quotes ? n.broken_quotes.length : 0;
        });
    }

    return {
        brokenChoices: acc.brokenChoices,
        brokenQuotes: acc.brokenQuotes,
    };
};
