import {assign, get} from 'lodash';
import DOMPurify from 'dompurify';

const getConversationObject = (conversationArr) => {
    if (conversationArr) {
        return conversationArr.reduce((accumulator, currentValue) => {
            return assign(accumulator, {[currentValue.id]: currentValue});
        }, {});
    }
};

function getNextCursor(action, data, prevData) {
    return action &&
        action.request.pathvars &&
        action.request.pathvars.next_cursor !== ''
        ? data.next_cursor
        : prevData.next_cursor;
}

function isConversationsEndList(action, prevData, data) {
    if (
        action &&
        action.request.pathvars &&
        action.request.pathvars.next_cursor
    ) {
        return (
            get(prevData, ['next_cursor'], '') ===
                get(data, ['next_cursor'], '') ||
            get(data, ['next_cursor'], '') === ''
        );
    }
    return get(data, ['next_cursor'], '') === '';
}

export const conversationsTransformer = (data, prevData, action) => {
    if (!data && !prevData) {
        return undefined;
    }
    const conversationsObject = getConversationObject(data.conversations);
    if (prevData) {
        return {
            ...data,
            conversations: {
                ...prevData.conversations,
                ...conversationsObject,
            },
            endList: isConversationsEndList(action, prevData, data),
            next_cursor: getNextCursor(action, data, prevData),
        };
    }
    return {
        ...data,
        conversations: conversationsObject,
        endList: isConversationsEndList(action, undefined, data),
    };
};

export const messagesTransformer = (data, prevData) => {
    if (!data && !prevData) {
        return undefined;
    }

    return data.events.reduce(
        (acc, message) => {
            const text =
                message.event_body.out_text?.text ||
                message.event_body.in_query?.text ||
                message.event_body.in_click?.text;

            let sanitizedText = DOMPurify.sanitize(text);

            if (sanitizedText.includes('javascript:')) {
                sanitizedText = undefined;
            }

            acc.events.push({
                ...message,
                body: {
                    ...message.body,
                    text: sanitizedText,
                },
            });

            return acc;
        },
        {events: []},
    );
};
