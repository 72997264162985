import {get, includes} from 'lodash';
const HOVER_RESPONSE_CHARACTER_LIMIT = 150;

export const getHoverResponseBody = (response) => {
    const hoverResponseText = get(response, ['title'], '');
    return hoverResponseText.length < HOVER_RESPONSE_CHARACTER_LIMIT
        ? hoverResponseText
        : hoverResponseText.substring(0, HOVER_RESPONSE_CHARACTER_LIMIT) +
              '...';
};

export const getFilteredResponses = (responses, filterQuery = undefined) => {
    return responses.filter((response) => {
        return filterQuery
            ? includes(response.title.toLowerCase(), filterQuery)
            : true;
    });
};

export const getHoverResponseLabels = (response) => {
    const hoverResponseLabels = get(response, ['labels'], []);
    return hoverResponseLabels && hoverResponseLabels.length
        ? hoverResponseLabels.slice(0, 5)
        : [];
};
