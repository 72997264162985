import React from 'react';
import {Field} from 'redux-form';
import cx from 'classnames';

import {Close} from '@app/common/icons';

interface MultiWebsiteInputProps {
    fields?: any;
    meta?: any;
}

export class MultiWebsiteInput extends React.Component<MultiWebsiteInputProps> {
    render() {
        const {
            fields,
            meta: {dirty, error},
        } = this.props;
        return (
            <section className="multi-website-input">
                {fields.map((field, i) => (
                    <div key={i} className="import-field__container">
                        <Field
                            className={cx({
                                error: dirty && error && error.includes(i),
                            })}
                            component="input"
                            type="text"
                            name={field}
                            required={true}
                            placeholder="www.companywebsite.com/*"
                        />
                        {fields.length > 1 && (
                            <Close
                                onClick={() => {
                                    fields.remove(i);
                                }}
                            />
                        )}
                    </div>
                ))}
                <a
                    onClick={() => {
                        fields.push('');
                    }}
                >
                    + Add another domain
                </a>
                {error && error.length > 0 && dirty && (
                    <div className="import-field__error">
                        Please, review that all urls are valid
                    </div>
                )}
            </section>
        );
    }
}
