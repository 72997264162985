import {
    CxMetricsAggregatedResponse,
    CxMetricsCounters,
    CxMetricsObject,
} from '@app/types/CxMetrics';
import {DATA_KEYS, getChartColor} from './DataRenderingUtils';
import {calcRatio} from './Common';
import {CX_TYPES} from '@app/containers/CxMetrics/constants';

export const transformCxMetricsDataToBarChart = (
    data: CxMetricsAggregatedResponse,
    cxType: string,
    metricId: string,
) => {
    const cxMetrics: CxMetricsObject = data.entries ? data.entries[cxType] : {};
    if (!cxMetrics[metricId]) return;

    const counters: CxMetricsCounters =
        cxMetrics[metricId].counters || ({} as CxMetricsCounters);
    const answers = counters.answers;
    const mappedBarData = (() => {
        switch (cxType) {
            case CX_TYPES.NPS: {
                const detractors = calcRatio(
                    counters[`${cxType}.detractors`] || 0,
                    answers,
                );
                const neutral = calcRatio(
                    counters[`${cxType}.neutral`] || 0,
                    answers,
                );
                const promoters = calcRatio(
                    counters[`${cxType}.promoters`] || 0,
                    answers,
                );

                return {
                    cxmetrics: 0,
                    answers,
                    detractors,
                    detractorsColor: getChartColor(DATA_KEYS.DISSATISFIED),
                    neutral,
                    neutralColor: getChartColor(DATA_KEYS.NEUTRAL),
                    promoters,
                    promotersColor: getChartColor(DATA_KEYS.SATISFIED),
                };
            }
            case CX_TYPES.CSAT: {
                const dissatisfied = calcRatio(
                    counters[`${cxType}.dissatisfied`] || 0,
                    answers,
                );
                const neutral = calcRatio(
                    counters[`${cxType}.neutral`] || 0,
                    answers,
                );
                const satisfied = calcRatio(
                    counters[`${cxType}.satisfied`] || 0,
                    answers,
                );

                return {
                    cxmetrics: 0,
                    answers,
                    dissatisfied,
                    dissatisfiedColor: getChartColor(DATA_KEYS.DISSATISFIED),
                    neutral,
                    neutralColor: getChartColor(DATA_KEYS.NEUTRAL),
                    satisfied,
                    satisfiedColor: getChartColor(DATA_KEYS.SATISFIED),
                };
            }
            case CX_TYPES.CES: {
                const greatlyDissatisfied = calcRatio(
                    counters[`${cxType}.greatly_dissatisfied`] || 0,
                    answers,
                );
                const dissatisfied = calcRatio(
                    counters[`${cxType}.dissatisfied`] || 0,
                    answers,
                );
                const moderatelyDissatisfied = calcRatio(
                    counters[`${cxType}.moderately_dissatisfied`] || 0,
                    answers,
                );
                const neutral = calcRatio(
                    counters[`${cxType}.neutral`] || 0,
                    answers,
                );
                const moderatelySatisfied = calcRatio(
                    counters[`${cxType}.moderately_satisfied`] || 0,
                    answers,
                );
                const satisfied = calcRatio(
                    counters[`${cxType}.satisfied`] || 0,
                    answers,
                );
                const greatlySatisfied = calcRatio(
                    counters[`${cxType}.greatly_satisfied`] || 0,
                    answers,
                );

                return {
                    cxmetrics: 0,
                    answers,
                    greatlyDissatisfied,
                    greatlyDissatisfiedColor: getChartColor(
                        DATA_KEYS.GREATLY_DISSATISFIED,
                    ),
                    dissatisfied,
                    dissatisfiedColor: getChartColor(DATA_KEYS.DISSATISFIED),
                    moderatelyDissatisfied,
                    moderatelyDissatisfiedColor: getChartColor(
                        DATA_KEYS.MODERATELY_DISSATISFIED,
                    ),
                    neutral,
                    neutralColor: getChartColor(DATA_KEYS.NEUTRAL),
                    moderatelySatisfied,
                    moderatelySatisfiedColor: getChartColor(
                        DATA_KEYS.MODERATELY_SATISFIED,
                    ),
                    satisfied,
                    satisfiedColor: getChartColor(DATA_KEYS.SATISFIED),
                    greatlySatisfied,
                    greatlySatisfiedColor: getChartColor(
                        DATA_KEYS.GREATLY_SATISFIED,
                    ),
                };
            }
            default:
                return {};
        }
    })();

    if (answers === 0) {
        return {
            counters: [],
            total: 0,
            totals: {
                greatlyDissatisfied: 0,
                dissatisfied: 0,
                moderatelyDissatisfied: 0,
                neutral: 0,
                moderatelySatisfied: 0,
                satisfied: 0,
                greatlySatisfied: 0,
            },
        };
    }
    return mappedBarData;
};

export const transformAggregatedCxMetricsDataToRadialChart = (
    data: CxMetricsAggregatedResponse,
    cxType: string,
    metricId: string,
) => {
    const cxMetrics: CxMetricsObject = data.entries ? data.entries[cxType] : {};
    if (!cxMetrics[metricId]) return;

    const cxMetricCounters: CxMetricsCounters = cxMetrics[metricId].counters;

    const getResults = (): {
        ratioType: {[x: string]: number};
        results: {[x: string]: string; empty: string};
    } => {
        switch (cxType) {
            case CX_TYPES.NPS:
                return calcNps(cxMetricCounters);
            case CX_TYPES.CES:
                return calcCes(cxMetricCounters);
            case CX_TYPES.CSAT:
                return calcCsat(cxMetricCounters);
            default:
                return {
                    ratioType: {positive: 0},
                    results: {positive: '0', empty: '0'},
                };
        }
    };

    const {ratioType, results} = getResults();
    return {
        plotData: {
            ...results,
        },
        legendData: {
            ...ratioType,
        },
    };
};

const calcNps = (
    npsCounter: CxMetricsCounters,
): {
    ratioType: {[x: string]: number};
    results: {[x: string]: string; empty: string};
} => {
    const {
        'nps.detractors': NpsDetractors,
        'nps.promoters': NpsPromoters,
        answers,
    } = npsCounter;
    const ratioValue =
        calcRatio(NpsPromoters || 0, answers) -
        calcRatio(NpsDetractors || 0, answers);
    const ratioType =
        ratioValue < -34
            ? 'negative'
            : ratioValue < 34
            ? 'neutral'
            : 'positive';
    return {
        ratioType: {[ratioType]: ratioValue},
        results: {
            [ratioType]: ((100 + ratioValue) / 2).toFixed(1),
            empty: ((100 - ratioValue) / 2).toFixed(1),
        },
    };
};

const calcCsat = (
    csatCounter: CxMetricsCounters,
): {
    ratioType: {[x: string]: number};
    results: {[x: string]: string; empty: string};
} => {
    const {'csat.satisfied': csatSatisfied, answers} = csatCounter;
    const ratioValue = calcRatio(csatSatisfied || 0, answers);
    const ratioType =
        ratioValue < 34 ? 'negative' : ratioValue < 67 ? 'neutral' : 'positive';
    return {
        ratioType: {[ratioType]: ratioValue},
        results: {
            [ratioType]: ratioValue.toFixed(1),
            empty: (100 - ratioValue).toFixed(1),
        },
    };
};

const calcCes = (
    cesCounter: CxMetricsCounters,
): {
    ratioType: {[x: string]: number};
    results: {[x: string]: string; empty: string};
} => {
    const {
        'ces.greatly_dissatisfied': cesVeryDissatisfied,
        'ces.moderately_dissatisfied': cesSomeWhatDissatisfied,
        'ces.dissatisfied': cesDissatisfied,
        'ces.satisfied': cesSatisfied,
        'ces.greatly_satisfied': cesVerySatisfied,
        answers,
    } = cesCounter;
    const ratioValue =
        calcRatio((cesSatisfied || 0) + (cesVerySatisfied || 0), answers) -
        calcRatio(
            (cesVeryDissatisfied || 0) +
                (cesDissatisfied || 0) +
                (cesSomeWhatDissatisfied || 0),
            answers,
        );
    const ratioType =
        ratioValue < -34
            ? 'negative'
            : ratioValue < 34
            ? 'neutral'
            : 'positive';
    return {
        ratioType: {[ratioType]: ratioValue},
        results: {
            [ratioType]: ((100 + ratioValue) / 2).toFixed(1),
            empty: ((100 - ratioValue) / 2).toFixed(1),
        },
    };
};
