import React, {Component} from 'react';

interface KareDummyProps {
    start: any;
    end: any;
}

export class KareDummyData extends Component<KareDummyProps> {
    render() {
        const {start, end} = this.props;
        return (
            <span className="kare-dummy-data">
                [ {start && 'start'} {end && 'end'} dummy data ]
            </span>
        );
    }
}
