export const PIE_CHART_SIZE = {
    width: 120,
    height: 120,
    innerRadius: 40,
    outerRadius: 60,
};
export const PIE_CHART_CONFIG = {
    dataKey: 'value',
    nameKey: 'name',
    innerRadius: PIE_CHART_SIZE.innerRadius,
    outerRadius: PIE_CHART_SIZE.outerRadius,
    startAngle: 90,
    endAngle: -360,
};
