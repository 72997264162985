import React, {useEffect, useState, Fragment} from 'react';
import {Field} from 'redux-form';
import {ActionListModal} from '@app/dashboard';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import axios from 'axios';
import get from 'lodash/get';
import {ResourceSelectionButton} from '@app/components/inputs/buttons/ResourceSelectionButton';
import {MdFlashOn} from 'react-icons/md';
import {CONTENT_LOCALE} from '@app/redux/constants';

const getActionsHostName = (state) =>
    get(state, ['ORG', 'data', 'webext_hostname'], '');

const getEscalation = (selectedEscalationMethod) => (state) =>
    get(state, [
        'form',
        'companySettings',
        'values',
        'escalation_entries',
        selectedEscalationMethod,
    ]);

const getActions = async (setActions, actionsHost) => {
    try {
        const instance = axios.create();
        if (instance.defaults.headers[CONTENT_LOCALE])
            delete instance.defaults.headers[CONTENT_LOCALE];
        const response = await instance.get(`${actionsHost}/conf/registry`);
        if (response.status === 200) {
            setActions(response.data);
        }
    } catch (e) {
        toast.error('Actions not fetched');
    }
};

interface RPACustomEscalationProps {
    selectedEscalationMethodIndex?: number;
}

export const RPACustomEscalation = (props: RPACustomEscalationProps) => {
    const {selectedEscalationMethodIndex} = props;

    const actionsHost = useSelector(getActionsHostName);
    const escalation = useSelector(
        getEscalation(selectedEscalationMethodIndex),
    );
    const [actions, setActions] = useState([]);
    useEffect(() => {
        if (actionsHost) {
            getActions(setActions, actionsHost);
        }
    }, [actionsHost]);
    return (
        <div className="rpa-custom-escalation-container">
            <Field
                component={RPASelectionButton}
                name={`escalation_entries[${selectedEscalationMethodIndex}]`}
                actions={actions}
                escalation={escalation}
                actionsHost={actionsHost}
            />
        </div>
    );
};

interface RPASelectionButtonProps {
    actions?: any[];
    input?: any;
    escalation?: any;
}

const RPASelectionButton = (props: RPASelectionButtonProps) => {
    const {
        actions,
        input: {onChange},
        escalation,
    } = props;
    const [showModal, setShowModal] = useState(false);
    const [selectedAction, setSelectedAction] = useState<any>();

    useEffect(() => {
        if (selectedAction)
            onChange({
                ...escalation,
                rpa_name: selectedAction.title,
                value: `/apps/${selectedAction.name}/`,
            });
    }, [selectedAction]);

    useEffect(() => {
        if (!escalation.rpa_name) {
            setSelectedAction(
                actions.find((action) => escalation.value === action.url),
            );
        }
    });

    return (
        <Fragment>
            {showModal && (
                <ActionListModal
                    actions={actions}
                    onClear={() => {
                        setShowModal(false);
                    }}
                    onSelectedAction={(action) => {
                        setShowModal(false);
                        setSelectedAction(action);
                    }}
                />
            )}
            <ResourceSelectionButton
                label="RPA"
                placeholder="Choose an RPA as an escalation"
                placeholderIcon={<MdFlashOn size={20} />}
                icon={<MdFlashOn />}
                onClick={() => {
                    setShowModal(true);
                }}
                text={escalation.rpa_name}
                onClear={() => {}}
            />
        </Fragment>
    );
};
