import React, {FC} from 'react';
import {Field, formValueSelector, getFormValues, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {get, map} from 'lodash';
import {ESCALATION_ENTRIES} from '@app/redux/org/constants';
import {escalationEntriesSelector} from '@app/redux/org/selectors';

import {
    Button,
    KareDropdown,
    KareTextInput,
    MultiselectInput,
} from '@app/common';
import {locale as localeUtil, validators} from '@app/utils';
import {LOCALES} from '@app/utils/locale/constants';
import {EscalationSettings} from '../escalation-settings';
import {uuidv4} from '@app/utils/Common';
import {Prompt} from 'react-router-dom';

export const COMPANY_SETTINGS_FORM_NAME = 'companySettings';

interface CompanyFormProps {
    change?: any;
    handleSubmit?: any;
    invalid?: boolean;
    dirty?: boolean;
    available_supported_locales?: any[];
    error?: string;
    formEscalationEntries?: any[];
    orgEscalationEntries?: any[];
    showModal?: any;
    submitting?: boolean;
}

const CompanyFormComponent: FC<CompanyFormProps> = ({
    change,
    handleSubmit,
    invalid,
    dirty,
    available_supported_locales,
    error,
    formEscalationEntries,
    orgEscalationEntries,
    submitting,
}) => {
    const {required} = validators;

    const getLocaleOptions = () =>
        map(LOCALES, (name, locale) => ({value: locale, label: name}));

    const getDefaultLocaleOptions = () => {
        return available_supported_locales
            ? available_supported_locales.map((supportedLocale) => {
                  return {
                      label: LOCALES[supportedLocale],
                      value: supportedLocale,
                  };
              })
            : [];
    };

    const removeEscalationEntry = (entry) => {
        change(
            'escalation_entries',
            formEscalationEntries.filter((e) => {
                return e.id !== entry.id;
            }),
        );
    };

    const makeDefault = (entry) => {
        change(
            'escalation_entries',
            formEscalationEntries.map((e) => {
                return {...e, default: e.id === entry.id};
            }),
        );
    };

    const addEscalationEntry = () => {
        change('escalation_entries', [
            ...formEscalationEntries,
            {
                default: false,
                id: uuidv4(),
                method: 'url',
                name: '',
                value: '',
            },
        ]);
    };

    const resetEscalationField = (entry, index, draftEscalationEntries) => {
        const escalationEntryRestoreData = draftEscalationEntries
            ? draftEscalationEntries[index]
            : orgEscalationEntries[index];

        if (index >= orgEscalationEntries.length) {
            change('escalation_entries', orgEscalationEntries);
        } else {
            if (escalationEntryRestoreData === undefined) {
                change('escalation_entries', []);
            } else {
                change(entry, escalationEntryRestoreData);
            }
        }
    };

    return (
        <section className="settings__company-form">
            <Prompt
                message="Discard unsaved changes?"
                when={dirty && !submitting}
            />
            <form onSubmit={handleSubmit}>
                {error && <p className="kare-text-error">{error}</p>}
                <div>
                    <h4 className="u-margin-bottom">Information</h4>

                    <Field
                        name="subdomain"
                        component={KareTextInput}
                        label="Company domain"
                        validate={required}
                        readonly={true}
                    />
                    <Field
                        name="id"
                        component={KareTextInput}
                        label="Company ID"
                        validate={required}
                        readonly={true}
                    />
                </div>
                <div className="settings__company-form__escalation">
                    {formEscalationEntries && (
                        <EscalationSettings
                            change={change}
                            addEscalationEntry={addEscalationEntry}
                            removeEscalationEntry={removeEscalationEntry}
                            makeDefault={makeDefault}
                            resetField={resetEscalationField}
                            escalationEntries={formEscalationEntries}
                        />
                    )}
                </div>

                <Field
                    label="Default language profile"
                    name="default_locale"
                    component={KareDropdown}
                    props={{
                        options: getDefaultLocaleOptions(),
                    }}
                    format={(value) => ({
                        value,
                        label: localeUtil.getLocaleName(value),
                    })}
                    normalize={(v) => v.value}
                />
                <Field
                    label="Available language profiles"
                    format={(locales = []) =>
                        locales.map((locale) => ({
                            value: locale,
                            label: localeUtil.getLocaleName(locale),
                        }))
                    }
                    normalize={(selectedLocales = []) =>
                        selectedLocales.map(
                            (localeObject) => localeObject.value,
                        )
                    }
                    name="available_supported_locales"
                    component={MultiselectInput}
                    props={{
                        options: getLocaleOptions(),
                    }}
                />
                <section className="submit-button-container">
                    <Button disabled={dirty && invalid} onClick={handleSubmit}>
                        Save changes
                    </Button>
                </section>
            </form>
        </section>
    );
};

const mapStateToProps = (state) => ({
    orgEscalationEntries: escalationEntriesSelector(state),
    formEscalationEntries: formValueSelector(COMPANY_SETTINGS_FORM_NAME)(
        state,
        ESCALATION_ENTRIES,
    ),
    available_supported_locales: get(
        getFormValues(COMPANY_SETTINGS_FORM_NAME)(state),
        ['available_supported_locales'],
        [],
    ),
});

export const CompanyForm = reduxForm({
    form: COMPANY_SETTINGS_FORM_NAME,
    enableReinitialize: true,
    initialValues: {
        crawling_url_list: [''],
    },
})(connect(mapStateToProps)(CompanyFormComponent));
