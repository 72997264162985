import React, {FunctionComponent as Fc} from 'react';

interface INeutralProps {
    size: number;
    color: string;
}

export const DialpadCallback: Fc<INeutralProps> = ({size, color}) => (
    <svg
        height={`${size}px`}
        width={`${size}px`}
        style={{color: color}}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.01213 5.59863V2.08954C6.01213 2.05525 5.97018 2.0383 5.94626 2.06297L4.45552 3.60223C4.15066 3.47335 3.81549 3.402 3.46354 3.402C2.05602 3.402 0.915039 4.54223 0.915039 5.9487C0.915039 7.35507 2.05602 8.4953 3.46354 8.4953C4.75218 8.4953 5.81671 7.53949 5.98753 6.29867V9.80775C5.98753 9.84224 6.02938 9.8591 6.05331 9.83442L7.54405 8.29517C7.84891 8.42405 8.18418 8.4953 8.53603 8.4953C9.94355 8.4953 11.0845 7.35507 11.0845 5.9487C11.0845 4.54223 9.94355 3.402 8.53603 3.402C7.24739 3.402 6.18286 4.3579 6.01213 5.59863Z"
            fill="currentColor"
        />
    </svg>
);
