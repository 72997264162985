import React, {useState} from 'react';
import {MdAttachFile, MdClose} from 'react-icons/md';
import {TypeContent, UploadContentModal} from '@app/dashboard';
import {Button} from '@app/components/inputs/buttons/Button';
import {TextInput} from '@app/components/inputs/text/TextInput';
import {BorderlessButton} from '@app/components/inputs/buttons/BorderlessButton';
import {BLOCK_KEYS, BLOCK_TYPES} from '@app/constants';
import {uuidv4} from '@app/utils/Common';
import get from 'lodash/get';
import {toast} from 'react-toastify';

interface AttachmentCreationModalProps {
    currentAttachment?: any[];
    addAttachments?: any;
    closeModal?: any;
}

export const AttachmentCreationModal = ({
    currentAttachment,
    addAttachments,
    closeModal,
}: AttachmentCreationModalProps) => {
    const [attachments, setAttachments] = useState(
        get(
            currentAttachment,
            [BLOCK_KEYS.ATTACHMENT_BLOCK, BLOCK_TYPES.ATTACHMENTS],
            [],
        ),
    );
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const validateAttachments = () => {
        const error = attachments.filter((a) => {
            if (!a.title) {
                a.focused = true;
                return a;
            }
        });
        if (error && error.length > 0) {
            setAttachments([...attachments]);
            return false;
        }
        return true;
    };
    const handleAddAttachments = () => {
        if (!validateAttachments()) {
            toast.error('Fill all the missing titles');
            return false;
        }
        const attachmentsResult = {
            [BLOCK_KEYS.ATTACHMENT_BLOCK]: {
                [BLOCK_TYPES.ATTACHMENTS]: attachments,
            },
            type: BLOCK_TYPES.ATTACHMENTS,
        };
        addAttachments(attachmentsResult);
        closeModal();
    };

    return (
        <div className="attachment-creation-modal">
            <h2>Add attachments</h2>
            {attachments.map((a, index) => {
                return (
                    <div
                        className="attachment-creation-modal-entry-container"
                        key={a.filename + index}
                    >
                        <div className="attachment-creation-modal-entry">
                            <MdAttachFile size={15} />
                            {a.filename}
                            <MdClose
                                size={15}
                                onClick={() => {
                                    setAttachments([
                                        ...attachments.filter(
                                            (att, position) =>
                                                index !== position,
                                        ),
                                    ]);
                                }}
                            />
                        </div>
                        <div className="attachment-creation-modal-entry-input">
                            <TextInput
                                placeholder="Attachment title"
                                value={a.title}
                                onChange={(v) => {
                                    a.title = v;
                                    setAttachments([...attachments]);
                                }}
                                error={{
                                    show: a.title === '' && a.focused,
                                    message: 'Missing title',
                                }}
                                onBlur={() => {
                                    a.focused = true;
                                    setAttachments([...attachments]);
                                }}
                            />
                        </div>
                    </div>
                );
            })}
            <div className="attachment-creation-modal-add-new-attachment">
                <BorderlessButton
                    text="+ Add new attachment"
                    onClick={() => {
                        setShowAttachmentModal(true);
                    }}
                />
            </div>
            {showAttachmentModal && (
                <UploadContentModal
                    titleContent="Choose an attachment to add"
                    typeContent={TypeContent.ATTACHMENT}
                    setUrl={(url, filename) => {
                        setAttachments([
                            ...attachments,
                            {
                                id: uuidv4(),
                                url,
                                filename,
                                title: '',
                                description: filename,
                            },
                        ]);
                    }}
                    closeModal={() => {
                        setShowAttachmentModal(false);
                    }}
                />
            )}
            <div className="attachment-creation-modal-buttons">
                <Button text="Cancel" type="cancel" onClick={closeModal} />
                <Button
                    text="Add"
                    type="confirm"
                    onClick={handleAddAttachments}
                />
            </div>
        </div>
    );
};
