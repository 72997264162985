import React from 'react';
import {SketchPicker} from 'react-color';
import cx from 'classnames';
import onClickOutside from 'react-onclickoutside';

import {color} from '@app/utils';

interface ColorpickerProps {
    input: any;
    meta: any;
    className: string;
    label?: string;
}

export class ColorpickerComponent extends React.Component<ColorpickerProps> {
    handleClickOutside() {
        const {
            input: {onBlur},
        } = this.props;
        onBlur();
    }

    handleChange = (color) => {
        const {
            input: {onChange},
        } = this.props;
        onChange(color.hex);
    };

    handleClickInput = () => {
        const {
            meta: {active},
            input: {onFocus, onBlur},
        } = this.props;
        if (active) {
            onBlur();
        } else {
            onFocus();
        }
    };

    render() {
        const {
            input,
            meta: {active},
            label,
        } = this.props;
        const {value, name} = input;
        const currentColor = value || '#03a9f4';
        const className = cx('kare-colorpicker', this.props.className);
        const textColor = color.getContrastingTextColor(currentColor);
        return (
            <div className={className}>
                {label && <label htmlFor={name}>{label}</label>}
                <input
                    readOnly={true}
                    value={currentColor}
                    style={{backgroundColor: currentColor, color: textColor}}
                    onClick={this.handleClickInput}
                    name={name}
                    id={name}
                    size={8}
                />
                {active && (
                    <SketchPicker
                        className="kare-colorpicker-picker"
                        color={currentColor}
                        onChange={this.handleChange}
                        disableAlpha={true}
                    />
                )}
            </div>
        );
    }
}

export const Colorpicker = onClickOutside(ColorpickerComponent);
