import React, {useEffect, useState} from 'react';
import {ResponsiveSunburst} from '@nivo/sunburst';
import {Language, Smartphone} from '@material-ui/icons';

import './TrafficSourcesChart.scss';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {transformTrafficSourceDataToSunburst} from '@app/utils/TimeSeriesDataTransform';
import {TrafficSourcesResponse} from '@app/types/TimeSeries';
import {DATA_KEYS, getChartColor} from '@app/utils/DataRenderingUtils';
import {RadialChartsBottomLegend} from '@app/components/RadialChartsBottomLegend/RadialChartsBottomLegend';
import {calcRatio} from '@app/utils/Common';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {RadialContentLoader} from '@app/components/ContentLoaders/RadialContentLoader';
import {dateSelector} from '@app/redux/application/selectors';
import {Moment} from 'moment';
import {redux} from '@app/redux';
import {connect} from 'react-redux';
import {GET_TRAFFIC_SOURCES_METRICS} from '@app/redux/metrics/constants';
import {
    getTrafficSourcesTimeseriesSelector,
    isTrafficSourcesTimeseriesFetchingSelector,
    trafficSourcesTimeseriesErrorSelector,
} from '@app/redux/metrics/selectors';

export interface TrafficSourcesChartProps {
    getTrafficSources: any;
    trafficSourcesSelector: any;
    trafficSourcesLoadingSelector: any;
    trafficSourcesErrorSelector: any;
    date: any;
}
const newDefaultInitialState = {
    data: {} as TrafficSourcesResponse,
    error: null,
    loading: false,
};

const TrafficSourcesChartComponent = (props: TrafficSourcesChartProps) => {
    const [trafficSources, setTrafficSources] = useState(
        newDefaultInitialState,
    );
    const {
        getTrafficSources,
        trafficSourcesErrorSelector,
        trafficSourcesLoadingSelector,
        trafficSourcesSelector,
        date,
    } = props;

    useEffect(() => {
        if (!trafficSourcesLoadingSelector && date?.minDate && date?.maxDate) {
            getTrafficSources(date.minDate, date.maxDate);
        }
    }, [date]);

    useEffect(() => {
        setTrafficSources({
            data: trafficSourcesSelector,
            error: trafficSourcesErrorSelector,
            loading: trafficSourcesLoadingSelector,
        });
    }, [
        trafficSourcesSelector,
        trafficSourcesLoadingSelector,
        trafficSourcesErrorSelector,
    ]);

    const transformedData = transformTrafficSourceDataToSunburst(
        trafficSources.data,
    );
    return (
        <div className="TrafficSourcesChart__container">
            <div className="TrafficSourcesChart__content">
                <CardTitle title={'Traffic sources'} />
                <ErrorLoadingWrapper
                    height={'291px'}
                    error={trafficSources.error}
                    isLoading={trafficSources.loading}
                    CustomLoading={RadialContentLoader}
                    emptyState={
                        !trafficSources.loading
                            ? !trafficSources.data.counters ||
                              (!trafficSources.data.counters.desktop &&
                                  !trafficSources.data.counters.mobile)
                                ? 'There is no data to show'
                                : null
                            : null
                    }
                    render={() => (
                        <>
                            <div className="TrafficSourcesChart__chartContainer">
                                <ResponsiveSunburst
                                    data={transformedData}
                                    margin={{
                                        top: 20,
                                        right: 20,
                                        bottom: 20,
                                        left: 20,
                                    }}
                                    id="name"
                                    value="loc"
                                    cornerRadius={2}
                                    borderWidth={1}
                                    borderColor="white"
                                    colors={(c: any) => c.data.color || '#000'}
                                    childColor={{from: 'color'}}
                                    animate
                                    motionConfig={{
                                        damping: 15,
                                        tension: 90,
                                    }}
                                    isInteractive
                                />
                            </div>
                            <RadialChartsBottomLegend
                                firstSource={{
                                    value: trafficSources.data.counters.desktop,
                                    ratio: calcRatio(
                                        trafficSources.data.counters.desktop,
                                        trafficSources.data.counters.desktop +
                                            trafficSources.data.counters.mobile,
                                    ),
                                    label: 'Desktop',
                                    color: getChartColor(DATA_KEYS.WINDOWS),
                                    icon: Language,
                                }}
                                secondSource={{
                                    value: trafficSources.data.counters.mobile,
                                    ratio: calcRatio(
                                        trafficSources.data.counters.mobile,
                                        trafficSources.data.counters.desktop +
                                            trafficSources.data.counters.mobile,
                                    ),
                                    label: 'Mobile',
                                    color: getChartColor(DATA_KEYS.ANDROID),
                                    icon: Smartphone,
                                }}
                            />
                        </>
                    )}
                />
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        trafficSourcesSelector: getTrafficSourcesTimeseriesSelector(state),
        trafficSourcesLoadingSelector:
            isTrafficSourcesTimeseriesFetchingSelector(state),
        trafficSourcesErrorSelector:
            trafficSourcesTimeseriesErrorSelector(state),
        date: dateSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTrafficSources: (minDate: Moment, maxDate: Moment) =>
            dispatch(
                redux.rest.actions[GET_TRAFFIC_SOURCES_METRICS].force({
                    minDate: minDate.unix(),
                    maxDate: maxDate.unix(),
                }),
            ),
    };
};

export const TrafficSourcesChart = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TrafficSourcesChartComponent as any);
