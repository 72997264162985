export const PLANS = {
    free: 'free',
    tier1: 'tier1',
    tier2: 'tier2',
    tier3: 'tier3',
};

export const SINGLE_LANGUAGE_PLANS = [PLANS.free, PLANS.tier1];
// The plans that the user can sign up for themselves, as opposed to having to contact us.
export const USER_SIGNUP_PLANS = [PLANS.free, PLANS.tier1];

export const planNames = {
    [PLANS.free]: 'Startup',
    [PLANS.tier1]: 'Small business',
    [PLANS.tier2]: 'Scale-up',
    [PLANS.tier3]: 'Enterprise',
};

export const QUERY_PARAMETERS = {
    PLAN: 'plan',
    UPGRADE: 'upgrade',
};

export const SUBSCRIPTIONS = [
    {
        name: planNames[PLANS.free],
        price: 'Free',
        key: PLANS.free,
        unlimitedSessions: true,
        unlimitedInsights: true,
        multiLanguage: false,
        numResponses: 15,
        externalKnowledge: '',
        activityLogs: '1 week',
        customerSupport: 'Online support',
        buttonText: 'Start free account',
    },
    {
        isMostPopular: true,
        name: planNames[PLANS.tier1],
        price: '£450 per month',
        showVAT: true,
        key: PLANS.tier1,
        unlimitedSessions: true,
        unlimitedInsights: true,
        multiLanguage: false,
        numResponses: 100,
        externalKnowledge: '50 items',
        activityLogs: '1 month',
        customerSupport: 'Online support',
        buttonText: 'Start free trial',
    },
    {
        name: planNames[PLANS.tier2],
        price: '£1500 per month',
        showVAT: true,
        key: PLANS.tier2,
        unlimitedSessions: true,
        unlimitedInsights: true,
        multiLanguage: true,
        numResponses: 200,
        externalKnowledge: '200 items',
        activityLogs: '2 months',
        customerSupport: '2 hours agent support',
        buttonText: 'Get in touch',
        needsContact: true,
    },
    {
        name: planNames[PLANS.tier3],
        price: 'Get a quote now',
        key: PLANS.tier3,
        unlimitedSessions: true,
        unlimitedInsights: true,
        multiLanguage: true,
        numResponses: 'Unlimited',
        externalKnowledge: 'Unlimited items',
        activityLogs: '24 months',
        customerSupport: 'Unlimited support',
        buttonText: 'Get in touch',
        needsContact: true,
    },
];
