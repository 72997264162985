import React from 'react';
import {ArrowDropDown} from '@material-ui/icons';
import Select, {components} from 'react-select';
import {get} from 'lodash';
import cx from 'classnames';

import './DropDown.scss';

export interface DropDownProps {
    className?: string;
    options: {[key: string]: string}[];
    value: any;
    onChange: (...args: any) => any;
    placeholder?: string;
    onMenuOpen?: (...args: any) => any;
}

const dropdownIndicator: React.FC = (props) => {
    const menuIsOpen = get(props, ['selectProps', 'menuIsOpen'], false);
    return (
        // @ts-ignore
        <components.DropdownIndicator {...props}>
            {/*@ts-ignore*/}
            <ArrowDropDown size={20} className={cx({rotate: menuIsOpen})} />
        </components.DropdownIndicator>
    );
};

export const DropDown: React.FC<DropDownProps> = (props) => {
    return (
        <Select
            className={cx(props.className, 'Dropdown__separator')}
            options={props.options}
            components={{DropdownIndicator: dropdownIndicator}}
            classNamePrefix={'kare-dropdown'}
            isSearchable={false}
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
            onMenuOpen={props.onMenuOpen}
            // menuIsOpen={true}
        />
    );
};
