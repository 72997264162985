import React from 'react';
import {Close} from '@material-ui/icons';

import './Modal.scss';

export interface ModalProps {
    children?: any;
    title: string;
    hasCloseIcon?: boolean;
    closeModal?: () => void;
}

export const Modal: React.FC<ModalProps> = ({
    children,
    title,
    hasCloseIcon,
    closeModal,
}) => {
    const handleClickClose = () => {
        closeModal();
    };

    const handleClickModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
    };

    const renderClose = () => {
        return (
            <div className="Modal__header">
                <h1 className="Modal__title">{title}</h1>
                {hasCloseIcon && <Close onClick={handleClickClose} />}
            </div>
        );
    };

    return (
        <div className="Modal__modal">
            <section onClick={handleClickModal} className="Modal__modalContent">
                {renderClose()}
                {children}
            </section>
        </div>
    );
};
