import React, {useState} from 'react';
import cx from 'classnames';

import './UploadContentModal.scss';
import {Uploader} from './Uploader';
import {UploadFromURL} from './UploadFromURL';
import {attachContent} from './Api';
import {Modal} from '@app/components/modals/generic/Modal';
import {Button} from '@app/components/inputs/buttons/Button';

export interface UploadContentModalProps {
    titleContent: string;
    typeContent: TypeContent;
    closeModal: () => void;
    setUrl: (url: String, filename: String) => void;
}

export enum TypeContent {
    DOCUMENT,
    VIDEO,
    PHOTO,
    ATTACHMENT,
    OTHER,
}

export const UploadContentModal: React.FC<UploadContentModalProps> = ({
    titleContent,
    typeContent,
    closeModal,
    setUrl,
}) => {
    const [activeTab, setActiveTab] = useState('upload');
    const [content, setContent] = useState<String | File>(null);
    const [filename, setFilename] = useState<String>('');
    const uploadClassname = cx('UploadContentModal__tab', {
        ['UploadContentModal__activeTab']: activeTab === 'upload',
    });
    const urlClassname = cx('UploadContentModal__tab', {
        ['UploadContentModal__activeTab']: activeTab === 'url',
    });
    const uploadContent = (media: String | File, filename: String) => {
        if (activeTab === 'url') {
            closeModal();
            setUrl(media.toString(), filename);
        } else {
            attachContent(typeContent, media, (url: string) => {
                closeModal();
                setUrl(url, filename);
            });
        }
    };
    return (
        <Modal title={titleContent} closeModal={closeModal} hasCloseIcon>
            <div className="UploadContentModal__container">
                <div className="UploadContentModal__tabs">
                    <div
                        className={uploadClassname}
                        onClick={() => {
                            setActiveTab('upload');
                            setContent(null);
                            setFilename('');
                        }}
                    >
                        Upload
                    </div>
                    <div
                        className={urlClassname}
                        onClick={() => {
                            setActiveTab('url');
                            setContent(null);
                            setFilename('');
                        }}
                    >
                        Attachment from URL
                    </div>
                </div>
                <div className="UploadContentModal__content">
                    {activeTab === 'upload' && (
                        <Uploader
                            typeContent={typeContent}
                            setContent={setContent}
                            setFilename={setFilename}
                        />
                    )}
                    {activeTab === 'url' && (
                        <UploadFromURL
                            setContent={setContent}
                            setFilename={setFilename}
                        />
                    )}
                </div>
                <div className="UploadContentModal__buttons">
                    <Button text="CANCEL" type="cancel" onClick={closeModal} />
                    <Button
                        text="ADD"
                        type="confirm"
                        onClick={() => {
                            uploadContent(content, filename);
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};
