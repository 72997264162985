import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import cx from 'classnames';
import {get, pickBy, replace, startsWith, transform} from 'lodash';

import {redux} from '@app/redux';
import {MdDesktopWindows, MdSmartphone} from 'react-icons/md';
import {GET_CONVERSATION_MESSAGES} from '@app/redux/conversations/constants';
import {ErrorBoundary, KareLabel, Spinner} from '@app/common';
import {MessageFactory} from './message-factory';

import {getSelectedConversationMetaData} from '@app/redux/conversations/selectors';
import uaParser from 'ua-parser-js';
// @ts-ignore
import locale from 'locale-string';

interface MessagesProps {
    getConversation: any;
    selectedConversation: string;
    metaData: any;
    messages: any[];
    conversations: any[];
    isConversationLoading: boolean;
    isConversationError: boolean;
    requiredPermissions: any[];
    scopes: any[];
    userId: string;
    conversationStatus: string;
}

export class MessagesComponent extends React.Component<MessagesProps> {
    state = {
        showActivityInfoPanel: false,
    };

    handleShowActivityInfoPanel = () => {
        this.setState({
            showActivityInfoPanel: !this.state.showActivityInfoPanel,
        });
    };

    componentDidUpdate(prevProps) {
        if (
            prevProps.selectedConversation !== this.props.selectedConversation
        ) {
            this.props.getConversation(this.props.selectedConversation);
        }
    }

    renderMessages = () => {
        const {messages, scopes, requiredPermissions} = this.props;

        return messages.map((message) => {
            return (
                <MessageFactory
                    key={message.id}
                    message={message}
                    messages={messages}
                    scopes={scopes}
                    requiredPermissions={requiredPermissions}
                />
            );
        });
    };

    renderSpinner = () => {
        return (
            <div className="console-activity-messages__spinner">
                <Spinner />
            </div>
        );
    };

    renderError = () => {
        return (
            <div className="console-activity-messages__error">
                There is an error loading the conversation{' '}
                {this.props.selectedConversation}
            </div>
        );
    };

    renderMetaTags = () => {
        const {metaData} = this.props;
        const customHeaders = pickBy(metaData, (value, key) =>
            startsWith(key, 'kare'),
        );

        const grouped = transform(
            customHeaders,
            (result, value, key) => {
                if (!result[result.length - 1]) {
                    result.push([]);
                }
                if (result[result.length - 1].length === 2) {
                    result.push([]);
                }
                result[result.length - 1].push({
                    key,
                    value,
                });
            },
            [],
        );
        return (
            <Fragment>
                {grouped.map((group, index) => {
                    return (
                        <div
                            key={index}
                            className="console-activity-messages__info__content-multiple"
                        >
                            {group.map((header) => {
                                const {key, value} = header;
                                return (
                                    <div key={key}>
                                        <strong>
                                            {replace(
                                                key,
                                                /kare_meta_|kare-meta-|kare_mind_|kare-mind-/,
                                                '',
                                            )}
                                        </strong>
                                        <span>{value}</span>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </Fragment>
        );
    };

    render() {
        const {
            metaData,
            selectedConversation,
            userId,
            conversationStatus,
            conversations,
        } = this.props;

        const headerUserAgent = get(
            metaData,
            'User-Agent',
            get(metaData, 'http_user_agent', ''),
        );

        let userAgent = '';
        let userLocale = '';
        if (headerUserAgent) {
            userAgent = uaParser(headerUserAgent);
            userLocale = locale.parse(headerUserAgent);
        }

        const className = cx('console-activity-messages', {
            ['show-panel']: this.state.showActivityInfoPanel,
        });
        const sConv =
            conversations?.length > 0
                ? conversations.filter((c) => c.id === selectedConversation)
                : null;
        let labels = [];
        if (sConv && sConv.length > 0) {
            labels = sConv[0].labels;
        }

        let platform = 'desktop';
        if (userAgent) {
            const os = get(userAgent, ['os', 'name'], '').toLowerCase();
            if (
                os.includes('linux') ||
                os.includes('windows') ||
                os.includes('mac')
            ) {
                platform = 'desktop';
            } else if (os) {
                platform = 'mobile';
            }
        }

        const userClassname = cx({
            'console-activity-messages__info__header-user-visitor': !userId,
        });
        const selectedConversationObject = conversations.filter(
            (c) => c.id === selectedConversation,
        );
        let referral = null;
        if (
            selectedConversationObject &&
            selectedConversationObject.length === 1
        ) {
            referral = get(
                selectedConversationObject,
                ['0', 'referrals'],
                [],
            ).join(', ');
        }

        return (
            <ErrorBoundary>
                <section className={className}>
                    {this.props.isConversationError ? (
                        this.renderError()
                    ) : this.props.isConversationLoading ? (
                        this.renderSpinner()
                    ) : (
                        <Fragment>
                            <div className="console-activity-messages__info">
                                <div
                                    className="console-activity-messages__info__header"
                                    onClick={this.handleShowActivityInfoPanel}
                                >
                                    <span className={userClassname}>
                                        {platform === 'desktop' ? (
                                            <MdDesktopWindows size={15} />
                                        ) : (
                                            <MdSmartphone size={15} />
                                        )}
                                        {userId && <strong>{userId}</strong>}
                                        {!userId && 'Visitor'}
                                    </span>
                                    <span className="console-activity-messages__info__header-detail-text">
                                        Detail view
                                    </span>
                                </div>
                                <div className="console-activity-messages__info__content">
                                    <div className="console-activity-messages__info__content-single">
                                        <strong>Conversation Id</strong>
                                        <span>{selectedConversation}</span>
                                    </div>
                                    {referral && (
                                        <div className="console-activity-messages__info__content-single">
                                            <strong>Traffic</strong>
                                            <span>{referral}</span>
                                        </div>
                                    )}
                                    {userAgent && (
                                        <div className="console-activity-messages__info__content-multiple">
                                            <div>
                                                <strong>Os and version</strong>
                                                <span>
                                                    {get(userAgent, [
                                                        'os',
                                                        'name',
                                                    ])}
                                                </span>
                                            </div>
                                            <div>
                                                <strong>
                                                    Browser and version
                                                </strong>
                                                <span>
                                                    {get(
                                                        userAgent,
                                                        'browser.name',
                                                    )}{' '}
                                                    -{' '}
                                                    {get(
                                                        userAgent,
                                                        'browser.major',
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="console-activity-messages__info__content-multiple">
                                        {userAgent && (
                                            <div>
                                                <strong>Language</strong>
                                                <span>
                                                    {get(
                                                        userLocale,
                                                        'language',
                                                        '',
                                                    )}{' '}
                                                    -{' '}
                                                    {get(
                                                        userLocale,
                                                        'country',
                                                        '',
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                        <div>
                                            <strong>Status</strong>
                                            {conversationStatus && (
                                                <span
                                                    className={cx(
                                                        'console-activity-messages-status',
                                                        {
                                                            'console-activity-messages-status__escalated':
                                                                conversationStatus.toLowerCase() ===
                                                                'escalated',
                                                            'console-activity-messages-status__handedover':
                                                                conversationStatus.toLowerCase() ===
                                                                'handed-over',
                                                            'console-activity-messages-status__resolved':
                                                                conversationStatus.toLowerCase() ===
                                                                'resolved',
                                                            'console-activity-messages-status__unresolved':
                                                                conversationStatus.toLowerCase() ===
                                                                'unresolved',
                                                        },
                                                    )}
                                                >
                                                    {conversationStatus}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    {this.renderMetaTags()}
                                    <div className="console-activity-messages__info-labels">
                                        <strong
                                            style={{
                                                display: 'block',
                                                marginBottom: '-5px',
                                            }}
                                        >
                                            Labels
                                        </strong>{' '}
                                        <br />{' '}
                                        {labels.map((l) => (
                                            <KareLabel
                                                key={`info-${selectedConversation}-${l}`}
                                                text={l}
                                                showRemoveButton={false}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="console-activity-messages__container">
                                {this.renderMessages()}
                            </div>
                        </Fragment>
                    )}
                    <div className="console-activity-messages-scroll-to-bottom" />
                </section>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => ({
    conversations: redux.conversations.selectors.conversationsSelector(state),
    messages: redux.conversations.selectors.conversationMessagesSelector(state),
    metaData: getSelectedConversationMetaData(state),
    selectedConversation:
        redux.conversations.selectors.getSelectedConversationId(state),
    userId: redux.conversations.selectors.getSelectedConversationUserId(state),
    conversationStatus:
        redux.conversations.selectors.getSelectedConversationStatus(state),
    isConversationLoading:
        redux.conversations.selectors.isConversationMessagesFetchingSelector(
            state,
        ),
    isConversationError:
        redux.conversations.selectors.isConversationMessagesErrorSelector(
            state,
        ),
});

export const getConversationMessagesDispatcher = (dispatch) => (id) => {
    dispatch(redux.rest.actions[GET_CONVERSATION_MESSAGES].force({id}));
};

const mapDispatchToProps = (dispatch) => ({
    getConversation: getConversationMessagesDispatcher(dispatch),
});

export const Messages = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MessagesComponent),
);
