import React from 'react';
import {Feedback} from './feedback';

interface QueryProps {
    query?: any;
}

export class Query extends React.Component<QueryProps> {
    render() {
        const {query} = this.props;
        return (
            <div className="dashboard-response-query">
                <div className="dashboard-response-query-text">
                    {query.query}
                    <Feedback feedback={query.feedback} />
                </div>
            </div>
        );
    }
}
