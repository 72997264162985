import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import axios from 'axios';

import {redux} from '@app/redux';
import {
    Spinner,
    Markdown,
    ErrorBoundary,
    Button,
    MarkdownEditor,
} from '@app/common';
import {get} from 'lodash';
import {OpenDocument} from '@app/common/icons';
import moment from 'moment';
import {DocumentMetadataEditor} from '@app/common/document-metadata-editor';
import {FIELD_MASKS, LABELS} from '@app/constants';
import {AUTHORIZATION} from '@app/redux/constants';

interface DocumentDetailProps {
    document: any;
    body: string;
    isEditingDocument: boolean;
    isDocumentFetching?: boolean; // Is the document being fetched from the server, i.e loading.
    isDocumentErroring?: boolean;
    patchDocumentLabels: any;
}

/**
 * Document Detail Component.
 * This is used when editing a response and relating documents to it.
 */
export class DocumentDetailComponent extends React.Component<
    DocumentDetailProps,
    any
> {
    constructor(props) {
        super(props);

        this.state = {
            documentBody: '',
        };
        this.onMarkdownEditorChange = this.onMarkdownEditorChange.bind(this);
    }

    componentDidUpdate() {
        if (!this.state.documentBody && this.props.body) {
            this.setState({
                documentBody: this.props.body,
            });
        }
    }

    getDocumentContentLink(document): string {
        if (axios && axios.defaults && document) {
            const {headers, baseURL} = axios.defaults;
            return `${baseURL}/v3.0/kbm/nodes/${
                document.id
            }/content/public?token=${headers[AUTHORIZATION].slice(7)}`;
        }
        return '';
    }

    getHostNameFromUrl(url: string): string {
        try {
            return new URL(url).hostname;
        } catch (e) {
            return '';
        }
    }

    isDocumentUploaded = (document) => {
        return (
            document?.type === 'content' &&
            document?.content?.source === 'uploads'
        );
    };

    renderContent = () => {
        const {
            document,
            isDocumentFetching,
            isDocumentErroring,
            isEditingDocument,
            patchDocumentLabels,
        } = this.props;

        if (isDocumentErroring) {
            return (
                <>
                    <h1 className="knowledge-base__document-detail-title">
                        Document not found
                    </h1>
                    <p>
                        Try searching all of your documents{' '}
                        <Link to="/library">here</Link>.
                    </p>
                </>
            );
        }

        if (isDocumentFetching) {
            return <Spinner />;
        }

        const url = get(document, ['content', 'url'], '');
        const {labels = []} = document;

        return (
            <>
                <div className="knowledge-base__doc-detail">
                    <div>
                        <h1 className="knowledge-base__document-detail-title">
                            {document.title}
                        </h1>
                        {url && (
                            <a
                                className="knowledge-base__document-detail-link"
                                href={url}
                            >
                                <span>{url}</span>
                                <OpenDocument size={16} />
                            </a>
                        )}
                        <div>
                            {document.created_at && (
                                <span className="knowledge-base__metadata-text">
                                    Upload date{' '}
                                    <b>
                                        {moment
                                            .unix(document.created_at)
                                            .format('DD/MM/YYYY HH:mm')}
                                    </b>
                                </span>
                            )}
                            {url && (
                                <span className="knowledge-base__metadata-text">
                                    Source <b>{this.getHostNameFromUrl(url)}</b>
                                </span>
                            )}
                            {document.status && (
                                <span className="knowledge-base__metadata-text">
                                    Status{' '}
                                    <b className="knowledge-base__status-text">
                                        {document.status}
                                    </b>
                                </span>
                            )}
                        </div>
                    </div>

                    {!url && (
                        <a
                            href={this.getDocumentContentLink(document)}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button outlined>Download file</Button>
                        </a>
                    )}
                </div>

                <DocumentMetadataEditor
                    showLabelEditIcon={this.isDocumentUploaded(document)}
                    documentLabels={labels}
                    onSaveDocumentLabels={(labels) =>
                        patchDocumentLabels(document.id, labels)
                    }
                />
                {isEditingDocument && (
                    <MarkdownEditor
                        previousValue={this.state.documentBody}
                        isEditingDocument={true}
                        onChange={this.onMarkdownEditorChange}
                    />
                )}
                {!isEditingDocument && (
                    <Markdown>{this.state.documentBody}</Markdown>
                )}
            </>
        );
    };

    onMarkdownEditorChange(arg) {
        const {text} = arg?.text_block;
        this.setState({
            documentBody: text,
        });
    }

    render() {
        return (
            <ErrorBoundary>
                <article className="knowledge-base__document-detail">
                    {this.renderContent()}
                </article>
            </ErrorBoundary>
        );
    }
}

/**
 * Map redux store state to props for the component.
 * @param {Object} state
 */
const mapStateToProps = (state) => {
    return {
        isDocumentFetching:
            redux.documents.selectors.isDocumentFetchingSelector(state),
        isDocumentErroring:
            redux.documents.selectors.isDocumentErroringSelector(state),
    };
};

const patchDocument = (dispatch, docId, content) => {
    return dispatch(
        redux.rest.actions[redux.documents.constants.PATCH_DOCUMENT](
            {
                id: docId,
            },
            {
                body: JSON.stringify(content),
            },
        ),
    );
};

/**
 * Map action dispatching to component props.
 * Search responses, get all responses and delete a single response.
 * @param {Function} dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return {
        patchDocumentLabels: (id, labels) => {
            const content = {
                [FIELD_MASKS]: [LABELS],
                labels,
            };

            return patchDocument(dispatch, id, content);
        },
    };
};

export const DocumentDetail = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DocumentDetailComponent);
