import qs from 'qs';

export function serializeQs(obj) {
    return Object.keys(obj)
        .map((k) => `${k}=${encodeURIComponent(obj[k])}`)
        .join('&');
}

// TODO:: This module was intentionally left behind so it wouldn't
// break any code that is currently using this function.
// A next piece of work around this should seek to remove this file completely.
export function deserializeQs(str, delim = '?') {
    const a = str[0] === delim ? str.substr(1) : str;
    return qs.parse(a);
}

export const getLoginRedirectUrl = (pathname, search) => {
    const redirect = `${pathname}${search}`;
    return redirect === '/' ? '/login' : `/login?redirect=${redirect}`;
};
