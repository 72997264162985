import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import {draftToMarkdown, markdownToDraft} from 'markdown-draft-js';

export const editorStateToMd = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const rawData = convertToRaw(contentState);
    return draftToMarkdown(rawData);
};

export const mdToEditorState = (md) => {
    const rawData = markdownToDraft(md);
    const contentState = convertFromRaw(rawData);
    return EditorState.createWithContent(contentState);
};

export const countStrippedMarkdownCharacters = (markdown) => {
    const regex = /(?:__|[*#])|\[(.*?)]\(.*?\)/gm;
    let strippedCharacters = 0;
    const matches = markdown.match(regex);
    if (matches) {
        matches.forEach((match) => {
            strippedCharacters += match.length;
        });
    }

    return markdown.length - strippedCharacters;
};
