import Axios, {AxiosResponse} from 'axios';

export interface Domain {
    host: string;
    hasRobots: boolean;
    ignoreLocale: boolean;
    forceLocale: boolean;
    forceCrawl: boolean;
    ignoreMeta: boolean;
    onlyCrawlStartingPages: boolean;
    hasCustomAgent: boolean;
    labels: string[];
    startingPages: string[];
    customAgent: string;
    robots: string;
    forcedLocales: string; // NOTE(msama): in the UI is a comma separated string. The APIs expect an array.
}

export interface Response {
    data: any | undefined;
    err: any | undefined;
}

export const getDomains = async (): Promise<Response> => {
    try {
        const result: AxiosResponse<any> = await Axios.get(
            '/v3.1/ingestion/webcrawler/domains',
        );
        return <Response>{data: result.data};
    } catch (err) {
        return <Response>{err: err.response};
    }
};

export const removeDomain = async (host: string): Promise<Response> => {
    try {
        const result: AxiosResponse<any> = await Axios.delete(
            '/v3.1/ingestion/webcrawler/domains?host=' + host,
        );
        return <Response>{data: result.data};
    } catch (err) {
        return <Response>{err: err.response};
    }
};

export const getDomain = async (host: string): Promise<Response> => {
    try {
        const result: AxiosResponse<any> = await Axios.get(
            '/v3.1/ingestion/webcrawler/domains?host=' + host,
        );
        return <Response>{data: result.data};
    } catch (err) {
        return <Response>{err: err.response};
    }
};

export const putWebcrawlerDomain = async (d: Domain) => {
    const flags: string[] = [];
    if (d.host.endsWith('/')) {
        d.host = d.host.substr(0, d.host.length - 1);
    }
    if (d.hasRobots) {
        flags.push('use_additional_robots');
    }
    if (d.ignoreLocale) {
        flags.push('ignore_locale');
    }
    if (d.forceLocale) {
        flags.push('force_locale');
    }
    if (d.forceCrawl) {
        flags.push('force_crawl');
    }
    if (d.ignoreMeta) {
        flags.push('ignore_meta_tags');
    }
    if (d.onlyCrawlStartingPages) {
        flags.push('only_given_pages');
    }
    if (d.hasCustomAgent) {
        flags.push('use_custom_agent');
    }

    try {
        const res: AxiosResponse<any> = await Axios.put(
            `/v3.1/ingestion/webcrawler/domains?host=${d.host}`,
            {
                domain: {
                    host: d.host,
                    labels: d.labels,
                    starting_pages: d.startingPages.filter(
                        (s) => s !== '' && s !== null && s !== undefined,
                    ),
                    flags: flags,
                    custom_agent: d.hasCustomAgent ? d.customAgent : '',
                    additional_robots: d.hasRobots ? d.robots : '',
                    forced_locales: d.forceLocale
                        ? d.forcedLocales.split(',').map((l) => {
                              return l.trim();
                          })
                        : [],
                },
            },
        );
        return {data: res.data, err: null};
    } catch (err) {
        return {data: null, err: err.response};
    }
};
