import React from 'react';
import Icon from 'react-icon-base';

export const Response = (props) => (
    <Icon viewBox="0 0 15 15" size={12} {...props}>
        <g id="Symbols" stroke="none" strokeWidth="1">
            <g
                id="icon/responses/response/k"
                transform="translate(-15.000000, -16.000000)"
                fill="currentColor"
            >
                <path
                    d="M28.5,16 C29.32875,16 30,16.67125 30,17.5 L30,26.5 C30,27.32875 29.32875,28 28.5,28 L18,28 L15,31 L15,17.5 C15,16.67125 15.67125,16 16.5,16 L28.5,16 Z M20.75,25.81125 L27.5,19.06125 L26.43875,18 L20.75,23.68875 L18.06125,21 L17,22.06125 L20.75,25.81125 Z"
                    id="Combined-Shape"
                />
            </g>
        </g>
    </Icon>
);
