import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';

import {Card} from '@app/components/Card/Card';
import {ActivityBarChart} from './ActivityBarChart/ActivityBarChart';
import {QueriesBarChart} from './QueriesBarChart/QueriesBarChart';
import {TrafficSourcesChart} from './TrafficSourcesChart/TrafficSourcesChart';
import {CustomerTypeChart} from './CustomerTypeChart/CustomerTypeChart';
import {BounceRateChart} from './BounceRateChart/BounceRateChart';
import {HandledChart} from './HandledChart/HandledChart';
import {FeedbackChart} from './FeedbackChart/FeedbackChart';
import {OpenRateChart} from './OpenRateChart/OpenRateChart';
import {connect} from 'react-redux';
import {GET_AGGREGATED_CONVERSATIONS_TIMESERIES} from '@app/redux/conversations/constants';
import {redux} from '@app/redux';
import {Moment} from 'moment';
import {dateSelector} from '@app/redux/application/selectors';
import {
    aggregatedConversationsTimeseriesErrorSelector,
    getAggregatedConversationsTimeseriesSelector,
    isAggregatedConversationsTimeseriesFetchingSelector,
} from '@app/redux/conversations/selectors';
import {
    aggregatedQueriesTimeseriesErrorSelector,
    getAggregatedQueriesTimeseriesSelector,
    isAggregatedQueriesTimeseriesFetchingSelector,
} from '@app/redux/queries/selectors';
import {GET_AGGREGATED_QUERIES_TIMESERIES} from '@app/redux/queries/constants';

interface IActivityProps {
    getConversations: any;
    getQueries: any;
    date: {
        minDate?: Moment;
        maxDate?: Moment;
    };
    conversationsSelector: any;
    conversationsLoadingSelector: any;
    conversationsErrorSelector: any;
    queriesSelector: any;
    queriesLoadingSelector: any;
    queriesErrorSelector: any;
}

export interface ChartDataTypes {
    data: {queries: any; conversations: any};
    loading: boolean;
    error: string;
}

const initialDataState: ChartDataTypes = {
    data: {queries: {}, conversations: {}},
    loading: false,
    error: '',
};

const ActivityComponent = (props: IActivityProps) => {
    const [data, setData] = useState<ChartDataTypes>(initialDataState);
    const {
        getConversations,
        getQueries,
        date,
        conversationsSelector,
        conversationsErrorSelector,
        conversationsLoadingSelector,
        queriesSelector,
        queriesErrorSelector,
        queriesLoadingSelector,
    } = props;

    useEffect(() => {
        if (!conversationsLoadingSelector && date?.minDate && date?.maxDate) {
            getConversations(date.minDate, date.maxDate);
        }
        if (!queriesLoadingSelector && date?.minDate && date?.maxDate) {
            getQueries(date.minDate, date.maxDate);
        }
    }, [date]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted)
            setData({
                loading: conversationsLoadingSelector || queriesLoadingSelector,
                error:
                    conversationsErrorSelector || queriesErrorSelector
                        ? 'Error'
                        : '',
                data: {
                    queries: queriesSelector,
                    conversations: conversationsSelector,
                },
            });
        return () => {
            isMounted = false;
        };
    }, [
        conversationsSelector,
        conversationsLoadingSelector,
        conversationsErrorSelector,
        queriesSelector,
        queriesLoadingSelector,
        queriesErrorSelector,
    ]);
    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Card>
                    <HandledChart
                        heroTitle={'Successfully handled'}
                        dataToShow={'handled'}
                        data={data}
                    />
                </Card>
            </Grid>
            <Grid item xs={4}>
                <Card>
                    <HandledChart
                        heroTitle={'Successfully deflected'}
                        dataToShow={'deflected'}
                        data={data}
                    />
                </Card>
            </Grid>
            <Grid item xs={4}>
                <Card>
                    <HandledChart
                        heroTitle={'Successfully automated'}
                        dataToShow={'automated'}
                        data={data}
                    />
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <ActivityBarChart />
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card>
                    <OpenRateChart
                        legendPosition="right"
                        conversationsData={data}
                    />
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card>
                    <BounceRateChart
                        legendPosition="right"
                        conversationsData={data}
                    />
                </Card>
            </Grid>
            <Grid item xs={4}>
                <Card>
                    <CustomerTypeChart />
                </Card>
            </Grid>
            <Grid item xs={4}>
                <Card>
                    <FeedbackChart />
                </Card>
            </Grid>
            <Grid item xs={4}>
                <Card>
                    <TrafficSourcesChart />
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <QueriesBarChart />
                </Card>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        conversationsSelector:
            getAggregatedConversationsTimeseriesSelector(state),
        conversationsLoadingSelector:
            isAggregatedConversationsTimeseriesFetchingSelector(state),
        conversationsErrorSelector:
            aggregatedConversationsTimeseriesErrorSelector(state),
        queriesSelector: getAggregatedQueriesTimeseriesSelector(state),
        queriesLoadingSelector:
            isAggregatedQueriesTimeseriesFetchingSelector(state),
        queriesErrorSelector: aggregatedQueriesTimeseriesErrorSelector(state),
        date: dateSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getConversations: (minDate: Moment, maxDate: Moment) =>
            dispatch(
                redux.rest.actions[
                    GET_AGGREGATED_CONVERSATIONS_TIMESERIES
                ].force({
                    minDate: minDate.unix(),
                    maxDate: maxDate.unix(),
                }),
            ),
        getQueries: (minDate: Moment, maxDate: Moment) =>
            dispatch(
                redux.rest.actions[GET_AGGREGATED_QUERIES_TIMESERIES].force({
                    minDate: minDate.unix(),
                    maxDate: maxDate.unix(),
                }),
            ),
    };
};

export const Activity = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ActivityComponent as any);
