import React, {Component} from 'react';
import {Pie, PieChart, Cell} from 'recharts';
import {PIE_CHART_CONFIG, PIE_CHART_SIZE} from './constants';
import {getResolvedRatio, mapDataToPieChartModel} from './utils';
import {Help, Sms, Response} from '@app/common/icons';

interface DashboardActivityQueriesSummaryProps {
    conversations?: number;
    resolved?: number;
    escalated?: number;
    bounced?: number;
    deflected?: number;
    queries?: any;
    responses?: any;
    missing?: any;
    disambiguations?: any;
}

export class DashboardActivityQueriesSummary extends Component<DashboardActivityQueriesSummaryProps> {
    render() {
        const {queries, responses, missing, disambiguations} = this.props;
        const resolvedRatio = getResolvedRatio(
            responses + disambiguations,
            queries,
        );
        const pieChartData = mapDataToPieChartModel(
            responses,
            disambiguations,
            missing,
        );
        return (
            <div className="dashboard-activity-queries-summary">
                {queries > 0 && (
                    <PieChart
                        width={PIE_CHART_SIZE.width}
                        height={PIE_CHART_SIZE.height}
                        className="dashboard-activity-queries-summary__pie-chart"
                    >
                        <text
                            x={PIE_CHART_SIZE.width / 2}
                            y={PIE_CHART_SIZE.height / 2 + 2}
                            textAnchor="middle"
                            className="dashboard-activity-queries-summary__resolved-ratio"
                        >
                            {resolvedRatio}%
                        </text>
                        <text
                            x={PIE_CHART_SIZE.width / 2}
                            y={PIE_CHART_SIZE.height / 2 + 17}
                            className="dashboard-activity-queries-summary__resolved-ratio-description"
                            textAnchor="middle"
                        >
                            HANDLED
                        </text>
                        <Pie data={pieChartData} {...PIE_CHART_CONFIG}>
                            {pieChartData.map((entry) => (
                                <Cell
                                    className={`dashboard-activity-queries-summary__cell ${entry.name}`}
                                    key={`cell-${entry.name}`}
                                />
                            ))}
                        </Pie>
                    </PieChart>
                )}
                <div className="dashboard-activity-queries-summary__fields">
                    <h2>
                        <span>Queries</span>
                        <span>{queries}</span>
                    </h2>
                    <p className="dashboard-activity-queries-summary__field responses">
                        <Response size={16} />
                        <span>Answered</span>
                        <span>{responses}</span>
                    </p>
                    <p className="dashboard-activity-queries-summary__field disambiguations">
                        <Sms size={18} />
                        <span>Choice</span>
                        <span>{disambiguations}</span>
                    </p>
                    <p className="dashboard-activity-queries-summary__field missing">
                        <Help size={19} />
                        <span>Missing</span>
                        <span>{missing}</span>
                    </p>
                </div>
            </div>
        );
    }
}
