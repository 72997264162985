import React from 'react';

import './Empty.scss';

interface IEmptyProps {
    header: string;
    subheader: string;
    icon: any;
}

export const Empty: React.FunctionComponent<IEmptyProps> = ({
    header,
    subheader,
    icon,
}) => {
    return (
        <div className="Empty__container">
            {icon()}
            <h2>{header}</h2>
            <h3>{subheader}</h3>
        </div>
    );
};
