import React from 'react';
import {connect} from 'react-redux';
import {CollapsedResponses, ThumbDown, ThumbUp} from '@app/common/icons';
import {ErrorBoundary} from '@app/common';
import {transformFeedbackMetrics} from '../utils';
import {getFeedbackMetrics} from '@app/redux/metrics/selectors';

interface QueriesProps {
    feedbackMetrics?: any;
}

export class QueriesComponent extends React.Component<QueriesProps> {
    render() {
        const feedbackTotals = transformFeedbackMetrics(
            this.props.feedbackMetrics,
        );

        return feedbackTotals ? (
            <ErrorBoundary>
                <div className="dashboard-queries__container">
                    <div className="dashboard-queries__counters">
                        <div className="dashboard-queries__responses">
                            <div className="dashboard-queries__counter">
                                <div className="dashboard-queries__icon">
                                    <CollapsedResponses />
                                </div>
                                <div className="dashboard-queries__values">
                                    <strong>
                                        {feedbackTotals.totalQueries}
                                    </strong>
                                    <span>All queries</span>
                                </div>
                            </div>
                            <div className="dashboard-queries__counter">
                                <div className="dashboard-queries__icon">
                                    <ThumbUp />
                                </div>
                                <div className="dashboard-queries__values">
                                    <strong>
                                        {feedbackTotals.totalHelpful}
                                    </strong>
                                    <span>Good</span>
                                </div>
                            </div>
                            <div className="dashboard-queries__counter">
                                <div className="dashboard-queries__icon">
                                    <ThumbDown />
                                </div>
                                <div className="dashboard-queries__values">
                                    <strong>
                                        {feedbackTotals.totalUnhelpful}
                                    </strong>
                                    <span>Unhelpful</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        ) : null;
    }
}

const mapStateToProps = (state) => {
    return {
        feedbackMetrics: getFeedbackMetrics(state),
    };
};

export const Queries = connect(mapStateToProps)(QueriesComponent);
