export const CREATE_RESPONSE = 'createResponse';
export const UPDATE_RESPONSE = 'updateResponse';
export const BULK_UPDATE_RESPONSES = 'BULK_UPDATE_RESPONSES';
export const GET_PUBLISHED_CHATTER_RESPONSES =
    'GET_PUBLISHED_CHATTER_RESPONSES';
export const GET_PUBLISHED_RESPONSES = 'GET_PUBLISHED_RESPONSES';
export const GET_ARCHIVED_RESPONSES = 'GET_ARCHIVED_RESPONSES';
export const GET_DRAFT_RESPONSES = 'GET_DRAFT_RESPONSES';
export const GET_RESPONSE = 'getResponse';
export const GET_OUTBOUND_NODES = 'GET_OUTBOUND_NODES';
export const GET_INBOUND_NODES = 'GET_INBOUND_NODES';
export const GET_IMPROVE_PAGE_RESPONSE = 'GET_IMPROVE_PAGE_RESPONSE';
export const GET_RESPONSE_CLUSTER = 'GET_RESPONSE_CLUSTER';
export const GET_IMPROVE_PAGE_OUTBOUND_NODES =
    'GET_IMPROVE_PAGE_OUTBOUND_NODES';
