import React from 'react';
import {Switch} from 'react-router';
import {EmptyLayout} from '@app/common/layouts';
import Connect from './connect';

interface ConnectIntegrationProps {
    match: any;
}

export class ConnectIntegration extends React.Component<ConnectIntegrationProps> {
    getPath(path) {
        return `${this.props.match.path}${path}`;
    }

    render() {
        return (
            <Switch>
                <EmptyLayout
                    exact
                    path={this.getPath('/')}
                    component={Connect}
                />
            </Switch>
        );
    }
}
