import React from 'react';

export class CustomLauncher extends React.Component {
    render() {
        return (
            <div className="launcher-preview__custom">
                <p>
                    Add the following id in your html button:{' '}
                    <span>
                        <strong>{'karehq-widget-launcher-button'}</strong>
                    </span>
                </p>
            </div>
        );
    }
}
