import React from 'react';
// @ts-ignore
import './ActionListModal.scss';
import {ActionListSelector} from '../selector/ActionListSelector';

interface ActionListModalProps {
    actions: any[];
    onSelectedAction?: (action: any) => void;
    onClear: () => void;
}

export const ActionListModal: React.FunctionComponent<ActionListModalProps> = (
    props,
) => {
    return (
        <div className="ActionListModal__container">
            <h1 className="ActionListModal__title">
                Choose an RPA for escalation
            </h1>
            <ActionListSelector
                actions={props.actions}
                onSelectedAction={props.onSelectedAction}
                onClear={props.onClear}
            />
        </div>
    );
};
