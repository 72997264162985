import React from 'react';
import {FEEDBACK} from '../../constants';
import {ThumbUp, ThumbDown} from '@app/common/icons';

interface FeedbackProps {
    feedback: string;
}

export class Feedback extends React.Component<FeedbackProps> {
    render() {
        const {feedback} = this.props;
        if (feedback === FEEDBACK.POSITIVE) {
            return (
                <span className="dashboard-response-query-feedback dashboard-response-query-feedback--positive">
                    <ThumbUp />
                </span>
            );
        }
        if (feedback === FEEDBACK.NEGATIVE) {
            return (
                <span className="dashboard-response-query-feedback dashboard-response-query-feedback--negative">
                    <ThumbDown />
                </span>
            );
        }
        return null;
    }
}
