import {rest} from './rest';
import * as selectors from './selectors';
import * as dispatchers from './dispatchers';
import * as constants from './constants';

/**
 * Public API for the responses redux module.
 */
export const responses = {
    rest,
    selectors,
    constants,
    dispatchers,
};
