const notificationDefaults = {
    position: 'br',
    dismissAfter: 3000,
    closeButton: true,
};

const textCopiedToClipboard = (title) => {
    return {
        ...notificationDefaults,
        title: 'Text copied',
        message: `${title} has been copied`,
        status: 'success',
    };
};

const responseCreated = (response) => {
    return {
        ...notificationDefaults,
        title: 'Response added',
        message: `"${response.title}" added to knowledge base`,
        status: 'success',
    };
};

const responseUpdated = (response) => {
    return {
        ...notificationDefaults,
        title: 'Response updated',
        message: response.title,
        status: 'success',
    };
};

const responseDeleted = () => {
    return {
        ...notificationDefaults,
        title: 'Response removed',
        message: `Response removed from the knowledge base`,
        status: 'success',
    };
};

// Organization setting notifications

const orgUpdated = () =>
    Object.assign({}, notificationDefaults, {
        title: 'Company Details Updated',
        message: 'Your changes have been saved',
        status: 'success',
    });

const passwordUpdated = () =>
    Object.assign({}, notificationDefaults, {
        title: 'Password changed',
        message: 'Your password has been successfully changed',
        status: 'success',
    });

const userAdded = () =>
    Object.assign({}, notificationDefaults, {
        title: 'User added',
        message: 'A user was added successfully',
        status: 'success',
    });
const userDeleted = () =>
    Object.assign({}, notificationDefaults, {
        title: 'User removed',
        message: 'A user was removed successfully',
        status: 'success',
    });

const userUpdated = () =>
    Object.assign({}, notificationDefaults, {
        title: 'User updated',
        message: 'A user was successfully updated',
        status: 'success',
    });

const emailUpdated = () =>
    Object.assign({}, notificationDefaults, {
        title: 'Email changed',
        message: 'Your email has been successfully changed',
        status: 'success',
    });

const widgetSettingsUpdated = () =>
    Object.assign({}, notificationDefaults, {
        title: 'Widget Settings Updated',
        message: 'Your changes have been saved',
        status: 'success',
    });

const widgetSettingsAvatarValidationError = (errorMessage: string) =>
    Object.assign({}, notificationDefaults, {
        title: 'Avatar Validation Failed',
        message: errorMessage,
        status: 'error',
    });

const knowledgeBaseUpdated = () =>
    Object.assign({}, notificationDefaults, {
        title: 'Knowledge base updated',
        message: 'Your changes have been saved',
        status: 'success',
    });

// Applications actions

const applicationCreated = () =>
    Object.assign({}, notificationDefaults, {
        title: 'Application created',
        message: 'Your application has been created successfully',
        status: 'success',
    });

const applicationUpdated = () =>
    Object.assign({}, notificationDefaults, {
        title: 'Application updated',
        message: 'Your application has been updated.',
        status: 'success',
    });

// Import file
const fileUploaded = () =>
    Object.assign({}, notificationDefaults, {
        title: 'File uploaded',
        message: 'Your file has been uploaded.',
        status: 'success',
    });

// Generic site errors

const networkError = () =>
    Object.assign({}, notificationDefaults, {
        // TODO: Implement retry
        title: 'Oops',
        message: 'Looks like something went wrong, please try again shortly',
        status: 'error',
    });

const documentArchived = () =>
    Object.assign({}, notificationDefaults, {
        title: 'Document archived',
        message: 'Your document has been archived.',
        status: 'success',
    });

const documentUnarchived = () =>
    Object.assign({}, notificationDefaults, {
        title: 'Document unarchived',
        message: 'Your document has been unarchived.',
        status: 'success',
    });

const importIntegrationSuccess = () => ({
    ...notificationDefaults,
    title: 'Connection successful',
    message: 'The import is now in progress',
    status: 'success',
});

const importIntegrationError = () => ({
    ...notificationDefaults,
    title: 'Connection error',
    message: 'Something went wrong connecting to the account',
    status: 'error',
});
const responseCreationError = () => ({
    ...notificationDefaults,
    title: 'Response creation error',
    message: 'Something went wrong creating the response',
    status: 'error',
});
const responseUpdateError = () => ({
    ...notificationDefaults,
    title: 'Response update error',
    message: 'Something went wrong updating the response',
    status: 'error',
});
export const filesUploadedSuccess = () => ({
    ...notificationDefaults,
    title: 'Files uploaded!',
    message: 'Your files has been uploaded',
    status: 'success',
});
export const fileUploadedError = () => ({
    ...notificationDefaults,
    title: 'Error uploading file!',
    message: 'There has been an error uploading your file',
    status: 'error',
});

export default {
    responseCreated,
    responseUpdated,
    responseDeleted,
    orgUpdated,
    passwordUpdated,
    emailUpdated,
    applicationCreated,
    applicationUpdated,
    networkError,
    fileUploaded,
    notificationDefaults,
    widgetSettingsAvatarValidationError,
    documentArchived,
    documentUnarchived,
    widgetSettingsUpdated,
    knowledgeBaseUpdated,
    importIntegrationSuccess,
    importIntegrationError,
    textCopiedToClipboard,
    responseCreationError,
    responseUpdateError,
    userAdded,
    userDeleted,
    userUpdated,
};
