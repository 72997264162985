import React from 'react';

import {Button} from '@app/common/button';
import errorScreen from './error_screen.svg';

interface KareErrorProps {
    error?: any;
    errorInfo?: any;
}
// Design: https://projects.invisionapp.com/d/main#/console/15528636/328533888/preview

export class KareError extends React.Component<KareErrorProps> {
    handleClickRefresh = () => {
        window.location.reload();
    };

    render() {
        const {error, errorInfo} = this.props;
        return (
            <section className="kare-error">
                <div className="error-message-inner">
                    <img src={errorScreen} />
                    <h1>Sorry, something went wrong.</h1>
                    <p>
                        An unexpected error seems to have occurred.
                        <br />
                        Why not try refreshing your page?
                    </p>
                    <Button outlined onClick={this.handleClickRefresh}>
                        Refresh
                    </Button>
                    {process.env.NODE_ENV === 'development' && errorInfo && (
                        <div style={{whiteSpace: 'pre-wrap'}}>
                            {error && error.toString()}
                            <br />
                            {errorInfo.componentStack}
                        </div>
                    )}
                </div>
            </section>
        );
    }
}
