import moment from 'moment';

import {
    VIEWING_OPTION_DAILY,
    VIEWING_OPTION_HOURLY,
} from '../../dashboard/constants';

export const formatDateViewingOption = (dateRange) => {
    // In order for backend to return the correct data,
    // the startDate must start at the first second of the day (00:00:00)
    // and the endDate the last second of the day (23:59:59)
    if (!dateRange.to) {
        dateRange.to = dateRange.from;
    }

    const from = moment(dateRange.from).set({h: 0, m: 0, s: 0});
    const to = moment(dateRange.to).set({h: 23, m: 59, s: 59});
    const difference = from.diff(to, 'days', false);
    const viewingOption =
        difference >= 1 || difference <= -1 || isNaN(difference)
            ? VIEWING_OPTION_DAILY
            : VIEWING_OPTION_HOURLY;
    return {
        from: dateRange.from ? from.unix() : null,
        to: dateRange.to ? to.unix() : null,
        viewingOption,
    };
};
