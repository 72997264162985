import moment from 'moment';
import {get} from 'lodash';
import {DAILY_DATE_FORMAT} from '@app/responses/constants';
import {MONTH_LABEL_FORMAT} from './constants';

export const calculateResolved = (activityData) => {
    return {
        ...activityData,
        totalResolved:
            activityData.totalConversations -
            activityData.totalEscalated -
            activityData.totalBounced -
            activityData.totalHandover,
    };
};

export const transformQueryMetrics = (queries: any) => {
    if (get(queries, ['counters']) && get(queries, ['counters']).length) {
        const viewingOption = queries.resolution;
        const activityData: any = {
            viewingOption,
            totalQueries: 0,
            totalResponses: 0,
            totalDisambiguations: 0,
            totalMissing: 0,
        };
        activityData.counters = queries.counters
            .map((counter) => {
                const date = moment.unix(counter.timestamp);
                const queries = counter.counters.queries || 0;
                const responses = counter.counters.responses || 0;
                const missing = counter.counters.missing || 0;
                const disambiguations = counter.counters.choices || 0;
                activityData.totalResponses += responses;
                activityData.totalQueries += queries;
                activityData.totalMissing += missing;
                activityData.totalDisambiguations += disambiguations;

                let name = '';
                if (viewingOption === 'daily') {
                    name = `${date.format(DAILY_DATE_FORMAT)} ${date.format(
                        MONTH_LABEL_FORMAT,
                    )}`;
                } else {
                    name = `${date.hour()}:00`;
                }

                return {
                    date,
                    name: name,
                    responses,
                    disambiguations,
                    missing,
                };
            })
            .sort((a, b) => a.date.diff(b.date));
        return activityData;
    }
    return null;
};

export const transformMetricsConversations = (conversations: any) => {
    if (
        get(conversations, ['counters']) &&
        get(conversations, ['counters']).length
    ) {
        const viewingOption = conversations.resolution;
        const metricsData: any = {
            viewingOption,
            totalConversations: 0,
            totalEscalated: 0,
            totalResolved: 0,
            totalBounced: 0,
            totalUnresolved: 0,
            totalDeflected: 0,
            totalHandover: 0,
        };
        metricsData.counters = conversations.counters
            .map((counter) => {
                const date = moment.unix(counter.timestamp);
                const conversations = counter.counters.conversations || 0;
                const escalated = counter.counters.escalated || 0;
                const bounced = counter.counters.bounced || 0;
                const unresolved = counter.counters.unresolved || 0;
                const handover = counter.counters.handover || 0;
                const resolved =
                    conversations - escalated - bounced - unresolved - handover;
                const deflected = resolved + unresolved;
                metricsData.totalConversations += conversations;
                metricsData.totalEscalated += escalated;
                metricsData.totalBounced += bounced;
                metricsData.totalUnresolved += unresolved;
                metricsData.totalHandover += handover;
                metricsData.totalDeflected += deflected;

                let name = '';
                if (viewingOption === 'daily') {
                    name = `${date.format(DAILY_DATE_FORMAT)} ${date.format(
                        MONTH_LABEL_FORMAT,
                    )}`;
                } else {
                    name = `${date.hour()}:00`;
                }

                return {
                    date,
                    name,
                    escalated,
                    bounced,
                    unresolved,
                    handover,
                    resolved,
                    deflected,
                };
            })
            .sort((a, b) => a.date.diff(b.date));
        return calculateResolved(metricsData);
    }
    return null;
};

export const transformFeedbackMetrics = (feedback) => {
    if (get(feedback, ['counters']) && get(feedback, ['counters']).length) {
        // backend is still sending resolution
        const viewingOption = feedback.resolution;
        const activityData: any = {
            viewingOption,
            totalHelpful: 0,
            totalUnhelpful: 0,
        };
        activityData.counters = feedback.counters
            .map((counter) => {
                const date = moment(counter.timestamp);
                const helpful = counter.counters.helpful || 0;
                const unhelpful = counter.counters.unhelpful || 0;
                activityData.totalHelpful += helpful;
                activityData.totalUnhelpful += unhelpful;
                return {
                    date,
                    name: `${date.format(DAILY_DATE_FORMAT)} ${date.format(
                        MONTH_LABEL_FORMAT,
                    )}`,
                    helpful,
                    unhelpful,
                    total: helpful + unhelpful,
                };
            })
            .sort((a, b) => a.date.diff(b.date));
        return calculateResolved(activityData);
    }
    return null;
};

export const getInitialDateValues = () => ({
    from: moment().subtract(7, 'd'),
    to: moment(),
});
