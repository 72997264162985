import {get} from 'lodash';

import {LOADING, DATA, ERROR} from '../../constants';
import {
    ORG,
    CONTENT_LOCALES,
    PRIMARY_COLOR,
    ESCALATION_ENTRIES,
    UPDATE_ORG,
    DEFAULT_LOCALE,
} from '../constants';
import {getZone} from '@app/zone';
import {locale} from '../../../utils';

export const updateOrgErrorSelector = (state) => {
    return get(state, [UPDATE_ORG, ERROR]);
};
export const updateOrgLoadingSelector = (state) => {
    return get(state, [UPDATE_ORG, LOADING]) === true;
};

export const orgSelector = (state) => {
    return get(state, [ORG, DATA]);
};
export const orgSubdomainSelector = (state) => {
    return get(state, [ORG, DATA, 'subdomain']);
};

export const isOrgLoadingSelector = (state) => {
    return get(state, [ORG, LOADING]) === true;
};

export const contentLocalesSelector = (state) => {
    return get(state, [ORG, DATA, CONTENT_LOCALES], []);
};

export const defaultLocaleSelector = (state) => {
    const defaultZoneLocale = locale.getDefaultLocaleForZone(getZone());
    return get(state, [ORG, DATA, DEFAULT_LOCALE], defaultZoneLocale);
};

export const primaryColorSelector = (state) => {
    return get(state, [ORG, DATA, PRIMARY_COLOR]);
};

export const escalationEntriesSelector = (state) =>
    get(state, [ORG, DATA, ESCALATION_ENTRIES], []);
