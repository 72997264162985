import React from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {get} from 'lodash';

import {Document, Archive} from '@app/common/icons';
import {querystring} from '@app/utils';
import {NODE_STATUSES} from '@app/constants';

interface NoContentProps {
    history: any;
}

export class NoContentComponent extends React.Component<NoContentProps> {
    render() {
        const search = get(this.props, ['location', 'search'], '');
        const status = querystring.getStatus(search);
        const Icon = status === NODE_STATUSES.ARCHIVED ? Archive : Document;
        const title =
            status === NODE_STATUSES.ARCHIVED
                ? "You don't have any archived content."
                : "You don't have any content yet.";
        return (
            <section className="kare-empty-state">
                <Icon size={60} />
                <h1>{title}</h1>
                <p>
                    Connect your external sources and start answering your
                    customers by leveraging your existing knowledge. You’ll be
                    able to manage your answers on the Improve section.
                </p>
                <Link to="/settings/knowledge-management" className="c-btn">
                    Import existing knowledge
                </Link>
            </section>
        );
    }
}

export const NoContent = withRouter(NoContentComponent);
