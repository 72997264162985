import React from 'react';

import {BeaconsTable} from './BeaconsTable/BeaconsTable';

export interface BeaconsProps {
    history: any;
}

export const Beacons: React.FC<BeaconsProps> = (props) => {
    return (
        <div>
            <BeaconsTable />
        </div>
    );
};
