import {
    GET_SERVICES,
    types,
    ZENDESK_DISCONNECT,
    ZENDESK_IMPORT,
} from './constants';
import {DATA, METHODS} from '../constants';

const {POST, DELETE, GET} = METHODS;

export const rest = {
    [GET_SERVICES]: {
        url: `/v3.0/ingestion/services`,
        options: {
            method: GET,
        },
    },
    [ZENDESK_IMPORT]: {
        url: `/v3.0/ingestion/zendesk/token`,
        options: {
            method: POST,
        },
    },
    [ZENDESK_DISCONNECT]: {
        url: `/v3.0/ingestion/zendesk/disconnect`,
        options: {
            method: POST,
        },
    },
};
