import React from 'react';
import {Close} from '@app/common/icons';
import cx from 'classnames';
import Autocomplete from 'react-autocomplete';
import {MdLabel} from 'react-icons/md';
import DOMPurify from 'dompurify';

interface KareLabelProps {
    handleClose?: any;
    autoCompleteList?: any[];
    onKeyPress?: any;
    editable?: boolean;
    showRemoveButton?: boolean;
    text?: string;
    value?: string;
    onSelectItem?: any;
    placeholder?: string;
}

export class KareLabel extends React.Component<KareLabelProps> {
    constructor(props) {
        super(props);
        this.onInputKeyPress = this.onInputKeyPress.bind(this);
        this.onBlurInput = this.onBlurInput.bind(this);
    }

    state = {
        value: '',
        items: this.props.autoCompleteList,
        shouldDisplayPlusSign: true,
    };

    matchLabelToQuery = (state, value) =>
        state.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;

    onInputKeyPress(e) {
        const {onKeyPress, onSelectItem} = this.props;
        const {value} = this.state;
        if ((e.key === 'Enter' || e.charCode === 13) && onSelectItem && value) {
            onSelectItem(e, DOMPurify.sanitize(value));
            this.setState({value: ''});
        } else if (onKeyPress) {
            onKeyPress(e, value);
        }
    }

    onBlurInput() {
        this.setState({shouldDisplayPlusSign: !this.state.value});
    }

    getEditableLabel = (autoComplete) => {
        const {onSelectItem, placeholder = ''} = this.props;
        const {value, items} = this.state;
        return autoComplete ? (
            <Autocomplete
                autoHighlight={false}
                inputProps={{
                    className: 'kare-label__input',
                    autoFocus: true,
                    onKeyPress: this.onInputKeyPress,
                    onBlur: this.onBlurInput,
                    placeholder: placeholder,
                }}
                value={value}
                items={items}
                getItemValue={(item) => item.label}
                onChange={(e) => this.setState({value: e.target.value})}
                onSelect={(value) => {
                    if (onSelectItem) {
                        onSelectItem(null, value);
                        this.setState({value: ''});
                    } else {
                        this.setState({value});
                    }
                }}
                shouldItemRender={this.matchLabelToQuery}
                renderMenu={(children) => (
                    <div className="kare-label__select-menu">
                        {children.slice(0, 10)}
                    </div>
                )}
                renderItem={(item, isHighlighted) => (
                    <div
                        className={cx('kare-label__select-item', {
                            highlighted: isHighlighted,
                        })}
                        key={item.id}
                    >
                        <MdLabel size={20} className="kare-label__menu-icon" />
                        <span className="kare-label__menu-text">
                            {item.label}
                        </span>
                    </div>
                )}
            />
        ) : (
            <input
                autoFocus
                className="kare-label__input"
                onKeyPress={this.onInputKeyPress}
                placeholder={placeholder}
            />
        );
    };

    render() {
        const {
            text,
            handleClose,
            editable,
            autoCompleteList,
            showRemoveButton = true,
        } = this.props;

        return (
            <span
                className={cx('kare-label', {
                    editable: editable,
                    'kare-label--has-close': showRemoveButton,
                })}
            >
                {editable ? (
                    this.getEditableLabel(autoCompleteList)
                ) : (
                    <span className="kare-label__text">{text}</span>
                )}
                {showRemoveButton && (
                    <Close
                        onClick={(e) => handleClose(e, text)}
                        className="kare-label__icon"
                    />
                )}
            </span>
        );
    }
}
