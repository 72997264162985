import {REDIRECT_ZONES, ZONES} from './constants';

/**
 * Helper function to check if the zone you are working with.
 * @param {string} zone
 */
const isZoneRedirectEnabled = (zone) => {
    return REDIRECT_ZONES.indexOf(zone) !== -1;
};

/**
 * Utility function to make sure that it is always the hostname that is passes
 * and not location.href for instance. The href could contain more "." than we care about.
 * E.g "https://dashboard.eu.karehq.com/settings/v1.0.2"
 */
const getZone = () => {
    // @ts-ignore
    return process.env.ZONE || window.ZONE || ZONES.LOCAL;
};

export {REDIRECT_ZONES, ZONES, isZoneRedirectEnabled, getZone};
