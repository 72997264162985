import React from 'react';
import {uuidv4} from '@app/utils/Common';

interface RadioInputProps {
    disabled?: boolean;
    radioGroup?: string;
    id?: string;
    label?: string;
    input?: any;
    onClick?: any;
    style?: any;
}

export class RadioInput extends React.Component<RadioInputProps> {
    id = uuidv4();

    render() {
        const {label, id, input, onClick, disabled, ...remainingProps} =
            this.props;
        return (
            <div className="radio-input" onClick={onClick} {...remainingProps}>
                <input
                    type="radio"
                    id={id ? id : this.id}
                    {...input}
                    disabled={disabled}
                />
                <label htmlFor={id ? id : this.id}>
                    <div className="radio-input__checkmark" />
                    {label && <span>{label}</span>}
                </label>
            </div>
        );
    }
}
