import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import cx from 'classnames';
import {MdClose} from 'react-icons/md';
import ReactTable from 'react-table';
import {Field, FieldArray} from 'redux-form';
import {Button, KareModal, KareTextInput} from '@app/common';
import {Spinner} from '@app/common/button/spinner';
import {KareMultiselectCheckboxForReduxForm} from '@app/common/kare-multiselect-checkbox';
import {validators} from '@app/utils';
import {maxLength500, minLength1} from '@app/utils/validators';
import {FIELDS} from './constants';
import {Link} from 'react-router-dom';
import {CONTENT_LOCALE} from '../../redux/constants';

const renderActions = ({fields, isLoading, change}) => {
    const data = fields.map((field, index) => {
        const action = fields.get(index);
        return {
            id: action.id,
            name: action.name,
            enabled: `${field}.enabled`,
            openInModal: `${field}.openInModal`,
            fieldName: `${field}`,
            index,
        };
    });

    const columns = [
        {
            Header: 'Name',
            accessor: 'name',
            sortable: true,
            resizable: false,
            maxWidth: 500,
            // eslint-disable-next-line react/display-name
            Cell: (props) => {
                // eslint-disable-next-line react/prop-types
                const {value} = props;

                return <div>{value}</div>;
            },
        },
        {
            Header: 'Enable',
            accessor: 'enabled',
            resizable: false,
            sortable: false,
            width: 90,
            maxWidth: 170,
            // eslint-disable-next-line react/display-name
            Cell: (props) => {
                const {
                    // eslint-disable-next-line react/prop-types
                    value,
                    // eslint-disable-next-line react/prop-types
                    original: {fieldName},
                } = props;
                return (
                    <Field
                        name={`${value}`}
                        component={KareMultiselectCheckboxForReduxForm}
                        type="checkbox"
                        onChange={(e, newValue) => {
                            if (!newValue) {
                                change(
                                    `${FIELDS.WEB_EXTENSIONS}.${fieldName}.openInModal`,
                                    false,
                                );
                            }
                        }}
                    />
                );
            },
        },
        {
            Header: 'Open in modal',
            accessor: 'openInModal',
            resizable: false,
            sortable: false,
            width: 95,
            maxWidth: 170,
            // eslint-disable-next-line react/display-name
            Cell: (props) => {
                // eslint-disable-next-line react/prop-types
                const {value, index} = props;

                return (
                    <Field
                        name={`${value}`}
                        component={KareMultiselectCheckboxForReduxForm}
                        type="checkbox"
                        onChange={(e, newValue) => {
                            const action = fields.get(index);
                            if (!action.enabled && newValue) {
                                e.preventDefault();
                            }
                        }}
                    />
                );
            },
        },
    ];
    return (
        <ReactTable
            pageSize={data.length}
            className="widget-config-actions-tabe"
            data={data}
            columns={columns}
            loading={isLoading}
            loadingText={<Spinner />}
            showPagination={false}
            minRows={data.length}
        />
    );
};

interface renderLinksProps {
    fields?: any;
    value?: any;
    index?: number;
}

const renderLinks = (props: renderLinksProps) => {
    const {fields = []} = props;
    const ref: React.MutableRefObject<any> = useRef();
    const [numberFields, setNumberFields] = useState(fields.length);

    useEffect(() => {
        if (numberFields !== fields) {
            if (ref && ref.current) {
                ref.current.scrollTop = ref.current.scrollHeight;
            }
            setNumberFields(fields.length);
        }
    }, [fields, numberFields, ref]);

    return (
        <div className="widget-form-webextension-links-container">
            <label>Add links and they will appear in the widget menu</label>
            <div
                className="widget-form-webextension-links-list-container"
                ref={ref}
            >
                {fields.length > 0 &&
                    fields.map((field, index) => {
                        return (
                            <div
                                key={index}
                                className="widget-form-webextension-links-item"
                            >
                                <Field
                                    component={KareTextInput}
                                    placeholder={'Description'}
                                    name={`${field}.name`}
                                />
                                <Field
                                    component={KareTextInput}
                                    placeholder={'https://...'}
                                    name={`${field}.url`}
                                    validate={[
                                        validators.website,
                                        minLength1,
                                        maxLength500,
                                    ]}
                                />
                                <MdClose
                                    size={30}
                                    onClick={() => {
                                        fields.remove(index);
                                    }}
                                />
                            </div>
                        );
                    })}
            </div>
            <button
                className="c-btn c-btn--inverted c-btn--has-icon"
                onClick={(e) => {
                    e.preventDefault();
                    fields.push({});
                }}
            >
                + Add new link
            </button>
        </div>
    );
};

interface WebExtensionFormProps {
    actions?: any[];
    links?: any[];
    organisation?: any;
    change?: any;
    closeModal?: any;
    value?: string;
}

export const WebExtensionForm = (props: WebExtensionFormProps) => {
    const {
        actions,
        links,
        organisation: {webext_hostname},
        change,
        closeModal,
    } = props;

    const [actionsRequest, setActionsRequest] = useState({
        actions: [],
        isLoading: true,
    });
    const [activeTab, setActiveTab] = useState('actions');
    const [prevActions, setPrevActions] = useState([]);
    const [prevLinks, setPrevLinks] = useState([]);
    const instance = axios.create();

    useEffect(() => {
        if (!prevActions.length) {
            setPrevActions(
                actionsRequest.actions.map((action) => {
                    const origAction = actions.find(
                        (a) => a.id === action.name,
                    );
                    return {
                        id: action.name,
                        name: action.title,
                        url: action.url,
                        enabled: origAction ? origAction.enabled : false,
                        openInModal: origAction
                            ? origAction.openInModal
                            : false,
                    };
                }),
            );
        }
    });

    useEffect(() => {
        setPrevLinks(links);
    }, [links]);

    useEffect(() => {
        if (webext_hostname) {
            if (instance.defaults.headers[CONTENT_LOCALE])
                delete instance.defaults.headers[CONTENT_LOCALE];
            instance.get<any>(webext_hostname + '/conf/registry').then((r) => {
                if (r && r.data && r.data.length > 0) {
                    setActionsRequest({
                        actions: r.data.sort((a, b) => {
                            if (a.title < b.title) return -1;
                            if (a.title > b.title) return 1;
                            return 0;
                        }),
                        isLoading: false,
                    });
                }
            });
        } else {
            setActionsRequest({
                actions: [],
                isLoading: false,
            });
        }
    }, [webext_hostname]);

    useEffect(() => {
        if (actionsRequest.isLoading || prevActions.length > 0) {
            return;
        }

        change(
            `${FIELDS.WEB_EXTENSIONS}.${FIELDS.WEB_EXTENSIONS_ACTIONS}`,
            actionsRequest.actions.map((action) => {
                const origAction = actions.find((a) => a.id === action.name);
                return {
                    id: action.name,
                    name: action.title,
                    url: action.url,
                    enabled: origAction ? origAction.enabled : false,
                    openInModal: origAction ? origAction.openInModal : false,
                };
            }),
        );
    }, [actionsRequest, prevActions]);

    const defaultClassName = cx('form-tabs-item', {
        '_is-active': activeTab === 'actions',
    });
    const chatterClassName = cx('form-tabs-item', {
        '_is-active': activeTab === 'links',
    });

    const handleCloseModal = () => {
        change(
            `${FIELDS.WEB_EXTENSIONS}.${FIELDS.WEB_EXTENSIONS_LINKS}`,
            prevLinks,
        );
        change(
            `${FIELDS.WEB_EXTENSIONS}.${FIELDS.WEB_EXTENSIONS_ACTIONS}`,
            prevActions,
        );
        closeModal();
    };

    return (
        <KareModal
            title="Widget menu configurator"
            closeModal={handleCloseModal}
        >
            <div>
                <div className="form-tabs-container">
                    <div
                        className={defaultClassName}
                        onClick={() => {
                            setActiveTab('actions');
                        }}
                    >
                        List of actions
                    </div>
                    <div
                        className={chatterClassName}
                        onClick={() => {
                            setActiveTab('links');
                        }}
                    >
                        List of links
                    </div>
                </div>
                {activeTab === 'actions' && !actionsRequest.isLoading && (
                    <div className="widget-webextensions-selector-container">
                        {actionsRequest.actions.length === 0 && (
                            <div>
                                Please, first{' '}
                                <Link to={'/settings/automations'}>
                                    connect
                                </Link>{' '}
                                your actions environment
                            </div>
                        )}
                        {actionsRequest.actions.length > 0 && (
                            <FieldArray
                                name={FIELDS.WEB_EXTENSIONS_ACTIONS}
                                component={renderActions}
                                isLoading={actionsRequest.isLoading}
                                change={change}
                            />
                        )}
                    </div>
                )}
                {activeTab === 'actions' && actionsRequest.isLoading && (
                    <Spinner />
                )}
                {activeTab === 'links' && (
                    <FieldArray
                        name={FIELDS.WEB_EXTENSIONS_LINKS}
                        component={renderLinks}
                    />
                )}
            </div>
            <div className="submit-button-container">
                <Button onClick={closeModal}>Save changes</Button>
            </div>
        </KareModal>
    );
};
