import React, {useEffect, useState} from 'react';
import {getAutoWidgetScriptUrl} from '@app/utils/client-url';
import {getCookie, setCookie} from '@app/utils/auth';
import {FIELDS} from '@app/settings/widget-form/constants';
import Axios from 'axios';

interface WidgetProps {
    match?: {
        params?: {
            id: string;
        };
    };
}

export const Widget = (props: WidgetProps) => {
    const [config, setConfig] = useState({});
    const [selected, setSelected] = useState(
        getCookie('enable-tracking-widget-preview') === 'true',
    );
    const {
        match: {
            params: {id},
        },
    } = props;
    const configureWidget = async () => {
        const response = await Axios.get(`/v3.0/iam/apps/${id}/config`);
        if (response.status === 200) {
            const widgetConfig = response.data;
            setConfig(widgetConfig);
            const j = document.createElement('script');
            j.async = Boolean(1);
            j.type = 'text/javascript';
            j.src = `${getAutoWidgetScriptUrl()}?appId=${id}`;
            j.onload = () => {
                /*@ts-ignore*/
                window.GLR.onWidgetInit = () => {
                    if (widgetConfig['FAB_STYLE'] === 'CUSTOM') {
                        /*@ts-ignore*/
                        window.kare.addBeacon({
                            beaconName: 'launcher',
                            beaconType: 'fab',
                            htmlClickableElementId:
                                'karehq-widget-launcher-button',
                        });
                        const button = document.getElementById(
                            'karehq-widget-launcher-button',
                        );
                        button.addEventListener('click', () => {
                            /*@ts-ignore*/
                            window.kare.open();
                        });
                        /*@ts-ignore*/
                        window.kare.onClose(() => {
                            button.style.display = 'flex';
                        });
                        /*@ts-ignore*/
                        window.kare.onOpen(() => {
                            button.style.display = 'none';
                        });
                    }
                };
                /*@ts-ignore*/
                window.GLR.forceTrackMessages = widgetConfig
                    ? widgetConfig[FIELDS.ENABLE_TRACKING_TRY_NOW_PAGE] || false
                    : false;
                /*@ts-ignore*/
                window.GLR.isBeta = false;
            };
            document.getElementsByTagName('head')[0].appendChild(j);
            sessionStorage.removeItem('kare:kare-widget_sessionToken');
        }
    };

    useEffect(() => {
        configureWidget();
    }, []);

    return (
        <section className="widget">
            <h1 id="try-now-title" className="kare-h4">
                Widget preview
            </h1>
            <p>App id: {id}</p>
            {config['FAB_STYLE'] === 'CUSTOM' && (
                <button
                    className="try-me-now-widget-custom-button"
                    id="karehq-widget-launcher-button"
                >
                    Try my custom button
                </button>
            )}
            {/*Enable after the logic is changed*/}
            {/*{false && (
                <Checkbox
                    text="Enable tracking of messages. If widget is already open, refresh page."
                    onChange={(v) => {
                        setCookie(
                            'enable-tracking-widget-preview',
                            JSON.stringify(v),
                        );
                        window.GLR.forceTrackMessages = v;
                        setSelected(v);
                    }}
                    selected={selected}
                />
            )}*/}
        </section>
    );
};
