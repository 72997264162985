import {
    LAUNCHER_SIZE_MEDIUM,
    HORIZONTAL_LAUNCHER,
    LAUNCHER_POSITION_BOTTOM,
    LAUNCHER_SIDE_RIGHT,
    BRANDED,
    MOBILE,
    BUTTON_COLOR_SCHEME_LIGHT,
    BOOKMARK_ICON_BRANDED,
    BRANDED_BADGE_STYLE_DOT,
} from './launcher-preview/constants';

export const WIDGET_FORM_NAME = 'widgetForm';

export const FIELDS = {
    LOGO_URL: 'LOGO_URL',
    AVATAR_IMAGE: 'AVATAR_IMAGE',
    SHOW_AVATAR: 'SHOW_AVATAR',
    FONT_FAMILY: 'FONT_FAMILY',
    SCROLL_FOCUS: 'SCROLL_FOCUS',
    PRIMARY_COLOR_HEX: 'PRIMARY_COLOR_HEX',
    WIDGET_POPOVER: 'WIDGET_POPOVER',
    POPOVER_TEXT: 'POPOVER_TEXT',
    POPOVER_NODE: 'POPOVER_NODE',
    DRAG_AND_DROP_ENABLED: 'DRAG_AND_DROP_ENABLED',
    RESIZE_CONTROLS_ENABLED: 'RESIZE_CONTROLS_ENABLED',
    RELOAD_CONVERSATION_ENABLED: 'RELOAD_CONVERSATION_ENABLED',
    SHOW_ESCALATION_BUTTON: 'SHOW_ESCALATION_BUTTON',
    ENABLE_TRACKING_BETA: 'ENABLE_TRACKING_BETA',
    ENABLE_TRACKING_TRY_NOW_PAGE: 'ENABLE_TRACKING_TRY_NOW_PAGE',
    SHOW_WIDGET_MENU: 'SHOW_WIDGET_MENU',
    SHOW_POPOVER: 'SHOW_POPOVER',
    SHOW_POPOVER_NODE: 'SHOW_POPOVER_NODE',
    LAUNCHER_SIZE: 'LAUNCHER_SIZE',
    LAUNCHER_TYPE: 'LAUNCHER_TYPE',
    LAUNCHER_POSITION: 'LAUNCHER_POSITION',
    LAUNCHER_SIDE: 'LAUNCHER_SIDE',
    CALL_TO_ACTION: 'CALL_TO_ACTION',
    BANNER_MESSAGE_TEXT: 'BANNER_MESSAGE_TEXT',
    BANNER_HEIGHT: 'BANNER_HEIGHT',
    CUSTOM_CSS: 'CUSTOM_CSS',
    WEB_EXTENSIONS: 'WEB_EXTENSIONS',
    WEB_EXTENSIONS_ACTIONS: 'ACTIONS',
    WEB_EXTENSIONS_LINKS: 'LINKS',
    WIDGET_POPOVER_ENABLED: 'WIDGET_POPOVER_ENABLED',
    WIDGET_POPOVER_TEXT: 'WIDGET_POPOVER_TEXT',
    WIDGET_POPOVER_NODE: 'WIDGET_POPOVER_NODE',
    DEVICE_TYPE: 'DEVICE_TYPE',
    FAB_STYLE: 'FAB_STYLE',
    FAB_COLOR: 'FAB_COLOR',
    FAB_COLOR_SCHEME: 'FAB_COLOR_SCHEME',
    FAB_HORIZONTAL_POSITION: 'FAB_HORIZONTAL_POSITION',
    FAB_BOOKMARK_ICON: 'FAB_BOOKMARK_ICON',
    FAB_BADGE_ENABLED: 'ENABLE_BADGE',
    FAB_BADGE_STYLE: 'FAB_BADGE_STYLE',
};

export const websafeFonts = [
    'Arial',
    'Arial Black',
    'Arial Narrow',
    'Arial Rounded MT Bold',
    'Avenir',
    'Baskerville',
    'Brush Script MT',
    'Century Gothic',
    'Copperplate',
    'Courier',
    'Courier New',
    'Georgia',
    'Gill Sans',
    'Helvetica',
    'Helvetica Neue',
    'Lucida Bright',
    'Lucida Sans Typewriter',
    'Open Sans',
    'Palatino',
    'Papyrus',
    'Tahoma',
    'Times',
    'Times New Roman',
    'Trebuchet MS',
    'Verdana',
];

export const widgetDefaults = {
    [FIELDS.SHOW_AVATAR]: false,
    [FIELDS.AVATAR_IMAGE]: undefined,
    [FIELDS.CALL_TO_ACTION]: 'Need help',
    [FIELDS.LOGO_URL]: '/assets/default-logo.png',
    [FIELDS.FONT_FAMILY]: 'Open Sans',
    [FIELDS.PRIMARY_COLOR_HEX]: '#6C3DFF',
    [FIELDS.SCROLL_FOCUS]: 'FOCUS_END',
    [FIELDS.POPOVER_TEXT]: 'How can I help you?',
    [FIELDS.POPOVER_NODE]: '',
    [FIELDS.SHOW_POPOVER]: true,
    [FIELDS.WIDGET_POPOVER]: '',
    [FIELDS.WIDGET_POPOVER_ENABLED]: true,
    [FIELDS.SHOW_POPOVER_NODE]: false,
    [FIELDS.DRAG_AND_DROP_ENABLED]: false,
    [FIELDS.RESIZE_CONTROLS_ENABLED]: false,
    [FIELDS.RELOAD_CONVERSATION_ENABLED]: false,
    [FIELDS.LAUNCHER_SIZE]: LAUNCHER_SIZE_MEDIUM,
    [FIELDS.LAUNCHER_TYPE]: HORIZONTAL_LAUNCHER,
    [FIELDS.LAUNCHER_POSITION]: LAUNCHER_POSITION_BOTTOM,
    [FIELDS.LAUNCHER_SIDE]: LAUNCHER_SIDE_RIGHT,
    [FIELDS.BANNER_MESSAGE_TEXT]: 'Ask us anything you need help with!',
    [FIELDS.BANNER_HEIGHT]: 264,
    [FIELDS.CUSTOM_CSS]: '',
    [FIELDS.SHOW_WIDGET_MENU]: false,
    [FIELDS.WEB_EXTENSIONS]: {
        [FIELDS.WEB_EXTENSIONS_ACTIONS]: [],
    },
    [FIELDS.SHOW_ESCALATION_BUTTON]: true,
    [FIELDS.ENABLE_TRACKING_BETA]: false,
    [FIELDS.ENABLE_TRACKING_TRY_NOW_PAGE]: false,
    [FIELDS.FAB_COLOR]: '#6C3DFF',
    [FIELDS.FAB_STYLE]: BRANDED,
    [FIELDS.DEVICE_TYPE]: MOBILE,
    [FIELDS.FAB_COLOR_SCHEME]: BUTTON_COLOR_SCHEME_LIGHT,
    [FIELDS.FAB_BOOKMARK_ICON]: BOOKMARK_ICON_BRANDED,
    [FIELDS.FAB_BADGE_STYLE]: BRANDED_BADGE_STYLE_DOT,
    [FIELDS.FAB_BADGE_ENABLED]: false,
};
