import React from 'react';
import ReactTable from 'react-table';
import cx from 'classnames';
import {connect} from 'react-redux';

import {redux} from '@app/redux';
import {Queries} from './queries';
import {getResponseIcon} from './utils';
import {Response, ThumbUp, ThumbDown, ChevronRight} from '@app/common/icons';
import {CellWrapper} from './cell-wrapper';
import {Spinner, ErrorBoundary} from '@app/common';
import {CustomTBody} from './custom-tbody';

interface ResponsesProps {
    responses?: any[];
    queries?: any[];
    getResponseQueries?: any;
    analyticsDateRange?: any;
    isResponseCountersLoading?: boolean;
    isResponseQueriesLoading?: boolean;
    setActiveResponseId?: any;
    activeResponseId?: string;
    value?: string;
    original?: any;
}

export class ResponsesComponent extends React.Component<ResponsesProps> {
    getTrProps = (state, row) => {
        const {activeResponseId} = this.props;
        const className = cx({
            'dashboard-responses-row--is-active':
                row.original.resolution_id === activeResponseId,
        });
        return {
            onClick: () => {
                const {setActiveResponseId} = this.props;
                const responseId = row.original.resolution_id;
                const responseQueriesUrl =
                    row.original.resolution_queries_list_url;
                setActiveResponseId(responseId);
                this.props.getResponseQueries({
                    url: responseQueriesUrl,
                });
            },
            className,
        };
    };

    render() {
        const iconWidth = 45;
        const columns = [
            {
                Header: 'Responses',
                accessor: 'resolution_title',
                sortable: false,
                headerClassName: 'dashboard__dashboard-responses-column-header',
                Cell: (props) => {
                    const {
                        value,
                        original: {resolution_type},
                    } = props;
                    return (
                        <CellWrapper
                            isDark={true}
                            className="dashboard-responses-cell-response"
                        >
                            {getResponseIcon(resolution_type)}
                            <span>{value}</span>
                        </CellWrapper>
                    );
                },
            },
            {
                Header: <Response size={16} />,
                headerClassName: 'dashboard-responses-icon-container',
                accessor: 'responses',
                sortable: true,
                width: iconWidth,
                Cell: (props) => (
                    <CellWrapper isCenterAligned={true}>
                        {props.value}
                    </CellWrapper>
                ),
            },
            {
                Header: <ThumbUp size={16} />,
                headerClassName: 'dashboard-responses-icon-container',
                accessor: 'feedback_up',
                sortable: true,
                width: iconWidth,
                Cell: (props) => (
                    <CellWrapper isCenterAligned={true}>
                        {props.value}
                    </CellWrapper>
                ),
            },
            {
                Header: <ThumbDown size={16} />,
                headerClassName: 'dashboard-responses-icon-container',
                accessor: 'feedback_down',
                sortable: true,
                width: iconWidth,
                Cell: (props) => (
                    <CellWrapper isCenterAligned={true}>
                        {props.value}
                    </CellWrapper>
                ),
            },
            {
                Header: '',
                accessor: 'id',
                sortable: false,
                width: iconWidth,
                Cell: () => (
                    <CellWrapper isCenterAligned={true}>
                        <ChevronRight
                            size={18}
                            className="dashboard-responses--icon dashboard-responses--icon-chevron"
                        />
                    </CellWrapper>
                ),
            },
        ];

        const {
            responses,
            queries,
            isResponseCountersLoading,
            isResponseQueriesLoading,
        } = this.props;
        if (isResponseCountersLoading) {
            return <Spinner withCenteredContainer={true} />;
        }
        if (!responses) {
            return null;
        }
        return (
            <ErrorBoundary>
                <div className="dashboard-responses-container">
                    <ReactTable
                        className="dashboard-responses"
                        data={responses}
                        columns={columns}
                        showPagination={false}
                        TbodyComponent={CustomTBody}
                        resizable={false}
                        // TODO(rupertrutland): have a placeholder when there is no data.
                        minRows={0}
                        getTrProps={this.getTrProps}
                        defaultSorted={[
                            {
                                id: 'responses',
                                desc: true,
                            },
                        ]}
                    />
                    <Queries
                        queries={queries}
                        isLoading={isResponseQueriesLoading}
                    />
                </div>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        responses: redux.activity.selectors.responseCountersSelector(state),
        queries: redux.activity.selectors.responseQueriesSelector(state),
        analyticsDateRange:
            redux.activity.selectors.analyticsDateRangeSelector(state),
        isResponseCountersLoading:
            redux.activity.selectors.isResponseCountersLoadingSelector(state),
        isResponseQueriesLoading:
            redux.activity.selectors.isResponseQueriesLoadingSelector(state),
        activeResponseId:
            redux.activity.selectors.activeResponseIdSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    getResponseQueries:
        redux.activity.dispatchers.getResponseQueriesDispatcher(dispatch),
    setActiveResponseId:
        redux.activity.dispatchers.setActiveResponseIdDispatcher(dispatch),
});

export const Responses = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResponsesComponent);
