import React from 'react';
import ScrollBar from 'react-perfect-scrollbar';

import {Query} from './query';
import {Spinner} from '@app/common';

interface QueriesProps {
    queries?: any[];
    isLoading?: boolean;
    onMount?: (...args: any) => any;
}

export class Queries extends React.Component<QueriesProps> {
    renderQueries = () => {
        const {queries, isLoading} = this.props;
        if (isLoading) {
            return <Spinner withCenteredContainer={true} />;
        }
        if (!queries || !queries.length) {
            return null;
        }
        return (
            <ScrollBar style={{paddingRight: 20}}>
                {queries.map((query) => (
                    <Query
                        query={query}
                        key={`${query.query}${query.timestamp}`}
                    />
                ))}
            </ScrollBar>
        );
    };
    render() {
        return (
            <div className="dashboard-response-queries">
                <div className="dashboard-response-queries-title">
                    <span>Queries</span>
                </div>
                <div className="dashboard-response-queries-list">
                    {this.renderQueries()}
                </div>
            </div>
        );
    }
}
