import React from 'react';
import cx from 'classnames';
// @ts-ignore
import './Button.scss';

export interface IButton {
    text?: string;
    type?: string;
    onClick?: any;
    className?: string;
}

export const Button: React.FunctionComponent<IButton> = (props) => {
    const typeButtonClassname = cx('Button__container', props.className, {
        ['Button__confirm']: props.type === 'confirm',
        ['Button__cancel']: props.type === 'cancel',
    });
    return (
        <div className={typeButtonClassname} onClick={props.onClick}>
            <span className="Button__button">{props.text}</span>
        </div>
    );
};
