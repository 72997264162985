import {METHODS} from '../constants';
import {ORG, UPDATE_ORG} from './constants';

const {PUT} = METHODS;

export const rest = {
    [ORG]: {
        url: '/v3.0/settings/org',
        crud: true,
    },
    [UPDATE_ORG]: {
        url: '/v3.0/settings/org',
        options: {
            method: PUT,
        },
    },
};
