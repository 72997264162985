import {DefaultLinkModel} from '@projectstorm/react-diagrams-defaults';

export class AdvancedLinkModel extends DefaultLinkModel {
    constructor() {
        super({
            type: 'advanced',
            width: 2,
            curvyness: 50,
        });
    }
}
