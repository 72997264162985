import React from 'react';
import Select, {components, createFilter} from 'react-select';

interface SearchableSelectInputProps {
    input: any;
    options: any[];
    label: string;
    placeholder?: string;
}

export class SearchableSelectInput extends React.Component<SearchableSelectInputProps> {
    render() {
        const {input, options, label, placeholder} = this.props;
        return (
            <div className="kare-select-input">
                {label && <label htmlFor={input.name}>{label}</label>}
                <Select
                    className="react-select-container"
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    options={options}
                    onChange={(event) => {
                        input.onChange(event);
                    }}
                    components={{
                        Option: Option,
                    }}
                    filterOption={createFilter({ignoreAccents: false})} // Reduce lag in big list of options
                    value={input.value}
                    placeholder={placeholder}
                />
            </div>
        );
    }
}

interface OptionProps {
    children: any;
    innerProps: any;
}

const Option = ({children, ...props}: OptionProps) => {
    // Hack to increase react-select performance with big list of options
    /* eslint-disable no-unused-vars */
    const {...rest} = props.innerProps;
    const newProps = Object.assign(props, {innerProps: rest});
    // @ts-ignore
    return <components.Option {...newProps}>{children}</components.Option>;
};
