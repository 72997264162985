import {resetSelectResponseStateAction, selectResponseAction} from '../actions';
import {redux} from '../../../index';
import {IMPROVE_CREATE_SELECTED_RESPONSE} from '../constants';

export const selectResponseDispatcher = (dispatch, id) => {
    dispatch(
        redux.rest.actions[
            redux.responses.constants.GET_IMPROVE_PAGE_RESPONSE
        ].force({id}),
    ).then((response) => {
        return dispatch(selectResponseAction({response}));
    });
};

export const resetSelectResponseState = (dispatch) => () => {
    dispatch(resetSelectResponseStateAction());
};

export const createImproveSelectedResponse = (dispatch) => (response) => {
    return dispatch({
        type: IMPROVE_CREATE_SELECTED_RESPONSE,
        payload: response,
    });
};
