import {get} from 'lodash';

import {
    IMPROVE_RESPONSES_STATE_KEY,
    IMPROVE_STATE_KEY,
    RESPONSE_FORM,
    RESPONSE_FORM_SOURCE,
    RESPONSE_FORM_TAGS,
    RESPONSE_FORM_TITLE_FIELD,
} from '../constants';

export const getSelectedResponse = (state) => {
    return get(state, [
        IMPROVE_STATE_KEY,
        IMPROVE_RESPONSES_STATE_KEY,
        'selectedResponse',
    ]);
};

export const isNewResponse = (state) => {
    return get(
        state,
        [IMPROVE_STATE_KEY, IMPROVE_RESPONSES_STATE_KEY, 'isNewResponse'],
        false,
    );
};

export const isAutoAnswerCluster = (state) => {
    return get(
        state,
        [IMPROVE_STATE_KEY, IMPROVE_RESPONSES_STATE_KEY, 'isAutoAnswerCluster'],
        false,
    );
};

export const isImproveAnswerCluster = (state) => {
    return get(
        state,
        [
            IMPROVE_STATE_KEY,
            IMPROVE_RESPONSES_STATE_KEY,
            'isImproveAnswerCluster',
        ],
        false,
    );
};

export const isAutoResponse = (state) => {
    return get(
        state,
        [IMPROVE_STATE_KEY, IMPROVE_RESPONSES_STATE_KEY, 'isAutoResponse'],
        false,
    );
};

export const getAutoResponseTitle = (state) => {
    return get(state, [
        IMPROVE_STATE_KEY,
        IMPROVE_RESPONSES_STATE_KEY,
        'autoResponse',
        'title',
    ]);
};

export const filterQuery = (state) => {
    return get(state, [
        IMPROVE_STATE_KEY,
        IMPROVE_RESPONSES_STATE_KEY,
        'filterQuery',
    ]);
};

export const getResponseFormData = (state) => {
    const values = get(state, `form.${RESPONSE_FORM}.values`);
    if (values) {
        return {
            title: values[RESPONSE_FORM_TITLE_FIELD],
            // body: values[RESPONSE_FORM_BODY_FIELD],
            body: values.body,
            tags: values[RESPONSE_FORM_TAGS],
            document: values[RESPONSE_FORM_SOURCE]
                ? {
                      id: values[RESPONSE_FORM_SOURCE].id,
                      selection: values[RESPONSE_FORM_SOURCE].selection,
                      attachmentUrl: values[RESPONSE_FORM_SOURCE].attachmentUrl,
                      attachmentTitle:
                          values[RESPONSE_FORM_SOURCE].attachmentTitle,
                  }
                : undefined,
        };
    }
};
