import {getZone, ZONES} from '@app/zone';

export function getDashboardDomain() {
    switch (getZone()) {
        case ZONES.DEVELOP:
            return `dashboard.${getZone()}.karehq.com`;
        default:
            return `dashboard.karehq.com`;
    }
}

export function getZoneDomain() {
    return `${getZone()}.karehq.com`;
}

export function getConsoleURL() {
    return `https://dashboard.${getZoneDomain()}`;
    // TODO(rupertrutland): Use this URL when it's available:
    // return `https://console.${getZoneDomain()}`;
}

export function getSubdomainFromURL() {
    if (process.env.SUBDOMAIN) {
        return process.env.SUBDOMAIN;
    }
    if (!window.location.hostname.endsWith(getDashboardDomain())) {
        return null;
    }
    return window.location.hostname.split('.')[0];
}

/**
 * Main Console API.
 */
export const getApiUrl = () => {
    return process.env.API_URL || `https://api.${getZoneDomain()}/`;
};

/**
 * Used for the snippet creator on the settings page.
 */
export const getWidgetScriptUrl = () => {
    if (window.location.hostname.endsWith(`beta.${getDashboardDomain()}`)) {
        return (
            process.env.WIDGET_SCRIPT_URL ||
            `https://widget-beta.${getZoneDomain()}/latest.js`
        );
    }
    return (
        process.env.WIDGET_SCRIPT_URL ||
        `https://widget.${getZoneDomain()}/latest.js`
    );
};

export const getAutoWidgetScriptUrl = () => {
    if (window.location.hostname.endsWith(`beta.${getDashboardDomain()}`)) {
        return (
            process.env.WIDGET_SCRIPT_URL ||
            `https://widget-beta.${getZoneDomain()}/kare_loader.js`
        );
    }
    return (
        process.env.WIDGET_SCRIPT_URL ||
        `https://widget.${getZoneDomain()}/kare_loader.js`
    );
};

export const getRecoveryUrl = () => {
    return `${getConsoleURL()}/reset-password/recover`;
};

export const removeLastPath = (url) => {
    const newUrl = url.split('/');
    newUrl.pop();
    return newUrl.join('/');
};
