import reduxApi from 'redux-api';
import {adapterFetch, getRequestOptions} from './utils';
import {clientUrl} from '../utils';
import {responses} from './responses';
import {documents} from './documents';
import {activity} from './activity';
import {conversations} from './conversations';
import {feedbacks} from './feedback';
import {escalations} from './escalations';
import {improve} from './improve';
import {integrations} from './integrations';
import {user} from './user';
import {org} from './org';
import {application} from './application';
import {locale} from './locale';
import {widget} from './widget';
import {settings} from './settings';
import {knowledgeBase} from './knowledge-base';
import {contact} from './contact';
import {metrics} from './metrics';
import {tokenSelector} from './user/selectors';
import axios from 'axios';
import {queries} from '@app/redux/improve/queries';
import {timeseriesQueries} from '@app/redux/queries';
import {removeLocalStorage} from '@app/utils/auth';

/**
 * The main entry point for redux-api redux setup.
 */
export const rest = reduxApi({
    ...integrations.rest,
    ...activity.rest,
    ...responses.rest,
    ...documents.rest,
    ...conversations.rest,
    ...feedbacks.rest,
    ...knowledgeBase.rest,
    ...improve.rest,
    ...escalations.rest,
    ...user.rest,
    ...org.rest,
    ...queries.rest,
    ...timeseriesQueries.rest,
    ...application.rest,
    ...widget.rest,
    ...settings.rest,
    ...contact.rest,
    ...metrics.rest,
})
    // TODO(rupertrutland): Placeholder for general redirects if we need them later.
    .use('responseHandler', (err, data) => {
        const search = window.location.search;
        const code = new URLSearchParams(search).get('code');

        if (err !== undefined && err !== null) {
            if (
                err.code &&
                err.code === 401 &&
                err.message &&
                err.message === 'Lockdown'
            ) {
                window.location.href = '/lockdown';
            }
            if (err.code && err.code === 401) {
                if (code) {
                    removeLocalStorage();
                    location.reload();
                } else if (window.location.pathname !== '/refresh') {
                    window.location.href = '/refresh';
                }
            }
            return Promise.reject(err);
        }
        return Promise.resolve(data);
    })
    .use('fetch', adapterFetch(fetch))
    .use('rootUrl', `${clientUrl.getApiUrl()}`)
    .use('options', (url, params, getState) => {
        const state = getState();
        const contentLocale = locale.selectors.contentLocaleSelector(state);
        const token = tokenSelector();
        axios.defaults.headers = getRequestOptions(
            token,
            contentLocale,
        ).headers;
        axios.defaults.baseURL = clientUrl.getApiUrl();
        return getRequestOptions(token, contentLocale);
    });
