import React from 'react';

export interface BarChartContentLoaderProps {}

export const BarChartContentLoader: React.FC<BarChartContentLoaderProps> = (
    props,
) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="493"
            height="153"
            viewBox="0 0 493 153"
        >
            <defs>
                <rect
                    id="path-1"
                    width="600"
                    height="320"
                    x="0"
                    y="0"
                    rx="2"
                ></rect>
                <filter
                    id="filter-3"
                    width="101.2%"
                    height="102.2%"
                    x="-.6%"
                    y="-.8%"
                    filterUnits="objectBoundingBox"
                >
                    <feOffset
                        dy="1"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                    ></feOffset>
                    <feGaussianBlur
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                        stdDeviation="1"
                    ></feGaussianBlur>
                    <feColorMatrix
                        in="shadowBlurOuter1"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
                    ></feColorMatrix>
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-454 -248)">
                    <g transform="translate(400 114)">
                        <mask fill="#fff">
                            <use xlinkHref="#path-1"></use>
                        </mask>
                        <g>
                            <use
                                fill="#000"
                                filter="url(#filter-3)"
                                xlinkHref="#path-1"
                            ></use>
                            <use fill="#FFF" xlinkHref="#path-1"></use>
                        </g>
                        <g fill="#DFE1E1" transform="translate(54 134)">
                            <rect
                                width="25"
                                height="103"
                                x="78"
                                y="50"
                                rx="2.5"
                            ></rect>
                            <rect
                                width="25"
                                height="83"
                                x="39"
                                y="70"
                                rx="2.5"
                            ></rect>
                            <rect
                                width="25"
                                height="103"
                                x="0"
                                y="50"
                                rx="2.5"
                            ></rect>
                            <rect
                                width="25"
                                height="83"
                                x="273"
                                y="70"
                                rx="2.5"
                            ></rect>
                            <rect
                                width="25"
                                height="83"
                                x="468"
                                y="70"
                                rx="2.5"
                            ></rect>
                            <rect
                                width="25"
                                height="123"
                                x="117"
                                y="30"
                                rx="2.5"
                            ></rect>
                            <rect
                                width="25"
                                height="123"
                                x="312"
                                y="30"
                                rx="2.5"
                            ></rect>
                            <rect
                                width="25"
                                height="143"
                                x="156"
                                y="10"
                                rx="2.5"
                            ></rect>
                            <rect
                                width="25"
                                height="143"
                                x="351"
                                y="10"
                                rx="2.5"
                            ></rect>
                            <rect
                                width="25"
                                height="153"
                                x="195"
                                y="0"
                                rx="2.5"
                            ></rect>
                            <rect
                                width="25"
                                height="153"
                                x="390"
                                y="0"
                                rx="2.5"
                            ></rect>
                            <rect
                                width="25"
                                height="113"
                                x="234"
                                y="40"
                                rx="2.5"
                            ></rect>
                            <rect
                                width="25"
                                height="113"
                                x="429"
                                y="40"
                                rx="2.5"
                            ></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
