import React from 'react';
import cx from 'classnames';

import './CardTitle.scss';

interface ICardTitleProps {
    title: string;
    className?: string;
}

export const CardTitle: React.FunctionComponent<ICardTitleProps> = ({
    title,
    className = '',
}) => {
    return <h1 className={cx('cardTitle__container', className)}>{title}</h1>;
};
