import React from 'react';

import {NODE_TYPES} from '@app/constants';
import {Response, Document} from '@app/common/icons';

// TODO(rupertrutland): Update this according to the node update from the backend.
// The keys will probably change here.
export const getResponseIcon = (type) => {
    const className = 'dashboard-response-type-icon';
    if (type === NODE_TYPES.RESPONSE) {
        return <Response className={className} />;
    }
    if (type === NODE_TYPES.CHATTER) {
        return <Response className={className} />;
    }
    if (type === NODE_TYPES.CONTENT) {
        return <Document className={className} />;
    }
    return <Document className={className} />;
};
