import {from} from 'fromfrom';
import {getChartColor} from './DataRenderingUtils';
import {ClusterResponse} from '@app/types/Clusters';

export const transformClustersDataToTreeMap = (data: ClusterResponse) => {
    const transData = from(data)
        // @ts-ignore
        .groupBy('cluster_type')
        .map((group) => ({
            name: group.key,
            id: group.key,
            color: getChartColor(group.key.toString()),
            children: group.items.map((c: any) => {
                const queryCount: any = c.queries.reduce(
                    (a, v) => a + v.query_count,
                    0,
                );
                return {
                    id: `${c.queries[0].query}`,
                    name: `${c.queries[0].query} (${queryCount})`,
                    nodeId: c.id,
                    type: c.cluster_type,
                    value: queryCount,
                    color: getChartColor(group.key.toString()),
                };
            }),
        }))
        .toArray();

    return {name: 'clusters', color: 'red', children: transData};
};
