import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {find, get} from 'lodash';
import cx from 'classnames';
import {MdCloudUpload} from 'react-icons/md';

import {redux} from '@app/redux';
import {DocumentsTableComp} from './documents-table';
import {DocumentPortal} from './document-portal';
import {
    Button,
    ErrorBoundary,
    KareError,
    KareStatusTabs,
    SearchBar,
    Spinner,
} from '@app/common';
import {FIELD_MASKS, NODE_STATUSES, STATUS} from '@app/constants';
import {KeyboardArrowLeft} from '@app/common/icons';
import {querystring} from '@app/utils';
import {NoContent} from './no-content';
import {FormLessTagsInput} from '@app/common/tags-input/formless-tags-input';
import {deserializeQs} from '@app/utils/url';
import {getValueByKey} from '@app/utils/querystring';
import {LibraryFilters} from './library-filters';
import {updateQueryStringParameter} from '@app/common/kare-status-tabs';

interface DocumentsProps {
    searchKnowledgeBase: any;
    history: any;
    query: string;
    status: string;
    publishedDocuments: any[];
    archivedDocuments: any[];
    documentsLoading: boolean;
    isLoading: boolean;
    contentLocale: string;
    location: any;
    resetSelectedRows: any;
    bulkArchiveDocuments: any;
    bulkUnarchiveDocuments: any;
    getAllPublishedDocuments: any;
    getAllArchivedDocuments: any;
    allContentError: any;
    selectedRows: any[];
}

/**
 * Responses listing component.
 */
export class DocumentsComponent extends React.Component<DocumentsProps> {
    state = {
        showFilterButton: false,
        labelFilter: null,
        labelsToFilter: [],
        showUploadModal: false,
    };

    setShowUploadModal = (value) => {
        this.setState({
            showUploadModal: value,
        });
    };

    componentDidMount() {
        const {
            getAllPublishedDocuments,
            documentsLoading,
            getAllArchivedDocuments,
        } = this.props;
        const activeFilter = getValueByKey(
            this.props.location.search,
            'source_name',
        );

        if (!documentsLoading) {
            getAllPublishedDocuments(activeFilter);
            getAllArchivedDocuments(activeFilter);
        }
    }

    renderDocumentsTable = () => {
        const {publishedDocuments, archivedDocuments, documentsLoading, query} =
            this.props;
        const isShowingArchived = this.isShowingArchived();
        const documents = isShowingArchived
            ? archivedDocuments
            : publishedDocuments;
        const filteredDocuments = documents.filter((document) => {
            const documentTags = get(document, 'labels', []) || [];
            let allLabelsMatch = true;

            const normalizedLabelsToFilter = this.state.labelsToFilter.map(
                (l) => l.name,
            );

            if (
                documentTags.length === 0 &&
                normalizedLabelsToFilter.length > 0
            ) {
                allLabelsMatch = false;
            }
            if (documentTags && documentTags.length > 0) {
                normalizedLabelsToFilter.map((tag) => {
                    if (!documentTags.includes(tag)) {
                        allLabelsMatch = false;
                    }
                });
            }

            return (
                document.title.toLowerCase().includes(query.toLowerCase()) &&
                allLabelsMatch
            );
        });

        return filteredDocuments && filteredDocuments.length > 0 ? (
            <DocumentsTableComp
                items={filteredDocuments}
                isLoading={documentsLoading}
            />
        ) : null;
    };

    componentWillUnmount() {
        const {resetSelectedRows} = this.props;
        resetSelectedRows();
    }

    componentDidUpdate(prevProps) {
        const newStatus = querystring.getStatus(this.props.location.search);
        const oldStatus = querystring.getStatus(prevProps.location.search);
        const {
            contentLocale,
            getAllPublishedDocuments,
            getAllArchivedDocuments,
        } = this.props;
        const {contentLocale: prevContentLocale} = prevProps;
        const previousPreFilledLabel = get(
            deserializeQs(prevProps.location.search),
            ['labelFilter'],
        );
        const preFilledLabel = get(deserializeQs(this.props.location.search), [
            'labelFilter',
        ]);

        const previousSourceFilter = get(
            deserializeQs(prevProps.location.search),
            ['source_name'],
        );
        const sourceFilter = get(deserializeQs(this.props.location.search), [
            'source_name',
        ]);

        if (contentLocale !== prevContentLocale || newStatus !== oldStatus) {
            if (newStatus === 'unpublished') {
                getAllArchivedDocuments(sourceFilter);
            } else {
                getAllPublishedDocuments(sourceFilter);
            }
        }
        if (
            previousPreFilledLabel !== preFilledLabel &&
            preFilledLabel &&
            preFilledLabel.length > 0
        ) {
            this.setState(
                {
                    labelsToFilter: [{name: preFilledLabel}],
                },
                () => {
                    this.props.history.push('/library');
                },
            );
        }
        if (sourceFilter && previousSourceFilter !== sourceFilter) {
            if (newStatus === 'unpublished') {
                getAllArchivedDocuments(sourceFilter);
            } else {
                getAllPublishedDocuments(sourceFilter);
            }
        }
    }

    /**
     * Query change event handle.
     */
    handleQueryChange = (event) => {
        const query = event.target.value;
        this.props.searchKnowledgeBase(query);
    };

    isShowingArchived = () => {
        return this.props.status === 'unpublished';
    };

    disableFilterButton = () => {
        this.setState({showFilterButton: false});
    };

    /*
    XXX(joaonoronha): Why do we have 2 functions with the same name doing different things (???)
    I have commented this one out since it's always being overwritten by the one in the bottom..
    This needs discussing.

    handleClickShowActive = () => {
        this.props.history.push(`?${STATUS}=${NODE_STATUSES.PUBLISHED}`);
    };*/
    handleClickArchiveSelected = () => {
        const {selectedRows, bulkArchiveDocuments} = this.props;
        bulkArchiveDocuments(selectedRows);
    };
    handleClickUnarchiveSelected = () => {
        const {selectedRows, bulkUnarchiveDocuments} = this.props;
        bulkUnarchiveDocuments(selectedRows);
    };

    handleClickDeselectAll = () => {
        this.props.resetSelectedRows();
    };

    handleClickShowActive = () => {
        this.props.history.push(
            `${
                this.props.history.location.pathname
            }${updateQueryStringParameter(
                this.props.history.location.search,
                'status',
                NODE_STATUSES.PUBLISHED,
            )}`,
        );
    };

    toggleFilterButton = () => {
        if (this.state.showFilterButton) {
            this.removeAllLabels();
        }
        this.setState({
            showFilterButton: !this.state.showFilterButton,
        });
    };

    handleClickShowArchived = () => {
        this.props.history.push(
            `${
                this.props.history.location.pathname
            }${updateQueryStringParameter(
                this.props.history.location.search,
                'status',
                'unpublished',
            )}`,
        );
    };

    setLabelsToFilter = (labelsToFilter) => {
        this.setState({
            labelsToFilter,
        });
    };

    removeAllLabels = () => {
        this.setState({
            labelsToFilter: [],
        });
    };

    reloadData = () => {
        const {getAllPublishedDocuments} = this.props;
        const activeFilter = getValueByKey(
            this.props.location.search,
            'source_name',
        );

        getAllPublishedDocuments(activeFilter);
    };

    /**
     * Return the main container for the knowledge base.
     */
    render() {
        const {
            publishedDocuments,
            archivedDocuments,
            documentsLoading,
            selectedRows,
            isLoading,
            allContentError,
            query,
        } = this.props;

        const numberOfSelectedRows = selectedRows.length;
        const isShowingArchived = this.isShowingArchived();
        const documents = isShowingArchived
            ? archivedDocuments
            : publishedDocuments;
        const areItemsSelected = numberOfSelectedRows > 0;
        const matchingItems = documents.filter((document) => {
            const documentTags = get(document, 'labels', []) || [];
            let labelMatch = true;
            if (this.state.labelsToFilter.length > 0) {
                if (
                    !documentTags.filter((tag) => {
                        return find(this.state.labelsToFilter, {name: tag});
                    }).length
                ) {
                    labelMatch = false;
                }
            }

            return (
                document.title.toLowerCase().includes(query.toLowerCase()) &&
                labelMatch
            );
        });

        // TODO(rupertrutland): rafactor this out to be reused between the documents and the responses.
        const className = cx('knowledge-base__container', {
            'knowledge-base__container--loading': documentsLoading,
        });
        const statusTabs = [
            {
                title: 'Published',
                onClick: this.handleClickShowActive,
                isActive: !isShowingArchived,
                position: 'LEFT',
            },
            {
                title: 'Archived',
                onClick: this.handleClickShowArchived,
                isActive: isShowingArchived,
                position: 'RIGHT',
            },
        ];
        if (allContentError) {
            return <KareError error={allContentError} />;
        }
        if (isLoading) {
            return <Spinner withCenteredContainer={true} />;
        }
        const activeFilter = getValueByKey(
            this.props.location.search,
            'source_name',
        );

        const filters = [
            {
                key: 'all',
                title: 'All',
                onClick: () => {
                    this.props.history.push(
                        `${
                            this.props.history.location.pathname
                        }${updateQueryStringParameter(
                            this.props.history.location.search,
                            'source_name',
                            'all',
                        )}`,
                    );
                },
            },
            {
                key: 'uploads',
                title: 'Uploads',
                onClick: () => {
                    this.props.history.push(
                        `${
                            this.props.history.location.pathname
                        }${updateQueryStringParameter(
                            this.props.history.location.search,
                            'source_name',
                            'uploads',
                        )}`,
                    );
                },
            },
            {
                key: 'web',
                title: 'Web',
                onClick: () => {
                    this.props.history.push(
                        `${
                            this.props.history.location.pathname
                        }${updateQueryStringParameter(
                            this.props.history.location.search,
                            'source_name',
                            'web',
                        )}`,
                    );
                },
            },
            {
                key: 'zendesk',
                title: 'Zendesk',
                onClick: () => {
                    this.props.history.push(
                        `${
                            this.props.history.location.pathname
                        }${updateQueryStringParameter(
                            this.props.history.location.search,
                            'source_name',
                            'zendesk',
                        )}`,
                    );
                },
            },
            {
                key: 'box',
                title: 'Box',
                onClick: () => {
                    this.props.history.push(
                        `${
                            this.props.history.location.pathname
                        }${updateQueryStringParameter(
                            this.props.history.location.search,
                            'source_name',
                            'box',
                        )}`,
                    );
                },
            },
        ];
        return (
            <ErrorBoundary>
                <div className={className}>
                    <Helmet title="Content" />
                    <header className="knowledge-base__toolbar">
                        <div className="knowledge-base-search__container">
                            <LibraryFilters
                                filters={filters}
                                active={activeFilter}
                                reloadData={this.reloadData}
                                showUploadModal={this.state.showUploadModal}
                                setShowUploadModal={this.setShowUploadModal}
                                addSource={() => {
                                    this.props.history.push(
                                        '/settings/knowledge-management',
                                    );
                                }}
                            />
                            <div className="knowledge-base-search__container-top">
                                <div className="knowledge-base__toolbar-left">
                                    {isShowingArchived ? (
                                        <div className="knowledge-base-search__counter">
                                            <div className="knowledge-base-search__counter-arrow">
                                                <KeyboardArrowLeft
                                                    size={24}
                                                    onClick={
                                                        this
                                                            .handleClickShowActive
                                                    }
                                                />
                                            </div>
                                            <div className="knowledge-base-search__counter-text">
                                                <h1 className="kare-h4">
                                                    {areItemsSelected
                                                        ? numberOfSelectedRows
                                                        : matchingItems.length}{' '}
                                                    Archived item
                                                    {((areItemsSelected &&
                                                        numberOfSelectedRows >
                                                            1) ||
                                                        (!areItemsSelected &&
                                                            matchingItems.length >
                                                                1)) &&
                                                        's'}
                                                    {areItemsSelected
                                                        ? ' selected'
                                                        : ''}
                                                </h1>

                                                <span
                                                    className={
                                                        'knowledge-base-search__counter-text-info'
                                                    }
                                                >
                                                    Archived content will not be
                                                    searched or used to answer
                                                    your customers. <br />
                                                    Archived content is still
                                                    available on linked
                                                    responses.
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                'knowledge-base-search__header'
                                            }
                                        >
                                            <h1 className="kare-h2">
                                                {areItemsSelected
                                                    ? numberOfSelectedRows
                                                    : matchingItems.length}{' '}
                                                {activeFilter === 'uploads'
                                                    ? 'Document'
                                                    : 'Imported item'}
                                                {((areItemsSelected &&
                                                    numberOfSelectedRows > 1) ||
                                                    (!areItemsSelected &&
                                                        matchingItems.length >
                                                            1)) &&
                                                    's'}
                                                {areItemsSelected &&
                                                    ' selected'}
                                            </h1>
                                        </div>
                                    )}
                                    {activeFilter === 'uploads' && (
                                        <Button
                                            onClick={() => {
                                                this.setShowUploadModal(true);
                                            }}
                                            icon={MdCloudUpload}
                                        >
                                            New Upload
                                        </Button>
                                    )}
                                </div>
                                <div className="knowledge-base__toolbar-right">
                                    {!areItemsSelected && (
                                        <SearchBar
                                            showFilterButton={
                                                !this.state.showFilterButton
                                            }
                                            onChange={this.handleQueryChange}
                                            filterButtonHandler={
                                                this.toggleFilterButton
                                            }
                                            value={query}
                                        />
                                    )}
                                    {!isShowingArchived &&
                                        this.props.selectedRows.length > 0 && (
                                            <Button
                                                outlined
                                                onClick={
                                                    this
                                                        .handleClickArchiveSelected
                                                }
                                            >
                                                Archive selected
                                            </Button>
                                        )}
                                    {isShowingArchived &&
                                        this.props.selectedRows.length > 0 && (
                                            <Button
                                                outlined
                                                onClick={
                                                    this
                                                        .handleClickUnarchiveSelected
                                                }
                                            >
                                                Unarchive selected
                                            </Button>
                                        )}
                                    {numberOfSelectedRows > 0 && (
                                        <Button
                                            outlined
                                            onClick={
                                                this.handleClickDeselectAll
                                            }
                                        >
                                            Deselect all
                                        </Button>
                                    )}
                                </div>
                            </div>
                            {(this.state.showFilterButton ||
                                this.state.labelsToFilter.length > 0) && (
                                <div className="knowledge-base-search__bottom">
                                    <span>Filter by label</span>
                                    <FormLessTagsInput
                                        selectedTags={this.state.labelsToFilter}
                                        setSelectedTags={this.setLabelsToFilter}
                                    />
                                </div>
                            )}
                        </div>
                    </header>
                    <KareStatusTabs tabs={statusTabs} />
                    {documents.length === 0 && !documentsLoading ? (
                        <NoContent />
                    ) : (
                        this.renderDocumentsTable()
                    )}
                    <Switch>
                        <Route
                            path="/library/:documentId"
                            render={() => {
                                return <DocumentPortal />;
                            }}
                        />
                    </Switch>
                </div>
            </ErrorBoundary>
        );
    }
}

/**
 * Map redux store state to props for the component.
 * Get the knowledgeBase search query.
 * @param {Object} state
 * @param {Object} props
 */
const mapStateToProps = (state, props) => {
    return {
        contentLocale: redux.locale.selectors.contentLocaleSelector(state),
        status: querystring.getStatus(props.location.search),
        query: redux.knowledgeBase.selectors.searchQuerySelector(state),
        publishedDocuments:
            redux.knowledgeBase.selectors.publishedDocumentsSelector(state),
        archivedDocuments:
            redux.knowledgeBase.selectors.archivedDocumentsSelector(state),
        allContentError:
            redux.knowledgeBase.selectors.allContentErrorSelector(state),
        isLoading:
            redux.org.selectors.isOrgLoadingSelector(state) ||
            redux.application.selectors.isApplicationLoadingSelector(state),
        documentsLoading:
            redux.knowledgeBase.selectors.publishedDocumentsLoadingSelector(
                state,
            ) ||
            redux.knowledgeBase.selectors.archivedDocumentsLoadingSelector(
                state,
            ),
        selectedRows:
            redux.documentsTable.selectors.selectedRowsSelector(state),
    };
};

export const bulkUpdateStatus = ({dispatch, ids, status}) => {
    return dispatch(
        redux.rest.actions[redux.documents.constants.BULK_UPDATE_DOCUMENTS](
            {},
            {
                body: JSON.stringify({
                    nodes: ids.map((id) => {
                        return {
                            id,
                            [STATUS]: status,
                            [FIELD_MASKS]: [STATUS],
                        };
                    }),
                }),
            },
        ),
    );
};

/**
 * Map action dispatching to component props.
 * Get application and organisation.
 * @param {Function} dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return {
        searchKnowledgeBase: (query) =>
            dispatch(redux.knowledgeBase.actions.searchKnowledgeBase(query)),
        getAllPublishedDocuments: (source) =>
            redux.knowledgeBase.dispatchers.getAllPublishedDocumentsDispatcher(
                dispatch,
            )({source}),
        getAllArchivedDocuments: (source) =>
            redux.knowledgeBase.dispatchers.getAllArchivedDocumentsDispatcher(
                dispatch,
            )({source}),
        resetSelectedRows: () =>
            dispatch(redux.documentsTable.actionCreators.resetSelectedRows()),
        bulkArchiveDocuments: (ids) => {
            return bulkUpdateStatus({
                dispatch,
                ids,
                status: NODE_STATUSES.DRAFT,
            });
        },
        bulkUnarchiveDocuments: (ids) => {
            return bulkUpdateStatus({
                dispatch,
                ids,
                status: NODE_STATUSES.PUBLISHED,
            });
        },
    };
};

export const Documents = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DocumentsComponent);
