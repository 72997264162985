import React, {useEffect, useState} from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import {Spinner, RadioInput, Button} from '@app/common';
import cx from 'classnames';
import {MdFlashOn} from 'react-icons/md';
import {BLOCK_KEYS, BLOCK_TYPES} from '@app/constants';
import {get} from 'lodash';
import axios from 'axios';

interface ExtensionCreationModalProps {
    currentExtension?: any;
    addExtension?: any;
    closeModal?: any;
}

export const ExtensionCreationModal = (props: ExtensionCreationModalProps) => {
    const {currentExtension, addExtension, closeModal} = props;

    const [search] = useState('');
    const [message, setMessage] = useState('');
    const [CTA, setCTA] = useState('');
    const [selectedAction, setSelectedAction] = useState<any>({name: null});
    const [type, setType] = useState('dialog');
    const [extensions, setExtensions] = useState([]);
    useEffect(() => {
        const webextBlock = get(currentExtension, 'webext_block', null);
        const target = get(webextBlock, 'target');
        if (target) {
            setType(target);
        }
        axios.get(`v3.0/kbm/web-extensions`).then((r) => {
            const extensions = get(r, 'data');
            if (extensions && extensions.length > 0) {
                setExtensions(extensions);
            }
        });
        if (webextBlock) {
            setSelectedAction(webextBlock);
            setMessage(webextBlock.text);
            setCTA(webextBlock.call_to_action);
        }
    }, []);

    const loading = false;

    const handleAddExtension = (e) => {
        e.preventDefault();
        addExtension({
            type: BLOCK_TYPES.EXTENSION,
            [BLOCK_KEYS.EXTENSION_BLOCK]: {
                call_to_action: CTA,
                target: type,
                text: message,
                url: selectedAction.url,
            },
        });
        closeModal();
    };

    const shouldAddButtonBeDisabled = () => {
        return !(!!message && !!get(selectedAction, 'url') && !!CTA);
    };

    return (
        <div className="extension-creation-modal">
            <h2>Select an action to add</h2>
            <div className="extension-creation-modal__content">
                <div className="extension-creation-modal__responses">
                    {/* <h3>All responses</h3>
          <div className="extension-creation-modal__responses-search">
            <label htmlFor="response-search-text">
              <Search size={18} />
            </label>
            <input
              id="response-search-text"
              type="text"
              placeholder="Search in your responses"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </div> */}
                    <ScrollBar>
                        {loading ? (
                            <Spinner />
                        ) : extensions && extensions.length > 0 ? (
                            <div>
                                {extensions
                                    .filter((e) =>
                                        e.title
                                            .toLowerCase()
                                            .includes(search.toLowerCase()),
                                    )
                                    .map((e) => {
                                        return (
                                            <div
                                                key={e.name}
                                                className={cx(
                                                    'extension-creation-modal__response',
                                                    {
                                                        selected:
                                                            e.url ===
                                                            selectedAction.url,
                                                    },
                                                )}
                                                onClick={() => {
                                                    setSelectedAction(e);
                                                    setCTA(e.title);
                                                }}
                                            >
                                                <MdFlashOn size={16} />
                                                <span>{e.title}</span>
                                            </div>
                                        );
                                    })}
                            </div>
                        ) : (
                            <div>No extensions</div>
                        )}
                    </ScrollBar>
                </div>
                <div className="extension-creation-modal__detail">
                    <h3>Customize your messages</h3>
                    <textarea
                        placeholder="Type your message here"
                        name="message"
                        id="message"
                        cols={30}
                        rows={10}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <input
                        placeholder="Type your button call to action here"
                        type="text"
                        name="cta"
                        value={CTA}
                        onChange={(e) => setCTA(e.target.value)}
                    />
                    <div className="extension-creation-modal__text">
                        <MdFlashOn size={20} />
                        <span>{CTA || 'Type your call to action above'}</span>
                    </div>

                    <div className="extension-creation-modal__radio-group">
                        <RadioInput
                            radioGroup={'actionType'}
                            label={'Open in a modal'}
                            id={'dialog'}
                            onClick={() => {
                                setType('dialog');
                            }}
                            input={{checked: type === 'dialog'}}
                        />
                        <RadioInput
                            radioGroup={'actionType'}
                            label={'Open in a new tab'}
                            id={'blank'}
                            onClick={() => {
                                setType('blank');
                            }}
                            input={{checked: type === 'blank'}}
                        />
                        <RadioInput
                            radioGroup={'actionType'}
                            label={'Open in the widget'}
                            id={'self'}
                            onClick={() => {
                                setType('self');
                            }}
                            input={{checked: type === 'self'}}
                        />
                    </div>
                    {CTA && CTA.length > 0 && (
                        <Button
                            outlinedPrimary={true}
                            icon={MdFlashOn}
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                        >
                            {CTA}
                        </Button>
                    )}
                </div>
                <Button
                    onClick={handleAddExtension}
                    disabled={shouldAddButtonBeDisabled()}
                    className={'extension-creation-modal__create-button'}
                >
                    Add
                </Button>
            </div>
        </div>
    );
};
