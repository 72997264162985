import React from 'react';
import './Consolidate.scss';
import {Broken} from './Broken/Broken';
import {UnderRepresented} from './UnderRepresented/UnderRepresented';
import {useHistory} from 'react-router-dom';
import cx from 'classnames';

interface IConsolidateProps {}

export const Consolidate: React.FunctionComponent<IConsolidateProps> = () => {
    const history = useHistory();
    const getActiveTabBasedOnPathName = (pathname: string) => {
        switch (pathname) {
            case '/consolidate/broken':
                return 'broken';
            case '/consolidate/under':
                return 'under';
            case '/consolidate/over':
                return 'over';
            default:
                return 'broken';
        }
    };

    const renderContentByTab = (tab: string) => {
        switch (tab) {
            case 'broken':
                return <Broken history={history} />;
            case 'under':
                return (
                    <UnderRepresented
                        history={history}
                        nodeType={'under_represented'}
                    />
                );
            case 'over':
                return (
                    <UnderRepresented
                        history={history}
                        nodeType={'over_represented'}
                    />
                );
            default:
                return <Broken history={history} />;
        }
    };

    const changeActiveTab = (active: string) => {
        history.push(`/consolidate/${active}`);
    };

    return (
        <div className="Consolidate__container">
            <h1 className="Consolidate__title">Consolidate</h1>
            <ul className="Consolidate__consolidateTabs">
                <li
                    className={cx({
                        ['active']:
                            getActiveTabBasedOnPathName(
                                history.location.pathname,
                            ) === 'broken',
                    })}
                    onClick={() => {
                        changeActiveTab('broken');
                    }}
                >
                    Broken content
                </li>
                <li
                    className={cx({
                        ['active']:
                            getActiveTabBasedOnPathName(
                                history.location.pathname,
                            ) === 'under',
                    })}
                    onClick={() => {
                        changeActiveTab('under');
                    }}
                >
                    Not enough queries
                </li>
                <li
                    className={cx({
                        ['active']:
                            getActiveTabBasedOnPathName(
                                history.location.pathname,
                            ) === 'over',
                    })}
                    onClick={() => {
                        changeActiveTab('over');
                    }}
                >
                    Too many queries
                </li>
            </ul>
            <div className="Consolidate__content">
                {renderContentByTab(
                    getActiveTabBasedOnPathName(history.location.pathname),
                )}
            </div>
        </div>
    );
};
