import {METHODS} from '../constants';
import {
    LOGIN,
    UPDATE_USER,
    RECOVER_PASSWORD,
    PASSWORD_RECOVER_CONFIRM,
    GET_LOGGED_IN_USER,
    GET_USERS,
    REFRESH_TOKEN,
    CREATE_USER,
    DELETE_USER,
    UPDATE_EMAIL,
    UPDATE_LOGGED_IN_USER,
    GET_USER,
} from './constants';

const {POST, PATCH, GET, DELETE} = METHODS;

export const rest = {
    [LOGIN]: {
        url: '/oauth/token',
        options: {
            method: POST,
        },
    },
    [UPDATE_LOGGED_IN_USER]: {
        url: '/v3.1/iam/users/me',
        options: {
            method: PATCH,
        },
    },
    [UPDATE_EMAIL]: {
        url: '/v3.1/iam/users/me',
        options: {
            method: PATCH,
        },
    },
    [UPDATE_USER]: {
        url: '/v3.1/iam/users/:id',
        options: {
            method: PATCH,
        },
    },
    [RECOVER_PASSWORD]: {
        url: 'v3.0/settings/user/recover',
        options: {
            method: POST,
        },
    },
    [PASSWORD_RECOVER_CONFIRM]: {
        url: 'v3.0/settings/user/recover/confirm',
        options: {
            method: POST,
        },
    },
    [GET_USER]: {
        url: '/v3.1/iam/users/:id',
        options: {
            method: GET,
        },
    },
    [GET_LOGGED_IN_USER]: {
        url: '/v3.1/iam/users/me',
        options: {
            method: GET,
        },
    },
    [GET_USERS]: {
        url: '/v3.1/iam/users',
        options: {
            method: GET,
        },
    },
    [DELETE_USER]: {
        url: '/v3.1/iam/users/:id',
        options: {
            method: DELETE,
        },
    },
    [CREATE_USER]: {
        url: '/v3.1/iam/users',
        options: {
            method: POST,
        },
    },
    [REFRESH_TOKEN]: {
        url: '/v3.1/auth/refresh',
        options: {
            method: POST,
        },
    },
};
