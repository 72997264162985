import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router';
import {ErrorBoundary} from '@app/common';
import {redux} from '@app/redux';
import {changeSelectedConversation} from '@app/redux/conversations/actions';
import {
    GET_CONVERSATION_MESSAGES,
    GET_CONVERSATIONS,
} from '@app/redux/conversations/constants';
import {
    areConversationsFetchingSelector,
    conversationsEndListSelector,
    conversationsErrorSelector,
    conversationsNextPageSelector,
    conversationsSelector,
    getSelectedConversationId,
} from '@app/redux/conversations/selectors';
import {getValueByKey, appendQuerystring} from '@app/utils/querystring';
import {ConversationsHeader} from './conversations_header';
import {ConversationListHeader} from './conversations_list_header';
import {ConversationList} from './conversation_list';

interface ConversationsContainerProps {
    history?: any;
    location?: any;
}

const ConversationsContainer = (props: ConversationsContainerProps) => {
    const {
        history,
        location: {search},
    } = props;

    const batchSize = 100;
    const next_cursor = useSelector((state) =>
        conversationsNextPageSelector(state),
    );
    const conversationsError = useSelector((state) =>
        conversationsErrorSelector(state),
    );
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const isEndList = useSelector((state) =>
        conversationsEndListSelector(state),
    );
    const selectedConversationId = useSelector((state) =>
        getSelectedConversationId(state),
    );
    const isRefreshing = useSelector((state) =>
        areConversationsFetchingSelector(state),
    );
    const [filters, setFilters] = useState([]);
    const conversations = useSelector((state) => conversationsSelector(state));
    const dispatch = useDispatch();

    const handleConversationPermalink = () => {
        const id = getValueByKey(search, 'id');
        if (id !== undefined) {
            handleClickConversation(id);
        }
    };

    const handleClickConversation = (id) => {
        dispatch(changeSelectedConversation(id));
        history.push(appendQuerystring(search, {id: id}));
    };

    // If the optional url param `user_id` is given pass it to the
    // request builder so that conversations are filtered.
    // XXX(msama): this is a temporay implementation while we build
    //             the filter UI.
    const queryUserId = getValueByKey(search, 'user_id');

    const fetchConversations = (cursor = undefined) => {
        dispatch(
            redux.rest.actions[GET_CONVERSATIONS]({
                user_id: queryUserId, // optional filter on `user_id`.
                next_cursor: cursor,
                limit: batchSize,
            }),
        ).catch((error) => {
            if (error === 'isLoading') return;
            console.error('fetchConversations error', error);
        });
    };

    const loadMorePages = () => {
        if (!isRefreshing && !isEndList && !conversationsError) {
            fetchConversations(next_cursor);
        }
        setIsLoadingMore(true);
    };

    useEffect(() => {
        fetchConversations();
        handleConversationPermalink();
    }, []);

    useEffect(() => {
        if (
            !selectedConversationId &&
            conversations &&
            conversations.length > 0
        ) {
            dispatch(changeSelectedConversation(conversations[0].id));
        }
        if (conversations.length < 10 && !isRefreshing && !isLoadingMore) {
            loadMorePages();
        }
    }, [conversations, isRefreshing, isLoadingMore]);

    useEffect(() => {
        if (selectedConversationId) {
            history.push(
                appendQuerystring(search, {id: selectedConversationId}),
            );
        }
    }, [selectedConversationId]);

    return (
        <ErrorBoundary>
            <section className="console-activity-conversations">
                <ConversationsHeader
                    conversations={conversations}
                    isLoadingMore={isLoadingMore}
                    isRefreshing={isRefreshing}
                    onRefresh={() => {
                        if (!isRefreshing) {
                            fetchConversations('');
                            dispatch(
                                redux.rest.actions[
                                    GET_CONVERSATION_MESSAGES
                                ].force({id: selectedConversationId}),
                            );
                        }
                    }}
                />
                <section
                    id="conversations-list-container"
                    className="console-activity-conversations-list"
                >
                    <ConversationListHeader
                        filters={filters}
                        onFilterClick={(filter) => {
                            if (filters.includes(filter)) {
                                setFilters(filters.filter((f) => f !== filter));
                            } else {
                                setFilters([...filters, filter]);
                            }
                        }}
                    />
                    <ConversationList
                        conversations={conversations}
                        filters={filters}
                        selectedConversationId={selectedConversationId}
                        onConversationClicked={handleClickConversation}
                        isRefreshing={isRefreshing}
                        isLoadingMore={isLoadingMore}
                        isEndList={isEndList}
                        onLoadMore={loadMorePages}
                        onEndListReached={() => {
                            if (filters.length === 0) {
                                loadMorePages();
                            }
                        }}
                    />
                </section>
            </section>
        </ErrorBoundary>
    );
};

export const Conversations = withRouter(ConversationsContainer);
