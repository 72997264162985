import {get, values} from 'lodash';
import {DATA, ERROR, LOADING} from '../../constants';
import {
    FEEDBACK_STATE_FIELD,
    GET_CX_METRIC_ASPECT_FEEDBACKS,
    SELECTED_ASPECT,
    META_DATA,
    SELECTED_MESSAGE,
    SELECTED_METRIC_ID,
    SELECTED_MAX_DATE,
    SELECTED_MIN_DATE,
    GET_AGGREGATED_FEEDBACK,
} from '../constants';

export const aspectFeedbacksSelector = (state) => {
    const feedbacksObject = get(
        state,
        [GET_CX_METRIC_ASPECT_FEEDBACKS, DATA, 'events'],
        [],
    );
    return values(feedbacksObject).sort((a, b) => b.created_at - a.created_at);
};

export const areAspectFeedbackMessagesFetchingSelector = (state) => {
    return get(state, [GET_CX_METRIC_ASPECT_FEEDBACKS, LOADING]) === true;
};

export const getSelectedMessage = (state) => {
    return get(state, [FEEDBACK_STATE_FIELD, SELECTED_MESSAGE]);
};

export const getSelectedAspect = (state) => {
    return get(state, [FEEDBACK_STATE_FIELD, SELECTED_ASPECT]);
};

export const getSelectedMetricId = (state) => {
    return get(state, [FEEDBACK_STATE_FIELD, SELECTED_METRIC_ID]);
};

export const getSelectedMinDate = (state) => {
    return get(state, [FEEDBACK_STATE_FIELD, SELECTED_MIN_DATE]);
};

export const getSelectedMaxDate = (state) => {
    return get(state, [FEEDBACK_STATE_FIELD, SELECTED_MAX_DATE]);
};

export const getFeedbackMessagesByMetricId = (state) => {
    const selectedMetricId = getSelectedMetricId(state);
    const feedbacksObject = get(
        state,
        [GET_CX_METRIC_ASPECT_FEEDBACKS, DATA, 'events'],
        [],
    ).filter((feedback) => selectedMetricId === feedback.body.metric_id);
    return values(feedbacksObject).sort((a, b) => b.created_at - a.created_at);
};

export const getSelectedAspectFeedbacksMetadata = (state) => {
    return get(state, [
        GET_CX_METRIC_ASPECT_FEEDBACKS,
        DATA,
        FEEDBACK_STATE_FIELD,
        getSelectedAspect(state),
        META_DATA,
    ]);
};

export const getAggregatedFeedbackSelector = (state) => {
    return get(state, [GET_AGGREGATED_FEEDBACK, DATA]);
};

export const isAggregatedFeedbackFetchingSelector = (state) => {
    return get(state, [GET_AGGREGATED_FEEDBACK, LOADING]);
};

export const aggregatedFeedbackErrorSelector = (state) => {
    return get(state, [GET_AGGREGATED_FEEDBACK, ERROR]);
};
