import {
    MdAccessTime,
    MdAdd,
    MdArchive,
    MdArrowDropDown,
    MdArrowDropUp,
    MdArrowBack,
    MdAttachFile,
    MdAttachment,
    MdAutorenew,
    MdBeenhere,
    MdChat,
    MdChatBubble,
    MdCheck,
    MdCheckBox,
    MdCheckCircle,
    MdChevronLeft,
    MdChevronRight,
    MdClose,
    MdCreate,
    MdDelete,
    MdDone,
    MdLabel,
    MdEdit,
    MdEmail,
    MdExitToApp,
    MdHelp,
    MdHelpOutline,
    MdInsertDriveFile,
    MdKeyboardArrowDown,
    MdKeyboardArrowLeft,
    MdLink,
    MdLocalPhone,
    MdModeComment,
    MdMoreVert,
    MdOpenInNew,
    MdPerson,
    MdCheckBoxOutlineBlank,
    MdRefresh,
    MdRemove,
    MdSms,
    MdSettings,
    MdSchool,
    MdSearch,
    MdSentimentDissatisfied,
    MdThumbDown,
    MdThumbUp,
    MdTimer,
    MdAssessment,
    MdFormatQuote,
    MdInbox,
    MdQuestionAnswer,
    MdClear,
    MdWarning,
    MdVisibility,
    MdSend,
    MdContentCopy,
    MdFileDownload,
    MdLibraryBooks,
    MdPlayArrow,
    MdError,
    MdLaunch,
    MdFastForward,
    MdFastRewind,
    MdAccountCircle,
    MdVerifiedUser,
    MdInsertChart,
    MdHourglassEmpty,
    MdArrowUpward,
    MdArrowDownward,
    MdShortText,
    MdMessage,
    MdImage,
    MdVideocam,
    MdAddCircleOutline,
    MdAddCircle,
    MdTextsms,
} from 'react-icons/md';
import {FaQuestionCircle, FaTrash} from 'react-icons/fa';
import {Warning} from './warning';

export {MdTextsms as Textsms};
export {MdAddCircle as AddCircle};
export {MdAddCircleOutline as AddCircleOutline};
export {MdVideocam as Videocam};
export {MdImage as Image};
export {MdMessage as Message};
export {MdShortText as ShortText};
export {MdArrowUpward as ArrowUpward};
export {MdArrowDownward as ArrowDownward};
export {MdHourglassEmpty as HourglassEmpty};
export {MdVerifiedUser as VerifiedUser};
export {MdAccessTime as AccessTime};
export {MdAdd as Add};
export {MdArrowDropDown as ArrowDropDown};
export {MdArrowDropUp as ArrowDropUp};
export {MdArrowBack as ArrowBack};
export {MdAssessment as Assessment};
export {MdArchive as Archive};
export {MdAttachFile as AttachFile};
export {MdAttachment as Attachment};
export {MdAutorenew as AnotherResponse};
export {MdBeenhere as Beenhere};
export {MdChat as Chat};
export {MdCheck as Check};
export {MdCheckBox as CheckBox};
export {MdCheckCircle as CheckCircle};
export {MdChevronLeft as ChevronLeft};
export {MdChevronRight as ChevronRight};
export {MdClear as Clear};
export {MdClose as Close};
export {MdCreate as Create};
export {MdDelete as Delete};
export {MdDone as Done};
export {MdLabel as Label};
export {MdEmail as Email};
export {MdError as Error};
export {MdExitToApp as ExitToApp};
export {MdHelp as Help};
export {MdHelpOutline as HelpOutline};
export {MdInbox as Inbox};
export {MdKeyboardArrowDown as KeyboardArrowDown};
export {MdKeyboardArrowLeft as KeyboardArrowLeft};
export {MdLink as Link};
export {MdLaunch as Launch};
export {MdLocalPhone as LocalPhone};
export {MdModeComment as ModeComment};
export {MdPerson as Person};
export {MdPlayArrow as PlayArrow};
export {MdCheckBoxOutlineBlank as CheckBoxOutlineBlank};
export {MdRefresh as Refresh};
export {MdRemove as Remove};
export {MdSettings as Settings};
export {MdSentimentDissatisfied as SentimentDissatisfied};
export {MdChatBubble as ChatBubble};
export {MdLibraryBooks as Document};
export {MdEdit as Edit};
export {MdQuestionAnswer as QuestionAnswer};
export {MdSchool as Improve};
export {MdSchool as School};
export {MdSearch as Search};
export {MdSend as Send};
export {MdOpenInNew as OpenDocument};
export {MdMoreVert as OptionsTooltip};
export {MdSms as Sms};
export {MdThumbDown as ThumbDown};
export {MdThumbUp as ThumbUp};
export {MdTimer as Timer};
export {MdInsertDriveFile as InsertDriveFile};
export {Warning as BannerWarning};
export {MdWarning as Warning};
export {MdVisibility as Visibility};
export {MdFormatQuote as FormatQuote};
export {FaQuestionCircle as QuestionCircle};
export {MdContentCopy as Clone};
export {MdFileDownload as FileDownload};
export {MdFastForward as FastForward};
export {MdFastRewind as FastRewind};
export {MdAccountCircle as AccountCircle};
export {FaTrash as Trash};
export {MdInsertChart as InsertChart};

export {Poweredby} from './poweredby';
export {Bounced} from './bounced';
export {Zendesk} from './zendesk';
export {Launcher} from './launcher';
export {Response} from './response';
export {CollapsedResponses} from './collapsed-responses'; // TODO(rupertrutland): fix this icon. It's not sized like others.
export {ChatBubbleCheck} from './chat-bubble-check';
export {Automation} from './automation';
export {BrandedFABIcon} from './branded-fab-icon';
