import {reducer} from './reducer';
import * as actions from './actions';
import * as selectors from './selectors';
import * as dispatchers from './dispatchers';
import * as constants from './constants';
import {rest} from './rest';

export const knowledgeBase = {
    actions,
    reducer,
    selectors,
    dispatchers,
    rest,
    constants,
};
