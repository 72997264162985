import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Switch} from '@material-ui/core';
import {Button} from '@app/common';
import {MdKeyboardArrowDown} from 'react-icons/md';

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.common.white,
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: '#00B957',
                borderColor: '#00B957',
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.grey[500],
    },
    checked: {},
}))(Switch);

export const FilterMenu = () => {
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedC: true,
        checkedD: true,
    });

    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.checked});
    };

    return (
        <div className="filter-modal">
            <div className="filter-header">
                <div className="clear">
                    <Button className="filter-button" outlined>
                        <span className="label">Clear</span>
                    </Button>
                </div>
                <div className="title">Filter</div>
                <div className="done">
                    <Button className="filter-button" outlined>
                        <span className="label">Done</span>
                    </Button>
                </div>
            </div>
            <div className="filter-body">
                <div className="filter-item">
                    <div className="filter-switch">
                        <AntSwitch
                            checked={state.checkedA}
                            onChange={handleChange}
                            name="checkedA"
                        />
                    </div>

                    <div className="filter-label">
                        <span>Username</span>
                    </div>
                    <div className="filter-collapse">
                        <MdKeyboardArrowDown size={32} />
                    </div>
                </div>
                <div className="filter-item">
                    <div className="filter-switch">
                        <AntSwitch
                            checked={state.checkedB}
                            onChange={handleChange}
                            name="checkedB"
                        />
                    </div>

                    <div className="filter-label">
                        <span>Time</span>
                    </div>
                    <div className="filter-collapse">
                        <MdKeyboardArrowDown size={32} />
                    </div>
                </div>
                <div className="filter-item">
                    <div className="filter-switch">
                        <AntSwitch
                            checked={state.checkedC}
                            onChange={handleChange}
                            name="checkedC"
                        />
                    </div>

                    <div className="filter-label">
                        <span>Score</span>
                    </div>
                    <div className="filter-collapse">
                        <MdKeyboardArrowDown size={32} />
                    </div>
                </div>
                <div className="filter-item">
                    <div className="filter-switch">
                        <AntSwitch
                            checked={state.checkedD}
                            onChange={handleChange}
                            name="checkedD"
                        />
                    </div>

                    <div className="filter-label">
                        <span>Audience</span>
                    </div>
                    <div className="filter-collapse">
                        <MdKeyboardArrowDown size={32} />
                    </div>
                </div>
            </div>
        </div>
    );
};
