import {get} from 'lodash';
import {LOADING, DATA, ERROR} from '../../constants';
import {
    GET_AGGREGATED_QUERIES_TIMESERIES,
    GET_QUERIES_TIMESERIES,
} from '../constants';

export const getAggregatedQueriesTimeseriesSelector = (state) => {
    return get(state, [GET_AGGREGATED_QUERIES_TIMESERIES, DATA]);
};

export const isAggregatedQueriesTimeseriesFetchingSelector = (state) => {
    return get(state, [GET_AGGREGATED_QUERIES_TIMESERIES, LOADING]);
};

export const aggregatedQueriesTimeseriesErrorSelector = (state) => {
    return get(state, [GET_AGGREGATED_QUERIES_TIMESERIES, ERROR]);
};

export const getQueriesTimeseriesSelector = (state) => {
    return get(state, [GET_QUERIES_TIMESERIES, DATA]);
};

export const isQueriesTimeseriesFetchingSelector = (state) => {
    return get(state, [GET_QUERIES_TIMESERIES, LOADING]);
};

export const queriesTimeseriesErrorSelector = (state) => {
    return get(state, [GET_QUERIES_TIMESERIES, ERROR]);
};
