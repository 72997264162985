import {METHODS} from '../constants';

import {CONTACT_US} from './constants';

const {POST} = METHODS;

export const rest = {
    [CONTACT_US]: {
        url: '/v3.1/help/contact-us',
        options: {
            method: POST,
        },
    },
};
