import {
    APPEND_MUTED_QUERIES_ACTION_TYPE,
    MUTED_QUERIES,
    QUERY_ARRAY_OF_FIELDS,
    REMOVE_ALL_SELECTED_QUERIES_ACTION_TYPE,
    REMOVE_MUTED_QUERIES_ACTION_TYPE,
} from '../constants';

const initialState = {
    [MUTED_QUERIES]: [],
    values: {},
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_ALL_SELECTED_QUERIES_ACTION_TYPE: {
            return {
                ...state,
                values: {
                    [QUERY_ARRAY_OF_FIELDS]: state.values[
                        QUERY_ARRAY_OF_FIELDS
                    ].map((q) => {
                        q.checked = false;
                        return q;
                    }),
                },
            };
        }
        case APPEND_MUTED_QUERIES_ACTION_TYPE: {
            return {
                ...state,
                [MUTED_QUERIES]: [
                    ...state[MUTED_QUERIES],
                    ...action.payload.MUTED_QUERIES,
                ],
            };
        }
        case REMOVE_MUTED_QUERIES_ACTION_TYPE: {
            const allMutedQueries = [...state[MUTED_QUERIES]];
            const mutedQueriesToDelete = [...action.payload.MUTED_QUERIES];
            mutedQueriesToDelete.forEach((mQ) => {
                const index = allMutedQueries.map((a) => a.id).indexOf(mQ);
                if (index > -1) {
                    allMutedQueries.splice(index, 1);
                }
            });

            return {
                ...state,
                [MUTED_QUERIES]: allMutedQueries,
            };
        }
        default:
            return state;
    }
};
