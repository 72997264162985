import React from 'react';
import {DefaultLinkFactory} from '@projectstorm/react-diagrams-defaults';

import {AdvancedLinkWidget} from './AdvancedLinkWidget';
import {AdvancedLinkModel} from './AdvancedLinkModel';

export class AdvancedLinkFactory extends DefaultLinkFactory {
    constructor() {
        super('advanced');
    }

    generateModel(): AdvancedLinkModel {
        return new AdvancedLinkModel();
    }

    generateReactWidget(event): JSX.Element {
        return (
            <AdvancedLinkWidget
                link={event.model}
                diagramEngine={this.engine}
            />
        );
    }
}
