import React from 'react';
import {Link} from 'react-router-dom';

import {School} from '@app/common/icons';

export class InactiveState extends React.Component {
    render() {
        return (
            <div className="kare-empty-state">
                <School size={60} />
                <h1>Manage your self service with cognitive insights</h1>
                <p>
                    Once you start using your self service assistance, we will
                    analyse the traffic and present you actionable cognitive
                    insights. You can improve your customer’s self service
                    experience and accuracy of your answers data driven, by
                    taking actions on the cognitive insight cards.
                </p>
                <p>Publish your widget to start self serving your customers.</p>
                <Link className="c-btn" to="/settings/widget">
                    Publish widget
                </Link>
            </div>
        );
    }
}
