import React, {useState, useEffect} from 'react';
import {MdSchool} from 'react-icons/md';
import ChatBubble from '@material-ui/icons/ChatBubble';
import {Spinner} from '@app/common';
import cx from 'classnames';

import './Knowledge.scss';
import {ClusterResponse} from '@app/types/Clusters';
import {Empty} from '@app/components/Empty/Empty';
import {DATA_KEYS, getChartColor} from '@app/utils/DataRenderingUtils';
import {StoreContext} from '@app/state';
import {NodeResponse} from '@app/types/Nodes';

interface IKnowledgeProps {
    history: any;
}
const defaultInitialDataState = {
    data: [] as ClusterResponse[] | null,
    error: null,
    loading: true,
};
export const Knowledge: React.FunctionComponent<IKnowledgeProps> = ({
    history,
}) => {
    const [clustersData, setClustersData] = useState(defaultInitialDataState);

    const [filter, setFilter] = useState({
        improve: false,
        choice: false,
        quote: false,
    });
    const {clusters} = React.useContext(StoreContext);

    const getImproveAnswerText = (clusterType: string) => {
        switch (clusterType) {
            case DATA_KEYS.DISAMBIGUATION:
                return 'Choices';
            case DATA_KEYS.AUTO_ANSWER:
                return 'Content used';
            case DATA_KEYS.IMPROVE_ANSWER:
            default:
                return 'Your response';
        }
    };

    const getClusters = () => {
        setClustersData({...clustersData, loading: true});
        clusters
            .getClusters(true)
            .then((res) => {
                setClustersData({
                    loading: false,
                    data: res.data,
                    error: res.error,
                });
            })
            .catch((error) => {
                if (error === 'isLoading') return;
                console.warn('Caught Error -> ', error);
            });
    };

    useEffect(() => {
        getClusters();
    }, []);

    const renderResponseInfo = (cluster: ClusterResponse) => {
        if (cluster.cluster_type === DATA_KEYS.DISAMBIGUATION) {
            const firstTwoNodes = cluster.nodes.slice(0, 2);
            const restOfNodes = cluster.nodes.slice(2);

            return (
                <div
                    className="knowledge__choicesInfo"
                    style={{marginBottom: restOfNodes.length > 0 ? 10 : 0}}
                >
                    {firstTwoNodes.map((n) => {
                        return (
                            <span>
                                <ChatBubble />
                                {n.title}
                            </span>
                        );
                    })}
                    {restOfNodes.length > 0 && (
                        <div className="knowledge__choicesMore">
                            + {restOfNodes.length} more
                        </div>
                    )}
                </div>
            );
        }
        if (cluster.cluster_type === DATA_KEYS.AUTO_ANSWER) {
            const node: NodeResponse | null =
                cluster.nodes && cluster.nodes.length > 0
                    ? cluster.nodes[0]
                    : null;
            return (
                <div className="knowledge__improveInfo">
                    <strong>
                        <ChatBubble />
                        {node?.title}
                    </strong>
                </div>
            );
        }
        if (cluster.cluster_type === DATA_KEYS.IMPROVE_ANSWER) {
            const node =
                cluster.nodes && cluster.nodes.length > 0
                    ? cluster.nodes[0]
                    : null;
            return (
                <div className="knowledge__improveInfo">
                    <strong>
                        <ChatBubble />
                        {node?.title}
                    </strong>
                </div>
            );
        }
        return null;
    };
    const filteredClusters =
        clustersData && clustersData.data
            ? clustersData.data.filter((c) => {
                  if (c.cluster_type === 'missing-knowledge') {
                      return false;
                  }
                  if (!filter.choice && !filter.quote && !filter.improve) {
                      return true;
                  }
                  if (c.cluster_type === DATA_KEYS.IMPROVE_ANSWER) {
                      return filter.improve;
                  }
                  if (c.cluster_type === DATA_KEYS.AUTO_ANSWER) {
                      return filter.quote;
                  }
                  if (c.cluster_type === DATA_KEYS.DISAMBIGUATION) {
                      return filter.choice;
                  }
                  return false;
              })
            : [];
    const getClusterHeader = (cluster: ClusterResponse, numQueries: number) => {
        const queriesWord = numQueries === 1 ? 'query is' : 'queries are';
        const nodesWord =
            cluster.nodes.length === 1
                ? `${cluster.nodes.length} option`
                : `${cluster.nodes.length} options`;
        switch (cluster.cluster_type) {
            case DATA_KEYS.AUTO_ANSWER:
                return `${numQueries} ${queriesWord} answered automatically with a content`;
            case DATA_KEYS.DISAMBIGUATION:
                return `${numQueries} ${queriesWord} answered with ${nodesWord}`;
            case DATA_KEYS.IMPROVE_ANSWER:
            default:
                return `${numQueries} ${queriesWord} answered with your response`;
        }
    };

    return (
        <div className="knowledge__container">
            {clustersData.loading ? (
                <Spinner />
            ) : !clustersData.data ? (
                <h3>We are currently processing your queries</h3>
            ) : clustersData.data.length === 0 ? (
                <Empty
                    icon={MdSchool}
                    header={'There are no responses that need your approval'}
                    subheader={''}
                />
            ) : (
                <>
                    <div className="knowledge__filters">
                        <strong>Show Only</strong>
                        <ul>
                            <li
                                key="improve"
                                onClick={() => {
                                    setFilter({
                                        ...filter,
                                        improve: !filter.improve,
                                    });
                                }}
                            >
                                <div
                                    className={cx('knowledge__filterCheckbox', {
                                        ['active']: filter.improve,
                                    })}
                                />
                                <div
                                    className="knowledge__filterIcon"
                                    style={{
                                        backgroundColor: getChartColor(
                                            DATA_KEYS.IMPROVE_ANSWER,
                                        ),
                                    }}
                                />
                                <span>Approval needed</span>
                            </li>
                            <li
                                key="choice"
                                onClick={() => {
                                    setFilter({
                                        ...filter,
                                        choice: !filter.choice,
                                    });
                                }}
                            >
                                <div
                                    className={cx('knowledge__filterCheckbox', {
                                        ['active']: filter.choice,
                                    })}
                                />
                                <div
                                    className="knowledge__filterIcon"
                                    style={{
                                        backgroundColor: getChartColor(
                                            DATA_KEYS.DISAMBIGUATION,
                                        ),
                                    }}
                                />
                                <span>Choice needed</span>
                            </li>
                            <li
                                key="quote"
                                onClick={() => {
                                    setFilter({
                                        ...filter,
                                        quote: !filter.quote,
                                    });
                                }}
                            >
                                <div
                                    className={cx('knowledge__filterCheckbox', {
                                        ['active']: filter.quote,
                                    })}
                                />
                                <div
                                    className="knowledge__filterIcon"
                                    style={{
                                        backgroundColor: getChartColor(
                                            DATA_KEYS.AUTO_ANSWER,
                                        ),
                                    }}
                                />
                                <span>Approve quote</span>
                            </li>
                        </ul>
                    </div>
                    <div className="knowledge__content">
                        <ul>
                            {filteredClusters.map((c) => {
                                const totalQueries = c.queries.reduce(
                                    (a, b) => a + (b.query_count || 0),
                                    0,
                                );

                                const firstTwoQueries = c.queries.slice(0, 2);
                                const restOfQueries = c.queries.slice(2);

                                return (
                                    <li
                                        key={c.id}
                                        className="knowledge__clusterItem"
                                        onClick={() => {
                                            history.push(
                                                `/improve/${c.id}?origin=/expand/knowledge`,
                                            );
                                        }}
                                    >
                                        <div className="knowledge__header">
                                            <div
                                                className="knowledge__responseIcon"
                                                style={{
                                                    backgroundColor:
                                                        getChartColor(
                                                            c.cluster_type,
                                                        ),
                                                }}
                                            />
                                            <span>
                                                {getClusterHeader(
                                                    c,
                                                    totalQueries,
                                                )}
                                            </span>
                                            <strong>
                                                {getImproveAnswerText(
                                                    c.cluster_type,
                                                )}
                                            </strong>
                                        </div>
                                        <div className="knowledge__responseContent">
                                            <div className="knowledge__queries">
                                                <ul>
                                                    {firstTwoQueries.map(
                                                        (q) => (
                                                            <li>{q.query}</li>
                                                        ),
                                                    )}
                                                </ul>
                                                {restOfQueries.length > 0 && (
                                                    <div className="knowledge__more">
                                                        <div>
                                                            +{' '}
                                                            {
                                                                restOfQueries.length
                                                            }{' '}
                                                            more
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="knowledge__info">
                                                {renderResponseInfo(c)}
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
};
