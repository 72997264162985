import React from 'react';

import './Analysis.scss';
import {Impressions} from './Impressions/Impresions';

interface IAnalysisProps {
    history: any;
}

export const Analysis: React.FunctionComponent<IAnalysisProps> = ({
    history,
}) => {
    return (
        <div className={'Analysis__container'}>
            <Impressions history={history} />
        </div>
    );
};
