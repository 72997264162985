import {map, get} from 'lodash';
import {USER_DATA_STORAGE_KEY, REMEMBER_ME} from '@app/redux/user/constants';
import {PERMISSION_KEYS} from '@app/constants';

export const removeLocalStorage = (keepRememberMe = undefined) => {
    eraseCookie(USER_DATA_STORAGE_KEY);
    eraseCookie('persist:auth');
    eraseCookie('persist:LOCALE');
    eraseCookie('ORG_ID');
    if (!keepRememberMe) {
        eraseCookie(REMEMBER_ME);
    }
};

export const saveOrgIdOnCookies = (orgData) => {
    const orgId = get(orgData, 'id', null);
    if (orgId) {
        setCookie('ORG_ID', orgId);
    }
};

export const saveAuths = (auths) => {
    setCookie('AUTHS', JSON.stringify(auths));
};

export const getAuths = () => {
    return JSON.parse(getCookie('AUTHS'));
};

export const removeOrgIdFromCookies = () => {
    eraseCookie('ORG_ID');
};

export const setCookie = (name, value, days = undefined) => {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
};
export const getCookie = (name) => {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

export const eraseCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

const logout = ({redux, dispatch}) => {
    // Reset everything.
    removeLocalStorage();
    return Promise.all(
        map(redux.rest.actions, (action) => {
            dispatch(action.reset());
        }),
    );
};

export const auth = {logout};

export const hasScope = (scopes, scope) =>
    scopes.find((s) => s === scope || s === PERMISSION_KEYS.MIND.ALL) !==
    undefined;
