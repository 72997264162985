import React, {useState} from 'react';
import {MdClose} from 'react-icons/md';
import {FieldArray} from 'react-final-form-arrays';

import './Labels.scss';
import {Label} from '../text/Label';
import {BorderlessTextInput} from '../inputs/text/BorderlessTextInput';

interface ILabels {}

export const Labels: React.FunctionComponent<ILabels> = () => {
    const [labels, setLabels] = useState<string[]>([]);
    const [value, setValue] = useState('');

    return (
        <div>
            <Label>Labels</Label>
            <div>
                <FieldArray name="labels">
                    {({fields}) => {
                        return (
                            <div className="Labels__container">
                                {fields.map((name, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="Labels__label"
                                        >
                                            {fields.value[index]}
                                            <MdClose
                                                className="Labels__close"
                                                onClick={() => {
                                                    fields.remove(index);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                                <BorderlessTextInput
                                    placeholder={'+ Add new label'.toUpperCase()}
                                    classname="Labels__textInput"
                                    value={value}
                                    onChange={(v: string) => {
                                        setValue(v);
                                    }}
                                    onKeyPressed={(key: string) => {
                                        if (key === 'Enter' || key === 'Tab') {
                                            fields.push(value);
                                            setValue('');
                                        }
                                    }}
                                />
                            </div>
                        );
                    }}
                </FieldArray>
            </div>
        </div>
    );
};
