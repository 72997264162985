import {METHODS} from '../constants';

import {LIST_DIALPAD_CALLCENTERS} from './constants';

const {GET} = METHODS;

export const rest = {
    [LIST_DIALPAD_CALLCENTERS]: {
        url: 'v3.1/escalations/dialpad/callcenters?provider=:provider',
        options: {
            method: GET,
        },
    },
};
