export const LOGIN = 'LOGIN';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_LOGGED_IN_USER = 'UPDATE_LOGGED_IN_USER';
export const GET_USER = 'GET_USER';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const PASSWORD_RECOVER_CONFIRM = 'PASSWORD_RECOVER_CONFIRM';
export const TOKEN = 'access_token';
export const REFRESH = 'refresh_token';
export const REMEMBER_ME = 'REMEMBER_ME';
export const GET_LOGGED_IN_USER = 'GET_LOGGED_IN_USER';
export const GET_USERS = 'GET_USERS';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER_DATA_STORAGE_KEY = 'USER_DATA_STORAGE_KEY';

export const DASHBOARD_APP_ID = 'dashboard';
export const PASSWORD_GRANT_TYPE = 'password';
export const REFRESH_TOKEN_GRANT_TYPE = 'refresh_token';
export const DIALPAD_AUTHORIZE_CODE_GRANT_TYPE = 'dialpad_authorize_code';
export const ADMIN_AUTHORIZE_CODE_GRANT_TYPE = 'admin_auth_code';
