import {
    SELECTED_ASPECT,
    CHANGE_SELECTED_ASPECT,
    CHANGE_SELECTED_MESSAGE,
    CHANGE_SELECTED_METRIC_ID,
    CHANGE_SELECTED_MIN_DATE,
    CHANGE_SELECTED_MAX_DATE,
    SELECTED_MESSAGE,
    SELECTED_METRIC_ID,
    SELECTED_MIN_DATE,
    SELECTED_MAX_DATE,
} from '../constants';

const initialState = {
    [SELECTED_ASPECT]: undefined,
    [SELECTED_MESSAGE]: undefined,
    [SELECTED_METRIC_ID]: undefined,
    [SELECTED_MIN_DATE]: undefined,
    [SELECTED_MAX_DATE]: undefined,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_SELECTED_ASPECT:
            return {...state, [SELECTED_ASPECT]: action.payload.aspect};
        case CHANGE_SELECTED_MESSAGE:
            return {...state, [SELECTED_MESSAGE]: action.payload};
        case CHANGE_SELECTED_METRIC_ID:
            return {...state, [SELECTED_METRIC_ID]: action.payload.id};
        case CHANGE_SELECTED_MIN_DATE:
            return {...state, [SELECTED_MIN_DATE]: action.payload.minDate};
        case CHANGE_SELECTED_MAX_DATE:
            return {...state, [SELECTED_MAX_DATE]: action.payload.maxDate};
        default:
            return state;
    }
};
