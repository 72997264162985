import axios, {AxiosResponse} from 'axios';
import {get} from 'lodash';
import {toast} from 'react-toastify';

export const listStaticMessages = (
    onSuccess: (data: any) => void,
    id: string,
) => {
    if (id) {
        return axios
            .get(`/v3.1/audience/campaigns/${id}/messages`)
            .then((response: AxiosResponse<any>) => {
                if (response && response.data) {
                    onSuccess(response.data);
                }
            })
            .catch(() => {
                toast.error('Error loading static messages. Try again');
            });
    } else {
        return axios
            .get('/v3.1/audience/messages')
            .then((response: AxiosResponse<any>) => {
                if (response && response.data) {
                    onSuccess(response.data);
                }
            })
            .catch(() => {
                toast.error('Error loading static messages. Try again');
            });
    }
};

export const listAllResponses = (onSuccess: (data: any) => void) => {
    const useNull = () => null;
    axios
        .all([
            axios
                .get(
                    `/v3.1/kbm/nodes?type=response&status=published&limit=1000`,
                )
                .catch(useNull),
        ])
        .then(
            axios.spread((responesPublished) => {
                onSuccess([...get(responesPublished, ['data', 'nodes'], [])]);
            }),
        );
};

export const listOnlyChatterNodes = (onSuccess: (data: any) => void) => {
    const useNull = () => null;
    axios
        .all([
            axios
                .get(
                    `/v3.1/kbm/nodes?type=chattering&status=published&limit=1000`,
                )
                .catch(useNull),
            axios
                .get(
                    `/v3.1/kbm/nodes?type=chattering&status=unpublished&limit=1000`,
                )
                .catch(useNull),
        ])
        .then(
            axios.spread((published, unpublished) => {
                onSuccess([
                    ...get(published, ['data', 'nodes'], []),
                    ...get(unpublished, ['data', 'nodes'], []),
                ]);
            }),
        );
};

export const deleteChatterMessage = (id: string, onSuccess: () => void) => {
    axios
        .patch('/v3.1/kbm/nodes/bulk', {
            nodes: [{id: id, status: 'deleted', field_masks: ['status']}],
        })
        .then((response: AxiosResponse<any>) => {
            if (get(response, ['data', 'nodes', '0', 'status'], '') === 'ok') {
                onSuccess();
            } else {
                toast.error('Error deleting this chatter message');
            }
        })
        .catch(() => {
            toast.error('Error deleting this chatter message');
        });
};

export const assignStaticMessageToChatterMessage = (
    id: string,
    chatterMessageId: string,
    staticMessageTitle: string,
    onSuccess: (data: any) => void,
    selectedCampaign,
) => {
    if (selectedCampaign?.id) {
        axios
            .put(
                `/v3.1/audience/campaigns/${selectedCampaign.id}/messages/${id}`,
                {
                    node_id: chatterMessageId,
                },
            )
            .then((response: AxiosResponse<any>) => {
                if (response && response.data) {
                    onSuccess(response.data.message);
                } else {
                    toast.error(
                        `Error saving assigning this chatter message to ${staticMessageTitle}`,
                    );
                }
            })
            .catch(() => {
                toast.error(
                    `Error saving assigning this chatter message to ${staticMessageTitle}`,
                );
            });
    } else {
        axios
            .put(`/v3.1/audience/messages/${id}`, {
                node_id: chatterMessageId,
            })
            .then((response: AxiosResponse<any>) => {
                if (response && response.data) {
                    onSuccess(response.data.message);
                } else {
                    toast.error(
                        `Error saving assigning this chatter message to ${staticMessageTitle}`,
                    );
                }
            })
            .catch(() => {
                toast.error(
                    `Error saving assigning this chatter message to ${staticMessageTitle}`,
                );
            });
    }
};

export const deleteStaticMessage = (
    id: string,
    onSuccess: () => void,
    selectedCampaign?: any,
) => {
    if (selectedCampaign?.id) {
        axios
            .delete(
                `/v3.1/audience/campaigns/${selectedCampaign.id}/messages/${id}`,
            )
            .then((response: AxiosResponse<any>) => {
                if (response && response.status === 200) {
                    onSuccess();
                } else {
                    toast.error(`Error deleting static message ${id}`);
                }
            })
            .catch(() => {
                toast.error(`Error deleting static message ${id}`);
            });
    } else {
        axios
            .delete(`/v3.1/audience/messages/${id}`)
            .then((response: AxiosResponse<any>) => {
                if (response && response.status === 200) {
                    onSuccess();
                } else {
                    toast.error(`Error deleting static message ${id}`);
                }
            })
            .catch(() => {
                toast.error(`Error deleting static message ${id}`);
            });
    }
};

export const checkChatterMessagesValidity = (
    ids: string[],
    onSuccess: (data: any) => void,
) => {
    axios
        .all(ids.map((id) => axios.get(`/v3.1/kbm/nodes/${id}`)))
        .then((responses: AxiosResponse<any>[]) => {
            onSuccess(
                responses
                    .filter(
                        (response: AxiosResponse<any>) =>
                            response && response.data,
                    )
                    .map((response) => response.data),
            );
        });
};
