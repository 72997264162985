import React from 'react';

import './HandledChart.scss';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {transformAggregatedConversationsDataToHandledChart} from '@app/utils/ConversationDataTransform';
import {RadialChart} from '@app/components/RadialChart/RadialChart';
import {HandedContentLoader} from '@app/components/ContentLoaders/HandedContentLoader';
import {ChartDataTypes} from '../Activity';

export interface HandledChartProps {
    heroTitle: string;
    dataToShow: string;
    data: ChartDataTypes;
}
export const HandledChart: React.FC<HandledChartProps> = ({
    heroTitle,
    dataToShow,
    data,
}) => {
    const showEmptyState = () => {
        if (
            !data.data.queries ||
            !data.data.queries.counters ||
            !data.data.conversations ||
            !data.data.conversations.counters
        ) {
            return 'There is no data to show';
        }
        return null;
    };

    return (
        <ErrorLoadingWrapper
            height={'244px'}
            error={data.error}
            isLoading={data.loading}
            CustomLoading={HandedContentLoader}
            emptyState={showEmptyState()}
            render={() => {
                const dataToUse = (dataKey) => {
                    switch (dataKey) {
                        default:
                            return transformAggregatedConversationsDataToHandledChart(
                                data.data.conversations,
                                dataKey,
                            );
                    }
                };
                return (
                    <div className="HandledChart__handedChart">
                        <RadialChart
                            extraOptions={{startAngle: -90, endAngle: 90}}
                            plotData={dataToUse(dataToShow).plotData}
                            direction="column" // change for self served
                            hero={{
                                value: dataToUse(dataToShow).totals.resolved,
                                title: heroTitle,
                            }}
                        />
                    </div>
                );
            }}
        />
    );
};
