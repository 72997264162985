import React from 'react';
import cx from 'classnames';
import {getTimestamp} from '../utils/timestamp';
import {Markdown} from '@app/common';
import {get} from 'lodash';
import {MessageUserChoice} from '../message-user-choice';
import {Email} from '@app/common/icons';
import {MdChatBubble} from 'react-icons/md';
import {Minus} from '@app/assets/icons/minus';

interface MessageProps {
    message: any;
    isHovering: boolean;
    onMouseEnter: any;
    resetCache: any;
    onMouseLeave: any;
}

export class Message extends React.Component<MessageProps> {
    reloadCacheIfMessageIsImage = (text) => {
        const {resetCache} = this.props;
        const markdownImgRegex = /!\[.*?]\((.*?)\)/;
        const imgMatch = text.match(markdownImgRegex);
        if (imgMatch && imgMatch[1]) {
            const preloadImg = new Image();
            preloadImg.src = imgMatch[1];
            if (preloadImg.naturalHeight === 0) {
                preloadImg.onload = () => {
                    if (resetCache && typeof resetCache === 'function') {
                        resetCache();
                    }
                };
            }
        }
    };

    render() {
        const {message, isHovering, onMouseEnter, onMouseLeave} = this.props;
        const uri = get(message, 'uri');
        const body =
            message?.event_body?.out_text ||
            message?.event_body?.in_query ||
            message?.event_body?.in_click;
        const aspect = body?.aspect;
        let dialogTarget = null;
        const text = message.body?.text;

        if (uri === 'in/click') {
            if (aspect === 'open/response') {
                dialogTarget = body.text;
                if (dialogTarget) {
                    return <MessageUserChoice {...this.props} />;
                }
            }
            if (aspect === 'open/beacon') {
                const aspectType =
                    body?.target?.body?.aspect === 'fab'
                        ? 'Floating Action Button'
                        : body?.target?.body?.aspect;
                if (text === 'exit-beacon') {
                    return (
                        <div className="console-activity-message--in-widget exit">
                            <span>
                                <Minus />
                                <p>Widget Minimized</p>
                            </span>
                        </div>
                    );
                }
                // Ignore in/clicks from launching and closing widget.
                if (text === 'launcher') {
                    return (
                        <div className="console-activity-message--in-widget launched">
                            <p>Launched</p>
                            <span> {aspectType} </span>
                        </div>
                    );
                }
            }
        }
        const timestamp = getTimestamp(message);

        if (!text) {
            return null;
        }
        const isUserMessage = uri.match(/^in\//);
        const className = cx(
            'console-activity-message-container',
            'console-activity-message',
            {
                'is-user-query': uri === 'in/query',
                'console-activity-message--in console-activity--in':
                    isUserMessage,
                'console-activity-message--out console-activity--out':
                    !isUserMessage,
            },
        );

        this.reloadCacheIfMessageIsImage(text);

        const triggerMethod = body?.trigger_method;

        let senderName;
        let senderEmail;
        const sender = body?.sender;
        if (sender && sender.length > 0) {
            const strippedEmail = sender.split(' <');
            senderName = strippedEmail[0];
            senderEmail = `<${strippedEmail[1]}`;
        }
        return (
            <div
                className={className}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <div>
                    {!!triggerMethod && (
                        <p>
                            {triggerMethod === 'webext' ? (
                                <MdChatBubble />
                            ) : (
                                <Email />
                            )}{' '}
                            Handover
                        </p>
                    )}{' '}
                    {uri === 'in/query' && <p>Asked</p>}
                    <div>
                        {senderName && senderEmail && (
                            <div className={'console-activity-message-sender'}>
                                <span>{senderName}</span>{' '}
                                {senderEmail &&
                                    senderEmail !== '<undefined' &&
                                    senderEmail !== '<NoEmail>' &&
                                    sender && <span>{senderEmail}</span>}
                            </div>
                        )}
                        <Markdown>{text}</Markdown>
                    </div>
                </div>
                {isHovering && (
                    <time className="console-activity-message-timestamp">
                        {timestamp}
                    </time>
                )}
            </div>
        );
    }
}
