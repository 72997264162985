import {RESET_SELECT_DOCUMENT, SELECT_DOCUMENT} from '../constants';

const initialState = {
    selectedDocument: null,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_DOCUMENT: {
            return {
                ...state,
                selectedDocument: action.payload,
            };
        }
        case RESET_SELECT_DOCUMENT: {
            return {
                ...state,
                selectedDocument: null,
            };
        }
        default:
            return state;
    }
};
