import React from 'react';
import {connect} from 'react-redux';
import {SubmissionError} from 'redux-form';

import {getConfirmCode} from './utils';
import {redux} from '@app/redux';
import {ResetPasswordForm} from './reset-password-form';

import {Wrapper, Container, H2} from './resetPassword.styles';

interface ResetPasswordProps {
    resetPassword: any;
}

export class ResetPasswordComponent extends React.Component<ResetPasswordProps> {
    render() {
        return (
            <Wrapper>
                <Container>
                    <H2>Reset your password</H2>
                    <ResetPasswordForm onSubmit={this.props.resetPassword} />
                </Container>
            </Wrapper>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        resetPassword: (params) => {
            const confirmCode = getConfirmCode(ownProps.location.hash);
            const payload = {
                code: confirmCode,
                ...params,
            };
            return dispatch(
                redux.rest.actions[
                    redux.user.constants.PASSWORD_RECOVER_CONFIRM
                ]({}, {body: JSON.stringify(payload)}),
            )
                .then(() => {
                    ownProps.history.push('/reset-password/success');
                })
                .catch((error) => {
                    throw new SubmissionError({password: error.message});
                });
        },
    };
};

export const ResetPassword = connect(
    null,
    mapDispatchToProps,
)(ResetPasswordComponent);
