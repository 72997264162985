import React, {useState, Fragment} from 'react';
import {MdSchool} from 'react-icons/md';
import {Spinner} from '@app/common';

import './Missing.scss';
import {ClusterResponse} from '@app/types/Clusters';
import {getChartColor} from '@app/utils/DataRenderingUtils';
import {Empty} from '@app/components/Empty/Empty';
import {StoreContext} from '@app/state';

const defaultInitialDataState = {
    data: [] as ClusterResponse[],
    error: null,
    loading: true,
};
interface IMissingProps {
    history: any;
}

export const Missing: React.FunctionComponent<IMissingProps> = ({history}) => {
    const [clustersData, setClustersData] = useState(defaultInitialDataState);
    const {clusters} = React.useContext(StoreContext);

    const getClusters = async () => {
        setClustersData({...clustersData, loading: true});
        const res = await clusters.getClusters(true);
        setClustersData({loading: false, data: res.data, error: res.error});
    };

    React.useEffect(() => {
        getClusters();
    }, []);
    const filteredClusters =
        clustersData && clustersData.data
            ? clustersData.data.filter(
                  (c) =>
                      c &&
                      c.cluster_type &&
                      c.cluster_type === 'missing-knowledge',
              )
            : [];

    return (
        <div className="missing__container">
            {clustersData.loading ? (
                <Spinner />
            ) : filteredClusters.length === 0 ? (
                <Empty
                    icon={MdSchool}
                    header={'There are no responses with missing knowledge'}
                    subheader={''}
                />
            ) : (
                <Fragment>
                    <div className="missing__subHeader">
                        Improve your self service coverage by completing missing
                        knowledge in your knowledgebase.
                    </div>
                    <div className="missing__content">
                        <ul>
                            {filteredClusters.map((c) => {
                                const firstTwoQueries = c.queries?.slice(0, 2);
                                const restOfQueries = c.queries?.slice(2);

                                return (
                                    <li
                                        key={c.id}
                                        className="missing__clusterItem"
                                        onClick={() => {
                                            history.push(
                                                `/improve/${c.id}?origin=/expand/missing`,
                                            );
                                        }}
                                    >
                                        <div className="missing__header">
                                            <div
                                                className="missing__responseIcon"
                                                style={{
                                                    backgroundColor:
                                                        getChartColor(
                                                            c.cluster_type,
                                                        ),
                                                }}
                                            />
                                            <span>Missing answer for:</span>
                                        </div>
                                        <div className="missing__responseContent">
                                            <div className="missing__queries">
                                                <ul>
                                                    {firstTwoQueries?.map(
                                                        (q) => (
                                                            <li
                                                                key={`query${q.id}`}
                                                            >
                                                                {q.query}
                                                            </li>
                                                        ),
                                                    )}
                                                </ul>
                                                {restOfQueries?.length > 0 && (
                                                    <div className="missing__more">
                                                        <div>
                                                            +{' '}
                                                            {
                                                                restOfQueries.length
                                                            }{' '}
                                                            more
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </Fragment>
            )}
        </div>
    );
};
