import React, {Component} from 'react';
import {includes} from 'lodash';
import {Close, Document} from '@app/common/icons/index';
import {SearchBar} from '@app/common';
import {redux} from '@app/redux';
import {selectDocumentAction} from '@app/redux/improve/documents/actions';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {QUERIES_RESPONSE_FORM} from '../../queries-card/queries-response/queries-response-search/constants';
import {documentSelector} from '@app/redux/documents/selectors';

interface DocumentListProps {
    documents?: {
        external_source?: string;
        external_url?: string;
        id?: string;
        score?: number;
        tags?: string[];
        title?: string;
    }[];
    getAllPublishedDocuments?: any;
    selectDocument?: any;
    closeModal?: any;
}

export class DocumentListComponent extends Component<DocumentListProps> {
    state = {
        query: '',
    };

    componentDidMount() {
        this.props.getAllPublishedDocuments();
    }

    selectDocument = (source) => {
        const id = source.id || null;
        this.props.selectDocument(id);
    };

    handleOnChange = (event) => {
        const query = event.target.value;
        this.setState({query});
    };

    render() {
        return (
            <div className="query-response__documents-list-modal">
                <Close
                    className="query-response__documents-list-modal-close-icon"
                    onClick={this.props.closeModal}
                    size={15}
                />
                <div className="query-response__documents-list-modal__title">
                    <SearchBar
                        onChange={this.handleOnChange}
                        value={this.state.query}
                        hideCross={true}
                    />
                </div>
                <div className="query-response__documents-list-modal__body">
                    {this.props.documents &&
                        this.props.documents
                            .filter((document) =>
                                includes(
                                    document.title.toLowerCase(),
                                    this.state.query.toLowerCase(),
                                ),
                            )
                            .map((document) => (
                                <div
                                    className={
                                        'query-response__documents-modal-item'
                                    }
                                    key={document.id}
                                    onClick={() => {
                                        this.selectDocument(document);
                                    }}
                                >
                                    <Document size={18} />
                                    {document.title}
                                </div>
                            ))}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        documents:
            redux.knowledgeBase.selectors.publishedDocumentsSelector(state),
        document: documentSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    getAllPublishedDocuments:
        redux.knowledgeBase.dispatchers.getAllPublishedDocumentsDispatcher(
            dispatch,
        ),
    selectDocument: (id, title, url) => {
        return dispatch(selectDocumentAction(id, title, url));
    },
});

export const DocumentList = connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    reduxForm({
        form: QUERIES_RESPONSE_FORM,
    })(DocumentListComponent),
);
