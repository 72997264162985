import React from 'react';

export class MarkdownEditorError extends React.Component {
    render() {
        return (
            <div className="markdown-editor-error">
                Please, add some content to the body of the response.
            </div>
        );
    }
}
