import {
    DELETE_CLUSTER,
    GET_CLUSTER,
    GET_CLUSTERS,
    SAVE_CLUSTER,
} from './constants';
import {METHODS} from '../constants';
import {
    CLUSTER_LIMIT,
    CLUSTER_LIMIT_KEY,
    CLUSTERS_TO_SKIP,
    DECREASE_SELECTED_CLUSTER_INDEX,
    INCREASE_SELECTED_CLUSTER_INDEX,
    REMOVE_INDEX_FROM_CLUSTERS,
    RESET_SELECTED_CLUSTER_INDEX,
    SELECTED_CLUSTER_INDEX,
} from './cards/constants';
import {get} from 'lodash';
import {GET_IMPROVE_PAGE_RESPONSE} from '../responses/constants';
const {GET, PATCH} = METHODS;

const initialState = {
    [SELECTED_CLUSTER_INDEX]: 0,
    [CLUSTER_LIMIT_KEY]: CLUSTER_LIMIT,
    [CLUSTERS_TO_SKIP]: [],
};

export const rest = {
    [GET_CLUSTERS]: {
        url: `/v3.0/improve/clusters`,
        reducer(state, action) {
            switch (action.type) {
                case DECREASE_SELECTED_CLUSTER_INDEX: {
                    let newIndex = state[SELECTED_CLUSTER_INDEX] - 1;
                    while (
                        state[CLUSTERS_TO_SKIP].includes(newIndex) &&
                        newIndex > 0
                    ) {
                        newIndex--;
                    }
                    return {
                        ...state,
                        [SELECTED_CLUSTER_INDEX]: newIndex,
                    };
                }
                case INCREASE_SELECTED_CLUSTER_INDEX: {
                    let newIndex = state[SELECTED_CLUSTER_INDEX] + 1;

                    while (state[CLUSTERS_TO_SKIP].includes(newIndex)) {
                        newIndex++;
                    }
                    return {
                        ...state,
                        [SELECTED_CLUSTER_INDEX]: newIndex,
                    };
                }
                case RESET_SELECTED_CLUSTER_INDEX: {
                    return {
                        ...state,
                        [SELECTED_CLUSTER_INDEX]: 0,
                    };
                }
                case REMOVE_INDEX_FROM_CLUSTERS: {
                    return {
                        ...state,
                        [CLUSTERS_TO_SKIP]: [
                            ...state[CLUSTERS_TO_SKIP],
                            state[SELECTED_CLUSTER_INDEX] - 1,
                        ],
                    };
                }
                default:
                    return state[CLUSTER_LIMIT_KEY] ? state : initialState;
            }
        },
        options: {
            method: GET,
        },
    },
    [GET_CLUSTER]: {
        url: `/v3.0/improve/clusters/:id`,
        options: {
            method: GET,
        },
        postfetch: [
            ({data, actions, dispatch}) => {
                const responseNodes = get(data, ['nodes'], []);
                let nodeId = null;

                if (responseNodes && responseNodes.length === 1) {
                    nodeId = get(responseNodes[0], 'id', null);
                }
                if (nodeId) {
                    dispatch(
                        actions[GET_IMPROVE_PAGE_RESPONSE]({
                            id: nodeId,
                        }),
                    );
                }
            },
        ],
    },
    [SAVE_CLUSTER]: {
        url: `/v3.0/improve/clusters/:id`,
        options: {
            method: PATCH,
        },
    },
    [DELETE_CLUSTER]: {
        url: '/v3.0/improve/clusters/:id',
        options: {
            method: PATCH,
        },
    },
};
