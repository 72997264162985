import React, {useCallback, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {Description, Close} from '@material-ui/icons';
import ReactPlayer from 'react-player';

import './Uploader.scss';
import {TypeContent} from './UploadContentModal';
import {getMediaType} from './Api';

export interface UploaderProps {
    typeContent: TypeContent;
    setFilename: (filename: String) => void;
    setContent: (content: File) => void;
}

export const Uploader: React.FC<UploaderProps> = (props) => {
    const [content, setContent] = useState(null);
    const [preview, setPreview] = useState(null);
    const onDrop = useCallback((content) => {
        const attachment = content[0];
        setContent(attachment);
        props.setFilename(attachment.name);
        props.setContent(attachment);
        const reader = new FileReader();
        reader.onload = (e) => {
            setPreview(e.target.result);
        };
        reader.readAsDataURL(attachment);
    }, []);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
    });
    const contentType = getMediaType(content);
    return (
        <div className="Uploader__container">
            {!content && (
                <div {...getRootProps()} className="Uploader__inputContainer">
                    <React.Fragment>
                        <input {...getInputProps()} />
                        <span className="Uploader__inputText">
                            Drag and drop attachments to upload or{' '}
                            <span>click here</span>
                        </span>
                    </React.Fragment>
                </div>
            )}
            {content && contentType !== 'photo' && contentType !== 'video' && (
                <div className="Uploader__contentContainer">
                    <Description />
                    <span className="Uploader__contentName">
                        {content.name}
                    </span>
                    <div className="Uploader__removeContent">
                        <Close />
                    </div>
                </div>
            )}
            {content && contentType === 'photo' && (
                <div className="Uploader__imageContainer">
                    <img src={preview} />
                </div>
            )}
            {content && contentType === 'video' && (
                <div className="Uploader__videoContainer">
                    <ReactPlayer
                        url={preview}
                        width="100%"
                        height="100%"
                        controls={true}
                    />
                </div>
            )}
        </div>
    );
};
