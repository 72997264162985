import {get} from 'lodash';

import {LOADING, DATA} from '../../constants';
import {APPLICATION, DATE} from '../constants';
import {application} from '@app/redux/application/constants';

export const applicationSelector = (state) => {
    const applications = get(state, [APPLICATION, DATA, DATA], []);
    return get(applications, '0');
};

export const multipleApplicationSelector = (state) => {
    const applications = get(state, [APPLICATION, DATA, DATA], []);
    return applications.length > 1;
};

export const applicationIdSelector = (state) => {
    const application = applicationSelector(state);
    return application && application.id;
};

export const applicationNameSelector = (state) => {
    const application = applicationSelector(state);
    return application && application.name;
};

export const isApplicationLoadingSelector = (state) => {
    return get(state, [APPLICATION, LOADING]) === true;
};

export const dateSelector = (state) => {
    return get(state, [application, DATE], {
        minDate: undefined,
        maxDate: undefined,
    });
};
