import React from 'react';

interface MessageImageProps {
    message: any;
}

export const MessageImage = (props: MessageImageProps) => {
    const {message} = props;
    return (
        <div
            className={
                'message-disambiguation__container console-activity--out console-activity-message-container'
            }
        >
            <div className={'message-disambiguation__body'}>
                <a href={message.body.text} target="_blank" rel="noreferrer">
                    <img src={message.body.text} alt="" />
                </a>
            </div>
        </div>
    );
};
