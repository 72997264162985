/**
 * The HTTP methods available for the API calls.
 */
export const METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
};

export const LOADING = 'loading';
export const SYNC = 'sync';

/**
 * Key in v2 server response used to express the total number of pages.
 */
export const PAGES = 'pages';
export const DATA = 'data';
export const ENTRIES = 'nodes';
export const ERROR = 'error';
export const CODE = 'code';
export const PAYLOAD = 'payload';

export const APPLICATION_JSON = 'application/json';
export const ACCEPT_LANGUAGE = 'Accept-Language';
export const CONTENT_TYPE = 'Content-Type';
export const ACCEPT = 'Accept';
export const AUTHORIZATION = 'authorization';
export const BEARER = 'Bearer';
export const CONTENT_LOCALE = 'Kare-Content-Locale';

export const LOCALE = 'LOCALE';
