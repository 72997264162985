import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {redux} from '@app/redux';
import {Button, ErrorBoundary, SearchBar} from '@app/common';
import {KeyboardArrowLeft} from '@app/common/icons';
import {FIELD_MASKS, NODE_STATUSES, STATUS} from '@app/constants';
import {FormLessTagsInput} from '@app/common/tags-input/formless-tags-input';

interface ResponsesSearchProps {
    query?: string;
    labelsToFilter?: any[];
    removeAllLabels?: any;
    searchKnowledgeBase?: any;
    setLabelsToFilter?: any;
    removeLabelFromFilter?: any;
    getResponses?: any;
    bulkArchiveResponses?: any;
    showingArchived?: boolean;
    showingDraft?: boolean;
    showingChatter?: boolean;
    showingActive?: boolean;
    responsesCount?: number;
    matchingItemsCount?: number;
    resetSelectedRows?: any;
    bulkArchiveDocuments?: any;
    bulkPublishResponses?: any;
    handleClickShowActive?: any;
    selectedRows?: any[];
    bulkDraftResponses?: any;
    addLabelToFilter?: any;
}

export class ResponsesSearchComponent extends React.Component<ResponsesSearchProps> {
    state = {showFilterButton: false, labelFilter: null};

    componentDidMount() {
        this.props.searchKnowledgeBase('');
        this.props.removeAllLabels();
    }

    componentWillUnmount() {
        this.props.resetSelectedRows();
        this.props.removeAllLabels();
    }

    handleClickArchiveSelected = () => {
        const {selectedRows, bulkArchiveResponses} = this.props;
        bulkArchiveResponses(selectedRows);
    };
    handleClickPublishSelected = () => {
        const {selectedRows, bulkPublishResponses} = this.props;
        bulkPublishResponses(selectedRows);
    };
    handleRevertToDraftSelected = () => {
        const {selectedRows, bulkDraftResponses} = this.props;
        bulkDraftResponses(selectedRows);
    };

    handleClickDeselectAll = () => {
        this.props.resetSelectedRows();
    };

    handleQueryChange = (event) => {
        const query = event.target.value;
        this.props.searchKnowledgeBase(query);
    };

    disableNewLabelEdition = () => {
        this.setState({showFilterButton: false});
    };
    toggleFilterButton = () => {
        if (this.state.showFilterButton) {
            this.props.removeAllLabels();
        }
        this.setState({
            showFilterButton: !this.state.showFilterButton,
        });
    };

    handleLabelListKeyPress = (e) => {
        const value = e.target.value || e.target.innerHTML;
        if (e.key === 'Enter') {
            e.preventDefault();
            if (value.length > 0) {
                this.props.addLabelToFilter(value);
                this.setState({currentLabel: ''}, this.disableNewLabelEdition);
            }
        }
    };

    render() {
        const {
            showingArchived,
            showingDraft,
            showingActive,
            responsesCount,
            selectedRows,
            handleClickShowActive,
            showingChatter,
            matchingItemsCount,
            labelsToFilter,
            setLabelsToFilter,
        } = this.props;
        const selectedRowCount = selectedRows.length;
        const hasSelectedRowCount = selectedRowCount > 0;

        return (
            <ErrorBoundary>
                <div className="knowledge-base-search__container">
                    <div className="knowledge-base-search__container-top">
                        <div className="knowledge-base__toolbar-left">
                            <div className="knowledge-base-search__counter">
                                {showingArchived || showingDraft ? (
                                    <>
                                        <div className="knowledge-base-search__counter-arrow">
                                            <KeyboardArrowLeft
                                                size={24}
                                                onClick={handleClickShowActive}
                                            />
                                        </div>
                                        <div className="knowledge-base-search__counter-text">
                                            <h1 className="kare-h4">
                                                {hasSelectedRowCount
                                                    ? selectedRowCount
                                                    : matchingItemsCount}{' '}
                                                {showingArchived
                                                    ? 'Archived'
                                                    : 'Draft'}{' '}
                                                response
                                                {matchingItemsCount === 1
                                                    ? ''
                                                    : 's'}
                                                {hasSelectedRowCount
                                                    ? ' selected'
                                                    : ''}
                                            </h1>

                                            <span className="knowledge-base-search__counter-text-info">
                                                {showingArchived
                                                    ? 'Archived'
                                                    : 'Draft'}{' '}
                                                responses will not be searched
                                                or used to answer your
                                                customers. <br />
                                                {showingArchived &&
                                                    'Archived content is still available on linked responses.'}
                                            </span>
                                        </div>
                                    </>
                                ) : (
                                    <h1 className="kare-h3">
                                        {hasSelectedRowCount
                                            ? selectedRowCount
                                            : matchingItemsCount}{' '}
                                        {showingChatter
                                            ? 'Chatter message'
                                            : 'Response'}
                                        {((hasSelectedRowCount &&
                                            selectedRowCount > 1) ||
                                            (!hasSelectedRowCount &&
                                                matchingItemsCount > 1)) &&
                                            's'}
                                        {hasSelectedRowCount ? ' selected' : ''}
                                    </h1>
                                )}
                            </div>
                        </div>
                        <div className="knowledge-base__toolbar-right">
                            {responsesCount > 0 && !hasSelectedRowCount && (
                                <SearchBar
                                    name={'responses-search'}
                                    onChange={this.handleQueryChange}
                                    value={this.props.query}
                                    showFilterButton={
                                        !this.state.showFilterButton
                                    }
                                    filterButtonHandler={
                                        this.toggleFilterButton
                                    }
                                />
                            )}
                            {showingActive && hasSelectedRowCount && (
                                <Button
                                    outlined
                                    onClick={this.handleClickArchiveSelected}
                                >
                                    Archive selected
                                </Button>
                            )}
                            {showingDraft && hasSelectedRowCount && (
                                <Button
                                    outlined
                                    onClick={this.handleClickPublishSelected}
                                >
                                    Publish selected
                                </Button>
                            )}
                            {(showingArchived || showingActive) &&
                                hasSelectedRowCount && (
                                    <Button
                                        outlined
                                        onClick={
                                            this.handleRevertToDraftSelected
                                        }
                                    >
                                        Revert selected to draft
                                    </Button>
                                )}
                            {hasSelectedRowCount && (
                                <Button
                                    outlined
                                    onClick={this.handleClickDeselectAll}
                                >
                                    Deselect all
                                </Button>
                            )}
                        </div>
                    </div>

                    {(this.state.showFilterButton ||
                        labelsToFilter.length > 0) && (
                        <div className="knowledge-base-search__bottom">
                            <span>Filter by label</span>
                            <FormLessTagsInput
                                selectedTags={labelsToFilter}
                                setSelectedTags={setLabelsToFilter}
                            />
                        </div>
                    )}
                </div>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedRows: redux.responsesTable.selectors.selectedRowsSelector(state),
});

const bulkUpdateStatus = ({dispatch, ids, status}) => {
    return dispatch(
        redux.rest.actions[redux.responses.constants.BULK_UPDATE_RESPONSES](
            {},
            {
                body: JSON.stringify({
                    nodes: ids.map((id) => {
                        return {
                            id,
                            [STATUS]: status,
                            [FIELD_MASKS]: [STATUS],
                        };
                    }),
                }),
            },
        ),
    );
};

const mapDispatchToProps = (dispatch) => ({
    searchKnowledgeBase: (query) =>
        dispatch(redux.knowledgeBase.actions.searchKnowledgeBase(query)),
    resetSelectedRows: () =>
        dispatch(redux.responsesTable.actionCreators.resetSelectedRows()),
    bulkArchiveResponses: (ids) => {
        return bulkUpdateStatus({
            dispatch,
            ids,
            status: NODE_STATUSES.ARCHIVED,
        });
    },
    bulkDraftResponses: (ids) => {
        return bulkUpdateStatus({
            dispatch,
            ids,
            status: NODE_STATUSES.DRAFT,
        });
    },
    bulkPublishResponses: (ids) => {
        return bulkUpdateStatus({
            dispatch,
            ids,
            status: NODE_STATUSES.PUBLISHED,
        });
    },
});

export const ResponsesSearch = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ResponsesSearchComponent),
);
