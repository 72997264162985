import React, {Component} from 'react';
import {Document} from '@app/common/icons';
import {getAutoResponseTitle} from '@app/redux/improve/responses/selectors';
import {getClusterResponseId} from '@app/redux/improve/cards/selectors';
import {redux} from '@app/redux';
import {GET_RESPONSE} from '@app/redux/responses/constants';
import {setImproveAnswer} from '@app/redux/improve/responses/actions';
import {responseSelector} from '@app/redux/responses/selectors';
import {connect} from 'react-redux';

interface QueryResponseListAutoResponseItemProps {
    autoResponseTitle?: string;
    selectStoredResponse?: any;
    clusterResponseId?: string;
}

export class QueryResponseListAutoResponseItemComponent extends Component<QueryResponseListAutoResponseItemProps> {
    render() {
        const {clusterResponseId} = this.props;
        if (!this.props.autoResponseTitle) {
            return null;
        }
        return (
            <div
                className="queries-stored-response__container"
                onClick={this.props.selectStoredResponse(clusterResponseId)}
            >
                <Document />
                <span>GENERATED FROM: {this.props.autoResponseTitle}</span>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        autoResponseTitle: getAutoResponseTitle(state),
        clusterResponseId: getClusterResponseId(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    selectStoredResponse:
        (cardResponseId) =>
        ({getState}) => {
            dispatch(
                redux.rest.actions[GET_RESPONSE]({id: cardResponseId}),
            ).then(() => {
                dispatch(
                    setImproveAnswer(
                        cardResponseId,
                        // @ts-ignore
                        responseSelector(getState()).title,
                    ),
                );
            });
        },
});

export const QueryResponseListAutoResponseItem = connect(
    mapStateToProps,
    mapDispatchToProps,
)(QueryResponseListAutoResponseItemComponent);
