import React, {useEffect, useState} from 'react';
import {MdEdit, MdLabel} from 'react-icons/md';
import {KareLabel} from '@app/common/kare-label';
import cx from 'classnames';
import {getFilteredLabelsSelector} from '@app/redux/knowledge-base/selectors';
import {redux} from '@app/redux';
import {GET_LABELS} from '@app/redux/knowledge-base/constants';
import {connect} from 'react-redux';

interface DocumentMetadataEditorProps {
    showLabelEditIcon: boolean;
    labels?: string[];
    getLabels?: any;
    documentLabels: string[];
    onSaveDocumentLabels: any;
}

/**
 * Document Metadata Editor Component.
 * This is used when editing a document with labels.
 */
export const DocumentMetadataEditorComponent = (
    props: DocumentMetadataEditorProps,
) => {
    const [isEditinglabels, setIsEditinglabels] = useState(false);
    const [documentLabels, setDocumentLabels] = useState(
        props.documentLabels || [],
    );

    useEffect(() => {
        props.getLabels();
    }, []);

    const documentHasLabels = () => {
        return documentLabels.length > 0;
    };

    const onSelectedLabel = (e, val) => {
        setDocumentLabels([...documentLabels, val]);
        handlePostEvent(e);
    };

    const onDeletedLabel = (e, label) => {
        setDocumentLabels(documentLabels.filter((l) => l != label));
        handlePostEvent(e);
    };

    const handlePostEvent = (e: Event) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const {onSaveDocumentLabels, showLabelEditIcon, labels} = props;

    const iconSize = 20;

    return (
        <div
            className={cx('document-context document-context-labels', {
                'document-context-editing-top': isEditinglabels,
            })}
        >
            <div className="document-context-labels-container">
                <MdLabel size={iconSize} className="document-context-icons" />
                {documentHasLabels() &&
                    documentLabels.map((l, ind) => (
                        <span
                            className="document-context-label-wrapper"
                            key={`${l}-${ind}`}
                        >
                            <KareLabel
                                key={`${ind}-${l}`}
                                text={l}
                                showRemoveButton={isEditinglabels}
                                editable={false}
                                handleClose={onDeletedLabel}
                            />
                        </span>
                    ))}
                {isEditinglabels && (
                    <KareLabel
                        key="l-input"
                        showRemoveButton={false}
                        editable
                        onSelectItem={onSelectedLabel}
                        autoCompleteList={labels.map((label, i) => ({
                            label: label,
                            id: `d-${i}-${label}`,
                        }))}
                    />
                )}
                {!documentHasLabels() && !isEditinglabels && (
                    <span className="document-context-text">
                        <b>No labels</b>
                    </span>
                )}
            </div>
            {isEditinglabels && (
                <b
                    className="document-context-save-text"
                    onClick={() => {
                        setIsEditinglabels(false);
                        onSaveDocumentLabels(documentLabels);
                    }}
                >
                    DONE
                </b>
            )}
            {!isEditinglabels && showLabelEditIcon && (
                <MdEdit
                    size={iconSize}
                    className="document-context-edit-icon"
                    onClick={() => {
                        setIsEditinglabels(true);
                    }}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    labels: getFilteredLabelsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    getLabels: () => {
        dispatch(redux.rest.actions[GET_LABELS]());
    },
});

export const DocumentMetadataEditor = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DocumentMetadataEditorComponent);
