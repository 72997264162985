import React from 'react';
import {Helmet} from 'react-helmet';

import {PanelItem} from '@app/common';

interface NotFoundProps {
    createPair?: any;
    location?: any;
}

export class NotFound extends React.Component<NotFoundProps> {
    render() {
        return (
            <div className="o-panel">
                <Helmet title="Page Not Found" />
                <PanelItem label="Page Not Found">
                    <p>
                        {
                            "Sorry, but we can't seem to find the page that you're looking for."
                        }
                    </p>
                </PanelItem>
            </div>
        );
    }
}
