import 'react-toastify/dist/ReactToastify.css';
import React, {useEffect} from 'react';
import {MdDelete, MdVisibilityOff} from 'react-icons/md';
import {Empty} from '@app/components/Empty/Empty';
import './Muted.scss';
import {redux} from '@app/redux';
import {connect, useDispatch} from 'react-redux';
import {
    DELETE_MUTED_QUERIES,
    GET_MUTED_QUERIES,
} from '@app/redux/improve/queries/constants';
import {
    getAllMutedQueriesSelector,
    getMutedQueriesCursorSelector,
    isMutedQueriesLoading,
} from '@app/redux/improve/queries/selectors';
import {Spinner} from '@app/common';
import {Button} from '@app/common';
import ReactTable from 'react-table';
import cx from 'classnames';
import {removeMutedQueries} from '@app/redux/improve/queries/actions';

interface IMutedProps {
    history?: any;
    mutedQueries?: any;
    mutedQueriesLoading?: any;
    deleteMutedQuery?: any;
    getMutedQueries?: any;
    mutedQueriesCursor?: any;
    filter?: any;
}

export const MutedComponent: React.FunctionComponent<IMutedProps> = ({
    mutedQueries,
    mutedQueriesLoading,
    getMutedQueries,
    mutedQueriesCursor,
    deleteMutedQuery,
    filter,
}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (!mutedQueries.length || mutedQueriesCursor)
            getMutedQueries(mutedQueriesCursor);
    }, [mutedQueriesCursor]);

    const handleDeleteMutedQuery = async (removeButton) => {
        await deleteMutedQuery(removeButton);
        dispatch(removeMutedQueries([removeButton]));
    };

    const filterMutedQueries = (mutedQueries) => {
        return mutedQueries.filter(
            (item) =>
                filter === '' ||
                (filter !== '' &&
                    item.query.toLowerCase().indexOf(filter.toLowerCase()) !==
                        -1),
        );
    };

    const columns = [
        {
            Header: 'Query',
            accessor: 'query',
            sortable: true,
            Cell: (props) => {
                const {original} = props;
                return (
                    <div className="muted__mutedQueryTableTitleLabel">
                        <span>{original?.query}</span>
                    </div>
                );
            },
        },
        {
            accessor: 'removeButton',
            sortable: false,
            resizable: false,
            Header: () => {
                return '';
            },
            Cell: (props) => {
                const {original} = props;
                return (
                    <div className="muted__buttonContainer">
                        <Button
                            inverted
                            icon={MdDelete}
                            onClick={async () => {
                                await handleDeleteMutedQuery(
                                    original?.removeButton,
                                );
                            }}
                        >
                            UNMUTE
                        </Button>
                    </div>
                );
            },
        },
    ];

    const className = cx('-striped', 'muted-query-table');

    return (
        <div className="muted__container">
            {!mutedQueries?.length ? (
                <Empty
                    header="You don't have any muted queries"
                    subheader="You can see and un-mute queries in this list"
                    icon={MdVisibilityOff}
                />
            ) : (
                <div className="muted__content">
                    <ReactTable
                        className={className}
                        data={filterMutedQueries(mutedQueries)}
                        columns={columns}
                        resizable={false}
                        showPagination={true}
                        defaultPageSize={20}
                        loading={mutedQueriesLoading}
                        loadingText={<Spinner />}
                    />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        mutedQueries: getAllMutedQueriesSelector(state)?.map(
            (mutedQuery: any) => {
                return {
                    query: mutedQuery?.query,
                    removeButton: mutedQuery?.id,
                };
            },
        ),
        mutedQueriesLoading: isMutedQueriesLoading(state),
        mutedQueriesCursor: getMutedQueriesCursorSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMutedQueries: (mutedQueriesCursor) => {
            dispatch(
                redux.rest.actions[GET_MUTED_QUERIES]({
                    cursor: mutedQueriesCursor || '',
                    limit: 2000,
                }),
            );
        },
        deleteMutedQuery: (mutedQueryId) => {
            dispatch(
                redux.rest.actions[DELETE_MUTED_QUERIES](
                    {},
                    {
                        body: JSON.stringify({
                            queries: [mutedQueryId],
                        }),
                    },
                ),
            );
        },
    };
};

export const Muted = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MutedComponent);
