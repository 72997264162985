import * as selectors from './selectors';
import * as constants from './constants';
import {reducer} from './reducer';

/**
 * Public API for the dialogs redux module.
 */
export const dialogs = {
    constants,
    selectors,
    reducer,
};
