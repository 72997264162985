import * as selectors from './selectors';
import * as constants from './constants';
import * as dispatchers from './dispatchers';
import {reducer} from './reducer';
import {rest} from './rest';

/**
 * Public API for the documents redux module.
 */
export const activity = {
    rest,
    constants,
    dispatchers,
    selectors,
    reducer,
};
