import React from 'react';
import {Close} from '@app/common/icons';
import {tokenSelector} from '@app/redux/user/selectors';

interface ActionModalProps {
    closeModal: any;
    action: any;
    org: any;
}

export const ActionModal = (props: ActionModalProps) => {
    const getWebextUrl = () => {
        const url = new window.URL(
            `${props.org.webext_hostname}${props.action.url}`,
        );
        url.searchParams.set('token', tokenSelector());
        return url.toString();
    };

    return (
        <div className={'kare-modal-overlay'}>
            <div
                className="actions-modal-container"
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <div className="actions-modal-content">
                    <iframe src={getWebextUrl()} />
                    <Close
                        size={21}
                        onClick={() => {
                            props.closeModal();
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
