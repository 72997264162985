import React, {useContext, useEffect, useState} from 'react';
import {ResponsivePie} from '@nivo/pie';

import './Opportunity.scss';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {StoreContext} from '@app/state';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {RadialContentLoader} from '@app/components/ContentLoaders/RadialContentLoader';
import {transformAggregatedOpportunityDataToRadialChart} from '../../../utils/ConversationDataTransform';
import {ChartLegend} from '@app/components/ChartLegend/ChartLegend';

export interface OpportunityProps {}

interface OpportunityData {
    data: any;
    error: any;
    loading: boolean;
}

const initialState: OpportunityData = {
    data: {} as any,
    error: null,
    loading: true,
};

export const Opportunity: React.FC<OpportunityProps> = () => {
    const [opportunities, setOpportunities] =
        useState<OpportunityData>(initialState);
    const {analytics} = useContext(StoreContext);
    const {minDate, maxDate} = useContext(StoreContext).date;

    const getOpportunities = async () => {
        setOpportunities(initialState);
        const res = await analytics.getAggregatedAutomationsByTitle(
            minDate,
            maxDate,
        );
        setOpportunities({
            loading: false,
            error: res.error,
            data: res.data,
        });
    };
    const showEmptyState: string | null =
        !opportunities?.data?.entries ||
        Object.keys(opportunities?.data?.entries).length === 0
            ? 'No data'
            : null;

    useEffect(() => {
        getOpportunities();
    }, [minDate, maxDate]);
    const transformedData = transformAggregatedOpportunityDataToRadialChart(
        opportunities.data,
    );
    return (
        <div>
            <CardTitle title="Automation opportunity" />
            <ErrorLoadingWrapper
                height="200px"
                error={opportunities.error}
                isLoading={opportunities.loading}
                CustomLoading={RadialContentLoader}
                emptyState={showEmptyState}
                render={() => {
                    const chartData = Object.keys(transformedData.plotData).map(
                        (k) => ({
                            id: k,
                            label: k,
                            value: transformedData.plotData[k],
                            color: k === 'handover' ? '#1F6A9D' : '#009445',
                        }),
                    );
                    return (
                        <div className="Opportunity__container">
                            <div className="Opportunity__chartContainer">
                                <ResponsivePie
                                    data={chartData}
                                    margin={{
                                        top: 20,
                                        right: 20,
                                        bottom: 20,
                                        left: 20,
                                    }}
                                    sortByValue={false}
                                    innerRadius={0.7}
                                    padAngle={1}
                                    colors={(c: any) => c.data.color as string}
                                    borderWidth={0}
                                    enableArcLabels={false}
                                    enableArcLinkLabels={false}
                                />
                            </div>
                            <ChartLegend
                                items={[
                                    {
                                        color: '#1F6A9D',
                                        title: 'Handovers',
                                        value: transformedData.legendData
                                            .handoverValue,
                                        percentage:
                                            transformedData.legendData.handover,
                                    },
                                    {
                                        color: '#009445',
                                        title: 'Web extensions',
                                        value: transformedData.legendData
                                            .webextValue,
                                        percentage:
                                            transformedData.legendData.webext,
                                    },
                                ]}
                            />
                        </div>
                    );
                }}
            />
        </div>
    );
};
