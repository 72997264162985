import React, {Fragment, useContext, useEffect, useState} from 'react';

import './DistributionChart.scss';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {BarChart} from '@app/components/BarChart/BarChart';
import ChevronRight from '@material-ui/icons/ChevronRight';
import {transformDistributionDataToBarChart} from '@app/utils/DistributionDataTransform';
import {StoreContext} from '@app/state';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';

interface IDistributionChartProps {
    history: any;
}

interface DistributionData {
    data: any;
    loading: boolean;
    error: string | null;
}

const defaultData: DistributionData = {
    data: {},
    loading: true,
    error: '' as string | null,
};
export const DistributionChart: React.FunctionComponent<
    IDistributionChartProps
> = ({history}) => {
    const [distributionData, setDistributionData] =
        useState<DistributionData>(defaultData);
    const {nodes} = useContext(StoreContext);

    const getDistribution = async () => {
        setDistributionData(defaultData);
        const res = await nodes.getDistribution();
        setDistributionData({
            data: res.data,
            error: res.error,
            loading: false,
        });
    };

    useEffect(() => {
        getDistribution();
    }, []);

    const showEmptyState = () => {
        return !distributionData.data ||
            (!distributionData.data.under_represented &&
                !distributionData.data.over_represented)
            ? 'No data'
            : null;
    };

    return (
        <div className="DistributionChart__container">
            <CardTitle title={'Graph distribution of your responses'} />
            <ErrorLoadingWrapper
                height={'200px'}
                isLoading={distributionData.loading}
                error={distributionData.error}
                emptyState={showEmptyState()}
                render={() => {
                    const underRepresented =
                        distributionData.data.under_represented;
                    const overRepresented =
                        distributionData.data.over_represented;

                    return (
                        <Fragment>
                            <BarChart
                                margin={{
                                    top: 30,
                                    right: 30,
                                    bottom: 70,
                                    left: 40,
                                }}
                                data={transformDistributionDataToBarChart(
                                    distributionData.data,
                                )}
                                keys={['queries']}
                                index={'timeseries'}
                            />
                            <div className="DistributionChart__underPlot">
                                {underRepresented.length > 0 && (
                                    <div
                                        className="DistributionChart__left"
                                        onClick={() => {
                                            history.push('/consolidate/under');
                                        }}
                                    >
                                        <div>
                                            <h1>
                                                {underRepresented.length}{' '}
                                                {underRepresented.length > 1
                                                    ? 'Responses'
                                                    : 'Response'}{' '}
                                                do not have enough queries
                                            </h1>
                                            <p>
                                                Add more queries to improve the
                                                precision.
                                            </p>
                                        </div>
                                        <ChevronRight />
                                    </div>
                                )}
                                {overRepresented.length > 0 && (
                                    <div
                                        className="DistributionChart__right"
                                        onClick={() => {
                                            history.push('/consolidate/over');
                                        }}
                                    >
                                        <div>
                                            <h1>
                                                {overRepresented.length}{' '}
                                                {overRepresented.length > 1
                                                    ? 'Responses'
                                                    : 'Response'}{' '}
                                                have too many queries
                                            </h1>
                                            <p>
                                                Clean up the responses for
                                                better performance.
                                            </p>
                                        </div>
                                        <ChevronRight />
                                    </div>
                                )}
                            </div>
                        </Fragment>
                    );
                }}
            />
        </div>
    );
};
