import React from 'react';
import {
    MdChatBubble,
    MdEmail,
    MdLink,
    MdPhone,
    MdFlashOn,
} from 'react-icons/md';
import {get} from 'lodash';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';

export const getHandoverIcon = (icon, props, noRender = false) => {
    switch (icon) {
        case 'link':
        case 'url':
            return noRender ? MdLink : <MdLink {...props} />;
        case 'contact':
            return noRender ? MdChatBubble : <MdChatBubble {...props} />;
        case 'email':
            return noRender ? MdEmail : <MdEmail {...props} />;
        case 'phone':
        default:
            return noRender ? MdPhone : <MdPhone {...props} />;
    }
};

export const WebExtMessage = (props) => {
    const text: string = props?.message?.body?.text;
    const actions = props?.message?.event_body?.out_text?.actions || '';
    const aspect = get(props, ['message', 'aspect'], null);
    const actionText = get(actions, ['0', 'text'], '');

    const icon = get(
        props,
        ['message', 'event_body', 'out_text', 'actions', 0, 'icon'],
        '',
    );

    const newClassName = cx(
        'chat__message',
        'chat__text-message',
        'chat__message',
    );

    if (aspect.toString() === 'open/webext') {
        return (
            <div className="console-activity__used-automation">
                <p>
                    <MdFlashOn /> USED AUTOMATION
                </p>
                <p>{text}</p>
            </div>
        );
    }
    return (
        <div
            className={cx(
                'console-activity-message-container',
                'console-activity-message',
                'console-activity-message--out',
                'console-activity-message--webext',
            )}
        >
            <div className={newClassName}>
                <div className="chat__text-message-header">
                    <ReactMarkdown key={text} className="markdown">
                        {text}
                    </ReactMarkdown>
                    <div
                        className="webext-call-to-action-container outlined"
                        style={{cursor: 'normal'}}
                    >
                        <div className="webext-call-to-action">
                            {icon && getHandoverIcon(icon, props)}
                            <span>{actionText}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
