import React from 'react';
import cx from 'classnames';

import {Close} from '@app/common/icons';
import {MdVisibility} from 'react-icons/md';

interface KareTextInputProps {
    placeholder?: string;
    value?: string;
    onChange?: any;
    error?: boolean;
    tight?: boolean;
    label?: string;
    tip?: string;
    input: any;
    meta?: any;
    clearable?: boolean;
    readonly?: boolean;
    appendText?: string;
    type?: string;
    onKeyDown?: any;
    disabled?: boolean;
}

export class KareTextInput extends React.Component<KareTextInputProps> {
    state = {
        showPassword: false,
    };

    handleClickClear = () => {
        const {
            input: {onChange},
        } = this.props;
        onChange('');
    };

    render() {
        const {
            placeholder,
            input,
            meta: {touched, error, warning},
            label: label,
            tight,
            tip,
            type = input.type,
            clearable,
            readonly,
            appendText,
            disabled,
        } = this.props;

        const className = cx('kare-text-input', {
            'kare-text-input--tight': tight,
            'kare-text-input--has-tip': tip,
            'kare-text-input--readonly': readonly,
        });
        return (
            <div className={className}>
                {label && (
                    <label
                        htmlFor={input.name}
                        className={'kare-text-input__label'}
                    >
                        {label}
                    </label>
                )}
                {tip && <div className="kare-text-input__tip">{tip}</div>}
                <div className="kare-text-input__input-row">
                    <input
                        {...input}
                        type={
                            type === 'password'
                                ? this.state.showPassword
                                    ? 'text'
                                    : 'password'
                                : type
                        }
                        readOnly={readonly}
                        id={input.name}
                        className={cx('kare-text-input__field', {
                            'kare-text-input__field--has-error':
                                error && touched,
                        })}
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                    {type === 'password' && (
                        <MdVisibility
                            onMouseOver={() => {
                                this.setState({showPassword: true});
                            }}
                            onMouseOut={() => {
                                this.setState({showPassword: false});
                            }}
                        />
                    )}
                    {clearable && (
                        <Close
                            size={20}
                            className="kare-text-input__clear-icon"
                            onClick={this.handleClickClear}
                        />
                    )}
                    {appendText && (
                        <span className="kare-text-input__append-text">
                            {appendText}
                        </span>
                    )}
                </div>

                {error && touched && (
                    <small className="kare-text-input__info kare-text-input__info--error">
                        {error}
                    </small>
                )}
                {warning && (
                    <small className="kare-text-input__info kare-text-input__info--warning">
                        {warning}
                    </small>
                )}
            </div>
        );
    }
}
