import React, {createContext, SetStateAction, useState} from 'react';
import moment, {Moment} from 'moment';

import {CxMetrics} from './CxMetrics';
import {Nodes} from './Nodes';
import {Clusters} from './Clusters';
import {Library} from './Library';
import {TimeSeries} from './TimeSeries';
import {Analytics} from './Analytics';
import {EntryPageAPI} from './EntryPage';
import {BeaconsApi} from './Beacons';
import {ExitPageAPI} from './ExitPage';

const entryPageApi = new EntryPageAPI();
const exitPageApi = new ExitPageAPI();
const beaconsApi = new BeaconsApi();
const cxMetrics = new CxMetrics();
const nodes = new Nodes();
const clusters = new Clusters();
const library = new Library();
const analytics = new Analytics();
const timeSeries = new TimeSeries();
const today = moment().endOf('day');
const olderDate = moment().subtract(15, 'days').startOf('day');
type IStoreProvider = {
    children: React.ReactNode;
};

export type IStoreValue = {
    cxMetrics: CxMetrics;
    library: Library;
    clusters: Clusters;
    nodes: Nodes;
    timeSeries: TimeSeries;
    analytics: Analytics;
    entryPageApi: EntryPageAPI;
    beaconsApi: BeaconsApi;
    exitPageApi: ExitPageAPI;
    date: {
        minDate: Moment;
        maxDate: Moment;
        setMinDate: React.Dispatch<SetStateAction<Moment>>;
        setMaxDate: React.Dispatch<SetStateAction<Moment>>;
    };
};
export const StoreContext = createContext<IStoreValue>({} as IStoreValue);

const parseMoment = (d) => moment(d, 'X');

const getMinDateInSessionStorage = () => {
    if (sessionStorage.getItem('kare:dashboard-min-date')) {
        return parseMoment(sessionStorage.getItem('kare:dashboard-min-date'));
    } else {
        return olderDate;
    }
};

const getMaxDateInSessionStorage = () => {
    if (sessionStorage.getItem('kare:dashboard-max-date')) {
        return parseMoment(sessionStorage.getItem('kare:dashboard-max-date'));
    } else {
        return today;
    }
};

export const StoreProvider = ({children}: IStoreProvider) => {
    const [minDate, setMinDate] = useState(getMinDateInSessionStorage());
    const [maxDate, setMaxDate] = useState(getMaxDateInSessionStorage());
    sessionStorage.setItem(
        'kare:dashboard-max-date',
        moment(maxDate).format('X'),
    );
    sessionStorage.setItem(
        'kare:dashboard-min-date',
        moment(minDate).format('X'),
    );

    const store = {
        cxMetrics,
        analytics,
        library,
        clusters,
        nodes,
        timeSeries,
        entryPageApi,
        beaconsApi,
        exitPageApi,
        date: {minDate, setMinDate, maxDate, setMaxDate},
    };
    return (
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    );
};

export type Call<T> = {
    error?: string;
    loading: boolean;
    data: T;
};
