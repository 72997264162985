import React from 'react';
import cx from 'classnames';

import {ValidationError} from '@app/common';
import {ValidationWarning} from '@app/common';
import {HelpOutline} from '@app/common/icons';
import {Tooltip} from '@app/common/tooltip';

interface LabelProps {
    error?: string;
    text?: string;
    warning?: string;
    children?: any;
    fixed?: boolean;
    showError?: boolean;
    showText?: boolean;
    tooltip?: string;
}

export class Label extends React.Component<LabelProps> {
    renderTooltip = () => {
        const {tooltip} = this.props;
        return (
            <Tooltip
                overlay={tooltip}
                tooltipProps={{
                    placement: 'left',
                }}
            >
                <HelpOutline size={16} className="label-tooltip-icon" />
            </Tooltip>
        );
    };

    render() {
        const {
            text,
            showText = true,
            children,
            showError,
            error,
            warning,
            tooltip,
        } = this.props;
        const containerClass = cx('c-field', 'c-label-field', {
                'c-label-field--empty': React.Children.count(children) === 0,
            }),
            labelClass = cx('c-label-field__text', {});
        return (
            <label className={containerClass}>
                {tooltip && this.renderTooltip()}
                {showText ? <span className={labelClass}>{text}</span> : null}
                {children}
                {showError && <ValidationError>{error}</ValidationError>}
                {warning && <ValidationWarning>{warning}</ValidationWarning>}
            </label>
        );
    }
}
