import {
    IMPROVE_ANSWER_ACTION,
    RESET_RESPONSE_CARD,
    SELECT_RESPONSE_ACTION,
} from '../constants';

export const selectResponseAction = (payload) => ({
    type: SELECT_RESPONSE_ACTION,
    payload: payload,
});

export const setImproveAnswer = (payload) => ({
    type: IMPROVE_ANSWER_ACTION,
    payload: payload,
});

export const resetSelectResponseStateAction = () => ({
    type: RESET_RESPONSE_CARD,
});
