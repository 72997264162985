import React from 'react';
import {NavLink} from 'react-router-dom';
import {DialpadLogo} from '../assets/logos';

export const LockDown = () => {
    return (
        <div className="lockdown">
            <DialpadLogo width={250} height={72} color={'#6C3DFF'} />
            <h1>Account locked</h1>
            <span>
                Access to your account has been temporarily blocked due to too
                many failed login attempts.
                <br />
                If it wasn’t you please contact your system administrator.
                Otherwise please come back later.
            </span>
            <NavLink className="c-btn" to="/" exact={true}>
                <span style={{textTransform: 'none'}}>Go back</span>
            </NavLink>
        </div>
    );
};
