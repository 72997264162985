import React from 'react';
import {Helmet} from 'react-helmet';
import {
    Button,
    CheckboxInput,
    KareDropdown,
    KareTextInput,
    RadioInput,
    KareSwitch,
} from '@app/common';
import {Create} from '@app/common/icons';
import {Field, reduxForm} from 'redux-form';

export class StyleGuideComponent extends React.Component {
    render() {
        const selectOptions = [
            {value: 'vanilla', label: 'Vanilla'},
            {value: 'chocolate', label: 'Chocolate'},
            {value: 'strawberry', label: 'Strawberry'},
            {value: 'salted-caramel', label: 'Salted Caramel'},
        ];

        const selectProps = {
            options: selectOptions,
            input: {
                value: {
                    value: 'vanilla',
                    label: 'Vanilla',
                },
                onChange: () => {},
            },
        };

        return (
            <form className="o-panel styleguide">
                <Helmet title="Styleguide" />
                <h2>Button</h2>

                <div className="styleguide__component-group styleguide__component-group-buttons">
                    <div>
                        <Button>primary</Button>
                        <Button type={'secondary'}>secondary</Button>
                        <Button icon={Create}>primary</Button>
                        <Button iconRight={Create}>icon right</Button>
                    </div>
                    <div>
                        <Button showSpinner={true}>loading</Button>
                        <Button showSpinner={true} iconRight={Create}>
                            loading with icon right
                        </Button>
                        <Button disabled={true}>disabled</Button>
                    </div>
                    <div>
                        <Button outlined>outlined</Button>
                        <Button outlinedPrimary={true}>
                            outlined priimary
                        </Button>
                        <Button outlined type={'secondary'}>
                            outlined secondary
                        </Button>
                        <Button outlined icon={Create}>
                            outlined
                        </Button>
                        <Button disabled={true} outlined>
                            outlined
                        </Button>
                    </div>
                    <div>
                        <Button inverted>text</Button>
                        <Button inverted icon={Create}>
                            text
                        </Button>
                        <Button disabled={true} inverted>
                            text
                        </Button>
                    </div>
                    <div>
                        <Button inverted secondaryText={true}>
                            text
                        </Button>
                        <Button inverted icon={Create} secondaryText={true}>
                            text
                        </Button>
                        <Button disabled={true} inverted secondaryText={true}>
                            text
                        </Button>
                    </div>
                </div>
                <h2>Typography</h2>
                <div className="styleguide__component-group">
                    <p className="kare-h1">
                        <span className="class-name">@include kare-h1():</span>
                        Lorem ipsum dolor sit amet...
                    </p>
                    <p className="kare-h2">
                        <span className="class-name">@include kare-h2():</span>
                        Lorem ipsum dolor sit amet...
                    </p>
                    <p className="kare-h3">
                        <span className="class-name">@include kare-h3():</span>
                        Lorem ipsum dolor sit amet...
                    </p>
                    <p className="kare-h4">
                        <span className="class-name">@include kare-h4():</span>
                        Lorem ipsum dolor sit amet...
                    </p>
                    <p className="kare-h5">
                        <span className="class-name">@include kare-h5():</span>
                        Lorem ipsum dolor sit amet...
                    </p>
                    <p className="kare-h6">
                        <span className="class-name">@include kare-h6():</span>
                        Lorem ipsum dolor sit amet...
                    </p>
                    <p className="kare-h7">
                        <span className="class-name">@include kare-h7():</span>
                        Lorem ipsum dolor sit amet...
                    </p>
                    <p className="kare-h8">
                        <span className="class-name">@include kare-h8():</span>
                        Lorem ipsum dolor sit amet...
                    </p>
                    <p className="kare-overline-1">
                        <span className="class-name">
                            @include kare-overline-1():
                        </span>
                        Lorem ipsum dolor sit amet...
                    </p>
                    <p className="kare-overline-2">
                        <span className="class-name">
                            @include kare-overline-2():
                        </span>
                        Lorem ipsum dolor sit amet...
                    </p>
                    <p className="kare-overline-3">
                        <span className="class-name">
                            @include kare-overline-3():
                        </span>
                        Lorem ipsum dolor sit amet...
                    </p>
                    <p className="kare-body">
                        <span className="class-name">
                            @include kare-body():
                        </span>
                        Lorem ipsum dolor sit amet...
                    </p>
                    <p className="kare-caption">
                        <span className="class-name">
                            @include kare-caption():
                        </span>
                        Lorem ipsum dolor sit amet...
                    </p>
                    <p className="kare-button-1-text">
                        <span className="class-name">
                            @include kare-button-1-text():
                        </span>
                        Lorem ipsum dolor sit amet...
                    </p>
                    <p className="kare-button-2-text">
                        <span className="class-name">
                            @include kare-button-2-text():
                        </span>
                        Lorem ipsum dolor sit amet...
                    </p>
                </div>
                <h2>Inputs</h2>
                <div className="styleguide__component-group">
                    <h3>Text</h3>
                    <KareTextInput
                        placeholder={'Text input '}
                        input={{name: 'blah'}}
                        meta={{
                            error: undefined,
                            touched: false,
                            warning: false,
                        }}
                    />
                    <KareTextInput
                        placeholder={'Text input with error'}
                        input={{name: 'blah'}}
                        meta={{
                            error: 'Some error',
                            touched: true,
                            warning: false,
                        }}
                    />
                    <KareTextInput
                        placeholder={'Text input with error and warning'}
                        input={{name: 'blah'}}
                        meta={{
                            error: 'Some error',
                            touched: true,
                            warning: "I'm warning you",
                        }}
                    />
                </div>
                <div className="styleguide__component-group">
                    <h3>Radio</h3>
                    <RadioInput radioGroup={'radio-group'} />
                    <RadioInput radioGroup={'radio-group'} />
                    <RadioInput radioGroup={'radio-group'} label={'Label'} />
                    <RadioInput radioGroup={'radio-group'} disabled />
                </div>
                <div className="styleguide__component-group">
                    <CheckboxInput
                        label="Unchecked"
                        input={{
                            value: false,
                            name: 'some_name',
                        }}
                        meta={{
                            error: undefined,
                            touched: false,
                            active: false,
                        }}
                    />
                    <CheckboxInput
                        label="Checked"
                        input={{
                            value: true,
                            name: 'some_name',
                        }}
                        meta={{
                            error: undefined,
                            touched: false,
                            active: false,
                        }}
                    />
                </div>
                <div className="styleguide__component-group">
                    <h3>Switch</h3>
                    <Field name={'switch'} component={KareSwitch} />
                    <Field
                        name={'switch-disabled'}
                        component={KareSwitch}
                        disabled={true}
                    />
                </div>
                <div className="styleguide__component-group">
                    <h3>Dropdown</h3>
                    <KareDropdown {...selectProps} />
                </div>
            </form>
        );
    }
}

export const StyleGuide = reduxForm({form: 'styleguide'})(StyleGuideComponent);
