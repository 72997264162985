import React, {Component} from 'react';
import {ResponseViewer} from './response-viewer';

import {connect} from 'react-redux';

interface ResponseProps {
    selectedResponse: any;
    isNewResponse?: any;
    openEditor?: any;
    onSubmit?: any;
}

export class ResponseComponent extends Component<ResponseProps> {
    render() {
        return <ResponseViewer {...this.props} />;
    }
}
const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = () => ({});

export const Response = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResponseComponent);
