import React from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {withRouter} from 'react-router-dom';
import {getSubdomainFromURL} from '@app/utils/client-url';
import {getAuths, getCookie} from '@app/utils/auth';
import {getZone} from '@app/zone';
import {getSuccessUrl} from '../../utils';
import {dialpadAuthorizeCodeGrantDispatcher} from '@app/redux/user/dispatchers';
import {Dialpad} from '@app/assets/icons';
import {LoginTitle} from './authorize.styles';
import {Spinner} from '@app/common/spinner';
import {OAUTH_CSRF_COOKIE} from '../../constants';
import {eraseCookie} from '@app/utils/auth';
import {Link} from 'react-router-dom';

interface completeProps {
    payloadParams?: {
        state: string;
    };
    expectedState?: string;
    location: {search: string};
    login: (...args: any) => any;
}

const getAuthorizeParams = () => {
    const auths = getAuths();
    const u = new URL(window.location.toString());
    const subdomain = getSubdomainFromURL();
    const provider = u.searchParams.get('provider');

    return {
        dialpad_client_id:
            u.searchParams.get('client_id') ||
            auths.find((auth) => auth.name === provider)?.client?.clientID,
        auth_provider: u.searchParams.get('provider'),
        code: u.searchParams.get('code'),
        state: u.searchParams.get('state'),
        subdomain: subdomain,
    };
};

class DialpadOAuthLoginCompleteComponent extends React.Component<completeProps> {
    state = {
        errorDesc: null,
    };

    componentDidMount() {
        if (
            !this.props.payloadParams.state ||
            this.props.payloadParams.state !== this.props.expectedState
        ) {
            toast.error('Login failed: CSRF token mismatch');
            eraseCookie(OAUTH_CSRF_COOKIE);
            return;
        }
        this.props
            .login(this.props.payloadParams)
            .then(() => {
                const successURL = getSuccessUrl(
                    this.props.location.search,
                ) as string;
                eraseCookie(OAUTH_CSRF_COOKIE);
                window.location.replace(successURL);
            })
            .catch((err) => {
                eraseCookie(OAUTH_CSRF_COOKIE);
                const errorDescription = err.error.split('desc = ')?.[1];
                toast.error(`Login failed: ${errorDescription}`);
                this.setState({
                    errorDesc: errorDescription,
                });
            });
    }

    render() {
        let renderedErrorBody;

        if (this.props.payloadParams.state !== this.props.expectedState) {
            toast.error('Login failed: CSRF token mismatch');
            renderedErrorBody = (
                <>
                    <h2 className="title">Login state does not match</h2>
                    <Link to="/login" className="c-btn">
                        Return to login
                    </Link>
                </>
            );
        }

        if (this.state.errorDesc) {
            renderedErrorBody = (
                <>
                    <h2 className="title">Login failed</h2>
                    <Link to="/login" className="c-btn">
                        Return to login
                    </Link>
                </>
            );
        }

        return (
            <div className="loginWrapper">
                <div className="loginContainer">
                    {renderedErrorBody ?? (
                        <>
                            <h2 className="title">Dialpad Single Sign-on</h2>
                            <Spinner withCenteredContainer={true} />
                            <Dialpad size={15} color="#6C3DFF" />
                            <LoginTitle>Logging in with Dialpad...</LoginTitle>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        zone: getZone(),
        subdomain: getSubdomainFromURL(),
        payloadParams: getAuthorizeParams(),
        expectedState: getCookie(OAUTH_CSRF_COOKIE),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (params) =>
            dialpadAuthorizeCodeGrantDispatcher(dispatch, params),
    };
};

export const DialpadLoginComplete = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(DialpadOAuthLoginCompleteComponent),
);
