import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {redux} from '@app/redux';
import {FlagIcon} from '@app/common';
import {Check} from '@app/common/icons';
import {locale as localeUtil} from '@app/utils';

interface OptionProps {
    locale?: string;
    isActive?: boolean;
    setContentLocale: any;
}

const OptionComponent: React.FC<OptionProps> = ({
    locale,
    isActive,
    setContentLocale,
}) => {
    const handleClickLanguageSelector = () => {
        setContentLocale(locale);
        location.reload();
    };

    return (
        <button
            className="language-picker-option"
            onClick={handleClickLanguageSelector}
        >
            <FlagIcon code={localeUtil.getCountryCode(locale).toLowerCase()} />
            <span className="language-picker-option-text">
                {localeUtil.getLocaleName(locale)}
            </span>
            {isActive && (
                <Check
                    className="language-picker-option-active-icon"
                    size={20}
                />
            )}
        </button>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setContentLocale: (locale) =>
        dispatch(
            redux.locale.actionCreators.setContentLocaleActionCreator(locale),
        ),
});

export const Option = withRouter(
    connect(null, mapDispatchToProps)(OptionComponent),
);
