export const STATUS_ESCALATED = 'escalated';
export const STATUS_BOUNCED = 'bounced';
export const STATUS_RESOLVED = 'resolved';
export const STATUS_ACTIVE = 'active';
export const STATUS_UNKNOWN = 'unknown';

export const CONVERSATION_STATS = {
    USER_ID: 'user_id',
    QUERIES: 'queries',
    ANSWERED: 'answered',
    CHOICE: 'choice',
    MISSING: 'missing',
    FORMS: 'messages',
    WEBEXT: 'opened_webext',
    HANDOVER: 'opened_handovers',
    LINKS: 'opened_links',
    THUMBS_UP: 'thumbs_up',
    THUMBS_DOWN: 'thumbs_down',
    ESCALATIONS: 'escalations',
    STATUS: 'status',
};
