import React from 'react';
import {connect} from 'react-redux';

import {redux} from '@app/redux';
import {clientUrl} from '@app/utils';
import {ResetPasswordEmailForm} from './reset-password-email-form';
import {getCaptchaToken} from '@app/utils/recaptcha/recaptcha';

interface RequestResetPasswordProps {
    requestResetPasswordEmail: any;
}

import {Wrapper, Container, H2} from './requestResetPassword.styles';

export class RequestResetPasswordComponent extends React.Component<RequestResetPasswordProps> {
    render() {
        return (
            <Wrapper>
                <Container>
                    <H2>Reset your password</H2>
                    <ResetPasswordEmailForm
                        /*@ts-ignore*/
                        onSubmit={this.props.requestResetPasswordEmail}
                    />
                    <div className="content-recaptcha-branding">
                        This site is protected by reCAPTCHA and the Google
                        <a href="https://policies.google.com/privacy">
                            {' '}
                            Privacy Policy{' '}
                        </a>
                        and
                        <a href="https://policies.google.com/terms">
                            {' '}
                            Terms of Service{' '}
                        </a>
                        apply.
                    </div>
                </Container>
            </Wrapper>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        requestResetPasswordEmail: (params) => {
            getCaptchaToken('login', (token) => {
                const body = {
                    subdomain: params.subdomain,
                    email: params.email,
                    recover_url: clientUrl.getRecoveryUrl(),
                    captcha_token: token,
                };
                dispatch(
                    redux.rest.actions[redux.user.constants.RECOVER_PASSWORD](
                        {},
                        {body: JSON.stringify(body)},
                    ),
                );
                ownProps.history.push('/reset-password/email-sent');
            });
        },
    };
};

export const RequestResetPassword = connect(
    null,
    mapDispatchToProps,
)(RequestResetPasswordComponent);
