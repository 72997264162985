import React, {useState, useContext, useEffect} from 'react';
import {values, keys} from 'lodash';
import {Moment} from 'moment';

import './UrlsTable.scss';
import {Card} from '@app/components/Card/Card';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {Table} from '@app/components/Table/Table';
import {StoreContext} from '@app/state';
import {DropDown} from '@app/components/inputs/dropdown/DropDown';

export interface UrlsTableProps {
    minDate: Moment;
    maxDate: Moment;
}

interface UrlData {
    data: any;
    loading: boolean;
    error: string;
}

const initialDataState: UrlData = {
    data: {},
    loading: true,
    error: '',
};

export const UrlsTable: React.FC<UrlsTableProps> = (props) => {
    const {entryPageApi} = useContext(StoreContext);
    const [data, setData] = useState<UrlData>(initialDataState);
    const [selectedDomain, setSelectedDomain] = useState(null);

    useEffect(() => {
        let isMounted = true;
        entryPageApi
            .getTrafficSourceByURL(props.minDate, props.maxDate)
            .then((response) => {
                if (!isMounted) return;
                if (response.data && response?.data?.entries) {
                    delete response?.data?.entries[''];
                    setData({
                        loading: false,
                        error: '',
                        data: response.data,
                    });
                    setSelectedDomain(keys(response.data.entries)[0]);
                } else {
                    setData({
                        loading: false,
                        error: response.error || '',
                        data: {},
                    });
                }
            });
        return () => {
            isMounted = false;
        };
    }, [props.minDate, props.maxDate]);

    const showEmptyState = () => {
        if (
            !data ||
            !data.data.entries ||
            values(data.data.entries).length === 0
        ) {
            return 'empty';
        }
        return '';
    };

    return (
        <Card>
            <div className="UrlsTable__titleContainer">
                <CardTitle
                    title="Show traffic from"
                    className="UrlsTable__title"
                />
                {data.data.entries &&
                    values(data.data.entries).length > 0 &&
                    !data.error && (
                        <DropDown
                            className="UrlsTable__dropdown"
                            value={{
                                label: selectedDomain,
                                value: selectedDomain,
                            }}
                            options={keys(data.data.entries).map((k) => ({
                                label: k,
                                value: k,
                            }))}
                            onChange={(v) => {
                                setSelectedDomain(v.label);
                            }}
                        />
                    )}
            </div>
            <ErrorLoadingWrapper
                height={'400px'}
                isLoading={data.loading}
                error={data.error}
                emptyState={showEmptyState()}
                render={() => {
                    return (
                        <Table
                            showPosition={false}
                            columns={[
                                'source',
                                'started conversations',
                                'questions asked',
                            ]}
                            rows={tableRowsFromURLsData(
                                data.data,
                                selectedDomain,
                            )}
                        />
                    );
                }}
            />
        </Card>
    );
};

export function tableRowsFromURLsData(
    data: any,
    selectedDomain: string | null,
): any[] {
    if (!data || !selectedDomain) {
        return [];
    }
    const tableCells: {value: string | number}[][] = [];
    const entries = values(data.entries[selectedDomain]);
    for (let i = 0; i < entries.length; i++) {
        const e = entries[i];
        if (!e || !e.url || !e.counters) {
            continue;
        }
        tableCells.push([
            {value: e.url},
            {value: e.counters?.opened || 0},
            {value: e.counters?.engaged || 0},
        ]);
    }
    return tableCells;
}
