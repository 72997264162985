import React from 'react';
import {getFormValues, reduxForm, Field} from 'redux-form';
import {connect} from 'react-redux';
import {DASHBOARD_FORM} from '../constants';
import {redux} from '@app/redux';
import {SingleDatePickerField} from './single-date-picker-field';
import {getInitialDateValues} from '../utils';
import {KeyboardArrowDown} from '@app/common/icons';
import {ErrorBoundary} from '@app/common';
import {
    getConversationMetrics,
    getFeedbackMetrics,
    getQueryMetrics,
} from '@app/redux/metrics/dispatchers';

interface DatePickerProps {
    getResponseCounters: any;
    getConversationMetrics: any;
    getQueryMetrics: any;
    getFeedbackMetrics: any;
}

export class DatePickerComponent extends React.Component<DatePickerProps, any> {
    getMetrics() {
        this.props.getConversationMetrics({
            dateRange: {
                from: getInitialDateValues().from,
                to: getInitialDateValues().to,
            },
        });
        this.props.getQueryMetrics({
            dateRange: {
                from: getInitialDateValues().from,
                to: getInitialDateValues().to,
            },
        });
        this.props.getFeedbackMetrics({
            dateRange: {
                from: getInitialDateValues().from,
                to: getInitialDateValues().to,
            },
        });
    }

    componentDidMount() {
        this.props.getResponseCounters({
            dateRange: getInitialDateValues(),
        });

        this.getMetrics();
    }

    render() {
        return (
            <ErrorBoundary>
                <form className="dashboard--form dashboard--date-picker">
                    <h2>All Customer activity</h2>
                    <span>Date range:</span>
                    <Field component={SingleDatePickerField} name="dateRange" />
                    <KeyboardArrowDown
                        className={'dashboard--chevron'}
                        size={16}
                    />
                </form>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => ({
    dashboardForm: getFormValues(DASHBOARD_FORM)(state),
});

const mapDispatchToProps = (dispatch) => ({
    getConversationMetrics: getConversationMetrics(dispatch),
    getQueryMetrics: getQueryMetrics(dispatch),
    getFeedbackMetrics: getFeedbackMetrics(dispatch),
    getResponseCounters:
        redux.activity.dispatchers.getResponseCountersDispatcher(dispatch),
});

export const DatePicker = connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    reduxForm({
        form: DASHBOARD_FORM,
        enableReinitialize: true,
        initialValues: {
            dateRange: {
                from: getInitialDateValues().from,
                to: getInitialDateValues().to,
            },
        },
        onChange: (values, dispatch) => {
            redux.activity.dispatchers.getResponseCountersDispatcher(dispatch)(
                values,
            );
            getConversationMetrics(dispatch)(values);
            getQueryMetrics(dispatch)(values);
            getFeedbackMetrics(dispatch)(values);
        },
    })(DatePickerComponent),
);
