import React, {ChangeEvent} from 'react';
import {useDispatch} from 'react-redux';
import {addNotification as notify} from 'reapop';

import './KareAvatarUpload.scss';
import {Add} from '@app/common/icons';
import Notification from '@app/utils/notifications';
import {Avatar} from '@app/settings/widget-form';

interface KareAvatarUploadInputProps {
    validateAvatar?: (event: ChangeEvent<HTMLInputElement>) => void;
    targetAvatar?: any;
}

const KareAvatarUploadInput = (props: KareAvatarUploadInputProps) => {
    const {validateAvatar, targetAvatar} = props;
    return (
        <input
            className="KareAvatarUpload__avatarInput"
            type="file"
            id="avatarFile"
            name="avatarFile"
            onChange={(e) => validateAvatar(e)}
            accept="image/png, image/jpeg, image/jpg"
            value={targetAvatar}
        />
    );
};

interface KareAvatarUploadProps {
    input?: any;
}

export const KareAvatarUpload = (props: KareAvatarUploadProps) => {
    const {input} = props;

    const dispatch = useDispatch();

    const fileExtValidate = (fdata): boolean => {
        const validExt = '.png, .gif, .jpeg, .jpg';
        const filePath = fdata.value;
        const getFileExt = filePath
            .substring(filePath.lastIndexOf('.') + 1)
            .toLowerCase();
        const pos = validExt.indexOf(getFileExt);
        if (pos < 0) {
            dispatch(
                notify(
                    Notification.widgetSettingsAvatarValidationError(
                        'Unrecognized format, please upload an image.',
                    ),
                ),
            );
            return false;
        }
        return true;
    };

    const fileSizeValidate = (fdata): boolean => {
        const maxSizeKB = 100;
        if (fdata.files && fdata.files[0]) {
            const fsize = fdata.files[0].size / 1024;
            if (fsize > maxSizeKB) {
                dispatch(
                    notify(
                        Notification.widgetSettingsAvatarValidationError(
                            `Maximum file size exceeded (${Math.round(
                                fsize,
                            )}KB of ${maxSizeKB}KB).`,
                        ),
                    ),
                );
                return false;
            }
            return true;
        }
    };

    const validateAvatar = (event: ChangeEvent<HTMLInputElement>) => {
        const targetElement: HTMLInputElement = event.target;
        if (fileExtValidate(targetElement) && fileSizeValidate(targetElement)) {
            const targetAvatar: Avatar = {
                file: targetElement.files[0],
                blob: URL.createObjectURL(targetElement.files[0]),
                title: targetElement.files[0].name,
                size: Math.floor(targetElement.files[0].size / 1024),
            };
            input.onChange(targetAvatar);
        } else if (!targetElement.files.length) input.onChange('');
    };

    return (
        <div className="KareAvatarUpload__kareAvatar">
            <label>AVATAR IMAGE</label>
            {/*            <span>
                Image should be 60 x 60px, JPG or PNG. Max file size 100kb
            </span>*/}
            {(!input.value && (
                <div className="KareAvatarUpload__kareAvatarWrapper">
                    <div className="KareAvatarUpload__kareAvatarUpload">
                        <div className="KareAvatarUpload__addImage">
                            <Add size={18} />
                        </div>
                        <KareAvatarUploadInput
                            validateAvatar={validateAvatar}
                        />
                    </div>
                </div>
            )) || (
                <div className="KareAvatarUpload__kareAvatarWrapper">
                    <div className="KareAvatarUpload__kareAvatarUpload">
                        <img src={input.value.blob} alt={'Avatar'} />
                    </div>
                    <div className="KareAvatarUpload__kareAvatarOptions">
                        <div className="KareAvatarUpload__kareAvatarInfo">
                            <span>{`${input.value.title} (${input.value.size}kb)`}</span>
                        </div>
                        <div className="KareAvatarUpload__kareAvatarActions">
                            <span className="KareAvatarUpload__changeImage">
                                <span className="KareAvatarUpload__changeImageText">
                                    CHANGE IMAGE
                                </span>
                                <KareAvatarUploadInput
                                    validateAvatar={validateAvatar}
                                />
                            </span>
                            <span className="KareAvatarUpload__divider">•</span>
                            <span
                                onClick={() => {
                                    input.onChange('');
                                }}
                                className="KareAvatarUpload__removeImage"
                            >
                                REMOVE IMAGE
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
