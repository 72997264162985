import {
    dialpadCallCenterList,
    isDialpadCallCenterListLoadingSelector,
} from '@app/redux/escalations/selectors';
import {listDialpadCallcenters} from '@app/redux/escalations/dispatchers';
import {connect} from 'react-redux';
import {KareDropdown} from '@app/common';
import {validators} from '../../../utils';
import React, {useEffect, useState} from 'react';
import {isEmpty} from 'lodash';
import {getAuths} from '@app/utils/auth';
import {Field} from 'redux-form';

const DialpadCallComponent = ({
    selectedEscalationMethodIndex,
    dialpadCallcenterListSelector,
    dispatchListDialpadCallcenters,
    dialpadCallcenterListLoadingSelector,
    change,
}) => {
    useEffect(() => {
        if (isEmpty(dialpadCallcenterListSelector) && !hasFetched && !error) {
            const auths = getAuths();
            const authProvider = auths.find((auth) =>
                auth.name.includes('dialpad'),
            );
            if (authProvider)
                dispatchListDialpadCallcenters(authProvider.name)
                    .then(() => setHasFetched(true))
                    .catch((error) => {
                        if (error === 'isLoading') return;
                        setError(error);
                    });
        }
    }, [dialpadCallcenterListLoadingSelector]);

    const [error, setError] = useState();
    const [hasFetched, setHasFetched] = useState(false);
    const getLabelByCallcenterId = (callcenterId) =>
        dialpadCallcenterListSelector.find(
            (callcenter) => callcenter.value === callcenterId,
        )?.label;

    return (
        <div className="escalation-settings__modal__content__row escalation-settings_spain_margins">
            <p>Callcenter</p>
            <Field
                name={`escalation_entries[${selectedEscalationMethodIndex}].value`}
                onKeyPress={(e) => {
                    if (e && e.key && e.key === 'Enter') {
                        e.preventDefault();
                    }
                }}
                component={KareDropdown}
                props={{
                    options: dialpadCallcenterListSelector,
                    isLoading: dialpadCallcenterListLoadingSelector,
                    isDisabled: dialpadCallcenterListLoadingSelector,
                }}
                normalize={(value) => value.value}
                format={(value) => {
                    return {
                        value,
                        label: getLabelByCallcenterId(value),
                    };
                }}
                onBlur={(e) => e.preventDefault()}
                onChange={(e) => {
                    change(
                        `escalation_entries[${selectedEscalationMethodIndex}].name`,
                        e?.label,
                    );
                }}
                validate={validators.required}
                placeholder={'Select...'}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    dialpadCallcenterListSelector: dialpadCallCenterList(state),
    dialpadCallcenterListLoadingSelector:
        isDialpadCallCenterListLoadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    dispatchListDialpadCallcenters: (provider) =>
        listDialpadCallcenters(dispatch, {provider}),
});

export const DialpadCallback = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DialpadCallComponent);
