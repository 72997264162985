import {get} from 'lodash';

/**
 * Custom transformer creator for redux-api
 * @see https://github.com/lexich/redux-api/blob/master/docs/DOCS.md#transformer
 * Attaches the id to the object that is stored in redux store.
 */
export const getResponseTransformer = (uuidv1) => (data, prevData, action) => {
    const id = get(action, ['request', 'pathvars', 'id']);
    const response = get(data, ['response'], {});
    const blocks = get(response, ['blocks'], []);
    // The blocks need ids so we can use that as a key in the dynamic FieldArray.
    const blocksWithIds = blocks.map((block) => {
        return {...block, id: uuidv1()};
    });
    if (!id) {
        return undefined;
    }
    return {...data, id, response: {...response, blocks: blocksWithIds}};
};
