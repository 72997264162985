import {responses} from './responses';
import {documents} from './documents';
import {knowledgeBase} from './knowledge-base';
import {rest} from './rest';
import {configureStore} from './configureStore';
import {activity} from './activity';
import {conversations} from './conversations';
import {feedbacks} from './feedback';
import {improve} from './improve';
import {user} from './user';
import {org} from './org';
import {widget} from './widget';
import {settings} from './settings';
import {application} from './application';
import {locale} from './locale';
import {responsesTable} from './responses-table';
import {documentsTable} from './documents-table';
import {contact} from './contact';
import {onboarding} from './onboarding';
import {integrations} from './integrations';
import {dialogs} from './dialogs';
import {metrics} from './metrics';
import {escalations} from './escalations';

/**
 * API for redux.
 * TODO(rupertrutland): Expose other redux modules here.
 */
export const redux = {
    dialogs,
    documents,
    knowledgeBase,
    responses,
    rest,
    activity,
    conversations,
    feedbacks,
    improve,
    configureStore,
    user,
    org,
    application,
    escalations,
    locale,
    widget,
    responsesTable,
    documentsTable,
    settings,
    contact,
    onboarding,
    integrations,
    metrics,
};
