import React, {Fragment} from 'react';
import {get} from 'lodash';
import {withRouter} from 'react-router';

import {Button} from '@app/common';
import {Beenhere} from '@app/common/icons';
import {connect} from 'react-redux';
import {
    GET_SERVICES,
    ZENDESK_DISCONNECT,
} from '@app/redux/integrations/constants';
import {redux} from '@app/redux';

interface ZendeskFormProps {
    showModal?: boolean;
    handleShowModal?: any;
    history: any;
    organisation?: any;
    handleZendeskDisconnect?: any;
    connected?: boolean;
}

export class ZendeskFormComponent extends React.PureComponent<ZendeskFormProps> {
    connectZendesk = () => {
        if (this.props.showModal) {
            this.props.handleShowModal();
        } else {
            this.props.history.push('/connect/zendesk');
        }
    };

    handleZendeskDisconnect = () => {
        this.props.handleZendeskDisconnect();
    };

    renderContent = () => {
        const {organisation, connected} = this.props;
        const zendesk_domain = get(organisation, ['zendesk_domain']);
        if (zendesk_domain && connected) {
            return (
                <Fragment>
                    <Button onClick={this.handleZendeskDisconnect}>
                        Disconnect from Zendesk
                    </Button>
                    <Beenhere size={14} />
                    {zendesk_domain}.zendesk.com
                </Fragment>
            );
        }
        return (
            <div>
                <p>Import articles on your Zendesk account</p>
                <Button onClick={this.connectZendesk}>
                    Connect with Zendesk
                </Button>
            </div>
        );
    };

    render() {
        return (
            <section className="settings-zendesk-form settings-section">
                <h1>Zendesk</h1>
                <div className="settings-zendesk-form-content">
                    {this.renderContent()}
                </div>
            </section>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleZendeskDisconnect: () =>
            dispatch(redux.rest.actions[ZENDESK_DISCONNECT]()).then(() => {
                dispatch(redux.rest.actions[GET_SERVICES]());
            }),
    };
};
export const ZendeskForm = withRouter(
    connect(null, mapDispatchToProps)(ZendeskFormComponent),
);
