import {
    escalationEntriesSelector,
    isOrgLoadingSelector,
} from '../org/selectors';
import {publishedResponsesLoadingSelector} from '../knowledge-base/selectors';
import {
    applicationIdSelector,
    isApplicationLoadingSelector,
} from '../application/selectors';

const areCompanyDetailsCompleteSelector = (state) =>
    escalationEntriesSelector(state).length > 0;

const isWidgetPublishedSelector = (state) => !!applicationIdSelector(state);

const isSetupDataLoadingSelector = (state) =>
    publishedResponsesLoadingSelector(state) ||
    isOrgLoadingSelector(state) ||
    isApplicationLoadingSelector(state);

const isSetupCompleteSelector = (state, hasSettingsPermission) => {
    return (
        (hasSettingsPermission
            ? areCompanyDetailsCompleteSelector(state)
            : true) &&
        (hasSettingsPermission ? isWidgetPublishedSelector(state) : true)
    );
};

const selectors = {
    areCompanyDetailsCompleteSelector,
    isWidgetPublishedSelector,
    isSetupCompleteSelector,
    isSetupDataLoadingSelector,
};

export const onboarding = {
    selectors,
};
