import React from 'react';

import {Button} from '@app/common';
import errorScreen from '@app/common/kare-error/error_screen.svg';

export class ErrorMessage extends React.Component {
    handleClickRefresh = () => {
        location.reload();
    };

    render() {
        return (
            <section className="kare-empty-state">
                <img src={errorScreen} />
                <h1>Sorry, something went wrong.</h1>
                <Button outlined onClick={this.handleClickRefresh}>
                    Refresh
                </Button>
            </section>
        );
    }
}
