import React from 'react';
import {get} from 'lodash';
import {Warning} from '@app/common/icons';
import {selectDocumentAction} from '@app/redux/improve/documents/actions';
import {BlockControls} from '../block-controls';
import {connect} from 'react-redux';
import cx from 'classnames';

export const renderInactiveWorkflow = (
    block: any,
    index: number,
    fields: any,
    activateBlock: any,
    removeBlock: any,
) => {
    const broken = get(block, ['workflow_block', 'broken'], false);

    const blockClassname = cx(
        'kare-block kare-block__workflow kare-block--inactive',
        {'kare-block-broken': broken},
    );
    return (
        <div className="kare-block-wrapper">
            <div className={blockClassname}>
                <div className="workflow-title-wrapper">
                    <Warning className="warning-icon" />
                    <span className="workflow-title">{block.type}</span>
                </div>
                <span>Unsupported content block</span>
            </div>
            {index >= 0 && fields && removeBlock && (
                <BlockControls
                    index={index}
                    fields={fields}
                    removeBlock={removeBlock}
                    activateBlock={activateBlock}
                />
            )}
        </div>
    );
};

interface WorkflowBlockFieldProps {
    input?: any;
    selectDocument?: any;
    activateBlock?: any;
    removeBlock?: any;
    fields?: any[];
    index?: number;
}

export class WorkflowBlockFieldComponent extends React.Component<WorkflowBlockFieldProps> {
    state = {
        active: false,
    };
    activateBlock = () => {
        const {input, selectDocument, activateBlock} = this.props;
        const value = get(input, ['value'], {});
        const node = get(value, ['workflow_block', 'node'], '');
        activateBlock();
        selectDocument(node);
    };

    render() {
        const {removeBlock, fields, index, input} = this.props;

        const value = get(input, ['value'], {});

        return renderInactiveWorkflow(
            value,
            index,
            fields,
            this.activateBlock,
            removeBlock,
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    selectDocument: (id, title, url) => {
        return dispatch(selectDocumentAction(id, title, url));
    },
});

export const WorkflowBlockField = connect(
    null,
    mapDispatchToProps,
)(WorkflowBlockFieldComponent);
