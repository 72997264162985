import * as selectors from './selectors';
import * as dispatchers from './dispatchers';
import {reducer} from './reducers';
import {QUERY_LIST_FORM} from './constants';
import {rest} from '@app/redux/improve/queries/rest';

export const queries = {
    selectors,
    dispatchers,
    rest,
    reducer,
    formReducer: {[QUERY_LIST_FORM]: reducer},
};
