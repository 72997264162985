import {get, isEmpty} from 'lodash';
import {formValueSelector} from 'redux-form';

import {DATA, LOADING, ERROR, CODE, ENTRIES} from '../constants';
import {
    CREATE_RESPONSE,
    GET_RESPONSE,
    UPDATE_RESPONSE,
    GET_IMPROVE_PAGE_OUTBOUND_NODES,
    GET_OUTBOUND_NODES,
    GET_RESPONSE_CLUSTER,
} from './constants';
import {RESPONSE_FORM} from '../../responses/constants';

export const responseFormSelector = formValueSelector(RESPONSE_FORM);

/**
 * Select response by id.
 * @param {Object} state
 * @param {Number} id
 */

const isAutoAnswer = () => {
    return (
        new window.URL(window.location.href).searchParams.get(
            'auto-response',
        ) === 'true'
    );
};

export const responseSelector = (state, clusterId = undefined) => {
    let reduxPath = [GET_RESPONSE, DATA];
    if (isAutoAnswer()) {
        reduxPath = ['manage', 'responses', 'selectedResponse'];
    }
    const response = get(state, reduxPath);
    if (isEmpty(response)) {
        return undefined;
    }
    const responseClusterBlocks = get(
        state,
        [GET_RESPONSE_CLUSTER, DATA, 'search_result', 'blocks'],
        [],
    );
    const responseClusterQueries = get(
        state,
        [GET_RESPONSE_CLUSTER, DATA, 'queries'],
        [],
    ).map((query) => query.query);
    const responseQueries = get(response, ['queries'], []);
    if (responseClusterBlocks.length === 0 || !clusterId) {
        return response;
    }
    const responseCopy = {...response};
    const isResponse = get(response, ['response']);
    const isChattering = get(response, ['chattering']);
    const isContent = get(response, ['content']);
    const responseBlocks = get(response, ['response', 'blocks'], []);
    const chatteringBlocks = get(response, ['chattering', 'blocks'], []);
    const contentBlocks = get(response, ['content', 'blocks'], []);
    // We take the cluster blocks first, because we can only show one quote or one dialog for now, and we take the first in the list.
    // Later this won't be a problem as we will be able to manage multiple.
    if (isResponse) {
        responseCopy.response.blocks = [
            ...responseClusterBlocks,
            ...responseBlocks,
        ];
        responseCopy.response.queries = [
            ...responseClusterQueries,
            ...responseQueries,
        ];
    } else if (isChattering) {
        responseCopy.chattering.blocks = [
            ...responseClusterBlocks,
            ...chatteringBlocks,
        ];
        responseCopy.chattering.queries = [
            ...responseClusterQueries,
            ...responseQueries,
        ];
    } else if (isContent) {
        responseCopy.response = {
            blocks: [...responseClusterBlocks, ...contentBlocks],
            queries: [...responseClusterQueries, ...responseQueries],
        };
    }
    return responseCopy;
};

export const isResponseClusterFetchingSelector = (state) =>
    !!get(state, [GET_RESPONSE_CLUSTER, LOADING]);

export const outboundNodesSelector = (state) => {
    const outboundNodes = get(state, [GET_OUTBOUND_NODES, DATA, ENTRIES], []);
    return outboundNodes;
};
export const improvePageResponseSelector = (state) => {
    const response = get(
        state,
        ['manage', 'responses', 'selectedResponse'],
        {},
    );
    if (!isEmpty(response)) {
        return response;
    }
};

export const improvePageOutboundNodesSelector = (state) => {
    const outboundNodes = get(
        state,
        [GET_IMPROVE_PAGE_OUTBOUND_NODES, DATA, ENTRIES],
        [],
    );
    return outboundNodes;
};
export const responseNotFoundSelector = (state) => {
    return get(state, [GET_RESPONSE, ERROR, CODE]) === 404;
};
export const responseErrorSelector = (state) => {
    return get(state, [GET_RESPONSE, ERROR]);
};

export const responseQueriesSelector = (state) => {
    // @ts-ignore
    return get(responseSelector(state), 'queries');
};

export const isResponseUpdatingSelector = (state) =>
    !!get(state, [UPDATE_RESPONSE, LOADING]);

export const isResponseFetchingSelector = (state) =>
    !!get(state, [GET_RESPONSE, LOADING]);

export const isResponseCreatingSelector = (state) =>
    !!get(state, [CREATE_RESPONSE, LOADING]);

export const attachedDocumentSelector = (state) =>
    responseFormSelector(state, 'action.document') || {};

// Dummy data
// const responseCluster = {
//   id: '04f07b31-c620-48b5-a816-57660b3720c7',
//   locale: 'en-US',
//   cluster_type: 'auto-answer',
//   queries: [
//     {
//       id: 'ef14ac35-e76f-4cd0-9edf-d5e805814745',
//       query: 'gold',
//       query_count: 2
//     }
//   ],
//   search_result: {
//     hit_text: '\n\nGOLD.',
//     id: '315f4e8e-92b6-11e9-ad54-0a580a0801b5',
//     blocks: [
//       {
//         quote_block: {
//           node: '315f4e8e-92b6-11e9-ad54-0a580a0801b5',
//           quote:
//             '1. Laube BL, Janssens HM, de Jongh FH, et al. What the pulmonary specialist should know about the new inhalation therapies. `Eur Respir J.` 2011;37(6):1308-1331. doi:[http://dx.doi.org/10.1183/09031936.00166410](http://dx.doi.org/10.1183/09031936.00166410).\n\n2. Data on File. 2017N335327_00.\n\n3. GOLD. Global Strategy for the Diagnosis, Management and Prevention of COPD, Global Initiative for Chronic Obstructive Lung Disease (GOLD) 2019. Available at: [http://www.goldcopd.org](http://www.goldcopd.org) (Accessed October 30, 2018).'
//         },
//         type: 'quote'
//       }
//     ],
//     labels: [
//       'clinical_data:efficacy',
//       'composition_formulation_ingredients:physical/chemical properties',
//       'content_category:clinical data',
//       'content_category:composition, formulation, ingredients',
//       'content_category:indication, dosage, administration',
//       'country:us',
//       'indication_disease:respiratory',
//       'lifecycle:advanced lc',
//       'product:fluticasone furoate+umeclidinium+vilanterol',
//       'status:effective',
//       'type:standard response (advanced)'
//     ],
//     title: 'Lung Deposition and Particle Size of Trelegy Ellipta',
//     type: 'content'
//   }
// };
