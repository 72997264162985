import React, {FunctionComponent as Fc} from 'react';

interface INeutralProps {
    size: number;
    color: string;
}

export const Dialpad: Fc<INeutralProps> = ({size, color}) => (
    <svg
        height={`${size}px`}
        width={`${size}px`}
        style={{color: color}}
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="36" height="36" rx="4" fill="evenodd" />
        <rect width="36" height="36" fill="evenodd" />
        <path
            d="M25.4824 10.0063C23.6702 10.0045 21.9185 10.6629 20.5503 11.8601C19.182 13.0573 18.2895 14.7126 18.0372 16.5207V6.11414C18.0372 6.09135 18.0305 6.06907 18.0179 6.05018C18.0052 6.03129 17.9873 6.01666 17.9663 6.00817C17.9453 5.9997 17.9222 5.99776 17.9002 6.00262C17.8781 6.00747 17.8579 6.0189 17.8424 6.03542L13.4449 10.6C12.5184 10.2067 11.523 10.0049 10.5176 10.0063C6.36774 10.0063 3 13.3873 3 17.5582C3 21.7292 6.36586 25.1102 10.5186 25.1102C12.3306 25.112 14.0822 24.4537 15.4504 23.2567C16.8186 22.0597 17.7113 20.4047 17.9638 18.5967V29.0023C17.9638 29.1048 18.0871 29.1541 18.1576 29.0811L22.5561 24.5165C23.4823 24.9096 24.4773 25.1115 25.4824 25.1102C29.6342 25.1102 33 21.7292 33 17.5582C33 13.3873 29.6342 10.0063 25.4824 10.0063Z"
            fill="#6C3DFF"
        />
    </svg>
);
