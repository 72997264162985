import React from 'react';
import {Editor} from 'react-draft-wysiwyg';
import {CharacterCount} from '@app/common';
import {
    countStrippedMarkdownCharacters,
    editorStateToMd,
    mdToEditorState,
} from './utils';
import {MarkdownEditorError} from '../markdown-editor-error';
import {Trash} from '@app/common/icons';
import cx from 'classnames';

interface MarkdownEditorProps {
    change?: any;
    name?: string;
    previousValue?: string;
    showError?: boolean;
    limit?: number;
    toolbarClassName?: string;
    onChange?: any;
    value?: any;
    removeBlock?: any;
    deactivateEditor?: any;
    isEditingDocument?: boolean;
}

export class MarkdownEditor extends React.Component<MarkdownEditorProps, any> {
    /**
     * Toolbar options.
     * @see https://jpuri.github.io/react-draft-wysiwyg/#/docs
     */
    toolbar = {
        options: ['inline', 'link', 'list'],
        inline: {
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'strikethrough', 'monospace'],
            monospace: {className: 'demo-option-custom'},
        },
        list: {
            options: ['ordered', 'unordered'],
        },
        link: {
            options: ['link'],
            linkCallback: undefined,
        },
        image: {
            className: undefined,
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: false,
            uploadCallback: undefined,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            defaultSize: {
                height: 'auto',
                width: 'auto',
            },
        },
    };

    defaultCharacterLimit = 2500;

    constructor(props) {
        super(props);

        this.state = {
            editorState: mdToEditorState(this.props.previousValue),
            focus: false,
        };
    }

    onEditorStateChange = (editorState) => {
        const markDown = editorStateToMd(editorState);
        this.props.onChange({
            ...this.props.value,
            text_block: {
                text: markDown,
            },
        });
        this.setState({editorState});
    };

    handleInput = (input, editorState) => {
        const {limit = this.defaultCharacterLimit} = this.props;
        const newMarkdown = editorStateToMd(editorState);
        const remainingCharacters =
            limit -
            (countStrippedMarkdownCharacters(newMarkdown) + input.length);
        if (remainingCharacters < 0) {
            // @see https://draftjs.org/docs/api-reference-editor.html#handlebeforeinput
            return 'handled';
        }
    };

    handleBeforeInput = (input, editorState) => {
        return this.handleInput(input, editorState);
    };

    handlePastedText = (input, html, editorState) => {
        return this.handleInput(input, editorState);
    };
    handleBlur = () => {
        this.setState({focus: false});
    };

    handleFocus = () => {
        this.setState({focus: true});
    };

    handleClickTrash = () => {
        const {removeBlock} = this.props;
        removeBlock();
    };

    handleClickDone = () => {
        const {deactivateEditor} = this.props;
        deactivateEditor();
    };

    render() {
        const {
            limit = this.defaultCharacterLimit,
            showError,
            isEditingDocument,
        } = this.props;
        const {editorState} = this.state;
        const markdown = editorStateToMd(editorState);
        const className = cx('markdown-editor__wrapper', {
            'markdown-editor__wrapper--error':
                !isEditingDocument && markdown.length >= limit,
        });
        return (
            <div className={className}>
                <div className="markdown-editor">
                    <Editor
                        onBlur={this.handleBlur}
                        onFocus={this.handleFocus}
                        spellCheck={true}
                        stripPastedStyles={true}
                        toolbar={this.toolbar}
                        editorState={editorState}
                        wrapperClassName={
                            'admin-console-draft-md-editor-wrapper'
                        }
                        editorClassName="admin-console-draft-md-editor"
                        onEditorStateChange={this.onEditorStateChange}
                        handleBeforeInput={this.handleBeforeInput}
                        handlePastedText={this.handlePastedText}
                        toolbarClassName={this.props.toolbarClassName}
                    />
                    {(this.props.previousValue === markdown || !markdown) &&
                        showError && <MarkdownEditorError />}
                </div>
                {!isEditingDocument && (
                    <>
                        <CharacterCount
                            count={countStrippedMarkdownCharacters(markdown)}
                            limit={limit}
                        />
                        <span
                            className="editor-done"
                            onClick={this.handleClickDone}
                        >
                            DONE
                        </span>
                        <Trash
                            onClick={this.handleClickTrash}
                            className="trash-editor-block"
                        />
                    </>
                )}
            </div>
        );
    }
}
