import {get} from 'lodash';
import {NODE_STATUSES, STATUS} from '@app/constants';

export const isDraft = (response) => {
    if (!response) return false;
    return get(response, [STATUS]) === NODE_STATUSES.DRAFT;
};

export const isArchived = (response) => {
    if (!response) return false;
    return get(response, [STATUS]) === NODE_STATUSES.ARCHIVED;
};

export const isActive = (response) => {
    if (!response) return false;
    return get(response, [STATUS]) === NODE_STATUSES.PUBLISHED;
};
