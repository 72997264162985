import {USER_FORM_FIELDS} from '../constants';
import {PERMISSION_KEYS} from '@app/constants';
import {getAuths} from '@app/utils/auth';

export const adaptUserFormPermissions = (scope) => {
    const adapted = [];
    if (scope[USER_FORM_FIELDS.ALL]) {
        adapted.push(PERMISSION_KEYS.MIND.ALL);
    }
    if (scope[USER_FORM_FIELDS.SETTINGS]) {
        adapted.push(PERMISSION_KEYS.MIND.SETTINGS);
    }
    if (scope[USER_FORM_FIELDS.IAM]) {
        adapted.push(PERMISSION_KEYS.MIND.IAM);
    }
    if (scope[USER_FORM_FIELDS.KNOWLEDGE_READ]) {
        adapted.push(PERMISSION_KEYS.MIND.KNOWLEDGE.READ);
    }
    if (scope[USER_FORM_FIELDS.KNOWLEDGE_WRITE]) {
        adapted.push(PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE);
    }
    return adapted;
};

export const adaptUserPermissionsForForm = (scope) => {
    const adapted = {};
    if (!scope || !scope.length) {
        return adapted;
    }
    if (scope.includes(PERMISSION_KEYS.MIND.ALL)) {
        adapted[USER_FORM_FIELDS.ALL] = true;
    }
    if (scope.includes(PERMISSION_KEYS.MIND.SETTINGS)) {
        adapted[USER_FORM_FIELDS.SETTINGS] = true;
    }
    if (scope.includes(PERMISSION_KEYS.MIND.IAM)) {
        adapted[USER_FORM_FIELDS.IAM] = true;
    }
    if (scope.includes(PERMISSION_KEYS.MIND.KNOWLEDGE.READ)) {
        adapted[USER_FORM_FIELDS.KNOWLEDGE_READ] = true;
    }
    if (scope.includes(PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE)) {
        adapted[USER_FORM_FIELDS.KNOWLEDGE_WRITE] = true;
    }
    return adapted;
};

/*** Function to verify if user is using Single Sign On.
 *   If no parameter is passed the function will check the current user,
 *   otherwise the function will expect an array of providers.
 *   ***/
export const isUserSSO = (authProviders = undefined) => {
    if (authProviders) return !authProviders?.every((a) => a === 'default');
    return !getAuths()?.every((a) => a.name === 'default');
};

/*** Function to verify if org has email auth provider.
 *   If no parameter is passed the function will check the current user,
 *   otherwise the function will expect an array of providers.
 *   ***/
export const hasEmailAuthProvider = (authProviders = undefined): boolean => {
    if (authProviders) return authProviders?.some((a) => a === 'default');
    return getAuths()?.some((a) => a.name === 'default');
};
