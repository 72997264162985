import {getCluster} from '@app/redux/improve/cards/selectors';
import {redux} from '@app/redux';
import {DELETE_CLUSTER} from '@app/redux/improve/constants';
import {
    increaseSelectedCardIndexAction,
    removeIndexFromClustersAction,
} from '@app/redux/improve/cards/actions';

export const dismissCluster =
    (preloadId = null) =>
    (dispatch, getState) => {
        const state = getState();
        const cluster = getCluster(state);
        const id = cluster.id;

        if (preloadId) {
            return dispatch(
                redux.rest.actions[DELETE_CLUSTER](
                    {id},
                    {
                        body: JSON.stringify({
                            cluster: {approved: true, queries: []},
                        }),
                    },
                ),
            );
        } else {
            return dispatch(
                redux.rest.actions[DELETE_CLUSTER](
                    {id},
                    {
                        body: JSON.stringify({
                            cluster: {approved: true, queries: []},
                        }),
                    },
                ),
            ).then(() => {
                dispatch(increaseSelectedCardIndexAction());
                dispatch(removeIndexFromClustersAction());
            });
        }
    };
