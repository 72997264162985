import {
    REMOVE_ALL_SELECTED_QUERIES_ACTION_TYPE,
    APPEND_MUTED_QUERIES_ACTION_TYPE,
    MUTED_QUERIES,
    REMOVE_MUTED_QUERIES_ACTION_TYPE,
} from '../constants';
import {PAYLOAD} from '@app/redux/constants';

export const removeAllQuerySelectionsAction = () => ({
    type: REMOVE_ALL_SELECTED_QUERIES_ACTION_TYPE,
});

export const appendMutedQueries = (mutedQueries) => ({
    type: APPEND_MUTED_QUERIES_ACTION_TYPE,
    [PAYLOAD]: {
        [MUTED_QUERIES]: mutedQueries,
    },
});

export const removeMutedQueries = (mutedQueries: any[]) => ({
    type: REMOVE_MUTED_QUERIES_ACTION_TYPE,
    [PAYLOAD]: {
        [MUTED_QUERIES]: mutedQueries,
    },
});
