export const SELECTED_CLUSTER_INDEX = 'selectedClusterIndex';
export const CLUSTER_LIMIT_KEY = 'clusterLimit';
export const CLUSTERS_TO_SKIP = 'clustersToSkip';
export const CLUSTER_LIMIT = 100;

export const INCREASE_SELECTED_CLUSTER_INDEX = 'increaseSelectedClusterIndex';
export const DECREASE_SELECTED_CLUSTER_INDEX = 'decreaseSelectedClusterIndex';
export const RESET_SELECTED_CLUSTER_INDEX = 'resetSelectedClusterIndex';
export const REMOVE_INDEX_FROM_CLUSTERS = 'removeIndexFromClusters';

/**
 * Backend response fields
 */
export const CLUSTER_TYPE = 'cluster_type';
