import {words} from 'lodash';

export const isAnyRouteActive = (activeLinks, currentUrl) => {
    const result = activeLinks.find((link) => {
        return isRouteActive(link, currentUrl);
    });
    return result !== undefined;
};

const isRouteActive = (navLinkUrl, currentUrl) => {
    if (navLinkUrl === currentUrl) return true;
    else {
        const regex = /[^/]+/g;
        const currentPathList = words(currentUrl, regex);
        const navLinkPathList = words(navLinkUrl, regex);
        if (navLinkPathList.length > 0) {
            return navLinkPathList.every(
                (path, index) => currentPathList.indexOf(path) === index,
            );
        } else {
            return false;
        }
    }
};
