import React from 'react';
import cx from 'classnames';

import {ValidationError} from '@app/common';

interface CheckboxInputProps {
    label?: any;
    input?: {value?: boolean; name?: string; onChange?: (...args: any) => void};
    meta?: any;
    disabled?: boolean;
}

export class CheckboxInput extends React.Component<CheckboxInputProps> {
    state = {
        isChecked: this.props.input?.value || false,
    };

    handleClick = () => {
        const {
            input: {onChange},
        } = this.props;
        const {isChecked} = this.state;

        this.setState({isChecked: !isChecked});
        onChange(!isChecked);
    };

    render() {
        const {input, meta, label, disabled, ...remainingProps} = this.props;
        const {isChecked} = this.state;
        const {value, name} = input;
        const {touched, error, active} = meta;
        const inputClass = cx('c-field', 'checkbox-field', {
            'is-invalid': touched && error,
            'is-active': active && !value,
        });
        const showError = touched && error;

        return (
            <div className={inputClass}>
                <div className="checkbox-field-container">
                    <input
                        {...remainingProps}
                        defaultChecked={isChecked}
                        type="checkbox"
                        disabled={disabled}
                        className="checkbox-field-input"
                        id={name}
                        onClick={this.handleClick}
                    />
                    {label && (
                        <label id={`${name}-label`} htmlFor={name}>
                            {label}
                        </label>
                    )}
                </div>
                {showError && <ValidationError>{error}</ValidationError>}
            </div>
        );
    }
}
