import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {persistStore} from 'redux-persist';

import {rootReducer} from './root-reducer';

/**
 * Main store configuration.
 * @param {Object} initialState
 */
export const configureStore = (initialState) => {
    const composeEnhancers =
        /*@ts-ignore */
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store: any = createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(thunk)),
    );

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../redux', () => {
            store.replaceReducer(require('./root-reducer').rootReducer);
        });
    }

    const persistor = persistStore(store);
    return {store, persistor};
};
