import React, {useEffect, useState} from 'react';
import {toast, ToastContainer} from 'react-toastify';
import {WebCrawlerItem} from '@app/components/WebCrawlerList/WebCrawlerItem';
import {getDomains, removeDomain} from '@app/api/webcrawler';
import {ApiDomain} from '@app/api/model';
import {LoadingModal} from '@app/components/Loading/LoadingModal';
import {Loading} from '@app/components/Loading/Loading';

export interface WebCrawlerListProps {
    showModal?: (host: string) => any;
    triggerRefresh?: boolean;
}

export const WebCrawlerList: React.FC<WebCrawlerListProps> = (props) => {
    const [domains, setDomains] = useState<ApiDomain[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [reloadList, setReloadList] = useState<boolean>(true);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    useEffect(() => {
        let promiseCanceled = false;
        const getListDomains = async () => {
            const result = await getDomains();
            if (!promiseCanceled) {
                setIsLoading(false);
                setReloadList(false);
                if (result.err) {
                    setIsError(true);
                } else {
                    setDomains(result.data.domains);
                }
            }
        };
        if (reloadList || props.triggerRefresh) {
            getListDomains();
        }
        return () => {
            promiseCanceled = true;
        };
    }, [reloadList, props.triggerRefresh]);

    return (
        <React.Fragment>
            {isDeleting && <LoadingModal text="Please wait..." />}
            {isLoading && <Loading />}
            {!isLoading && !isError && (
                <div>
                    {domains.map((d: any) => (
                        <WebCrawlerItem
                            domain={d}
                            key={d.host}
                            onEdit={() => {
                                props.showModal(d.host);
                            }}
                            onDelete={() => {
                                (async () => {
                                    setIsDeleting(true);
                                    const r = await removeDomain(d.host);
                                    setIsDeleting(false);
                                    if (r.err) {
                                        toast.error(
                                            'Error removing this domain. Try again later.',
                                        );
                                    } else {
                                        setReloadList(true);
                                    }
                                })();
                            }}
                        />
                    ))}
                </div>
            )}
            {isError && (
                <span>
                    Error fetching the list of domains. Try again later please.
                </span>
            )}
            <ToastContainer />
        </React.Fragment>
    );
};
