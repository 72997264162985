import React from 'react';
import {Route} from 'react-router-dom';
import {omit} from 'lodash';
import './_index.scss';
import cx from 'classnames';

import {LOGO_PLACEMENT} from './constants';
import * as constants from './constants';
import {DialpadLogo} from '@app/assets/logos';

export {constants};

interface EmptyLayoutProps {
    component?: any;
    withLogo?: boolean;
    logoPlacement?: string;
    exact?: any;
    path?: any;
}

export class EmptyLayout extends React.Component<EmptyLayoutProps> {
    render() {
        const {
            component: Component,
            withLogo = true,
            logoPlacement = LOGO_PLACEMENT.TOP,
        } = this.props;
        const logoContainerClassName = cx('empty-layout__kare-logo-container', {
            'empty-layout__kare-logo--top':
                logoPlacement === LOGO_PLACEMENT.TOP,
            'empty-layout__kare-logo--bottom-left':
                logoPlacement === LOGO_PLACEMENT.BOTTOM_LEFT,
            'empty-layout__kare-logo--top-left':
                logoPlacement === LOGO_PLACEMENT.TOP_LEFT,
        });

        const onClickLogo = () => {
            sessionStorage.removeItem('kare:persist:appReducer');
            window.location.replace('/');
        };

        return (
            <Route
                {...omit(this.props, ['component', 'withLogo'])}
                render={(matchProps) => (
                    <div className="empty-layout">
                        {withLogo && (
                            <div className={logoContainerClassName}>
                                <button
                                    className="empty-layout__kare-logo"
                                    onClick={onClickLogo}
                                >
                                    <DialpadLogo
                                        width={170}
                                        height={40}
                                        color="#6C3DFF"
                                    />
                                </button>
                            </div>
                        )}
                        <Component {...matchProps} />
                    </div>
                )}
            />
        );
    }
}
