import React from 'react';
import {AccessDenied} from './access-denied';
import {permissions} from '@app/utils';

interface enforcePermissionsProps {
    requiredPermissions?: any[];
    scopes?: any[];
}

export const enforcePermissions = (WrappedComponent) => {
    const component = class extends React.Component<enforcePermissionsProps> {
        render() {
            const {requiredPermissions, scopes} = this.props;
            const userHasRequiredPermissions =
                permissions.hasRequiredPermissions(requiredPermissions, scopes);
            if (!userHasRequiredPermissions) {
                return <AccessDenied />;
            }
            // Wraps the input component in a container, without mutating it. Good!
            return <WrappedComponent {...this.props} />;
        }
    };
    /*@ts-ignore*/
    component.displayName = 'enforcePermissions';
    return component;
};

export const isAllowed = (requiredPermissions, scopes) => {
    return permissions.hasRequiredPermissions(requiredPermissions, scopes);
};

interface ifHasPermissionsProps {
    requiredPermissions?: any;
    scopes?: any;
}

export const ifHasPermissions = (WrappedComponent) => {
    const component = class extends React.Component<ifHasPermissionsProps> {
        render() {
            const {requiredPermissions, scopes} = this.props;
            const userHasRequiredPermissions =
                permissions.hasRequiredPermissions(requiredPermissions, scopes);
            if (!userHasRequiredPermissions) {
                return '';
            }
            // Wraps the input component in a container, without mutating it. Good!
            return <WrappedComponent {...this.props} />;
        }
    };
    /*@ts-ignore*/
    component.displayName = 'ifHasPermissions';
    return component;
};
