import {rest} from './rest';
import * as selectors from './selectors';
import * as constants from './constants';
import {reducer} from '@app/redux/application/reducers';

export const timeseriesQueries = {
    rest,
    selectors,
    reducer,
    constants,
};
