import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {redux} from '@app/redux';
import axios from 'axios';
import {ActionsTable} from './actions-table';
import {Helmet} from 'react-helmet';
import cx from 'classnames';
import {Spinner, SearchBar, ErrorBoundary} from '@app/common';
import {NoContent} from './no-content';
import {toast} from 'react-toastify';
import {ActionModal} from './action-modal';
import {CONTENT_LOCALE} from '../redux/constants';

interface ActionsComponentProps {
    org: any;
    token?: string;
}

const ActionsComponent = (props: ActionsComponentProps) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [rowClicked, setRowClicked] = useState(null);

    const instance = axios.create();
    useEffect(() => {
        if (
            props.org.webext_hostname !== undefined &&
            props.org.webext_hostname !== ''
        ) {
            if (instance.defaults.headers[CONTENT_LOCALE])
                delete instance.defaults.headers[CONTENT_LOCALE];
            instance
                .get(`${props.org.webext_hostname}/conf/registry`)
                .then((result) => {
                    if (result.status === 200) {
                        setItems(result.data);
                    } else {
                        toast.error(
                            `Error retrieving actions ${result.statusText}`,
                        );
                    }
                    setLoading(false);
                })
                .catch((e) => {
                    toast.error(`${e}`);
                    setLoading(false);
                });
        } else {
            setLoading(false);
            setItems([]);
        }
    }, [props.org]);

    const className = cx('knowledge-base__container', {
        'knowledge-base__container--loading': loading,
    });

    const filteredItems = items.filter(
        (item) =>
            searchQuery === '' ||
            (searchQuery !== '' &&
                (item.title.toLowerCase().indexOf(searchQuery.toLowerCase()) !==
                    -1 ||
                    item.description
                        .toLowerCase()
                        .indexOf(searchQuery.toLowerCase()) !== -1)),
    );

    return (
        <ErrorBoundary>
            <div className={className}>
                <Helmet title="Actions" />
                <header className="knowledge-base__toolbar actions-header">
                    <div className="knowledge-base__toolbar-left">
                        <h1 className="kare-h3">
                            {items.length >= 0 &&
                                `${items.length} Automation${
                                    items.length !== 1 ? 's' : ''
                                }`}
                        </h1>
                    </div>
                    <div className="knowledge-base__toolbar-right">
                        <SearchBar
                            name={'responses-search'}
                            onChange={(event) => {
                                setSearchQuery(event.target.value);
                            }}
                            value={searchQuery}
                            hideCross={true}
                            filterButtonHandler={() => {}}
                            placeholder={'Search in your automations'}
                            filterCTA={''}
                        />
                    </div>
                </header>
                {!loading && items.length > 0 && (
                    <ActionsTable
                        items={filteredItems}
                        isLoading={false}
                        rowClick={(row) => {
                            setRowClicked(row);
                        }}
                    />
                )}
                {!loading && items.length === 0 && <NoContent />}
                {loading && <Spinner />}
            </div>
            {rowClicked && (
                <ActionModal
                    closeModal={() => {
                        setRowClicked(null);
                    }}
                    action={rowClicked}
                    org={props.org}
                />
            )}
        </ErrorBoundary>
    );
};

const mapStateToProps = (state) => {
    return {
        org: redux.org.selectors.orgSelector(state),
    };
};

export const Actions = connect(mapStateToProps)(ActionsComponent);
