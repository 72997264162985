import React from 'react';
import {BannerWarning} from '@app/common/icons';

/**
 * Reusable sidebar for the portal
 */
export class PromptActionBanner extends React.Component {
    render() {
        return (
            <div className="prompt-action-banner">
                <BannerWarning size={20} />
                <h5>You have more than one chatbot.</h5>
                <span>
                    You will only be able to manage the first one from this
                    dashboard.
                </span>
            </div>
        );
    }
}
