import {
    AUTO_ANSWER_CLUSTER,
    DISAMBIGUATION_CLUSTER,
    IMPROVE_ANSWER_CLUSTER,
    MISSING_KNOWLEDGE_CLUSTER,
} from '@app/redux/improve/constants';
import {ChatBubble, QuestionCircle, Document} from '@app/common/icons';
import React from 'react';
import {get} from 'lodash';

export const getClusterHeaderIcon = (clusterType) => {
    const iconSize = 16;

    switch (clusterType) {
        case AUTO_ANSWER_CLUSTER:
            return (
                <Document fill={getClusterColor(clusterType)} size={iconSize} />
            );
        case IMPROVE_ANSWER_CLUSTER:
            return (
                <ChatBubble
                    fill={getClusterColor(clusterType)}
                    size={iconSize}
                />
            );
        case DISAMBIGUATION_CLUSTER:
            return (
                <ChatBubble
                    fill={getClusterColor(clusterType)}
                    size={iconSize}
                />
            );
        case MISSING_KNOWLEDGE_CLUSTER:
            return (
                <QuestionCircle
                    fill={getClusterColor(clusterType)}
                    size={iconSize}
                />
            );
        default:
            return (
                <QuestionCircle
                    fill={getClusterColor(clusterType)}
                    size={iconSize}
                />
            );
    }
};

export const getClusterTitle = (clusterType) => {
    switch (clusterType) {
        case IMPROVE_ANSWER_CLUSTER:
            return 'Approval needed';
        case AUTO_ANSWER_CLUSTER:
        case DISAMBIGUATION_CLUSTER:
            return 'Choose from responses';
        case MISSING_KNOWLEDGE_CLUSTER:
        default:
            return 'Missing response';
    }
};

export const getClusterQueriesNumber = (cluster) => {
    const queries = get(cluster, ['queries'], []) || [];
    return queries.reduce((a, c) => a + c.query_count, 0);
};

export const getQueriesWordByQueriesNumber = (numberOfQueries) =>
    numberOfQueries > 1 ? 'queries' : 'query';

export const getClusterQueriesText = (clusterType, numberOfQueries) => {
    switch (clusterType) {
        case AUTO_ANSWER_CLUSTER:
            return `${numberOfQueries} ${getQueriesWordByQueriesNumber(
                numberOfQueries,
            )} are answered automatically with a content from URL.`;
        case IMPROVE_ANSWER_CLUSTER:
            return `${numberOfQueries} ${getQueriesWordByQueriesNumber(
                numberOfQueries,
            )} answered with your response`;
        case DISAMBIGUATION_CLUSTER:
            return `${numberOfQueries} queries answered.`;
        case MISSING_KNOWLEDGE_CLUSTER:
        default:
            return `${numberOfQueries} similar ${getQueriesWordByQueriesNumber(
                numberOfQueries,
            )} couldn't be answered`;
    }
};
export const getClusterQueriesSubText = (clusterType) => {
    switch (clusterType) {
        case AUTO_ANSWER_CLUSTER:
            return `Choose a response or create a new one to answer them.`;
        case IMPROVE_ANSWER_CLUSTER:
            return `Approve the response by saving queries or correct it by choosing another one.`;
        case DISAMBIGUATION_CLUSTER:
            return `Choose the correct answer or create a dialog with options.`;
        case MISSING_KNOWLEDGE_CLUSTER:
        default:
            return `Edit and save the autogenerated response.`;
    }
};

export const getClusterColor = (cluster_type) => {
    switch (cluster_type) {
        case IMPROVE_ANSWER_CLUSTER:
            return '#36A385';
        case AUTO_ANSWER_CLUSTER:
            return '#FDAC7A';
        case DISAMBIGUATION_CLUSTER:
            return '#2E91FF';
        case MISSING_KNOWLEDGE_CLUSTER:
        default:
            return '#E8586B';
    }
};
