import {get, isEqual} from 'lodash';
import {
    GET_MUTED_QUERIES,
    MUTED_QUERIES,
    QUERY_ARRAY_OF_FIELDS,
    QUERY_LIST_FORM,
} from '../constants';
import {GET_CLUSTER, GET_CLUSTER_QUERIES} from '../../constants';
import {DATA, LOADING} from '../../../constants';

export const getSelectedQueriesFromQueryListForm = (state) => {
    const allQueries = get(
        state,
        `form[${QUERY_LIST_FORM}].values[${QUERY_ARRAY_OF_FIELDS}]`,
        [],
    );
    const selectedQueries = allQueries.filter((query) => query.checked);
    if (selectedQueries.length === 0) {
        return allQueries;
    }
    return selectedQueries;
};

export const getNumberOfSelectedQueriesFromQueryList = (state) => {
    return getSelectedQueriesFromQueryListForm(state).filter(
        (query) => query === true,
    ).length;
};

export const isQueryListFormPristine = (state) => {
    const selectedValues = getSelectedQueriesFromQueryListForm(state);
    const initialValues = get(
        state,
        `form[${QUERY_LIST_FORM}].initial[${QUERY_ARRAY_OF_FIELDS}]`,
        [],
    );
    return isEqual(selectedValues, initialValues);
};

export const getSelectedQueries = (state) => {
    return getSelectedQueriesFromQueryListForm(state);
};

export const getQueryList = (state) => {
    const queryList = get(state, [GET_CLUSTER, DATA, 'queries'], []);
    return queryList || [];
};

export const getQueryListLengthSelector = (state) =>
    get(getQueryList(state), 'length', 0);

export const isQueryListLoading = (state) => {
    return get(state, [GET_CLUSTER_QUERIES, LOADING], false);
};

export const getMutedQueriesSelector = (state) =>
    get(state, [GET_MUTED_QUERIES, DATA], []);

export const isMutedQueriesLoading = (state) =>
    get(state, [GET_MUTED_QUERIES, LOADING], false);

export const getMutedQueriesCursorSelector = (state) =>
    get(state, [GET_MUTED_QUERIES, DATA, 'next_cursor']);

export const getAllMutedQueriesSelector = (state) => {
    return get(state, ['queries', MUTED_QUERIES], []);
};
