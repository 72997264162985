import React, {Fragment} from 'react';
import {redux} from '@app/redux';
import {ErrorBoundary} from '@app/common';
import {connect} from 'react-redux';
import {DashboardActivityChart} from './dashboard-activity-chart';
import {DashboardActivityQueriesChart} from './dashboard-activity-queries-chart';
import {transformMetricsConversations, transformQueryMetrics} from '../utils';
import {DashboardActivitySummary} from './dashboard-activity-summary';
import {DashboardActivityQueriesSummary} from './dashboard-activity-queries-summary';
import {
    getConversationMetrics,
    getFeedbackMetrics,
    getQueryMetrics,
    isAllMetricsEmpty,
} from '@app/redux/metrics/selectors';

interface DashboardActivityProps {
    activity: {
        resolution: string;
        counters: any[];
    };
    conversationMetrics: any;
    queryMetrics: any;
    feedbackMetrics: any;
    isAllMetricsEmpty: boolean;
}

export class DashboardActivityComponent extends React.Component<DashboardActivityProps> {
    render() {
        const transformedConversationData = transformMetricsConversations(
            this.props.conversationMetrics,
        );
        const transformedQueryData = transformQueryMetrics(
            this.props.queryMetrics,
        );
        return !this.props.isAllMetricsEmpty ? (
            <ErrorBoundary>
                <div className="dashboard-activity__container">
                    {transformedConversationData &&
                        transformedConversationData.counters.length > 0 && (
                            <Fragment>
                                <DashboardActivitySummary
                                    escalated={
                                        transformedConversationData.totalEscalated
                                    }
                                    conversations={
                                        transformedConversationData.totalConversations
                                    }
                                    bounced={
                                        transformedConversationData.totalBounced
                                    }
                                    resolved={
                                        transformedConversationData.totalResolved
                                    }
                                    deflected={
                                        transformedConversationData.totalDeflected
                                    }
                                    handover={
                                        transformedConversationData.totalHandover
                                    }
                                />
                                <DashboardActivityChart
                                    viewingOption={
                                        transformedConversationData.viewingOption
                                    }
                                    activityData={
                                        transformedConversationData.counters
                                    }
                                />
                            </Fragment>
                        )}
                    {transformedQueryData &&
                        transformedQueryData.counters.length > 0 && (
                            <Fragment>
                                <DashboardActivityQueriesSummary
                                    queries={transformedQueryData.totalQueries}
                                    responses={
                                        transformedQueryData.totalResponses
                                    }
                                    missing={transformedQueryData.totalMissing}
                                    disambiguations={
                                        transformedQueryData.totalDisambiguations
                                    }
                                />
                                <DashboardActivityQueriesChart
                                    viewingOption={
                                        transformedQueryData.viewingOption
                                    }
                                    activityData={transformedQueryData.counters}
                                />
                            </Fragment>
                        )}
                </div>
            </ErrorBoundary>
        ) : (
            <ErrorBoundary>
                <div className="dashboard-activity__empty">
                    No data available
                </div>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activity: redux.activity.selectors.activitySelector(state),
        conversationMetrics: getConversationMetrics(state),
        queryMetrics: getQueryMetrics(state),
        feedbackMetrics: getFeedbackMetrics(state),
        isAllMetricsEmpty: isAllMetricsEmpty(state),
    };
};

export const DashboardActivity = connect(mapStateToProps)(
    DashboardActivityComponent,
);
