import {queries} from './queries';
import {responses} from './responses';
import {cards} from './cards';
import {rest} from './rest';
import * as constants from './constants';

export const improve = {
    queries,
    responses,
    cards,
    rest,
    constants,
};
