import React from 'react';
import {Link} from 'react-router-dom';

import {Wrapper, Container, H2, Section} from './emailSent.styles';

export class ResetPasswordEmailSent extends React.Component {
    render() {
        return (
            <Wrapper>
                <Container>
                    <H2>Email sent</H2>

                    <p className="u-margin-bottom">
                        If an account is found associated with the email address
                        you provided, you will receive an email with
                        instructions on how to reset your password.
                    </p>

                    <Section>
                        <Link to="/" className="c-btn">
                            Return to console
                        </Link>
                    </Section>
                </Container>
            </Wrapper>
        );
    }
}
