import React from 'react';
import {get} from 'lodash';
import {ChatBubble, Archive} from '@app/common/icons';
import {Button} from '@app/common';
import {NODE_STATUSES} from '@app/constants';
import {querystring} from '@app/utils';

interface NoResponsesProps {
    isChatterSelected?: boolean;
    history?: any;
    location?: any;
}

export class NoResponses extends React.Component<NoResponsesProps> {
    handleClickButton = () => {
        const {
            history: {push},
            location,
            isChatterSelected,
        } = this.props;
        push(querystring.getNewResponsePath(location, isChatterSelected));
    };

    render() {
        const search = get(this.props, ['location', 'search'], '');
        const status = querystring.getStatus(search);
        switch (status) {
            case NODE_STATUSES.DRAFT:
                return (
                    <section className="kare-empty-state">
                        <ChatBubble size={60} />
                        <h1>{"You don't have any drafts."}</h1>
                        <p>
                            Save responses that you are working on as draft and
                            publish them when they are ready. Or revert
                            responses to draft to temporarily disable them. You
                            can still assign queries to draft responses on the
                            improve page.
                        </p>
                    </section>
                );
            case NODE_STATUSES.ARCHIVED:
                return (
                    <section className="kare-empty-state">
                        <Archive size={60} />
                        <h1>{"You don't have any archived responses."}</h1>
                        <p>
                            Archive your unwanted responses, so they won’t be
                            used to answer you customers questions. You can
                            still see them on the activity page on the past
                            conversations.
                        </p>
                    </section>
                );
            default:
                return (
                    <section className="kare-empty-state">
                        <ChatBubble size={60} />
                        <h1>What’s the most common question you receive?</h1>
                        <p>
                            Add your first response to start answering your
                            customers.
                        </p>
                        <Button onClick={this.handleClickButton}>
                            {this.props.isChatterSelected
                                ? 'Create a new chatter response'
                                : 'Create a response'}
                        </Button>
                    </section>
                );
        }
    }
}
