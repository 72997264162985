import React from 'react';

interface PanelItemProps {
    label?: string;
    children?: any;
}

export const PanelItem = ({label, children}: PanelItemProps) => {
    return (
        <section id={label} className="c-panel__item">
            {label && <h1>{label}</h1>}
            <div>{children}</div>
        </section>
    );
};
