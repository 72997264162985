import React from 'react';
import Icon from 'react-icon-base';

export const VeryDissatisfied = (props) => (
    <Icon viewBox="0 0 15 15" {...props}>
        <defs>
            <path
                d="M7.4925,0 C3.3525,0 0,3.36 0,7.5 C0,11.64 3.3525,15 7.4925,15 C11.64,15 15,11.64 15,7.5 C15,3.36 11.64,0 7.4925,0 Z M7.5,13.5 C4.185,13.5 1.5,10.815 1.5,7.5 C1.5,4.185 4.185,1.5 7.5,1.5 C10.815,1.5 13.5,4.185 13.5,7.5 C13.5,10.815 10.815,13.5 7.5,13.5 Z M10.125,6.75 C10.7475,6.75 11.25,6.2475 11.25,5.625 C11.25,5.0025 10.7475,4.5 10.125,4.5 C9.5025,4.5 9,5.0025 9,5.625 C9,6.2475 9.5025,6.75 10.125,6.75 Z M4.875,6.75 C5.4975,6.75 6,6.2475 6,5.625 C6,5.0025 5.4975,4.5 4.875,4.5 C4.2525,4.5 3.75,5.0025 3.75,5.625 C3.75,6.2475 4.2525,6.75 4.875,6.75 Z M7.5,8.625 C5.7525,8.625 4.2675,9.72 3.6675,11.25 L11.3325,11.25 C10.7325,9.72 9.2475,8.625 7.5,8.625 Z"
                id="path-6"></path>
        </defs>
        <g
            id="icon-feedback-very_dissatisfied"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd">
            <mask id="mask-6" fill="white">
                <use xlinkHref="#path-6"></use>
            </mask>
            <use
                id="icon/social/mood_bad_24px"
                fill="currentColor"
                fillRule="nonzero"
                xlinkHref="#path-6"></use>
        </g>
    </Icon>
);
