import React from 'react';

interface ITextArea {
    value: string;
    onChange: (...args: any) => any;
}

export const TextArea: React.FunctionComponent<ITextArea> = (props) => {
    return (
        <div className="container">
            <textarea
                onChange={(event) => {
                    props.onChange(event.target.value);
                }}
                value={props.value}
            />
        </div>
    );
};
