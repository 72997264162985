import React from 'react';
import {AbstractReactFactory} from '@projectstorm/react-canvas-core';
import {DiagramEngine} from '@projectstorm/react-diagrams-core';
import get from 'lodash/get';

import {DefaultNodeModel} from './DefaultNodeModel';
import {DefaultNodeWidget} from './DefaultNodeWidget';

export class DefaultNodeFactory extends AbstractReactFactory<
    DefaultNodeModel & {block: any; setRoot: any},
    DiagramEngine
> {
    constructor() {
        super('default');
    }

    generateReactWidget(event): JSX.Element {
        const resp = get(event, ['model', 'options', 'resp']);
        const setRoot = get(event, ['model', 'options', 'setRootNode']);
        const editNode = get(event, ['model', 'options', 'editNode']);
        const isResponse = get(event, ['model', 'options', 'isResponse']);
        const nodeType = get(event, ['model', 'options', 'nodeType']);
        return (
            <DefaultNodeWidget
                engine={this.engine}
                node={event.model}
                resp={resp}
                setRoot={setRoot}
                isResponse={isResponse}
                editNode={editNode}
                //@ts-ignore
                nodeType={nodeType}
            />
        );
    }

    //@ts-ignore
    generateModel(): DefaultNodeModel {
        //@ts-ignore
        return new DefaultNodeModel();
    }
}
