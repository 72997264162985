import React from 'react';
import Icon from 'react-icon-base';

export const Over = (props) => (
    <Icon viewBox="0 0 15 15" {...props}>
        <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M8.82353 1H0V2.47059H8.82353V1Z" fill="black" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2943 13.0587C12.8932 13.0587 15.0001 10.9518 15.0001 8.35286C15.0001 5.75387 12.8932 3.64697 10.2943 3.64697C7.69527 3.64697 5.58838 5.75387 5.58838 8.35286C5.58838 10.9518 7.69527 13.0587 10.2943 13.0587ZM9.58824 7.64694H6.76471V9.05871H9.58824H13.8235V7.64694H9.58824Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.48497 6.88232H0V8.35291H4.44407C4.42272 8.15978 4.41176 7.96352 4.41176 7.76471C4.41176 7.46407 4.43682 7.16928 4.48497 6.88232Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.0439 3.94141H0V5.41199H4.96196C5.23609 4.8603 5.60364 4.3632 6.0439 3.94141Z"
                fill="black"
            />
        </svg>
    </Icon>
);
