import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import cx from 'classnames';

interface FlagIconProps {
    code: string;
    className?: string;
    style?: any;
    ariaLabel?: string;
}

export const FlagIcon = (props: FlagIconProps) => {
    const {code, className, style, ariaLabel} = props;
    return (
        <ReactCountryFlag
            style={{width: '15px', ...style}}
            aria-label={ariaLabel}
            className={cx('flag-icon', className)}
            countryCode={code}
            svg
        />
    );
};
