import React, {useEffect, useState} from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import './QueriesBarChart.scss';
import {BarChart} from '@app/components/BarChart/BarChart';
import {QueriesResponse} from '@app/types/Queries';
import {DATA_KEYS, getChartColor} from '@app/utils/DataRenderingUtils';
import {calcRatio, formatThousands} from '@app/utils/Common';
import {transformQueriesDataToGroupedBarChart} from '@app/utils/QueriesDataTransform';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {BarChartContentLoader} from '@app/components/ContentLoaders/BarChartContentLoader';
import {Moment} from 'moment';
import {connect} from 'react-redux';
import {
    getQueriesTimeseriesSelector,
    isQueriesTimeseriesFetchingSelector,
    queriesTimeseriesErrorSelector,
} from '@app/redux/queries/selectors';
import {dateSelector} from '@app/redux/application/selectors';
import {redux} from '@app/redux';
import {GET_QUERIES_TIMESERIES} from '@app/redux/queries/constants';

interface IQueriesBarChartProps {
    date: {
        minDate?: Moment;
        maxDate?: Moment;
    };
    getQueries: any;
    queriesSelector: any;
    queriesErrorSelector: boolean;
    queriesLoadingSelector: boolean;
}
const initialData = {
    data: {} as QueriesResponse,
    error: '',
    loading: true,
};
const QueriesBarChartComponent = (props: IQueriesBarChartProps) => {
    const [queriesData, setQueriesData] = useState(initialData);
    const {
        date,
        queriesLoadingSelector,
        queriesErrorSelector,
        queriesSelector,
        getQueries,
    } = props;

    useEffect(() => {
        if (!queriesLoadingSelector && date?.minDate && date?.maxDate) {
            getQueries(date.minDate, date.maxDate);
        }
    }, [date]);

    useEffect(() => {
        setQueriesData({
            data: queriesSelector,
            error: queriesErrorSelector ? 'Error' : '',
            loading: queriesLoadingSelector,
        });
    }, [queriesLoadingSelector, queriesErrorSelector, queriesSelector]);

    const showEmptyState = () => {
        if (
            !queriesData.data ||
            !queriesData.data.counters ||
            !queriesData.data.counters.length
        ) {
            return 'empty';
        }

        return '';
    };

    return (
        <ErrorLoadingWrapper
            isLoading={queriesData.loading}
            error={queriesData.error}
            emptyState={showEmptyState()}
            CustomLoading={BarChartContentLoader}
            height={'300px'}
            render={() => {
                const querData = transformQueriesDataToGroupedBarChart(
                    queriesData.data,
                );

                return (
                    <div className="QueriesBarChart__container">
                        <div className="QueriesBarChart__header">
                            <ul>
                                <li>
                                    <span>
                                        <span>All queries</span>
                                    </span>
                                    <strong>
                                        {formatThousands(
                                            querData.totals.queries,
                                        )}
                                    </strong>
                                </li>
                                <li>
                                    <span
                                        className="QueriesBarChart__subtitle"
                                        style={{
                                            color: getChartColor(
                                                DATA_KEYS.RESOLVED,
                                            ),
                                        }}
                                    >
                                        <FiberManualRecordIcon />{' '}
                                        <span>Answered</span>
                                    </span>
                                    <strong>
                                        {calcRatio(
                                            querData.totals.resolved,
                                            querData.totals.queries,
                                        )}{' '}
                                        <span>%</span>
                                    </strong>
                                    <small>
                                        {formatThousands(
                                            querData.totals.resolved,
                                        )}
                                    </small>
                                </li>
                                <li>
                                    <span
                                        className="QueriesBarChart__subtitle"
                                        style={{
                                            color: getChartColor(
                                                DATA_KEYS.HANDED,
                                            ),
                                        }}
                                    >
                                        <FiberManualRecordIcon />{' '}
                                        <span>Uncertain</span>
                                    </span>
                                    <strong>
                                        {calcRatio(
                                            querData.totals.handed,
                                            querData.totals.queries,
                                        )}{' '}
                                        <span>%</span>
                                    </strong>
                                    <small>
                                        {formatThousands(
                                            querData.totals.handed,
                                        )}
                                    </small>
                                </li>
                                <li>
                                    <span
                                        className="QueriesBarChart__subtitle"
                                        style={{
                                            color: getChartColor(
                                                DATA_KEYS.ESCALATED,
                                            ),
                                        }}
                                    >
                                        <FiberManualRecordIcon />{' '}
                                        <span>Missing</span>
                                    </span>
                                    <strong>
                                        {calcRatio(
                                            querData.totals.missing,
                                            querData.totals.queries,
                                        )}{' '}
                                        <span>%</span>
                                    </strong>
                                    <small>
                                        {formatThousands(
                                            querData.totals.missing,
                                        )}
                                    </small>
                                </li>
                            </ul>
                        </div>
                        <BarChart
                            data={querData.counters}
                            keys={[
                                DATA_KEYS.ANSWERED,
                                DATA_KEYS.UNCERTAIN,
                                DATA_KEYS.ESCALATED,
                                DATA_KEYS.MISSING,
                            ]}
                            index={'timeseries'}
                            tickRotation={65}
                        />
                    </div>
                );
            }}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        queriesSelector: getQueriesTimeseriesSelector(state),
        queriesLoadingSelector: isQueriesTimeseriesFetchingSelector(state),
        queriesErrorSelector: queriesTimeseriesErrorSelector(state),
        date: dateSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getQueries: (minDate: Moment, maxDate: Moment) =>
            dispatch(
                redux.rest.actions[GET_QUERIES_TIMESERIES].force({
                    minDate: minDate.unix(),
                    maxDate: maxDate.unix(),
                }),
            ),
    };
};

export const QueriesBarChart = connect(
    mapStateToProps,
    mapDispatchToProps,
)(QueriesBarChartComponent as any);
