import React from 'react';
import {get} from 'lodash';

import {KareTextInput} from '@app/common';

interface SearchBarProps {
    search?: any;
    query?: string;
}

export class SearchBar extends React.Component<SearchBarProps> {
    handleChange = (event) => {
        // This is inline with the redux-form api.
        // @see: https://redux-form.com/7.4.2/docs/api/field.md/#-code-input-onchange-eventorvalue-function-code-
        const query = get(event, ['target', 'value'], event);
        const {search} = this.props;
        search(query);
    };

    render() {
        const {query} = this.props;
        const inputProps = {
            placeholder: 'Search',
            onChange: this.handleChange,
            input: {
                name: 'documents_search',
                onChange: this.handleChange,
                value: query,
            },
            meta: {},
            clearable: true,
            tight: true,
        };
        return (
            <div className="knowledge-base__related-documents-search-bar">
                <KareTextInput {...inputProps} />
            </div>
        );
    }
}
