import qs from 'qs';
import {omit, pick} from 'lodash';

import * as constants from './constants';
import {
    NODE_TYPES,
    COMPOSE,
    CLONE,
    WITH_DOCUMENT,
    NEW,
    STATUS,
    NODE_STATUSES,
    CLUSTER,
} from '@app/constants';

export {constants};

const {TRUE} = constants;

/**
 * Provided a query string and key it will return the value of that key.
 * Defaults to undefined.
 * @param {string} querystring - The querystring to be parsed.
 * @param {string} key - The key whose value to be retrieved.
 * @return {string}
 */
export const getValueByKey = (querystring, key) => {
    const parsed = qs.parse(querystring, {ignoreQueryPrefix: true});
    return parsed[key];
};

/**
 * Takes an existing query string and appends a new values.
 * It will overwrite existing keys if they are the same as the new ones.
 * @param {string} search - The querystring used as the base.
 * @param {Object} newValues - The key value pairs to append.
 */
export const appendQuerystring = (search, newValues) => {
    const parsed = qs.parse(search, {
        ignoreQueryPrefix: true,
    });
    const appended = {...parsed, ...newValues};
    return qs.stringify(appended, {addQueryPrefix: true});
};

/**
 * Takes a querystring and removes and key value pairs by key.
 * @param {string} search - The querystring used as the base.
 * @param {array} keys - The query string keys to be removed from the querystring.
 */
export const removeQuerystrings = (search, keys) => {
    const parsed = qs.parse(search, {
        ignoreQueryPrefix: true,
    });
    return qs.stringify(omit(parsed, keys), {addQueryPrefix: true});
};

// RESPONSE FORM LOGIC

export const responseQuerystringKeys = [COMPOSE, CLONE, WITH_DOCUMENT, CLUSTER];

/**
 * Returns the props from a query string that are needed for the response portal.
 */
export const getResponsePortalPropsFromQuerystring = (search) => {
    const parsed = qs.parse(search, {
        ignoreQueryPrefix: true,
    });
    return pick(parsed, responseQuerystringKeys);
};

/**
 * Returns the path to show a "create new response" form.
 */
export const getNewResponsePath = (location, chatter = false) => {
    const {pathname, search} = location;
    let strippedSearch = removeQuerystrings(search, responseQuerystringKeys);
    if (!chatter) {
        strippedSearch = removeQuerystrings(strippedSearch, STATUS);
    }
    const querystring = appendQuerystring(strippedSearch, {[COMPOSE]: NEW});
    return `${pathname}${querystring}`;
};

/**
 * Returns the path required to show an "edit response" form.
 */
export const getEditResponsePath = (location, id) => {
    const {pathname, search} = location;
    const strippedSearch = removeQuerystrings(search, responseQuerystringKeys);
    const querystring = appendQuerystring(strippedSearch, {[COMPOSE]: id});
    return `${pathname}${querystring}`;
};

/**
 * Returns the path required to show a "clone response" form.
 */
export const getCloneResponsePath = (location, id) => {
    const {pathname, search} = location;
    const strippedSearch = removeQuerystrings(search, responseQuerystringKeys);
    const querystring = appendQuerystring(strippedSearch, {
        [COMPOSE]: id,
        [CLONE]: TRUE,
    });
    return `${pathname}${querystring}`;
};

/**
 * Returns the path required to show a "new response with document attached" form.
 */
export const getNewResponseWithDocumentPath = (location, documentId) => {
    const {pathname, search} = location;
    const strippedSearch = removeQuerystrings(search, responseQuerystringKeys);
    const querystring = appendQuerystring(strippedSearch, {
        [COMPOSE]: NEW,
        [WITH_DOCUMENT]: documentId,
    });
    return `${pathname}${querystring}`;
};

/**
 * Returns the path required to hide the response form, if it was previously visible.
 */
export const getCloseResponseFormPath = (location) => {
    const {pathname, search} = location;
    return `${pathname}${removeQuerystrings(search, responseQuerystringKeys)}`;
};

// Status is either "active" or "archived".
export const getStatus = (search) => {
    const status = getValueByKey(search, STATUS);
    if (status === NODE_STATUSES.DRAFT) {
        return NODE_STATUSES.DRAFT;
    }
    if (status === NODE_STATUSES.ARCHIVED) {
        return NODE_STATUSES.ARCHIVED;
    }
    if (status === NODE_TYPES.CHATTER) {
        return NODE_TYPES.CHATTER;
    }
    return NODE_STATUSES.PUBLISHED;
};
