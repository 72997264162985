import {get} from 'lodash';
import {REGISTER, VERIFY_ACCOUNT} from './constants';
import {DATA, LOADING, SYNC, ERROR} from '../constants';

export const isRegisterLoadingSelector = (state) => {
    return get(state, [REGISTER, LOADING]);
};
export const isVerifyLoadingSelector = (state) => {
    return get(state, [VERIFY_ACCOUNT, LOADING]);
};
export const verifySyncSelector = (state) => {
    return get(state, [VERIFY_ACCOUNT, SYNC]);
};
export const verifyErrorSelector = (state) => {
    return get(state, [VERIFY_ACCOUNT, ERROR]);
};
export const verifyDataSelector = (state) => {
    return get(state, [VERIFY_ACCOUNT, DATA]);
};
