import {ACTIONS, ROWS} from '../constants';
import {PAYLOAD} from '../../constants';
import {TYPE} from '../../../constants';

export const addSelectedRows = (rows) => {
    return {
        [TYPE]: ACTIONS.ADD_SELECTED_ROWS,
        [PAYLOAD]: {[ROWS]: rows},
    };
};

export const removeSelectedRows = (rows) => {
    return {
        [TYPE]: ACTIONS.REMOVE_SELECTED_ROWS,
        [PAYLOAD]: {[ROWS]: rows},
    };
};

export const setSelectedRows = (rows) => {
    return {
        [TYPE]: ACTIONS.SET_SELECTED_ROWS,
        [PAYLOAD]: {[ROWS]: rows},
    };
};
export const resetSelectedRows = () => {
    return {
        [TYPE]: ACTIONS.RESET_SELECTED_ROWS,
    };
};
