import React from 'react';
import {Field} from 'react-final-form';

import './ExtraOptions.scss';
import {Checkbox} from '../inputs/checkbox/Checkbox';
import {TextInput} from '../inputs/text/TextInput';
import {TextArea} from '../inputs/text/TextArea';

export interface IExtraOptions {
    values: any;
}

export const ExtraOptions: React.FunctionComponent<IExtraOptions> = (props) => {
    const {hasCustomAgent, hasRobots, forceLocale} = props.values;
    return (
        <div className="ExtraOptions__container">
            <Field name="ignoreMeta">
                {({input}) => (
                    <Checkbox
                        text="Ignore meta directives"
                        onChange={input.onChange}
                        selected={input.value}
                        helpMessage="Meta directives are defined for a reason. This flag should only be used as a temporary measure in case a needed page was excluded by mistake, while your system administrator fixes it."
                    />
                )}
            </Field>
            <Field name="forceCrawl">
                {({input}) => (
                    <Checkbox
                        text="Force crawl"
                        onChange={input.onChange}
                        selected={input.value}
                        helpMessage="Set the spider to crawl the sites right away."
                    />
                )}
            </Field>
            <Field name="hasCustomAgent">
                {({input}) => (
                    <Checkbox
                        text="Use custom user agent"
                        onChange={input.onChange}
                        selected={input.value}
                        helpMessage="Change the user agent that the spider will use to crawl your sites."
                    />
                )}
            </Field>
            {hasCustomAgent && (
                <Field name="customAgent">
                    {({input}) => (
                        <TextInput
                            placeholder="User agent"
                            onChange={input.onChange}
                            value={input.value}
                        />
                    )}
                </Field>
            )}
            <Field name="hasRobots">
                {({input}) => (
                    <Checkbox
                        text="Use additional Robots.txt"
                        onChange={input.onChange}
                        selected={input.value}
                        helpMessage="Add an additional Robots.txt. The rules specified here will be merged with any Robots.txt found in the host."
                    />
                )}
            </Field>
            {hasRobots && (
                <Field name="robots">
                    {({input}) => (
                        <TextArea
                            onChange={input.onChange}
                            value={input.value}
                        />
                    )}
                </Field>
            )}
            <Field name="ignoreLocale">
                {({input}) => (
                    <Checkbox
                        text="Ignore page locale"
                        onChange={input.onChange}
                        selected={input.value}
                        helpMessage="Ignore the specifed page locale and save all the content in the configured default locale of the account."
                    />
                )}
            </Field>
            <Field name="forceLocale">
                {({input}) => (
                    <Checkbox
                        text="Force locales"
                        onChange={input.onChange}
                        selected={input.value}
                        helpMessage="If no country is specified or if 'ignore locale' is set, force the given locale."
                    />
                )}
            </Field>
            {forceLocale && (
                <Field name="forcedLocales">
                    {({input}) => (
                        <TextInput
                            placeholder="en-GB,it-IT"
                            onChange={input.onChange}
                            value={input.value}
                        />
                    )}
                </Field>
            )}
        </div>
    );
};
