import Axios from 'axios';
import {Moment} from 'moment';

export class Analytics {
    cache = {};

    constructor() {}

    storeData = (URL: string, data: any) => {
        this.cache[URL] = data;
    };

    async getAggregatedResolutionCountersByNode(
        minDate: Moment,
        maxDate: Moment,
        forceRefresh = false,
    ) {
        const URL = `/v3.1/analytics/aggregated/resolution_counters/node_id?from=${minDate.unix()}&to=${maxDate.unix()}`;
        if (this.cache[URL] && !forceRefresh) {
            return {data: this.cache[URL], error: null};
        }
        const res = {
            data: null,
            error: null,
        };
        try {
            const req = await Axios.get(URL);
            res.data = req.data;
            this.storeData(URL, req.data);
        } catch (e) {
            res.error = e;
        }

        return res;
    }

    async getAggregatedExternalLinks(
        minDate: Moment,
        maxDate: Moment,
        forceRefresh = false,
    ) {
        const URL = `/v3.1/analytics/aggregated/external_links/url?from=${minDate.unix()}&to=${maxDate.unix()}`;
        if (this.cache[URL] && !forceRefresh) {
            return {data: this.cache[URL], error: null};
        }
        const res = {
            data: null,
            error: null,
        };
        try {
            const req = await Axios.get(URL);
            res.data = req.data;
            this.storeData(URL, req.data);
        } catch (e) {
            res.error = e;
        }

        return res;
    }

    async getAggregatedAutomationsByTitle(
        minDate: Moment,
        maxDate: Moment,
        forceRefresh = false,
    ) {
        const URL = `/v3.1/analytics/aggregated/automations_clickthrough/automation_type?from=${minDate.unix()}&to=${maxDate.unix()}`;
        if (this.cache[URL] && !forceRefresh) {
            return {data: this.cache[URL], error: null};
        }
        const res = {
            data: null,
            error: null,
        };
        try {
            const req = await Axios.get(URL);
            res.data = req.data;
            this.storeData(URL, req.data);
        } catch (e) {
            res.error = e;
        }

        return res;
    }
    async getAggregatedWebext(
        minDate: Moment,
        maxDate: Moment,
        forceRefresh = false,
    ) {
        const URL = `/v3.1/analytics/aggregated/automations_clickthrough/automation_type,automation_id?from=${minDate.unix()}&to=${maxDate.unix()}`;
        if (this.cache[URL] && !forceRefresh) {
            return {data: this.cache[URL], error: null};
        }
        const res = {
            data: null,
            error: null,
        };
        try {
            const req = await Axios.get(URL);
            res.data = req.data;
            this.storeData(URL, req.data);
        } catch (e) {
            res.error = e;
        }

        return res;
    }
    async getAggregatedAutomationsByNodeId(
        minDate: Moment,
        maxDate: Moment,
        forceRefresh = false,
    ) {
        const URL = `/v3.1/analytics/aggregated/automations_clickthrough/automation_id,node_id?from=${minDate.unix()}&to=${maxDate.unix()}`;
        if (this.cache[URL] && !forceRefresh) {
            return {data: this.cache[URL], error: null};
        }
        const res = {
            data: null,
            error: null,
        };
        try {
            const req = await Axios.get(URL);
            res.data = req.data;
            this.storeData(URL, req.data);
        } catch (e) {
            res.error = e;
        }

        return res;
    }
}
