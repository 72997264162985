import {
    FILTER_QUERY_RESPONSES,
    IMPROVE_ANSWER_ACTION,
    IMPROVE_CREATE_SELECTED_RESPONSE,
    IMPROVE_RESET_SELECTED_RESPONSE,
    RESET_RESPONSE_CARD,
    SELECT_RESPONSE_ACTION,
} from '../constants';
import {get} from 'lodash';

const initialState = {
    selectedResponse: null,
    editingResponse: false,
    isNewResponse: false,
    isAutoResponse: false,
    isStoredResponse: false,
    autoResponse: null,
};

export const reducer = (state = initialState, action) => {
    const responsePayload = get(action, ['payload', 'response'], null);
    const documentPayload = get(action, ['payload', 'document'], null);

    switch (action.type) {
        case IMPROVE_CREATE_SELECTED_RESPONSE:
            return {
                ...state,
                selectedResponse: action.payload,
            };
        case IMPROVE_RESET_SELECTED_RESPONSE:
            return {
                ...state,
                selectedResponse: null,
            };
        case IMPROVE_ANSWER_ACTION:
            return {
                ...state,
                isAutoResponse: false,
                selectedResponse: {
                    ...action.payload.cluster.resolution,
                    document: action.payload.document
                        ? {
                              attachmentTitle: get(
                                  action,
                                  'payload.document.title',
                              ),
                              attachmentUrl: get(
                                  action,
                                  'payload.document.external_url',
                              ),
                          }
                        : null,
                },
            };
        case SELECT_RESPONSE_ACTION:
            return {
                ...state,
                isAutoResponse: false,
                isNewResponse: false,
                selectedResponse: responsePayload
                    ? {
                          ...responsePayload,
                          document: documentPayload
                              ? {
                                    attachmentTitle: documentPayload.title,
                                    attachmentUrl: documentPayload.external_url,
                                }
                              : null,
                      }
                    : null,
            };
        case RESET_RESPONSE_CARD:
            return {
                ...state,
                selectedResponse: null,
                filterQuery: null,
                isNewResponse: false,
                isAutoResponse: false,
                autoResponse: null,
            };
        case FILTER_QUERY_RESPONSES:
            return {...state, filterQuery: action.payload};
        default:
            return state;
    }
};
