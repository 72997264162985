import React, {Component} from 'react';
import {Cell, Pie, PieChart} from 'recharts';
import {PIE_CHART_CONFIG, PIE_CHART_SIZE} from './constants';
import {
    getBounceRateRatio,
    getResolvedRatio,
    mapBounceDataToPieChart,
    mapDataToPieChartModel,
} from './utils';
import {Check, Person} from '@app/common/icons';
import {MdCallMissed} from 'react-icons/md';

interface DashboardActivitySummaryProps {
    conversations?: number;
    resolved?: number;
    escalated?: number;
    bounced?: number;
    deflected?: number;
    handover?: number;
}

export class DashboardActivitySummary extends Component<DashboardActivitySummaryProps> {
    render() {
        const {
            conversations,
            resolved,
            escalated,
            bounced,
            deflected,
            handover,
        } = this.props;
        const resolvedRatio = getResolvedRatio(
            resolved,
            handover,
            bounced,
            conversations,
        );
        const bounceRateRatio = getBounceRateRatio(bounced, conversations);
        const pieChartData = mapDataToPieChartModel(
            resolved,
            handover,
            escalated,
        );
        const bounceRateData = mapBounceDataToPieChart(conversations, bounced);

        return (
            <div className="dashboard-activity-summary">
                {conversations > 0 && (
                    <PieChart
                        width={PIE_CHART_SIZE.width}
                        height={PIE_CHART_SIZE.height}
                        className={'dashboard-activity-summary__pie-chart'}
                    >
                        <text
                            className={
                                'dashboard-activity-summary__resolved-ratio'
                            }
                            x={PIE_CHART_SIZE.width / 2}
                            y={PIE_CHART_SIZE.height / 2 + 7}
                            textAnchor={'middle'}
                        >
                            {resolvedRatio}%
                        </text>
                        <Pie data={pieChartData} {...PIE_CHART_CONFIG}>
                            {pieChartData.map((entry) => (
                                <Cell
                                    className={`dashboard-activity-summary__cell ${entry.name}`}
                                    key={`cell-${entry.name}`}
                                />
                            ))}
                        </Pie>
                    </PieChart>
                )}
                <div className="dashboard-activity-summary__fields">
                    <h2>
                        <span>Conversations</span>
                        <span> {conversations - bounced}</span>
                    </h2>
                    <p className="dashboard-activity-summary__field resolved">
                        <Check />
                        <span>Deflected</span>
                        <span>{deflected}</span>
                    </p>
                    <p className="dashboard-activity-summary__field handover">
                        <Person />
                        <span>Handed over</span>
                        <span>{handover}</span>
                    </p>
                    <p className="dashboard-activity-summary__field escalated">
                        <Person />
                        <span>Escalated</span>
                        <span>{escalated}</span>
                    </p>
                </div>
                <div className="dashboard-activity-summary__pie-bounce-rate">
                    <PieChart
                        width={PIE_CHART_SIZE.width}
                        height={PIE_CHART_SIZE.height}
                        className={
                            'dashboard-activity-summary__bounce-rate-pie-chart'
                        }
                    >
                        <text
                            className={
                                'dashboard-activity-summary__resolved-ratio'
                            }
                            x={PIE_CHART_SIZE.width / 2}
                            y={PIE_CHART_SIZE.height / 2 + 7}
                            textAnchor={'middle'}
                        >
                            {bounceRateRatio}%
                        </text>
                        <Pie data={bounceRateData} {...PIE_CHART_CONFIG}>
                            {bounceRateData.map((entry) => (
                                <Cell
                                    className={`dashboard-activity-summary__bounce-rate-pie-chart-cell ${entry.name}`}
                                    key={`cell-${entry.name}`}
                                />
                            ))}
                        </Pie>
                    </PieChart>
                </div>
                <div className="dashboard-activity-summary__fields dashboard-activity-summary__bounce-rate-fields">
                    <h2>
                        <span>Widget opens</span>
                        <span> {conversations}</span>
                    </h2>
                    <p className="dashboard-activity-summary__field engaged">
                        <Check />
                        <span>Engaged</span>
                        <span>{conversations - bounced}</span>
                    </p>
                    <p className="dashboard-activity-summary__field rate-bounced">
                        <MdCallMissed />
                        <span>Bounced</span>
                        <span>{bounced}</span>
                    </p>
                </div>
            </div>
        );
    }
}
