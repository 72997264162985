import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {redux} from '@app/redux';
import {Button} from '@app/common';
import {Add} from '@app/common/icons';
import {PERMISSIONS, PERMISSION_KEYS, NEW, USER, SCOPES} from '@app/constants';
import Modal from 'react-modal';
import {PasswordResetForm} from './password-reset-form';
import {ChangeEmailForm} from './change-email-form';
import {MODAL_BASE_CONFIG} from '@app/utils/modals';
import {querystring} from '@app/utils';
import {addNotification as notify} from 'reapop';
import Notification from '@app/utils/notifications';
import {SubmissionError, reset} from 'redux-form';
import {UserTable} from './user-table';
import {UserForm} from './user-form';
import {
    adaptUserFormPermissions,
    adaptUserPermissionsForForm,
    hasEmailAuthProvider,
    isUserSSO,
} from './utils';
import {withRouter} from 'react-router';
import {hasScope} from '@app/utils/auth';

interface UserSettingsProps {
    scopes?: any;
    getUsers?: any;
    history?: any;
    resetUserForm?: any;
    updateEmail?: any;
    getLoggedInUser?: any;
    updatePassword?: any;
    location?: any;
    createUser?: any;
    updateUser?: any;
    deleteUser?: any;
    email?: any;
    users?: any;
    isUserAdminCSR?: boolean;
}

export class UserSettingsComponent extends React.Component<UserSettingsProps> {
    state = {
        showChangeEmailModal: false,
        showChangePasswordModal: false,
    };

    componentDidMount() {
        this.getUsers();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.scopes.length === 0 && this.props.scopes.length > 0) {
            this.getUsers();
        }
    }

    getUsers() {
        if (hasScope(this.props.scopes, PERMISSION_KEYS.MIND.IAM)) {
            this.props.getUsers();
        }
    }

    handleClickChangeEmail = () => {
        this.setState({
            showChangeEmailModal: true,
        });
    };

    handleClickChangePassword = () => {
        this.setState({
            showChangePasswordModal: true,
        });
    };

    handleCloseModal = () => {
        const {
            history: {push},
            resetUserForm,
        } = this.props;
        push('/settings/user-settings');
        this.setState({
            showChangePasswordModal: false,
            showChangeEmailModal: false,
        });
        resetUserForm();
    };

    handleAddNewUser = () => {
        const {
            history: {push},
        } = this.props;
        push(`?${USER}=${NEW}`);
    };

    handleSubmitEmailForm = (params, dispatch, props) => {
        return this.props.updateEmail(params, dispatch, props).then(() => {
            this.setState({
                showChangeEmailModal: false,
            });
            this.props.getLoggedInUser();
            this.getUsers();
        });
    };

    handleSubmitPasswordForm = (params, dispatch, props) => {
        return this.props.updatePassword(params, dispatch, props).then(() => {
            this.setState({
                showChangePasswordModal: false,
            });
            this.props.getLoggedInUser();
        });
    };

    handleSubmitUserForm = (params, dispatch, props) => {
        const {
            location: {search},
            createUser,
            updateUser,
        } = this.props;
        const userParam = querystring.getValueByKey(search, USER);
        const userId = userParam !== NEW ? userParam : undefined;
        const onSubmitUserForm = userId ? updateUser : createUser;
        return onSubmitUserForm(params, dispatch, props).then(() => {
            this.props.getLoggedInUser();
            this.getUsers();
            this.handleCloseModal();
        });
    };

    deleteUser = (id) => {
        return this.props
            .deleteUser(id)
            .then(() => {
                this.props.getLoggedInUser();
                this.getUsers();
            })
            .catch(() => {});
    };

    render() {
        const {showChangePasswordModal, showChangeEmailModal} = this.state;
        const {
            email,
            scopes,
            location: {search},
            history: {push},
            users,
            isUserAdminCSR,
        } = this.props;
        const userParam = querystring.getValueByKey(search, USER);
        const userId = userParam !== NEW ? userParam : undefined;
        const activeUser = users.find((user) => user.id === userId) || {};
        const initialValues = {
            ...activeUser,
            [SCOPES]: adaptUserPermissionsForForm(activeUser[SCOPES]),
        };
        return (
            <section className="kare-user-settings">
                <div className="kare-user-settings-top">
                    <div className="kare-user-settings-left">
                        <h1>{email}</h1>
                        <p className="kare-user-settings-permissions">
                            Permissions:{' '}
                            {scopes
                                .map((scope) => PERMISSIONS[scope])
                                .join(', ')}
                        </p>
                    </div>
                    {!isUserSSO() && !isUserAdminCSR && (
                        <div className="kare-user-settings-right">
                            <Button
                                onClick={this.handleClickChangeEmail}
                                outlined
                            >
                                Change email
                            </Button>
                            <Button
                                onClick={this.handleClickChangePassword}
                                outlined
                            >
                                Change password
                            </Button>
                        </div>
                    )}
                </div>
                {hasScope(this.props.scopes, PERMISSION_KEYS.MIND.IAM) && (
                    <Fragment>
                        <UserTable data={users} push={push} />
                        {hasEmailAuthProvider() && (
                            <Button
                                className="user-settings-add-new-user"
                                icon={Add}
                                inverted
                                onClick={this.handleAddNewUser}
                            >
                                Add new user
                            </Button>
                        )}
                    </Fragment>
                )}
                <Modal
                    isOpen={showChangeEmailModal}
                    onRequestClose={this.handleCloseModal}
                    style={{
                        ...MODAL_BASE_CONFIG,
                        content: {
                            ...MODAL_BASE_CONFIG.content,
                            padding: '20px',
                            width: '620px',
                        },
                    }}
                >
                    <ChangeEmailForm
                        onSubmit={this.handleSubmitEmailForm}
                        onCloseModal={this.handleCloseModal}
                    />
                </Modal>
                <Modal
                    isOpen={showChangePasswordModal}
                    onRequestClose={this.handleCloseModal}
                    style={{
                        ...MODAL_BASE_CONFIG,
                        content: {
                            ...MODAL_BASE_CONFIG.content,
                            width: '560px',
                            padding: '20px',
                        },
                    }}
                >
                    <PasswordResetForm
                        onSubmit={this.handleSubmitPasswordForm}
                        onCloseModal={this.handleCloseModal}
                    />
                </Modal>
                <Modal
                    isOpen={!!userParam}
                    onRequestClose={this.handleCloseModal}
                    style={{
                        ...MODAL_BASE_CONFIG,
                        content: {
                            ...MODAL_BASE_CONFIG.content,
                            width: '560px',
                            padding: '20px',
                        },
                    }}
                >
                    <UserForm
                        initialValues={initialValues}
                        onSubmit={this.handleSubmitUserForm}
                        onCloseModal={this.handleCloseModal}
                        deleteUser={this.deleteUser}
                        users={users}
                    />
                </Modal>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        users: redux.user.selectors.getUsersSelector(state),
        email: redux.user.selectors.emailSelector(state),
        isUserAdminCSR: redux.user.selectors.isSuperAdminSelector(state),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getLoggedInUser: () => {
            dispatch(
                redux.rest.actions[redux.user.constants.GET_LOGGED_IN_USER](),
            ).catch((error) => {
                if (error === 'isLoading') return;
                console.warn('getLoggedInUser Error -> ', error);
            });
        },
        getUsers: () => {
            return dispatch(
                redux.rest.actions[redux.user.constants.GET_USERS](),
            ).catch((error) => {
                if (error === 'isLoading') return;
                console.warn('getUsers Error -> ', error);
            });
        },
        updatePassword: (params, dispatch, props) => {
            const {reset} = props;
            const body = {
                current_password: params.currentPassword,
                new_password: params.password,
            };
            return dispatch(
                redux.rest.actions[redux.user.constants.UPDATE_EMAIL](
                    {},
                    {body: JSON.stringify(body)},
                ),
            )
                .then(() => {
                    reset();
                    return dispatch(notify(Notification.passwordUpdated()));
                })
                .catch((error) => {
                    throw new SubmissionError({
                        _error: error.message,
                    });
                });
        },
        updateEmail: (params, dispatch, props) => {
            const {reset} = props;
            const body = {
                email: params.email,
            };
            return dispatch(
                redux.rest.actions[redux.user.constants.UPDATE_LOGGED_IN_USER](
                    {},
                    {body: JSON.stringify(body)},
                ),
            )
                .then(() => {
                    reset();
                    return dispatch(notify(Notification.emailUpdated()));
                })
                .catch((error) => {
                    throw new SubmissionError({
                        _error: error.message,
                    });
                });
        },
        createUser: (params, dispatch, props) => {
            const {reset} = props;
            const scopes = adaptUserFormPermissions(params[SCOPES]);
            const body = {
                email: params.email,
                password: params.password,
                scopes,
            };
            return dispatch(
                redux.rest.actions[redux.user.constants.CREATE_USER](
                    {},
                    {body: JSON.stringify(body)},
                ),
            )
                .then(() => {
                    reset();
                    return dispatch(notify(Notification.userAdded()));
                })
                .catch((error) => {
                    throw new SubmissionError({
                        _error: error.message,
                    });
                });
        },
        updateUser: (params, dispatch, props) => {
            const {reset} = props;
            const scopes = adaptUserFormPermissions(params[SCOPES]);
            const body = {
                email: params.email,
                scopes,
            };
            return dispatch(
                redux.rest.actions[redux.user.constants.UPDATE_USER](
                    {id: params.id},
                    {body: JSON.stringify(body)},
                ),
            )
                .then(() => {
                    reset();
                    return dispatch(notify(Notification.userUpdated()));
                })
                .catch((error) => {
                    throw new SubmissionError({
                        _error: error.message || 'Something went wrong',
                    });
                });
        },
        deleteUser: (id) => {
            const {
                history: {push},
            } = ownProps;
            return dispatch(
                redux.rest.actions[redux.user.constants.DELETE_USER]({id}),
            ).then(() => {
                dispatch(notify(Notification.userDeleted()));
                push('/settings/user-settings');
            });
        },
        resetUserForm: () => {
            dispatch(reset('USER_FORM'));
        },
    };
};

export const UserSettings = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UserSettingsComponent),
);
