import {
    CHANGE_SELECTED_MAX_DATE,
    CHANGE_SELECTED_MIN_DATE,
} from '@app/redux/application/constants';
import {Moment} from 'moment';

export const changeSelectedMinDate = (minDate: Moment) => {
    return {
        type: CHANGE_SELECTED_MIN_DATE,
        payload: {
            minDate,
        },
    };
};

export const changeSelectedMaxDate = (maxDate: Moment) => {
    return {
        type: CHANGE_SELECTED_MAX_DATE,
        payload: {
            maxDate,
        },
    };
};
