import React, {FunctionComponent as Fc} from 'react';

interface IMinusProps {}

export const Minus: Fc<IMinusProps> = (props) => (
    <svg
        {...props}
        width="8"
        height="8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect y="5" width="8" height="8" fill="currentColor" />
    </svg>
);
