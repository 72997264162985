import React from 'react';
import cx from 'classnames';
import {Spinner} from './spinner';

interface ButtonProps {
    children?: any;
    className?: any;
    type?: string;
    inverted?: boolean;
    secondaryText?: boolean;
    outlined?: boolean;
    outlinedPrimary?: boolean;
    disabled?: boolean;
    showSpinner?: boolean;
    onClick?: any;
    style?: any;
    icon?: any;
    iconRight?: any;
    component?: any;
    noPadding?: boolean;
    htmlType?: string;
    onMouseEnter?: any;
    onMouseLeave?: any;
}

export class Button extends React.Component<ButtonProps> {
    render() {
        const {
            children,
            component,
            className,
            type,
            outlined,
            outlinedPrimary,
            inverted,
            disabled,
            onClick,
            style = {},
            showSpinner = false,
            icon,
            iconRight,
            secondaryText,
            noPadding,
            htmlType = 'submit',
        } = this.props;
        const btnClass = cx(className, 'c-btn', {
            'c-btn--primary': type === 'primary',
            'c-btn--secondary': type === 'secondary',
            'c-btn--inverted': inverted,
            'c-btn--secondary-text': secondaryText,
            'c-btn--outlined': outlined,
            'c-btn--outlined-primary': outlinedPrimary,
            'c-btn--has-icon': icon,
            'c-btn--show-spinner': showSpinner,
            'c-btn--no-padding': noPadding,
        });
        const Icon = icon;
        const IconRight = iconRight;

        const Component = component || 'button';

        return (
            <Component
                disabled={disabled}
                className={btnClass}
                onClick={onClick}
                style={style}
                type={htmlType}
            >
                {icon && <Icon className="c-btn__icon-left" size={15} />}
                <span>{children}</span>
                {showSpinner && <Spinner size={20} border={3} color="#fff" />}
                {iconRight && (
                    <IconRight className="c-btn__icon-right" size={15} />
                )}
            </Component>
        );
    }
}
