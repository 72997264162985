import {change} from 'redux-form';

import {RESPONSE_FORM} from '../../responses/constants';

export const attachDocumentDispatcher = (dispatch) => (id) => {
    // The object that we send with the response object.
    const responseDocumentProperty = {
        id,
        mode: 'attached',
        selection: '',
    };
    return dispatch(
        change(RESPONSE_FORM, 'action.document', responseDocumentProperty),
    );
};

export const setResponseTitleDispatcher = (dispatch) => (title) => {
    dispatch(change(RESPONSE_FORM, 'title', title));
};

export const setResponseQueriesDispatcher = (dispatch) => (query) => {
    dispatch(change(RESPONSE_FORM, 'queries', [{text: query, public: true}]));
};
