// @ts-ignore
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import React, {FunctionComponent as Fc} from 'react';

import {TabBar} from './components/TabBar/TabBar';
import {Activity} from './containers/Activity/Activity';
import {Analysis} from './containers/Analysis/Analysis';
import {DateRange} from './containers/DateRange/DateRange';
import {Performance} from './containers/Performance/Performance';
import {Usage} from './containers/Usage/Usage';
import {StoreProvider} from './state';
import {Clickthrough} from './containers/Clickthrough/Clickthrough';
import {EntryPage} from './containers/EntryPage/EntryPage';
import {Automation} from './containers/Automation/Automation';
import {Beacons} from './containers/Beacons/Beacons';
import {ToastContainer} from 'react-toastify';
import {Route, Switch, useHistory} from 'react-router-dom';
import {AudienceDefault} from './containers/Default/AudienceDefault';
import cx from 'classnames';
import {Dialog} from './containers/Dialog/Dialog';
import {ExitPage} from './containers/ExitPage/ExitPage';
import {Consolidate} from './containers/Consolidate/Consolidate';
import {Expand} from './containers/Expand/Expand';
import {WebCrawlerForm} from './containers/Form/WebCrawlerForm';
import {CxMetrics} from './containers/CxMetrics/CxMetrics';
import {CX_TYPES} from './containers/CxMetrics/constants';
import {npsEnabled} from './utils/Common';

interface IKareLibDashboard {
    history: any;
}

export const KareLibDashboard: Fc<IKareLibDashboard> = ({history}) => {
    const getActiveTabBasedOnPathName = (pathname: string) => {
        switch (pathname) {
            case '/bi/activity':
                return 'activity';
            case '/bi/automation':
                return 'automation';
            case '/bi/usage':
                return 'usage';
            case '/bi/click':
                return 'click';
            case '/bi/beacons':
                return 'beacons';
            case '/bi/performance':
                return 'performance';
            case '/bi/knowledge':
                return 'knowledge';
            case '/bi/engagement':
                return 'engagement';
            case '/bi/completion':
                return 'completion';
            case '/bi/cxmetrics':
            case '/bi/cxmetrics/':
            case '/bi/cxmetrics/nps':
                return npsEnabled() ? 'cxmetrics/nps' : 'cxmetrics/csat';
            case '/bi/cxmetrics/ces':
                return 'cxmetrics/ces';
            case '/bi/cxmetrics/csat':
                return 'cxmetrics/csat';
            case '/bi/kpis':
                return 'KPIs';
            default:
                return 'activity';
        }
    };

    const getTitleBasedOnPathName = (pathname: string) => {
        switch (pathname) {
            case '/bi/knowledge':
            case '/bi/performance':
                return 'knowledge';
            case '/bi/engagement':
            case '/bi/beacons':
                return 'engagement';
            case '/bi/completion':
            case '/bi/click':
            case '/bi/automation':
                return 'completion';
            case '/bi/cxmetrics':
            case '/bi/cxmetrics/':
            case '/bi/cxmetrics/nps':
            case '/bi/cxmetrics/ces':
            case '/bi/cxmetrics/csat':
                return 'CX Metrics';
            case '/bi/kpis':
                return 'KPIs';
            default:
                return 'activity';
        }
    };

    const renderActiveTab = (activeTab: string) => {
        switch (activeTab) {
            default:
            case 'activity':
                return <Activity />;
            case 'usage':
                return <Usage />;
            case 'click':
                return <Clickthrough history={history} />;
            case 'automation':
                return <Automation history={history} />;
            case 'beacons':
                return <Beacons history={history} />;
            case 'knowledge':
                return <Analysis history={history} />;
            case 'performance':
                return <Performance history={history} />;
            case 'completion':
                return <ExitPage />;
            case 'engagement':
                return <EntryPage />;
            case 'cxmetrics/nps':
                return (
                    <CxMetrics
                        cxType={CX_TYPES.NPS}
                        heroTitle="Net Promoter Score®"
                    />
                );
            case 'cxmetrics/ces':
                return (
                    <CxMetrics
                        cxType={CX_TYPES.CES}
                        heroTitle="Net Easy Score"
                    />
                );
            case 'cxmetrics/csat':
                return (
                    <CxMetrics
                        cxType={CX_TYPES.CSAT}
                        heroTitle="Satisfaction Score"
                    />
                );
        }
    };

    const changeActiveTab = (active: string) => {
        history.push(`/bi/${active}`);
    };

    const getTabs = (path: string) => {
        switch (path) {
            case '/bi/engagement':
            case '/bi/beacons':
            case '/bi/entry':
                return [
                    {value: 'engagement', label: 'Entry pages'},
                    {value: 'beacons', label: 'Tooltips'},
                ];
            case '/bi/completion':
            case '/bi/click':
            case '/bi/automation':
                return [
                    {value: 'completion', label: 'Exit page'},
                    {value: 'click', label: 'Click analysis'},
                    {value: 'automation', label: 'Automations'},
                ];
            case '/bi/knowledge':
            case '/bi/performance':
                return [
                    {value: 'knowledge', label: 'Impression analysis'},
                    {value: 'performance', label: 'Knowledge performance'},
                ];
            case '/bi/cxmetrics':
            case '/bi/cxmetrics/nps':
            case '/bi/cxmetrics/ces':
            case '/bi/cxmetrics/csat':
                return npsEnabled()
                    ? [
                          {
                              value: 'cxmetrics/nps',
                              label: 'Net Promoter Score® (NPS)',
                          },
                          {
                              value: 'cxmetrics/csat',
                              label: 'Customer Satisfaction Scale (CSAT)',
                          },
                          {
                              value: 'cxmetrics/ces',
                              label: 'Customer Effort Score (CES)',
                          },
                      ]
                    : [
                          {
                              value: 'cxmetrics/csat',
                              label: 'Customer Satisfaction Scale (CSAT)',
                          },
                          {
                              value: 'cxmetrics/ces',
                              label: 'Customer Effort Score (CES)',
                          },
                      ];
            default:
                return [];
        }
    };

    const tabs = getTabs(history.location.pathname);

    return (
        <StoreProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <div className="dashboard__container">
                    <div className="dashboard__header">
                        <h1 className="dashboard__title">
                            {getTitleBasedOnPathName(history.location.pathname)}
                        </h1>
                        <DateRange />
                    </div>
                    {tabs?.length > 0 && (
                        <TabBar
                            activeTab={getActiveTabBasedOnPathName(
                                history.location.pathname,
                            )}
                            setActiveTab={changeActiveTab}
                            tabs={tabs}
                        />
                    )}
                    {renderActiveTab(
                        getActiveTabBasedOnPathName(history.location.pathname),
                    )}
                </div>
            </MuiPickersUtilsProvider>
        </StoreProvider>
    );
};

interface KareLibActionsProps {
    history: any;
}

export const KareLibActions: Fc<KareLibActionsProps> = () => {
    return <div>Actions</div>;
};

export {ActionList} from './components/list/ActionsList';
export {ActionListSelector} from './components/selector/ActionListSelector';
export {ActionListModal} from './components/modal/ActionListModal';

export const KareLibAudience: Fc<{}> = () => {
    const defaultClassname = cx(
        'dashboard__whiteContainer',
        'dashboard__container',
    );
    return (
        <StoreProvider>
            <Switch>
                <Route path="/audience/default">
                    <div className={defaultClassname}>
                        <AudienceDefault />
                    </div>
                </Route>
            </Switch>
            <ToastContainer />
        </StoreProvider>
    );
};
export const KareLibDialog: Fc<{}> = () => {
    return (
        <StoreProvider>
            <Dialog />
            <ToastContainer />
        </StoreProvider>
    );
};

export const KareLibImprove: Fc<{}> = () => {
    const history = useHistory();
    const getActiveTabBasedOnPathName = (pathname: string) => {
        switch (pathname) {
            case '/consolidate/broken':
            case '/consolidate/under':
            case '/consolidate/over':
                return 'consolidate';
            case '/expand':
            case '/expand/missing':
            case '/expand/knowledge':
            case '/expand/muted':
                return 'expand';
            default:
                return 'consolidate';
        }
    };
    const renderContentByTab = (tab: string) => {
        switch (tab) {
            case 'expand':
                return <Expand history={history} />;
            case 'consolidate':
                return <Consolidate />;
            default:
                return <Expand history={history} />;
        }
    };

    return (
        <StoreProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {renderContentByTab(
                    getActiveTabBasedOnPathName(history.location.pathname),
                )}
            </MuiPickersUtilsProvider>
            <ToastContainer />
        </StoreProvider>
    );
};
export {ChatterMessages} from './containers/chatter/ChatterMessages';
export {UploadContentModal} from './containers/modal-upload-content/UploadContentModal';
export {TypeContent} from './containers/modal-upload-content/UploadContentModal';

export const KareLibWebCrawler: Fc<{}> = (props) => {
    return (
        <div>
            <WebCrawlerForm {...props} />
        </div>
    );
};
