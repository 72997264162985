import React from 'react';
import Icon from 'react-icon-base';

export const CollapsedResponses = (props) => (
    <Icon width="45px" height="45px" viewBox="0 0 45 45" {...props}>
        <g
            id="icon/responses-collapsed"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <path
                d="M29,18 C29.55,18 30,18.45 30,19 L30,31 L27,28 L19,28 C18.45,28 18,27.55 18,27 L18,26 L28,26 L28,18 L29,18 Z M25.5,16 C26.32875,16 27,16.67125 27,17.5 L27,23.5 C27,24.32875 26.32875,25 25.5,25 L18,25 L15,28 L15,17.5 C15,16.67125 15.67125,16 16.5,16 L25.5,16 Z M21.5,24 L21.5,23 L20.5,23 L20.5,24 L21.5,24 Z M22.5325,20.1275 C22.8225,19.8375 23,19.44 23,19 C23,17.895 22.105,17 21,17 C19.895,17 19,17.895 19,19 L20,19 C20,18.4475 20.4475,18 21,18 C21.5525,18 22,18.4475 22,19 C22,19.275 21.8875,19.525 21.7075,19.705 L21.085,20.335 C20.725,20.6975 20.5,21.1975 20.5,21.75 L20.5,22 L21.5,22 C21.5,21.25 21.725,20.9475 22.085,20.5875 L22.5325,20.1275 Z"
                id="SQ-Icon"
                fill="currentColor"
            />
        </g>
    </Icon>
);
