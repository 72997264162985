import _ from 'lodash';

/**
 * Adapt the document data so that it can be consumed by the DocumentDetail component.
 * TODO(rupertrutland): Refactor this.
 * @param {Object} param0
 */
export const documentDetailAdapter = ({
    document,
    isDocumentFetching,
    isDocumentAttached,
}) => {
    if (isDocumentFetching) {
        return {
            headerTitle: 'Loading...',
            documentTitle: null,
            documentBody: 'Loading...',
        };
    }
    if (_.isEmpty(document)) {
        return {
            headerTitle: 'Document not found',
            documentTitle: null,
            documentBody:
                'Unfortunately no document was found for the id provided.',
        };
    }
    if (isDocumentAttached) {
        return {
            headerTitle: 'Attached document',
            documentTitle: document.title,
            documentBody: document.body,
        };
    }
    return {
        headerTitle: 'Attach a document',
        documentTitle: document.title,
        documentBody: document.body,
    };
};
