import {redux} from '../index';
import {
    GET_ARCHIVED_RESPONSES,
    GET_DRAFT_RESPONSES,
    GET_INBOUND_NODES,
    GET_OUTBOUND_NODES,
    GET_PUBLISHED_CHATTER_RESPONSES,
    GET_PUBLISHED_RESPONSES,
} from '../responses/constants';
import {
    GET_ARCHIVED_DOCUMENTS,
    GET_PUBLISHED_DOCUMENTS,
    GET_RELATED_DOCUMENTS,
} from '../documents/constants';
import {LIMIT} from '@app/constants';
import {
    setArchivedDocuments,
    setArchivedDocumentsError,
    setArchivedDocumentsLoading,
    setArchivedResponses,
    setArchivedResponsesError,
    setArchivedResponsesLoading,
    setDraftResponses,
    setDraftResponsesError,
    setDraftResponsesLoading,
    setInboundNodes,
    setInboundNodesError,
    setInboundNodesLoading,
    setPublishedChatterResponses,
    setPublishedChatterResponsesError,
    setPublishedChatterResponsesLoading,
    setPublishedDocuments,
    setPublishedDocumentsError,
    setPublishedDocumentsLoading,
    setPublishedResponses,
    setPublishedResponsesError,
    setPublishedResponsesLoading,
    setRelatedDocuments,
    setRelatedDocumentsError,
    setRelatedDocumentsLoading,
    setRelatedResponses,
    setRelatedResponsesError,
    setRelatedResponsesLoading,
    unsetArchivedDocumentsError,
    unsetArchivedDocumentsLoading,
    unsetArchivedResponsesError,
    unsetArchivedResponsesLoading,
    unsetDraftResponsesError,
    unsetDraftResponsesLoading,
    unsetInboundNodesError,
    unsetInboundNodesLoading,
    unsetPublishedChatterResponsesError,
    unsetPublishedChatterResponsesLoading,
    unsetPublishedDocumentsError,
    unsetPublishedDocumentsLoading,
    unsetPublishedResponsesError,
    unsetPublishedResponsesLoading,
    unsetRelatedDocumentsError,
    unsetRelatedDocumentsLoading,
    unsetRelatedResponsesError,
    unsetRelatedResponsesLoading,
} from './actions';

const getDispatcher =
    ({
        actionKey,
        setEntriesAction,
        setLoadingAction,
        unsetLoadingAction,
        setErrorAction,
        unsetErrorAction,
    }) =>
    (dispatch) =>
    (params = {}) => {
        dispatch(unsetErrorAction());
        dispatch(setLoadingAction());

        const payload: any = {
            ...params,
            [LIMIT]: 250,
        };
        const getNext = (
            cursor = undefined,
            items = [],
            parentResolve = undefined,
        ) => {
            return new Promise((resolve, reject) => {
                if (!parentResolve) parentResolve = resolve;
                if (cursor) payload.cursor = cursor;
                dispatch(redux.rest.actions[actionKey](payload)).then((res) => {
                    const {next_cursor, nodes, edges} = res;

                    const newItems = nodes?.length ? nodes : edges || [];
                    items = [...items, ...newItems];

                    if (next_cursor && newItems.length)
                        return getNext(next_cursor, items, parentResolve);

                    return parentResolve(items);
                });
            });
        };
        getNext()
            .then((items) => {
                dispatch(unsetLoadingAction());
                dispatch(setEntriesAction(items));
            })
            .catch((error) => {
                if (error === 'isLoading') {
                    return Promise.resolve();
                }
                dispatch(unsetLoadingAction());
                dispatch(setErrorAction(error));
            });
    };

export const getAllPublishedChatterResponsesDispatcher = getDispatcher({
    actionKey: GET_PUBLISHED_CHATTER_RESPONSES,
    setEntriesAction: setPublishedChatterResponses,
    setLoadingAction: setPublishedChatterResponsesLoading,
    unsetLoadingAction: unsetPublishedChatterResponsesLoading,
    setErrorAction: setPublishedChatterResponsesError,
    unsetErrorAction: unsetPublishedChatterResponsesError,
});

export const getAllPublishedResponsesDispatcher = getDispatcher({
    actionKey: GET_PUBLISHED_RESPONSES,
    setEntriesAction: setPublishedResponses,
    setLoadingAction: setPublishedResponsesLoading,
    unsetLoadingAction: unsetPublishedResponsesLoading,
    setErrorAction: setPublishedResponsesError,
    unsetErrorAction: unsetPublishedResponsesError,
});

export const getAllArchivedResponsesDispatcher = getDispatcher({
    actionKey: GET_ARCHIVED_RESPONSES,
    setEntriesAction: setArchivedResponses,
    setLoadingAction: setArchivedResponsesLoading,
    unsetLoadingAction: unsetArchivedResponsesLoading,
    setErrorAction: setArchivedResponsesError,
    unsetErrorAction: unsetArchivedResponsesError,
});

export const getAllDraftResponsesDispatcher = getDispatcher({
    actionKey: GET_DRAFT_RESPONSES,
    setEntriesAction: setDraftResponses,
    setLoadingAction: setDraftResponsesLoading,
    unsetLoadingAction: unsetDraftResponsesLoading,
    setErrorAction: setDraftResponsesError,
    unsetErrorAction: unsetDraftResponsesError,
});

export const getAllPublishedDocumentsDispatcher = getDispatcher({
    actionKey: GET_PUBLISHED_DOCUMENTS,
    setEntriesAction: setPublishedDocuments,
    setLoadingAction: setPublishedDocumentsLoading,
    unsetLoadingAction: unsetPublishedDocumentsLoading,
    setErrorAction: setPublishedDocumentsError,
    unsetErrorAction: unsetPublishedDocumentsError,
});

export const getAllArchivedDocumentsDispatcher = getDispatcher({
    actionKey: GET_ARCHIVED_DOCUMENTS,
    setEntriesAction: setArchivedDocuments,
    setLoadingAction: setArchivedDocumentsLoading,
    unsetLoadingAction: unsetArchivedDocumentsLoading,
    setErrorAction: setArchivedDocumentsError,
    unsetErrorAction: unsetArchivedDocumentsError,
});

export const getAllRelatedDocumentsDispatcher = getDispatcher({
    actionKey: GET_RELATED_DOCUMENTS,
    setEntriesAction: setRelatedDocuments,
    setLoadingAction: setRelatedDocumentsLoading,
    unsetLoadingAction: unsetRelatedDocumentsLoading,
    setErrorAction: setRelatedDocumentsError,
    unsetErrorAction: unsetRelatedDocumentsError,
});

export const getAllRelatedResponsesDispatcher = getDispatcher({
    actionKey: GET_OUTBOUND_NODES,
    setEntriesAction: setRelatedResponses,
    setLoadingAction: setRelatedResponsesLoading,
    unsetLoadingAction: unsetRelatedResponsesLoading,
    setErrorAction: setRelatedResponsesError,
    unsetErrorAction: unsetRelatedResponsesError,
});

export const getAllInboundNodesDispatcher = getDispatcher({
    actionKey: GET_INBOUND_NODES,
    setEntriesAction: setInboundNodes,
    setLoadingAction: setInboundNodesLoading,
    unsetLoadingAction: unsetInboundNodesLoading,
    setErrorAction: setInboundNodesError,
    unsetErrorAction: unsetInboundNodesError,
});
