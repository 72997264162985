import React from 'react';
import cx from 'classnames';

import {Launcher, BrandedFABIcon} from '@app/common/icons';
import {BRANDED, FLOATING, CUSTOM} from '../constants';

interface HorizontalLauncherProps {
    backgroundColor?: string;
    iconStyle?: any;
    fabStyle?: string;
    onMouseEnter?: any;
    onMouseLeave?: any;
    onClick?: any;
    launcherSize?: string;
    launcherPosition?: string;
    launcherSidePosition?: string;
    text: string;
    popOverText?: string;
    windowPreview?: boolean;
    showPopOver?: boolean;
    textStyle?: any;
    buttonStyle?: any;
}

export class HorizontalLauncher extends React.Component<HorizontalLauncherProps> {
    render() {
        const {
            backgroundColor,
            iconStyle,
            fabStyle,
            launcherSize,
            launcherPosition,
            launcherSidePosition,
            text,
            showPopOver,
            popOverText,
            buttonStyle,
            textStyle,
            onMouseEnter,
            onMouseLeave,
            onClick,
            windowPreview,
        } = this.props;
        const widgetClass = cx(
            'console-kare-widget',
            `console-kare-widget--style-${fabStyle}`,
            `console-kare-widget--size-${launcherSize}`,
            `console-kare-widget--position-${launcherPosition}`,
        );

        return (
            <div
                className={cx(
                    'launcher-preview__default-demo',
                    `console-kare-widget--side-${launcherSidePosition}`,
                )}
            >
                <div className={widgetClass}>
                    <button
                        className="console-kare-widget-launcher"
                        style={
                            fabStyle === FLOATING || fabStyle === CUSTOM
                                ? buttonStyle
                                : undefined
                        }
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        onClick={onClick}
                    >
                        {text && fabStyle === FLOATING && (
                            <span
                                className="console-kare-widget-launcher-text"
                                style={{color: textStyle}}
                            >
                                {text}
                            </span>
                        )}
                        {fabStyle === BRANDED ? (
                            <BrandedFABIcon style={iconStyle} />
                        ) : (
                            <Launcher style={iconStyle} />
                        )}
                    </button>
                </div>
                {popOverText && showPopOver && windowPreview && (
                    <div className="launcher-popover__preview-container">
                        <div
                            className="launcher-popover__preview"
                            ref={(ref) => {
                                if (ref) {
                                    ref.style.setProperty(
                                        'background',
                                        backgroundColor,
                                        'important',
                                    );
                                }
                            }}
                        >
                            {popOverText}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
