import React from 'react';

interface PanelBodyProps {
    children?: any;
}
/**
 * Reusable panel body - a scrollable section in the middle of a panel.
 */
export class PanelBody extends React.Component<PanelBodyProps> {
    render() {
        return (
            <section className="knowledge-base__panel-body">
                {this.props.children}
            </section>
        );
    }
}
