import React from 'react';
import {Field, reduxForm} from 'redux-form';

import {Button} from '@app/common/button';
import {KareTextInput} from '@app/common/kare-text-input';
import {validators} from '@app/utils';
import {getProviderName, getProviderTLD} from './utils';

const {required} = validators;

function subdomainNormalize(value) {
    return value.toLowerCase();
}

interface ConnectFormProps {
    handleSubmit: any;
    provider: string;
    valid: boolean;
}

export class ConnectForm extends React.Component<ConnectFormProps> {
    render() {
        const {handleSubmit, valid, provider} = this.props;
        return (
            <div className="kare-integrations__zendesk-connect-form">
                <Field
                    component={KareTextInput}
                    name="domain"
                    label={`${getProviderName(provider)} subdomain`}
                    normalize={subdomainNormalize}
                    validate={required}
                    appendText={getProviderTLD(provider)}
                />
                <section className="submit-button-container">
                    <Button
                        className="u-margin-right-small"
                        disabled={!valid}
                        onClick={handleSubmit}
                    >
                        Connect with {getProviderName(provider)}
                    </Button>
                </section>
            </div>
        );
    }
}

export default reduxForm({
    form: 'integrationConnectForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(ConnectForm);
