import {METHODS} from '../constants';
import {
    GET_CONVERSATION_MESSAGES,
    GET_CONVERSATIONS,
    GET_CONVERSATIONS_TIMESERIES,
    GET_AGGREGATED_CONVERSATIONS_TIMESERIES,
} from './constants';
import {
    conversationsTransformer,
    messagesTransformer,
} from './rest-transformer/conversationsTransformer';

const {GET} = METHODS;

export const rest = {
    [GET_CONVERSATIONS]: {
        url: '/v3.0/activity/conversations',
        options: {
            method: GET,
        },
        transformer: conversationsTransformer,
    },
    [GET_CONVERSATION_MESSAGES]: {
        url: '/v3.0/activity/conversations/:id/events',
        options: {
            method: GET,
        },
        transformer: messagesTransformer,
    },
    [GET_CONVERSATIONS_TIMESERIES]: {
        url: '/v3.0/analytics/timeseries/conversations/daily?from=:minDate&to=:maxDate',
        options: {
            method: GET,
        },
    },
    [GET_AGGREGATED_CONVERSATIONS_TIMESERIES]: {
        url: '/v3.0/analytics/aggregated/conversations?from=:minDate&to=:maxDate',
        options: {
            method: GET,
        },
    },
};
