import {ACTIONS, CONTENT_LOCALE} from '../constants';
import {PAYLOAD} from '../../constants';
import {TYPE} from '@app/constants';

export const setContentLocaleActionCreator = (locale) => {
    return {
        [TYPE]: ACTIONS.SET_CONTENT_LOCALE,
        [PAYLOAD]: {[CONTENT_LOCALE]: locale},
    };
};
export const resetContentLocaleActionCreator = () => {
    return {
        [TYPE]: ACTIONS.RESET_CONTENT_LOCALE,
    };
};
