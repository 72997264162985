import React from 'react';

export interface RadialContentLoaderProps {}

export const RadialContentLoader: React.FC<RadialContentLoaderProps> = (
    props,
) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="161"
            height="159"
            viewBox="0 0 161 159"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-410 -508)">
                    <g transform="translate(395 449)">
                        <rect
                            width="195"
                            height="245"
                            x="0"
                            y="0"
                            fill="#FFF"
                            rx="2"
                        ></rect>
                        <g fill="#DFE1E1" transform="translate(15 59)">
                            <g>
                                <g>
                                    <path d="M80 0c31.48 0 57 25.52 57 57s-25.52 57-57 57-57-25.52-57-57S48.52 0 80 0zm0 15c-23.196 0-42 18.804-42 42s18.804 42 42 42 42-18.804 42-42-18.804-42-42-42z"></path>
                                    <circle
                                        cx="107.5"
                                        cy="132.5"
                                        r="7.5"
                                    ></circle>
                                    <rect
                                        width="41"
                                        height="9"
                                        x="120"
                                        y="128"
                                        rx="4"
                                    ></rect>
                                    <rect
                                        width="41"
                                        height="9"
                                        x="100"
                                        y="150"
                                        rx="4"
                                    ></rect>
                                    <rect
                                        width="41"
                                        height="9"
                                        x="20"
                                        y="128"
                                        rx="4"
                                    ></rect>
                                    <rect
                                        width="41"
                                        height="9"
                                        x="1"
                                        y="150"
                                        rx="4"
                                    ></rect>
                                    <circle
                                        cx="7.5"
                                        cy="132.5"
                                        r="7.5"
                                    ></circle>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};
