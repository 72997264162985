import React, {Component} from 'react';
import {Response} from '../response';
import {filterQuery} from '@app/redux/improve/responses/selectors';
import {connect} from 'react-redux';

interface NewResponseProps {
    filterQuery?: string;
    snippet?: string;
}

export class NewResponseComponent extends Component<NewResponseProps> {
    handleSubmit = () => {};

    render() {
        return (
            <Response
                isNewResponse={true}
                openEditor={true}
                selectedResponse={{
                    title: this.props.filterQuery,
                    document: {selection: this.props.snippet},
                }}
                onSubmit={this.handleSubmit}
            />
        );
    }
}
const mapStateToProps = (state) => ({
    filterQuery: filterQuery(state),
});

export const NewResponse = connect(mapStateToProps)(NewResponseComponent);
