import React from 'react';
import {Field} from 'redux-form';
import {reduxForm} from 'redux-form';
import {Button, KareTextInput, KareSwitch, CheckboxInput} from '@app/common';
import {Trash} from '@app/common/icons';
import {passwordStrengthValidation} from '@app/common/password-validation';
import {validators} from '@app/utils';
import {USER_FORM_FIELDS} from '../constants';
import {get} from 'lodash';
import {MdClose} from 'react-icons/md';
import {Prompt} from 'react-router-dom';
import {SCOPES} from '@app/constants';
import {isUserSSO} from '../utils';

interface UserFormProps {
    handleSubmit?: any;
    pristine?: boolean;
    dirty?: boolean;
    submitting?: boolean;
    error?: string;
    deleteUser?: any;
    initialValues?: any;
    users?: any[];
    onCloseModal: any;
}

export class UserFormComponent extends React.Component<UserFormProps> {
    state = {
        userDeletionError: null,
    };

    handleClickRemoveUser = () => {
        const confirmed = confirm('Are you sure you want to delete this user?');
        if (confirmed) {
            const {
                deleteUser,
                initialValues: {id},
            } = this.props;

            return deleteUser(id).catch((error) => {
                const userDeletionError = get(error, 'message');
                this.setState({userDeletionError});
            });
        }
    };

    render() {
        const {
            handleSubmit,
            pristine,
            error,
            dirty,
            submitting,
            initialValues: {id},
            users,
            onCloseModal,
        } = this.props;
        const {userDeletionError} = this.state;
        const userid = id;
        const user = users?.find((usr) => usr.id === userid);
        const isSSO = user?.auth_providers
            ? isUserSSO(user?.auth_providers)
            : false;
        return (
            <form onSubmit={handleSubmit} className="settings__user-form">
                <h1>
                    {userid ? 'Edit' : 'Add'} user
                    <MdClose
                        onClick={() => {
                            onCloseModal();
                        }}
                    />
                </h1>
                <Prompt
                    message="Discard unsaved changes?"
                    when={dirty && !submitting}
                />
                {error && <p className="kare-text-error">{error}</p>}
                {userDeletionError && (
                    <p className="kare-text-error">{userDeletionError}</p>
                )}
                <Field
                    name="email"
                    component={KareTextInput}
                    placeholder="you@company.com"
                    label="Email address"
                    props={{
                        readonly: isSSO,
                    }}
                    validate={[validators.required, validators.email]}
                />
                {!userid && (
                    <Field
                        name="password"
                        component={KareTextInput}
                        type="password"
                        label="Password"
                        props={{
                            readonly: isSSO,
                        }}
                        validate={[
                            validators.required,
                            passwordStrengthValidation,
                        ]}
                    />
                )}
                {/* We need all to be maintained in the list in order to continue editng users! */}
                <Field
                    name={`${SCOPES}[${USER_FORM_FIELDS.ALL}]`}
                    props={{
                        disabled: isSSO,
                    }}
                    component={CheckboxInput}
                    label="Admin"
                />
                <label className="kare-text-input__label">Permissions</label>
                <Field
                    name={`${SCOPES}[${USER_FORM_FIELDS.IAM}]`}
                    component={KareSwitch}
                    label="Manage users"
                />
                <Field
                    name={`${SCOPES}[${USER_FORM_FIELDS.SETTINGS}]`}
                    component={KareSwitch}
                    label="Edit settings"
                />

                <Field
                    name={`${SCOPES}[${USER_FORM_FIELDS.KNOWLEDGE_READ}]`}
                    component={KareSwitch}
                    label="View knowledge"
                />

                <Field
                    name={`${SCOPES}[${USER_FORM_FIELDS.KNOWLEDGE_WRITE}]`}
                    component={KareSwitch}
                    label="Edit knowledge"
                />

                <section className="submit-button-container submit-button-container--two-buttons">
                    {/* Do not show the delete button if there is only one user! 😬 This should be handled on the backend eventually. */}
                    {userid && users.length > 1 ? (
                        <Button
                            htmlType="button"
                            inverted
                            icon={Trash}
                            onClick={this.handleClickRemoveUser}
                            className="settings__user-form-remove-button"
                        >
                            Remove user
                        </Button>
                    ) : (
                        <span />
                    )}
                    <Button disabled={pristine}>
                        {userid ? 'Update' : 'Add'}
                    </Button>
                </section>
            </form>
        );
    }
}

export const UserForm = reduxForm({
    form: 'USER_FORM',
})(UserFormComponent);
