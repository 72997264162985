import React from 'react';
import Select, {components} from 'react-select';
import {get} from 'lodash';
import cx from 'classnames';

import {ArrowDropDown} from '@app/common/icons';

interface KareDropdownProps {
    options: any;
    input: any;
    placeholder?: string;
    onChange?: any;
    className?: string;
    label?: string;
    onMenuOpen?: any;
    icon?: any;
    isDisabled?: any;
    isLoading?: any;
}

export class KareDropdown extends React.Component<KareDropdownProps> {
    dropdownIndicator = (props) => {
        const menuIsOpen = get(props, ['selectProps', 'menuIsOpen'], false);
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <ArrowDropDown
                        size={20}
                        className={cx({rotate: menuIsOpen})}
                    />
                </components.DropdownIndicator>
            )
        );
    };

    iconOptionComponent = (props) => {
        const {Option} = components;
        return (
            <Option {...props}>
                {props.data.icon}
                {props.data.label}
            </Option>
        );
    };

    iconControlComponent = (props) => {
        const {Control} = components;
        const {children, selectProps} = props;
        return (
            <Control {...props}>
                {selectProps.value.value ? selectProps.value.icon : null}
                {children}
            </Control>
        );
    };

    render() {
        const {
            options,
            input,
            placeholder,
            onChange,
            className,
            label,
            icon,
            isDisabled = false,
            isLoading = false,
            onMenuOpen = () => {},
        } = this.props;
        const value = input ? input.value : undefined;
        const handleOnChange = input ? input.onChange : onChange;
        const iconOption = icon
            ? {
                  Option: this.iconOptionComponent,
                  Control: this.iconControlComponent,
              }
            : {};
        return (
            <div className="kare-dropdown-container">
                {label && <label htmlFor={input.name}>{label}</label>}
                <Select
                    className={className}
                    options={options}
                    components={{
                        DropdownIndicator: this.dropdownIndicator,
                        ...iconOption,
                    }}
                    classNamePrefix={'kare-dropdown'}
                    value={value}
                    onChange={handleOnChange}
                    placeholder={placeholder}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    onMenuOpen={onMenuOpen}
                    // menuIsOpen={true}
                />
            </div>
        );
    }
}
