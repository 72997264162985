import React, {FC, useEffect, useState} from 'react';
import {withRouter} from 'react-router';
import {Field} from 'redux-form';
import {KareTextInput, Button, KareDropdown} from '@app/common';
import DOMPurify from 'dompurify';

import {Edit} from '@app/common/icons/index';
import {AVAILABLE_ESCALATION_METHODS, ESCALATION_METHODS} from './constants';
import {Add, Delete, Warning} from '@app/common/icons';
import {
    dispatchBlurAction,
    getEditingEscalationValueLabel,
    getEscalationIcon,
    getEscalationValueValidation,
} from './utils';
import {validators} from '@app/utils';
import {connect} from 'react-redux';
import {RPACustomEscalation} from './rpa/RpaCustomEscalation';
import {MdClose} from 'react-icons/md';
import {DialpadCallback} from '@app/settings/escalation-settings/dialpad-callback/dialpadCallback';

interface EscalationSettingsProps {
    resetField?: any;
    change?: any;
    addEscalationEntry?: any;
    escalationEntries?: {
        default?: boolean;
        method?: string;
        name?: string;
        value?: string;
        id?: any;
    }[];
    makeDefault?: any;
    removeEscalationEntry?: any;
    dispatchBlurAction?: any;
}

const EscalationSettingsComponent: FC<EscalationSettingsProps> = ({
    resetField,
    change,
    addEscalationEntry,
    escalationEntries,
    makeDefault,
    removeEscalationEntry,
    dispatchBlurAction,
    ...remainingProps
}) => {
    const [showModal, setShowModal] = useState(false);
    const [prevEscalationEntries, setPrevEscalationEntries] =
        useState(escalationEntries);
    const [selectedEscalationMethodIndex, setSelectedEscalationMethodIndex] =
        useState(null);
    const [selectedEditEntry, setSelectedEditEntry] = useState(null);

    useEffect(() => {
        if (
            escalationEntries[selectedEscalationMethodIndex] !==
            selectedEditEntry
        ) {
            setSelectedEditEntry(
                escalationEntries[selectedEscalationMethodIndex],
            );
        }
    }, [escalationEntries, selectedEscalationMethodIndex, selectedEditEntry]);

    const handleCancel = () => {
        if (
            prevEscalationEntries.length !== escalationEntries.length &&
            !prevEscalationEntries.find(
                (escalation) => escalation.id === selectedEditEntry.id,
            )
        ) {
            removeEscalationEntry(selectedEditEntry);
        }

        closeEditModal();
    };

    const isValidEscalationEntry = (i) => {
        const escalationEntry = escalationEntries[i];
        const validation: any = getEscalationValueValidation(
            escalationEntry?.method,
        );

        return !(
            !!validation.filter((val) => !!val(escalationEntry?.value))
                .length ||
            !!validators.required(escalationEntry?.name) ||
            !!validators.required(escalationEntry?.method)
        );
    };

    const closeEditModal = () => {
        setShowModal(false);
    };

    const handleAddEscalationEntry = () => {
        const newEscalation = escalationEntries[escalationEntries.length - 1];

        let sanitizedValue = DOMPurify.sanitize(newEscalation.value);
        let sanitizedName = DOMPurify.sanitize(newEscalation.name);

        if (sanitizedValue.includes('javascript:')) {
            sanitizedValue = '';
        }

        if (sanitizedName.includes('javascript:')) {
            sanitizedName = '';
        }

        newEscalation.name = sanitizedName;
        newEscalation.value = sanitizedValue;

        //Set the first escalation as default
        if (escalationEntries.length === 1) {
            handleMakeDefault(escalationEntries[0]);
        }
        setPrevEscalationEntries(escalationEntries);
        closeEditModal();
    };

    const handleMakeDefault = (escalationEntry) => {
        makeDefault(escalationEntry);
    };

    const handleRemoveEscalationEntry = (e, escalationEntry) => {
        e.preventDefault();
        e.stopPropagation();
        if (escalationEntry.default) {
            window.prompt(
                'Please, make another escalation entry default before removing this one',
            );
            return;
        }
        const confirm = window.confirm(
            'Are you sure you want to delete the selected escalation method?',
        );
        if (confirm) {
            removeEscalationEntry(escalationEntry);
        }
    };

    const handleNewEscalationEntry = (e) => {
        e.preventDefault();
        addEscalationEntry();
        openEditModal(escalationEntries.length);
    };

    const openEditModal = (selectedEntryIndex: number) => {
        setSelectedEscalationMethodIndex(selectedEntryIndex);
        setShowModal(true);
    };

    const handleOnChange = () => {
        dispatchBlurAction(
            selectedEscalationMethodIndex,
            selectedEditEntry.value,
        );
        change(`escalation_entries[${selectedEscalationMethodIndex}].name`, '');
        change(
            `escalation_entries[${selectedEscalationMethodIndex}].value`,
            '',
        );
    };

    return (
        <div className="escalation-settings">
            <h4>Escalation</h4>
            <p>
                Choose your contact methods, when your customers need more help
                form you.
            </p>
            {escalationEntries.length === 0 ? (
                <div className="escalation-settings__warning">
                    <span className="escalation-settings__warning__message">
                        <Warning /> <span>WARNING!</span>
                    </span>
                    <p>
                        You have no escalation method chosen.
                        <br /> Add an escalation method for your customers to
                        get in touch when they need more help.
                    </p>
                    <Button
                        className="escalation-settings__add__button"
                        inverted
                        icon={Add}
                        onClick={(e) => handleNewEscalationEntry(e)}
                    >
                        Add new escalation method
                    </Button>
                </div>
            ) : (
                escalationEntries.map((escalationEntry, index) => {
                    return (
                        <div
                            className="escalation-settings__entry__container"
                            key={escalationEntry.id}
                        >
                            <div
                                className="escalation-settings__entry"
                                onClick={() => openEditModal(index)}
                            >
                                <div className="escalation-settings__entry__method">
                                    {getEscalationIcon(escalationEntry.method)}
                                    <span>{escalationEntry.name}</span>
                                </div>
                                <div className="escalation-settings__entry__meta">
                                    {escalationEntry.default && (
                                        <span className="escalation-settings__entry__default">
                                            Default
                                        </span>
                                    )}
                                    <Edit />
                                    {!escalationEntry.default && (
                                        <Delete
                                            onClick={(e) => {
                                                handleRemoveEscalationEntry(
                                                    e,
                                                    escalationEntry,
                                                );
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                            {!escalationEntry.default && (
                                <Button
                                    outlined
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleMakeDefault(escalationEntry);
                                    }}
                                >
                                    Make Default
                                </Button>
                            )}
                        </div>
                    );
                })
            )}
            {escalationEntries.length > 0 && (
                <Button
                    className="escalation-settings__add__button"
                    inverted
                    icon={Add}
                    onClick={handleNewEscalationEntry}
                >
                    Add new escalation method
                </Button>
            )}
            {showModal && (
                <div className="escalation-settings__modal">
                    <div
                        className="escalation-settings__modal__content"
                        key={selectedEditEntry?.id}
                    >
                        <h2>
                            Setup an escalation method
                            <MdClose onClick={handleCancel} />
                        </h2>
                        <div className="escalation-settings__modal__content__row spaced escalation-settings_spain_margins">
                            <span>Respondent</span>
                            <Field
                                onKeyPress={(e) => {
                                    if (e && e.key && e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                component={KareTextInput}
                                readonly={
                                    selectedEditEntry?.method ===
                                    ESCALATION_METHODS.DIALPAD_CALLBACK
                                }
                                name={`escalation_entries[${selectedEscalationMethodIndex}].name`}
                                onBlur={(e) => e.preventDefault()}
                                validate={validators.required}
                            />
                        </div>
                        <div className="escalation-settings__modal__content__row escalation-settings_spain_margins">
                            <p>Contact method</p>
                            <Field
                                name={`escalation_entries[${selectedEscalationMethodIndex}].method`}
                                onKeyPress={(e) => {
                                    if (e && e.key && e.key === 'Enter') {
                                        e.preventDefault();
                                    }
                                }}
                                component={KareDropdown}
                                props={{
                                    options: AVAILABLE_ESCALATION_METHODS.map(
                                        (method) => ({
                                            value: method,
                                            label: getEditingEscalationValueLabel(
                                                method,
                                            ),
                                            icon: getEscalationIcon(method),
                                        }),
                                    ),
                                    icon: true,
                                }}
                                normalize={(value) => {
                                    return value.value;
                                }}
                                format={(value) => {
                                    return {
                                        value,
                                        label: getEditingEscalationValueLabel(
                                            value,
                                        ),
                                        icon: getEscalationIcon(value),
                                    };
                                }}
                                onBlur={(e) => e.preventDefault()}
                                onChange={handleOnChange}
                                validate={validators.required}
                                placeholder={'Select...'}
                            />
                        </div>

                        {selectedEditEntry?.method ===
                            ESCALATION_METHODS.RPA && (
                            <RPACustomEscalation
                                selectedEscalationMethodIndex={
                                    selectedEscalationMethodIndex
                                }
                                {...remainingProps}
                            />
                        )}

                        {selectedEditEntry?.method ===
                            ESCALATION_METHODS.DIALPAD_CALLBACK && (
                            <DialpadCallback
                                selectedEscalationMethodIndex={
                                    selectedEscalationMethodIndex
                                }
                                change={change}
                            />
                        )}

                        {selectedEditEntry?.method !==
                            ESCALATION_METHODS.ZENDESK &&
                            selectedEditEntry?.method !==
                                ESCALATION_METHODS.DIALPAD_CALLBACK &&
                            selectedEditEntry?.method !==
                                ESCALATION_METHODS.RPA && (
                                <div className="escalation-settings__modal__content__row spaced">
                                    <span className="escalation-settings_spain_margins">
                                        {getEditingEscalationValueLabel(
                                            selectedEditEntry?.method,
                                        )}
                                    </span>
                                    <Field
                                        component={KareTextInput}
                                        name={`escalation_entries[${selectedEscalationMethodIndex}].value`}
                                        onBlur={(e) => e.preventDefault()}
                                        validate={getEscalationValueValidation(
                                            selectedEditEntry?.method,
                                        )}
                                    />
                                </div>
                            )}
                        {selectedEditEntry?.method ===
                            ESCALATION_METHODS.CUSTOM && (
                            <aside className="escalation-instructions">
                                <h1 className="kare-h4">Custom escalation</h1>
                                <p>
                                    For custom escalation instructions please
                                    see our documentation{' '}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://developer.karehq.com/javascript-sdk#setting-event-handlers-programmatically-with-javascript"
                                    >
                                        here
                                    </a>
                                    .
                                </p>
                            </aside>
                        )}
                        <div className="escalation-settings__modal__content__row confirm-buttons">
                            <Button onClick={handleCancel} outlined>
                                Cancel
                            </Button>
                            <Button
                                disabled={
                                    !isValidEscalationEntry(
                                        selectedEscalationMethodIndex,
                                    )
                                }
                                onClick={handleAddEscalationEntry}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                    );
                </div>
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    dispatchBlurAction: (index) => dispatchBlurAction(dispatch, index),
});

export const EscalationSettings = withRouter(
    connect(null, mapDispatchToProps)(EscalationSettingsComponent),
);
