import React, {useState} from 'react';
import get from 'lodash/get';
import {Button} from '@app/components/inputs/buttons/Button';
import {BLOCK_KEYS, BLOCK_TYPES} from '@app/constants';
import {isURL} from 'validator';

interface CTACreationModalProps {
    currentCTA?: any[];
    addCTA?: any;
    closeModal?: any;
}

export const CTACreationModal = (props: CTACreationModalProps) => {
    const currentCTA = get(props.currentCTA, ['call_to_action_block']);
    const [buttonText, setButtonText] = useState(
        currentCTA ? currentCTA.call_to_action : '',
    );
    const [description, setDescription] = useState(
        currentCTA ? currentCTA.text : '',
    );
    const [errors, setErrors] = useState([]);
    const [url, setURL] = useState(currentCTA ? currentCTA.url : '');
    const validateForm = () => {
        const errors = [];
        if (!isURL(url)) {
            errors.push('url');
        }
        if (!buttonText) {
            errors.push('buttonText');
        }
        if (!description) {
            errors.push('description');
        }
        return errors;
    };
    const addCTA = () => {
        const errors = validateForm();
        if (errors && errors.length > 0) {
            setErrors(errors);
            return;
        }
        props.addCTA({
            [BLOCK_KEYS.CTA_BLOCK]: {
                call_to_action: buttonText,
                text: description,
                url,
            },
            type: BLOCK_TYPES.CTA,
        });
        props.closeModal();
    };
    return (
        <div className="cta-creation-modal">
            <h2>Add call to action</h2>
            <div className="cta-creation-modal-content">
                <div className="cta-creation-modal-wrapper-input">
                    <span className="cta-creation-modal-h3">Description</span>
                    <input
                        onChange={(e) => {
                            setDescription(e.target.value);
                        }}
                        value={description}
                    />
                    {errors && errors.includes('description') && (
                        <span className="cta-creation-modal-error">
                            Missing description for call to action
                        </span>
                    )}
                </div>
                <div className="cta-creation-modal-wrapper-input">
                    <span>Button text</span>
                    <input
                        onChange={(e) => {
                            setButtonText(e.target.value);
                        }}
                        value={buttonText}
                    />
                    {errors && errors.includes('buttonText') && (
                        <span className="cta-creation-modal-error">
                            Missing call to action text
                        </span>
                    )}
                </div>

                <div className="cta-creation-modal-wrapper-input">
                    <span>Target URL</span>
                    <input
                        onChange={(e) => {
                            setURL(e.target.value);
                        }}
                        value={url}
                    />
                    {errors && errors.includes('url') && (
                        <span className="cta-creation-modal-error">
                            Invalid url
                        </span>
                    )}
                </div>
            </div>
            <div className="cta-creation-modal-wrapper-buttons">
                <Button text="ADD" type="confirm" onClick={addCTA} />
                <Button
                    text="CANCEL"
                    type="cancel"
                    onClick={props.closeModal}
                />
            </div>
        </div>
    );
};
