export const getBlocksWithNodeData = (blocks = [], nodes = []) => {
    const richBlocks = blocks.map((block) => {
        if (block.type === 'quote') {
            // TODO(rupertrutland): Normalize nodes here from the outbound nodes...!!
            const match = nodes.find((node) => {
                if (node.node) {
                    return node.node.id === block.quote_block.node;
                } else {
                    return node.id === block.quote_block.node;
                }
            });
            if (match) {
                if (match.node) {
                    return {
                        ...block,
                        quote_block: {...block.quote_block, ...match.node},
                    };
                } else {
                    return {
                        ...block,
                        quote_block: {...block.quote_block, ...match},
                    };
                }
            }
            return block;
        }
        if (block.type === 'choices') {
            return {
                ...block,
                choices_block: {
                    choices: block.choices_block.choices.map((choice) => {
                        const match = nodes.find((node) => {
                            if (node.node) {
                                return node.node.id === choice.node;
                            } else {
                                return node.id === choice.node;
                            }
                        });
                        if (match) {
                            if (match.node) {
                                return {...choice, ...match.node};
                            } else {
                                return {...choice, ...match};
                            }
                        }
                        return choice;
                    }),
                },
            };
        }
        return block;
    });
    return richBlocks;
};

export const sanitizeMarkdown = (md) =>
    md
        .split('\n')
        .filter((t) => t !== '')
        .join('\n\n');
