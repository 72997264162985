import React, {useEffect, useState} from 'react';
import {ResponsivePie} from '@nivo/pie';
import './FeedbackChart.scss';
import {CardTitle} from '@app/components/CardTitle/CardTitle';
import {
    DATA_KEYS,
    getChartColor,
    getLegendIcon,
} from '@app/utils/DataRenderingUtils';
import {RadialChartsBottomLegend} from '@app/components/RadialChartsBottomLegend/RadialChartsBottomLegend';
import {ErrorLoadingWrapper} from '@app/components/ErrorLoadingWrapper/ErrorLoadingWrapper';
import {transformAggregatedFeedbackDataToRadialChart} from '@app/utils/FeedbackDataTransform';
import {RadialContentLoader} from '@app/components/ContentLoaders/RadialContentLoader';
import {connect} from 'react-redux';
import {dateSelector} from '@app/redux/application/selectors';
import {Moment} from 'moment';
import {redux} from '@app/redux';
import {GET_AGGREGATED_FEEDBACK} from '@app/redux/feedback/constants';
import {
    aggregatedFeedbackErrorSelector,
    getAggregatedFeedbackSelector,
    isAggregatedFeedbackFetchingSelector,
} from '@app/redux/feedback/selectors';

export interface IFeedbackChartProps {
    date: {
        minDate?: Moment;
        maxDate?: Moment;
    };
    getAggregatedFeedback: any;
    feedbackSelector: any;
    feedbackLoadingSelector: boolean;
    feedbackErrorSelector: boolean;
}
const newDefaultInitialState = {
    data: {} as any,
    error: null,
    loading: true,
};

const FeedbackChartComponent = (props: IFeedbackChartProps) => {
    const [feedbackData, setFeedbackData] = useState(newDefaultInitialState);
    const {
        date,
        feedbackErrorSelector,
        feedbackLoadingSelector,
        feedbackSelector,
        getAggregatedFeedback,
    } = props;

    useEffect(() => {
        if (!feedbackLoadingSelector && date?.minDate && date?.maxDate) {
            getAggregatedFeedback(date.minDate, date.maxDate);
        }
    }, [date]);

    useEffect(() => {
        setFeedbackData({
            loading: feedbackLoadingSelector,
            error: feedbackErrorSelector ? 'Error' : '',
            data: feedbackSelector,
        });
    }, [feedbackLoadingSelector, feedbackSelector, feedbackErrorSelector]);

    const showEmptyState = () => {
        if (
            !feedbackData.data ||
            !feedbackData.data.counters ||
            feedbackData.data.counters === 0
        ) {
            return 'No data';
        }
        return null;
    };

    return (
        <div className="FeedbackChart__container">
            <div className="FeedbackChart__content">
                <CardTitle title={'Content feedback'} />
                <ErrorLoadingWrapper
                    height={'291px'}
                    error={feedbackData.error}
                    isLoading={feedbackData.loading}
                    CustomLoading={RadialContentLoader}
                    emptyState={showEmptyState()}
                    render={() => {
                        const transformedData =
                            transformAggregatedFeedbackDataToRadialChart(
                                feedbackData.data,
                            );
                        const chartData = Object.keys(
                            transformedData.plotData,
                        ).map((k) => ({
                            id: k,
                            label: k,
                            value: transformedData.plotData[k],
                            color: getChartColor(k),
                        }));
                        return (
                            <>
                                <div className="FeedbackChart__chartContainer">
                                    <ResponsivePie
                                        data={chartData}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            bottom: 20,
                                            left: 20,
                                        }}
                                        sortByValue={false}
                                        innerRadius={0.7}
                                        padAngle={1}
                                        colors={(c: any) =>
                                            c.data.color as string
                                        }
                                        borderWidth={0}
                                        enableArcLabels={false}
                                        enableArcLinkLabels={false}
                                    />
                                </div>
                                <RadialChartsBottomLegend
                                    firstSource={{
                                        ratio: transformedData.legendData
                                            .helpful,
                                        value:
                                            feedbackData.data.counters
                                                ?.helpful || 0,
                                        label: 'Helpful',
                                        color: getChartColor(DATA_KEYS.HELPFUL),
                                        icon: getLegendIcon(
                                            DATA_KEYS.HELPFUL,
                                            '',
                                            true,
                                        ),
                                    }}
                                    secondSource={{
                                        ratio: transformedData.legendData
                                            .unhelpful,
                                        value:
                                            feedbackData.data.counters
                                                ?.unhelpful || 0,
                                        label: 'Unhelpful',
                                        color: getChartColor(
                                            DATA_KEYS.UNHELPFUL,
                                        ),
                                        icon: getLegendIcon(
                                            DATA_KEYS.UNHELPFUL,
                                            '',
                                            true,
                                        ),
                                    }}
                                />
                            </>
                        );
                    }}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        feedbackSelector: getAggregatedFeedbackSelector(state),
        feedbackLoadingSelector: isAggregatedFeedbackFetchingSelector(state),
        feedbackErrorSelector: aggregatedFeedbackErrorSelector(state),
        date: dateSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAggregatedFeedback: (minDate: Moment, maxDate: Moment) =>
            dispatch(
                redux.rest.actions[GET_AGGREGATED_FEEDBACK].force({
                    minDate: minDate.unix(),
                    maxDate: maxDate.unix(),
                }),
            ),
    };
};

export const FeedbackChart = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FeedbackChartComponent as any);
