import React from 'react';

// @ts-ignore
import './BorderlessButton.scss';

interface IBorderlessButton {
    text: string;
    onClick: () => any;
    type?: string;
}

export const BorderlessButton: React.FunctionComponent<IBorderlessButton> = (
    props,
) => {
    return (
        <div className="BorderlessButton__container" onClick={props.onClick}>
            <span className="BorderlessButton__button">
                {props.text.toUpperCase()}
            </span>
        </div>
    );
};
