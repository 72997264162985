import React, {useEffect, useContext, useState, Fragment} from 'react';
import {Spinner} from '@app/common';
import {
    KeyboardArrowDown,
    KeyboardArrowUp,
    LibraryBooks,
    LinkOff,
    Message,
} from '@material-ui/icons';

import './Broken.scss';
import {BrokenNode} from '@app/types/Distribution';
import {StoreContext} from '@app/state';

type INodeType = 'under_represented' | 'over_represented';

const QUERIES_KEY = 'queries_count';
const TITLE_KEY = 'title';

interface IBrokenProps {
    history: any;
}

export const Broken: React.FunctionComponent<IBrokenProps> = ({history}) => {
    const {nodes} = useContext(StoreContext);

    const [Broken, setBroken] = useState([] as BrokenNode[]);
    const [loadingBroken, setLoadingBroken] = useState(true);
    const [sortKey, setSortKey] = useState(TITLE_KEY);
    const [sortOrder, setSortOrder] = useState(1);

    const getBroken = async () => {
        setLoadingBroken(true);
        const res = await nodes.getBroken();
        setBroken(res.data ? res.data.nodes : [] || []);
        setLoadingBroken(false);
    };

    const getOrderedResponses = (responses: BrokenNode[]): BrokenNode[] => {
        return responses.sort((a, b) => {
            if (a[sortKey] > b[sortKey]) {
                return sortOrder;
            }
            if (a[sortKey] < b[sortKey]) {
                return sortOrder * -1;
            }
            return 0;
        });
    };

    useEffect(() => {
        getBroken();
    }, []);

    return (
        <div className="Broken__container">
            {loadingBroken ? (
                <Spinner />
            ) : Broken && Broken.length > 0 ? (
                <Fragment>
                    <div className="Broken__tableHeader">
                        <span
                            onClick={() => {
                                if (sortKey !== TITLE_KEY) {
                                    setSortKey(TITLE_KEY);
                                } else {
                                    setSortOrder(sortOrder * -1);
                                }
                            }}
                        >
                            <span>Title</span>
                            {sortKey === TITLE_KEY ? (
                                sortOrder === -1 ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                )
                            ) : null}
                        </span>
                        <span>
                            <span>Complication</span>
                            {sortKey === QUERIES_KEY ? (
                                sortOrder === -1 ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                )
                            ) : null}
                        </span>
                    </div>
                    <ul>
                        {getOrderedResponses(Broken).map((i: BrokenNode) => {
                            return (
                                <li
                                    key={i.id}
                                    onClick={() => {
                                        history.push(`?compose=${i.id}`);
                                    }}
                                >
                                    <LinkOff />
                                    {i.title}
                                    <small>
                                        {i.broken_choices &&
                                        i.broken_choices.length > 0 ? (
                                            <span>
                                                <Message />
                                                {i.broken_choices.length}
                                            </span>
                                        ) : null}
                                        {i.broken_quotes &&
                                        i.broken_quotes.length > 0 ? (
                                            <span>
                                                <LibraryBooks />
                                                {i.broken_quotes.length}
                                            </span>
                                        ) : null}
                                    </small>
                                </li>
                            );
                        })}
                    </ul>
                </Fragment>
            ) : (
                <div className="Broken__noData">
                    <h2>There are no responses with broken content</h2>
                </div>
            )}
        </div>
    );
};
