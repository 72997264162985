import React from 'react';
import cx from 'classnames';
import {get} from 'lodash';
import {BLOCK_KEYS, BLOCK_TYPES, CX_BLOCK_TYPES} from '@app/constants';
import {renderInactiveExtension} from '@app/responses/response-portal/response-form/block-fields/extension-block-field';
import {renderInactiveHandover} from '@app/responses/response-portal/response-form/block-fields/handover-block-field';
import {renderInactiveImage} from '@app/responses/response-portal/response-form/block-fields/image-block-field';
import {renderInactiveQuote} from '@app/responses/response-portal/response-form/block-fields/quote-block-field';
import {renderInactiveWorkflow} from '@app/responses/response-portal/response-form/block-fields/workflow-block-field';
import {renderInactiveText} from '@app/responses/response-portal/response-form/block-fields/text-block-field';
import {renderInactiveVideo} from '@app/responses/response-portal/response-form/block-fields/video-block-field';
import {ChatBubble, Document, FormatQuote} from '../icons';
import {Markdown} from '../markdown';
import {Tags} from '../tags';
import {renderInactiveChoices} from '@app/responses/response-portal/response-form/block-fields/dialog-block-field';
import {renderInactiveCTABlock} from '@app/responses/response-portal/response-form/block-fields/cta-block-field';
import {renderInactiveNPSBlock} from '@app/responses/response-portal/response-form/block-fields/nps-block-field';
import {renderInactiveCSATBlock} from '@app/responses/response-portal/response-form/block-fields/csat-block-field';
import {renderInactiveCESBlock} from '@app/responses/response-portal/response-form/block-fields/ces-block-field';

interface KareResponseRendererProps {
    response: any;
    nodes: any;
}

// TODO(rupertrutland): Render all of these blocks nicely.
// Maybe add some utility functions to work with blocks, pulling out data etc...
export class KareResponseRenderer extends React.Component<KareResponseRendererProps> {
    state = {
        npsScores: Array.from({length: 11}).fill({hoverClassName: ''}),
    };

    setNpsScores = (npsScores) => {
        this.setState({npsScores: npsScores});
    };

    renderTextBlocks = (blocks) => {
        return (
            <section className="kare-response-renderer-text">
                {blocks.map((block) => {
                    const markdown = get(block, ['text_block', 'text'], '');
                    return (
                        <Markdown key={JSON.stringify(block)}>
                            {markdown}
                        </Markdown>
                    );
                })}
            </section>
        );
    };
    renderQuoteBlocks = (blocks) => {
        return (
            <section className="kare-response-renderer-quotes">
                {blocks.map((block) => {
                    const selection = get(block, ['quote_block', 'quote'], '');
                    const title = get(block, ['quote_block', 'title'], '');
                    return (
                        <div
                            key={JSON.stringify(block)}
                            className={cx('kare-quote-selection')}
                        >
                            <div className="kare-quote-selection__header">
                                {selection && (
                                    <span>
                                        <FormatQuote size={14} />
                                        QUOTE
                                    </span>
                                )}
                            </div>
                            <div className={'kare-quote-selection__content'}>
                                {selection && <Markdown>{selection}</Markdown>}
                                {title && (
                                    <span className="kare-quote-selection__content__document">
                                        <Document />
                                        {title}
                                    </span>
                                )}
                            </div>
                        </div>
                    );
                })}
            </section>
        );
    };
    renderDialogBlocks = (blocks) => {
        return (
            <section className="kare-response-renderer-dialogs">
                {blocks.map((block) => {
                    const choices = get(
                        block,
                        ['choices_block', 'choices'],
                        [],
                    );
                    return (
                        <div
                            key={JSON.stringify(block)}
                            className="response-form__dialog"
                        >
                            <h2>
                                <ChatBubble size={12} />
                                <span>Dialog Options</span>
                            </h2>
                            {choices.map((choice) => (
                                <div
                                    key={choice.node}
                                    className={cx('response-form__dialog-item')}
                                >
                                    <ChatBubble size={10} />
                                    <div
                                        className={
                                            'response-form__dialog-item__text'
                                        }
                                    >
                                        <span>
                                            {' '}
                                            {choice.text || choice.title}
                                        </span>
                                        {choice.text &&
                                            choice.text.length > 0 && (
                                                <small>{choice.title}</small>
                                            )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    );
                })}
            </section>
        );
    };

    renderBlocks = (block) => {
        const blockType: any = get(block, ['type'], '');

        if (blockType === BLOCK_TYPES.HANDOVER) {
            /*@ts-ignore*/
            return renderInactiveHandover(
                get(block, BLOCK_KEYS.HANDOVER_BLOCK),
            );
        }
        if (blockType === BLOCK_TYPES.EXTENSION) {
            /*@ts-ignore*/
            return renderInactiveExtension(
                get(block, BLOCK_KEYS.EXTENSION_BLOCK),
            );
        }
        if (blockType === BLOCK_TYPES.TEXT) {
            const text = get(block, [BLOCK_KEYS.TEXT_BLOCK, 'text'], '');
            /*@ts-ignore*/
            return renderInactiveText(text);
        }
        if (blockType === BLOCK_TYPES.IMAGE) {
            const img = get(block, [BLOCK_KEYS.IMAGE_BLOCK, 'url'], '');
            return renderInactiveImage(img);
        }
        if (blockType === BLOCK_TYPES.VIDEO) {
            const video = get(block, [BLOCK_KEYS.VIDEO_BLOCK, 'url']);
            return renderInactiveVideo(video);
        }
        if (blockType === BLOCK_TYPES.QUOTE) {
            /*@ts-ignore*/
            return renderInactiveQuote(block);
        }
        if (blockType === BLOCK_TYPES.WORKFLOW) {
            /*@ts-ignore*/
            return renderInactiveWorkflow(block);
        }
        if (blockType === BLOCK_TYPES.CHOICES) {
            const title = get(block, [BLOCK_KEYS.CHOICES_BLOCK, 'text'], '');
            const choices = get(
                block,
                [BLOCK_KEYS.CHOICES_BLOCK, 'choices'],
                '',
            );
            /*@ts-ignore*/
            return renderInactiveChoices(choices, title);
        }
        if (blockType === BLOCK_TYPES.CTA) {
            const buttonText = get(
                block,
                [BLOCK_KEYS.CTA_BLOCK, 'call_to_action'],
                '',
            );
            const title = get(block, [BLOCK_KEYS.CTA_BLOCK, 'text'], '');

            return renderInactiveCTABlock(title, buttonText);
        }
        if (blockType === BLOCK_TYPES.CX_QUESTION) {
            const cxType = get(block, ['cx_question_block', 'type']);

            switch (cxType) {
                case CX_BLOCK_TYPES.NPS:
                    return renderInactiveNPSBlock(
                        block,
                        this.state.npsScores,
                        this.setNpsScores,
                    );
                case CX_BLOCK_TYPES.CSAT:
                    return renderInactiveCSATBlock(block);
                case CX_BLOCK_TYPES.CES:
                    return renderInactiveCESBlock(block);
            }
            return null;
        }
    };

    render() {
        const {response} = this.props;
        const title = get(response, ['title'], '');
        const labels = get(response, ['labels'], []);
        const chatteringBlocks = get(response, ['chattering', 'blocks']);
        const responseBlocks = get(response, ['response', 'blocks']);
        const blocks = chatteringBlocks || responseBlocks || [];

        return (
            <section className="kare-response-renderer">
                <h1>
                    <ChatBubble />
                    {title}
                </h1>
                <Tags tags={labels} />
                {blocks.map((b) => this.renderBlocks(b))}
            </section>
        );
    }
}
