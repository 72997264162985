import {rest} from './rest';

import * as constants from './constants';
import * as selectors from './selectors';

export const widget = {
    rest,
    constants,
    selectors,
};
