import Axios from 'axios';
import {DistributionResponse} from '@app/types/Distribution';

export class Nodes {
    cache = {
        unbalanced: {},
        broken: {},
    };

    constructor() {}

    storeDistributionData = (URL: string, data: DistributionResponse) => {
        this.cache.unbalanced[URL] = data;
    };

    storeBrokenData = (URL: string, data: DistributionResponse) => {
        this.cache.broken[URL] = data;
    };

    async getNode(node: string) {
        const URL = `/v3.0/kbm/nodes/${node}`;
        const res = {
            data: null,
            error: null,
        };
        try {
            const req = await Axios.get(URL);
            res.data = req.data;
            this.storeDistributionData(URL, req.data);
        } catch (e) {
            res.error = e;
        }

        return res;
    }

    async getDistribution(forceRefresh = false) {
        const URL = `/v3.0/improve/nodes/unbalanced`;
        if (this.cache.unbalanced[URL] && !forceRefresh) {
            return {data: this.cache.unbalanced[URL], error: null};
        }
        const res = {
            data: null,
            error: null,
        };
        try {
            const req = await Axios.get(URL);
            res.data = req.data;
            this.storeDistributionData(URL, req.data);
        } catch (e) {
            res.error = e;
        }

        return res;
    }

    async getBroken(forceRefresh = false) {
        const URL = `/v3.0/improve/nodes/broken`;
        if (this.cache.broken[URL] && !forceRefresh) {
            return {data: this.cache.broken[URL], error: null};
        }
        const res = {
            data: null,
            error: null,
        };
        try {
            const req = await Axios.get(URL);
            res.data = req.data;
            this.storeDistributionData(URL, req.data);
        } catch (e) {
            res.error = e;
        }

        return res;
    }

    async editNode(node: any) {
        const URL = `/v3.0/kbm/nodes/${node.id}`;
        const res = {
            data: null,
            error: null,
        };
        try {
            const req = await Axios.put(URL, node);
            res.data = req.data;
        } catch (e) {
            res.error = e;
        }

        return res;
    }

    async getNodes() {
        const URL = `/v3.0/kbm/nodes?status=published&type=response&limit=1000`;
        const res = {
            data: null,
            error: null,
        };
        try {
            const req = await Axios.get(URL);
            res.data = req.data;
        } catch (e) {
            res.error = e;
        }

        return res;
    }
}
