import {url} from '@app/utils';
import {services} from '@app/redux/integrations';
import {setCookie, getCookie, eraseCookie} from '@app/utils/auth';

const {serializeQs} = url;

// We don't rely on redux persist here due to the
// nature of this individual project.

export const persistDetails = (providerKey, details) => {
    setCookie(providerKey, JSON.stringify(details));
};

export const loadDetails = (providerKey) => {
    return JSON.parse(getCookie(providerKey));
};

export const deleteDetails = (providerKey) => {
    eraseCookie(providerKey);
};

export const buildAuthUrl = (provider, values, config) => {
    const qs = serializeQs({
        client_id: config.clientId,
        scope: config.scope,
        redirect_uri: `https://zendeskgateway.karehq.com/mind/return`,
        response_type: config.responseType,
        state: window.btoa(
            `${window.location.hostname}/complete/zendesk**${values.domain}`,
        ),
    });

    return `https://${values.domain}.zendesk.com/oauth/authorizations/new?${qs}`;
};

export const getProviderName = (provider) => {
    if (provider === services.ZENDESK) {
        return 'Zendesk';
    }
    return 'No provider set';
};

export const getProviderTLD = (provider) => {
    if (provider === services.ZENDESK) {
        return '.zendesk.com';
    }
    return 'No provider set';
};
