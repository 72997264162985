import React from 'react';
import {connect} from 'react-redux';
import {redux} from '@app/redux';
import {ErrorBoundary} from '@app/common';
import {PERMISSION_KEYS, SCOPES} from '@app/constants';
import {Check, Settings} from '@app/common/icons';
import ReactTable from 'react-table';

interface UserTableComponentProps {
    push?: any;
    data?: any[];
    userId?: string;
    value?: string;
}

export class UserTableComponent extends React.Component<UserTableComponentProps> {
    handleClickSettings = (id) => () => {
        this.props.push(`?user=${id}`);
    };

    render() {
        const {data, userId} = this.props;
        const columns = [
            {
                Header: 'Users',
                accessor: 'email',
                headerClassName: 'dashboard__dashboard-responses-column-header',
                sortable: false,
                Cell: (props) => {
                    const {value} = props;
                    return <div>{value}</div>;
                },
                width: 180,
            },
            {
                Header: 'Admin',
                accessor: SCOPES,
                sortable: false,
                Cell: (props) => {
                    const {value} = props;
                    if (value.includes(PERMISSION_KEYS.MIND.ALL)) {
                        return (
                            <span className="dashboard__dashboard-responses-admin">
                                Admin
                            </span>
                        );
                    } else {
                        return null;
                    }
                },
                width: undefined,
            },
            {
                Header: 'KB read',
                accessor: SCOPES,
                sortable: false,
                width: undefined,
                Cell: (props) => {
                    const {value} = props;
                    if (
                        value.includes(PERMISSION_KEYS.MIND.KNOWLEDGE.READ) ||
                        value.includes(PERMISSION_KEYS.MIND.ALL)
                    ) {
                        return <Check size={15} />;
                    } else {
                        return null;
                    }
                },
            },
            {
                Header: 'KB write',
                accessor: SCOPES,
                sortable: false,
                width: undefined,
                Cell: (props) => {
                    const {value} = props;
                    if (
                        value.includes(PERMISSION_KEYS.MIND.KNOWLEDGE.WRITE) ||
                        value.includes(PERMISSION_KEYS.MIND.ALL)
                    ) {
                        return <Check size={15} />;
                    } else {
                        return null;
                    }
                },
            },
            {
                Header: 'Settings',
                accessor: SCOPES,
                sortable: false,
                width: undefined,
                Cell: (props) => {
                    const {value} = props;
                    if (
                        value.includes(PERMISSION_KEYS.MIND.SETTINGS) ||
                        value.includes(PERMISSION_KEYS.MIND.ALL)
                    ) {
                        return <Check size={15} />;
                    } else {
                        return null;
                    }
                },
            },
            {
                Header: 'Users',
                accessor: SCOPES,
                sortable: false,
                width: undefined,
                Cell: (props) => {
                    const {value} = props;
                    if (
                        value.includes(PERMISSION_KEYS.MIND.IAM) ||
                        value.includes(PERMISSION_KEYS.MIND.ALL)
                    ) {
                        return <Check size={15} />;
                    } else {
                        return null;
                    }
                },
            },
            {
                Header: '',
                accessor: 'id',
                sortable: true,
                width: 50,
                Cell: (props) => {
                    const {value} = props;
                    if (value === userId) {
                        return null;
                    }
                    return (
                        <Settings
                            className="user-settings-settings"
                            onClick={this.handleClickSettings(value)}
                            size={15}
                        />
                    );
                },
            },
        ];

        if (!data) {
            return null;
        }
        return (
            <ErrorBoundary>
                <div className="">
                    <ReactTable
                        className="kare-user-settings-user-table"
                        data={data}
                        columns={columns}
                        showPagination={true}
                        resizable={false}
                        minRows={0}
                        //@ts-ignore
                        defaultSorted={[{id: 'email'}]}
                    />
                </div>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userId: redux.user.selectors.idSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLoggedInUser: () => {
            dispatch(
                /*@ts-ignore*/
                redux.user.rest.actions[
                    redux.user.constants.GET_LOGGED_IN_USER
                ](),
            );
        },
    };
};

export const UserTable = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserTableComponent);
