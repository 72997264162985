import React from 'react';
import cx from 'classnames';

interface SelectInputProps {
    input: any;
    label?: string;
    children: any;
    meta: any;
    defaultValue: string;
}

export class SelectInput extends React.Component<SelectInputProps> {
    handleClickArrow = () => {
        //@ts-ignore
        this.select.click();
    };

    render() {
        const {
            input,
            children,
            meta: {touched, error, dirty},
        } = this.props;
        const inputClass = cx('c-field c-field--input c-field--select', {
            'is-invalid': touched && error,
        });

        const wrapperClassName = cx('c-field--select-wrapper', {
            dirty: input.value || dirty,
        });
        return (
            <div className={wrapperClassName}>
                <select
                    ref={(select) =>
                        //@ts-ignore
                        (this.select = select)
                    }
                    {...input}
                    className={inputClass}
                >
                    {children}
                </select>
            </div>
        );
    }
}
