import React, {useEffect, useState} from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
import {getSubdomainFromURL} from '../../utils/client-url';
import {superAdminAuthorizeCodeGrantDispatcher} from '../../redux/user/dispatchers';
import {getSuccessUrl} from '../utils';
import {toast} from 'react-toastify';
import {Spinner} from '../../common';

const getSuperAdminAuthorizeParams = () => {
    const u = new URL(window.location.toString());
    const subdomain = getSubdomainFromURL();
    return {
        code: u.searchParams.get('code'),
        subdomain: subdomain,
    };
};

const SuperAdminLoginCompleteComponent = (props) => {
    const [code, setCode] = useState<string | undefined>();
    const [errorDesc, setErrorDesc] = useState<string | undefined>();
    useEffect(() => {
        if (!code) {
            const {code, subdomain} = getSuperAdminAuthorizeParams();
            setCode(code ? code : 'error');
            props
                .superAdminLogin({
                    code,
                    subdomain,
                })
                .then(() => {
                    const successURL = getSuccessUrl(
                        props.location.search,
                    ) as string;
                    window.location.replace(successURL);
                })
                .catch((err) => {
                    const errorDescription: string =
                        err.error.split('desc = ')?.[1];
                    toast.error(`Login failed: ${errorDescription}`);
                    setErrorDesc(errorDescription);
                });
        }
    });
    return (
        (errorDesc && (
            <>
                <h2 className="title">Login failed</h2>
                <Link to="/login" className="c-btn">
                    Return to login
                </Link>
            </>
        )) || (
            <>
                <div className="superadmin-title">
                    <span>Logging in as Super Admin...</span>
                </div>
                <div className="superadmin-spinner">
                    <Spinner withCenteredContainer={true} />
                </div>
            </>
        )
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        superAdminLogin: (params) =>
            superAdminAuthorizeCodeGrantDispatcher(dispatch, params),
    };
};

export const SuperAdminLoginComplete = withRouter(
    connect(null, mapDispatchToProps)(SuperAdminLoginCompleteComponent),
);
