import React from 'react';
import ReactTags from 'react-tag-autocomplete';
import get from 'lodash/get';
import find from 'lodash/find';

import './react-tags.scss';

interface LabelFilterProps {
    selectedTags?: any;
    availableTags?: any;
    setSelectedTags?: any;
    placeholder?: string;
}

/**
 * Tags input tightly coupled with Redux form (all of our forms use it).
 */
export class LabelFilter extends React.Component<LabelFilterProps> {
    state = {
        allowNew: true,
        currentInputText: '',
    };

    /**
     * Use redux-form's onChange method to propagate the delete event.
     */
    handleDelete = (i) => {
        const tags = this.props.selectedTags.slice(0);
        tags.splice(i, 1);
        this.props.setSelectedTags(tags);
    };

    /**
     * Do not allow addition of duplicate tags.
     */
    handleInputChange = (tagName) => {
        const value = this.props.selectedTags || [];
        const currentTagNames = value.map((item) => item.name.toLowerCase());
        this.setState({currentInputText: tagName});
        if (currentTagNames.includes(tagName.toLowerCase())) {
            this.setState({allowNew: false});
        } else {
            this.setState({allowNew: true});
        }
    };

    /**
     * Use redux-form's onChange method to propagate the change event.
     */
    handleAddition = (tag) => {
        const name = get(tag, 'name');
        if (name.length > 0) {
            const foundTag = find(this.props.selectedTags, {name});
            if (!foundTag) {
                const tags = [].concat(this.props.selectedTags, tag);
                this.props.setSelectedTags(tags);
            }
        }
    };

    render() {
        const {selectedTags, placeholder} = this.props;
        const labels = get(this.props, ['availableTags']) || [];
        return (
            <div className="c-field-tags c-field-tags__form-less">
                <ReactTags
                    tags={selectedTags}
                    suggestions={labels.map((label, i) => ({
                        name: label,
                        key: i,
                    }))}
                    minQueryLength={0}
                    handleDelete={this.handleDelete}
                    handleAddition={this.handleAddition}
                    handleInputChange={this.handleInputChange}
                    allowNew={this.state.allowNew}
                    placeholder={placeholder}
                    autofocus={false}
                />
            </div>
        );
    }
}
