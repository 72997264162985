import React from 'react';
import cx from 'classnames';
import './TextInput.scss';

export interface ITextInput {
    classname?: string;
    placeholder?: string;
    label?: string;
    value?: string | undefined;
    disabled?: boolean | undefined;
    onChange?: (value: string) => any | undefined;
    onBlur?: () => any | undefined;
    error?:
        | {
              show: boolean;
              message: string;
          }
        | undefined;
}

export const TextInput: React.FunctionComponent<ITextInput> = (props) => {
    const containerClassName = cx(props.classname, 'TextInput__container');
    return (
        <div className={containerClassName}>
            <label className="TextInput__label">
                {props.label}
                <div className="TextInput__inputAndError">
                    <input
                        disabled={props.disabled}
                        type="text"
                        placeholder={props.placeholder}
                        className="TextInput__input"
                        value={props.value}
                        onChange={(e) => {
                            e.preventDefault();
                            props.onChange?.(e.target.value);
                        }}
                        onBlur={props.onBlur}
                    />
                    {props.error?.show && <span>{props.error?.message}</span>}
                </div>
            </label>
        </div>
    );
};
