import {rest} from './rest';

import {services, types} from './constants';
import * as selectors from './selectors';

export {types, services};

export function submit() {
    return {
        type: types.SUBMIT,
    };
}

export function submitSuccess() {
    return {
        type: types.SUBMIT_SUCCESS,
    };
}

export function submitFailure(error) {
    return {
        type: types.SUBMIT_FAILURE,
        error,
    };
}

export const integrations = {
    rest,
    selectors,
};

// Note(eddie): Due to the nature of the zendesk sales demo, we have not
// integrated parts of it with redux. This will come soon with further integration work
// and for now the reducer is a placeholder.
export default function integrationsReducer(state = {}) {
    return state;
}
