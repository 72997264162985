import React from 'react';
import {Portal} from 'react-portal';
import cx from 'classnames';

interface KnowledgeBasePortalProps {
    children?: any;
    className?: string;
}

/**
 * Reusable portal component.
 */
export class KnowledgeBasePortal extends React.Component<KnowledgeBasePortalProps> {
    render() {
        const className = cx('knowledge-base__portal', this.props.className);
        return (
            <Portal>
                <section className={className}>
                    <div className="knowledge-base__portal__overlay modal-overlay" />
                    <div className="knowledge-base__portal__body">
                        {this.props.children}
                    </div>
                </section>
            </Portal>
        );
    }
}
