import React from 'react';
import {connect} from 'react-redux';

import {redux} from '@app/redux';
import {Tooltip, FlagIcon} from '@app/common';
import {Option} from './option';
import {OptionsTooltip} from '@app/common/icons';
import {locale} from '@app/utils';

interface LanguagePickerProps {
    contentLocale?: any;
    contentLocales?: any;
}

export class LanguagePickerComponent extends React.Component<LanguagePickerProps> {
    render() {
        const {contentLocale, contentLocales} = this.props;
        const {language, country} = locale.getLanguageAndCountry(contentLocale);
        return (
            <section className="language-picker">
                <h3>Support language</h3>

                <div>
                    {contentLocale && (
                        <FlagIcon
                            code={locale
                                .getCountryCode(contentLocale)
                                .toLowerCase()}
                        />
                    )}
                    <div className="language-picker-text">
                        <div className="language-picker-text-language">
                            {language}
                        </div>
                        <div className="language-picker-text-country">
                            {country}
                        </div>
                    </div>
                    {contentLocales && contentLocales.length > 0 && (
                        <Tooltip
                            overlay={
                                <section className="language-picker-overlay">
                                    <h1>Manage support profile:</h1>
                                    {contentLocales.map((loc) => {
                                        return (
                                            <Option
                                                key={loc}
                                                locale={loc}
                                                isActive={loc === contentLocale}
                                            />
                                        );
                                    })}
                                </section>
                            }
                            tooltipProps={{
                                placement: 'top',
                            }}
                        >
                            <OptionsTooltip
                                size={20}
                                className="more-vertical"
                            />
                        </Tooltip>
                    )}
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contentLocale: redux.locale.selectors.contentLocaleSelector(state),
        contentLocales: redux.org.selectors.contentLocalesSelector(state),
    };
};

export const LanguagePicker = connect(
    mapStateToProps,
    null,
)(LanguagePickerComponent);
