import React from 'react';

import {HourglassEmpty} from '@app/common/icons';

export class ProcessingData extends React.Component {
    render() {
        return (
            <div className="kare-empty-state kare-empty-state--icon-secondary">
                <HourglassEmpty size={120} />
                <h1>Analysing your customer activity</h1>
                <p>
                    We are processing your customer activity at the moment.
                    Please come back later to view your cognitive insights and
                    improve your knowledge base
                </p>
            </div>
        );
    }
}
