import React from 'react';

interface GenericModalProps {
    children: any;
}

export class GenericModal extends React.Component<GenericModalProps> {
    handleClickModal = (event) => {
        event.stopPropagation();
    };

    render() {
        const {children} = this.props;
        return (
            <section
                onClick={this.handleClickModal}
                className="kare-modal kare-modal-generic"
            >
                {children}
            </section>
        );
    }
}
