import React from 'react';

import './Label.scss';

interface ILabel {
    text?: String;
    children?: React.ReactNode;
}

export const Label: React.FunctionComponent<ILabel> = (props) => {
    return (
        <div className="Label__label">{props.children || props.text || ''}</div>
    );
};
