import {redux} from '../index';
import {isEmpty} from 'lodash';
import {
    USER_DATA_STORAGE_KEY,
    DASHBOARD_APP_ID,
    PASSWORD_GRANT_TYPE,
    REFRESH_TOKEN_GRANT_TYPE,
    DIALPAD_AUTHORIZE_CODE_GRANT_TYPE,
    ADMIN_AUTHORIZE_CODE_GRANT_TYPE,
} from './constants';
import {setCookie, eraseCookie} from '../../utils/auth';

export const getUserDispatcher = (dispatch) =>
    dispatch(
        redux.rest.actions[redux.user.constants.GET_LOGGED_IN_USER](),
    ).catch(() => {});

export const dialpadAuthorizeCodeGrantDispatcher = (dispatch, userData) => {
    const payload = Object.assign(
        {
            grant_type: DIALPAD_AUTHORIZE_CODE_GRANT_TYPE,
            client_id: DASHBOARD_APP_ID,
        },
        userData,
    );
    return dispatch(
        redux.rest.actions[redux.user.constants.LOGIN](
            {},
            {
                body: JSON.stringify(payload),
            },
        ),
    ).then((data) => {
        if (data && !isEmpty(data)) {
            eraseCookie(USER_DATA_STORAGE_KEY);
            setCookie(USER_DATA_STORAGE_KEY, JSON.stringify(data));
        }
    });
};

export const superAdminAuthorizeCodeGrantDispatcher = (dispatch, data) => {
    const payload = Object.assign(
        {
            grant_type: ADMIN_AUTHORIZE_CODE_GRANT_TYPE,
            client_id: DASHBOARD_APP_ID,
        },
        data,
    );
    return dispatch(
        redux.rest.actions[redux.user.constants.LOGIN](
            {},
            {
                body: JSON.stringify(payload),
            },
        ),
    ).then((data) => {
        if (data && !isEmpty(data)) {
            eraseCookie(USER_DATA_STORAGE_KEY);
            setCookie(USER_DATA_STORAGE_KEY, JSON.stringify(data));
        }
    });
};

export const passwordGrantDispatcher = (dispatch, userData) => {
    const payload = Object.assign(
        {
            grant_type: PASSWORD_GRANT_TYPE,
            client_id: DASHBOARD_APP_ID,
        },
        userData,
    );
    return dispatch(
        redux.rest.actions[redux.user.constants.LOGIN](
            {},
            {
                body: JSON.stringify(payload),
            },
        ),
    ).then((data) => {
        if (data && !isEmpty(data)) {
            eraseCookie(USER_DATA_STORAGE_KEY);
            setCookie(USER_DATA_STORAGE_KEY, JSON.stringify(data));
        }
    });
};

export const refreshTokenGrantDispatcher = (dispatch, refreshToken) => {
    return dispatch(
        redux.rest.actions[redux.user.constants.REFRESH_TOKEN](
            {},
            {
                body: JSON.stringify({
                    grant_type: REFRESH_TOKEN_GRANT_TYPE,
                    refresh_token: refreshToken,
                }),
            },
        ),
    ).catch(() => {});
};
